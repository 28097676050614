import React from "react";
import Map from "../Map/Map";
import InternetSideInfo from "./InternetSideInfo";

const InternetRegionPage: React.FC = () => {
    return (
        <>
            <Map />
            <InternetSideInfo />
        </>
    );
};

export default InternetRegionPage;
