import {LatLngExpression} from "leaflet";

export const glubokoeDistrict: LatLngExpression[] = [
    [
            82.0395898,
            50.2029732
    ],
    [
            82.0271872,
            50.2129434
    ],
    [
            82.011051,
            50.2177767
    ],
    [
            81.9928549,
            50.2130532
    ],
    [
            81.980152,
            50.2130532
    ],
    [
            81.989765,
            50.2245863
    ],
    [
            82.0041846,
            50.2288144
    ],
    [
            82.0316504,
            50.246217
    ],
    [
            82.0274448,
            50.2734336
    ],
    [
            82.0292472,
            50.2735433
    ],
    [
            82.0472003,
            50.3140464
    ],
    [
            82.0273278,
            50.3232337
    ],
    [
            82.0787955,
            50.3838048
    ],
    [
            82.0845222,
            50.3877815
    ],
    [
            82.0904445,
            50.3907912
    ],
    [
            82.0938777,
            50.3920225
    ],
    [
            82.095766,
            50.3920771
    ],
    [
            82.0974183,
            50.3918172
    ],
    [
            82.1055507,
            50.3874395
    ],
    [
            82.1074176,
            50.3866322
    ],
    [
            82.1095306,
            50.3860612
    ],
    [
            82.1118916,
            50.3890789
    ],
    [
            82.1377363,
            50.391302
    ],
    [
            82.2569806,
            50.3730041
    ],
    [
            82.3114861,
            50.3987652
    ],
    [
            82.3278434,
            50.3748206
    ],
    [
            82.3693083,
            50.3708686
    ],
    [
            82.3681067,
            50.3616707
    ],
    [
            82.3816679,
            50.3561949
    ],
    [
            82.3962591,
            50.3547711
    ],
    [
            82.4094771,
            50.3614517
    ],
    [
            82.4098204,
            50.3865229
    ],
    [
            82.4144552,
            50.3921046
    ],
    [
            82.4160002,
            50.3876174
    ],
    [
            82.42115,
            50.3778753
    ],
    [
            82.4345396,
            50.3788606
    ],
    [
            82.4414061,
            50.3765617
    ],
    [
            82.4487875,
            50.3809405
    ],
    [
            82.4539374,
            50.3576187
    ],
    [
            82.4616621,
            50.3525805
    ],
    [
            82.4685286,
            50.3520329
    ],
    [
            82.5002859,
            50.3817067
    ],
    [
            82.487583,
            50.4165033
    ],
    [
            82.5064657,
            50.4228471
    ],
    [
            82.5341032,
            50.4441691
    ],
    [
            82.5349615,
            50.4491975
    ],
    [
            82.5669511,
            50.4892269
    ],
    [
            82.5672769,
            50.4904976
    ],
    [
            82.5715255,
            50.4938011
    ],
    [
            82.5711822,
            50.4969134
    ],
    [
            82.5701522,
            50.4981418
    ],
    [
            82.5694656,
            50.5008989
    ],
    [
            82.5685644,
            50.5022637
    ],
    [
            82.5675344,
            50.5031372
    ],
    [
            82.5686502,
            50.5045293
    ],
    [
            82.5709247,
            50.5057848
    ],
    [
            82.5710105,
            50.506822
    ],
    [
            82.5691652,
            50.5069584
    ],
    [
            82.5662899,
            50.5060032
    ],
    [
            82.5592517,
            50.5056483
    ],
    [
            82.5563335,
            50.5082685
    ],
    [
            82.5559473,
            50.5113798
    ],
    [
            82.5580501,
            50.5145454
    ],
    [
            82.5607538,
            50.5156097
    ],
    [
            82.5619983,
            50.5167831
    ],
    [
            82.5613975,
            50.5183931
    ],
    [
            82.55908,
            50.519812
    ],
    [
            82.5561618,
            50.5207397
    ],
    [
            82.549467,
            50.5220494
    ],
    [
            82.5458265,
            50.5268084
    ],
    [
            82.5689077,
            50.534735
    ],
    [
            82.5896787,
            50.5477173
    ],
    [
            82.5942707,
            50.5545343
    ],
    [
            82.5978755,
            50.5615957
    ],
    [
            82.598133,
            50.5686287
    ],
    [
            82.5937556,
            50.5749521
    ],
    [
            82.5947856,
            50.5789311
    ],
    [
            82.6002788,
            50.5812747
    ],
    [
            82.6062011,
            50.5800756
    ],
    [
            82.6119517,
            50.5748431
    ],
    [
            82.620449,
            50.5738074
    ],
    [
            82.6280021,
            50.5734259
    ],
    [
            82.6343533,
            50.5751703
    ],
    [
            82.6412197,
            50.573208
    ],
    [
            82.653236,
            50.5766965
    ],
    [
            82.6608752,
            50.5702642
    ],
    [
            82.6694583,
            50.5753882
    ],
    [
            82.6825046,
            50.5698281
    ],
    [
            82.7134894,
            50.5861796
    ],
    [
            82.767391,
            50.6086263
    ],
    [
            82.789192,
            50.6390104
    ],
    [
            82.8032683,
            50.6432561
    ],
    [
            82.8097914,
            50.6515286
    ],
    [
            82.865238,
            50.6556643
    ],
    [
            82.8717612,
            50.6469571
    ],
    [
            82.8959654,
            50.6530523
    ],
    [
            82.9155348,
            50.6465217
    ],
    [
            82.9383658,
            50.6534877
    ],
    [
            82.9419707,
            50.6605614
    ],
    [
            82.9601666,
            50.661432
    ],
    [
            82.9721829,
            50.6825377
    ],
    [
            82.9615399,
            50.6884108
    ],
    [
            82.9416272,
            50.6901508
    ],
    [
            82.9320144,
            50.6981431
    ],
    [
            82.9368206,
            50.7197214
    ],
    [
            82.9706382,
            50.7701765
    ],
    [
            82.9652309,
            50.7762015
    ],
    [
            82.9811094,
            50.8021378
    ],
    [
            82.9850577,
            50.8018124
    ],
    [
            82.9920959,
            50.7981235
    ],
    [
            82.999413,
            50.7956821
    ],
    [
            83.0060004,
            50.8021378
    ],
    [
            83.0140685,
            50.8052839
    ],
    [
            83.0193042,
            50.8127686
    ],
    [
            83.0187033,
            50.8141787
    ],
    [
            83.0178451,
            50.818788
    ],
    [
            83.0149267,
            50.8222042
    ],
    [
            83.0070304,
            50.8247525
    ],
    [
            83.0046271,
            50.8248609
    ],
    [
            83.0016231,
            50.8262706
    ],
    [
            83.0005931,
            50.8285476
    ],
    [
            82.9990481,
            50.8293609
    ],
    [
            82.9922674,
            50.8310957
    ],
    [
            82.9836844,
            50.8301199
    ],
    [
            82.9783629,
            50.8320715
    ],
    [
            82.9776762,
            50.8349988
    ],
    [
            82.9795645,
            50.8379258
    ],
    [
            82.9879759,
            50.8397687
    ],
    [
            82.9965588,
            50.8462725
    ],
    [
            82.9821092,
            50.8548927
    ],
    [
            82.9796426,
            50.8838498
    ],
    [
            82.9843166,
            50.8889401
    ],
    [
            82.9893561,
            50.8971567
    ],
    [
            82.9899613,
            50.9022768
    ],
    [
            82.9960814,
            50.9133575
    ],
    [
            83.0059934,
            50.9251995
    ],
    [
            83.0290308,
            50.9289653
    ],
    [
            83.0345693,
            50.9293808
    ],
    [
            83.0388852,
            50.9300912
    ],
    [
            83.0473288,
            50.9332333
    ],
    [
            83.0529621,
            50.9326605
    ],
    [
            83.0589646,
            50.9346123
    ],
    [
            83.0728539,
            50.9412966
    ],
    [
            83.0802932,
            50.9455816
    ],
    [
            83.0886263,
            50.9488605
    ],
    [
            83.0916914,
            50.9508719
    ],
    [
            83.0923938,
            50.9553371
    ],
    [
            83.0977258,
            50.9574488
    ],
    [
            83.1004976,
            50.9581166
    ],
    [
            83.1058743,
            50.962569
    ],
    [
            83.1124788,
            50.9659531
    ],
    [
            83.1144854,
            50.9694943
    ],
    [
            83.114776,
            50.9783532
    ],
    [
            83.1186366,
            50.9837544
    ],
    [
            83.1223062,
            50.9845235
    ],
    [
            83.1202112,
            50.9944943
    ],
    [
            83.119193,
            51.0012376
    ],
    [
            83.1220341,
            51.0047971
    ],
    [
            83.1210212,
            51.0110042
    ],
    [
            83.1397845,
            51.0116659
    ],
    [
            83.1462955,
            51.0100765
    ],
    [
            83.1521325,
            51.0120098
    ],
    [
            83.160929,
            51.0123931
    ],
    [
            83.1750429,
            51.0115701
    ],
    [
            83.1774823,
            51.0083648
    ],
    [
            83.1796199,
            51.0037975
    ],
    [
            83.1876362,
            50.9999678
    ],
    [
            83.2020454,
            50.9933378
    ],
    [
            83.2060446,
            50.9933553
    ],
    [
            83.2256795,
            51.0011814
    ],
    [
            83.2580218,
            51.0034198
    ],
    [
            83.2683749,
            51.006025
    ],
    [
            83.2771448,
            51.013343
    ],
    [
            83.2844364,
            51.0150364
    ],
    [
            83.2915581,
            51.008962
    ],
    [
            83.3201138,
            51.010511
    ],
    [
            83.329825,
            51.0090547
    ],
    [
            83.3459239,
            51.0093976
    ],
    [
            83.3582012,
            51.0075455
    ],
    [
            83.3582263,
            51.0019571
    ],
    [
            83.3662971,
            50.9986147
    ],
    [
            83.3695677,
            50.9986938
    ],
    [
            83.3707753,
            51.0005779
    ],
    [
            83.3746742,
            51.0016328
    ],
    [
            83.3794709,
            51.001419
    ],
    [
            83.3871094,
            51.0037416
    ],
    [
            83.3952068,
            51.0098935
    ],
    [
            83.4043065,
            51.0131384
    ],
    [
            83.4071645,
            51.0131887
    ],
    [
            83.4123809,
            51.0110008
    ],
    [
            83.4171627,
            51.0100749
    ],
    [
            83.4249084,
            51.0104852
    ],
    [
            83.4324488,
            51.0093789
    ],
    [
            83.4398071,
            51.0049494
    ],
    [
            83.4368883,
            50.9953846
    ],
    [
            83.4302231,
            50.9867139
    ],
    [
            83.4474816,
            50.9771369
    ],
    [
            83.4782067,
            50.9768058
    ],
    [
            83.4888187,
            50.9792389
    ],
    [
            83.4995071,
            50.9766292
    ],
    [
            83.5024137,
            50.9740053
    ],
    [
            83.5013112,
            50.9681724
    ],
    [
            83.5043918,
            50.9579256
    ],
    [
            83.5010759,
            50.952505
    ],
    [
            83.5069156,
            50.9497161
    ],
    [
            83.5122732,
            50.9485465
    ],
    [
            83.5375945,
            50.9608683
    ],
    [
            83.5431395,
            50.9589979
    ],
    [
            83.551704,
            50.9591616
    ],
    [
            83.5604668,
            50.9575902
    ],
    [
            83.5633327,
            50.9562524
    ],
    [
            83.5666729,
            50.9559537
    ],
    [
            83.5722768,
            50.9548811
    ],
    [
            83.5752748,
            50.9528935
    ],
    [
            83.579171,
            50.9481973
    ],
    [
            83.5842875,
            50.9467184
    ],
    [
            83.5890055,
            50.9474347
    ],
    [
            83.5935072,
            50.9494263
    ],
    [
            83.5943167,
            50.9559138
    ],
    [
            83.5965872,
            50.9583288
    ],
    [
            83.5976998,
            50.9607349
    ],
    [
            83.6012493,
            50.9591813
    ],
    [
            83.6028215,
            50.9589103
    ],
    [
            83.6056848,
            50.959025
    ],
    [
            83.6078694,
            50.9562311
    ],
    [
            83.609872,
            50.9547819
    ],
    [
            83.6128829,
            50.9540577
    ],
    [
            83.6163235,
            50.9540724
    ],
    [
            83.6215707,
            50.9500971
    ],
    [
            83.6269774,
            50.9498708
    ],
    [
            83.6267041,
            50.9466138
    ],
    [
            83.6275052,
            50.9447129
    ],
    [
            83.6302324,
            50.943596
    ],
    [
            83.6338119,
            50.9439826
    ],
    [
            83.6372891,
            50.9466138
    ],
    [
            83.6379709,
            50.9498032
    ],
    [
            83.642148,
            50.9520043
    ],
    [
            83.6440507,
            50.9530068
    ],
    [
            83.6487039,
            50.9547766
    ],
    [
            83.6511756,
            50.9521048
    ],
    [
            83.6520435,
            50.9453853
    ],
    [
            83.6562059,
            50.9428536
    ],
    [
            83.6557968,
            50.9414681
    ],
    [
            83.6564957,
            50.9395348
    ],
    [
            83.6629225,
            50.9368872
    ],
    [
            83.6639767,
            50.9346776
    ],
    [
            83.6656804,
            50.9330341
    ],
    [
            83.6710868,
            50.931268
    ],
    [
            83.6786076,
            50.9279627
    ],
    [
            83.680433,
            50.9236942
    ],
    [
            83.6808676,
            50.9218354
    ],
    [
            83.6798881,
            50.920554
    ],
    [
            83.6784779,
            50.9195528
    ],
    [
            83.6770814,
            50.9171533
    ],
    [
            83.677846,
            50.9154467
    ],
    [
            83.6847195,
            50.9134081
    ],
    [
            83.6926192,
            50.9089643
    ],
    [
            83.6944779,
            50.9073039
    ],
    [
            83.6943424,
            50.9060708
    ],
    [
            83.6950781,
            50.9048864
    ],
    [
            83.6972293,
            50.9035498
    ],
    [
            83.6995333,
            50.9014251
    ],
    [
            83.7002497,
            50.8989829
    ],
    [
            83.703568,
            50.8962114
    ],
    [
            83.7116038,
            50.8933514
    ],
    [
            83.7143839,
            50.8929728
    ],
    [
            83.7160297,
            50.8934003
    ],
    [
            83.7210924,
            50.8925499
    ],
    [
            83.7269521,
            50.8931544
    ],
    [
            83.7340608,
            50.8923266
    ],
    [
            83.7433456,
            50.8941851
    ],
    [
            83.7546446,
            50.8900747
    ],
    [
            83.7580304,
            50.8898731
    ],
    [
            83.7607002,
            50.8905554
    ],
    [
            83.7627401,
            50.8903827
    ],
    [
            83.7655194,
            50.8891045
    ],
    [
            83.7680796,
            50.889139
    ],
    [
            83.7711678,
            50.8897416
    ],
    [
            83.7896459,
            50.8876354
    ],
    [
            83.7901667,
            50.887585
    ],
    [
            83.7974142,
            50.8878163
    ],
    [
            83.8004881,
            50.8863753
    ],
    [
            83.8021801,
            50.8838311
    ],
    [
            83.8066076,
            50.8828348
    ],
    [
            83.8114299,
            50.8837066
    ],
    [
            83.8153216,
            50.8825145
    ],
    [
            83.8188466,
            50.8831551
    ],
    [
            83.8211591,
            50.8830305
    ],
    [
            83.8243532,
            50.8835698
    ],
    [
            83.828181,
            50.8803439
    ],
    [
            83.833666,
            50.8800221
    ],
    [
            83.8348645,
            50.8761608
    ],
    [
            83.8365995,
            50.8737365
    ],
    [
            83.8357906,
            50.8726571
    ],
    [
            83.8363262,
            50.8699516
    ],
    [
            83.8431501,
            50.8669969
    ],
    [
            83.8476991,
            50.8664552
    ],
    [
            83.8499736,
            50.8647488
    ],
    [
            83.8494551,
            50.8638013
    ],
    [
            83.8473551,
            50.8608367
    ],
    [
            83.8471357,
            50.8581808
    ],
    [
            83.8487956,
            50.8561825
    ],
    [
            83.851282,
            50.8550935
    ],
    [
            83.8573512,
            50.8554601
    ],
    [
            83.86096,
            50.8559449
    ],
    [
            83.8676118,
            50.8516372
    ],
    [
            83.8719463,
            50.8491445
    ],
    [
            83.8761949,
            50.8484942
    ],
    [
            83.8769953,
            50.8472065
    ],
    [
            83.8755839,
            50.8436003
    ],
    [
            83.8752848,
            50.8404649
    ],
    [
            83.8762618,
            50.8385005
    ],
    [
            83.8887274,
            50.8326925
    ],
    [
            83.8928461,
            50.8319088
    ],
    [
            83.897072,
            50.832119
    ],
    [
            83.9002704,
            50.8285205
    ],
    [
            83.903071,
            50.8245645
    ],
    [
            83.9104902,
            50.8162608
    ],
    [
            83.9352667,
            50.8102688
    ],
    [
            83.9437916,
            50.807809
    ],
    [
            83.947132,
            50.8065741
    ],
    [
            83.9509346,
            50.8043071
    ],
    [
            83.9559326,
            50.8034582
    ],
    [
            83.9624119,
            50.8024768
    ],
    [
            83.9643364,
            50.8009987
    ],
    [
            83.9647876,
            50.7983608
    ],
    [
            83.9669308,
            50.7966853
    ],
    [
            83.9701305,
            50.7958272
    ],
    [
            83.9713784,
            50.7957202
    ],
    [
            83.9726623,
            50.7953988
    ],
    [
            83.9734815,
            50.7898237
    ],
    [
            83.9730347,
            50.7865009
    ],
    [
            83.9748033,
            50.7838559
    ],
    [
            83.9766108,
            50.7829126
    ],
    [
            83.9765739,
            50.781303
    ],
    [
            83.9762238,
            50.779943
    ],
    [
            83.9670154,
            50.7735674
    ],
    [
            83.9622819,
            50.7689981
    ],
    [
            83.9582475,
            50.7663194
    ],
    [
            83.9546646,
            50.7656784
    ],
    [
            83.9516926,
            50.7635669
    ],
    [
            83.9502601,
            50.7592623
    ],
    [
            83.9508735,
            50.7572143
    ],
    [
            83.9520094,
            50.7559567
    ],
    [
            83.9517482,
            50.7539374
    ],
    [
            83.9504963,
            50.7519893
    ],
    [
            83.9501373,
            50.7507202
    ],
    [
            83.9489166,
            50.7505568
    ],
    [
            83.9510436,
            50.7463971
    ],
    [
            83.9569681,
            50.7420246
    ],
    [
            83.958454,
            50.7404809
    ],
    [
            83.9605103,
            50.7395151
    ],
    [
            83.9613778,
            50.7380918
    ],
    [
            83.9637072,
            50.7364045
    ],
    [
            83.9654688,
            50.7328669
    ],
    [
            83.9661114,
            50.7279926
    ],
    [
            83.975748,
            50.7211665
    ],
    [
            83.9779901,
            50.7205227
    ],
    [
            83.9792132,
            50.7204548
    ],
    [
            83.979951,
            50.7200877
    ],
    [
            83.9811324,
            50.7169232
    ],
    [
            83.983655,
            50.715727
    ],
    [
            83.9856291,
            50.715333
    ],
    [
            83.986335,
            50.7141613
    ],
    [
            83.9868122,
            50.7118452
    ],
    [
            83.9872473,
            50.709985
    ],
    [
            83.9892713,
            50.7087056
    ],
    [
            83.9924809,
            50.7078799
    ],
    [
            83.9941609,
            50.7079434
    ],
    [
            83.9976714,
            50.7068954
    ],
    [
            83.9992009,
            50.7067366
    ],
    [
            84.0058708,
            50.7054186
    ],
    [
            84.0093613,
            50.7038511
    ],
    [
            84.0110457,
            50.7026687
    ],
    [
            84.0170217,
            50.699638
    ],
    [
            84.0232179,
            50.697862
    ],
    [
            84.0239497,
            50.696779
    ],
    [
            84.0251732,
            50.6962188
    ],
    [
            84.0259807,
            50.6922839
    ],
    [
            84.0278112,
            50.690902
    ],
    [
            84.0285562,
            50.6893948
    ],
    [
            84.029614,
            50.6880544
    ],
    [
            84.031055,
            50.6869831
    ],
    [
            84.0335741,
            50.6853419
    ],
    [
            84.0346523,
            50.6829906
    ],
    [
            84.0363825,
            50.6815925
    ],
    [
            84.0392159,
            50.6803532
    ],
    [
            84.0395669,
            50.6782876
    ],
    [
            84.0410213,
            50.6769688
    ],
    [
            84.0345525,
            50.6755214
    ],
    [
            84.0031813,
            50.6700411
    ],
    [
            83.9897274,
            50.671809
    ],
    [
            83.9756512,
            50.6722714
    ],
    [
            83.9525413,
            50.6879347
    ],
    [
            83.9148615,
            50.6813954
    ],
    [
            83.9043473,
            50.6745152
    ],
    [
            83.8940047,
            50.6747872
    ],
    [
            83.8834047,
            50.6779962
    ],
    [
            83.8760231,
            50.6709251
    ],
    [
            83.8627194,
            50.6615407
    ],
    [
            83.8549088,
            50.6596635
    ],
    [
            83.8375281,
            50.6574599
    ],
    [
            83.8373564,
            50.6534332
    ],
    [
            83.8319491,
            50.6531066
    ],
    [
            83.822379,
            50.6446983
    ],
    [
            83.8086462,
            50.6426845
    ],
    [
            83.7945698,
            50.639228
    ],
    [
            83.7809442,
            50.6365471
    ],
    [
            83.761053,
            50.6405072
    ],
    [
            83.7536072,
            50.6403303
    ],
    [
            83.745389,
            50.6376494
    ],
    [
            83.7349604,
            50.6234804
    ],
    [
            83.7272143,
            50.619546
    ],
    [
            83.7198971,
            50.617463
    ],
    [
            83.708589,
            50.6164011
    ],
    [
            83.7035893,
            50.6162241
    ],
    [
            83.6557686,
            50.6119327
    ],
    [
            83.6488724,
            50.5925482
    ],
    [
            83.647279,
            50.5927934
    ],
    [
            83.6466894,
            50.5934525
    ],
    [
            83.6443332,
            50.594994
    ],
    [
            83.6434748,
            50.5954371
    ],
    [
            83.6408084,
            50.5957995
    ],
    [
            83.6371389,
            50.5958083
    ],
    [
            83.6332034,
            50.5948165
    ],
    [
            83.6295066,
            50.5936161
    ],
    [
            83.6257513,
            50.5938486
    ],
    [
            83.6237559,
            50.5946791
    ],
    [
            83.6223938,
            50.5958843
    ],
    [
            83.619243,
            50.5966456
    ],
    [
            83.6176609,
            50.5966338
    ],
    [
            83.616543,
            50.5962825
    ],
    [
            83.6161375,
            50.5951437
    ],
    [
            83.616248,
            50.5937834
    ],
    [
            83.6157102,
            50.5931934
    ],
    [
            83.6132107,
            50.5929709
    ],
    [
            83.6118704,
            50.593189
    ],
    [
            83.6097043,
            50.5937857
    ],
    [
            83.6074482,
            50.5950477
    ],
    [
            83.6067589,
            50.5945574
    ],
    [
            83.6065631,
            50.5943139
    ],
    [
            83.6065819,
            50.5939887
    ],
    [
            83.6065041,
            50.5936345
    ],
    [
            83.6059435,
            50.5932173
    ],
    [
            83.6060025,
            50.5929075
    ],
    [
            83.6058818,
            50.5927219
    ],
    [
            83.6054902,
            50.5924784
    ],
    [
            83.6055626,
            50.5921855
    ],
    [
            83.6058925,
            50.5919624
    ],
    [
            83.6062144,
            50.5915538
    ],
    [
            83.6063432,
            50.5911519
    ],
    [
            83.6061822,
            50.5908863
    ],
    [
            83.6056672,
            50.5907024
    ],
    [
            83.6053883,
            50.5904367
    ],
    [
            83.6054849,
            50.5901711
    ],
    [
            83.6056243,
            50.5897215
    ],
    [
            83.6055278,
            50.5892992
    ],
    [
            83.6049913,
            50.5890744
    ],
    [
            83.6043905,
            50.5888769
    ],
    [
            83.6039506,
            50.5887952
    ],
    [
            83.6036609,
            50.5882434
    ],
    [
            83.6031352,
            50.5879914
    ],
    [
            83.6022662,
            50.5879233
    ],
    [
            83.6022018,
            50.5875282
    ],
    [
            83.6026417,
            50.5870037
    ],
    [
            83.6035215,
            50.5864383
    ],
    [
            83.6035751,
            50.586268
    ],
    [
            83.6031755,
            50.5858763
    ],
    [
            83.6025183,
            50.5855459
    ],
    [
            83.6021348,
            50.5850691
    ],
    [
            83.6018692,
            50.5841018
    ],
    [
            83.601601,
            50.5836862
    ],
    [
            83.6014615,
            50.5835159
    ],
    [
            83.6005389,
            50.5835977
    ],
    [
            83.5998522,
            50.5835977
    ],
    [
            83.5994982,
            50.5838089
    ],
    [
            83.5978353,
            50.583802
    ],
    [
            83.5971915,
            50.584095
    ],
    [
            83.5962902,
            50.5841699
    ],
    [
            83.5952174,
            50.5842448
    ],
    [
            83.5946273,
            50.5841972
    ],
    [
            83.5921811,
            50.5837135
    ],
    [
            83.5915374,
            50.58355
    ],
    [
            83.5913013,
            50.5831549
    ],
    [
            83.591076,
            50.5830186
    ],
    [
            83.5904645,
            50.5830323
    ],
    [
            83.5901212,
            50.5831957
    ],
    [
            83.5892843,
            50.5832707
    ],
    [
            83.5884045,
            50.5832707
    ],
    [
            83.5880076,
            50.5834682
    ],
    [
            83.5876643,
            50.5838429
    ],
    [
            83.5874175,
            50.5843266
    ],
    [
            83.587042,
            50.5846944
    ],
    [
            83.5868274,
            50.585001
    ],
    [
            83.5868703,
            50.5856686
    ],
    [
            83.5864626,
            50.5861454
    ],
    [
            83.5858404,
            50.5863021
    ],
    [
            83.5835873,
            50.5872966
    ],
    [
            83.5829221,
            50.5876031
    ],
    [
            83.5817098,
            50.588005
    ],
    [
            83.5815917,
            50.5882298
    ],
    [
            83.5808085,
            50.5882162
    ],
    [
            83.5804974,
            50.5885023
    ],
    [
            83.5794889,
            50.5885023
    ],
    [
            83.5785018,
            50.5887815
    ],
    [
            83.5777508,
            50.5889246
    ],
    [
            83.5770749,
            50.5889723
    ],
    [
            83.5767852,
            50.5891494
    ],
    [
            83.5764312,
            50.5890268
    ],
    [
            83.5761522,
            50.5891902
    ],
    [
            83.5752939,
            50.5893401
    ],
    [
            83.5748111,
            50.5892856
    ],
    [
            83.5738133,
            50.5893878
    ],
    [
            83.5734056,
            50.5895921
    ],
    [
            83.5731589,
            50.5896057
    ],
    [
            83.5728585,
            50.5894423
    ],
    [
            83.572322,
            50.5893537
    ],
    [
            83.5707127,
            50.5895376
    ],
    [
            83.5700582,
            50.5897215
    ],
    [
            83.5692536,
            50.5896807
    ],
    [
            83.5690497,
            50.5897352
    ],
    [
            83.5688351,
            50.5900417
    ],
    [
            83.5685991,
            50.5901983
    ],
    [
            83.5680519,
            50.5904027
    ],
    [
            83.5672902,
            50.5907228
    ],
    [
            83.5666357,
            50.5907773
    ],
    [
            83.5662924,
            50.5909271
    ],
    [
            83.5661851,
            50.59122
    ],
    [
            83.5656916,
            50.5916695
    ],
    [
            83.564726,
            50.5921668
    ],
    [
            83.5642322,
            50.592542
    ],
    [
            83.5630523,
            50.5927661
    ],
    [
            83.5623549,
            50.5927457
    ],
    [
            83.5616361,
            50.5924869
    ],
    [
            83.5607563,
            50.5925414
    ],
    [
            83.5599409,
            50.5923437
    ],
    [
            83.55875,
            50.5925482
    ],
    [
            83.5584389,
            50.5928206
    ],
    [
            83.557999,
            50.5928342
    ],
    [
            83.55713,
            50.5927321
    ],
    [
            83.5566686,
            50.5928887
    ],
    [
            83.5564755,
            50.5932701
    ],
    [
            83.5557889,
            50.5936311
    ],
    [
            83.5550164,
            50.5935834
    ],
    [
            83.5546731,
            50.5936652
    ],
    [
            83.5540723,
            50.5936447
    ],
    [
            83.5530852,
            50.5934745
    ],
    [
            83.5529457,
            50.5936243
    ],
    [
            83.5529994,
            50.5939785
    ],
    [
            83.5526346,
            50.5940466
    ],
    [
            83.5515403,
            50.5939308
    ],
    [
            83.5507141,
            50.5936379
    ],
    [
            83.5493623,
            50.5934608
    ],
    [
            83.5486435,
            50.5936379
    ],
    [
            83.5475813,
            50.5932974
    ],
    [
            83.5466372,
            50.5934064
    ],
    [
            83.5459935,
            50.5925346
    ],
    [
            83.544073,
            50.5918943
    ],
    [
            83.5431396,
            50.591833
    ],
    [
            83.5425173,
            50.5920304
    ],
    [
            83.541541,
            50.5920237
    ],
    [
            83.5411762,
            50.5921736
    ],
    [
            83.5408007,
            50.5921668
    ],
    [
            83.5405003,
            50.5926299
    ],
    [
            83.5392848,
            50.593039
    ],
    [
            83.538612,
            50.5929977
    ],
    [
            83.5371499,
            50.5924853
    ],
    [
            83.5355758,
            50.5928069
    ],
    [
            83.5348784,
            50.5935765
    ],
    [
            83.5345136,
            50.5936652
    ],
    [
            83.5339879,
            50.5936311
    ],
    [
            83.5338806,
            50.5939172
    ],
    [
            83.5333227,
            50.5942032
    ],
    [
            83.5328077,
            50.594176
    ],
    [
            83.5314773,
            50.5947208
    ],
    [
            83.5314773,
            50.5949524
    ],
    [
            83.531016,
            50.5951499
    ],
    [
            83.529471,
            50.595436
    ],
    [
            83.5292565,
            50.5959876
    ],
    [
            83.5279368,
            50.595756
    ],
    [
            83.5276579,
            50.5967027
    ],
    [
            83.527218,
            50.5966278
    ],
    [
            83.5265206,
            50.5971181
    ],
    [
            83.5256087,
            50.5972339
    ],
    [
            83.5252761,
            50.5968593
    ],
    [
            83.5246431,
            50.5973497
    ],
    [
            83.5242246,
            50.5974586
    ],
    [
            83.5236453,
            50.5973224
    ],
    [
            83.5234522,
            50.597445
    ],
    [
            83.5228621,
            50.5972952
    ],
    [
            83.5221969,
            50.5969479
    ],
    [
            83.5207163,
            50.5973633
    ],
    [
            83.5193859,
            50.5977106
    ],
    [
            83.5183346,
            50.5984325
    ],
    [
            83.5181843,
            50.5988956
    ],
    [
            83.5175084,
            50.5992293
    ],
    [
            83.5164248,
            50.5994472
    ],
    [
            83.5158025,
            50.5997605
    ],
    [
            83.5152875,
            50.6007002
    ],
    [
            83.5147725,
            50.6009454
    ],
    [
            83.5139786,
            50.6009318
    ],
    [
            83.5138606,
            50.6014697
    ],
    [
            83.5132276,
            50.6021167
    ],
    [
            83.5128092,
            50.6022324
    ],
    [
            83.5126053,
            50.6022052
    ],
    [
            83.5125624,
            50.6019668
    ],
    [
            83.5122513,
            50.6018987
    ],
    [
            83.5118758,
            50.6021167
    ],
    [
            83.5112106,
            50.6020486
    ],
    [
            83.5105347,
            50.6024299
    ],
    [
            83.5096978,
            50.6025184
    ],
    [
            83.5090219,
            50.6038258
    ],
    [
            83.5084962,
            50.6039688
    ],
    [
            83.5084103,
            50.6043297
    ],
    [
            83.5077988,
            50.6041867
    ],
    [
            83.507595,
            50.6044523
    ],
    [
            83.5081099,
            50.6047996
    ],
    [
            83.5081958,
            50.6051332
    ],
    [
            83.5075735,
            50.6064201
    ],
    [
            83.5069512,
            50.6062431
    ],
    [
            83.5065435,
            50.6060524
    ],
    [
            83.5064684,
            50.6062499
    ],
    [
            83.5066723,
            50.606461
    ],
    [
            83.5070371,
            50.6066789
    ],
    [
            83.5069727,
            50.6069512
    ],
    [
            83.5065435,
            50.6068763
    ],
    [
            83.5060715,
            50.606924
    ],
    [
            83.5056638,
            50.606781
    ],
    [
            83.5055136,
            50.6065836
    ],
    [
            83.5045801,
            50.6067878
    ],
    [
            83.5024237,
            50.6074551
    ],
    [
            83.5005354,
            50.6087079
    ],
    [
            83.4989046,
            50.6091709
    ],
    [
            83.4965657,
            50.6089122
    ],
    [
            83.4954499,
            50.6092322
    ],
    [
            83.4939479,
            50.6092458
    ],
    [
            83.492993,
            50.6085241
    ],
    [
            83.4891843,
            50.6091096
    ],
    [
            83.4888624,
            50.6094228
    ],
    [
            83.4869419,
            50.6104509
    ],
    [
            83.4861802,
            50.6105326
    ],
    [
            83.4850859,
            50.611193
    ],
    [
            83.4838735,
            50.6112066
    ],
    [
            83.4815346,
            50.6123776
    ],
    [
            83.4796356,
            50.6124321
    ],
    [
            83.477168,
            50.6129291
    ],
    [
            83.4764706,
            50.6127248
    ],
    [
            83.4751724,
            50.6131606
    ],
    [
            83.4734236,
            50.6132763
    ],
    [
            83.4722971,
            50.6127248
    ],
    [
            83.4709452,
            50.6122006
    ],
    [
            83.4687136,
            50.6121121
    ],
    [
            83.4652804,
            50.6138005
    ],
    [
            83.4591435,
            50.6158429
    ],
    [
            83.4511077,
            50.6190627
    ],
    [
            83.4505926,
            50.61877
    ],
    [
            83.4489404,
            50.6181506
    ],
    [
            83.4477066,
            50.6173881
    ],
    [
            83.4455286,
            50.6171635
    ],
    [
            83.4435009,
            50.6178374
    ],
    [
            83.4408401,
            50.6183616
    ],
    [
            83.4393595,
            50.6179395
    ],
    [
            83.4381365,
            50.6170001
    ],
    [
            83.4362053,
            50.6163466
    ],
    [
            83.4335231,
            50.6162785
    ],
    [
            83.4283088,
            50.6175583
    ],
    [
            83.4265493,
            50.6183888
    ],
    [
            83.4225583,
            50.6182663
    ],
    [
            83.4205197,
            50.618593
    ],
    [
            83.4189318,
            50.6194644
    ],
    [
            83.417816,
            50.6206896
    ],
    [
            83.4160994,
            50.6213431
    ],
    [
            83.4154128,
            50.6220374
    ],
    [
            83.4148763,
            50.6223233
    ],
    [
            83.4141253,
            50.6224185
    ],
    [
            83.4126448,
            50.6227589
    ],
    [
            83.4101986,
            50.6225275
    ],
    [
            83.407173,
            50.621452
    ],
    [
            83.404448,
            50.6198047
    ],
    [
            83.4025382,
            50.6192601
    ],
    [
            83.4003924,
            50.6192329
    ],
    [
            83.3975385,
            50.6197503
    ],
    [
            83.394792,
            50.6199681
    ],
    [
            83.393483,
            50.6198592
    ],
    [
            83.3920883,
            50.6192193
    ],
    [
            83.3908652,
            50.6187292
    ],
    [
            83.3898996,
            50.6189334
    ],
    [
            83.3887194,
            50.6187428
    ],
    [
            83.3873461,
            50.6185658
    ],
    [
            83.3853291,
            50.6185386
    ],
    [
            83.3831402,
            50.6189744
    ],
    [
            83.3813165,
            50.6194644
    ],
    [
            83.3806084,
            50.6201178
    ],
    [
            83.38019,
            50.6215541
    ],
    [
            83.3797716,
            50.6219999
    ],
    [
            83.3789991,
            50.6223913
    ],
    [
            83.3787524,
            50.622844
    ],
    [
            83.3787416,
            50.6231809
    ],
    [
            83.3789187,
            50.6233919
    ],
    [
            83.3788811,
            50.623562
    ],
    [
            83.3786022,
            50.62395
    ],
    [
            83.3786022,
            50.6241763
    ],
    [
            83.3789213,
            50.6244843
    ],
    [
            83.3787765,
            50.6247889
    ],
    [
            83.3782588,
            50.6250969
    ],
    [
            83.378165,
            50.6251905
    ],
    [
            83.378122,
            50.6254917
    ],
    [
            83.3780443,
            50.6255988
    ],
    [
            83.3773657,
            50.6257197
    ],
    [
            83.3772423,
            50.62582
    ],
    [
            83.3771404,
            50.6259834
    ],
    [
            83.3768265,
            50.6262863
    ],
    [
            83.3761265,
            50.6265636
    ],
    [
            83.3742543,
            50.6267389
    ],
    [
            83.3735141,
            50.6269618
    ],
    [
            83.3723151,
            50.6271745
    ],
    [
            83.370062,
            50.6273463
    ],
    [
            83.3698796,
            50.6275437
    ],
    [
            83.369193,
            50.627639
    ],
    [
            83.3687209,
            50.6275233
    ],
    [
            83.3684741,
            50.6275097
    ],
    [
            83.3683239,
            50.6277274
    ],
    [
            83.3679913,
            50.6278159
    ],
    [
            83.3671867,
            50.6275505
    ],
    [
            83.3666824,
            50.6275777
    ],
    [
            83.3665,
            50.6277887
    ],
    [
            83.3661567,
            50.6277479
    ],
    [
            83.3658134,
            50.6274756
    ],
    [
            83.3642684,
            50.6272442
    ],
    [
            83.3634316,
            50.627006
    ],
    [
            83.3626484,
            50.6270196
    ],
    [
            83.3623158,
            50.6268359
    ],
    [
            83.3624874,
            50.6265568
    ],
    [
            83.3621548,
            50.6260736
    ],
    [
            83.3620797,
            50.6252909
    ],
    [
            83.361597,
            50.6250731
    ],
    [
            83.3611142,
            50.6246101
    ],
    [
            83.3602344,
            50.6242494
    ],
    [
            83.3604275,
            50.6240112
    ],
    [
            83.3603524,
            50.6237458
    ],
    [
            83.3590649,
            50.6229562
    ],
    [
            83.3586894,
            50.6228949
    ],
    [
            83.3578526,
            50.622575
    ],
    [
            83.3573805,
            50.6223027
    ],
    [
            83.3575522,
            50.6218944
    ],
    [
            83.3573054,
            50.6204718
    ],
    [
            83.3567904,
            50.6201587
    ],
    [
            83.3561896,
            50.6195733
    ],
    [
            83.3541726,
            50.6191921
    ],
    [
            83.3537005,
            50.6184977
    ],
    [
            83.3527349,
            50.6184297
    ],
    [
            83.3516191,
            50.6179668
    ],
    [
            83.3517264,
            50.617218
    ],
    [
            83.3513242,
            50.6169898
    ],
    [
            83.3504391,
            50.6171634
    ],
    [
            83.3496183,
            50.6170715
    ],
    [
            83.3493018,
            50.6166256
    ],
    [
            83.348996,
            50.6160946
    ],
    [
            83.3488297,
            50.6159687
    ],
    [
            83.3484006,
            50.6160061
    ],
    [
            83.3476764,
            50.6159346
    ],
    [
            83.3478856,
            50.6156726
    ],
    [
            83.347832,
            50.6155126
    ],
    [
            83.3474779,
            50.6155058
    ],
    [
            83.34722,
            50.61529
    ],
    [
            83.3451819,
            50.615339
    ],
    [
            83.3428929,
            50.6161699
    ],
    [
            83.341074,
            50.6186752
    ],
    [
            83.3391952,
            50.6196957
    ],
    [
            83.3385421,
            50.6205796
    ],
    [
            83.338691,
            50.6212273
    ],
    [
            83.3392811,
            50.6215472
    ],
    [
            83.3393025,
            50.6218263
    ],
    [
            83.3389056,
            50.6220781
    ],
    [
            83.338294,
            50.6218875
    ],
    [
            83.3377468,
            50.6220917
    ],
    [
            83.3373928,
            50.6225205
    ],
    [
            83.3379292,
            50.6228405
    ],
    [
            83.3384013,
            50.6232761
    ],
    [
            83.3383691,
            50.623664
    ],
    [
            83.3378649,
            50.6237934
    ],
    [
            83.3374143,
            50.6238002
    ],
    [
            83.3369851,
            50.6240792
    ],
    [
            83.3372426,
            50.6246238
    ],
    [
            83.3372962,
            50.6250049
    ],
    [
            83.3366525,
            50.625107
    ],
    [
            83.3362985,
            50.6252976
    ],
    [
            83.3362555,
            50.6256311
    ],
    [
            83.3365559,
            50.6258625
    ],
    [
            83.3371138,
            50.6260122
    ],
    [
            83.3382188,
            50.6271626
    ],
    [
            83.3380794,
            50.6277682
    ],
    [
            83.3382189,
            50.6282446
    ],
    [
            83.3390772,
            50.6290885
    ],
    [
            83.3392811,
            50.629878
    ],
    [
            83.3395386,
            50.6304224
    ],
    [
            83.3406436,
            50.6313139
    ],
    [
            83.340987,
            50.6317767
    ],
    [
            83.3409548,
            50.632151
    ],
    [
            83.3408046,
            50.6324504
    ],
    [
            83.3402145,
            50.6324844
    ],
    [
            83.3400214,
            50.6326273
    ],
    [
            83.340193,
            50.633165
    ],
    [
            83.3415769,
            50.6344172
    ],
    [
            83.3417916,
            50.6348322
    ],
    [
            83.3416629,
            50.6352201
    ],
    [
            83.3409977,
            50.6356964
    ],
    [
            83.3405685,
            50.6357917
    ],
    [
            83.3401394,
            50.635676
    ],
    [
            83.3398175,
            50.6358937
    ],
    [
            83.3396566,
            50.6363224
    ],
    [
            83.3401286,
            50.6367511
    ],
    [
            83.3406973,
            50.6367851
    ],
    [
            83.3410942,
            50.6369348
    ],
    [
            83.3410728,
            50.6371526
    ],
    [
            83.3406007,
            50.6377174
    ],
    [
            83.3400965,
            50.6379351
    ],
    [
            83.3394849,
            50.6385883
    ],
    [
            83.3381545,
            50.6388945
    ],
    [
            83.3374357,
            50.6389081
    ],
    [
            83.3370495,
            50.6388401
    ],
    [
            83.336792,
            50.6387108
    ],
    [
            83.3363306,
            50.6387108
    ],
    [
            83.3354509,
            50.6392279
    ],
    [
            83.333863,
            50.6407793
    ],
    [
            83.3334339,
            50.6414393
    ],
    [
            83.3333157,
            50.6423443
    ],
    [
            83.3337986,
            50.6428068
    ],
    [
            83.3340776,
            50.6438002
    ],
    [
            83.3337879,
            50.6444329
    ],
    [
            83.3334982,
            50.6449023
    ],
    [
            83.3338523,
            50.6452901
    ],
    [
            83.3346891,
            50.645916
    ],
    [
            83.3357298,
            50.6462086
    ],
    [
            83.3361161,
            50.646712
    ],
    [
            83.3362716,
            50.647076
    ],
    [
            83.3361697,
            50.6473685
    ],
    [
            83.3357459,
            50.6474162
    ],
    [
            83.3347212,
            50.6471882
    ],
    [
            83.3341955,
            50.647576
    ],
    [
            83.3333265,
            50.6474944
    ],
    [
            83.333187,
            50.6469501
    ],
    [
            83.3338415,
            50.6463514
    ],
    [
            83.3328437,
            50.645399
    ],
    [
            83.3320819,
            50.6442832
    ],
    [
            83.331524,
            50.6440927
    ],
    [
            83.3303009,
            50.6450316
    ],
    [
            83.3281444,
            50.6460521
    ],
    [
            83.3260738,
            50.6460861
    ],
    [
            83.3236812,
            50.6452425
    ],
    [
            83.3223616,
            50.6453786
    ],
    [
            83.3201515,
            50.6463446
    ],
    [
            83.3191966,
            50.6467664
    ],
    [
            83.3175873,
            50.6471338
    ],
    [
            83.316214,
            50.647644
    ],
    [
            83.3106564,
            50.6512835
    ],
    [
            83.3073948,
            50.6526984
    ],
    [
            83.3034037,
            50.6537051
    ],
    [
            83.2970523,
            50.6565893
    ],
    [
            83.2865595,
            50.652195
    ],
    [
            83.2824611,
            50.6508755
    ],
    [
            83.2772254,
            50.6504944
    ],
    [
            83.273835,
            50.6509842
    ],
    [
            83.2715605,
            50.6509026
    ],
    [
            83.2662605,
            50.6497733
    ],
    [
            83.2609175,
            50.648576
    ],
    [
            83.2562397,
            50.650059
    ],
    [
            83.2504462,
            50.6507393
    ],
    [
            83.2432364,
            50.652127
    ],
    [
            83.2334517,
            50.6526168
    ],
    [
            83.2258128,
            50.6534875
    ],
    [
            83.2207058,
            50.6550111
    ],
    [
            83.2163286,
            50.6548208
    ],
    [
            83.2103632,
            50.6523175
    ],
    [
            83.2085608,
            50.6506849
    ],
    [
            83.2075308,
            50.6504128
    ],
    [
            83.2066083,
            50.6492972
    ],
    [
            83.2050632,
            50.6487325
    ],
    [
            83.1999134,
            50.6488073
    ],
    [
            83.1962656,
            50.6481815
    ],
    [
            83.1922959,
            50.646712
    ],
    [
            83.1892704,
            50.646263
    ],
    [
            83.186438,
            50.6468481
    ],
    [
            83.1810521,
            50.6462494
    ],
    [
            83.178048,
            50.6461133
    ],
    [
            83.1744002,
            50.6471882
    ],
    [
            83.1725656,
            50.6465147
    ],
    [
            83.1714283,
            50.6466031
    ],
    [
            83.1690251,
            50.6475352
    ],
    [
            83.1662034,
            50.6478345
    ],
    [
            83.1635749,
            50.6473924
    ],
    [
            83.1628453,
            50.6473855
    ],
    [
            83.1624161,
            50.6469501
    ],
    [
            83.1629847,
            50.6468345
    ],
    [
            83.1632959,
            50.6465759
    ],
    [
            83.1629311,
            50.6462018
    ],
    [
            83.1611608,
            50.6460317
    ],
    [
            83.1604527,
            50.6455078
    ],
    [
            83.159353,
            50.6454182
    ],
    [
            83.15688,
            50.6437185
    ],
    [
            83.1567191,
            50.6432286
    ],
    [
            83.1576096,
            50.6428885
    ],
    [
            83.1585108,
            50.643079
    ],
    [
            83.1587254,
            50.6429633
    ],
    [
            83.1577276,
            50.6420925
    ],
    [
            83.1561934,
            50.6417454
    ],
    [
            83.1548845,
            50.640963
    ],
    [
            83.1547772,
            50.6400784
    ],
    [
            83.1535112,
            50.6392347
    ],
    [
            83.1519126,
            50.6385747
    ],
    [
            83.1509363,
            50.6376154
    ],
    [
            83.1510865,
            50.6367511
    ],
    [
            83.1517195,
            50.6359822
    ],
    [
            83.1528567,
            50.6356283
    ],
    [
            83.153951,
            50.6340429
    ],
    [
            83.1529962,
            50.6314773
    ],
    [
            83.1516551,
            50.631171
    ],
    [
            83.1509684,
            50.630191
    ],
    [
            83.1490587,
            50.6289796
    ],
    [
            83.1491982,
            50.6278975
    ],
    [
            83.1485115,
            50.6275776
    ],
    [
            83.1467306,
            50.6274619
    ],
    [
            83.1442093,
            50.626897
    ],
    [
            83.1421708,
            50.6248824
    ],
    [
            83.1412159,
            50.6228336
    ],
    [
            83.1414627,
            50.6216084
    ],
    [
            83.1401217,
            50.6186407
    ],
    [
            83.1422459,
            50.6167549
    ],
    [
            83.1499814,
            50.6135894
    ],
    [
            83.1497668,
            50.6122482
    ],
    [
            83.149917,
            50.6099878
    ],
    [
            83.1511723,
            50.609484
    ],
    [
            83.1522774,
            50.6085103
    ],
    [
            83.1507002,
            50.6074958
    ],
    [
            83.1493377,
            50.607026
    ],
    [
            83.1487905,
            50.6062226
    ],
    [
            83.1488334,
            50.6044794
    ],
    [
            83.1518267,
            50.6006933
    ],
    [
            83.1549703,
            50.5989908
    ],
    [
            83.1553565,
            50.5979285
    ],
    [
            83.1547879,
            50.5966958
    ],
    [
            83.156129,
            50.5945913
    ],
    [
            83.155099,
            50.5934812
    ],
    [
            83.1552278,
            50.5923846
    ],
    [
            83.1565046,
            50.5916151
    ],
    [
            83.1588327,
            50.5922348
    ],
    [
            83.1615256,
            50.5924187
    ],
    [
            83.1666969,
            50.5927184
    ],
    [
            83.1692826,
            50.5924936
    ],
    [
            83.1711708,
            50.5926434
    ],
    [
            83.1736599,
            50.5922688
    ],
    [
            83.1753122,
            50.5925004
    ],
    [
            83.1771146,
            50.5922348
    ],
    [
            83.1803333,
            50.5923914
    ],
    [
            83.1842171,
            50.5922416
    ],
    [
            83.1873177,
            50.594244
    ],
    [
            83.1904076,
            50.5937877
    ],
    [
            83.1926607,
            50.5932905
    ],
    [
            83.1954825,
            50.5933995
    ],
    [
            83.2043551,
            50.5922825
    ],
    [
            83.2229803,
            50.589776
    ],
    [
            83.2382582,
            50.5847625
    ],
    [
            83.2513045,
            50.5849806
    ],
    [
            83.2858084,
            50.5632858
    ],
    [
            83.3023738,
            50.5563066
    ],
    [
            83.3132743,
            50.5475265
    ],
    [
            83.3163642,
            50.5399993
    ],
    [
            83.3092403,
            50.5221039
    ],
    [
            83.3185958,
            50.5099334
    ],
    [
            83.3066653,
            50.5037104
    ],
    [
            83.3093261,
            50.4915351
    ],
    [
            83.302803,
            50.4835075
    ],
    [
            83.2973956,
            50.4668473
    ],
    [
            83.2820318,
            50.4581052
    ],
    [
            83.2665825,
            50.4552636
    ],
    [
            83.2646083,
            50.4416273
    ],
    [
            83.2549523,
            50.4313222
    ],
    [
            83.2510042,
            50.4256084
    ],
    [
            83.2541369,
            50.420386
    ],
    [
            83.2541369,
            50.4152725
    ],
    [
            83.2579135,
            50.4108696
    ],
    [
            83.249824,
            50.4059329
    ],
    [
            83.2478499,
            50.4050166
    ],
    [
            83.2448244,
            50.4036489
    ],
    [
            83.2415414,
            50.4031702
    ],
    [
            83.239696,
            50.4022265
    ],
    [
            83.2367563,
            50.4014743
    ],
    [
            83.2298255,
            50.3981643
    ],
    [
            83.2257485,
            50.3960441
    ],
    [
            83.222959,
            50.3914752
    ],
    [
            83.2217574,
            50.3905586
    ],
    [
            83.2178736,
            50.3896968
    ],
    [
            83.2138395,
            50.390928
    ],
    [
            83.2109749,
            50.3906749
    ],
    [
            83.2106316,
            50.3901414
    ],
    [
            83.210004,
            50.3895463
    ],
    [
            83.2092959,
            50.3890948
    ],
    [
            83.2081264,
            50.3869537
    ],
    [
            83.2080996,
            50.3864543
    ],
    [
            83.207531,
            50.3855206
    ],
    [
            83.2070106,
            50.3851888
    ],
    [
            83.2069033,
            50.3847681
    ],
    [
            83.2062596,
            50.3839198
    ],
    [
            83.2060933,
            50.383328
    ],
    [
            83.2062703,
            50.3829483
    ],
    [
            83.2065707,
            50.3818092
    ],
    [
            83.2067531,
            50.3812688
    ],
    [
            83.2065439,
            50.3805914
    ],
    [
            83.2062167,
            50.3800509
    ],
    [
            83.2057661,
            50.3795139
    ],
    [
            83.2047254,
            50.3787989
    ],
    [
            83.2039958,
            50.37845
    ],
    [
            83.2032609,
            50.3785663
    ],
    [
            83.20207,
            50.3785902
    ],
    [
            83.2007021,
            50.3785868
    ],
    [
            83.1997204,
            50.3785355
    ],
    [
            83.1988406,
            50.3783542
    ],
    [
            83.1981862,
            50.3780976
    ],
    [
            83.1971937,
            50.3776358
    ],
    [
            83.1960136,
            50.3770439
    ],
    [
            83.1950855,
            50.3767292
    ],
    [
            83.1940019,
            50.3764452
    ],
    [
            83.1929558,
            50.3762229
    ],
    [
            83.1905365,
            50.3762708
    ],
    [
            83.1896889,
            50.3760963
    ],
    [
            83.1891793,
            50.3760279
    ],
    [
            83.1888789,
            50.3762263
    ],
    [
            83.1882781,
            50.3765137
    ],
    [
            83.1875431,
            50.3767873
    ],
    [
            83.1866258,
            50.376931
    ],
    [
            83.1853759,
            50.37676
    ],
    [
            83.1846142,
            50.3764213
    ],
    [
            83.1837934,
            50.3761784
    ],
    [
            83.1830102,
            50.3758568
    ],
    [
            83.1833053,
            50.374969
    ],
    [
            83.1832999,
            50.3745995
    ],
    [
            83.1829834,
            50.3738947
    ],
    [
            83.1828332,
            50.3731249
    ],
    [
            83.1827527,
            50.3723414
    ],
    [
            83.1825435,
            50.3710823
    ],
    [
            83.1822967,
            50.3682527
    ],
    [
            83.1817979,
            50.3651628
    ],
    [
            83.1808484,
            50.3641294
    ],
    [
            83.1800169,
            50.3635134
    ],
    [
            83.1793141,
            50.3619974
    ],
    [
            83.179298,
            50.361159
    ],
    [
            83.1795019,
            50.359198
    ],
    [
            83.1797004,
            50.3584519
    ],
    [
            83.1801455,
            50.3571309
    ],
    [
            83.1805371,
            50.3563334
    ],
    [
            83.1810146,
            50.355666
    ],
    [
            83.1823342,
            50.3543517
    ],
    [
            83.1843566,
            50.3530545
    ],
    [
            83.187291,
            50.3514149
    ],
    [
            83.1884175,
            50.3509118
    ],
    [
            83.18978,
            50.3503915
    ],
    [
            83.1910621,
            50.3497959
    ],
    [
            83.191722,
            50.3493132
    ],
    [
            83.1918829,
            50.3485636
    ],
    [
            83.1918614,
            50.347119
    ],
    [
            83.1920062,
            50.345736
    ],
    [
            83.1924354,
            50.3446987
    ],
    [
            83.1931328,
            50.3436374
    ],
    [
            83.194034,
            50.342874
    ],
    [
            83.1947367,
            50.3425453
    ],
    [
            83.1953162,
            50.3424426
    ],
    [
            83.1953322,
            50.3419462
    ],
    [
            83.1958472,
            50.3407034
    ],
    [
            83.1958633,
            50.3398338
    ],
    [
            83.1958257,
            50.339019
    ],
    [
            83.1953805,
            50.3380911
    ],
    [
            83.1945758,
            50.3372351
    ],
    [
            83.1939106,
            50.3359854
    ],
    [
            83.1934493,
            50.3350438
    ],
    [
            83.1927466,
            50.3343966
    ],
    [
            83.1916522,
            50.3340987
    ],
    [
            83.1909495,
            50.3337255
    ],
    [
            83.1898605,
            50.3330714
    ],
    [
            83.1890504,
            50.3321845
    ],
    [
            83.1882082,
            50.3314586
    ],
    [
            83.1794053,
            50.3233148
    ],
    [
            83.1789224,
            50.3223935
    ],
    [
            83.1784611,
            50.3215989
    ],
    [
            83.1783002,
            50.320859
    ],
    [
            83.1780212,
            50.3191499
    ],
    [
            83.1781392,
            50.3187594
    ],
    [
            83.1780641,
            50.3185128
    ],
    [
            83.17815,
            50.3168926
    ],
    [
            83.1779783,
            50.3159643
    ],
    [
            83.1780373,
            50.3150428
    ],
    [
            83.1778336,
            50.3144399
    ],
    [
            83.1772648,
            50.3139021
    ],
    [
            83.1766372,
            50.3133849
    ],
    [
            83.1760739,
            50.3130046
    ],
    [
            83.1758754,
            50.3124599
    ],
    [
            83.1757682,
            50.3116857
    ],
    [
            83.1754785,
            50.3104319
    ],
    [
            83.1735741,
            50.303213
    ],
    [
            83.1732093,
            50.3025209
    ],
    [
            83.1730216,
            50.3016471
    ],
    [
            83.1725656,
            50.3010098
    ],
    [
            83.1722491,
            50.3003416
    ],
    [
            83.1720239,
            50.2996905
    ],
    [
            83.1720775,
            50.299084
    ],
    [
            83.1722276,
            50.298666
    ],
    [
            83.1724423,
            50.298265
    ],
    [
            83.1732899,
            50.2978333
    ],
    [
            83.1738048,
            50.2972644
    ],
    [
            83.1744592,
            50.2963289
    ],
    [
            83.1747007,
            50.2956264
    ],
    [
            83.1539619,
            50.2913562
    ],
    [
            83.1406313,
            50.2635669
    ],
    [
            83.1387912,
            50.2633251
    ],
    [
            83.1377505,
            50.2628451
    ],
    [
            83.1365596,
            50.2626462
    ],
    [
            83.1353687,
            50.2628656
    ],
    [
            83.1346499,
            50.2633114
    ],
    [
            83.1338667,
            50.2635926
    ],
    [
            83.1328367,
            50.2636681
    ],
    [
            83.1322842,
            50.2637675
    ],
    [
            83.1311685,
            50.2637367
    ],
    [
            83.1307768,
            50.2631674
    ],
    [
            83.1304924,
            50.262545
    ],
    [
            83.1300418,
            50.2612281
    ],
    [
            83.1291192,
            50.2601993
    ],
    [
            83.1283467,
            50.2599798
    ],
    [
            83.1270808,
            50.2598563
    ],
    [
            83.1300043,
            50.2587023
    ],
    [
            83.1309055,
            50.2577969
    ],
    [
            83.1315707,
            50.2567268
    ],
    [
            83.1311415,
            50.2542573
    ],
    [
            83.1331156,
            50.2501824
    ],
    [
            83.1332873,
            50.2493454
    ],
    [
            83.1328367,
            50.2485633
    ],
    [
            83.1330299,
            50.2479047
    ],
    [
            83.1347679,
            50.2459562
    ],
    [
            83.1360124,
            50.2442821
    ],
    [
            83.1392955,
            50.2409886
    ],
    [
            83.1425464,
            50.2397946
    ],
    [
            83.1435227,
            50.2383536
    ],
    [
            83.144102,
            50.2376674
    ],
    [
            83.1450248,
            50.2368302
    ],
    [
            83.1452608,
            50.2363224
    ],
    [
            83.145867,
            50.2355469
    ],
    [
            83.1470257,
            50.2336803
    ],
    [
            83.1471598,
            50.2328773
    ],
    [
            83.1591439,
            50.2283956
    ],
    [
            83.1708923,
            50.2212744
    ],
    [
            83.2180935,
            50.1907102
    ],
    [
            83.2145691,
            50.1752491
    ],
    [
            83.2130992,
            50.1705696
    ],
    [
            83.2132172,
            50.164948
    ],
    [
            83.2234954,
            50.1626044
    ],
    [
            83.2481288,
            50.1441537
    ],
    [
            83.2758835,
            50.1256195
    ],
    [
            83.2653651,
            50.1112875
    ],
    [
            83.2636395,
            50.0995871
    ],
    [
            83.2518885,
            50.0817674
    ],
    [
            83.2559972,
            50.0761775
    ],
    [
            83.2513133,
            50.0664729
    ],
    [
            83.2517241,
            50.0598263
    ],
    [
            83.256819,
            50.0535479
    ],
    [
            83.2667621,
            50.0357638
    ],
    [
            83.2679947,
            50.0160196
    ],
    [
            83.3045624,
            50.0038732
    ],
    [
            83.3574829,
            49.9919353
    ],
    [
            83.3713704,
            49.9863349
    ],
    [
            83.4067056,
            49.9895579
    ],
    [
            83.4049799,
            49.9822664
    ],
    [
            83.3878054,
            49.9704815
    ],
    [
            83.3857926,
            49.9587165
    ],
    [
            83.3581404,
            49.9616807
    ],
    [
            83.3477042,
            49.9583501
    ],
    [
            83.3279823,
            49.9503138
    ],
    [
            83.3216137,
            49.9462157
    ],
    [
            83.3199292,
            49.9448673
    ],
    [
            83.3107462,
            49.9308518
    ],
    [
            83.3296617,
            49.917715
    ],
    [
            83.3270732,
            49.9166071
    ],
    [
            83.3220452,
            49.9154298
    ],
    [
            83.3205249,
            49.9157076
    ],
    [
            83.3181624,
            49.9152843
    ],
    [
            83.3161902,
            49.9139085
    ],
    [
            83.3143002,
            49.911342
    ],
    [
            83.3128622,
            49.9103762
    ],
    [
            83.3116501,
            49.9102439
    ],
    [
            83.307151,
            49.9081271
    ],
    [
            83.3041105,
            49.9079948
    ],
    [
            83.3013577,
            49.9063808
    ],
    [
            83.3007722,
            49.9035393
    ],
    [
            83.300536,
            49.9029141
    ],
    [
            83.3001045,
            49.9002147
    ],
    [
            83.2988923,
            49.8964036
    ],
    [
            83.2972488,
            49.8950273
    ],
    [
            83.275226,
            49.8928833
    ],
    [
            83.2611743,
            49.903417
    ],
    [
            83.2498752,
            49.8927246
    ],
    [
            83.2418632,
            49.8905805
    ],
    [
            83.2388637,
            49.8917452
    ],
    [
            83.2355357,
            49.8916393
    ],
    [
            83.2318378,
            49.8986798
    ],
    [
            83.2287152,
            49.9001089
    ],
    [
            83.223908,
            49.9066189
    ],
    [
            83.2119105,
            49.9065396
    ],
    [
            83.2052955,
            49.9108791
    ],
    [
            83.2000362,
            49.9195041
    ],
    [
            83.2075552,
            49.9230224
    ],
    [
            83.2100616,
            49.9304286
    ],
    [
            83.1974888,
            49.9348982
    ],
    [
            83.1988036,
            49.9386534
    ],
    [
            83.1859844,
            49.9472734
    ],
    [
            83.1687688,
            49.9531426
    ],
    [
            83.1584969,
            49.9537771
    ],
    [
            83.1571821,
            49.9597248
    ],
    [
            83.1536898,
            49.9629891
    ],
    [
            83.1439931,
            49.9612314
    ],
    [
            83.1420209,
            49.9606763
    ],
    [
            83.1398433,
            49.9599891
    ],
    [
            83.1394735,
            49.9590111
    ],
    [
            83.1384052,
            49.9582974
    ],
    [
            83.1369466,
            49.9567642
    ],
    [
            83.134276,
            49.9559844
    ],
    [
            83.1325708,
            49.955231
    ],
    [
            83.128873,
            49.9554028
    ],
    [
            83.1213745,
            49.9525213
    ],
    [
            83.1188169,
            49.9533409
    ],
    [
            83.1160537,
            49.9535788
    ],
    [
            83.1153039,
            49.9533276
    ],
    [
            83.1144719,
            49.9513846
    ],
    [
            83.1130544,
            49.9510805
    ],
    [
            83.1107124,
            49.9511995
    ],
    [
            83.1089456,
            49.9507633
    ],
    [
            83.1099728,
            49.9511334
    ],
    [
            83.1062339,
            49.9499966
    ],
    [
            83.1050423,
            49.9499436
    ],
    [
            83.1010569,
            49.9520851
    ],
    [
            83.0951815,
            49.9533805
    ],
    [
            83.0924697,
            49.9538299
    ],
    [
            83.0908673,
            49.9532483
    ],
    [
            83.0881555,
            49.9536185
    ],
    [
            83.084129,
            49.9546494
    ],
    [
            83.0805955,
            49.9564999
    ],
    [
            83.0774317,
            49.9569757
    ],
    [
            83.0725012,
            49.9563677
    ],
    [
            83.0689266,
            49.9564734
    ],
    [
            83.0683103,
            49.9554425
    ],
    [
            83.0665025,
            49.9553368
    ],
    [
            83.0651055,
            49.9562091
    ],
    [
            83.059189,
            49.9569493
    ],
    [
            83.0561485,
            49.9569757
    ],
    [
            83.0515467,
            49.9579339
    ],
    [
            83.0364982,
            49.9540839
    ],
    [
            83.043109,
            49.9442793
    ],
    [
            83.0561694,
            49.9341873
    ],
    [
            83.0641105,
            49.9243008
    ],
    [
            83.064957,
            49.9179423
    ],
    [
            83.0508082,
            49.9133481
    ],
    [
            83.050647,
            49.9100514
    ],
    [
            83.0349664,
            49.9080006
    ],
    [
            83.025937,
            49.9101228
    ],
    [
            83.0251021,
            49.9091701
    ],
    [
            83.0226268,
            49.9084438
    ],
    [
            83.021997,
            49.9076231
    ],
    [
            83.0216308,
            49.906727
    ],
    [
            83.0209424,
            49.9066326
    ],
    [
            83.0206202,
            49.9060949
    ],
    [
            83.0201075,
            49.9058214
    ],
    [
            83.0192287,
            49.9056704
    ],
    [
            83.0188772,
            49.9053025
    ],
    [
            83.0181155,
            49.9049535
    ],
    [
            83.0179471,
            49.9045714
    ],
    [
            83.017515,
            49.9042271
    ],
    [
            83.0151861,
            49.9043969
    ],
    [
            83.0134871,
            49.905095
    ],
    [
            83.0097668,
            49.9059251
    ],
    [
            83.0074232,
            49.9069251
    ],
    [
            83.0045524,
            49.9067364
    ],
    [
            83.0030292,
            49.9076231
    ],
    [
            83.0020039,
            49.9078118
    ],
    [
            83.0005685,
            49.9074533
    ],
    [
            82.998928,
            49.9076608
    ],
    [
            82.9969946,
            49.9088682
    ],
    [
            82.9950319,
            49.9092267
    ],
    [
            82.9935086,
            49.9088305
    ],
    [
            82.9923662,
            49.9093587
    ],
    [
            82.9906671,
            49.909189
    ],
    [
            82.987767,
            49.9105095
    ],
    [
            82.9851892,
            49.9108113
    ],
    [
            82.9852478,
            49.9106982
    ],
    [
            82.9770601,
            49.9072363
    ],
    [
            82.9743065,
            49.9052459
    ],
    [
            82.973706,
            49.9040384
    ],
    [
            82.9731494,
            49.9035573
    ],
    [
            82.9721827,
            49.9032743
    ],
    [
            82.9715382,
            49.9023781
    ],
    [
            82.9712013,
            49.9012366
    ],
    [
            82.9690482,
            49.900812
    ],
    [
            82.9678325,
            49.8999346
    ],
    [
            82.9668365,
            49.899812
    ],
    [
            82.9656355,
            49.8990289
    ],
    [
            82.9641854,
            49.8986327
    ],
    [
            82.9636435,
            49.8978213
    ],
    [
            82.963043,
            49.8977552
    ],
    [
            82.9626768,
            49.8982741
    ],
    [
            82.9616955,
            49.8984534
    ],
    [
            82.9612268,
            49.8983968
    ],
    [
            82.9610364,
            49.8980854
    ],
    [
            82.9598939,
            49.8979439
    ],
    [
            82.9591908,
            49.8971325
    ],
    [
            82.9579605,
            49.8968117
    ],
    [
            82.9562761,
            49.8954531
    ],
    [
            82.9545038,
            49.8955191
    ],
    [
            82.9536836,
            49.8945756
    ],
    [
            82.9518381,
            49.8948492
    ],
    [
            82.9503734,
            49.8942359
    ],
    [
            82.9488647,
            49.8944152
    ],
    [
            82.9467702,
            49.8933489
    ],
    [
            82.9455399,
            49.8935188
    ],
    [
            82.9431744,
            49.8930658
    ],
    [
            82.9418831,
            49.8929561
    ],
    [
            82.9414534,
            49.8929196
    ],
    [
            82.9408529,
            49.8925657
    ],
    [
            82.9403622,
            49.8918109
    ],
    [
            82.939725,
            49.8918958
    ],
    [
            82.9387071,
            49.8915183
    ],
    [
            82.9382384,
            49.8906361
    ],
    [
            82.9376179,
            49.889984
    ],
    [
            82.935331,
            49.889315
    ],
    [
            82.9334488,
            49.8889941
    ],
    [
            82.9321306,
            49.8890083
    ],
    [
            82.9315667,
            49.8890885
    ],
    [
            82.9310174,
            49.889013
    ],
    [
            82.9304901,
            49.8887724
    ],
    [
            82.9299262,
            49.8883548
    ],
    [
            82.9293733,
            49.8882321
    ],
    [
            82.9288785,
            49.8880447
    ],
    [
            82.9294136,
            49.8873427
    ],
    [
            82.9288131,
            49.8869228
    ],
    [
            82.9278464,
            49.8866019
    ],
    [
            82.9271872,
            49.886734
    ],
    [
            82.9237306,
            49.8854883
    ],
    [
            82.9224416,
            49.8839594
    ],
    [
            82.9209476,
            49.8819209
    ],
    [
            82.9193658,
            49.8805052
    ],
    [
            82.9150888,
            49.8790328
    ],
    [
            82.9117786,
            49.8782399
    ],
    [
            82.9101089,
            49.8764843
    ],
    [
            82.9095523,
            49.8750684
    ],
    [
            82.9079704,
            49.8740112
    ],
    [
            82.9077672,
            49.8740526
    ],
    [
            82.7224742,
            49.9117927
    ],
    [
            82.683818,
            49.95477
    ],
    [
            82.6775939,
            49.9602728
    ],
    [
            82.6622083,
            49.9738722
    ],
    [
            82.7149485,
            50.0024984
    ],
    [
            82.7928187,
            50.0129176
    ],
    [
            82.800814,
            50.0265045
    ],
    [
            82.7713396,
            50.0377452
    ],
    [
            82.7306988,
            50.0401708
    ],
    [
            82.6449968,
            50.0300268
    ],
    [
            82.6102998,
            50.0235334
    ],
    [
            82.5826187,
            50.0126757
    ],
    [
            82.5795551,
            50.0125962
    ],
    [
            82.5785603,
            50.0136114
    ],
    [
            82.5701249,
            50.0222187
    ],
    [
            82.5680925,
            50.0242922
    ],
    [
            82.5456478,
            50.0225
    ],
    [
            82.5173266,
            50.0411047
    ],
    [
            82.5145266,
            50.0390705
    ],
    [
            82.4934196,
            50.0237334
    ],
    [
            82.4741509,
            50.0290618
    ],
    [
            82.4636227,
            50.0364937
    ],
    [
            82.4029615,
            50.0606007
    ],
    [
            82.3361339,
            50.0641437
    ],
    [
            82.2757954,
            50.1419468
    ],
    [
            82.2685704,
            50.1728001
    ],
    [
            82.2586689,
            50.2150506
    ],
    [
            82.225351,
            50.2317453
    ],
    [
            82.1999001,
            50.2213463
    ],
    [
            82.17704,
            50.2230256
    ],
    [
            82.1643018,
            50.2075053
    ],
    [
            82.1442604,
            50.203605
    ],
    [
            82.0872191,
            50.2079468
    ],
    [
            82.0395898,
            50.2029732
    ]
]