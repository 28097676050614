import React, {useEffect, useRef, useState} from 'react';
import {IFilterOption} from "../../types";
import {FilterIcon, HelicopterBlack} from "../Icons";

interface IFilter {
    options: IFilterOption[];
    onSelect: (option: IFilterOption) => void;
    selectedOpt: IFilterOption | null;
    isFire?: boolean;
    isHelicopter?: boolean;
}

const Filter: React.FC<IFilter> = ({options, onSelect, selectedOpt, isFire, isHelicopter,}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const filterRef = useRef<any>(null);

    const handleClickOutside = (event: any) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`filter-wrapper ${isHelicopter ? "helicopter" : ""}`} ref={filterRef}>
            {isHelicopter ? (
                <div
                    className={selectedOpt ? "helicopter-selected" : "filter"}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selectedOpt ? (
                        <div className="filter-item fires-item">
                            <div className="filter-item-text">{selectedOpt.label}</div>
                            <div className="filter filter-item-icon"><HelicopterBlack /></div>
                        </div>
                    ) :
                        <HelicopterBlack />
                    }
                </div>
            ) : (
                <div
                    className={selectedOpt ? isFire ? "filter fires" : "filter floods" : "filter"}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selectedOpt ?
                        selectedOpt.icon
                    :
                        <FilterIcon />
                    }
                </div>
            )}

            <div className="filter-items">
                {isOpen && options.map((option) => (
                    <div
                        className={isFire ? "filter-item fires-item" : "filter-item floods-item"}
                        key={option.label}
                        onClick={() => {
                            setIsOpen(false);
                            onSelect(option);
                        }}
                    >
                        <div className="filter-item-text">{option.label}</div>
                        <div className="filter filter-item-icon">{option.icon}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Filter;