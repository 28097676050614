import React, {useState} from 'react';
import Dropdown from "./Dropdown";

const YearPicker = () => {
    const [selectedYear, setSelectedYear] = useState<{value: string, label: string}>(
        {label: String(new Date().getFullYear()), value: String(new Date().getFullYear())}
    );
    const years =
        Array.from(new Array(6), (_, index) => {
            const year = new Date().getFullYear() - 5 + index;
            return { value: String(year), label: String(year) }
        });

    return (
        <div>
            <Dropdown
                options={years}
                onSelect={(e) => console.log(e)}
                selectedOpt={selectedYear}
            />
        </div>
    );
};

export default YearPicker;