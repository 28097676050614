import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import CardCarousel from "../CardCarousel/CardCarousel";

const costFormatter = (price: number) => {
    const format = (number: number) =>
        number.toLocaleString("ru-RU", { maximumFractionDigits: 0 });

    const trillion = 1_000_000_000_000;
    const billion = 1_000_000_000;
    const million = 1_000_000;
    const thousand = 1_000;

    if (price > trillion) {
        return `${format(price / trillion)} трлн.`;
    }

    if (price > billion) {
        return `${format(price / billion)} млрд.`;
    }

    if (price > million) {
        return `${format(price / million)} млн.`;
    }

    if (price > thousand) {
        return `${format(price / thousand)} тыс.`;
    }

    return format(price);
};

const ConstructionOfAntennas: React.FC = () => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipContent, setTooltipContent] = useState("");
    const [tooltipPos, setTooltipPos] = useState({
        top: "0px",
        left: "0px",
    });

    const [info, setInfo] = useState({
        onProductionPSD: {
            count: 0,
            price: 0,
        },
        producedPSD: {
            count: 0,
            price: 0,
        },
        waitingFinances: {
            count: 0,
            price: 0,
        },

        builded: {
            count: 0,
            price: 0,
        },
        building: {
            count: 0,
            price: 0,
        },
        waitingFinances2: {
            count: 0,
            price: 0,
        },
    });

    const totalCountOfGos = useMemo(() => {
        return [
            info.onProductionPSD.count,
            info.producedPSD.count,
            info.waitingFinances.count,
        ].reduce((acc, crnt) => acc + crnt, 0);
    }, [info]);

    const totalCountOfPrivate = useMemo(() => {
        return [
            info.builded.count,
            info.building.count,
            info.waitingFinances2.count,
        ].reduce((acc, crnt) => acc + crnt, 0);
    }, [info]);

    const getPercentage = (amount: number, total: number) => {
        return (amount / total || 0) * 100;
    };

    const colors = ["#e17055", "#0984e3", "#fdcb6e", "#6c5ce7", "#00b894"];

    const gosItems = [
        {
            name: "Общее количество",
            count() {
                return totalCountOfGos;
            },
            price() {
                return [
                    info.onProductionPSD.price,
                    info.producedPSD.price,
                    info.waitingFinances.price,
                ].reduce((acc, crnt) => acc + crnt, 0);
            },
        },
        {
            name: "На разработке ПСД",
            count() {
                return info.onProductionPSD.count;
            },
            price() {
                return info.onProductionPSD.price;
            },
        },
        {
            name: "Разработано ПСД",
            count() {
                return info.producedPSD.count;
            },
            price() {
                return info.producedPSD.price;
            },
        },
        {
            name: "Ожидает финансирование",
            count() {
                return info.waitingFinances.count;
            },
            price() {
                return info.waitingFinances.price;
            },
        },
    ];

    const privateItems = [
        {
            name: "Общее количество",
            count() {
                return totalCountOfPrivate;
            },
            price() {
                return [
                    info.builded.price,
                    info.building.price,
                    info.waitingFinances2.price,
                ].reduce((acc, crnt) => acc + crnt, 0);
            },
        },
        {
            name: "Построено",
            count() {
                return info.builded.count;
            },
            price() {
                return info.builded.price;
            },
        },
        {
            name: "Строятся",
            count() {
                return info.building.count;
            },
            price() {
                return info.building.price;
            },
        },
        {
            name: "Ожидает финансирование",
            count() {
                return info.waitingFinances2.count;
            },
            price() {
                return info.waitingFinances2.price;
            },
        },
    ];

    const onEnterProgress = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item: (typeof gosItems)[number] | (typeof privateItems)[number],
        privateItem?: boolean
    ) => {
        const percent = (
            !privateItem
                ? getPercentage(item.count(), totalCountOfGos)
                : getPercentage(item.count(), totalCountOfPrivate)
        ).toLocaleString("ru-RU", { maximumFractionDigits: 2 });

        setShowTooltip(true);
        setTooltipContent(`${item.name} ${percent}%`);
        setTooltipPos({
            left: `${event.clientX + 10}px`,
            top: `${event.clientY - 30}px`,
        });
    };

    const onLeaveProgress = () => {
        setShowTooltip(false);
        setTooltipContent("");
    };

    const fetchInfo = async () => {
        await new Promise((res) => setTimeout(res, 500));

        setInfo({
            onProductionPSD: {
                count: 18,
                price: 10_000_000_000,
            },
            producedPSD: {
                count: 33,
                price: 10_000_000_000,
            },
            waitingFinances: {
                count: 5,
                price: 10_000_000_000,
            },

            builded: {
                count: 5,
                price: 10_000_000_000,
            },
            building: {
                count: 8,
                price: 10_000_000_000,
            },
            waitingFinances2: {
                count: 10,
                price: 10_000_000_000,
            },
        });
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    return (
        <>
            <CardCarousel
                titles={{
                    0: "АМС гос.",
                    1: "АМС частн.",
                }}
            >
                <div className="internet-page-antennas-info-slide">
                    <div className="internet-page-antennas-info-progress">
                        {gosItems.slice(1).map((item, index) => {
                            return (
                                <div
                                    className="internet-page-antennas-info-progress-item"
                                    style={{
                                        width: `${getPercentage(
                                            item.count(),
                                            totalCountOfGos
                                        )}%`,
                                        backgroundColor: colors[index],
                                    }}
                                    onMouseMove={(event) =>
                                        onEnterProgress(event, item)
                                    }
                                    onMouseLeave={onLeaveProgress}
                                />
                            );
                        })}
                    </div>

                    <div className="internet-page-antennas-info-legends">
                        {gosItems.map((item, index) => {
                            return (
                                <div
                                    className="internet-page-antennas-info-legends-item"
                                    style={{
                                        fontWeight: !index ? "700" : undefined,
                                        fontSize: !index ? "14px" : "12px",
                                    }}
                                >
                                    {Boolean(index) && (
                                        <div
                                            className="internet-page-antennas-info-legends-item-color"
                                            style={{
                                                backgroundColor:
                                                    colors[index - 1],
                                            }}
                                        />
                                    )}

                                    <p>{item.name}</p>

                                    <div className="internet-page-antennas-info-legends-item-end">
                                        {`${item.count()} шт.`}

                                        <div
                                            className="price"
                                            style={{
                                                backgroundColor: index
                                                    ? "rgba(0, 0, 0, 0.15)"
                                                    : "rgba(0, 0, 0, 0.55)",
                                            }}
                                        />

                                        {costFormatter(item.price())}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="internet-page-antennas-info-slide">
                    <div className="internet-page-antennas-info-progress">
                        {privateItems.slice(1).map((item, index) => {
                            return (
                                <div
                                    className="internet-page-antennas-info-progress-item"
                                    style={{
                                        width: `${getPercentage(
                                            item.count(),
                                            totalCountOfPrivate
                                        )}%`,
                                        backgroundColor: colors[index],
                                    }}
                                    onMouseEnter={(event) =>
                                        onEnterProgress(event, item, true)
                                    }
                                    onMouseLeave={onLeaveProgress}
                                />
                            );
                        })}
                    </div>

                    <div className="internet-page-antennas-info-legends">
                        {privateItems.map((item, index) => {
                            return (
                                <div
                                    className="internet-page-antennas-info-legends-item"
                                    style={{
                                        fontWeight: !index ? "700" : undefined,
                                        fontSize: !index ? "14px" : "12px",
                                    }}
                                >
                                    {Boolean(index) && (
                                        <div
                                            className="internet-page-antennas-info-legends-item-color"
                                            style={{
                                                backgroundColor:
                                                    colors[index - 1],
                                            }}
                                        />
                                    )}

                                    <p>{item.name}</p>

                                    <div className="internet-page-antennas-info-legends-item-end">
                                        {`${item.count()} шт.`}

                                        <div
                                            className="price"
                                            style={{
                                                backgroundColor: index
                                                    ? "rgba(0, 0, 0, 0.15)"
                                                    : "rgba(0, 0, 0, 0.55)",
                                            }}
                                        />

                                        {costFormatter(item.price())}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </CardCarousel>

            {ReactDOM.createPortal(
                <div
                    className="internet-page-antennas-info-tooltip"
                    style={{
                        top: tooltipPos.top,
                        left: tooltipPos.left,
                        visibility: showTooltip ? "visible" : "hidden",
                    }}
                >
                    {tooltipContent}
                </div>,
                document.getElementById("modal-root")!
            )}
        </>
    );
};

export default ConstructionOfAntennas;
