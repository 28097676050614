import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {login} from "../../services/auth";

const AuthorizationPage = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(false);

    const handleSubmit = async (e:React.FormEvent) => {
        e.preventDefault();

        const response = await login(password, userName);

        if (!response.isError) {
            setIsError(false);
            localStorage.setItem("token", response.data.auth_token)
            navigate("/");
        } else {
            setIsError(true);
        }
    };

    return (
        <div className="authorization">
            <form onSubmit={handleSubmit}>
                <div className="authorization-form">
                    <p className="authorization-title">Авторизация</p>

                    <label htmlFor="usetName">Логин</label>
                    <input
                        name="usetName"
                        type="text"
                        className={isError ? "authorization-input input-error" : "authorization-input"}
                        onChange={(e) => setUserName(e.target.value)}
                    />

                    <label htmlFor="password">Пароль</label>
                    <input
                        name="password"
                        type="password"
                        className={isError ? "authorization-input input-error" : "authorization-input"}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    {isError && <div className="authorization-error">Неверный логин или пароль</div>}

                    <button type="submit" className="authorization-button">
                        Войти
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AuthorizationPage;