import React from 'react';

interface IScale {
    waterLevel: number;
    criticalWaterLevel: number;
}

const Scale: React.FC<IScale> = ({ waterLevel, criticalWaterLevel }) => {
    const calculateWaterLevelPercentage = () => {
        const fullScaleValue = criticalWaterLevel * 100 / 90;
        const currentWaterLevel = waterLevel * 100 / fullScaleValue;

        if (currentWaterLevel > 100) {
            return 100;
        }

        return currentWaterLevel;
    };

    return (
        <div className="scale">
            {criticalWaterLevel ? (
                <>
                    <div className="green" />
                    <div className="orange" />
                    <div className="red" />
                    <div className="scale-slider" style={{left: calculateWaterLevelPercentage() + "%"}} />
                </>
            ) : null}
        </div>
    );
};

export default Scale;