import React, {useEffect, useRef, useState} from 'react';
import {ITenderDistrictInfo} from "../../../types/tenders";
import {divIcon, DivIconOptions, LatLngExpression} from "leaflet";
import ReactDOMServer from "react-dom/server";
import {Marker} from "react-leaflet";
import {getSumString} from "../../../utils";
import Modal from "../../UI/Modal";
import TendersPointModal from "../../TendersPages/modals/TendersPointModal";
import {useParams} from "react-router-dom";

const TenderInfoMarker: React.FC<{point: ITenderDistrictInfo}> = ({point}) => {
    const params = useParams();
    const districtId = params.id;
    const markerRef = useRef<any>(null);
    const [isModal, setIsModal] = useState<boolean>(false);
    const [chosenLocality, setChosenLocality] = useState<ITenderDistrictInfo | null>(null);

    const createFireMarker = () => {
        const options: DivIconOptions = {
            className: "marker-wrapper",
            html: ReactDOMServer.renderToString(
                <div className="big-count-marker">
                    <span>{point.total.total_count}</span>
                    <div className="big-count-marker-sum">{getSumString(point.total.total_sum) + " ₸"}</div>
                </div>
            )}
        return divIcon(options);
    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    const showModal = () => {
        setIsModal(true);
        setChosenLocality(point);
    };

    const getCoords = () => {
        if (districtId) {
            return [point.latitude, point.longitude];
        } else {
            return [point.geo_center[1], point.geo_center[0]];
        }
    };

    return (
        <>
            <Marker
                position={getCoords() as LatLngExpression}
                icon={createFireMarker()}
                ref={markerRef}
                eventHandlers={{
                    click: showModal,
                }}
            />

            {chosenLocality &&
                <Modal
                    isOpen={isModal}
                    onClose={() => {
                        setIsModal(false);
                        setChosenLocality(null);
                    }}
                    title={chosenLocality.name}
                    bigModal
                >
                    <TendersPointModal id={!districtId ? chosenLocality.id : undefined} county={districtId ? chosenLocality.id : undefined} />
                </Modal>
            }
        </>
    );
};

export default TenderInfoMarker;