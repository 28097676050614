import L from 'leaflet';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useMap} from "react-leaflet";
import {vko} from "../../coordinates/vko_coordinates";
import {getDistrictCoordinates, getDistrictCoordinatesWithNames, isMobileDevice, swapCoordinates} from "../../utils";
import {useNavigate} from "react-router-dom";
import {AppDataContext} from "../../contexts/AppDataContext";
import {getDistrictCounties} from "../../services/fires";
import {ICounty} from "../../types";

interface IMapController {
    districtId?: number;
    regionOnly?: boolean;
}

const vkoCoords = swapCoordinates(vko);

const MapController: React.FC<IMapController> = ({ districtId, regionOnly }) => {
    const map = useMap();
    const mapRef = useRef(map);
    const districtCoordinates = getDistrictCoordinatesWithNames();
    const navigate = useNavigate();
    const {districts, districtsData} = useContext(AppDataContext);
    const isMobile = isMobileDevice();
    const [isPolygon, setIsPolygon] = useState<boolean>(false);
    const [counties, setCounties] = useState<ICounty[]>([]);
    let isCircle = false;
    const previousPolygonRef = useRef<L.Polygon | null>(null);

    useEffect(() => {
        mapRef.current = map;
        map.attributionControl.setPrefix("");

        return () => {
            if (mapRef.current) {
                mapRef.current.eachLayer((layer) => {
                    if (layer instanceof L.Polygon) {
                        mapRef.current.removeLayer(layer);
                    }
                });
            }
        };
    }, [map]);
    
    useEffect(() => {
        if (districtId) {
            const coords = getDistrictCoordinates(districtId);
            const districtCoords = swapCoordinates(coords);

            if (previousPolygonRef.current) {
                map.removeLayer(previousPolygonRef.current);
            }

            if (regionOnly) {
                const polygon = L.polygon(districtCoords, { color: 'white', fillColor: '#E0C225CC', fillOpacity: 1 }).addTo(map);
                previousPolygonRef.current = polygon;
                districtCoordinates.forEach((district: any) => {
                    const polygon = L.polygon(swapCoordinates(district.coords), { color: '#BDBDBD', fillOpacity: 0, weight: 1 }).addTo(map);
                    drawPolygons(polygon, district);
                });

                const mapPolygon = L.polygon(vkoCoords);
                const bounds = mapPolygon.getBounds();
                map.fitBounds(bounds, {paddingBottomRight: [185, 20], paddingTopLeft: [326, 50]});
            } else {
                const polygon = L.polygon(districtCoords, { color: 'white', fillColor: '#3880CE', fillOpacity: 1 }).addTo(map);
                const bounds = polygon.getBounds();
                map.fitBounds(bounds, {paddingBottomRight: [185, 100], paddingTopLeft: [326, 100]});
    
                fetchCounties(String(districtId));
            }
        } else {
            if (!isPolygon) {
                setIsPolygon(true);
                const polygon = L.polygon(vkoCoords, { color: 'white', fillColor: '#3880CE', fillOpacity: 1, weight: 3 });
                const bounds = polygon.getBounds();
                map.fitBounds(bounds, {paddingBottomRight: [185, 20], paddingTopLeft: [326, 50]});

                if (!isCircle) {
                    isCircle = true;
                    const overlayPane = map.getPane('overlayPane');
                    const div = L.DomUtil.create('div', 'blurred-overlay', overlayPane);

                    const topLeft = map.latLngToLayerPoint(bounds.getNorthWest());
                    const bottomRight = map.latLngToLayerPoint(bounds.getSouthEast());
                    const size = bottomRight.subtract(topLeft);

                    L.DomUtil.setPosition(div, topLeft);
                    div.style.width = `${size.x}px`;
                    div.style.height = `${size.y}px`;
                }

                if (regionOnly) {
                    setTimeout(() => {
                        districtCoordinates.forEach((district) => {
                            const polygon = L.polygon(swapCoordinates(district.coords), { color: '#BDBDBD', fillOpacity: 0, weight: 1 }).addTo(map);
                            drawPolygons(polygon, district);
                        });
                    }, 50);
                }
            }
        }
    }, [districts, districtId, districtsData]);

    useEffect(() => {
        if (!districtId && (districts.length || districtsData?.length)) {
            districtCoordinates.forEach((district) => {
                const polygon = L.polygon(swapCoordinates(district.coords), { color: '#BDBDBD', fillOpacity: 0, weight: 1 }).addTo(map);
                drawPolygons(polygon, district)
            });
        }
    }, [districts, districtsData]);

    useEffect(() => {
        if (counties) {
            counties.forEach((county) => {
                if (county.boundary) {
                    for (let i = 0; i < county.boundary.length; i++) {
                        const polygon = L.polygon(swapCoordinates(county.boundary[i]), { color: '#BDBDBD', fillOpacity: 0, weight: 1 }).addTo(map);
                        drawPolygons(polygon, county);
                    }
                }
            })
        }
    }, [counties]);

    const drawPolygons = (polygon: L.Polygon, item: any) => {
        if (isMobile) {
            const redirectToDistrict = (name: string) => {
                if (districtId && regionOnly) {
                    districts.forEach((dist) => {
                        if (name === dist.name) {
                            const currentPath = window.location.pathname;
                            const newPath = currentPath.replace(/\/[^/]+$/, `/${dist.id}`);
                            navigate(newPath);
                        }
                    })
                } else {
                    if (districts.length) {
                        districts.forEach((dist) => {
                            if (name === dist.name) {
                                navigate(`district/${dist.id}`);
                            }
                        });
                    } else if (districtsData?.length) {
                        districtsData.forEach((dist) => {
                            if (name === dist.name) {
                                navigate(`district/${dist.id}`);
                            }
                        });
                    }
                }
            };

            polygon.on('click', function (e) {
                L.popup()
                    .setLatLng(e.latlng)
                    .setContent(() => {
                        const container = document.createElement('div');
                        const title = document.createElement('h2');
                        title.textContent = item.name;
                        title.style.fontWeight = "600";
                        container.appendChild(title);
                        if ((!districtId && !regionOnly) || (districtId && regionOnly)) {
                            const button = document.createElement('button');
                            button.textContent = 'Перейти';
                            button.onclick = () => redirectToDistrict(item.name);
                            button.className = "popup-btn"
                            container.appendChild(button);
                        }
                        return container;
                    })
                    .openOn(map);
            });

            polygon.on('mousemove', function () {
                polygon.setStyle({
                    color: '#BDBDBD',
                    weight: 2
                });
            });

            polygon.on('mouseout', function () {
                polygon.setStyle({
                    color: '#BDBDBD',
                    weight: 1
                });
            });

        } else {
            let popup = L.popup({ autoClose: false, closeOnClick: false, closeButton: false, className: 'hover-popup' });

            polygon.on('mousemove', function (e: any) {
                polygon.setStyle({
                    color: '#BDBDBD',
                    weight: 2
                });

                popup.setLatLng(e.latlng)
                    .setContent(item.name)
                    .openOn(map);
            });

            polygon.on('mouseout', function () {
                polygon.setStyle({
                    color: '#BDBDBD',
                    weight: 1
                });
                map.closePopup(popup);
            });

            if (!districtId && !regionOnly) {
                polygon.on('click', function () {
                    if (districts.length) {
                        districts.forEach((dist) => {
                            if (item.name === dist.name) {
                                navigate(`district/${dist.id}`)
                            }
                        });
                    } else if (districtsData?.length) {
                        districtsData.forEach((dist) => {
                            if (item.name === dist.name) {
                                navigate(`district/${dist.id}`)
                            }
                        });
                    }
                });
            }
            if (districtId && regionOnly) {
                polygon.on('click', function () {
                    if (districts.length) {
                        districts.forEach((dist) => {
                            if (item.name === dist.name) {
                                const currentPath = window.location.pathname;
                                const newPath = currentPath.replace(/\/[^/]+$/, `/${dist.id}`);
                                navigate(newPath);
                            }
                        });
                    }
                    if (districtsData && districtsData.length) {
                        districtsData.forEach((dist) => {
                            if (item.name === dist.name) {
                                const currentPath = window.location.pathname;
                                const newPath = currentPath.replace(/\/[^/]+$/, `/${dist.id}`);
                                navigate(newPath);
                            }
                        });
                    }
                });
            }
        }
    };

    const fetchCounties = async (id: string): Promise<void> => {
        const response = await getDistrictCounties(id);

        if (!response.isError) {
            setCounties(response.data);
        } else {
            setCounties([]);
        }
    };

    return (
        <></>
    );
};

export default MapController;
