import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    plugins,
} from "chart.js";

import { Doughnut } from "react-chartjs-2";

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    plugins,
);

interface IDonutChart {
    labels: string[],
    values: number[],
    backgroundColor: string[],
    thickness?: string, // in %
    borderRadius?: number,
    distanceBetweenSegments?: number, // in px
    rotation?: number,
    customTooltip?: {top: string, left: string},
    withPercentageInTooltip?: boolean,
}

const DonutChart: React.FC<IDonutChart> = ({
    labels,
    values,
    backgroundColor,
    thickness,
    borderRadius,
    distanceBetweenSegments,
    rotation,
    customTooltip,
    withPercentageInTooltip,
}) => {
    const data = {
        labels: labels,
        datasets: [{
            data: values,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            offset: distanceBetweenSegments,
            cutout: thickness,
            borderRadius: borderRadius,
            rotation: rotation,
            hoverOffset: 12,
        }]
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: customTooltip ? {
                enabled: false,
                external: function(context: any) {
                    let percentage: string | null = null;

                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.style.opacity = '0';
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
                        tooltipEl.style.color = 'white';
                        tooltipEl.style.borderRadius = '4px';
                        tooltipEl.style.padding = '8px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.zIndex = '999';
                        document.body.appendChild(tooltipEl);
                    }

                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    if (withPercentageInTooltip && tooltipModel.dataPoints?.[0].raw) {
                        const currentValue = tooltipModel.dataPoints?.[0].raw;
                        const total = values.reduce((acc, val) => acc + val, 0);
                        
                        percentage = `${(currentValue/total*100).toLocaleString('ru-RU', { maximumFractionDigits: 2 })}%`;
                    }

                    if (tooltipModel.body) {
                        const color = tooltipModel.labelColors[0].backgroundColor;
                        const title = tooltipModel.title;

                        const tooltipHTML = `
                            <div style="display: flex; align-items: center;">
                                <span style="background-color: ${color}; display: flex; width: 10px; height: 10px; margin-right: 8px; border: 0.3px solid #fff;"></span>
                                <span style="max-width: 180px; font-size: 12px">${title[0]} ${percentage || ''}</span>
                            </div>
                        `;

                        tooltipEl.innerHTML = tooltipHTML;
                    }

                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.left = customTooltip.left + 'px';
                    tooltipEl.style.top = customTooltip.top + 'px';
                }
            } : undefined,
            datalabels: {
                display: false
            }
        } 
    };

    return (
        <Doughnut
            data = {data}
            options = {options}
        />
    )
}

export default DonutChart;
