import React, {useContext, useEffect, useState} from 'react';
import {IFilterOption} from "../../types";
import {AllFires, ExtinguishedFire, Fire, FireStations, HelicopterBlack, TemperatureIcon} from "../Icons";
import Map from "../Map/Map";
import Filter from "../UI/Filter";
import {AppDataContext} from "../../contexts/AppDataContext";
import SideInfo from "../SideInfo/SideInfo";
import {getFires, getFireStations, getFlights, getSatellitesData} from "../../services/fires";
import {getDistricts} from "../../services/floods";
import dayjs from "dayjs";
import {IFire, IFiresStation, IFlight, ISatellite} from "../../types/fires";

export const firesFilterOptions: IFilterOption[] = [
    {value: "active", label: "Действующие", icon: <Fire />},
    {value: "inactive", label: "Потушенные", icon: <ExtinguishedFire />},
    {value: "all", label: "Все пожары", icon: <AllFires />},
    {value: "stations", label: "Пожарные посты", icon: <FireStations />},
];

const FiresRegionPage = () => {
    const {date, updateDistricts} = useContext(AppDataContext);
    const [filter, setFilter] = useState<IFilterOption | null>(firesFilterOptions[3]);
    const [helicopterFilter, setHelicopterFilter] = useState<boolean>(false);
    const [fires, setFires] = useState<IFire[]>([]);
    const [fireStations, setFireStations] = useState<IFiresStation[]>([]);
    const [flights, setFlights] = useState<IFlight[]>([]);
    const [satelliteFilter, setSatelliteFilter] = useState<boolean>(false);
    const [satellites, setSatellites] = useState<ISatellite[]>([]);

    useEffect(() => {
        fetchDistrictsData();
        if (filter) handleFilterChange();
        if (helicopterFilter) fetchFlights();
        if (satelliteFilter) fetchSatellitesData();
    }, [date]);

    useEffect(() => {
        if (filter) handleFilterChange();
    }, [filter]);

    useEffect(() => {
        if (helicopterFilter) fetchFlights();
    }, [helicopterFilter]);

    useEffect(() => {
        if (satelliteFilter) fetchSatellitesData();
    }, [satelliteFilter]);

    const handleFilterChange = () => {
        if (filter?.value === "active") {
            fetchFires("active");
        } else if (filter?.value === "inactive") {
            fetchFires("inactive");
        } else if (filter?.value === "all") {
            fetchFires();
        } else if (filter?.value === "stations") {
            fetchFireStations();
        }
    };

    const fetchDistrictsData = async (): Promise<void> => {
        const response = await getDistricts(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            updateDistricts(response.data);
        } else {
            updateDistricts([]);
        }
    };

    const fetchFires = async (status?: string): Promise<void> => {
        const response = await getFires(dayjs(date).format("YYYY-MM-DD"), status);

        if (!response.isError) {
            setFires(response.data);
            setFireStations([]);
            setFlights([]);
            setSatellites([]);
        } else {
            setFires([]);
        }
    };

    const fetchFireStations = async (): Promise<void> => {
        const response = await getFireStations();

        if (!response.isError) {
            setFireStations(response.data);
            setFires([]);
            setFlights([]);
            setSatellites([]);
        } else {
            setFires([]);
        }
    };

    const fetchFlights = async (): Promise<void> => {
        const response = await getFlights(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            setFlights(response.data);
            setFireStations([]);
            setFires([]);
            setSatellites([]);
        } else {
            setFlights([]);
        }
    };

    const fetchSatellitesData = async (): Promise<void> => {
        const response = await getSatellitesData(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            setSatellites(response.data);
            setFlights([]);
            setFireStations([]);
            setFires([]);
        } else {
            setFlights([]);
        }
    };

    const handleChangeFlightsFilter = () => {
        setHelicopterFilter(true);
        setSatelliteFilter(false);
        setFilter(null);
        setFireStations([]);
        setFires([]);
    };

    const handleChangeSatelliteFilter = () => {
        setSatelliteFilter(true);
        setHelicopterFilter(false);
        setFilter(null);
        setFireStations([]);
        setFires([]);
    };

    return (
        <>
            <Map
                fires={fires}
                fireStations={fireStations}
                helicopter={helicopterFilter}
                flights={flights}
                satelliteFilter={satelliteFilter}
                satellites={satellites}
            />
            <SideInfo />
            <Filter
                options={firesFilterOptions}
                onSelect={(option) => {
                    setFilter(option);
                    setHelicopterFilter(false);
                    setSatelliteFilter(false);
                }}
                selectedOpt={filter}
                isFire
            />
            <div className="filter-wrapper helicopter">
                <div
                    className={`filter ${helicopterFilter ? "fires" : ""}`}
                    onClick={() => handleChangeFlightsFilter()}
                >
                    <HelicopterBlack />
                </div>
            </div>

            <div className="filter-wrapper satellite">
                <div
                    className={`filter ${satelliteFilter ? "fires" : ""}`}
                    onClick={() => handleChangeSatelliteFilter()}
                >
                    <TemperatureIcon />
                </div>
            </div>
        </>
    );
};

export default FiresRegionPage;