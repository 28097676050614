import React, {createContext, ReactNode, useEffect, useState} from "react";
import {ITenderCheckbox, ITenderFilterOption, ITenderOrganizator} from "../types/tenders";
import {featureTypes, subjectTypes, tradeTypes} from "../utils/options";

interface ITenderProvider {
    children: ReactNode;
}

interface IDefaultValues {
    customer: ITenderFilterOption;
    supplier: ITenderFilterOption;
    subject: ITenderCheckbox;
    status: ITenderFilterOption;
    budgetType: ITenderFilterOption;
    type: ITenderCheckbox;
    tradeMethod: ITenderFilterOption;
    organizator: ITenderFilterOption;
    feature: ITenderCheckbox;
    setCustomer: (opt: ITenderFilterOption) => void;
    setSupplier: (opt: ITenderFilterOption) => void;
    setSubject: (opt: ITenderCheckbox) => void;
    setStatus: (opt: ITenderFilterOption) => void;
    setBudgetType: (opt: ITenderFilterOption) => void;
    setType: (opt: ITenderCheckbox) => void;
    setTradeMethod: (opt: ITenderFilterOption) => void;
    setOrganizator: (opt: ITenderFilterOption) => void;
    setFeature: (opt: ITenderCheckbox) => void;
}

const defaultValues: IDefaultValues = {
    customer: {open: false, value: "", selected: [], selectedIds: []},
    supplier: {open: false, value: "", selected: [] , selectedIds: []},
    subject: {open: false, value: [], ids: []},
    status: {open: false, value: "", selected: [], selectedIds: []},
    budgetType: {open: false, value: "", selected: [], selectedIds: []},
    type: {open: false, value: [], ids: []},
    tradeMethod: {open: false, value: "", selected: [], selectedIds: []},
    organizator: {open: false, value: "", selected: [], selectedIds: []},
    feature: {open: false, value: [], ids: []},
    setCustomer: () => {},
    setSupplier: () => {},
    setSubject: () => {},
    setStatus: () => {},
    setBudgetType: () => {},
    setType: () => {},
    setTradeMethod: () => {},
    setOrganizator: () => {},
    setFeature: () => {}
};

export const TendersContext = createContext(defaultValues);

export const TendersProvider: React.FC<ITenderProvider> = ({children}) => {
    const [customer, setCustomer] = useState<ITenderFilterOption>({open: false, value: "", selected: [], selectedIds: []});
    const [supplier, setSupplier] = useState<ITenderFilterOption>({open: false, value: "", selected: [], selectedIds: []});
    const [subject, setSubject] = useState<ITenderCheckbox>({open: false, value: subjectTypes, ids: []});
    const [status, setStatus] = useState<ITenderFilterOption>({open: false, value: "", selected: [], selectedIds: []});
    const [budgetType, setBudgetType] = useState<ITenderFilterOption>({open: false, value: "", selected: [], selectedIds: []});
    const [type, setType] = useState<ITenderCheckbox>({open: false, value: tradeTypes, ids: []});
    const [tradeMethod, setTradeMethod] = useState<ITenderFilterOption>({open: false, value: "", selected: [], selectedIds: []});
    const [organizator, setOrganizator] = useState<ITenderFilterOption>({open: false, value: "", selected: [], selectedIds: []});
    const [feature, setFeature] = useState<ITenderCheckbox>({open: false, value: featureTypes, ids: []});

    return (
        <TendersContext.Provider value={{ customer, supplier, subject, status, budgetType, type, tradeMethod, organizator, feature, setCustomer, setSupplier, setSubject, setStatus, setBudgetType, setType, setTradeMethod, setOrganizator, setFeature}}>
            {children}
        </TendersContext.Provider>
    );
}