import { useContext, useEffect, useState } from "react";
import { IModalStatus } from "../../../types/tenders";
import { getMoreDetailsModal } from "../../../services/tenders";
import { AppDataContext } from "../../../contexts/AppDataContext";
import dayjs from "dayjs";
import BarChart from "../../Charts/BarChart";
import { chartColors } from "../../../utils/options";

const TendersContractsModal = () => {
    const { date } = useContext(AppDataContext);
    const [chartData, setChartData] = useState<IModalStatus[]>([]);

    useEffect(() => {
        loadDetailsModal();
    }, []);

    const loadDetailsModal = async () => {
        const response = await getMoreDetailsModal(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            setChartData(response.data);
        }
    }

    const getChartData = () => {
        let names: string[] = [];
        let statusesData: number[] = [];

        chartData.forEach((item) => {
            if (item.count !== 0) {
                names.push(item.status)
                statusesData.push(item.count);
            }
        });

        return {
            labels: names,
            datasets: [
                {   
                    data: statusesData,
                    backgroundColor: chartColors,
                    borderColor: chartColors,
                    borderWidth: 1
                }
            ]
        }
    };

    return (
        <div>
            <div className="modal-title">Кол-во объявлений по способу закупа</div>
            <div>
                <BarChart
                    data={getChartData()}
                    isLegend={false}
                    responsive
                    chartWidth={1000}
                    chartHeight={400}
                    textRotation={45}
                />
                    
            </div>
        </div>
    )
}

export default TendersContractsModal;