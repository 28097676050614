import { useEffect, useRef, useState } from "react";
import Button from "../UI/Button";

const TendersExportButton = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const exportRef = useRef<HTMLDivElement | null>(null);
    const options: string[] = ["Заключенные договора с начаоа года", "Отчет по отфильтрованным объявлениям"];

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (exportRef.current && !exportRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }

    return (
        <div className="tenders-export-wrapper" ref={exportRef}>
            <button
                className="button"
                style={{width: "182px"}}
                onClick={() => setIsOpen(!isOpen)}
            >
                ЭКСПОРТ
            </button>

            {isOpen && 
                <div className="dashboard-switcher-list-wrapper tenders-export">
                    {options.map((option: string) => 
                        <div
                            key={option}
                            className="dashboard-switcher dashboard-switcher-list"
                            onClick={() => {}}
                        >
                            {option}
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default TendersExportButton;