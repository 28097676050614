import React from 'react';
import {IDistrict} from "../../types/district";
import {ChevronOutline, EmergencySituation} from "../Icons";
import {useLocation, useNavigate} from "react-router-dom";
import {getDistrictStatus} from "../../utils";

interface ISideInfoRegionItem {
    district: IDistrict;
}

const SideInfoRegionItem: React.FC<ISideInfoRegionItem> = ({district}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isFirePage = location.pathname.includes("fires");

    return (
        <div className="side-info-card side-info-region-item">
            <div className="side-info-region-item-info">
                {isFirePage ? (
                    <>
                        {district.fire_information.length ?
                            <EmergencySituation /> : null
                        }
                        {/*<Events />*/}
                        <p>{district.name}</p>
                    </>
                ) : (
                    <>
                        <div className={getDistrictStatus(district)} />
                        <p>{district.name}</p>
                    </>
                )}
            </div>

            <div
                className={isFirePage ? "item-btn" : "item-btn floods-btn"}
                onClick={() => navigate(isFirePage ? `/fires/district/${district.id}` : `/district/${district.id}`)}
            >
                <ChevronOutline />
            </div>
        </div>
    );
};

export default SideInfoRegionItem;