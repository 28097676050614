import { useContext, useEffect, useState } from "react";
import { getGeneralDebts } from "../../../../services/hcs";
import { DebtsRecord, GeneralDebt, LineChartData } from "../../../../types/debts";
import LineChart from "../../../Charts/LineChart";
import { AppDataContext } from "../../../../contexts/AppDataContext";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { DebtsContext } from "../../../../contexts/DebtsContext";
import { addSpaces } from "../../../../utils";

dayjs.locale("ru");

interface IDebtsGeneralModal {
    text: string;
    id?: number;
}

const DebtsGeneralModal: React.FC<IDebtsGeneralModal> = ({text, id}) => {
    const { dateRange } = useContext(AppDataContext);
    const { type, kse, krp } = useContext(DebtsContext);
    const [generalDebt, setGeneralDebt] = useState<GeneralDebt>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadGeneralDebts = async (): Promise<void> => {
        setIsLoading(true);

        const response = await getGeneralDebts(
            dayjs(dateRange.from).format("YYYY-MM-DD"), 
            dayjs(dateRange.to).format("YYYY-MM-DD"), 
            text, 
            krp.selected, 
            kse.selected, 
            type.selected,
            id?.toString()
        );

        if (!response.isError) {
            setGeneralDebt(response.data);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        loadGeneralDebts();
    }, [dateRange, krp.selected, kse.selected, type.selected]);

    const beautifyDate = (date: string): string => {
        return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(0, 4)}`
    }

    const lineChartData = {
        labels: generalDebt?.daily_averages.map((object: LineChartData) => dayjs(object.date, "YYYY-MM-DD").format("D MMMM")) || [],
        datasets: [
            {
                label: "Кол-во",
                data: generalDebt?.daily_averages.map((object: LineChartData) => object.avg_debit_amount) || [],
                borderColor: "#4DA2F1",
                backgroundColor: "#4DA2F1",
            },
            {
                label: "Кол-во",
                data: generalDebt?.daily_averages.map((object: LineChartData) => object.avg_outstanding_amount) || [],
                borderColor: "#FF3D63",
                background: "#FF3D63",
            }
        ]
    }

    return (
        <>  
            {isLoading ? 
                <div className="loader">
                    Загрузка...
                </div> : 
            <>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                        <div className="debit"/>
                        <span>Дебиторская задолжность</span>
                    </div>

                    <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                        <div className="expired"/>
                        <span>Просроченная задолжность</span>
                    </div>

                </div>

                <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                    <LineChart isLegend={false} data={lineChartData} chartWidth={924} chartHeight={312}/>
                </div>

                <table className="table">
                    <thead className="table-header">
                        <tr className="row" style={{fontSize: "14px"}}>
                            <th className="cell debts-table">Дата отчета</th>
                            <th className="cell debts-table">БИН</th>
                            <th className="cell debts-table">Район</th>
                            <th className="cell debts-table">Организация</th>
                            <th className="cell debts-table">Дебиторская задолжность</th>
                            <th className="cell debts-table">Просроченная задолжность</th>
                            <th className="cell debts-table">Дата последнего платежа</th>
                            <th className="cell debts-table">Сумма последнего платежа</th>
                        </tr>
                    </thead>

                    <tbody>
                        {generalDebt?.records.map((debt: DebtsRecord) => (
                            <tr key={debt.id} className="row" style={{fontSize: "12px", textAlign: "center"}}>
                                <td className="cell" >{beautifyDate(debt.created_at)}</td>
                                <td className="cell" >{debt.bin_code}</td>
                                <td className="cell" >{debt.district_name}</td>
                                <td className="cell" >{debt.organization_name}</td>
                                <td className="cell" >{addSpaces(debt.debit_amount)}</td>
                                <td className="cell" >{addSpaces(debt.outstanding_amount)}</td>
                                <td className="cell" >{beautifyDate(debt.payment_date)}</td>
                                <td className="cell" >{addSpaces(debt.payment_sum)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
            }
        </>
    )
}

export default DebtsGeneralModal;
