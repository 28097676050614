import React, { ReactNode, useEffect, useRef, useState } from "react";

interface INewFilter {
    children: ReactNode;
    amount: number;
}

const NewFilter: React.FC<INewFilter> = ({amount, children}) => {
    const [open, setOpen] = useState<boolean>(false);
    const filterRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: any) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="filter-wrapper new-filter-wrapper" ref={filterRef}>
            <div
                className={open ? "new-filter new-filter-active" : "new-filter"}
                onClick={() => setOpen(!open)}
            >
                Фильтры
                {amount !== 0 && <span className="new-filter-amount">{amount}</span>}
            </div>

            <div className={open ? "new-filter-open" : "new-filter-closed"}>
                {open && children}
            </div>
        </div>
    );
};

export default NewFilter;