import { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../../contexts/AppDataContext";
import { DebtsContext } from "../../../contexts/DebtsContext";
import Map from "../../Map/Map";
import DebtsFilter from "./DebtsFilter";
import DebtsSideInfo from "./DebtsSideInfo"
import { getDebtsDistricts } from "../../../services/hcs";
import dayjs from "dayjs";
import { DebtsDistrict } from "../../../types/debts";
import { getDistrictData } from "../../../utils";

const DebtsRegionPage = () => {
    const { dateRange, updateDistrictsData } = useContext(AppDataContext);
    const { type, kse, krp } = useContext(DebtsContext);
    const [debtsDistricts, setDebtsDistricts] = useState<DebtsDistrict[]>([]);

    const loadDebtsDistricts = async () => {
        const response = await getDebtsDistricts(dayjs(dateRange.to).format("YYYY-MM-DD"), krp.selected, kse.selected, type.selected);

        if (!response.isError) {
            setDebtsDistricts(response.data);
            updateDistrictsData(getDistrictData(response.data));
        }
    };

    useEffect(() => {
        loadDebtsDistricts();
    }, []);

    useEffect(() => {
        loadDebtsDistricts();
    }, [dateRange, krp, kse, type]);

    return (
        <>  
            <Map debts={debtsDistricts} />
            <DebtsSideInfo />
            <DebtsFilter />
        </>
    )
}

export default DebtsRegionPage;
