import React, {useContext, useEffect, useState} from 'react';
import {Arrow, EmergencySituation, Events} from "../Icons";
import {useLocation} from "react-router-dom";
import {AppDataContext} from "../../contexts/AppDataContext";
import {getFiresStatistic} from "../../services/fires";
import {IFiresStatistic} from "../../types/fires";
import dayjs from "dayjs";

interface ISideInfoStatistic {
    handleShow: () => void;
}

const SideInfoStatistic: React.FC<ISideInfoStatistic> = ({handleShow}) => {
    const location = useLocation();
    const isFirePage = location.pathname.includes("fires");
    const {districts, date} = useContext(AppDataContext);
    const [isShow, setIsShow] = useState<boolean>(true);
    const [firesStatistic, setFiresStatistic] = useState<IFiresStatistic | null>(null);

    useEffect(() => {
        if (isFirePage) fetchFiresStatistic();
    }, [date]);

    const getWaterLevelStatistic = () => {
        let critical = 0;
        let medium = 0;
        let normal = 0;

        districts.forEach((district) => {
            district.hydrometeorological_reports.forEach((point) => {
                if (point.water_level_status.toLowerCase() === "high") critical++;
                if (point.water_level_status.toLowerCase() === "medium") medium++;
                if (point.water_level_status.toLowerCase() === "normal") normal++;
            });
        });

        return {
            critical: critical,
            medium: medium,
            normal: normal
        }
    };


    const fetchFiresStatistic = async () => {
        const response = await getFiresStatistic(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            setFiresStatistic(response.data);
        } else {
            setFiresStatistic(null);
        }
    };

    return (
        <div className="side-info-card side-info-statistic" id="side-info-statistic">
            <div className="side-info-statistic-title">ОБЩАЯ СТАТИСТИКА</div>

            <div className="side-info-statistic-data">
                {isFirePage ? (
                    <div className="data-list">
                        <div className="data-list-item">
                            <div>Количество пожаров</div>
                            <div>{firesStatistic?.total_fires}</div>
                        </div>
                        <ul>
                            <li>
                                <div className="data-list-item">
                                    <div>Лесные</div>
                                    <div>{firesStatistic?.forest_fires}</div>
                                </div>
                            </li>
                            <li>
                                <div className="data-list-item">
                                    <div>Степные</div>
                                    <div>{firesStatistic?.steppe_fires}</div>
                                </div>
                            </li>
                            <li>
                                <div className="data-list-item">
                                    <div>Домашние</div>
                                    <div>{firesStatistic?.domestic_fires}</div>
                                </div>
                            </li>
                        </ul>
                        <div className="data-list-item">
                            <div>Действующие пожары</div>
                            <div>{firesStatistic?.active_fires}</div>
                        </div>
                        <div className="data-list-item">
                            <div>Устраненные пожары</div>
                            <div>{firesStatistic?.extinguished_fires}</div>
                        </div>
                    </div>
                ) : (
                    <div className="data-list">
                        <div className="data-list-item">
                            <div>Нормальная ситуация</div>
                            <div>{getWaterLevelStatistic().normal}</div>
                        </div>
                        <div className="data-list-item">
                            <div>Стабильная ситуация</div>
                            <div>{getWaterLevelStatistic().medium}</div>
                        </div>
                        <div className="data-list-item">
                            <div>Критическая ситуация</div>
                            <div>{getWaterLevelStatistic().critical}</div>
                        </div>
                    </div>
                )}

                <div className="data-icons">
                    <div className="data-icons-item">
                        <EmergencySituation size={40} />
                        <div>
                            {firesStatistic ?
                                `(${firesStatistic?.active_fires + firesStatistic?.extinguished_fires})`
                            :
                                `(${getWaterLevelStatistic().medium + getWaterLevelStatistic().critical})`
                            }
                        </div>
                    </div>
                    <div className="data-icons-item">
                        <Events size={40} />
                        <div>{firesStatistic ? `(${firesStatistic?.events_fires})` : "(0)"}</div>
                    </div>
                </div>
            </div>

            <div
                className={isShow ? "side-info-card hide-button" : "side-info-card hide-button open"}
                onClick={() => {
                    setIsShow(!isShow);
                    handleShow();
                }}
            >
                <Arrow />
            </div>
        </div>
    );
};

export default SideInfoStatistic;