import React, {useEffect, useRef, useState} from 'react';
import {ChevronDown} from "../Icons";

interface IDropdown {
    options: IOption[];
    onSelect: (option: IOption) => void;
    selectedOpt?: IOption;
}

interface IOption {
    value: string;
    label: string;
}

const Dropdown: React.FC<IDropdown> = ({ options, onSelect, selectedOpt }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<IOption | null>(selectedOpt ? selectedOpt : null);
    const dropdownRef = useRef<any>(null);

    const handleToggle = () => setIsOpen(!isOpen);

    const handleOptionClick = (option: IOption) => {
        setSelectedOption(option);
        setIsOpen(false);
        onSelect(option);
    };

    const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <div className="dropdown" ref={dropdownRef}>
                <button className="dropdown-toggle" onClick={handleToggle}>
                    {selectedOption ? selectedOption.label : 'Выберите опцию'}
                    <ChevronDown color="black" />
                </button>
                {isOpen && (
                    <ul className="dropdown-menu">
                        {options.map((option) => (
                            <li
                                key={option.value}
                                className="dropdown-item"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Dropdown;