import {LatLngExpression} from "leaflet";

export  const ulanDistrict: LatLngExpression[] = [
    [
        81.1469101,
        50.177109
    ],
    [
        81.3721662,
        50.1181656
    ],
    [
        81.3550784,
        50.0540741
    ],
    [
        81.327215,
        50.0291761
    ],
    [
        81.3257031,
        49.9640993
    ],
    [
        81.5172575,
        49.9273077
    ],
    [
        81.5386654,
        49.9156955
    ],
    [
        81.5919789,
        49.9035044
    ],
    [
        81.619675,
        49.8740626
    ],
    [
        81.7081829,
        49.8690918
    ],
    [
        81.7329708,
        49.7978779
    ],
    [
        81.6888741,
        49.7835398
    ],
    [
        81.7438879,
        49.7414283
    ],
    [
        81.7167958,
        49.6865968
    ],
    [
        81.7516939,
        49.6938855
    ],
    [
        81.7944026,
        49.6536132
    ],
    [
        81.7869043,
        49.6168598
    ],
    [
        81.8614275,
        49.6095438
    ],
    [
        81.899505,
        49.5909802
    ],
    [
        81.8918676,
        49.5711062
    ],
    [
        81.9787893,
        49.5617366
    ],
    [
        82.0167323,
        49.5094259
    ],
    [
        82.05793,
        49.498714
    ],
    [
        82.0225669,
        49.4579585
    ],
    [
        82.0878687,
        49.409732
    ],
    [
        82.1776963,
        49.3743826
    ],
    [
        82.1855479,
        49.3941837
    ],
    [
        82.2084281,
        49.3863116
    ],
    [
        82.2346724,
        49.4031612
    ],
    [
        82.255996,
        49.391312
    ],
    [
        82.2603218,
        49.3695554
    ],
    [
        82.3322217,
        49.353745
    ],
    [
        82.3743219,
        49.2963906
    ],
    [
        82.4042127,
        49.337848
    ],
    [
        82.5238035,
        49.3290379
    ],
    [
        82.6092909,
        49.2541372
    ],
    [
        82.5986266,
        49.2449906
    ],
    [
        82.6091408,
        49.2416846
    ],
    [
        82.6230561,
        49.2375657
    ],
    [
        82.6262533,
        49.2363468
    ],
    [
        82.6277446,
        49.2356813
    ],
    [
        82.6292788,
        49.2352049
    ],
    [
        82.6301586,
        49.2344903
    ],
    [
        82.6310061,
        49.2335446
    ],
    [
        82.6308237,
        49.2321855
    ],
    [
        82.6329159,
        49.2302799
    ],
    [
        82.6333237,
        49.229271
    ],
    [
        82.633227,
        49.2288366
    ],
    [
        82.6335167,
        49.2280729
    ],
    [
        82.6326476,
        49.2269378
    ],
    [
        82.6298367,
        49.2254944
    ],
    [
        82.6291608,
        49.2244434
    ],
    [
        82.6273584,
        49.2235745
    ],
    [
        82.6268112,
        49.2223973
    ],
    [
        82.626661,
        49.2210589
    ],
    [
        82.627058,
        49.2199868
    ],
    [
        82.6278733,
        49.2190618
    ],
    [
        82.6285385,
        49.2186203
    ],
    [
        82.62915,
        49.2183329
    ],
    [
        82.6316606,
        49.2171486
    ],
    [
        82.6328086,
        49.2155578
    ],
    [
        82.6336132,
        49.2130558
    ],
    [
        82.6349222,
        49.2114297
    ],
    [
        82.6358664,
        49.210876
    ],
    [
        82.6382481,
        49.210862
    ],
    [
        82.6416813,
        49.2131328
    ],
    [
        82.6419174,
        49.2136375
    ],
    [
        82.6423572,
        49.2134693
    ],
    [
        82.643162,
        49.2120185
    ],
    [
        82.6437949,
        49.211612
    ],
    [
        82.6470136,
        49.2115279
    ],
    [
        82.6497548,
        49.2109987
    ],
    [
        82.6502,
        49.2109391
    ],
    [
        82.6503449,
        49.2111143
    ],
    [
        82.6507955,
        49.2111424
    ],
    [
        82.6513856,
        49.2114262
    ],
    [
        82.6517074,
        49.2114613
    ],
    [
        82.6526998,
        49.2111564
    ],
    [
        82.6539122,
        49.2106342
    ],
    [
        82.6546097,
        49.2106237
    ],
    [
        82.6549314,
        49.2106588
    ],
    [
        82.6565354,
        49.2114963
    ],
    [
        82.6570343,
        49.2115699
    ],
    [
        82.6577424,
        49.2113561
    ],
    [
        82.6581876,
        49.2113631
    ],
    [
        82.6586275,
        49.2116155
    ],
    [
        82.6600437,
        49.2131118
    ],
    [
        82.6606499,
        49.2142822
    ],
    [
        82.660945,
        49.2147763
    ],
    [
        82.6617013,
        49.2154281
    ],
    [
        82.6624631,
        49.215768
    ],
    [
        82.6626508,
        49.2163917
    ],
    [
        82.663375,
        49.2171381
    ],
    [
        82.6636325,
        49.2177898
    ],
    [
        82.66389,
        49.2183575
    ],
    [
        82.664051,
        49.2196539
    ],
    [
        82.6641314,
        49.2198571
    ],
    [
        82.6643353,
        49.2201024
    ],
    [
        82.6653599,
        49.2207471
    ],
    [
        82.665494,
        49.2215705
    ],
    [
        82.6661753,
        49.2231331
    ],
    [
        82.6671194,
        49.2241071
    ],
    [
        82.6673447,
        49.2268537
    ],
    [
        82.6701879,
        49.2276315
    ],
    [
        82.6729344,
        49.2318913
    ],
    [
        82.6755951,
        49.2341611
    ],
    [
        82.679157,
        49.2357585
    ],
    [
        82.6918172,
        49.2401856
    ],
    [
        82.699499,
        49.2404658
    ],
    [
        82.7054213,
        49.2395972
    ],
    [
        82.711644,
        49.2366271
    ],
    [
        82.7125639,
        49.2367708
    ],
    [
        82.7130057,
        49.2373132
    ],
    [
        82.7135323,
        49.2374677
    ],
    [
        82.7155864,
        49.2365763
    ],
    [
        82.7175663,
        49.2334045
    ],
    [
        82.7208279,
        49.2331804
    ],
    [
        82.7233599,
        49.2321995
    ],
    [
        82.7259348,
        49.2331523
    ],
    [
        82.7276944,
        49.2321155
    ],
    [
        82.7277373,
        49.2310506
    ],
    [
        82.7300118,
        49.2297334
    ],
    [
        82.7319859,
        49.2298175
    ],
    [
        82.7328442,
        49.2291449
    ],
    [
        82.7331017,
        49.2273512
    ],
    [
        82.73396,
        49.2263423
    ],
    [
        82.7368353,
        49.226062
    ],
    [
        82.7399682,
        49.2276595
    ],
    [
        82.7415989,
        49.2287525
    ],
    [
        82.7458046,
        49.2279678
    ],
    [
        82.7478646,
        49.2290608
    ],
    [
        82.7471994,
        49.2323256
    ],
    [
        82.7539587,
        49.2355903
    ],
    [
        82.7585934,
        49.236515
    ],
    [
        82.7607821,
        49.2358425
    ],
    [
        82.76134,
        49.234021
    ],
    [
        82.7663611,
        49.2345535
    ],
    [
        82.767949,
        49.2334045
    ],
    [
        82.7706526,
        49.2331523
    ],
    [
        82.7711676,
        49.2314709
    ],
    [
        82.7730988,
        49.231527
    ],
    [
        82.7730559,
        49.2307703
    ],
    [
        82.7724122,
        49.2300137
    ],
    [
        82.7729271,
        49.2293411
    ],
    [
        82.7741288,
        49.2289767
    ],
    [
        82.7788065,
        49.2295092
    ],
    [
        82.780094,
        49.2309665
    ],
    [
        82.7817677,
        49.2311627
    ],
    [
        82.7848576,
        49.2289207
    ],
    [
        82.787175,
        49.2286124
    ],
    [
        82.7909087,
        49.2289767
    ],
    [
        82.7925824,
        49.2279958
    ],
    [
        82.7914237,
        49.2271831
    ],
    [
        82.7903508,
        49.226062
    ],
    [
        82.7902972,
        49.2256206
    ],
    [
        82.7917563,
        49.2242542
    ],
    [
        82.7915632,
        49.224002
    ],
    [
        82.7900397,
        49.2243593
    ],
    [
        82.7893745,
        49.2243803
    ],
    [
        82.7891814,
        49.2239739
    ],
    [
        82.7899645,
        49.2234274
    ],
    [
        82.7916865,
        49.2234974
    ],
    [
        82.7936982,
        49.2237918
    ],
    [
        82.7970028,
        49.2244574
    ],
    [
        82.7976035,
        49.2241631
    ],
    [
        82.7968847,
        49.223035
    ],
    [
        82.7966915,
        49.2222081
    ],
    [
        82.7971529,
        49.2216405
    ],
    [
        82.7981936,
        49.2210029
    ],
    [
        82.7981614,
        49.2204983
    ],
    [
        82.7967452,
        49.219307
    ],
    [
        82.7961873,
        49.2189076
    ],
    [
        82.7960371,
        49.218403
    ],
    [
        82.7964233,
        49.2178704
    ],
    [
        82.7987193,
        49.2172467
    ],
    [
        82.7996849,
        49.2170785
    ],
    [
        82.8012084,
        49.2164969
    ],
    [
        82.8028499,
        49.216644
    ],
    [
        82.8031181,
        49.2163006
    ],
    [
        82.8026782,
        49.2159993
    ],
    [
        82.8019165,
        49.2159152
    ],
    [
        82.8011547,
        49.2153896
    ],
    [
        82.8007792,
        49.2148219
    ],
    [
        82.8007256,
        49.2141351
    ],
    [
        82.8019379,
        49.2138477
    ],
    [
        82.802528,
        49.2135043
    ],
    [
        82.8023778,
        49.2130347
    ],
    [
        82.8001999,
        49.2125862
    ],
    [
        82.7991163,
        49.2115559
    ],
    [
        82.7972709,
        49.2104625
    ],
    [
        82.7966057,
        49.2098107
    ],
    [
        82.7966915,
        49.2095934
    ],
    [
        82.7975499,
        49.209327
    ],
    [
        82.8007363,
        49.2089626
    ],
    [
        82.8017448,
        49.2087593
    ],
    [
        82.8020989,
        49.2083878
    ],
    [
        82.8008329,
        49.2073855
    ],
    [
        82.8008436,
        49.207049
    ],
    [
        82.8026782,
        49.2064112
    ],
    [
        82.8028499,
        49.2061588
    ],
    [
        82.8021633,
        49.2050793
    ],
    [
        82.8017985,
        49.2042732
    ],
    [
        82.8020238,
        49.2029624
    ],
    [
        82.804942,
        49.2017426
    ],
    [
        82.8035043,
        49.2007191
    ],
    [
        82.8046416,
        49.1981953
    ],
    [
        82.8038048,
        49.1971016
    ],
    [
        82.8049421,
        49.1955732
    ],
    [
        82.805972,
        49.1958397
    ],
    [
        82.8069376,
        49.1967651
    ],
    [
        82.8098987,
        49.1981953
    ],
    [
        82.812302,
        49.1987562
    ],
    [
        82.8132676,
        49.1993871
    ],
    [
        82.814555,
        49.1992469
    ],
    [
        82.8157781,
        49.197999
    ],
    [
        82.8175377,
        49.1984477
    ],
    [
        82.8194474,
        49.1995974
    ],
    [
        82.8182458,
        49.2005929
    ],
    [
        82.8189539,
        49.2014061
    ],
    [
        82.8208207,
        49.2018968
    ],
    [
        82.8229879,
        49.2019389
    ],
    [
        82.8215502,
        49.203383
    ],
    [
        82.8208421,
        49.2038316
    ],
    [
        82.8209065,
        49.2042241
    ],
    [
        82.8222798,
        49.2046447
    ],
    [
        82.8243827,
        49.2044485
    ],
    [
        82.824726,
        49.2041681
    ],
    [
        82.8241895,
        49.2031446
    ],
    [
        82.8246831,
        49.2018828
    ],
    [
        82.8247045,
        49.2009014
    ],
    [
        82.8250478,
        49.200621
    ],
    [
        82.8279232,
        49.2015884
    ],
    [
        82.8300046,
        49.202037
    ],
    [
        82.8309702,
        49.2014762
    ],
    [
        82.8313993,
        49.1998638
    ],
    [
        82.8300904,
        49.1989384
    ],
    [
        82.8314852,
        49.1980271
    ],
    [
        82.8322791,
        49.1974802
    ],
    [
        82.8330516,
        49.1976625
    ],
    [
        82.8341459,
        49.1985038
    ],
    [
        82.8342103,
        49.1992609
    ],
    [
        82.8337812,
        49.200621
    ],
    [
        82.8342961,
        49.2007752
    ],
    [
        82.8357338,
        49.200663
    ],
    [
        82.8377079,
        49.201322
    ],
    [
        82.837622,
        49.2031446
    ],
    [
        82.8363775,
        49.2037335
    ],
    [
        82.8349828,
        49.2067056
    ],
    [
        82.8356265,
        49.2082897
    ],
    [
        82.8404115,
        49.2107569
    ],
    [
        82.8408407,
        49.2107289
    ],
    [
        82.8414844,
        49.2098738
    ],
    [
        82.8423213,
        49.2092289
    ],
    [
        82.8446816,
        49.2091728
    ],
    [
        82.8464841,
        49.2081916
    ],
    [
        82.8476642,
        49.2071542
    ],
    [
        82.8477501,
        49.2062009
    ],
    [
        82.8466986,
        49.2060887
    ],
    [
        82.8458189,
        49.2055981
    ],
    [
        82.8454541,
        49.2050793
    ],
    [
        82.8465055,
        49.2043223
    ],
    [
        82.8495311,
        49.2042382
    ],
    [
        82.8508185,
        49.2049251
    ],
    [
        82.852106,
        49.2054579
    ],
    [
        82.8589295,
        49.2054298
    ],
    [
        82.8601097,
        49.204813
    ],
    [
        82.8618478,
        49.2032007
    ],
    [
        82.8642725,
        49.2029063
    ],
    [
        82.8662251,
        49.2036073
    ],
    [
        82.866547,
        49.2021912
    ],
    [
        82.8674268,
        49.2010276
    ],
    [
        82.871611,
        49.1994011
    ],
    [
        82.8725766,
        49.1985458
    ],
    [
        82.8727268,
        49.1974241
    ],
    [
        82.8719329,
        49.1965408
    ],
    [
        82.8716968,
        49.1958397
    ],
    [
        82.8727268,
        49.195419
    ],
    [
        82.8741215,
        49.1955312
    ],
    [
        82.8766321,
        49.1976064
    ],
    [
        82.8783487,
        49.1974522
    ],
    [
        82.8794001,
        49.1978868
    ],
    [
        82.8831123,
        49.1981673
    ],
    [
        82.885289,
        49.1989147
    ],
    [
        82.8856229,
        49.1988964
    ],
    [
        82.8870015,
        49.1970256
    ],
    [
        82.8869103,
        49.1964707
    ],
    [
        82.8857014,
        49.1958683
    ],
    [
        82.8853223,
        49.1951122
    ],
    [
        82.8850107,
        49.1947687
    ],
    [
        82.8835579,
        49.1938421
    ],
    [
        82.8826402,
        49.1929791
    ],
    [
        82.882334,
        49.19242
    ],
    [
        82.8822262,
        49.191265
    ],
    [
        82.8814932,
        49.1900334
    ],
    [
        82.8813791,
        49.1894191
    ],
    [
        82.8816196,
        49.1889349
    ],
    [
        82.8864597,
        49.1839615
    ],
    [
        82.8872536,
        49.1838213
    ],
    [
        82.8885626,
        49.1842701
    ],
    [
        82.8896281,
        49.1844161
    ],
    [
        82.8910731,
        49.1844524
    ],
    [
        82.891631,
        49.1841299
    ],
    [
        82.8916789,
        49.1837674
    ],
    [
        82.8913735,
        49.1833023
    ],
    [
        82.8903865,
        49.1823346
    ],
    [
        82.8903234,
        49.1816894
    ],
    [
        82.8913735,
        49.1812125
    ],
    [
        82.8929185,
        49.1802306
    ],
    [
        82.8946004,
        49.1797558
    ],
    [
        82.8968238,
        49.1818717
    ],
    [
        82.8994201,
        49.1822224
    ],
    [
        82.9019736,
        49.1837652
    ],
    [
        82.9043769,
        49.1837371
    ],
    [
        82.9055785,
        49.1833585
    ],
    [
        82.9072307,
        49.1832462
    ],
    [
        82.9086469,
        49.1827553
    ],
    [
        82.9096984,
        49.1828255
    ],
    [
        82.9105996,
        49.1826011
    ],
    [
        82.9117583,
        49.1830218
    ],
    [
        82.9134535,
        49.1825309
    ],
    [
        82.9146122,
        49.1826712
    ],
    [
        82.9177879,
        49.1820681
    ],
    [
        82.9193543,
        49.1810442
    ],
    [
        82.9230021,
        49.1800483
    ],
    [
        82.9261779,
        49.1794872
    ],
    [
        82.9285597,
        49.1786035
    ],
    [
        82.9306625,
        49.1789963
    ],
    [
        82.9323362,
        49.1781968
    ],
    [
        82.9342459,
        49.1777058
    ],
    [
        82.9346751,
        49.1763381
    ],
    [
        82.9349755,
        49.1761979
    ],
    [
        82.9360484,
        49.1760856
    ],
    [
        82.9371856,
        49.1758963
    ],
    [
        82.9376577,
        49.1754053
    ],
    [
        82.9375612,
        49.1746758
    ],
    [
        82.9381191,
        49.1741708
    ],
    [
        82.9396962,
        49.1740165
    ],
    [
        82.9406832,
        49.173287
    ],
    [
        82.9415417,
        49.17281
    ],
    [
        82.9512833,
        49.176261
    ],
    [
        82.9701661,
        49.180946
    ],
    [
        82.9677199,
        49.1763452
    ],
    [
        82.9685782,
        49.1700043
    ],
    [
        83.0085325,
        49.1692186
    ],
    [
        83.0608463,
        49.1746618
    ],
    [
        83.029561,
        49.2279958
    ],
    [
        83.0393457,
        49.2492629
    ],
    [
        83.0948782,
        49.2387006
    ],
    [
        83.1817388,
        49.2667124
    ],
    [
        83.156805,
        49.2950723
    ],
    [
        83.1920814,
        49.3056505
    ],
    [
        83.2183455,
        49.3092321
    ],
    [
        83.2619046,
        49.2926093
    ],
    [
        83.2843493,
        49.2848556
    ],
    [
        83.3003568,
        49.2820421
    ],
    [
        83.3163641,
        49.2792286
    ],
    [
        83.3536577,
        49.2618115
    ],
    [
        83.3715963,
        49.2846877
    ],
    [
        83.4236525,
        49.2646401
    ],
    [
        83.4261845,
        49.2672445
    ],
    [
        83.4274292,
        49.2646401
    ],
    [
        83.4446811,
        49.2498792
    ],
    [
        83.4682416,
        49.251644
    ],
    [
        83.4863518,
        49.2692887
    ],
    [
        83.4956216,
        49.3041954
    ],
    [
        83.522315,
        49.3147438
    ],
    [
        83.5447597,
        49.3438029
    ],
    [
        83.592782,
        49.3561039
    ],
    [
        83.5988759,
        49.3688768
    ],
    [
        83.5776758,
        49.3882954
    ],
    [
        83.6090039,
        49.3875411
    ],
    [
        83.6295603,
        49.3964237
    ],
    [
        83.6375426,
        49.3939379
    ],
    [
        83.6435936,
        49.4087394
    ],
    [
        83.6444521,
        49.4191258
    ],
    [
        83.6346243,
        49.4204099
    ],
    [
        83.6259984,
        49.4230061
    ],
    [
        83.6199044,
        49.420382
    ],
    [
        83.6153553,
        49.4158035
    ],
    [
        83.6126517,
        49.4162502
    ],
    [
        83.6187886,
        49.4232853
    ],
    [
        83.6201619,
        49.4280306
    ],
    [
        83.6152695,
        49.4294541
    ],
    [
        83.6078452,
        49.4267466
    ],
    [
        83.5987042,
        49.4336127
    ],
    [
        83.6142395,
        49.4394733
    ],
    [
        83.6162566,
        49.4444681
    ],
    [
        83.6247538,
        49.4486534
    ],
    [
        83.6198185,
        49.4519456
    ],
    [
        83.6171578,
        49.4569671
    ],
    [
        83.6159132,
        49.4632711
    ],
    [
        83.6123942,
        49.4636616
    ],
    [
        83.6100338,
        49.466618
    ],
    [
        83.6101627,
        49.4713592
    ],
    [
        83.6205052,
        49.4735343
    ],
    [
        83.6419629,
        49.4812861
    ],
    [
        83.6529921,
        49.4869738
    ],
    [
        83.6104175,
        49.5350899
    ],
    [
        83.5883117,
        49.5539226
    ],
    [
        83.5698059,
        49.5735897
    ],
    [
        83.5157044,
        49.582169
    ],
    [
        83.4826301,
        49.5788867
    ],
    [
        83.4571142,
        49.5820382
    ],
    [
        83.4379615,
        49.5989935
    ],
    [
        83.4226899,
        49.6141936
    ],
    [
        83.4078597,
        49.6196634
    ],
    [
        83.3947277,
        49.6215261
    ],
    [
        83.3849001,
        49.6229439
    ],
    [
        83.3768165,
        49.6249968
    ],
    [
        83.3707809,
        49.6268917
    ],
    [
        83.3625411,
        49.629727
    ],
    [
        83.3581513,
        49.6319976
    ],
    [
        83.3531766,
        49.6353492
    ],
    [
        83.3500098,
        49.6377321
    ],
    [
        83.3481216,
        49.6397886
    ],
    [
        83.3464908,
        49.6434014
    ],
    [
        83.345075,
        49.6492797
    ],
    [
        83.3446679,
        49.6525508
    ],
    [
        83.3455973,
        49.6567
    ],
    [
        83.3459583,
        49.6574228
    ],
    [
        83.3464108,
        49.6581976
    ],
    [
        83.3470353,
        49.6591524
    ],
    [
        83.3481645,
        49.6608222
    ],
    [
        83.3499512,
        49.6637233
    ],
    [
        83.3526549,
        49.6688367
    ],
    [
        83.3531674,
        49.6717955
    ],
    [
        83.3533031,
        49.6725785
    ],
    [
        83.3535327,
        49.6739041
    ],
    [
        83.3515664,
        49.679698
    ],
    [
        83.3471773,
        49.6828332
    ],
    [
        83.3433852,
        49.6835375
    ],
    [
        83.3377672,
        49.6827651
    ],
    [
        83.3310255,
        49.6806068
    ],
    [
        83.3241435,
        49.6766761
    ],
    [
        83.3162432,
        49.674404
    ],
    [
        83.3098878,
        49.6735859
    ],
    [
        83.3010188,
        49.6741335
    ],
    [
        83.2808283,
        49.6736807
    ],
    [
        83.2691007,
        49.6732263
    ],
    [
        83.2594096,
        49.6699541
    ],
    [
        83.2532805,
        49.6685401
    ],
    [
        83.2271763,
        49.6669543
    ],
    [
        83.2129908,
        49.668886
    ],
    [
        83.2068174,
        49.6708637
    ],
    [
        83.2006001,
        49.6728772
    ],
    [
        83.1944418,
        49.6743907
    ],
    [
        83.1884765,
        49.6757238
    ],
    [
        83.183863,
        49.6768207
    ],
    [
        83.1809234,
        49.6774178
    ],
    [
        83.1781338,
        49.6778482
    ],
    [
        83.1757445,
        49.6781308
    ],
    [
        83.1692623,
        49.6788003
    ],
    [
        83.1651735,
        49.6793617
    ],
    [
        83.1581998,
        49.6803058
    ],
    [
        83.1515263,
        49.681236
    ],
    [
        83.1478143,
        49.681722
    ],
    [
        83.1447243,
        49.6820552
    ],
    [
        83.1419563,
        49.6820274
    ],
    [
        83.1388664,
        49.6814859
    ],
    [
        83.1341243,
        49.6800628
    ],
    [
        83.1313884,
        49.6794866
    ],
    [
        83.1288135,
        49.6794033
    ],
    [
        83.1263995,
        49.6796602
    ],
    [
        83.1233311,
        49.6804238
    ],
    [
        83.11993,
        49.6815137
    ],
    [
        83.1173873,
        49.6826938
    ],
    [
        83.1157458,
        49.6836171
    ],
    [
        83.1140935,
        49.6848318
    ],
    [
        83.1127846,
        49.6859772
    ],
    [
        83.1111646,
        49.687643
    ],
    [
        83.1100488,
        49.6886981
    ],
    [
        83.1089544,
        49.6896143
    ],
    [
        83.107624,
        49.6903014
    ],
    [
        83.1060254,
        49.6908844
    ],
    [
        83.103311,
        49.6914535
    ],
    [
        83.0986333,
        49.6919394
    ],
    [
        83.0949211,
        49.6918006
    ],
    [
        83.0920243,
        49.691162
    ],
    [
        83.0893421,
        49.6898988
    ],
    [
        83.0833983,
        49.6869281
    ],
    [
        83.0805015,
        49.6858869
    ],
    [
        83.0768537,
        49.6854566
    ],
    [
        83.0737424,
        49.6859147
    ],
    [
        83.0706739,
        49.6869559
    ],
    [
        83.0665111,
        49.6884551
    ],
    [
        83.0634641,
        49.6889688
    ],
    [
        83.0603528,
        49.68883
    ],
    [
        83.0574989,
        49.6883094
    ],
    [
        83.0539799,
        49.6871502
    ],
    [
        83.0497098,
        49.6856232
    ],
    [
        83.0452679,
        49.6844985
    ],
    [
        83.0399251,
        49.6836795
    ],
    [
        83.0350756,
        49.6829993
    ],
    [
        83.030827,
        49.682791
    ],
    [
        83.0271577,
        49.6830269
    ],
    [
        83.0239176,
        49.6836656
    ],
    [
        83.0225053,
        49.6842911
    ],
    [
        83.0208921,
        49.6856232
    ],
    [
        83.0187464,
        49.6883719
    ],
    [
        83.0181885,
        49.6909122
    ],
    [
        83.0185102,
        49.6931469
    ],
    [
        83.0196475,
        49.6954649
    ],
    [
        83.0227804,
        49.6988932
    ],
    [
        83.0254197,
        49.7017244
    ],
    [
        83.0272436,
        49.7040004
    ],
    [
        83.0283807,
        49.7065676
    ],
    [
        83.0281662,
        49.7087184
    ],
    [
        83.0276513,
        49.7118266
    ],
    [
        83.0255699,
        49.7147681
    ],
    [
        83.0211495,
        49.7176954
    ],
    [
        83.0165577,
        49.719499
    ],
    [
        83.0102491,
        49.7201649
    ],
    [
        83.0048788,
        49.7199449
    ],
    [
        82.9994559,
        49.7186528
    ],
    [
        82.989043,
        49.7144736
    ],
    [
        82.9777719,
        49.7110226
    ],
    [
        82.9660092,
        49.7085249
    ],
    [
        82.9584951,
        49.7085476
    ],
    [
        82.9547732,
        49.710296
    ],
    [
        82.9507001,
        49.714292
    ],
    [
        82.9480667,
        49.7181288
    ],
    [
        82.9497872,
        49.7267548
    ],
    [
        82.9508054,
        49.7337225
    ],
    [
        82.9536144,
        49.7402809
    ],
    [
        82.8918304,
        49.7559835
    ],
    [
        82.8792713,
        49.7901031
    ],
    [
        82.8394888,
        49.7732828
    ],
    [
        82.6904872,
        49.8198067
    ],
    [
        82.6665401,
        49.7989958
    ],
    [
        82.6411294,
        49.8111916
    ],
    [
        82.6253199,
        49.8039123
    ],
    [
        82.6277232,
        49.7955746
    ],
    [
        82.6129843,
        49.8012724
    ],
    [
        82.5663566,
        49.8300089
    ],
    [
        82.5584737,
        49.8506581
    ],
    [
        82.5892697,
        49.8634634
    ],
    [
        82.5173663,
        49.9016561
    ],
    [
        82.495222,
        49.8860082
    ],
    [
        82.4661253,
        49.8716277
    ],
    [
        82.4279361,
        49.8812006
    ],
    [
        82.4580572,
        49.9154197
    ],
    [
        82.3959699,
        49.9204142
    ],
    [
        82.4547797,
        49.9691412
    ],
    [
        82.4484657,
        49.9716935
    ],
    [
        82.4410106,
        49.9761963
    ],
    [
        82.4388432,
        49.9802691
    ],
    [
        82.4433803,
        50.0055325
    ],
    [
        82.4644515,
        50.022363
    ],
    [
        82.4741509,
        50.0290618
    ],
    [
        82.4636227,
        50.0364937
    ],
    [
        82.4029615,
        50.0606007
    ],
    [
        82.3361339,
        50.0641437
    ],
    [
        82.2757954,
        50.1419468
    ],
    [
        82.2685704,
        50.1728001
    ],
    [
        82.2586689,
        50.2150506
    ],
    [
        82.225351,
        50.2317453
    ],
    [
        82.1999001,
        50.2213463
    ],
    [
        82.17704,
        50.2230256
    ],
    [
        82.1643018,
        50.2075053
    ],
    [
        82.1442604,
        50.203605
    ],
    [
        82.0872191,
        50.2079468
    ],
    [
        82.0395898,
        50.2029732
    ],
    [
        82.0289038,
        50.1883301
    ],
    [
        81.9183539,
        50.1975616
    ],
    [
        81.9197272,
        50.2010779
    ],
    [
        81.9102858,
        50.2041544
    ],
    [
        81.8859278,
        50.2035551
    ],
    [
        81.8579291,
        50.1986605
    ],
    [
        81.8350124,
        50.1947593
    ],
    [
        81.8151854,
        50.1876707
    ],
    [
        81.8024824,
        50.1953638
    ],
    [
        81.7939853,
        50.2006383
    ],
    [
        81.7896079,
        50.2049234
    ],
    [
        81.7914104,
        50.2100871
    ],
    [
        81.7923545,
        50.216129
    ],
    [
        81.7886638,
        50.2207971
    ],
    [
        81.7841148,
        50.2221151
    ],
    [
        81.7771625,
        50.2208521
    ],
    [
        81.7722701,
        50.2217856
    ],
    [
        81.7684077,
        50.2250256
    ],
    [
        81.7679786,
        50.2281006
    ],
    [
        81.7695235,
        50.2313401
    ],
    [
        81.7736434,
        50.2349636
    ],
    [
        81.7754459,
        50.2379282
    ],
    [
        81.7760467,
        50.2408377
    ],
    [
        81.7731284,
        50.2440763
    ],
    [
        81.7635154,
        50.2466012
    ],
    [
        81.7511557,
        50.2454486
    ],
    [
        81.6269688,
        50.2688259
    ],
    [
        81.6119312,
        50.2750199
    ],
    [
        81.5913089,
        50.2867776
    ],
    [
        81.4740747,
        50.3248141
    ],
    [
        81.3330235,
        50.3160143
    ],
    [
        81.4023138,
        50.2344828
    ],
    [
        81.3651958,
        50.22316
    ],
    [
        81.3323375,
        50.2238301
    ],
    [
        81.2357409,
        50.2508629
    ],
    [
        81.1377161,
        50.215755
    ],
    [
        81.1469101,
        50.177109
    ]
]