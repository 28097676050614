import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ChevronOutline} from "../Icons";
import {useLocation} from "react-router-dom";
import dayjs from "dayjs";
import {IHydrometeorologicalReport, IWaterLevelHistory} from "../../types/district";
import Scale from "../UI/Scale";
import {IFire} from "../../types/fires";
import LineChart from '../Charts/LineChart';
import { AppDataContext } from '../../contexts/AppDataContext';

interface ISideInfoDistrictItem {
    data?: IHydrometeorologicalReport;
    fire?: IFire;
    count?:number;
    onClick?: () => void;
}

const SideInfoDistrictItem: React.FC<ISideInfoDistrictItem> = ({data, fire, count, onClick}) => {
    const location = useLocation();
    const isFirePage = location.pathname.includes("fires");
    const { selectedPoint, setSelectedPoint } = useContext(AppDataContext);
    const [isShow, setIsShow] = useState<boolean>(!!(count && count < 3));

    useEffect(() => {
        if (data && count === 1) {
            setSelectedPoint(data);
        }
    }, []);

    const getWaterLevelMarkerColor = (status: string) => {
        if (status.toLowerCase() === "medium") return "marker medium";
        if (status.toLowerCase() === "high") return "marker high";
        if (status.toLowerCase() === "undefined") return "marker special";
        return "marker";
    };

    const getWaterLevelColor = (waterLevel: number) => {
        if (waterLevel > 0) return "raising";
        if (waterLevel < 0) return "downgrade";
    };

    const getFireTypeName = (type: string) => {
        if (type === "forest") {
            return "Лесной пожар";
        } else if (type === "domestic") {
            return "Домашний пожар"
        } else {
            return "Степной пожар"
        }
    };

    const getChartData = () => {
        const label: string[] = data?.water_level_history.map((object: IWaterLevelHistory) => dayjs(object.report_date).format("DD.MM")).reverse() as string[];
        for (let i = 0; i < label.length; i++) {
            if (i !== label.length - 1) {
                label[i] = "";
            }
        }

        const predictedData: string[] = Array(data?.water_level_prediction.length).fill("");

        return {
            labels: label.concat(predictedData) || [],
            datasets: [
                {
                    label: "",
                    data: data?.water_level_history.map((object: IWaterLevelHistory) => object.water_level_current).reverse() || [],
                    backgroundColor: "#34A8E5",
                    borderColor: "#34A8E5",
                    borderWidth: 1
                },
                {
                    label: "",
                    data: data?.water_level_prediction.length as number > 0 && Array(data?.water_level_history.length! - 1).fill(null).concat(data?.water_level_history[0].water_level_current, data?.water_level_prediction.map((object) => object.forecast_water_level)) || [],
                    backgroundColor: "#076d30",
                    borderColor: "#076d30",
                    borderWidth: 1
                }
            ]
        }
    };

    const chartData = useMemo(() => getChartData(), [data]);

    return (
        <div className={`side-info-card side-info-district-item ${data?.id === selectedPoint?.id ? "selected" : ""}`} style={(!isFirePage && data) ? {width: "326px"} : {}} onClick={onClick}>
            <div className="item-info">

                <div className={isShow ? "item-info-header show" : "item-info-header"}>
                    {isFirePage ? (
                     fire &&
                        <>
                            <div className="item-info-icons">
                                <div className={fire.status === "active" ? "marker high" : "marker"} />
                                {/*<EmergencySituation />*/}
                            </div>
                            <p>{getFireTypeName(fire.fire_type)}</p>
                        </>

                    ) : (
                        <>
                            {data &&
                                <>
                                    <div className="item-info-icons item-info-card">
                                        <div className="item-info-card-name">
                                            <div className={getWaterLevelMarkerColor(data.water_level_status)}/>
                                            <p style={{fontSize: "12px"}}>{data.checkpoint_name}</p>
                                        </div>
                                        {!isShow && 
                                            <div className="scale-wrapper">
                                                <Scale
                                                    waterLevel={data.water_level_current}
                                                    criticalWaterLevel={data.water_level_critical}
                                                />
                                                <div>
                                                    {data.water_level_current}
                                                    {data.water_level_perDay && data.water_level_perDay !== 0 &&
                                                        <span className={getWaterLevelColor(data.water_level_perDay)}>
                                                            ({data.water_level_perDay})
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        }  
                                    </div>
                                    
                                </>
                            }
                        </>
                    )}
                </div>

                {(isShow && isFirePage && fire) &&
                    <div className="data-list">
                        <div className="data-list-item">
                            <div>Жертвы</div>
                            <div>{fire.casualties_population}</div>
                        </div>
                        <div className="data-list-item">
                            <div>Возможный радиус</div>
                            <div>{fire.possible_radius}</div>
                        </div>
                        <div className="data-list-item">
                            <div>Площадь</div>
                            <div>{fire.suspended_area}</div>
                        </div>
                        <div className="data-list-item">
                            <div>Привлеченные силы</div>
                            <div>{fire.engaged_equipment + fire.engaged_personnel}</div>
                        </div>
                        <ul>
                            <li>
                                <div className="data-list-item">
                                    <div>Количество людей</div>
                                    <div>{fire.engaged_personnel}</div>
                                </div>
                            </li>
                            <li>
                                <div className="data-list-item">
                                    <div>Количество техники</div>
                                    <div>{fire.engaged_equipment}</div>
                                </div>
                            </li>
                        </ul>
                        <div className="data-list-item">
                            <div>Причина возгорания</div>
                            <div>{fire.reason}</div>
                        </div>
                        <div className="data-list-item">
                            <div>Что было повреждено</div>
                        </div>
                        <ul>
                            <li>
                                <div className="data-list-item">
                                    <div>Инфраструктурные объекты (здания, жилые дома)</div>
                                    <div>{fire.damaged_infrastructure}</div>
                                </div>
                            </li>
                            <li>
                                <div className="data-list-item">
                                    <div>Природные (степи, леса, заповедники и т.д)</div>
                                    <div>{fire.damaged_nature}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                }

                {(isShow && !isFirePage && data) &&
                    <div className="floods-data-list">

                        <div className="scale-wrapper">
                            <Scale
                                waterLevel={data.water_level_current}
                                criticalWaterLevel={data.water_level_critical}
                            />
                            <div>
                                {data.water_level_current}
                                {data.water_level_perDay !== 0 &&
                                    <span className={getWaterLevelColor(data.water_level_perDay)}>
                                        ({data.water_level_perDay})
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="linechart">
                            <LineChart 
                                data={chartData}
                                isLegend={false} 
                                straightLabels
                                chartWidth={430}
                                criticalLevel={data.water_level_critical}
                                hasPrediction={data.water_level_prediction.length !== 0}
                            />
                        </div>
                    </div>
                }
            </div>

            <div
                className={isShow ? `item-btn district-item-btn item-open-btn ${!isFirePage ? "floods-btn" : ""}` : `item-btn district-item-btn ${!isFirePage ? "floods-btn" : ""}`}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation(); 
                    setIsShow(!isShow)
                }}
            >
                <ChevronOutline />
            </div>
        </div>
    );
};

export default SideInfoDistrictItem;