import React, {useContext, useEffect, useRef, useState} from 'react';
import {divIcon, DivIconOptions} from "leaflet";
import ReactDOMServer from "react-dom/server";
import {Marker, Popup} from "react-leaflet";
import {IHydrometeorologicalReport} from "../../../types/district";
import {isMobileDevice} from "../../../utils";
import Modal from "../../UI/Modal";
import DistrictChartModal from "../../FloodsPages/DistrictPage/DistrictChartModal";
import Scale from "../../UI/Scale";
import { AppDataContext } from '../../../contexts/AppDataContext';

interface IPointMarker {
    point: IHydrometeorologicalReport;
    districtName?: string;
}

const PointMarker: React.FC<IPointMarker> = ({ point, districtName, }) => {
    const markerRef = useRef<any>(null);
    const isMobile = isMobileDevice();
    const [isChartModal, setIsChartModal] = useState<boolean>(false);
    const [chosenLocality, setChosenLocality] = useState<IHydrometeorologicalReport | null>(null);
    const {selectedPoint} = useContext(AppDataContext);

    const createMarkers = (status:string) => {
        const options: DivIconOptions = {
            className: "marker-wrapper",
            html: ReactDOMServer.renderToString(
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div className={`marker ${status.toLowerCase() !== "undefined" ? status.toLowerCase() : "special"} ${status.toLowerCase() === "high" ? "pulse" : null} ${selectedPoint?.id === point.id ? "scaled" : ""}`} />
                    {point.water_level_current && <div style={{color: "white", textWrap: "nowrap"}}>{point.water_level_current} см</div>}
                </div>
            )
        }
        return divIcon(options);
    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    const showModal = () => {
        if (!isMobile) {
            setIsChartModal(true);
            setChosenLocality(point);
        }
    };

    const getWaterLevelColor = (waterLevel: number) => {
        if (waterLevel > 0) return "raising";
        if (waterLevel < 0) return "downgrade";
    };

    return (
        <>
            {point.latitude && point.longitude &&
                <Marker
                    position={[point.latitude, point.longitude]}
                    icon={createMarkers(point.water_level_status)}
                    ref={markerRef}
                    eventHandlers={{
                        click: showModal,
                    }}
                >
                    <Popup>
                        {districtName &&
                            <><span style={{ fontWeight: 600, fontSize: "14px" }}>{districtName}</span> <br /></>
                        }

                        <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
                            <span style={{ fontWeight: 600 }}>{point.checkpoint_name}</span>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Крит. уровень воды, см</div>
                                <div>{point.water_level_critical}</div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Уровень воды, см</div>
                                <div>
                                    {point.water_level_current}
                                    {point.water_level_perDay !== 0 &&
                                        <span className={getWaterLevelColor(point.water_level_perDay)}>
                                        ({point.water_level_perDay})
                                    </span>
                                    }
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>Расход воды, м3/сек</div>
                                <div>{point.water_flow}</div>
                            </div>

                            {point.ice_on_coastline && <div>Ледовая обстановка: {point.ice_on_coastline}</div>}

                            <div style={{marginTop: "4px"}}>
                                <Scale
                                    waterLevel={point.water_level_current}
                                    criticalWaterLevel={point.water_level_critical}
                                />
                            </div>
                        </div>

                        {isMobile &&
                            <button
                                onClick={() => {
                                    setIsChartModal(true);
                                    setChosenLocality(point);
                                }}
                                className="popup-btn">
                                Посмотреть
                            </button>
                        }
                    </Popup>
                </Marker>
            }

            {chosenLocality &&
                <Modal
                    isOpen={isChartModal}
                    onClose={() => {
                        setIsChartModal(false);
                        setChosenLocality(null);
                    }}
                    title={chosenLocality.checkpoint_name}
                >
                    <DistrictChartModal id={chosenLocality.checkpoint} latitude={chosenLocality.latitude} longitude={chosenLocality.longitude}/>
                </Modal>
            }
        </>
    );
};

export default PointMarker;