import {IFire, IFiresStation, IFiresStatistic, IFlight, ISatellite} from "../types/fires";
import {ICounty} from "../types";
import axiosInstance from "./axiosInstance";

type TResult<DataType> =
    | {
    isError: true;
    error: string;
}
    | {
    isError: false;
    data: DataType;
};

export const getFires = async (date: string, status?: string, id?: string): Promise<TResult<IFire[]>> => {
    try {
        const response = await axiosInstance.get('/api/emergency/fire/list/', {
            params: {
                date: date,
                status: status,
                district_id: id,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getFiresStatistic = async (date: string): Promise<TResult<IFiresStatistic>> => {
    try {
        const response = await axiosInstance.get('/api/emergency/fire/statistics/', {
            params: {
                date: date,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getFireStations = async (id?: string): Promise<TResult<IFiresStation[]>> => {
    try {
        const response = await axiosInstance.get('/api/core/fire/stations/', {
            params: {
                district_id: id,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getFlights = async (date: string, id?: string): Promise<TResult<IFlight[]>> => {
    try {
        const response = await axiosInstance.get('/api/emergency/fire/control-flight/list', {
            params: {
                date: date,
                district_id: id,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getDistrictCounties = async (id: string): Promise<TResult<ICounty[]>> => {
    try {
        const response = await axiosInstance.get(`/api/core/counties/${id}/`);

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getSatellitesData = async (date: string): Promise<TResult<ISatellite[]>> => {
    try {
        const response = await axiosInstance.get('/api/core/fire/satellites/', {
            params: {
                date: date,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};