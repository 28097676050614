import React, { useContext, useEffect, useState } from 'react';
import ProgressBar from "../Charts/ProgressBar";
import { ITenderExecution } from '../../types/tenders';
import { getTendersExecutionChart } from '../../services/tenders';
import { AppDataContext } from '../../contexts/AppDataContext';
import dayjs from 'dayjs';
import { getSumString } from '../../utils';

const TenderPlanExecutionCard = () => {
    const { date } = useContext(AppDataContext);
    const [chartData, setChartData] = useState<ITenderExecution>();

    const loadChartData = async () => {
        const response = await getTendersExecutionChart(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            setChartData(response.data);
        }
    }

    useEffect(() => {
        loadChartData();
    }, [date]);

    const done = chartData ? chartData.processed_sum : 0;
    const sum = chartData ? chartData.all_sum : 0;
    const plan = chartData ? chartData.all_sum - chartData.processed_sum : 0;
    
    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-title">Исполнение плана</div>

            <div className="side-info-big-card-items" style={{marginTop: "10px"}}>
                <ProgressBar value1={done} value2={plan} />

                <div className="side-info-big-card-item side-info-big-card-item-title">
                    <div>Общая сумма плана ВКО</div>
                    <span>{getSumString(Math.round(sum))} тг</span>
                </div>

                <div className="side-info-big-card-item">
                    <div className="side-info-big-card-item-text">
                        <div className="dot" style={{background: "#00D67E"}} />
                        <div>Проведено на сумму</div>
                    </div>
                    <span style={{fontSize: "12px"}}>{getSumString(Math.round(done))}</span>
                </div>

                <div className="side-info-big-card-item">
                    <div className="side-info-big-card-item-text">
                        <div className="dot"  style={{background: "#C6C6C6"}} />
                        <div>Плановые закупки на сумму</div>
                    </div>
                    <span style={{fontSize: "12px"}}>{getSumString(Math.round(plan))}</span>
                </div>
            </div>
        </div>
    );
};

export default TenderPlanExecutionCard;
