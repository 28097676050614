import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDataContext } from "../../contexts/AppDataContext";

export enum PRESENTATION_QUERY_KEYS {
    PRESENTATION_MODE = "presentationMode",
    PRESENTATION_DISTRICTS = "presentationDistricts",
};

const PresentationButton: React.FC = () => {
    const { districts, districtsData } = useContext(AppDataContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { id } = useParams();

    const isFloodsRegionPage = Boolean(useMatch("/"));
    const isTendersRegionPage = Boolean(useMatch("/tenders"));
    const isDebtsRegionPage = Boolean(useMatch("/debts"));

    const isFloodsDistrictPage = Boolean(useMatch("/district/:id"));
    const isTendersDistrictPage = Boolean(useMatch("/tenders/district/:id"));
    const isDebtsDistrictPage = Boolean(useMatch("/debts/district/:id"));

    const isRegionPage = isFloodsRegionPage || isTendersRegionPage || isDebtsRegionPage;

    const presentationMode = useMemo(() => searchParams.get(PRESENTATION_QUERY_KEYS.PRESENTATION_MODE) == "true", [searchParams]);

    const presentationDistricts = useMemo(() => {        
        const query = searchParams.get(PRESENTATION_QUERY_KEYS.PRESENTATION_DISTRICTS) || "";

        return query.split(",").filter(Boolean).map(Number);
    }, [searchParams]);

    const [timeoutId, setTimeoutId] = useState<Parameters<typeof clearTimeout>["0"]>();

    useEffect(() => {
        if (!isRegionPage || !presentationMode) {
            return;
        }

        if (isFloodsRegionPage && districts.length) {
            setTimeoutId(
                setTimeout(() => {
                    const search = [
                        "?", 
                        new URLSearchParams(
                            [
                                [PRESENTATION_QUERY_KEYS.PRESENTATION_MODE, "true"],
                                [PRESENTATION_QUERY_KEYS.PRESENTATION_DISTRICTS, districts.slice(1).map(({id})=> id).join(",")],
                            ],
                        ).toString(),
                    ].join("");

                    navigate({
                        pathname: `/district/${districts[0].id}`,
                        search,
                    });
                }, 7_000)
            );
        }

        if ((isTendersRegionPage || isDebtsRegionPage) && districtsData?.length) {
            setTimeoutId(
                setTimeout(() => {
                    const search = [
                        "?", 
                        new URLSearchParams(
                            [
                                [PRESENTATION_QUERY_KEYS.PRESENTATION_MODE, "true"],
                                [PRESENTATION_QUERY_KEYS.PRESENTATION_DISTRICTS, districtsData.slice(1).map(({id})=> id).join(",")],
                            ],
                        ).toString(),
                    ].join("");

                    const path = isTendersRegionPage ? "/tenders/district" : "/debts/district";

                    navigate({
                        pathname: `${path}/${districtsData[0].id}`,
                        search,
                    });
                }, 7_000)
            );
        }

        return () => clearTimeout(timeoutId);
    }, [isRegionPage, presentationMode, districts, districtsData]);

    useEffect(() => {
        if (isRegionPage || !presentationMode) {
            return;
        }

        if (!presentationDistricts.length) {
            setTimeoutId(
                setTimeout(() => {
                    const search = [
                        "?", 
                        new URLSearchParams(
                            [
                                [PRESENTATION_QUERY_KEYS.PRESENTATION_MODE, "true"],
                            ],
                        ).toString(),
                    ].join("");

                    const pathname = ((isFloodsDistrictPage && "/tenders") || (isTendersDistrictPage && "/debts") || (isDebtsDistrictPage && "/")) || "/";

                    navigate({
                        pathname,
                        search,
                    });
                }, 7_000)
            );

            return () => clearTimeout(timeoutId);
        }

        setTimeoutId(
            setTimeout(() => {
                const search = [
                    "?", 
                    new URLSearchParams(
                        [
                            [PRESENTATION_QUERY_KEYS.PRESENTATION_MODE, "true"],
                            [PRESENTATION_QUERY_KEYS.PRESENTATION_DISTRICTS, presentationDistricts.slice(1).join(',')],
                        ],
                    ).toString(),
                ].join("");

                const pathname = (
                    (
                        (isFloodsDistrictPage && "/district") || 
                        (isTendersDistrictPage && "/tenders/district") || 
                        (isDebtsDistrictPage && "/debts/district")
                    ) || 
                    "/"
                );

                navigate({
                    pathname: `${pathname}/${presentationDistricts[0]}`,
                    search,
                });
            }, 7_000)
        );
        
        return () => clearTimeout(timeoutId);
    }, [isRegionPage, presentationMode, id]);

    const handleClick = () => {
        if (presentationMode) {
            document.exitFullscreen().catch(console.error);

            clearTimeout(timeoutId);

            searchParams.delete(PRESENTATION_QUERY_KEYS.PRESENTATION_MODE);
            // searchParams.delete(PRESENTATION_QUERY_KEYS.PRESENTATION_DISTRICTS);
            setSearchParams(searchParams);
            
            return;
        }

        document.documentElement.requestFullscreen().catch(console.error);

        searchParams.set(PRESENTATION_QUERY_KEYS.PRESENTATION_MODE, "true");
        setSearchParams(searchParams);
    }

    useEffect(() => {
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
            <div>
                <button className="button" type="button" onClick={handleClick}>
                    {
                        !presentationMode ? "play" : "stop"
                    }
                </button>
            </div>
        </>
    );
};

export default PresentationButton;