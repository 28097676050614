import React from 'react';
import Header from "./components/Header/Header";
import {Outlet, useLocation} from "react-router-dom";
import {AppDataProvider} from "./contexts/AppDataContext";
import { DebtsProvider } from './contexts/DebtsContext';
import {TendersProvider} from "./contexts/TendersContext";
import DashboardSwitcher from "./components/UI/DashboardSwitcher";

const App = () => {
    const location = useLocation();

    return (
        <AppDataProvider>
            <DebtsProvider>
                <TendersProvider>
                    <div className="App">
                        {location.pathname !== "/authorization" && <Header />}
                        {location.pathname !== "/authorization" && <DashboardSwitcher />}
                        <Outlet />
                        <p className="copyright">&copy; 2024 Robosoft LLP</p>
                    </div>
                </TendersProvider>
            </DebtsProvider>
        </AppDataProvider>
    );
}

export default App;
