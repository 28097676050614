import React from 'react';

const ProgressBar: React.FC<{value1: number, value2: number}> = ({value1, value2}) => {
    return (
        <div>
            <div className="progress-bar">
                <div className="progress-bar-left" style={{width: `${(value1 / (value1 + value2))*100}%`}}>
                    {value1 >= value2 ? Math.round((value1 / (value1 + value2))*100) + "%" : ""}</div>
                <div className="progress-bar-right" style={{width: `${(value2 / (value1 + value2))*100}%`}}>
                    {value1 < value2 ? Math.round((value2 / (value1 + value2))*100) + "%" : ""}
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;