import {LatLngExpression} from "leaflet";

export const vko: LatLngExpression[] = [
  [
    81.1469101,
    50.177109
  ],
  [
    81.1377161,
    50.215755
  ],
  [
    81.2357409,
    50.2508629
  ],
  [
    81.3323375,
    50.2238301
  ],
  [
    81.3651958,
    50.22316
  ],
  [
    81.4023138,
    50.2344828
  ],
  [
    81.3330235,
    50.3160143
  ],
  [
    81.4740747,
    50.3248141
  ],
  [
    81.5913089,
    50.2867776
  ],
  [
    81.6119312,
    50.2750199
  ],
  [
    81.6269688,
    50.2688259
  ],
  [
    81.6480629,
    50.2865251
  ],
  [
    81.6747549,
    50.304443
  ],
  [
    81.6824911,
    50.3152159
  ],
  [
    81.6795729,
    50.3303406
  ],
  [
    81.6821478,
    50.3327514
  ],
  [
    81.6887279,
    50.3605305
  ],
  [
    81.6883276,
    50.3991082
  ],
  [
    81.72163,
    50.4009409
  ],
  [
    81.7203426,
    50.4023907
  ],
  [
    81.7184543,
    50.4035396
  ],
  [
    81.7148494,
    50.4044696
  ],
  [
    81.7048072,
    50.4035943
  ],
  [
    81.6972541,
    50.4046337
  ],
  [
    81.6900442,
    50.407533
  ],
  [
    81.682872,
    50.4144931
  ],
  [
    81.6780279,
    50.4270029
  ],
  [
    81.6692732,
    50.4302835
  ],
  [
    81.6602596,
    50.4312486
  ],
  [
    81.6649817,
    50.4456995
  ],
  [
    81.6761397,
    50.4627497
  ],
  [
    81.6883276,
    50.4625311
  ],
  [
    81.6898724,
    50.4814324
  ],
  [
    81.6989706,
    50.4828522
  ],
  [
    81.7335942,
    50.5043358
  ],
  [
    81.7134581,
    50.5286301
  ],
  [
    81.709957,
    50.5328528
  ],
  [
    81.6981692,
    50.5387263
  ],
  [
    81.6783712,
    50.5499808
  ],
  [
    81.6846402,
    50.5605461
  ],
  [
    81.6787576,
    50.5649488
  ],
  [
    81.6545103,
    50.5558704
  ],
  [
    81.6378592,
    50.5668843
  ],
  [
    81.6301344,
    50.5790945
  ],
  [
    81.6355417,
    50.5846537
  ],
  [
    81.6645525,
    50.5844901
  ],
  [
    81.6641232,
    50.6479368
  ],
  [
    81.6340826,
    50.6737265
  ],
  [
    81.6340411,
    50.6959405
  ],
  [
    81.5849699,
    50.7043253
  ],
  [
    81.5908286,
    50.7435026
  ],
  [
    81.6534113,
    50.7518814
  ],
  [
    81.6597187,
    50.7526851
  ],
  [
    81.7278273,
    50.7601264
  ],
  [
    81.7414631,
    50.7708353
  ],
  [
    81.7554322,
    50.7818034
  ],
  [
    81.775857,
    50.820919
  ],
  [
    81.7895734,
    50.8165709
  ],
  [
    81.7887292,
    50.815036
  ],
  [
    81.7888387,
    50.8113168
  ],
  [
    81.7895368,
    50.8092495
  ],
  [
    81.7880174,
    50.8067064
  ],
  [
    81.7893734,
    50.8057968
  ],
  [
    81.7891946,
    50.8026059
  ],
  [
    81.7900159,
    50.7953903
  ],
  [
    81.7912616,
    50.7943433
  ],
  [
    81.7912978,
    50.7938968
  ],
  [
    81.791456,
    50.793651
  ],
  [
    81.7913122,
    50.7933049
  ],
  [
    81.7903996,
    50.789776
  ],
  [
    81.7967794,
    50.7719818
  ],
  [
    81.8854153,
    50.804307
  ],
  [
    81.9062043,
    50.795451
  ],
  [
    81.9278138,
    50.7932854
  ],
  [
    81.9569988,
    50.7903423
  ],
  [
    81.9650382,
    50.7895315
  ],
  [
    81.9819892,
    50.7847499
  ],
  [
    81.9919293,
    50.7847519
  ],
  [
    81.997179,
    50.7879389
  ],
  [
    82.0125734,
    50.7857508
  ],
  [
    82.0262941,
    50.7791634
  ],
  [
    82.035972,
    50.769114
  ],
  [
    82.0481439,
    50.7693719
  ],
  [
    82.0629188,
    50.7657995
  ],
  [
    82.061132,
    50.7573664
  ],
  [
    82.0783819,
    50.7508377
  ],
  [
    82.1472347,
    50.7473268
  ],
  [
    82.1468078,
    50.7451018
  ],
  [
    82.1523774,
    50.7437712
  ],
  [
    82.1559373,
    50.7443433
  ],
  [
    82.158932,
    50.7469534
  ],
  [
    82.1663907,
    50.745845
  ],
  [
    82.1721616,
    50.7468046
  ],
  [
    82.1753045,
    50.7452719
  ],
  [
    82.17463,
    50.7398972
  ],
  [
    82.1723916,
    50.7386358
  ],
  [
    82.172702,
    50.7300643
  ],
  [
    82.1797603,
    50.7317879
  ],
  [
    82.1813548,
    50.7337481
  ],
  [
    82.184697,
    50.7347572
  ],
  [
    82.1855111,
    50.7370321
  ],
  [
    82.1891536,
    50.7352208
  ],
  [
    82.1916253,
    50.735015
  ],
  [
    82.1959183,
    50.7351933
  ],
  [
    82.1994524,
    50.7349052
  ],
  [
    82.204179,
    50.7366342
  ],
  [
    82.2119058,
    50.7369226
  ],
  [
    82.2158289,
    50.7412541
  ],
  [
    82.2170247,
    50.741225
  ],
  [
    82.2224366,
    50.7369171
  ],
  [
    82.2240075,
    50.7368288
  ],
  [
    82.2290213,
    50.7394037
  ],
  [
    82.230127,
    50.7419009
  ],
  [
    82.2341164,
    50.7428476
  ],
  [
    82.2370218,
    50.7455915
  ],
  [
    82.2386479,
    50.7458796
  ],
  [
    82.241228,
    50.7475808
  ],
  [
    82.2434395,
    50.7473613
  ],
  [
    82.2477975,
    50.7489978
  ],
  [
    82.2491418,
    50.7488949
  ],
  [
    82.2500255,
    50.7519307
  ],
  [
    82.2535057,
    50.7540743
  ],
  [
    82.2570166,
    50.7611348
  ],
  [
    82.2599794,
    50.7628135
  ],
  [
    82.263589,
    50.7642731
  ],
  [
    82.2642156,
    50.7657256
  ],
  [
    82.2703799,
    50.7697911
  ],
  [
    82.2958581,
    50.7693844
  ],
  [
    82.3051638,
    50.7557875
  ],
  [
    82.3167363,
    50.7529586
  ],
  [
    82.3213316,
    50.7612487
  ],
  [
    82.3272322,
    50.7628752
  ],
  [
    82.326439,
    50.7644185
  ],
  [
    82.3319132,
    50.7673958
  ],
  [
    82.3353213,
    50.7679212
  ],
  [
    82.3374622,
    50.7702245
  ],
  [
    82.3403695,
    50.7697129
  ],
  [
    82.3423717,
    50.7702787
  ],
  [
    82.3435328,
    50.7729995
  ],
  [
    82.3457055,
    50.7736999
  ],
  [
    82.346451,
    50.7749392
  ],
  [
    82.3507749,
    50.7756261
  ],
  [
    82.3521169,
    50.7769057
  ],
  [
    82.3558444,
    50.7828587
  ],
  [
    82.3559722,
    50.7844074
  ],
  [
    82.3573142,
    50.7845959
  ],
  [
    82.3622183,
    50.7828491
  ],
  [
    82.3665957,
    50.7778932
  ],
  [
    82.3682615,
    50.7777413
  ],
  [
    82.3702484,
    50.7790246
  ],
  [
    82.3745184,
    50.7798531
  ],
  [
    82.3760019,
    50.7807007
  ],
  [
    82.3778319,
    50.7804293
  ],
  [
    82.3807238,
    50.7757198
  ],
  [
    82.3821881,
    50.7751716
  ],
  [
    82.4082922,
    50.7638408
  ],
  [
    82.4107141,
    50.7646687
  ],
  [
    82.4718233,
    50.756125
  ],
  [
    82.4806629,
    50.7518985
  ],
  [
    82.4809098,
    50.7517593
  ],
  [
    82.4809661,
    50.7516949
  ],
  [
    82.4810949,
    50.7514097
  ],
  [
    82.4812665,
    50.7511433
  ],
  [
    82.4814033,
    50.7506783
  ],
  [
    82.4818137,
    50.7503932
  ],
  [
    82.481929,
    50.7502846
  ],
  [
    82.4819827,
    50.7501947
  ],
  [
    82.4820792,
    50.7499265
  ],
  [
    82.4820658,
    50.7496482
  ],
  [
    82.4819559,
    50.7494836
  ],
  [
    82.4819451,
    50.7493784
  ],
  [
    82.4820095,
    50.7492375
  ],
  [
    82.482267,
    50.7484382
  ],
  [
    82.482275,
    50.7482108
  ],
  [
    82.4821758,
    50.7477678
  ],
  [
    82.4821973,
    50.7477067
  ],
  [
    82.4823314,
    50.7475727
  ],
  [
    82.4823823,
    50.7474352
  ],
  [
    82.4824118,
    50.7472434
  ],
  [
    82.4823877,
    50.7467886
  ],
  [
    82.4823501,
    50.7466664
  ],
  [
    82.4823153,
    50.746646
  ],
  [
    82.4821838,
    50.7466172
  ],
  [
    82.4820068,
    50.7466443
  ],
  [
    82.4817787,
    50.7467342
  ],
  [
    82.4817305,
    50.7467376
  ],
  [
    82.4816473,
    50.7467019
  ],
  [
    82.48165,
    50.7465118
  ],
  [
    82.4817546,
    50.7461962
  ],
  [
    82.4817117,
    50.7458041
  ],
  [
    82.4817921,
    50.7456955
  ],
  [
    82.4819155,
    50.7456582
  ],
  [
    82.4820523,
    50.7456887
  ],
  [
    82.4821972,
    50.7458126
  ],
  [
    82.4822696,
    50.7458499
  ],
  [
    82.4824198,
    50.7458771
  ],
  [
    82.4825163,
    50.7458669
  ],
  [
    82.4827738,
    50.74576
  ],
  [
    82.4830152,
    50.7457498
  ],
  [
    82.4830984,
    50.7457277
  ],
  [
    82.4831467,
    50.7456768
  ],
  [
    82.4831467,
    50.7456446
  ],
  [
    82.4830206,
    50.7455207
  ],
  [
    82.4830099,
    50.7454579
  ],
  [
    82.4830474,
    50.7453628
  ],
  [
    82.4831359,
    50.7452865
  ],
  [
    82.4832164,
    50.7452491
  ],
  [
    82.4833532,
    50.7452372
  ],
  [
    82.4835383,
    50.7452576
  ],
  [
    82.4842088,
    50.7453747
  ],
  [
    82.4843617,
    50.7453696
  ],
  [
    82.4845012,
    50.7453323
  ],
  [
    82.4846085,
    50.7452627
  ],
  [
    82.4848338,
    50.7449165
  ],
  [
    82.4853032,
    50.7446025
  ],
  [
    82.4854051,
    50.7444718
  ],
  [
    82.4854104,
    50.7444073
  ],
  [
    82.4853648,
    50.7443224
  ],
  [
    82.4852214,
    50.7442223
  ],
  [
    82.4851355,
    50.7441926
  ],
  [
    82.4849371,
    50.7441791
  ],
  [
    82.4848835,
    50.744146
  ],
  [
    82.484858,
    50.7440976
  ],
  [
    82.4848728,
    50.744034
  ],
  [
    82.4850109,
    50.7439491
  ],
  [
    82.4850471,
    50.7439076
  ],
  [
    82.4850391,
    50.7438524
  ],
  [
    82.4849908,
    50.7438057
  ],
  [
    82.4849076,
    50.743776
  ],
  [
    82.4848111,
    50.7437777
  ],
  [
    82.4846193,
    50.7439279
  ],
  [
    82.4845107,
    50.7439602
  ],
  [
    82.4844114,
    50.743967
  ],
  [
    82.4843403,
    50.7439568
  ],
  [
    82.4842639,
    50.7439169
  ],
  [
    82.4841901,
    50.7438422
  ],
  [
    82.4841539,
    50.7437573
  ],
  [
    82.4841834,
    50.7436971
  ],
  [
    82.4843739,
    50.7436165
  ],
  [
    82.4844691,
    50.7435248
  ],
  [
    82.4844476,
    50.7434552
  ],
  [
    82.4843578,
    50.7433237
  ],
  [
    82.4843497,
    50.7431786
  ],
  [
    82.4843953,
    50.7431013
  ],
  [
    82.4844396,
    50.7430632
  ],
  [
    82.4845254,
    50.7430343
  ],
  [
    82.4846998,
    50.7430861
  ],
  [
    82.4847708,
    50.7430784
  ],
  [
    82.48485,
    50.743036
  ],
  [
    82.4848942,
    50.7429579
  ],
  [
    82.4847896,
    50.7427856
  ],
  [
    82.4847869,
    50.742722
  ],
  [
    82.4848191,
    50.7426575
  ],
  [
    82.4849264,
    50.7425913
  ],
  [
    82.4850015,
    50.7425922
  ],
  [
    82.4851638,
    50.7426286
  ],
  [
    82.4852858,
    50.7426032
  ],
  [
    82.4853569,
    50.74252
  ],
  [
    82.4853489,
    50.7423842
  ],
  [
    82.4854763,
    50.7422773
  ],
  [
    82.4854816,
    50.7422451
  ],
  [
    82.4854762,
    50.7422289
  ],
  [
    82.5022682,
    50.7417798
  ],
  [
    82.5368069,
    50.7470549
  ],
  [
    82.5674695,
    50.7468424
  ],
  [
    82.6033094,
    50.773777
  ],
  [
    82.6054564,
    50.7759965
  ],
  [
    82.6234429,
    50.7779755
  ],
  [
    82.6304219,
    50.7790917
  ],
  [
    82.6376108,
    50.7876972
  ],
  [
    82.6470531,
    50.7943349
  ],
  [
    82.6544189,
    50.7954493
  ],
  [
    82.6633479,
    50.7928822
  ],
  [
    82.6680143,
    50.7932451
  ],
  [
    82.6731737,
    50.7970066
  ],
  [
    82.680216,
    50.8005861
  ],
  [
    82.6798858,
    50.8019868
  ],
  [
    82.6749695,
    50.8059781
  ],
  [
    82.6751058,
    50.807954
  ],
  [
    82.6776917,
    50.8088453
  ],
  [
    82.6820407,
    50.8082264
  ],
  [
    82.6886775,
    50.8107648
  ],
  [
    82.6968226,
    50.8098545
  ],
  [
    82.7015323,
    50.8122002
  ],
  [
    82.7039386,
    50.8163435
  ],
  [
    82.7099723,
    50.8225069
  ],
  [
    82.7092671,
    50.8256997
  ],
  [
    82.7106871,
    50.8281648
  ],
  [
    82.7064741,
    50.8318982
  ],
  [
    82.7391938,
    50.8504319
  ],
  [
    82.7306832,
    50.857299
  ],
  [
    82.729081,
    50.8669107
  ],
  [
    82.7253925,
    50.8768276
  ],
  [
    82.717451,
    50.8939978
  ],
  [
    82.7187596,
    50.8975625
  ],
  [
    82.7173386,
    50.90093
  ],
  [
    82.7279056,
    50.9045275
  ],
  [
    82.7314149,
    50.9072059
  ],
  [
    82.7332484,
    50.9125905
  ],
  [
    82.7331552,
    50.9153598
  ],
  [
    82.7288007,
    50.9228584
  ],
  [
    82.7300602,
    50.9252994
  ],
  [
    82.7328173,
    50.9272716
  ],
  [
    82.7713517,
    50.9271287
  ],
  [
    82.7736295,
    50.9245427
  ],
  [
    82.7780307,
    50.9234016
  ],
  [
    82.7812715,
    50.9199856
  ],
  [
    82.7830155,
    50.9198092
  ],
  [
    82.7852547,
    50.92099
  ],
  [
    82.7876445,
    50.9207321
  ],
  [
    82.7942112,
    50.9180652
  ],
  [
    82.8379931,
    50.9180323
  ],
  [
    82.847812,
    50.90966
  ],
  [
    82.861305,
    50.90597
  ],
  [
    82.8663085,
    50.9044233
  ],
  [
    82.8683171,
    50.9010875
  ],
  [
    82.870203,
    50.8992395
  ],
  [
    82.8748348,
    50.8980669
  ],
  [
    82.8788882,
    50.8975302
  ],
  [
    82.8854664,
    50.8979673
  ],
  [
    82.886806,
    50.905132
  ],
  [
    82.8891154,
    50.9064951
  ],
  [
    82.8958682,
    50.9048269
  ],
  [
    82.8993665,
    50.9051789
  ],
  [
    82.9008551,
    50.9061412
  ],
  [
    82.9061311,
    50.9071687
  ],
  [
    82.9110476,
    50.9076824
  ],
  [
    82.9149494,
    50.9044145
  ],
  [
    82.918581,
    50.9024065
  ],
  [
    82.9232389,
    50.9007635
  ],
  [
    82.9266862,
    50.8986557
  ],
  [
    82.9309757,
    50.8981577
  ],
  [
    82.9422652,
    50.8947386
  ],
  [
    82.9617873,
    50.8932929
  ],
  [
    82.9715379,
    50.8892551
  ],
  [
    82.9759755,
    50.8856366
  ],
  [
    82.9796426,
    50.8838498
  ],
  [
    82.9843166,
    50.8889401
  ],
  [
    82.9893561,
    50.8971567
  ],
  [
    82.9899613,
    50.9022768
  ],
  [
    82.9960814,
    50.9133575
  ],
  [
    83.0059934,
    50.9251995
  ],
  [
    83.0290308,
    50.9289653
  ],
  [
    83.0345693,
    50.9293808
  ],
  [
    83.0388852,
    50.9300912
  ],
  [
    83.0473288,
    50.9332333
  ],
  [
    83.0529621,
    50.9326605
  ],
  [
    83.0589646,
    50.9346123
  ],
  [
    83.0728539,
    50.9412966
  ],
  [
    83.0802932,
    50.9455816
  ],
  [
    83.0886263,
    50.9488605
  ],
  [
    83.0916914,
    50.9508719
  ],
  [
    83.0923938,
    50.9553371
  ],
  [
    83.0977258,
    50.9574488
  ],
  [
    83.1004976,
    50.9581166
  ],
  [
    83.1058743,
    50.962569
  ],
  [
    83.1124788,
    50.9659531
  ],
  [
    83.1144854,
    50.9694943
  ],
  [
    83.114776,
    50.9783532
  ],
  [
    83.1186366,
    50.9837544
  ],
  [
    83.1223062,
    50.9845235
  ],
  [
    83.1202112,
    50.9944943
  ],
  [
    83.119193,
    51.0012376
  ],
  [
    83.1220341,
    51.0047971
  ],
  [
    83.1210212,
    51.0110042
  ],
  [
    83.1397845,
    51.0116659
  ],
  [
    83.1462955,
    51.0100765
  ],
  [
    83.1521325,
    51.0120098
  ],
  [
    83.160929,
    51.0123931
  ],
  [
    83.1750429,
    51.0115701
  ],
  [
    83.1774823,
    51.0083648
  ],
  [
    83.1796199,
    51.0037975
  ],
  [
    83.1876362,
    50.9999678
  ],
  [
    83.2020454,
    50.9933378
  ],
  [
    83.2060446,
    50.9933553
  ],
  [
    83.2256795,
    51.0011814
  ],
  [
    83.2580218,
    51.0034198
  ],
  [
    83.2683749,
    51.006025
  ],
  [
    83.2771448,
    51.013343
  ],
  [
    83.2844364,
    51.0150364
  ],
  [
    83.2915581,
    51.008962
  ],
  [
    83.3201138,
    51.010511
  ],
  [
    83.329825,
    51.0090547
  ],
  [
    83.3459239,
    51.0093976
  ],
  [
    83.3582012,
    51.0075455
  ],
  [
    83.3582263,
    51.0019571
  ],
  [
    83.3662971,
    50.9986147
  ],
  [
    83.3695677,
    50.9986938
  ],
  [
    83.3707753,
    51.0005779
  ],
  [
    83.3746742,
    51.0016328
  ],
  [
    83.3794709,
    51.001419
  ],
  [
    83.3871094,
    51.0037416
  ],
  [
    83.3952068,
    51.0098935
  ],
  [
    83.4043065,
    51.0131384
  ],
  [
    83.4071645,
    51.0131887
  ],
  [
    83.4123809,
    51.0110008
  ],
  [
    83.4171627,
    51.0100749
  ],
  [
    83.4249084,
    51.0104852
  ],
  [
    83.4324488,
    51.0093789
  ],
  [
    83.4398071,
    51.0049494
  ],
  [
    83.4368883,
    50.9953846
  ],
  [
    83.4302231,
    50.9867139
  ],
  [
    83.4474816,
    50.9771369
  ],
  [
    83.4782067,
    50.9768058
  ],
  [
    83.4888187,
    50.9792389
  ],
  [
    83.4995071,
    50.9766292
  ],
  [
    83.5024137,
    50.9740053
  ],
  [
    83.5013112,
    50.9681724
  ],
  [
    83.5043918,
    50.9579256
  ],
  [
    83.5010759,
    50.952505
  ],
  [
    83.5069156,
    50.9497161
  ],
  [
    83.5122732,
    50.9485465
  ],
  [
    83.5375945,
    50.9608683
  ],
  [
    83.5431395,
    50.9589979
  ],
  [
    83.551704,
    50.9591616
  ],
  [
    83.5604668,
    50.9575902
  ],
  [
    83.5633327,
    50.9562524
  ],
  [
    83.5666729,
    50.9559537
  ],
  [
    83.5722768,
    50.9548811
  ],
  [
    83.5752748,
    50.9528935
  ],
  [
    83.579171,
    50.9481973
  ],
  [
    83.5842875,
    50.9467184
  ],
  [
    83.5890055,
    50.9474347
  ],
  [
    83.5935072,
    50.9494263
  ],
  [
    83.5943167,
    50.9559138
  ],
  [
    83.5965872,
    50.9583288
  ],
  [
    83.5976998,
    50.9607349
  ],
  [
    83.6012493,
    50.9591813
  ],
  [
    83.6028215,
    50.9589103
  ],
  [
    83.6056848,
    50.959025
  ],
  [
    83.6078694,
    50.9562311
  ],
  [
    83.609872,
    50.9547819
  ],
  [
    83.6128829,
    50.9540577
  ],
  [
    83.6163235,
    50.9540724
  ],
  [
    83.6215707,
    50.9500971
  ],
  [
    83.6269774,
    50.9498708
  ],
  [
    83.6267041,
    50.9466138
  ],
  [
    83.6275052,
    50.9447129
  ],
  [
    83.6302324,
    50.943596
  ],
  [
    83.6338119,
    50.9439826
  ],
  [
    83.6372891,
    50.9466138
  ],
  [
    83.6379709,
    50.9498032
  ],
  [
    83.642148,
    50.9520043
  ],
  [
    83.6440507,
    50.9530068
  ],
  [
    83.6487039,
    50.9547766
  ],
  [
    83.6511756,
    50.9521048
  ],
  [
    83.6520435,
    50.9453853
  ],
  [
    83.6562059,
    50.9428536
  ],
  [
    83.6557968,
    50.9414681
  ],
  [
    83.6564957,
    50.9395348
  ],
  [
    83.6629225,
    50.9368872
  ],
  [
    83.6639767,
    50.9346776
  ],
  [
    83.6656804,
    50.9330341
  ],
  [
    83.6710868,
    50.931268
  ],
  [
    83.6786076,
    50.9279627
  ],
  [
    83.680433,
    50.9236942
  ],
  [
    83.6808676,
    50.9218354
  ],
  [
    83.6798881,
    50.920554
  ],
  [
    83.6784779,
    50.9195528
  ],
  [
    83.6770814,
    50.9171533
  ],
  [
    83.677846,
    50.9154467
  ],
  [
    83.6847195,
    50.9134081
  ],
  [
    83.6926192,
    50.9089643
  ],
  [
    83.6944779,
    50.9073039
  ],
  [
    83.6943424,
    50.9060708
  ],
  [
    83.6950781,
    50.9048864
  ],
  [
    83.6972293,
    50.9035498
  ],
  [
    83.6995333,
    50.9014251
  ],
  [
    83.7002497,
    50.8989829
  ],
  [
    83.703568,
    50.8962114
  ],
  [
    83.7116038,
    50.8933514
  ],
  [
    83.7143839,
    50.8929728
  ],
  [
    83.7160297,
    50.8934003
  ],
  [
    83.7210924,
    50.8925499
  ],
  [
    83.7269521,
    50.8931544
  ],
  [
    83.7340608,
    50.8923266
  ],
  [
    83.7433456,
    50.8941851
  ],
  [
    83.7546446,
    50.8900747
  ],
  [
    83.7580304,
    50.8898731
  ],
  [
    83.7607002,
    50.8905554
  ],
  [
    83.7627401,
    50.8903827
  ],
  [
    83.7655194,
    50.8891045
  ],
  [
    83.7680796,
    50.889139
  ],
  [
    83.7711678,
    50.8897416
  ],
  [
    83.7896459,
    50.8876354
  ],
  [
    83.7901667,
    50.887585
  ],
  [
    83.7974142,
    50.8878163
  ],
  [
    83.8004881,
    50.8863753
  ],
  [
    83.8021801,
    50.8838311
  ],
  [
    83.8066076,
    50.8828348
  ],
  [
    83.8114299,
    50.8837066
  ],
  [
    83.8153216,
    50.8825145
  ],
  [
    83.8188466,
    50.8831551
  ],
  [
    83.8211591,
    50.8830305
  ],
  [
    83.8243532,
    50.8835698
  ],
  [
    83.828181,
    50.8803439
  ],
  [
    83.833666,
    50.8800221
  ],
  [
    83.8348645,
    50.8761608
  ],
  [
    83.8365995,
    50.8737365
  ],
  [
    83.8357906,
    50.8726571
  ],
  [
    83.8363262,
    50.8699516
  ],
  [
    83.8431501,
    50.8669969
  ],
  [
    83.8476991,
    50.8664552
  ],
  [
    83.8499736,
    50.8647488
  ],
  [
    83.8494551,
    50.8638013
  ],
  [
    83.8473551,
    50.8608367
  ],
  [
    83.8471357,
    50.8581808
  ],
  [
    83.8487956,
    50.8561825
  ],
  [
    83.851282,
    50.8550935
  ],
  [
    83.8573512,
    50.8554601
  ],
  [
    83.86096,
    50.8559449
  ],
  [
    83.8676118,
    50.8516372
  ],
  [
    83.8719463,
    50.8491445
  ],
  [
    83.8761949,
    50.8484942
  ],
  [
    83.8769953,
    50.8472065
  ],
  [
    83.8755839,
    50.8436003
  ],
  [
    83.8752848,
    50.8404649
  ],
  [
    83.8762618,
    50.8385005
  ],
  [
    83.8887274,
    50.8326925
  ],
  [
    83.8928461,
    50.8319088
  ],
  [
    83.897072,
    50.832119
  ],
  [
    83.9002704,
    50.8285205
  ],
  [
    83.903071,
    50.8245645
  ],
  [
    83.9104902,
    50.8162608
  ],
  [
    83.9352667,
    50.8102688
  ],
  [
    83.9437916,
    50.807809
  ],
  [
    83.947132,
    50.8065741
  ],
  [
    83.9509346,
    50.8043071
  ],
  [
    83.9559326,
    50.8034582
  ],
  [
    83.9624119,
    50.8024768
  ],
  [
    83.9643364,
    50.8009987
  ],
  [
    83.9647876,
    50.7983608
  ],
  [
    83.9669308,
    50.7966853
  ],
  [
    83.9701305,
    50.7958272
  ],
  [
    83.9713784,
    50.7957202
  ],
  [
    83.9726623,
    50.7953988
  ],
  [
    83.9734815,
    50.7898237
  ],
  [
    83.9730347,
    50.7865009
  ],
  [
    83.9748033,
    50.7838559
  ],
  [
    83.9766108,
    50.7829126
  ],
  [
    83.9765739,
    50.781303
  ],
  [
    83.9762238,
    50.779943
  ],
  [
    83.9670154,
    50.7735674
  ],
  [
    83.9622819,
    50.7689981
  ],
  [
    83.9582475,
    50.7663194
  ],
  [
    83.9546646,
    50.7656784
  ],
  [
    83.9516926,
    50.7635669
  ],
  [
    83.9502601,
    50.7592623
  ],
  [
    83.9508735,
    50.7572143
  ],
  [
    83.9520094,
    50.7559567
  ],
  [
    83.9517482,
    50.7539374
  ],
  [
    83.9504963,
    50.7519893
  ],
  [
    83.9501373,
    50.7507202
  ],
  [
    83.9489166,
    50.7505568
  ],
  [
    83.9510436,
    50.7463971
  ],
  [
    83.9569681,
    50.7420246
  ],
  [
    83.958454,
    50.7404809
  ],
  [
    83.9605103,
    50.7395151
  ],
  [
    83.9613778,
    50.7380918
  ],
  [
    83.9637072,
    50.7364045
  ],
  [
    83.9654688,
    50.7328669
  ],
  [
    83.9661114,
    50.7279926
  ],
  [
    83.975748,
    50.7211665
  ],
  [
    83.9779901,
    50.7205227
  ],
  [
    83.9792132,
    50.7204548
  ],
  [
    83.979951,
    50.7200877
  ],
  [
    83.9811324,
    50.7169232
  ],
  [
    83.983655,
    50.715727
  ],
  [
    83.9856291,
    50.715333
  ],
  [
    83.986335,
    50.7141613
  ],
  [
    83.9868122,
    50.7118452
  ],
  [
    83.9872473,
    50.709985
  ],
  [
    83.9892713,
    50.7087056
  ],
  [
    83.9924809,
    50.7078799
  ],
  [
    83.9941609,
    50.7079434
  ],
  [
    83.9976714,
    50.7068954
  ],
  [
    83.9992009,
    50.7067366
  ],
  [
    84.0058708,
    50.7054186
  ],
  [
    84.0093613,
    50.7038511
  ],
  [
    84.0110457,
    50.7026687
  ],
  [
    84.0170217,
    50.699638
  ],
  [
    84.0232179,
    50.697862
  ],
  [
    84.0239497,
    50.696779
  ],
  [
    84.0251732,
    50.6962188
  ],
  [
    84.0259807,
    50.6922839
  ],
  [
    84.0278112,
    50.690902
  ],
  [
    84.0285562,
    50.6893948
  ],
  [
    84.029614,
    50.6880544
  ],
  [
    84.031055,
    50.6869831
  ],
  [
    84.0335741,
    50.6853419
  ],
  [
    84.0346523,
    50.6829906
  ],
  [
    84.0363825,
    50.6815925
  ],
  [
    84.0392159,
    50.6803532
  ],
  [
    84.0395669,
    50.6782876
  ],
  [
    84.0410213,
    50.6769688
  ],
  [
    84.0447754,
    50.6762481
  ],
  [
    84.0460393,
    50.674113
  ],
  [
    84.048622,
    50.6732866
  ],
  [
    84.0494013,
    50.6728834
  ],
  [
    84.0500733,
    50.6719954
  ],
  [
    84.0495592,
    50.6692302
  ],
  [
    84.0518101,
    50.6670278
  ],
  [
    84.0551841,
    50.6664167
  ],
  [
    84.0553766,
    50.6651219
  ],
  [
    84.0577963,
    50.6626821
  ],
  [
    84.0628614,
    50.6614582
  ],
  [
    84.0633462,
    50.6609834
  ],
  [
    84.0648001,
    50.6604551
  ],
  [
    84.0656512,
    50.6604551
  ],
  [
    84.0687561,
    50.6596771
  ],
  [
    84.0716653,
    50.6591169
  ],
  [
    84.0709556,
    50.6580601
  ],
  [
    84.0705858,
    50.6555683
  ],
  [
    84.0708929,
    50.6531281
  ],
  [
    84.0742063,
    50.651787
  ],
  [
    84.0743034,
    50.6506763
  ],
  [
    84.0737892,
    50.6488045
  ],
  [
    84.074114,
    50.646451
  ],
  [
    84.0736484,
    50.6452542
  ],
  [
    84.0739046,
    50.6439219
  ],
  [
    84.0754969,
    50.6413459
  ],
  [
    84.077553,
    50.6403123
  ],
  [
    84.0812913,
    50.6399929
  ],
  [
    84.0812665,
    50.6387651
  ],
  [
    84.0802137,
    50.6370822
  ],
  [
    84.0802771,
    50.6360297
  ],
  [
    84.0807346,
    50.6348461
  ],
  [
    84.0826229,
    50.6336146
  ],
  [
    84.0859963,
    50.6326759
  ],
  [
    84.0906351,
    50.6320397
  ],
  [
    84.0940578,
    50.6311125
  ],
  [
    84.0944494,
    50.6297134
  ],
  [
    84.095515,
    50.628099
  ],
  [
    84.0992261,
    50.6259675
  ],
  [
    84.1038273,
    50.6239791
  ],
  [
    84.107825,
    50.6230903
  ],
  [
    84.1092555,
    50.61941
  ],
  [
    84.1124654,
    50.6173405
  ],
  [
    84.1138527,
    50.6151274
  ],
  [
    84.1134857,
    50.6128961
  ],
  [
    84.1135744,
    50.6113322
  ],
  [
    84.1152943,
    50.6091944
  ],
  [
    84.1184858,
    50.6081479
  ],
  [
    84.1222092,
    50.6079454
  ],
  [
    84.1280479,
    50.6068805
  ],
  [
    84.1287839,
    50.6070942
  ],
  [
    84.1296208,
    50.6074755
  ],
  [
    84.1311334,
    50.6084043
  ],
  [
    84.1325125,
    50.6068087
  ],
  [
    84.1354026,
    50.6056046
  ],
  [
    84.137548,
    50.6052333
  ],
  [
    84.1428041,
    50.6061654
  ],
  [
    84.1439734,
    50.6062389
  ],
  [
    84.144622,
    50.6045741
  ],
  [
    84.145274,
    50.6028394
  ],
  [
    84.1477814,
    50.6009455
  ],
  [
    84.1501886,
    50.5984626
  ],
  [
    84.1516429,
    50.5950564
  ],
  [
    84.1519062,
    50.5925779
  ],
  [
    84.1547648,
    50.588837
  ],
  [
    84.156031,
    50.5877385
  ],
  [
    84.156871,
    50.5873405
  ],
  [
    84.156031,
    50.5856371
  ],
  [
    84.1558206,
    50.5841151
  ],
  [
    84.1539624,
    50.5827338
  ],
  [
    84.1529092,
    50.5817427
  ],
  [
    84.152921,
    50.5802099
  ],
  [
    84.1517989,
    50.5753056
  ],
  [
    84.1517487,
    50.5739441
  ],
  [
    84.1534462,
    50.5720809
  ],
  [
    84.1592678,
    50.5714608
  ],
  [
    84.1600302,
    50.570605
  ],
  [
    84.1612005,
    50.5702108
  ],
  [
    84.1625493,
    50.5703135
  ],
  [
    84.1652298,
    50.5701551
  ],
  [
    84.1673904,
    50.5699771
  ],
  [
    84.1668552,
    50.5674962
  ],
  [
    84.1682559,
    50.565638
  ],
  [
    84.1710162,
    50.5637455
  ],
  [
    84.1720818,
    50.563411
  ],
  [
    84.1697123,
    50.5598589
  ],
  [
    84.1695709,
    50.5589244
  ],
  [
    84.1700257,
    50.5567367
  ],
  [
    84.1687845,
    50.5544586
  ],
  [
    84.1685839,
    50.5532319
  ],
  [
    84.1691364,
    50.5517446
  ],
  [
    84.1709449,
    50.5505308
  ],
  [
    84.1761508,
    50.5516105
  ],
  [
    84.1804273,
    50.548982
  ],
  [
    84.1901675,
    50.5454922
  ],
  [
    84.1967257,
    50.5416217
  ],
  [
    84.2087203,
    50.5435516
  ],
  [
    84.213016,
    50.5437221
  ],
  [
    84.2156352,
    50.5433263
  ],
  [
    84.2186574,
    50.5415774
  ],
  [
    84.2229956,
    50.5405992
  ],
  [
    84.2305773,
    50.5402423
  ],
  [
    84.2320713,
    50.5355949
  ],
  [
    84.2346301,
    50.5322224
  ],
  [
    84.2382417,
    50.5295933
  ],
  [
    84.2419136,
    50.5237414
  ],
  [
    84.2421382,
    50.5198901
  ],
  [
    84.2432417,
    50.5176226
  ],
  [
    84.2487069,
    50.5155657
  ],
  [
    84.2438998,
    50.5106791
  ],
  [
    84.2383101,
    50.5082815
  ],
  [
    84.2353063,
    50.5042146
  ],
  [
    84.2375128,
    50.5009828
  ],
  [
    84.2352078,
    50.49637
  ],
  [
    84.2344986,
    50.4933923
  ],
  [
    84.2356185,
    50.4897509
  ],
  [
    84.2334137,
    50.4865334
  ],
  [
    84.2325711,
    50.4830655
  ],
  [
    84.2300152,
    50.4811671
  ],
  [
    84.2188308,
    50.4762889
  ],
  [
    84.2125876,
    50.4735732
  ],
  [
    84.2089086,
    50.4727408
  ],
  [
    84.2045909,
    50.4703535
  ],
  [
    84.2039747,
    50.4689746
  ],
  [
    84.2040314,
    50.4676535
  ],
  [
    84.2045571,
    50.465313
  ],
  [
    84.2065435,
    50.4541438
  ],
  [
    84.2117553,
    50.4456801
  ],
  [
    84.2150463,
    50.4387252
  ],
  [
    84.2164508,
    50.4342309
  ],
  [
    84.2267093,
    50.4307652
  ],
  [
    84.2283682,
    50.4259772
  ],
  [
    84.2357557,
    50.4145156
  ],
  [
    84.2383437,
    50.4124773
  ],
  [
    84.2398065,
    50.410155
  ],
  [
    84.2419879,
    50.4086615
  ],
  [
    84.2433309,
    50.4030801
  ],
  [
    84.2447691,
    50.3988153
  ],
  [
    84.2447489,
    50.3969514
  ],
  [
    84.245728,
    50.3927506
  ],
  [
    84.2416643,
    50.390669
  ],
  [
    84.2396862,
    50.3875249
  ],
  [
    84.2420265,
    50.3768546
  ],
  [
    84.2493409,
    50.3708774
  ],
  [
    84.2550939,
    50.3695448
  ],
  [
    84.2604326,
    50.3661125
  ],
  [
    84.2621957,
    50.3646262
  ],
  [
    84.2642151,
    50.3613601
  ],
  [
    84.2682897,
    50.3565842
  ],
  [
    84.2695591,
    50.3537549
  ],
  [
    84.2690978,
    50.3518764
  ],
  [
    84.2661971,
    50.3506259
  ],
  [
    84.2659785,
    50.3483399
  ],
  [
    84.2690733,
    50.3413522
  ],
  [
    84.2695448,
    50.3383385
  ],
  [
    84.2684821,
    50.3367524
  ],
  [
    84.2660479,
    50.3352562
  ],
  [
    84.2660265,
    50.3325995
  ],
  [
    84.2644118,
    50.3307742
  ],
  [
    84.262238,
    50.3280283
  ],
  [
    84.2617622,
    50.3255682
  ],
  [
    84.2629635,
    50.3238977
  ],
  [
    84.2659458,
    50.3227257
  ],
  [
    84.2693185,
    50.3229141
  ],
  [
    84.2720293,
    50.3206593
  ],
  [
    84.2707698,
    50.318923
  ],
  [
    84.2664349,
    50.3173484
  ],
  [
    84.2632161,
    50.3144311
  ],
  [
    84.2622297,
    50.3101543
  ],
  [
    84.2603348,
    50.3073527
  ],
  [
    84.2597117,
    50.3010523
  ],
  [
    84.2518466,
    50.2971724
  ],
  [
    84.2507044,
    50.2951328
  ],
  [
    84.2506785,
    50.2925126
  ],
  [
    84.251587,
    50.2901411
  ],
  [
    84.2560518,
    50.2893119
  ],
  [
    84.2549615,
    50.2861109
  ],
  [
    84.2576871,
    50.2834903
  ],
  [
    84.2599195,
    50.2825117
  ],
  [
    84.2605944,
    50.2788624
  ],
  [
    84.2648255,
    50.2761086
  ],
  [
    84.265734,
    50.273222
  ],
  [
    84.2714967,
    50.2712975
  ],
  [
    84.2747495,
    50.2682557
  ],
  [
    84.2787915,
    50.2669981
  ],
  [
    84.2786048,
    50.2642363
  ],
  [
    84.280651,
    50.2615431
  ],
  [
    84.2832857,
    50.2605685
  ],
  [
    84.2849441,
    50.2587815
  ],
  [
    84.288448,
    50.2573536
  ],
  [
    84.2904408,
    50.2570714
  ],
  [
    84.2902,
    50.2561394
  ],
  [
    84.2916578,
    50.2539076
  ],
  [
    84.2939448,
    50.2522828
  ],
  [
    84.2969138,
    50.2513593
  ],
  [
    84.300498,
    50.2484516
  ],
  [
    84.3032531,
    50.2477161
  ],
  [
    84.3080945,
    50.2482464
  ],
  [
    84.3107096,
    50.2468052
  ],
  [
    84.3183194,
    50.2470276
  ],
  [
    84.3200903,
    50.2443136
  ],
  [
    84.3194095,
    50.2424266
  ],
  [
    84.3208601,
    50.2401855
  ],
  [
    84.3249793,
    50.2389024
  ],
  [
    84.3235884,
    50.235994
  ],
  [
    84.3259685,
    50.2329756
  ],
  [
    84.3258485,
    50.2285811
  ],
  [
    84.329085,
    50.2264592
  ],
  [
    84.3342741,
    50.2256036
  ],
  [
    84.3367349,
    50.2216504
  ],
  [
    84.3394632,
    50.2204867
  ],
  [
    84.3473983,
    50.218383
  ],
  [
    84.3574604,
    50.2138081
  ],
  [
    84.3594452,
    50.2117952
  ],
  [
    84.3618265,
    50.2086679
  ],
  [
    84.3615778,
    50.2071083
  ],
  [
    84.3625226,
    50.2054649
  ],
  [
    84.3668318,
    50.2064617
  ],
  [
    84.37112,
    50.2092937
  ],
  [
    84.371403,
    50.2116777
  ],
  [
    84.3765497,
    50.2144663
  ],
  [
    84.3850833,
    50.2152607
  ],
  [
    84.3910652,
    50.2158311
  ],
  [
    84.3937061,
    50.2155162
  ],
  [
    84.3992079,
    50.2137525
  ],
  [
    84.4032891,
    50.2096671
  ],
  [
    84.4074208,
    50.2088382
  ],
  [
    84.4098473,
    50.2092264
  ],
  [
    84.4125216,
    50.2120305
  ],
  [
    84.412569,
    50.2164529
  ],
  [
    84.4159301,
    50.2170509
  ],
  [
    84.4216721,
    50.2248435
  ],
  [
    84.4239331,
    50.2268933
  ],
  [
    84.4315582,
    50.2300155
  ],
  [
    84.4338384,
    50.236084
  ],
  [
    84.4469701,
    50.2463503
  ],
  [
    84.4568008,
    50.2472562
  ],
  [
    84.4765198,
    50.2406672
  ],
  [
    84.4769495,
    50.2388349
  ],
  [
    84.4793493,
    50.2388942
  ],
  [
    84.482009,
    50.2386007
  ],
  [
    84.48498,
    50.2392599
  ],
  [
    84.4900346,
    50.2376727
  ],
  [
    84.4919382,
    50.2363249
  ],
  [
    84.4949042,
    50.2350564
  ],
  [
    84.4964698,
    50.2340216
  ],
  [
    84.4981258,
    50.2324688
  ],
  [
    84.49894,
    50.232052
  ],
  [
    84.5001013,
    50.2317897
  ],
  [
    84.5028077,
    50.2314659
  ],
  [
    84.5069313,
    50.2284123
  ],
  [
    84.509332,
    50.2257525
  ],
  [
    84.5084941,
    50.224448
  ],
  [
    84.5124901,
    50.2207179
  ],
  [
    84.5157846,
    50.215063
  ],
  [
    84.5201126,
    50.2130023
  ],
  [
    84.5216297,
    50.2110808
  ],
  [
    84.5240652,
    50.2092387
  ],
  [
    84.5278785,
    50.2084515
  ],
  [
    84.5302577,
    50.2060354
  ],
  [
    84.5333953,
    50.2053699
  ],
  [
    84.5366859,
    50.205696
  ],
  [
    84.5413602,
    50.2073965
  ],
  [
    84.5477989,
    50.2047927
  ],
  [
    84.5540969,
    50.2034542
  ],
  [
    84.562647,
    50.1983912
  ],
  [
    84.5675221,
    50.1968412
  ],
  [
    84.5695942,
    50.1979632
  ],
  [
    84.5696638,
    50.2012537
  ],
  [
    84.5811688,
    50.2080688
  ],
  [
    84.5986706,
    50.2100696
  ],
  [
    84.6027517,
    50.2117073
  ],
  [
    84.6055569,
    50.2119278
  ],
  [
    84.6120259,
    50.2108297
  ],
  [
    84.6158706,
    50.2109803
  ],
  [
    84.6281869,
    50.2106789
  ],
  [
    84.6339276,
    50.2071385
  ],
  [
    84.6498239,
    50.2001086
  ],
  [
    84.6584938,
    50.1997841
  ],
  [
    84.66137,
    50.1983222
  ],
  [
    84.6606739,
    50.1952727
  ],
  [
    84.6626991,
    50.1937373
  ],
  [
    84.6714263,
    50.1932761
  ],
  [
    84.6785772,
    50.1913609
  ],
  [
    84.6920117,
    50.1886227
  ],
  [
    84.6946697,
    50.1829185
  ],
  [
    84.6994711,
    50.1778279
  ],
  [
    84.7105207,
    50.1731235
  ],
  [
    84.7271804,
    50.1642278
  ],
  [
    84.7323641,
    50.1537314
  ],
  [
    84.7441032,
    50.1441937
  ],
  [
    84.7492686,
    50.1400029
  ],
  [
    84.7577173,
    50.1368998
  ],
  [
    84.7747927,
    50.1393227
  ],
  [
    84.7811801,
    50.1429497
  ],
  [
    84.7854656,
    50.1446079
  ],
  [
    84.7916944,
    50.144316
  ],
  [
    84.8052256,
    50.1472004
  ],
  [
    84.8063425,
    50.1466761
  ],
  [
    84.8175394,
    50.1451985
  ],
  [
    84.8253745,
    50.1429846
  ],
  [
    84.8134244,
    50.1361621
  ],
  [
    84.8151104,
    50.1318951
  ],
  [
    84.8132264,
    50.1288199
  ],
  [
    84.8142713,
    50.1249682
  ],
  [
    84.8139079,
    50.120772
  ],
  [
    84.8166148,
    50.1166971
  ],
  [
    84.8325813,
    50.1142754
  ],
  [
    84.8334999,
    50.1050999
  ],
  [
    84.8412514,
    50.0988472
  ],
  [
    84.8506999,
    50.0938999
  ],
  [
    84.8563631,
    50.0921128
  ],
  [
    84.8603597,
    50.0930984
  ],
  [
    84.8643733,
    50.0960678
  ],
  [
    84.87278,
    50.095991
  ],
  [
    84.8748756,
    50.097573
  ],
  [
    84.8768451,
    50.1020799
  ],
  [
    84.8838259,
    50.1019615
  ],
  [
    84.8865853,
    50.1042151
  ],
  [
    84.8963578,
    50.1002258
  ],
  [
    84.9052835,
    50.0954081
  ],
  [
    84.908574,
    50.0955906
  ],
  [
    84.9183892,
    50.0930631
  ],
  [
    84.9239949,
    50.0921596
  ],
  [
    84.9287388,
    50.0928507
  ],
  [
    84.9366773,
    50.0958828
  ],
  [
    84.942693,
    50.097015
  ],
  [
    84.9458311,
    50.0966095
  ],
  [
    84.9515999,
    50.0911999
  ],
  [
    84.9662999,
    50.0724999
  ],
  [
    84.9748274,
    50.0669581
  ],
  [
    84.9864452,
    50.0644708
  ],
  [
    85.0062454,
    50.0581669
  ],
  [
    85.0241391,
    50.0602182
  ],
  [
    85.026135,
    50.0540746
  ],
  [
    85.0355435,
    50.0524943
  ],
  [
    85.0394823,
    50.0484214
  ],
  [
    85.0335224,
    50.0418265
  ],
  [
    85.0339848,
    50.0365873
  ],
  [
    85.0323637,
    50.0333558
  ],
  [
    85.0325326,
    50.0326016
  ],
  [
    85.0366276,
    50.0268588
  ],
  [
    85.0373823,
    50.0201948
  ],
  [
    85.035365,
    50.0188758
  ],
  [
    85.0492581,
    50.0103973
  ],
  [
    85.0378305,
    50.0090701
  ],
  [
    85.0328986,
    50.0035715
  ],
  [
    85.0321626,
    49.9990559
  ],
  [
    85.0252916,
    49.9999241
  ],
  [
    85.0084594,
    49.995345
  ],
  [
    84.9996891,
    49.9905195
  ],
  [
    84.9934653,
    49.988835
  ],
  [
    84.983576,
    49.983967
  ],
  [
    84.9796927,
    49.9803063
  ],
  [
    84.9780463,
    49.9762943
  ],
  [
    84.9820148,
    49.975141
  ],
  [
    84.9894279,
    49.9741098
  ],
  [
    84.9930481,
    49.9725033
  ],
  [
    84.9947261,
    49.9701275
  ],
  [
    84.9946295,
    49.9682302
  ],
  [
    84.9729465,
    49.9542789
  ],
  [
    84.9785302,
    49.9536065
  ],
  [
    84.9849266,
    49.9499191
  ],
  [
    84.9793021,
    49.9456416
  ],
  [
    84.978111,
    49.9425262
  ],
  [
    84.9836473,
    49.9345574
  ],
  [
    84.9836972,
    49.9316846
  ],
  [
    84.9871379,
    49.9234157
  ],
  [
    84.9875942,
    49.9206429
  ],
  [
    84.9841429,
    49.9133174
  ],
  [
    84.9854889,
    49.9067722
  ],
  [
    84.9952089,
    49.9055937
  ],
  [
    85.0001047,
    49.9033687
  ],
  [
    85.0006323,
    49.9008908
  ],
  [
    85.0039838,
    49.8999075
  ],
  [
    85.004756,
    49.8982758
  ],
  [
    85.0114973,
    49.8982907
  ],
  [
    85.0196749,
    49.8946373
  ],
  [
    85.0232711,
    49.8910524
  ],
  [
    85.0344073,
    49.8888854
  ],
  [
    85.0417282,
    49.8862801
  ],
  [
    85.0546588,
    49.8863312
  ],
  [
    85.0596988,
    49.8850363
  ],
  [
    85.069781,
    49.8759228
  ],
  [
    85.0803856,
    49.8685762
  ],
  [
    85.0845827,
    49.8601758
  ],
  [
    85.0874357,
    49.858013
  ],
  [
    85.1004266,
    49.8521156
  ],
  [
    85.1008287,
    49.8453266
  ],
  [
    85.1054987,
    49.8431157
  ],
  [
    85.1043555,
    49.8411592
  ],
  [
    85.1051909,
    49.8385789
  ],
  [
    85.1049323,
    49.8344192
  ],
  [
    85.100237,
    49.827851
  ],
  [
    85.1000836,
    49.8258171
  ],
  [
    85.101607,
    49.8242126
  ],
  [
    85.1084661,
    49.8227708
  ],
  [
    85.1102666,
    49.8196772
  ],
  [
    85.1124649,
    49.8183156
  ],
  [
    85.1162021,
    49.8176631
  ],
  [
    85.1212802,
    49.817961
  ],
  [
    85.1233376,
    49.8145637
  ],
  [
    85.1285916,
    49.8128614
  ],
  [
    85.1305481,
    49.8095561
  ],
  [
    85.1323508,
    49.808109
  ],
  [
    85.1324387,
    49.8041223
  ],
  [
    85.1355603,
    49.8003481
  ],
  [
    85.139913,
    49.7980494
  ],
  [
    85.1440966,
    49.7966549
  ],
  [
    85.1445651,
    49.7964186
  ],
  [
    85.1434882,
    49.7931025
  ],
  [
    85.1396815,
    49.7906451
  ],
  [
    85.1403613,
    49.788129
  ],
  [
    85.1395003,
    49.7853787
  ],
  [
    85.1415849,
    49.7831549
  ],
  [
    85.1448704,
    49.7811213
  ],
  [
    85.1431256,
    49.778634
  ],
  [
    85.1481531,
    49.7737659
  ],
  [
    85.1489489,
    49.7703956
  ],
  [
    85.1479305,
    49.7691308
  ],
  [
    85.1487477,
    49.7679097
  ],
  [
    85.1505902,
    49.7672577
  ],
  [
    85.1560308,
    49.7673655
  ],
  [
    85.1590154,
    49.7678174
  ],
  [
    85.163943,
    49.7693233
  ],
  [
    85.1656995,
    49.7690824
  ],
  [
    85.166959,
    49.7653945
  ],
  [
    85.1718613,
    49.7627249
  ],
  [
    85.1738618,
    49.759615
  ],
  [
    85.1728286,
    49.7580528
  ],
  [
    85.1752877,
    49.7545638
  ],
  [
    85.1819553,
    49.7545221
  ],
  [
    85.1862973,
    49.752661
  ],
  [
    85.1923857,
    49.7505149
  ],
  [
    85.1974004,
    49.7472636
  ],
  [
    85.2033689,
    49.7452678
  ],
  [
    85.2040504,
    49.7417661
  ],
  [
    85.2027863,
    49.7399547
  ],
  [
    85.1983069,
    49.7379392
  ],
  [
    85.1985789,
    49.7371908
  ],
  [
    85.1998614,
    49.7358094
  ],
  [
    85.2024962,
    49.7344129
  ],
  [
    85.2038042,
    49.7308744
  ],
  [
    85.2059046,
    49.729459
  ],
  [
    85.2077374,
    49.7291744
  ],
  [
    85.2088178,
    49.7280871
  ],
  [
    85.2098028,
    49.7261041
  ],
  [
    85.2123075,
    49.7232156
  ],
  [
    85.215526,
    49.7185296
  ],
  [
    85.2133497,
    49.7151468
  ],
  [
    85.2109095,
    49.7150473
  ],
  [
    85.2097224,
    49.7154168
  ],
  [
    85.2068298,
    49.7154085
  ],
  [
    85.2064781,
    49.7133189
  ],
  [
    85.2067969,
    49.7113502
  ],
  [
    85.2054614,
    49.7098626
  ],
  [
    85.2056403,
    49.7078142
  ],
  [
    85.208244,
    49.7040595
  ],
  [
    85.2097208,
    49.7031849
  ],
  [
    85.2103348,
    49.7025264
  ],
  [
    85.2091845,
    49.7018126
  ],
  [
    85.2085705,
    49.7004754
  ],
  [
    85.2086249,
    49.6992438
  ],
  [
    85.208002,
    49.6978015
  ],
  [
    85.2088523,
    49.6964468
  ],
  [
    85.2129262,
    49.6938946
  ],
  [
    85.2149409,
    49.6890682
  ],
  [
    85.2131999,
    49.6867451
  ],
  [
    85.2132621,
    49.6836274
  ],
  [
    85.2126257,
    49.6801858
  ],
  [
    85.2141206,
    49.6752514
  ],
  [
    85.2083133,
    49.672758
  ],
  [
    85.2082978,
    49.6640455
  ],
  [
    85.2100854,
    49.661842
  ],
  [
    85.2065102,
    49.6586019
  ],
  [
    85.2036298,
    49.6574402
  ],
  [
    85.2045673,
    49.6563004
  ],
  [
    85.2041166,
    49.6536817
  ],
  [
    85.2034571,
    49.6523225
  ],
  [
    85.2053279,
    49.6480751
  ],
  [
    85.2092867,
    49.6443961
  ],
  [
    85.2099452,
    49.6417103
  ],
  [
    85.2126033,
    49.6392642
  ],
  [
    85.2157132,
    49.6378946
  ],
  [
    85.2163452,
    49.6370155
  ],
  [
    85.2145041,
    49.6313551
  ],
  [
    85.2132232,
    49.6304986
  ],
  [
    85.2106798,
    49.6273503
  ],
  [
    85.234525,
    49.6258878
  ],
  [
    85.254512,
    49.6272583
  ],
  [
    85.2646714,
    49.6197876
  ],
  [
    85.2591909,
    49.6126011
  ],
  [
    85.2549831,
    49.6039977
  ],
  [
    85.2586462,
    49.5992168
  ],
  [
    85.2575191,
    49.5885235
  ],
  [
    85.321261,
    49.5908467
  ],
  [
    85.3884399,
    49.6348026
  ],
  [
    85.490205,
    49.5981291
  ],
  [
    85.5238793,
    49.5956797
  ],
  [
    85.5246339,
    49.5967933
  ],
  [
    85.5270067,
    49.5976694
  ],
  [
    85.5325438,
    49.598487
  ],
  [
    85.5394465,
    49.5973066
  ],
  [
    85.5453272,
    49.5969366
  ],
  [
    85.5532876,
    49.5944135
  ],
  [
    85.556311,
    49.5954414
  ],
  [
    85.5667422,
    49.6030825
  ],
  [
    85.5699822,
    49.6031664
  ],
  [
    85.5739539,
    49.6041236
  ],
  [
    85.5781469,
    49.6074917
  ],
  [
    85.5796238,
    49.6103593
  ],
  [
    85.5837033,
    49.6136726
  ],
  [
    85.5899141,
    49.6143806
  ],
  [
    85.599124,
    49.6125584
  ],
  [
    85.6072246,
    49.6092699
  ],
  [
    85.6108444,
    49.6063156
  ],
  [
    85.6216481,
    49.600869
  ],
  [
    85.6234246,
    49.5987154
  ],
  [
    85.6228523,
    49.5942107
  ],
  [
    85.6228429,
    49.5858793
  ],
  [
    85.6272318,
    49.5777867
  ],
  [
    85.6786796,
    49.5508666
  ],
  [
    85.6818777,
    49.5569163
  ],
  [
    85.6841267,
    49.558939
  ],
  [
    85.6939273,
    49.5641207
  ],
  [
    85.7005209,
    49.5653802
  ],
  [
    85.75689,
    49.5771504
  ],
  [
    85.7617812,
    49.5731087
  ],
  [
    85.7659931,
    49.5728146
  ],
  [
    85.7806054,
    49.5749996
  ],
  [
    85.7841045,
    49.5732138
  ],
  [
    85.7900196,
    49.5724777
  ],
  [
    85.8014541,
    49.5670142
  ],
  [
    85.8099119,
    49.5658807
  ],
  [
    85.8056466,
    49.5616311
  ],
  [
    85.8055008,
    49.5573542
  ],
  [
    85.8160024,
    49.5469945
  ],
  [
    85.8203928,
    49.5454895
  ],
  [
    85.826225,
    49.5442537
  ],
  [
    85.8331567,
    49.5446051
  ],
  [
    85.8410632,
    49.5476503
  ],
  [
    85.8837372,
    49.5669068
  ],
  [
    85.8838237,
    49.5651858
  ],
  [
    85.8848887,
    49.564015
  ],
  [
    85.8887493,
    49.5622091
  ],
  [
    85.8901753,
    49.5619632
  ],
  [
    85.9004442,
    49.5621054
  ],
  [
    85.9055244,
    49.5634135
  ],
  [
    85.9172674,
    49.5651685
  ],
  [
    85.9211545,
    49.5643755
  ],
  [
    85.9279268,
    49.5587965
  ],
  [
    85.938214,
    49.5621001
  ],
  [
    85.9442812,
    49.5613255
  ],
  [
    85.9497566,
    49.5560267
  ],
  [
    85.9494498,
    49.5537785
  ],
  [
    85.9468149,
    49.5501594
  ],
  [
    85.941384,
    49.5449459
  ],
  [
    85.9362193,
    49.5424464
  ],
  [
    85.9324999,
    49.5396999
  ],
  [
    85.9320781,
    49.5374414
  ],
  [
    85.9294084,
    49.5358658
  ],
  [
    85.9378281,
    49.5279
  ],
  [
    85.9383568,
    49.5232599
  ],
  [
    85.9395301,
    49.5224045
  ],
  [
    85.9393496,
    49.5202367
  ],
  [
    85.9403424,
    49.5183734
  ],
  [
    85.9431205,
    49.5176152
  ],
  [
    85.9444382,
    49.5132894
  ],
  [
    85.950257,
    49.5104556
  ],
  [
    85.9512498,
    49.508967
  ],
  [
    85.9529865,
    49.5080803
  ],
  [
    85.9550356,
    49.5075578
  ],
  [
    85.9534791,
    49.5050826
  ],
  [
    85.9533888,
    49.5027498
  ],
  [
    85.9601815,
    49.49856
  ],
  [
    85.963158,
    49.4909396
  ],
  [
    85.9689904,
    49.4871161
  ],
  [
    85.9864641,
    49.4889454
  ],
  [
    85.999028,
    49.4928619
  ],
  [
    86.00909,
    49.4976807
  ],
  [
    86.0102721,
    49.4997234
  ],
  [
    86.0245069,
    49.5037268
  ],
  [
    86.0290535,
    49.5065424
  ],
  [
    86.0328369,
    49.5080739
  ],
  [
    86.0324133,
    49.5123243
  ],
  [
    86.0332694,
    49.5167819
  ],
  [
    86.0371993,
    49.5218457
  ],
  [
    86.057964,
    49.5231011
  ],
  [
    86.0772931,
    49.5216759
  ],
  [
    86.0891058,
    49.5243831
  ],
  [
    86.1129224,
    49.5243831
  ],
  [
    86.1411034,
    49.5127929
  ],
  [
    86.1594393,
    49.5077292
  ],
  [
    86.1663446,
    49.5033037
  ],
  [
    86.1706146,
    49.4976439
  ],
  [
    86.1718683,
    49.4832777
  ],
  [
    86.1739094,
    49.4789793
  ],
  [
    86.2020374,
    49.4661127
  ],
  [
    86.2079017,
    49.4699238
  ],
  [
    86.2171584,
    49.4777626
  ],
  [
    86.2250948,
    49.4902102
  ],
  [
    86.2282701,
    49.4951894
  ],
  [
    86.230501,
    49.5022481
  ],
  [
    86.2352072,
    49.5136517
  ],
  [
    86.2387546,
    49.5176809
  ],
  [
    86.2394051,
    49.5254417
  ],
  [
    86.2538292,
    49.5338981
  ],
  [
    86.255045,
    49.5387201
  ],
  [
    86.2603239,
    49.543461
  ],
  [
    86.2670042,
    49.5465394
  ],
  [
    86.2683108,
    49.5504723
  ],
  [
    86.2662446,
    49.5556566
  ],
  [
    86.2682956,
    49.5577558
  ],
  [
    86.268383,
    49.5611255
  ],
  [
    86.266021,
    49.5660636
  ],
  [
    86.2662499,
    49.5686232
  ],
  [
    86.2686617,
    49.5718179
  ],
  [
    86.2690999,
    49.5780999
  ],
  [
    86.2715999,
    49.5826999
  ],
  [
    86.2866118,
    49.5918467
  ],
  [
    86.2992315,
    49.5959555
  ],
  [
    86.3159147,
    49.5992796
  ],
  [
    86.3226746,
    49.5976143
  ],
  [
    86.3313998,
    49.5932033
  ],
  [
    86.3421351,
    49.5941199
  ],
  [
    86.3507333,
    49.5919067
  ],
  [
    86.3618183,
    49.5924001
  ],
  [
    86.3713507,
    49.5936218
  ],
  [
    86.3792064,
    49.5973797
  ],
  [
    86.3867759,
    49.6013724
  ],
  [
    86.3945507,
    49.6033924
  ],
  [
    86.4078318,
    49.6060011
  ],
  [
    86.4194415,
    49.6083071
  ],
  [
    86.4256461,
    49.6242937
  ],
  [
    86.4286117,
    49.6293521
  ],
  [
    86.4477538,
    49.6377689
  ],
  [
    86.4562683,
    49.6383159
  ],
  [
    86.4690996,
    49.6455264
  ],
  [
    86.4884508,
    49.6579097
  ],
  [
    86.4927644,
    49.6620068
  ],
  [
    86.506078,
    49.6746498
  ],
  [
    86.5065202,
    49.6761951
  ],
  [
    86.5083639,
    49.6782157
  ],
  [
    86.5089108,
    49.6842556
  ],
  [
    86.5086057,
    49.6865111
  ],
  [
    86.5087801,
    49.6897344
  ],
  [
    86.5092392,
    49.6909505
  ],
  [
    86.5135531,
    49.6910552
  ],
  [
    86.5182799,
    49.6933279
  ],
  [
    86.523739,
    49.6974508
  ],
  [
    86.526557,
    49.702045
  ],
  [
    86.5341651,
    49.7045333
  ],
  [
    86.5394815,
    49.7054633
  ],
  [
    86.5451167,
    49.7084999
  ],
  [
    86.5486327,
    49.711447
  ],
  [
    86.5542162,
    49.7125971
  ],
  [
    86.5587767,
    49.7130354
  ],
  [
    86.5643092,
    49.7145812
  ],
  [
    86.5654277,
    49.7160182
  ],
  [
    86.567897,
    49.717192
  ],
  [
    86.5701921,
    49.7176148
  ],
  [
    86.5738236,
    49.719324
  ],
  [
    86.5768304,
    49.722085
  ],
  [
    86.580549,
    49.7229677
  ],
  [
    86.5822921,
    49.7241416
  ],
  [
    86.5804799,
    49.7273355
  ],
  [
    86.5784669,
    49.7300765
  ],
  [
    86.5784234,
    49.733919
  ],
  [
    86.5817548,
    49.7354169
  ],
  [
    86.5831743,
    49.7376448
  ],
  [
    86.5852021,
    49.7380381
  ],
  [
    86.5860133,
    49.7396669
  ],
  [
    86.5898661,
    49.7397416
  ],
  [
    86.5930817,
    49.7403221
  ],
  [
    86.5950226,
    49.7426809
  ],
  [
    86.5995707,
    49.7427371
  ],
  [
    86.6024097,
    49.7440475
  ],
  [
    86.60235,
    49.7467666
  ],
  [
    86.602558,
    49.7486899
  ],
  [
    86.6033755,
    49.7533765
  ],
  [
    86.6045981,
    49.7542843
  ],
  [
    86.6055367,
    49.7584477
  ],
  [
    86.6092491,
    49.7637696
  ],
  [
    86.608102,
    49.7655209
  ],
  [
    86.6094785,
    49.7681746
  ],
  [
    86.6105005,
    49.7689291
  ],
  [
    86.6105839,
    49.7720407
  ],
  [
    86.6096985,
    49.7729811
  ],
  [
    86.6081646,
    49.7743844
  ],
  [
    86.6058912,
    49.7750578
  ],
  [
    86.6044104,
    49.7782364
  ],
  [
    86.6029505,
    49.779866
  ],
  [
    86.603889,
    49.78128
  ],
  [
    86.6043412,
    49.7836316
  ],
  [
    86.5999471,
    49.7848218
  ],
  [
    86.5975389,
    49.7872897
  ],
  [
    86.5973192,
    49.7875149
  ],
  [
    86.5967381,
    49.7881289
  ],
  [
    86.5962767,
    49.7893549
  ],
  [
    86.5956321,
    49.790601
  ],
  [
    86.59511,
    49.7911021
  ],
  [
    86.5950778,
    49.7915108
  ],
  [
    86.5953755,
    49.7923575
  ],
  [
    86.5955204,
    49.7929116
  ],
  [
    86.5956759,
    49.7935799
  ],
  [
    86.5960112,
    49.7947158
  ],
  [
    86.5962935,
    49.7950166
  ],
  [
    86.5969393,
    49.7959866
  ],
  [
    86.5965718,
    49.7963779
  ],
  [
    86.596148,
    49.7971813
  ],
  [
    86.5958905,
    49.7976245
  ],
  [
    86.5950322,
    49.7985733
  ],
  [
    86.5937662,
    49.7994251
  ],
  [
    86.5925914,
    49.7999098
  ],
  [
    86.5909338,
    49.8004534
  ],
  [
    86.5902031,
    49.8009663
  ],
  [
    86.5901642,
    49.8015561
  ],
  [
    86.5902453,
    49.8024032
  ],
  [
    86.5900878,
    49.8027253
  ],
  [
    86.5900942,
    49.8028373
  ],
  [
    86.5903114,
    49.804854
  ],
  [
    86.5899075,
    49.8070677
  ],
  [
    86.5947962,
    49.805948
  ],
  [
    86.6011905,
    49.8031228
  ],
  [
    86.6034436,
    49.8030259
  ],
  [
    86.6053748,
    49.80376
  ],
  [
    86.6100417,
    49.8046254
  ],
  [
    86.6110289,
    49.8030536
  ],
  [
    86.6128207,
    49.8017328
  ],
  [
    86.6131532,
    49.8014955
  ],
  [
    86.6152775,
    49.8007062
  ],
  [
    86.6159426,
    49.8011147
  ],
  [
    86.6181537,
    49.8029182
  ],
  [
    86.6181922,
    49.8029496
  ],
  [
    86.6182595,
    49.8030045
  ],
  [
    86.6199037,
    49.8043455
  ],
  [
    86.6222473,
    49.8052692
  ],
  [
    86.6253695,
    49.8048817
  ],
  [
    86.6264603,
    49.8055856
  ],
  [
    86.6282424,
    49.8054817
  ],
  [
    86.6288176,
    49.805082
  ],
  [
    86.6305283,
    49.8057767
  ],
  [
    86.6315505,
    49.8069033
  ],
  [
    86.636417,
    49.8079682
  ],
  [
    86.6400492,
    49.8079711
  ],
  [
    86.6433775,
    49.8062744
  ],
  [
    86.6456149,
    49.8065271
  ],
  [
    86.6473352,
    49.8075174
  ],
  [
    86.6488593,
    49.8084223
  ],
  [
    86.6488087,
    49.8100346
  ],
  [
    86.6488893,
    49.8106402
  ],
  [
    86.6499556,
    49.8110929
  ],
  [
    86.6538786,
    49.8125599
  ],
  [
    86.6554448,
    49.8126862
  ],
  [
    86.6605986,
    49.811796
  ],
  [
    86.664143,
    49.8086795
  ],
  [
    86.6658873,
    49.8081767
  ],
  [
    86.6678452,
    49.8084957
  ],
  [
    86.6713558,
    49.8083737
  ],
  [
    86.6762148,
    49.8075773
  ],
  [
    86.6787708,
    49.8087769
  ],
  [
    86.6818072,
    49.8095521
  ],
  [
    86.685065,
    49.8109245
  ],
  [
    86.6883746,
    49.8108988
  ],
  [
    86.693833,
    49.8097757
  ],
  [
    86.6974139,
    49.8080905
  ],
  [
    86.6997493,
    49.8064303
  ],
  [
    86.7014711,
    49.8060702
  ],
  [
    86.7044203,
    49.8040536
  ],
  [
    86.7053768,
    49.8039507
  ],
  [
    86.710829,
    49.8012137
  ],
  [
    86.7133,
    49.7954821
  ],
  [
    86.7153406,
    49.7942163
  ],
  [
    86.7194855,
    49.7938972
  ],
  [
    86.7217022,
    49.7936422
  ],
  [
    86.7250173,
    49.7932901
  ],
  [
    86.7285086,
    49.7928578
  ],
  [
    86.731697,
    49.7904084
  ],
  [
    86.73255,
    49.7893167
  ],
  [
    86.7324661,
    49.788049
  ],
  [
    86.7358617,
    49.7871536
  ],
  [
    86.73835,
    49.7869333
  ],
  [
    86.7400545,
    49.7870815
  ],
  [
    86.7413936,
    49.7874109
  ],
  [
    86.7431791,
    49.7873594
  ],
  [
    86.746511,
    49.7865978
  ],
  [
    86.7509666,
    49.7906549
  ],
  [
    86.7551037,
    49.791785
  ],
  [
    86.7562037,
    49.7913219
  ],
  [
    86.7576225,
    49.791219
  ],
  [
    86.7591689,
    49.7906015
  ],
  [
    86.7612254,
    49.7902721
  ],
  [
    86.7625167,
    49.7906015
  ],
  [
    86.7638718,
    49.7903853
  ],
  [
    86.7664225,
    49.7914557
  ],
  [
    86.7722732,
    49.7921349
  ],
  [
    86.7745848,
    49.7935757
  ],
  [
    86.7776456,
    49.7936478
  ],
  [
    86.779425,
    49.7944909
  ],
  [
    86.7810989,
    49.7943469
  ],
  [
    86.7828427,
    49.7937918
  ],
  [
    86.7834645,
    49.7931126
  ],
  [
    86.7835282,
    49.7918056
  ],
  [
    86.7822369,
    49.790622
  ],
  [
    86.7827311,
    49.78914
  ],
  [
    86.7780252,
    49.7824716
  ],
  [
    86.7760349,
    49.7775466
  ],
  [
    86.776295,
    49.7742145
  ],
  [
    86.7755252,
    49.7699359
  ],
  [
    86.7745383,
    49.7696586
  ],
  [
    86.7747529,
    49.7679955
  ],
  [
    86.7737873,
    49.7678569
  ],
  [
    86.7719152,
    49.7646228
  ],
  [
    86.771384,
    49.7638237
  ],
  [
    86.7715342,
    49.762618
  ],
  [
    86.7697318,
    49.7619387
  ],
  [
    86.7684443,
    49.7602892
  ],
  [
    86.7667277,
    49.75961
  ],
  [
    86.7656763,
    49.7584041
  ],
  [
    86.7644746,
    49.7581408
  ],
  [
    86.7647536,
    49.7546057
  ],
  [
    86.7643888,
    49.7533996
  ],
  [
    86.7653115,
    49.7518054
  ],
  [
    86.7645845,
    49.7502326
  ],
  [
    86.7628224,
    49.7481591
  ],
  [
    86.7587453,
    49.7464121
  ],
  [
    86.7576753,
    49.7448768
  ],
  [
    86.7549904,
    49.743015
  ],
  [
    86.7529975,
    49.7423948
  ],
  [
    86.755315,
    49.7390529
  ],
  [
    86.7580186,
    49.7371252
  ],
  [
    86.7563878,
    49.7339632
  ],
  [
    86.7568385,
    49.7322157
  ],
  [
    86.7583405,
    49.7310091
  ],
  [
    86.7614948,
    49.7303711
  ],
  [
    86.76216,
    49.7285402
  ],
  [
    86.7636951,
    49.7255558
  ],
  [
    86.7663363,
    49.7249357
  ],
  [
    86.7666292,
    49.7236608
  ],
  [
    86.7675519,
    49.722121
  ],
  [
    86.7686603,
    49.7179831
  ],
  [
    86.7710481,
    49.7132568
  ],
  [
    86.7706793,
    49.7110774
  ],
  [
    86.7709261,
    49.7105154
  ],
  [
    86.7736727,
    49.7072337
  ],
  [
    86.7751465,
    49.7056389
  ],
  [
    86.7766446,
    49.7042883
  ],
  [
    86.7784569,
    49.7031465
  ],
  [
    86.7789236,
    49.6960165
  ],
  [
    86.7730187,
    49.6932107
  ],
  [
    86.7708035,
    49.6932107
  ],
  [
    86.7706623,
    49.6919495
  ],
  [
    86.7677226,
    49.6891316
  ],
  [
    86.7614999,
    49.6879655
  ],
  [
    86.7590108,
    49.6883126
  ],
  [
    86.7579594,
    49.6877851
  ],
  [
    86.7560711,
    49.6884097
  ],
  [
    86.7554548,
    49.6896125
  ],
  [
    86.7525392,
    49.6899991
  ],
  [
    86.7517673,
    49.6894191
  ],
  [
    86.7496507,
    49.6898218
  ],
  [
    86.7490531,
    49.6907723
  ],
  [
    86.7445212,
    49.6893708
  ],
  [
    86.7384205,
    49.6884204
  ],
  [
    86.736279,
    49.6869544
  ],
  [
    86.7348597,
    49.6856494
  ],
  [
    86.734785,
    49.6842962
  ],
  [
    86.734013,
    49.6838452
  ],
  [
    86.7317222,
    49.6819762
  ],
  [
    86.7309876,
    49.6807114
  ],
  [
    86.7311245,
    49.680228
  ],
  [
    86.7307759,
    49.6795271
  ],
  [
    86.7298546,
    49.6791888
  ],
  [
    86.7294064,
    49.678198
  ],
  [
    86.7268988,
    49.6774543
  ],
  [
    86.7241897,
    49.6766511
  ],
  [
    86.721351,
    49.6771587
  ],
  [
    86.719832,
    49.6780851
  ],
  [
    86.7163957,
    49.6829591
  ],
  [
    86.7136815,
    49.6841674
  ],
  [
    86.7094733,
    49.6872606
  ],
  [
    86.707033,
    49.6863826
  ],
  [
    86.7049414,
    49.6866725
  ],
  [
    86.7019906,
    49.6856818
  ],
  [
    86.6997247,
    49.6849729
  ],
  [
    86.7005802,
    49.6829875
  ],
  [
    86.7006673,
    49.6806515
  ],
  [
    86.6972047,
    49.6784072
  ],
  [
    86.6842375,
    49.6751482
  ],
  [
    86.6846981,
    49.6706844
  ],
  [
    86.6819148,
    49.6686591
  ],
  [
    86.6797667,
    49.6688983
  ],
  [
    86.6781468,
    49.6692972
  ],
  [
    86.6733575,
    49.6686021
  ],
  [
    86.6657335,
    49.6655024
  ],
  [
    86.6663321,
    49.6628355
  ],
  [
    86.6632251,
    49.6568084
  ],
  [
    86.658543,
    49.6548812
  ],
  [
    86.6532607,
    49.6524873
  ],
  [
    86.6528734,
    49.6497515
  ],
  [
    86.6568879,
    49.6454648
  ],
  [
    86.6591592,
    49.6437888
  ],
  [
    86.6356928,
    49.6319262
  ],
  [
    86.6217032,
    49.6309918
  ],
  [
    86.6191874,
    49.6290188
  ],
  [
    86.6155494,
    49.6285236
  ],
  [
    86.6113599,
    49.6239653
  ],
  [
    86.6100693,
    49.6195024
  ],
  [
    86.6085769,
    49.6170992
  ],
  [
    86.6007582,
    49.6109145
  ],
  [
    86.5975765,
    49.609524
  ],
  [
    86.602492,
    49.6055533
  ],
  [
    86.6102374,
    49.5845317
  ],
  [
    86.6182488,
    49.5703775
  ],
  [
    86.6295593,
    49.5656815
  ],
  [
    86.6438954,
    49.5628083
  ],
  [
    86.6659047,
    49.5602717
  ],
  [
    86.6755483,
    49.5614612
  ],
  [
    86.6809745,
    49.5637969
  ],
  [
    86.6823965,
    49.5690592
  ],
  [
    86.6966175,
    49.5702568
  ],
  [
    86.7104304,
    49.5680851
  ],
  [
    86.721383,
    49.5682209
  ],
  [
    86.7261268,
    49.5706866
  ],
  [
    86.7338985,
    49.571457
  ],
  [
    86.7395126,
    49.571169
  ],
  [
    86.7424077,
    49.5701963
  ],
  [
    86.7442215,
    49.5688843
  ],
  [
    86.7477445,
    49.5689295
  ],
  [
    86.752279,
    49.5676401
  ],
  [
    86.7588018,
    49.5687712
  ],
  [
    86.7593599,
    49.5645181
  ],
  [
    86.7596263,
    49.5636907
  ],
  [
    86.7612831,
    49.5625086
  ],
  [
    86.7645707,
    49.5618355
  ],
  [
    86.7771891,
    49.5634761
  ],
  [
    86.7774332,
    49.5618479
  ],
  [
    86.7747125,
    49.557549
  ],
  [
    86.7902327,
    49.5527722
  ],
  [
    86.798372,
    49.5541162
  ],
  [
    86.8008377,
    49.5505844
  ],
  [
    86.8044304,
    49.5491587
  ],
  [
    86.8069767,
    49.5490455
  ],
  [
    86.8092606,
    49.5493117
  ],
  [
    86.8106199,
    49.548072
  ],
  [
    86.8129918,
    49.5477212
  ],
  [
    86.8180495,
    49.5481399
  ],
  [
    86.823843,
    49.5472812
  ],
  [
    86.8271741,
    49.5454254
  ],
  [
    86.8273607,
    49.5387381
  ],
  [
    86.8251905,
    49.5367025
  ],
  [
    86.8148466,
    49.5323219
  ],
  [
    86.8186044,
    49.5273046
  ],
  [
    86.8248181,
    49.5230345
  ],
  [
    86.8343827,
    49.5183495
  ],
  [
    86.8375767,
    49.5160622
  ],
  [
    86.8449322,
    49.5146408
  ],
  [
    86.8463471,
    49.5125298
  ],
  [
    86.8510494,
    49.5106244
  ],
  [
    86.8533432,
    49.5058838
  ],
  [
    86.8536391,
    49.501383
  ],
  [
    86.8554365,
    49.4993355
  ],
  [
    86.8503834,
    49.4954079
  ],
  [
    86.8473003,
    49.4948793
  ],
  [
    86.8458698,
    49.4932132
  ],
  [
    86.8407142,
    49.4887038
  ],
  [
    86.8361266,
    49.486243
  ],
  [
    86.8332882,
    49.4852914
  ],
  [
    86.832131,
    49.4832626
  ],
  [
    86.8297196,
    49.4817399
  ],
  [
    86.8342578,
    49.4771334
  ],
  [
    86.8317791,
    49.4748977
  ],
  [
    86.8309158,
    49.4727581
  ],
  [
    86.8309528,
    49.4697529
  ],
  [
    86.8336739,
    49.4667548
  ],
  [
    86.8360458,
    49.4662675
  ],
  [
    86.8369527,
    49.4635698
  ],
  [
    86.8386619,
    49.4627196
  ],
  [
    86.8383305,
    49.4612233
  ],
  [
    86.8362551,
    49.4588768
  ],
  [
    86.8363074,
    49.4565094
  ],
  [
    86.8339355,
    49.4537432
  ],
  [
    86.8333774,
    49.4522466
  ],
  [
    86.8441207,
    49.4527455
  ],
  [
    86.8470507,
    49.4517024
  ],
  [
    86.8482543,
    49.4474089
  ],
  [
    86.8510814,
    49.4445937
  ],
  [
    86.8533921,
    49.444122
  ],
  [
    86.8575631,
    49.4443211
  ],
  [
    86.8612361,
    49.4420266
  ],
  [
    86.8637788,
    49.4411824
  ],
  [
    86.8652646,
    49.4395687
  ],
  [
    86.8683051,
    49.4348227
  ],
  [
    86.8802244,
    49.4341011
  ],
  [
    86.8911001,
    49.4302447
  ],
  [
    86.8937124,
    49.4309061
  ],
  [
    86.8973017,
    49.4297124
  ],
  [
    86.9024319,
    49.4292472
  ],
  [
    86.9065016,
    49.427643
  ],
  [
    86.9118785,
    49.4211936
  ],
  [
    86.9117305,
    49.4186105
  ],
  [
    86.9144436,
    49.4166369
  ],
  [
    86.9209214,
    49.4168689
  ],
  [
    86.9237331,
    49.418682
  ],
  [
    86.9269642,
    49.4192757
  ],
  [
    86.9289127,
    49.4202383
  ],
  [
    86.9301213,
    49.4200618
  ],
  [
    86.9328735,
    49.4176872
  ],
  [
    86.9373378,
    49.4169331
  ],
  [
    86.9437999,
    49.4108996
  ],
  [
    86.9473282,
    49.4096239
  ],
  [
    86.9477722,
    49.4064175
  ],
  [
    86.9450344,
    49.4056311
  ],
  [
    86.9443259,
    49.4035023
  ],
  [
    86.9385182,
    49.4033548
  ],
  [
    86.9362659,
    49.4001947
  ],
  [
    86.9364385,
    49.3980117
  ],
  [
    86.9450732,
    49.3914794
  ],
  [
    86.9452127,
    49.3857804
  ],
  [
    86.9492001,
    49.3799416
  ],
  [
    86.9396667,
    49.3727187
  ],
  [
    86.9367763,
    49.3689182
  ],
  [
    86.931043,
    49.3686505
  ],
  [
    86.9247153,
    49.3698176
  ],
  [
    86.9231005,
    49.3623292
  ],
  [
    86.9250042,
    49.3607609
  ],
  [
    86.927097,
    49.359784
  ],
  [
    86.9249693,
    49.3582505
  ],
  [
    86.925353,
    49.3556719
  ],
  [
    86.9269575,
    49.3526727
  ],
  [
    86.9259459,
    49.3509572
  ],
  [
    86.9264692,
    49.3488781
  ],
  [
    86.9292528,
    49.3473531
  ],
  [
    86.9317991,
    49.3449898
  ],
  [
    86.9356535,
    49.3437967
  ],
  [
    86.9403677,
    49.3397189
  ],
  [
    86.9430438,
    49.3385216
  ],
  [
    86.9484331,
    49.3369867
  ],
  [
    86.9494672,
    49.3373735
  ],
  [
    86.9500596,
    49.3372778
  ],
  [
    86.9502665,
    49.3369665
  ],
  [
    86.9505749,
    49.33674
  ],
  [
    86.9508979,
    49.336416
  ],
  [
    86.9511829,
    49.336296
  ],
  [
    86.9517269,
    49.336215
  ],
  [
    86.9523899,
    49.336193
  ],
  [
    86.9531319,
    49.335991
  ],
  [
    86.9537799,
    49.335561
  ],
  [
    86.9541259,
    49.335238
  ],
  [
    86.9544029,
    49.334562
  ],
  [
    86.9547199,
    49.334125
  ],
  [
    86.9548829,
    49.333391
  ],
  [
    86.9551539,
    49.333037
  ],
  [
    86.9578669,
    49.331163
  ],
  [
    86.9594609,
    49.329814
  ],
  [
    86.9602819,
    49.328497
  ],
  [
    86.9606999,
    49.327963
  ],
  [
    86.9613779,
    49.3273
  ],
  [
    86.9627079,
    49.325412
  ],
  [
    86.9627979,
    49.325037
  ],
  [
    86.9621789,
    49.323963
  ],
  [
    86.9587679,
    49.318599
  ],
  [
    86.9586989,
    49.318211
  ],
  [
    86.9588249,
    49.317878
  ],
  [
    86.9589669,
    49.317622
  ],
  [
    86.9593839,
    49.317463
  ],
  [
    86.9600889,
    49.317401
  ],
  [
    86.9606069,
    49.317289
  ],
  [
    86.9609689,
    49.317104
  ],
  [
    86.9619049,
    49.315929
  ],
  [
    86.9626299,
    49.314567
  ],
  [
    86.9633819,
    49.312776
  ],
  [
    86.9636979,
    49.312187
  ],
  [
    86.9640799,
    49.311671
  ],
  [
    86.9652599,
    49.310428
  ],
  [
    86.9662399,
    49.309585
  ],
  [
    86.9671669,
    49.30911
  ],
  [
    86.9677879,
    49.308615
  ],
  [
    86.9680419,
    49.308301
  ],
  [
    86.9684889,
    49.308058
  ],
  [
    86.9691109,
    49.307626
  ],
  [
    86.9699909,
    49.307308
  ],
  [
    86.9703529,
    49.306617
  ],
  [
    86.9709869,
    49.305851
  ],
  [
    86.9731949,
    49.304755
  ],
  [
    86.9741829,
    49.30436
  ],
  [
    86.9750579,
    49.304158
  ],
  [
    86.9786939,
    49.303593
  ],
  [
    86.9799689,
    49.303284
  ],
  [
    86.9821429,
    49.30089
  ],
  [
    86.9995569,
    49.297546
  ],
  [
    87.0060455,
    49.2988636
  ],
  [
    87.0185208,
    49.2690463
  ],
  [
    87.0185332,
    49.2690166
  ],
  [
    87.0185585,
    49.2689726
  ],
  [
    87.0252909,
    49.2572823
  ],
  [
    87.0307157,
    49.2528804
  ],
  [
    87.0369401,
    49.2508474
  ],
  [
    87.0438033,
    49.250477
  ],
  [
    87.0471749,
    49.250485
  ],
  [
    87.0482239,
    49.250544
  ],
  [
    87.0495059,
    49.250725
  ],
  [
    87.0506149,
    49.250977
  ],
  [
    87.0519279,
    49.251371
  ],
  [
    87.0546879,
    49.25236
  ],
  [
    87.0597579,
    49.254474
  ],
  [
    87.0603539,
    49.254606
  ],
  [
    87.0634999,
    49.254785
  ],
  [
    87.0645029,
    49.254773
  ],
  [
    87.0659149,
    49.254594
  ],
  [
    87.0677149,
    49.254299
  ],
  [
    87.0707769,
    49.253535
  ],
  [
    87.0720879,
    49.253374
  ],
  [
    87.0727749,
    49.253326
  ],
  [
    87.0740979,
    49.253478
  ],
  [
    87.0760939,
    49.253577
  ],
  [
    87.0815019,
    49.253103
  ],
  [
    87.0829699,
    49.252878
  ],
  [
    87.0857299,
    49.252151
  ],
  [
    87.0862849,
    49.252063
  ],
  [
    87.0870289,
    49.252066
  ],
  [
    87.0892989,
    49.252188
  ],
  [
    87.0922589,
    49.25207
  ],
  [
    87.0947089,
    49.252063
  ],
  [
    87.0951709,
    49.252154
  ],
  [
    87.0966549,
    49.25298
  ],
  [
    87.0986669,
    49.253872
  ],
  [
    87.1008039,
    49.2545
  ],
  [
    87.1018789,
    49.254763
  ],
  [
    87.1032409,
    49.254977
  ],
  [
    87.1040649,
    49.254989
  ],
  [
    87.1058529,
    49.254815
  ],
  [
    87.1079259,
    49.25447
  ],
  [
    87.1109609,
    49.253817
  ],
  [
    87.1124719,
    49.253561
  ],
  [
    87.1130199,
    49.253491
  ],
  [
    87.1146219,
    49.253394
  ],
  [
    87.1153409,
    49.253266
  ],
  [
    87.1160789,
    49.253316
  ],
  [
    87.1166449,
    49.253414
  ],
  [
    87.1200999,
    49.25421
  ],
  [
    87.1216119,
    49.254628
  ],
  [
    87.1247249,
    49.255641
  ],
  [
    87.1255089,
    49.255803
  ],
  [
    87.1269429,
    49.255978
  ],
  [
    87.1282739,
    49.256051
  ],
  [
    87.1287199,
    49.255963
  ],
  [
    87.1289909,
    49.25582
  ],
  [
    87.1306459,
    49.254168
  ],
  [
    87.1335159,
    49.251905
  ],
  [
    87.1351689,
    49.250178
  ],
  [
    87.1358709,
    49.249245
  ],
  [
    87.1367319,
    49.24832
  ],
  [
    87.1376689,
    49.247554
  ],
  [
    87.1387949,
    49.246842
  ],
  [
    87.1395809,
    49.246442
  ],
  [
    87.1403249,
    49.246145
  ],
  [
    87.1410729,
    49.24597
  ],
  [
    87.1417209,
    49.245908
  ],
  [
    87.1423079,
    49.245921
  ],
  [
    87.1443279,
    49.246851
  ],
  [
    87.1456969,
    49.247401
  ],
  [
    87.1485369,
    49.248135
  ],
  [
    87.1531199,
    49.249238
  ],
  [
    87.1545839,
    49.249468
  ],
  [
    87.1562479,
    49.249604
  ],
  [
    87.1577359,
    49.24961
  ],
  [
    87.1601369,
    49.24972
  ],
  [
    87.1618009,
    49.249864
  ],
  [
    87.1635799,
    49.249961
  ],
  [
    87.1652019,
    49.250144
  ],
  [
    87.1657789,
    49.25017
  ],
  [
    87.1664529,
    49.250154
  ],
  [
    87.1669639,
    49.250091
  ],
  [
    87.1673069,
    49.249947
  ],
  [
    87.1675569,
    49.24975
  ],
  [
    87.1677299,
    49.249475
  ],
  [
    87.1677589,
    49.248829
  ],
  [
    87.1678679,
    49.248573
  ],
  [
    87.1683649,
    49.248159
  ],
  [
    87.1697519,
    49.24751
  ],
  [
    87.1707209,
    49.247187
  ],
  [
    87.1714419,
    49.247017
  ],
  [
    87.1724809,
    49.246821
  ],
  [
    87.1734029,
    49.246701
  ],
  [
    87.1741819,
    49.246656
  ],
  [
    87.1756429,
    49.246746
  ],
  [
    87.1785859,
    49.247207
  ],
  [
    87.1800529,
    49.247541
  ],
  [
    87.1807009,
    49.247831
  ],
  [
    87.1812219,
    49.24813
  ],
  [
    87.1816131,
    49.2485524
  ],
  [
    87.1827439,
    49.248369
  ],
  [
    87.1843889,
    49.248367
  ],
  [
    87.1860109,
    49.248452
  ],
  [
    87.1878209,
    49.248784
  ],
  [
    87.1895099,
    49.249245
  ],
  [
    87.1903859,
    49.249658
  ],
  [
    87.1915669,
    49.250125
  ],
  [
    87.1955349,
    49.251316
  ],
  [
    87.1967469,
    49.251764
  ],
  [
    87.1983249,
    49.252499
  ],
  [
    87.1985259,
    49.252469
  ],
  [
    87.1991279,
    49.252258
  ],
  [
    87.2011179,
    49.251453
  ],
  [
    87.2019799,
    49.251249
  ],
  [
    87.2027149,
    49.251176
  ],
  [
    87.2034369,
    49.25116
  ],
  [
    87.2040019,
    49.25124
  ],
  [
    87.2055419,
    49.251619
  ],
  [
    87.2064129,
    49.25177
  ],
  [
    87.2071209,
    49.251792
  ],
  [
    87.2077989,
    49.251729
  ],
  [
    87.2093079,
    49.251407
  ],
  [
    87.2099979,
    49.251241
  ],
  [
    87.2108719,
    49.250924
  ],
  [
    87.2119429,
    49.250381
  ],
  [
    87.2134119,
    49.249552
  ],
  [
    87.2173829,
    49.248243
  ],
  [
    87.2183139,
    49.247887
  ],
  [
    87.2189279,
    49.24761
  ],
  [
    87.2197389,
    49.24705
  ],
  [
    87.2209999,
    49.246329
  ],
  [
    87.2212849,
    49.246228
  ],
  [
    87.2217259,
    49.24618
  ],
  [
    87.2255329,
    49.246189
  ],
  [
    87.2277209,
    49.246026
  ],
  [
    87.2285779,
    49.245909
  ],
  [
    87.2290839,
    49.245784
  ],
  [
    87.2297859,
    49.245525
  ],
  [
    87.2320229,
    49.24483
  ],
  [
    87.2334809,
    49.244311
  ],
  [
    87.2347539,
    49.243643
  ],
  [
    87.2363699,
    49.242382
  ],
  [
    87.2374699,
    49.241202
  ],
  [
    87.2399489,
    49.239165
  ],
  [
    87.2412049,
    49.238526
  ],
  [
    87.2425949,
    49.238045
  ],
  [
    87.2432979,
    49.237869
  ],
  [
    87.2439889,
    49.237779
  ],
  [
    87.2448549,
    49.237733
  ],
  [
    87.2461559,
    49.237796
  ],
  [
    87.2474629,
    49.238103
  ],
  [
    87.2507869,
    49.239217
  ],
  [
    87.2521369,
    49.239859
  ],
  [
    87.2533139,
    49.240264
  ],
  [
    87.2539619,
    49.240328
  ],
  [
    87.2545499,
    49.240321
  ],
  [
    87.2552649,
    49.240168
  ],
  [
    87.2577919,
    49.239216
  ],
  [
    87.2600959,
    49.23818
  ],
  [
    87.2626299,
    49.236916
  ],
  [
    87.2632029,
    49.236422
  ],
  [
    87.2637469,
    49.236056
  ],
  [
    87.2643529,
    49.235732
  ],
  [
    87.2653929,
    49.234791
  ],
  [
    87.2658119,
    49.234518
  ],
  [
    87.2666039,
    49.234102
  ],
  [
    87.2676649,
    49.233316
  ],
  [
    87.2688709,
    49.232705
  ],
  [
    87.2693249,
    49.23257
  ],
  [
    87.2707369,
    49.232531
  ],
  [
    87.2721679,
    49.232549
  ],
  [
    87.2737549,
    49.232769
  ],
  [
    87.2753109,
    49.233201
  ],
  [
    87.2766769,
    49.233733
  ],
  [
    87.2789069,
    49.234207
  ],
  [
    87.2814739,
    49.234223
  ],
  [
    87.2846919,
    49.233865
  ],
  [
    87.2853769,
    49.233874
  ],
  [
    87.2862779,
    49.233822
  ],
  [
    87.2867439,
    49.233741
  ],
  [
    87.2874599,
    49.233527
  ],
  [
    87.2880959,
    49.233193
  ],
  [
    87.2893249,
    49.233343
  ],
  [
    87.2907729,
    49.233694
  ],
  [
    87.2922509,
    49.233924
  ],
  [
    87.2931229,
    49.234003
  ],
  [
    87.2956669,
    49.234111
  ],
  [
    87.2972769,
    49.234445
  ],
  [
    87.2987549,
    49.23459
  ],
  [
    87.3002959,
    49.234952
  ],
  [
    87.3019639,
    49.235192
  ],
  [
    87.3036569,
    49.235279
  ],
  [
    87.3041819,
    49.235247
  ],
  [
    87.3056459,
    49.234892
  ],
  [
    87.3067029,
    49.234709
  ],
  [
    87.3082419,
    49.234373
  ],
  [
    87.3096709,
    49.233885
  ],
  [
    87.3101989,
    49.233592
  ],
  [
    87.3119519,
    49.232255
  ],
  [
    87.3123859,
    49.232012
  ],
  [
    87.3134269,
    49.231707
  ],
  [
    87.3148879,
    49.231361
  ],
  [
    87.3156336,
    49.2310833
  ],
  [
    87.2915928,
    49.201769
  ],
  [
    87.2926946,
    49.1901042
  ],
  [
    87.2997388,
    49.1845691
  ],
  [
    87.3007609,
    49.184046
  ],
  [
    87.3015169,
    49.1826
  ],
  [
    87.3019319,
    49.181337
  ],
  [
    87.3020689,
    49.180276
  ],
  [
    87.3025619,
    49.179154
  ],
  [
    87.3027529,
    49.178554
  ],
  [
    87.3031559,
    49.176312
  ],
  [
    87.3036029,
    49.174606
  ],
  [
    87.3038139,
    49.172727
  ],
  [
    87.3038119,
    49.172181
  ],
  [
    87.3035749,
    49.171171
  ],
  [
    87.3033559,
    49.170707
  ],
  [
    87.3027309,
    49.169557
  ],
  [
    87.3021309,
    49.168823
  ],
  [
    87.3016559,
    49.168457
  ],
  [
    87.2994279,
    49.167062
  ],
  [
    87.2959329,
    49.165432
  ],
  [
    87.2945249,
    49.164462
  ],
  [
    87.2939759,
    49.164021
  ],
  [
    87.2931269,
    49.16316
  ],
  [
    87.2928399,
    49.162728
  ],
  [
    87.2923499,
    49.161439
  ],
  [
    87.2918949,
    49.159174
  ],
  [
    87.2916789,
    49.158518
  ],
  [
    87.2910399,
    49.157286
  ],
  [
    87.2906779,
    49.156729
  ],
  [
    87.2899649,
    49.155999
  ],
  [
    87.2907589,
    49.155277
  ],
  [
    87.2942579,
    49.153244
  ],
  [
    87.2954049,
    49.152498
  ],
  [
    87.2959609,
    49.152029
  ],
  [
    87.2965149,
    49.151663
  ],
  [
    87.2977059,
    49.150624
  ],
  [
    87.2986679,
    49.149913
  ],
  [
    87.2991729,
    49.149459
  ],
  [
    87.2994129,
    49.149129
  ],
  [
    87.2994589,
    49.148658
  ],
  [
    87.2993299,
    49.148135
  ],
  [
    87.2990289,
    49.147356
  ],
  [
    87.2976539,
    49.145043
  ],
  [
    87.2965529,
    49.143993
  ],
  [
    87.2960109,
    49.143583
  ],
  [
    87.2951809,
    49.142763
  ],
  [
    87.2948759,
    49.142264
  ],
  [
    87.2943269,
    49.141013
  ],
  [
    87.2940569,
    49.139913
  ],
  [
    87.2941349,
    49.139432
  ],
  [
    87.2944949,
    49.138937
  ],
  [
    87.2950429,
    49.138503
  ],
  [
    87.2964739,
    49.137571
  ],
  [
    87.2968919,
    49.135694
  ],
  [
    87.2972309,
    49.133679
  ],
  [
    87.2970419,
    49.132452
  ],
  [
    87.2967659,
    49.131834
  ],
  [
    87.2962789,
    49.131085
  ],
  [
    87.2959689,
    49.130281
  ],
  [
    87.2960617,
    49.130044
  ],
  [
    87.2971969,
    49.1271417
  ],
  [
    87.2937029,
    49.1238876
  ],
  [
    87.2903199,
    49.1210925
  ],
  [
    87.2883359,
    49.1189934
  ],
  [
    87.2877449,
    49.1179584
  ],
  [
    87.2875789,
    49.1172944
  ],
  [
    87.2875279,
    49.1167973
  ],
  [
    87.2876049,
    49.1156513
  ],
  [
    87.2878779,
    49.1144423
  ],
  [
    87.2878499,
    49.1140742
  ],
  [
    87.2835929,
    49.116077
  ],
  [
    87.2814349,
    49.117057
  ],
  [
    87.2787289,
    49.117763
  ],
  [
    87.2774999,
    49.117732
  ],
  [
    87.2769449,
    49.117631
  ],
  [
    87.2767689,
    49.117416
  ],
  [
    87.2763349,
    49.117131
  ],
  [
    87.2761169,
    49.11673
  ],
  [
    87.2756509,
    49.116285
  ],
  [
    87.2752139,
    49.115553
  ],
  [
    87.2741149,
    49.114608
  ],
  [
    87.2726889,
    49.114139
  ],
  [
    87.2723639,
    49.113887
  ],
  [
    87.2720439,
    49.113807
  ],
  [
    87.2717619,
    49.113825
  ],
  [
    87.2713119,
    49.114046
  ],
  [
    87.2699819,
    49.114184
  ],
  [
    87.2694369,
    49.114344
  ],
  [
    87.2682029,
    49.114341
  ],
  [
    87.2658749,
    49.114488
  ],
  [
    87.2646989,
    49.114422
  ],
  [
    87.2628019,
    49.114573
  ],
  [
    87.2615899,
    49.114885
  ],
  [
    87.2608229,
    49.114909
  ],
  [
    87.2599929,
    49.114839
  ],
  [
    87.2590349,
    49.114627
  ],
  [
    87.2586869,
    49.114152
  ],
  [
    87.2575399,
    49.113619
  ],
  [
    87.2552649,
    49.112443
  ],
  [
    87.2540719,
    49.112014
  ],
  [
    87.2535239,
    49.11171
  ],
  [
    87.2521889,
    49.112248
  ],
  [
    87.2506779,
    49.112456
  ],
  [
    87.2500019,
    49.112456
  ],
  [
    87.2490759,
    49.112696
  ],
  [
    87.2482199,
    49.112705
  ],
  [
    87.2474639,
    49.112811
  ],
  [
    87.2445459,
    49.112951
  ],
  [
    87.2430249,
    49.112964
  ],
  [
    87.2418529,
    49.113066
  ],
  [
    87.2410259,
    49.113041
  ],
  [
    87.2399639,
    49.112766
  ],
  [
    87.2395809,
    49.112811
  ],
  [
    87.2391379,
    49.112736
  ],
  [
    87.2387849,
    49.112809
  ],
  [
    87.2379459,
    49.112872
  ],
  [
    87.2376519,
    49.112789
  ],
  [
    87.2370499,
    49.112954
  ],
  [
    87.2365099,
    49.113225
  ],
  [
    87.2352329,
    49.11375
  ],
  [
    87.2326849,
    49.114261
  ],
  [
    87.2319549,
    49.114475
  ],
  [
    87.2314479,
    49.11451
  ],
  [
    87.2308789,
    49.114471
  ],
  [
    87.2306649,
    49.114375
  ],
  [
    87.2303029,
    49.1144
  ],
  [
    87.2297549,
    49.114684
  ],
  [
    87.2289729,
    49.114916
  ],
  [
    87.2279949,
    49.1153
  ],
  [
    87.2278269,
    49.116316
  ],
  [
    87.2277239,
    49.117985
  ],
  [
    87.2273719,
    49.119458
  ],
  [
    87.2269499,
    49.119592
  ],
  [
    87.2262929,
    49.119665
  ],
  [
    87.2256419,
    49.11969
  ],
  [
    87.2249279,
    49.119603
  ],
  [
    87.2243369,
    49.119588
  ],
  [
    87.2236139,
    49.119676
  ],
  [
    87.2221119,
    49.120027
  ],
  [
    87.2219439,
    49.120126
  ],
  [
    87.2205509,
    49.120434
  ],
  [
    87.2197039,
    49.120758
  ],
  [
    87.2187059,
    49.121073
  ],
  [
    87.2183999,
    49.121089
  ],
  [
    87.2175279,
    49.121473
  ],
  [
    87.2160959,
    49.121835
  ],
  [
    87.2155619,
    49.122031
  ],
  [
    87.2150739,
    49.12215
  ],
  [
    87.2141169,
    49.122464
  ],
  [
    87.2135459,
    49.122777
  ],
  [
    87.2135669,
    49.123266
  ],
  [
    87.2136939,
    49.123499
  ],
  [
    87.2140259,
    49.123828
  ],
  [
    87.2141269,
    49.12409
  ],
  [
    87.2141979,
    49.124902
  ],
  [
    87.2144879,
    49.126253
  ],
  [
    87.2153819,
    49.127904
  ],
  [
    87.2158339,
    49.12835
  ],
  [
    87.2156419,
    49.129316
  ],
  [
    87.2153709,
    49.129509
  ],
  [
    87.2149859,
    49.130341
  ],
  [
    87.2147199,
    49.130468
  ],
  [
    87.2144909,
    49.130815
  ],
  [
    87.2137229,
    49.131399
  ],
  [
    87.2136059,
    49.131615
  ],
  [
    87.2136219,
    49.131923
  ],
  [
    87.2132469,
    49.132841
  ],
  [
    87.2130369,
    49.133005
  ],
  [
    87.2125599,
    49.133314
  ],
  [
    87.2121359,
    49.133428
  ],
  [
    87.2117969,
    49.13358
  ],
  [
    87.2115909,
    49.133788
  ],
  [
    87.2112129,
    49.134016
  ],
  [
    87.2104019,
    49.13431
  ],
  [
    87.2086109,
    49.135219
  ],
  [
    87.2082669,
    49.135255
  ],
  [
    87.2073949,
    49.135678
  ],
  [
    87.2072669,
    49.135882
  ],
  [
    87.2072419,
    49.136193
  ],
  [
    87.2073259,
    49.136771
  ],
  [
    87.2070699,
    49.137122
  ],
  [
    87.2071599,
    49.137221
  ],
  [
    87.2069639,
    49.137428
  ],
  [
    87.2067389,
    49.137475
  ],
  [
    87.2059039,
    49.138345
  ],
  [
    87.2051549,
    49.138771
  ],
  [
    87.2042569,
    49.139128
  ],
  [
    87.2038259,
    49.139479
  ],
  [
    87.2035319,
    49.139609
  ],
  [
    87.2037249,
    49.13992
  ],
  [
    87.2038079,
    49.140323
  ],
  [
    87.2039229,
    49.140365
  ],
  [
    87.2041049,
    49.140584
  ],
  [
    87.2041159,
    49.140752
  ],
  [
    87.2040229,
    49.141015
  ],
  [
    87.2037229,
    49.141247
  ],
  [
    87.2029639,
    49.141567
  ],
  [
    87.2018949,
    49.14186
  ],
  [
    87.2013119,
    49.142323
  ],
  [
    87.2000039,
    49.142945
  ],
  [
    87.1991659,
    49.143187
  ],
  [
    87.1979609,
    49.14315
  ],
  [
    87.1975159,
    49.142879
  ],
  [
    87.1965629,
    49.142732
  ],
  [
    87.1958679,
    49.142576
  ],
  [
    87.1953079,
    49.142353
  ],
  [
    87.1948159,
    49.142318
  ],
  [
    87.1932959,
    49.141927
  ],
  [
    87.1924589,
    49.141885
  ],
  [
    87.1915469,
    49.141921
  ],
  [
    87.1907929,
    49.142154
  ],
  [
    87.1898979,
    49.14228
  ],
  [
    87.1891389,
    49.142265
  ],
  [
    87.1879449,
    49.141385
  ],
  [
    87.1872369,
    49.140709
  ],
  [
    87.1862559,
    49.1401
  ],
  [
    87.1849529,
    49.139481
  ],
  [
    87.1845339,
    49.139018
  ],
  [
    87.1829099,
    49.138897
  ],
  [
    87.1815789,
    49.139073
  ],
  [
    87.1790189,
    49.138963
  ],
  [
    87.1760799,
    49.139448
  ],
  [
    87.1753559,
    49.139511
  ],
  [
    87.1746569,
    49.139631
  ],
  [
    87.1741069,
    49.13982
  ],
  [
    87.1734059,
    49.13976
  ],
  [
    87.1731359,
    49.139545
  ],
  [
    87.1720929,
    49.139563
  ],
  [
    87.1716259,
    49.139483
  ],
  [
    87.1711689,
    49.13931
  ],
  [
    87.1710089,
    49.139113
  ],
  [
    87.1707199,
    49.139009
  ],
  [
    87.1700129,
    49.138909
  ],
  [
    87.1692189,
    49.138947
  ],
  [
    87.1686709,
    49.138877
  ],
  [
    87.1677859,
    49.139134
  ],
  [
    87.1668149,
    49.139519
  ],
  [
    87.1665429,
    49.139698
  ],
  [
    87.1658569,
    49.139923
  ],
  [
    87.1653469,
    49.140207
  ],
  [
    87.1647769,
    49.140271
  ],
  [
    87.1641339,
    49.140846
  ],
  [
    87.1627549,
    49.141419
  ],
  [
    87.1617039,
    49.141673
  ],
  [
    87.1610769,
    49.141902
  ],
  [
    87.1593249,
    49.142371
  ],
  [
    87.1587269,
    49.142829
  ],
  [
    87.1576079,
    49.143235
  ],
  [
    87.1565689,
    49.143724
  ],
  [
    87.1555819,
    49.144107
  ],
  [
    87.1553669,
    49.144276
  ],
  [
    87.1541939,
    49.144753
  ],
  [
    87.1532629,
    49.14521
  ],
  [
    87.1526839,
    49.145611
  ],
  [
    87.1523209,
    49.146101
  ],
  [
    87.1523999,
    49.14642
  ],
  [
    87.1521679,
    49.14657
  ],
  [
    87.1518429,
    49.147656
  ],
  [
    87.1514609,
    49.148384
  ],
  [
    87.1510099,
    49.149024
  ],
  [
    87.1511349,
    49.149361
  ],
  [
    87.1509099,
    49.149427
  ],
  [
    87.1507919,
    49.149588
  ],
  [
    87.1509249,
    49.149758
  ],
  [
    87.1514329,
    49.150148
  ],
  [
    87.1515139,
    49.150749
  ],
  [
    87.1500279,
    49.150753
  ],
  [
    87.1477959,
    49.151317
  ],
  [
    87.1449129,
    49.151639
  ],
  [
    87.1434939,
    49.151966
  ],
  [
    87.1425089,
    49.152272
  ],
  [
    87.1418299,
    49.152374
  ],
  [
    87.1410999,
    49.152304
  ],
  [
    87.1404309,
    49.152049
  ],
  [
    87.1399209,
    49.151676
  ],
  [
    87.1395809,
    49.150952
  ],
  [
    87.1384889,
    49.15077
  ],
  [
    87.1359519,
    49.150801
  ],
  [
    87.1343839,
    49.150883
  ],
  [
    87.1328569,
    49.151064
  ],
  [
    87.1319359,
    49.151032
  ],
  [
    87.1303139,
    49.15118
  ],
  [
    87.1287369,
    49.151537
  ],
  [
    87.1274329,
    49.152018
  ],
  [
    87.1264849,
    49.152182
  ],
  [
    87.1252519,
    49.152641
  ],
  [
    87.1240179,
    49.152659
  ],
  [
    87.1202839,
    49.152438
  ],
  [
    87.1180569,
    49.152586
  ],
  [
    87.1162409,
    49.152496
  ],
  [
    87.1131749,
    49.152261
  ],
  [
    87.1095879,
    49.151617
  ],
  [
    87.1082709,
    49.15178
  ],
  [
    87.1059729,
    49.151452
  ],
  [
    87.1041369,
    49.151124
  ],
  [
    87.1034899,
    49.150112
  ],
  [
    87.1028069,
    49.149544
  ],
  [
    87.1013479,
    49.148674
  ],
  [
    87.1003199,
    49.147541
  ],
  [
    87.1000719,
    49.146811
  ],
  [
    87.1001559,
    49.145459
  ],
  [
    87.0999379,
    49.144685
  ],
  [
    87.1000949,
    49.143336
  ],
  [
    87.1005419,
    49.141347
  ],
  [
    87.1010279,
    49.140729
  ],
  [
    87.1009539,
    49.140353
  ],
  [
    87.1005749,
    49.140051
  ],
  [
    87.0978939,
    49.139226
  ],
  [
    87.0967899,
    49.13895
  ],
  [
    87.0957099,
    49.138964
  ],
  [
    87.0947849,
    49.138485
  ],
  [
    87.0938149,
    49.138726
  ],
  [
    87.0926959,
    49.138345
  ],
  [
    87.0897289,
    49.138035
  ],
  [
    87.0882839,
    49.137171
  ],
  [
    87.0870259,
    49.136305
  ],
  [
    87.0868139,
    49.135894
  ],
  [
    87.0855529,
    49.134937
  ],
  [
    87.0844589,
    49.133795
  ],
  [
    87.0838509,
    49.132738
  ],
  [
    87.0826209,
    49.131518
  ],
  [
    87.0824739,
    49.131059
  ],
  [
    87.0816109,
    49.131013
  ],
  [
    87.0810059,
    49.130878
  ],
  [
    87.0793829,
    49.131017
  ],
  [
    87.0784469,
    49.131276
  ],
  [
    87.0769079,
    49.131349
  ],
  [
    87.0757629,
    49.131734
  ],
  [
    87.0747889,
    49.132325
  ],
  [
    87.0739279,
    49.132218
  ],
  [
    87.0717143,
    49.1347499
  ],
  [
    87.0671652,
    49.1353957
  ],
  [
    87.0650195,
    49.1342164
  ],
  [
    87.0629442,
    49.1323571
  ],
  [
    87.06223,
    49.1317173
  ],
  [
    87.0598349,
    49.12992
  ],
  [
    87.0594849,
    49.129906
  ],
  [
    87.0567829,
    49.129459
  ],
  [
    87.0563369,
    49.129263
  ],
  [
    87.0554719,
    49.129421
  ],
  [
    87.0544439,
    49.129733
  ],
  [
    87.0536419,
    49.129838
  ],
  [
    87.0529049,
    49.12999
  ],
  [
    87.0525119,
    49.130168
  ],
  [
    87.0518549,
    49.130269
  ],
  [
    87.0512169,
    49.130247
  ],
  [
    87.0507509,
    49.130305
  ],
  [
    87.0505969,
    49.130146
  ],
  [
    87.0502569,
    49.130063
  ],
  [
    87.0498239,
    49.130054
  ],
  [
    87.0486119,
    49.13052
  ],
  [
    87.0483899,
    49.130645
  ],
  [
    87.0479379,
    49.130637
  ],
  [
    87.0473029,
    49.130815
  ],
  [
    87.0467019,
    49.130869
  ],
  [
    87.0464399,
    49.130933
  ],
  [
    87.0451249,
    49.130957
  ],
  [
    87.0448849,
    49.131143
  ],
  [
    87.0446039,
    49.131195
  ],
  [
    87.0443199,
    49.131124
  ],
  [
    87.0437649,
    49.13122
  ],
  [
    87.0432779,
    49.131348
  ],
  [
    87.0427009,
    49.131611
  ],
  [
    87.0424079,
    49.132019
  ],
  [
    87.0413399,
    49.132176
  ],
  [
    87.0407079,
    49.132502
  ],
  [
    87.0404829,
    49.132528
  ],
  [
    87.0402989,
    49.132702
  ],
  [
    87.0400539,
    49.132716
  ],
  [
    87.0398509,
    49.132878
  ],
  [
    87.0396429,
    49.132843
  ],
  [
    87.0380609,
    49.133619
  ],
  [
    87.0376139,
    49.133783
  ],
  [
    87.0372059,
    49.134057
  ],
  [
    87.0359689,
    49.134253
  ],
  [
    87.0357839,
    49.134426
  ],
  [
    87.0355049,
    49.134527
  ],
  [
    87.0353389,
    49.134701
  ],
  [
    87.0351149,
    49.134764
  ],
  [
    87.0346889,
    49.1351
  ],
  [
    87.0338849,
    49.135292
  ],
  [
    87.0330779,
    49.135372
  ],
  [
    87.0329309,
    49.135534
  ],
  [
    87.0323169,
    49.135643
  ],
  [
    87.0320189,
    49.135844
  ],
  [
    87.0315289,
    49.135787
  ],
  [
    87.0306969,
    49.136053
  ],
  [
    87.0299699,
    49.1358
  ],
  [
    87.0297169,
    49.13594
  ],
  [
    87.0295369,
    49.136261
  ],
  [
    87.0292609,
    49.136431
  ],
  [
    87.0288219,
    49.136572
  ],
  [
    87.0285429,
    49.136589
  ],
  [
    87.0278319,
    49.137113
  ],
  [
    87.0276009,
    49.137207
  ],
  [
    87.0271579,
    49.13718
  ],
  [
    87.0267589,
    49.137001
  ],
  [
    87.0264339,
    49.137064
  ],
  [
    87.0262269,
    49.137158
  ],
  [
    87.0259239,
    49.13713
  ],
  [
    87.0252109,
    49.137547
  ],
  [
    87.0247009,
    49.137673
  ],
  [
    87.0241709,
    49.137921
  ],
  [
    87.0236599,
    49.138017
  ],
  [
    87.0232939,
    49.138294
  ],
  [
    87.0226659,
    49.138315
  ],
  [
    87.0220649,
    49.138564
  ],
  [
    87.0209769,
    49.138817
  ],
  [
    87.0207959,
    49.139077
  ],
  [
    87.0203879,
    49.139146
  ],
  [
    87.0199679,
    49.139074
  ],
  [
    87.0191569,
    49.139278
  ],
  [
    87.0187219,
    49.139648
  ],
  [
    87.0183059,
    49.139773
  ],
  [
    87.017523,
    49.1398836
  ],
  [
    87.0140579,
    49.140373
  ],
  [
    87.0129819,
    49.140324
  ],
  [
    87.0124709,
    49.140404
  ],
  [
    87.0101909,
    49.140469
  ],
  [
    87.0097309,
    49.140747
  ],
  [
    87.0089439,
    49.140967
  ],
  [
    87.0083869,
    49.141009
  ],
  [
    87.0078249,
    49.140846
  ],
  [
    87.0069869,
    49.140853
  ],
  [
    87.0065239,
    49.140948
  ],
  [
    87.0060389,
    49.14115
  ],
  [
    87.0057639,
    49.141381
  ],
  [
    87.0056459,
    49.141631
  ],
  [
    87.0048629,
    49.142033
  ],
  [
    87.0042599,
    49.142176
  ],
  [
    87.0039809,
    49.142163
  ],
  [
    87.0037729,
    49.142256
  ],
  [
    87.0035399,
    49.142243
  ],
  [
    87.0034429,
    49.142045
  ],
  [
    87.0016289,
    49.142152
  ],
  [
    87.0009629,
    49.142462
  ],
  [
    87.0000579,
    49.142606
  ],
  [
    86.9993149,
    49.142674
  ],
  [
    86.9965209,
    49.142682
  ],
  [
    86.9960549,
    49.14264
  ],
  [
    86.9944849,
    49.142075
  ],
  [
    86.9918959,
    49.141837
  ],
  [
    86.9911249,
    49.141707
  ],
  [
    86.9895309,
    49.141111
  ],
  [
    86.9893819,
    49.14064
  ],
  [
    86.9886289,
    49.14022
  ],
  [
    86.9883489,
    49.140176
  ],
  [
    86.9872219,
    49.140171
  ],
  [
    86.9866659,
    49.140282
  ],
  [
    86.9860379,
    49.140287
  ],
  [
    86.9853579,
    49.140049
  ],
  [
    86.9849789,
    49.139748
  ],
  [
    86.9842539,
    49.139556
  ],
  [
    86.9838289,
    49.139224
  ],
  [
    86.9838249,
    49.138996
  ],
  [
    86.9833779,
    49.138786
  ],
  [
    86.9830239,
    49.138546
  ],
  [
    86.9822329,
    49.138537
  ],
  [
    86.9816489,
    49.138466
  ],
  [
    86.9812039,
    49.138271
  ],
  [
    86.9808939,
    49.137893
  ],
  [
    86.9805669,
    49.13782
  ],
  [
    86.9797689,
    49.137491
  ],
  [
    86.9786229,
    49.137227
  ],
  [
    86.9782009,
    49.137063
  ],
  [
    86.9771969,
    49.136888
  ],
  [
    86.9761659,
    49.136531
  ],
  [
    86.9755979,
    49.136033
  ],
  [
    86.9754529,
    49.135745
  ],
  [
    86.9751239,
    49.135596
  ],
  [
    86.9743469,
    49.135099
  ],
  [
    86.9736329,
    49.135049
  ],
  [
    86.9725359,
    49.134612
  ],
  [
    86.9720269,
    49.134639
  ],
  [
    86.9712039,
    49.134356
  ],
  [
    86.9711339,
    49.134201
  ],
  [
    86.9700079,
    49.134032
  ],
  [
    86.9697659,
    49.133811
  ],
  [
    86.9688059,
    49.133441
  ],
  [
    86.9675799,
    49.133384
  ],
  [
    86.9656879,
    49.133902
  ],
  [
    86.9648099,
    49.134037
  ],
  [
    86.9646099,
    49.134042
  ],
  [
    86.9640939,
    49.133943
  ],
  [
    86.9638379,
    49.134001
  ],
  [
    86.9637579,
    49.134064
  ],
  [
    86.9635059,
    49.134094
  ],
  [
    86.9633769,
    49.134058
  ],
  [
    86.9633149,
    49.133968
  ],
  [
    86.9628789,
    49.13385
  ],
  [
    86.9627409,
    49.13382
  ],
  [
    86.9622709,
    49.133902
  ],
  [
    86.9618649,
    49.13379
  ],
  [
    86.9617479,
    49.133691
  ],
  [
    86.9613849,
    49.133619
  ],
  [
    86.9611629,
    49.133444
  ],
  [
    86.9609319,
    49.13281
  ],
  [
    86.9608539,
    49.132711
  ],
  [
    86.9607049,
    49.132659
  ],
  [
    86.9601289,
    49.133106
  ],
  [
    86.9599309,
    49.133177
  ],
  [
    86.9592699,
    49.133256
  ],
  [
    86.9589749,
    49.133375
  ],
  [
    86.9588359,
    49.133385
  ],
  [
    86.9583099,
    49.13325
  ],
  [
    86.9580239,
    49.133244
  ],
  [
    86.9575919,
    49.133156
  ],
  [
    86.9569299,
    49.133145
  ],
  [
    86.9566649,
    49.133033
  ],
  [
    86.9564019,
    49.133048
  ],
  [
    86.9562319,
    49.133005
  ],
  [
    86.9558079,
    49.132717
  ],
  [
    86.9552329,
    49.132634
  ],
  [
    86.9549019,
    49.132632
  ],
  [
    86.9547459,
    49.132549
  ],
  [
    86.9547099,
    49.132474
  ],
  [
    86.9547319,
    49.132192
  ],
  [
    86.9546829,
    49.132117
  ],
  [
    86.9544999,
    49.132039
  ],
  [
    86.9543339,
    49.131785
  ],
  [
    86.9540189,
    49.131518
  ],
  [
    86.9538009,
    49.131418
  ],
  [
    86.9535669,
    49.131155
  ],
  [
    86.9533109,
    49.131197
  ],
  [
    86.9529109,
    49.131068
  ],
  [
    86.9524639,
    49.131023
  ],
  [
    86.9520939,
    49.131066
  ],
  [
    86.9519259,
    49.131111
  ],
  [
    86.9511789,
    49.131563
  ],
  [
    86.9502859,
    49.131844
  ],
  [
    86.9500839,
    49.131868
  ],
  [
    86.9495779,
    49.131719
  ],
  [
    86.9491809,
    49.131427
  ],
  [
    86.9487749,
    49.13135
  ],
  [
    86.9480269,
    49.131405
  ],
  [
    86.9473309,
    49.131397
  ],
  [
    86.9470289,
    49.131461
  ],
  [
    86.9467089,
    49.131327
  ],
  [
    86.9465809,
    49.131332
  ],
  [
    86.9461029,
    49.131385
  ],
  [
    86.9457139,
    49.131489
  ],
  [
    86.9454909,
    49.131487
  ],
  [
    86.9450489,
    49.131162
  ],
  [
    86.9447569,
    49.131046
  ],
  [
    86.9445509,
    49.130884
  ],
  [
    86.9444139,
    49.130752
  ],
  [
    86.9443979,
    49.130616
  ],
  [
    86.9442069,
    49.130485
  ],
  [
    86.9439779,
    49.130164
  ],
  [
    86.9436919,
    49.130021
  ],
  [
    86.9434229,
    49.130085
  ],
  [
    86.9428769,
    49.130107
  ],
  [
    86.9429469,
    49.13023
  ],
  [
    86.9425789,
    49.130436
  ],
  [
    86.9423239,
    49.130848
  ],
  [
    86.9419859,
    49.131169
  ],
  [
    86.9414599,
    49.131383
  ],
  [
    86.9407139,
    49.131565
  ],
  [
    86.9396539,
    49.131498
  ],
  [
    86.9392599,
    49.131378
  ],
  [
    86.9381539,
    49.131404
  ],
  [
    86.9380309,
    49.131352
  ],
  [
    86.9379819,
    49.131251
  ],
  [
    86.9374799,
    49.130977
  ],
  [
    86.9372899,
    49.13089
  ],
  [
    86.9368899,
    49.130809
  ],
  [
    86.9363389,
    49.130946
  ],
  [
    86.9356889,
    49.131207
  ],
  [
    86.9355609,
    49.131208
  ],
  [
    86.9353309,
    49.130817
  ],
  [
    86.9351749,
    49.130774
  ],
  [
    86.9348929,
    49.130838
  ],
  [
    86.9347749,
    49.131082
  ],
  [
    86.9344809,
    49.131186
  ],
  [
    86.9340589,
    49.13115
  ],
  [
    86.9339249,
    49.131069
  ],
  [
    86.9335619,
    49.131048
  ],
  [
    86.9334239,
    49.131155
  ],
  [
    86.9331939,
    49.131549
  ],
  [
    86.9331279,
    49.131559
  ],
  [
    86.9326719,
    49.131263
  ],
  [
    86.9325609,
    49.131273
  ],
  [
    86.9322689,
    49.131435
  ],
  [
    86.9315659,
    49.131421
  ],
  [
    86.9311609,
    49.131468
  ],
  [
    86.9307529,
    49.131413
  ],
  [
    86.9305289,
    49.131299
  ],
  [
    86.9302509,
    49.131277
  ],
  [
    86.9300969,
    49.131365
  ],
  [
    86.9300269,
    49.131545
  ],
  [
    86.9298459,
    49.131754
  ],
  [
    86.9293939,
    49.131706
  ],
  [
    86.9292969,
    49.131654
  ],
  [
    86.9292519,
    49.131596
  ],
  [
    86.9293669,
    49.131455
  ],
  [
    86.9291939,
    49.131272
  ],
  [
    86.9289879,
    49.131288
  ],
  [
    86.9288169,
    49.131241
  ],
  [
    86.9283879,
    49.131264
  ],
  [
    86.9282489,
    49.131294
  ],
  [
    86.9281269,
    49.131542
  ],
  [
    86.9275969,
    49.131652
  ],
  [
    86.9275809,
    49.13201
  ],
  [
    86.9274939,
    49.13205
  ],
  [
    86.9272279,
    49.132076
  ],
  [
    86.9269869,
    49.131855
  ],
  [
    86.9267099,
    49.131993
  ],
  [
    86.9261909,
    49.132133
  ],
  [
    86.9260369,
    49.132245
  ],
  [
    86.9260109,
    49.132705
  ],
  [
    86.9257579,
    49.132917
  ],
  [
    86.9256279,
    49.132834
  ],
  [
    86.9252549,
    49.132761
  ],
  [
    86.9251379,
    49.132645
  ],
  [
    86.9252249,
    49.132501
  ],
  [
    86.9251329,
    49.132401
  ],
  [
    86.9247849,
    49.132303
  ],
  [
    86.9240809,
    49.132368
  ],
  [
    86.9238609,
    49.132235
  ],
  [
    86.9236969,
    49.132035
  ],
  [
    86.9233459,
    49.132123
  ],
  [
    86.9231079,
    49.132294
  ],
  [
    86.9227579,
    49.132431
  ],
  [
    86.9227049,
    49.132565
  ],
  [
    86.9228369,
    49.132685
  ],
  [
    86.9228589,
    49.132867
  ],
  [
    86.9224909,
    49.133015
  ],
  [
    86.9221579,
    49.133066
  ],
  [
    86.9218779,
    49.133008
  ],
  [
    86.9215779,
    49.132792
  ],
  [
    86.9213549,
    49.132769
  ],
  [
    86.9211899,
    49.13288
  ],
  [
    86.9208739,
    49.132858
  ],
  [
    86.9206709,
    49.13292
  ],
  [
    86.9203809,
    49.133286
  ],
  [
    86.9202529,
    49.13336
  ],
  [
    86.9199949,
    49.133325
  ],
  [
    86.9199449,
    49.133086
  ],
  [
    86.9196509,
    49.133268
  ],
  [
    86.9194729,
    49.133569
  ],
  [
    86.9192239,
    49.13375
  ],
  [
    86.9187189,
    49.133679
  ],
  [
    86.9183299,
    49.133712
  ],
  [
    86.9181509,
    49.133924
  ],
  [
    86.9178779,
    49.134016
  ],
  [
    86.9172439,
    49.134096
  ],
  [
    86.9167889,
    49.134071
  ],
  [
    86.9163919,
    49.134026
  ],
  [
    86.9162429,
    49.133912
  ],
  [
    86.9160809,
    49.133894
  ],
  [
    86.9158919,
    49.133981
  ],
  [
    86.9157179,
    49.134273
  ],
  [
    86.9154119,
    49.134381
  ],
  [
    86.9152639,
    49.134315
  ],
  [
    86.9150699,
    49.134125
  ],
  [
    86.9145669,
    49.133927
  ],
  [
    86.9142439,
    49.133901
  ],
  [
    86.9141139,
    49.133988
  ],
  [
    86.9139729,
    49.134306
  ],
  [
    86.9136659,
    49.134376
  ],
  [
    86.9134889,
    49.134348
  ],
  [
    86.9133669,
    49.134071
  ],
  [
    86.9132169,
    49.133899
  ],
  [
    86.9129379,
    49.133882
  ],
  [
    86.9125159,
    49.134087
  ],
  [
    86.9119159,
    49.134178
  ],
  [
    86.9115989,
    49.134488
  ],
  [
    86.9113799,
    49.134556
  ],
  [
    86.9109039,
    49.134195
  ],
  [
    86.9107139,
    49.134235
  ],
  [
    86.9105119,
    49.134419
  ],
  [
    86.9097209,
    49.13455
  ],
  [
    86.9095009,
    49.134532
  ],
  [
    86.9092629,
    49.134352
  ],
  [
    86.9090269,
    49.134296
  ],
  [
    86.9088279,
    49.134313
  ],
  [
    86.9081269,
    49.13451
  ],
  [
    86.9079239,
    49.134637
  ],
  [
    86.9075119,
    49.135427
  ],
  [
    86.9068569,
    49.135701
  ],
  [
    86.9065459,
    49.135588
  ],
  [
    86.9065879,
    49.135482
  ],
  [
    86.9070399,
    49.135296
  ],
  [
    86.9070389,
    49.13521
  ],
  [
    86.9069049,
    49.135134
  ],
  [
    86.9067159,
    49.135231
  ],
  [
    86.9058659,
    49.135276
  ],
  [
    86.9055399,
    49.135115
  ],
  [
    86.9052319,
    49.135118
  ],
  [
    86.9049839,
    49.135187
  ],
  [
    86.9046749,
    49.135122
  ],
  [
    86.9042469,
    49.135001
  ],
  [
    86.9039779,
    49.134753
  ],
  [
    86.9039009,
    49.134552
  ],
  [
    86.9036639,
    49.13439
  ],
  [
    86.9030039,
    49.134443
  ],
  [
    86.9027119,
    49.134522
  ],
  [
    86.9023889,
    49.134525
  ],
  [
    86.9020639,
    49.134393
  ],
  [
    86.9016379,
    49.134348
  ],
  [
    86.9010509,
    49.134404
  ],
  [
    86.9007439,
    49.1347
  ],
  [
    86.9005299,
    49.134701
  ],
  [
    86.9001189,
    49.13459
  ],
  [
    86.8998829,
    49.134426
  ],
  [
    86.8995889,
    49.134352
  ],
  [
    86.8992359,
    49.134176
  ],
  [
    86.8986329,
    49.134232
  ],
  [
    86.8977259,
    49.134697
  ],
  [
    86.8973969,
    49.134776
  ],
  [
    86.8966969,
    49.134858
  ],
  [
    86.8963069,
    49.134822
  ],
  [
    86.8954269,
    49.134587
  ],
  [
    86.8948779,
    49.134387
  ],
  [
    86.8940319,
    49.133884
  ],
  [
    86.8935409,
    49.133652
  ],
  [
    86.8932309,
    49.133757
  ],
  [
    86.8929389,
    49.133797
  ],
  [
    86.8925489,
    49.133724
  ],
  [
    86.8919979,
    49.133384
  ],
  [
    86.8918189,
    49.133169
  ],
  [
    86.8916429,
    49.133132
  ],
  [
    86.8913659,
    49.132904
  ],
  [
    86.8905259,
    49.132745
  ],
  [
    86.8902739,
    49.132785
  ],
  [
    86.8900229,
    49.132902
  ],
  [
    86.8898279,
    49.132878
  ],
  [
    86.8895719,
    49.132739
  ],
  [
    86.8893779,
    49.132741
  ],
  [
    86.8885559,
    49.13253
  ],
  [
    86.8878359,
    49.132548
  ],
  [
    86.8873699,
    49.132616
  ],
  [
    86.8871549,
    49.132579
  ],
  [
    86.8868189,
    49.132301
  ],
  [
    86.8866049,
    49.132303
  ],
  [
    86.8864859,
    49.132202
  ],
  [
    86.8862519,
    49.132165
  ],
  [
    86.8857029,
    49.131915
  ],
  [
    86.8852139,
    49.131791
  ],
  [
    86.8848579,
    49.1315
  ],
  [
    86.8845999,
    49.131197
  ],
  [
    86.8841379,
    49.130385
  ],
  [
    86.8841359,
    49.130232
  ],
  [
    86.8839589,
    49.130144
  ],
  [
    86.8836839,
    49.130019
  ],
  [
    86.8829599,
    49.12982
  ],
  [
    86.8823959,
    49.129824
  ],
  [
    86.8821529,
    49.129758
  ],
  [
    86.8816819,
    49.129558
  ],
  [
    86.8814649,
    49.129394
  ],
  [
    86.8800549,
    49.128895
  ],
  [
    86.8797639,
    49.12896
  ],
  [
    86.8793929,
    49.128887
  ],
  [
    86.8792729,
    49.128709
  ],
  [
    86.8789989,
    49.128647
  ],
  [
    86.8787039,
    49.128484
  ],
  [
    86.8780129,
    49.127941
  ],
  [
    86.8778289,
    49.127433
  ],
  [
    86.8775929,
    49.127256
  ],
  [
    86.8776479,
    49.127078
  ],
  [
    86.8772149,
    49.126762
  ],
  [
    86.8772309,
    49.126571
  ],
  [
    86.8769729,
    49.12628
  ],
  [
    86.8762889,
    49.12617
  ],
  [
    86.8754019,
    49.125502
  ],
  [
    86.8752999,
    49.12526
  ],
  [
    86.8753649,
    49.124508
  ],
  [
    86.8752459,
    49.124369
  ],
  [
    86.8751989,
    49.12386
  ],
  [
    86.8747659,
    49.123621
  ],
  [
    86.8747019,
    49.123277
  ],
  [
    86.8752399,
    49.122866
  ],
  [
    86.8753529,
    49.122674
  ],
  [
    86.8755269,
    49.122596
  ],
  [
    86.8756779,
    49.122417
  ],
  [
    86.8754569,
    49.121799
  ],
  [
    86.8747739,
    49.12077
  ],
  [
    86.8745289,
    49.120522
  ],
  [
    86.8742999,
    49.120424
  ],
  [
    86.8736839,
    49.120393
  ],
  [
    86.8725989,
    49.120515
  ],
  [
    86.8719249,
    49.120508
  ],
  [
    86.8713429,
    49.12002
  ],
  [
    86.8709119,
    49.119839
  ],
  [
    86.8697309,
    49.119931
  ],
  [
    86.8689099,
    49.119786
  ],
  [
    86.8681029,
    49.119447
  ],
  [
    86.8675349,
    49.11902
  ],
  [
    86.8672469,
    49.118877
  ],
  [
    86.8666379,
    49.118698
  ],
  [
    86.8663289,
    49.118648
  ],
  [
    86.8660099,
    49.118652
  ],
  [
    86.8654619,
    49.118519
  ],
  [
    86.8646389,
    49.118282
  ],
  [
    86.8641099,
    49.118002
  ],
  [
    86.8636919,
    49.117875
  ],
  [
    86.8625359,
    49.117704
  ],
  [
    86.8623069,
    49.11756
  ],
  [
    86.8619639,
    49.117162
  ],
  [
    86.8614459,
    49.116835
  ],
  [
    86.8608229,
    49.116154
  ],
  [
    86.8606849,
    49.1159
  ],
  [
    86.8606829,
    49.115396
  ],
  [
    86.8605839,
    49.115243
  ],
  [
    86.8598719,
    49.114764
  ],
  [
    86.8591629,
    49.114401
  ],
  [
    86.8588339,
    49.114064
  ],
  [
    86.8586259,
    49.113639
  ],
  [
    86.8586619,
    49.113251
  ],
  [
    86.8582929,
    49.112745
  ],
  [
    86.8578569,
    49.112425
  ],
  [
    86.8572089,
    49.112085
  ],
  [
    86.8555429,
    49.111338
  ],
  [
    86.8550669,
    49.111228
  ],
  [
    86.8540219,
    49.111078
  ],
  [
    86.8537209,
    49.110904
  ],
  [
    86.8531749,
    49.110745
  ],
  [
    86.8528269,
    49.110571
  ],
  [
    86.8527489,
    49.110348
  ],
  [
    86.8519979,
    49.109706
  ],
  [
    86.8516459,
    49.108986
  ],
  [
    86.8507599,
    49.108184
  ],
  [
    86.8505229,
    49.107784
  ],
  [
    86.8503389,
    49.107128
  ],
  [
    86.8500709,
    49.106837
  ],
  [
    86.8496969,
    49.106586
  ],
  [
    86.8490989,
    49.106353
  ],
  [
    86.8489619,
    49.106131
  ],
  [
    86.8489529,
    49.105403
  ],
  [
    86.8488669,
    49.10528
  ],
  [
    86.8485279,
    49.105128
  ],
  [
    86.8481189,
    49.104625
  ],
  [
    86.8478499,
    49.104396
  ],
  [
    86.8465329,
    49.103878
  ],
  [
    86.8460619,
    49.103514
  ],
  [
    86.8460909,
    49.103126
  ],
  [
    86.8462259,
    49.102789
  ],
  [
    86.8461269,
    49.102557
  ],
  [
    86.8462529,
    49.102366
  ],
  [
    86.8462869,
    49.102185
  ],
  [
    86.8459739,
    49.101784
  ],
  [
    86.8455849,
    49.101531
  ],
  [
    86.8454089,
    49.101343
  ],
  [
    86.8446509,
    49.101051
  ],
  [
    86.8443609,
    49.101038
  ],
  [
    86.8438719,
    49.100909
  ],
  [
    86.8435349,
    49.100629
  ],
  [
    86.8434619,
    49.10038
  ],
  [
    86.8435319,
    49.100035
  ],
  [
    86.8434759,
    49.099484
  ],
  [
    86.8443399,
    49.098423
  ],
  [
    86.8443739,
    49.098225
  ],
  [
    86.8442969,
    49.097813
  ],
  [
    86.8444169,
    49.097406
  ],
  [
    86.8443199,
    49.097235
  ],
  [
    86.8437439,
    49.096846
  ],
  [
    86.8426929,
    49.096357
  ],
  [
    86.8425549,
    49.096135
  ],
  [
    86.8422819,
    49.095311
  ],
  [
    86.8423989,
    49.094811
  ],
  [
    86.8423949,
    49.093734
  ],
  [
    86.8421339,
    49.093359
  ],
  [
    86.8420859,
    49.093067
  ],
  [
    86.8426149,
    49.092208
  ],
  [
    86.8425729,
    49.091816
  ],
  [
    86.8427319,
    49.091441
  ],
  [
    86.8429439,
    49.090391
  ],
  [
    86.8430359,
    49.090191
  ],
  [
    86.8432109,
    49.089951
  ],
  [
    86.8440539,
    49.089354
  ],
  [
    86.8440849,
    49.089163
  ],
  [
    86.8440089,
    49.089029
  ],
  [
    86.8435919,
    49.088859
  ],
  [
    86.8429929,
    49.088272
  ],
  [
    86.8423179,
    49.08797
  ],
  [
    86.8421559,
    49.087806
  ],
  [
    86.8421109,
    49.08757
  ],
  [
    86.8422299,
    49.087069
  ],
  [
    86.8427309,
    49.085928
  ],
  [
    86.8432189,
    49.085192
  ],
  [
    86.8432469,
    49.084898
  ],
  [
    86.8431549,
    49.084637
  ],
  [
    86.8429329,
    49.084325
  ],
  [
    86.8430339,
    49.084046
  ],
  [
    86.8430039,
    49.083856
  ],
  [
    86.8427299,
    49.083558
  ],
  [
    86.8428079,
    49.083334
  ],
  [
    86.8432109,
    49.08302
  ],
  [
    86.8437859,
    49.08237
  ],
  [
    86.8438359,
    49.081989
  ],
  [
    86.8441179,
    49.081232
  ],
  [
    86.8441089,
    49.080922
  ],
  [
    86.8436509,
    49.080571
  ],
  [
    86.8438239,
    49.080081
  ],
  [
    86.8441139,
    49.079633
  ],
  [
    86.8443749,
    49.079424
  ],
  [
    86.8445499,
    49.079175
  ],
  [
    86.8445289,
    49.07889
  ],
  [
    86.8442509,
    49.078481
  ],
  [
    86.8442769,
    49.078107
  ],
  [
    86.8444289,
    49.077923
  ],
  [
    86.8456569,
    49.077242
  ],
  [
    86.8458929,
    49.077001
  ],
  [
    86.8460269,
    49.076602
  ],
  [
    86.8459459,
    49.076318
  ],
  [
    86.8460839,
    49.076038
  ],
  [
    86.8460709,
    49.075727
  ],
  [
    86.8458529,
    49.075317
  ],
  [
    86.8457799,
    49.074882
  ],
  [
    86.8456119,
    49.074519
  ],
  [
    86.8454379,
    49.07433
  ],
  [
    86.8447849,
    49.073942
  ],
  [
    86.8445109,
    49.073691
  ],
  [
    86.8442459,
    49.073313
  ],
  [
    86.8440629,
    49.072824
  ],
  [
    86.8440299,
    49.072546
  ],
  [
    86.8441079,
    49.072291
  ],
  [
    86.8442259,
    49.072171
  ],
  [
    86.8448129,
    49.071965
  ],
  [
    86.8450149,
    49.071804
  ],
  [
    86.8452129,
    49.071529
  ],
  [
    86.8452199,
    49.071346
  ],
  [
    86.8451299,
    49.071141
  ],
  [
    86.8451909,
    49.070736
  ],
  [
    86.8455069,
    49.070343
  ],
  [
    86.8456969,
    49.070198
  ],
  [
    86.8460339,
    49.069717
  ],
  [
    86.8465029,
    49.069609
  ],
  [
    86.8467769,
    49.069415
  ],
  [
    86.8469519,
    49.069183
  ],
  [
    86.8469299,
    49.068834
  ],
  [
    86.8467319,
    49.068459
  ],
  [
    86.8469019,
    49.068045
  ],
  [
    86.8472469,
    49.067818
  ],
  [
    86.8473689,
    49.067444
  ],
  [
    86.8473379,
    49.067206
  ],
  [
    86.8469289,
    49.066434
  ],
  [
    86.8471589,
    49.066002
  ],
  [
    86.8473939,
    49.065761
  ],
  [
    86.8475329,
    49.065514
  ],
  [
    86.8475019,
    49.0653
  ],
  [
    86.8465449,
    49.063808
  ],
  [
    86.8459509,
    49.063363
  ],
  [
    86.8456239,
    49.063367
  ],
  [
    86.8453399,
    49.063188
  ],
  [
    86.8449549,
    49.062488
  ],
  [
    86.8442989,
    49.061988
  ],
  [
    86.8439859,
    49.06165
  ],
  [
    86.8437639,
    49.061518
  ],
  [
    86.8431559,
    49.061399
  ],
  [
    86.8430189,
    49.061257
  ],
  [
    86.8429529,
    49.061068
  ],
  [
    86.8427549,
    49.060951
  ],
  [
    86.8424389,
    49.060876
  ],
  [
    86.8421119,
    49.06088
  ],
  [
    86.8418069,
    49.060812
  ],
  [
    86.8412859,
    49.060794
  ],
  [
    86.8407039,
    49.060872
  ],
  [
    86.8402909,
    49.060845
  ],
  [
    86.8395329,
    49.0606
  ],
  [
    86.8393469,
    49.060436
  ],
  [
    86.8390749,
    49.05982
  ],
  [
    86.8389139,
    49.058944
  ],
  [
    86.8386049,
    49.058313
  ],
  [
    86.8377329,
    49.057427
  ],
  [
    86.8367499,
    49.056058
  ],
  [
    86.8361359,
    49.055748
  ],
  [
    86.8356609,
    49.05481
  ],
  [
    86.8348799,
    49.054019
  ],
  [
    86.8347559,
    49.053473
  ],
  [
    86.8347929,
    49.053052
  ],
  [
    86.8346629,
    49.052736
  ],
  [
    86.8344609,
    49.052468
  ],
  [
    86.8332749,
    49.052046
  ],
  [
    86.8325129,
    49.052087
  ],
  [
    86.8322489,
    49.05217
  ],
  [
    86.8318469,
    49.052087
  ],
  [
    86.8317209,
    49.051891
  ],
  [
    86.8317149,
    49.051684
  ],
  [
    86.8315689,
    49.05138
  ],
  [
    86.8313289,
    49.051033
  ],
  [
    86.8311319,
    49.050933
  ],
  [
    86.8306939,
    49.050414
  ],
  [
    86.8305509,
    49.050051
  ],
  [
    86.8298869,
    49.049273
  ],
  [
    86.8296289,
    49.049134
  ],
  [
    86.8292889,
    49.049114
  ],
  [
    86.8278529,
    49.049266
  ],
  [
    86.8271879,
    49.049282
  ],
  [
    86.8250979,
    49.049076
  ],
  [
    86.8242959,
    49.048959
  ],
  [
    86.8235999,
    49.048753
  ],
  [
    86.8233899,
    49.048597
  ],
  [
    86.8233389,
    49.048486
  ],
  [
    86.8235469,
    49.048142
  ],
  [
    86.8235549,
    49.047984
  ],
  [
    86.8234399,
    49.047763
  ],
  [
    86.8228319,
    49.047262
  ],
  [
    86.8225989,
    49.047146
  ],
  [
    86.8223439,
    49.047117
  ],
  [
    86.8221579,
    49.047397
  ],
  [
    86.8217789,
    49.047703
  ],
  [
    86.8215479,
    49.047682
  ],
  [
    86.8211789,
    49.047465
  ],
  [
    86.8209479,
    49.047428
  ],
  [
    86.8201479,
    49.047118
  ],
  [
    86.8200399,
    49.047037
  ],
  [
    86.8199389,
    49.046817
  ],
  [
    86.8197469,
    49.046736
  ],
  [
    86.8193659,
    49.046692
  ],
  [
    86.8190009,
    49.046461
  ],
  [
    86.8188689,
    49.046276
  ],
  [
    86.8190449,
    49.046157
  ],
  [
    86.8190319,
    49.046074
  ],
  [
    86.8186469,
    49.045885
  ],
  [
    86.8178509,
    49.045069
  ],
  [
    86.8174099,
    49.044756
  ],
  [
    86.8172239,
    49.044686
  ],
  [
    86.8155959,
    49.044933
  ],
  [
    86.8148559,
    49.04488
  ],
  [
    86.8143549,
    49.044706
  ],
  [
    86.8139149,
    49.044251
  ],
  [
    86.8126549,
    49.043789
  ],
  [
    86.8120649,
    49.043751
  ],
  [
    86.8111249,
    49.043592
  ],
  [
    86.8100329,
    49.04356
  ],
  [
    86.8095629,
    49.043486
  ],
  [
    86.8088939,
    49.043085
  ],
  [
    86.8081339,
    49.042057
  ],
  [
    86.8076369,
    49.041643
  ],
  [
    86.8069689,
    49.041276
  ],
  [
    86.8055259,
    49.0411
  ],
  [
    86.8048259,
    49.041437
  ],
  [
    86.8039949,
    49.041459
  ],
  [
    86.8035589,
    49.04135
  ],
  [
    86.8032769,
    49.041194
  ],
  [
    86.8031129,
    49.04089
  ],
  [
    86.8028129,
    49.040678
  ],
  [
    86.8027509,
    49.040315
  ],
  [
    86.8014219,
    49.039229
  ],
  [
    86.8010879,
    49.03904
  ],
  [
    86.8007729,
    49.038931
  ],
  [
    86.8001469,
    49.038836
  ],
  [
    86.7992999,
    49.038641
  ],
  [
    86.7987269,
    49.03858
  ],
  [
    86.7979819,
    49.038588
  ],
  [
    86.7968789,
    49.038783
  ],
  [
    86.7966059,
    49.038911
  ],
  [
    86.7958279,
    49.039144
  ],
  [
    86.7952749,
    49.039196
  ],
  [
    86.7949429,
    49.039097
  ],
  [
    86.7942229,
    49.03872
  ],
  [
    86.7939739,
    49.038473
  ],
  [
    86.7936239,
    49.038352
  ],
  [
    86.7933519,
    49.037913
  ],
  [
    86.7927389,
    49.036421
  ],
  [
    86.7922979,
    49.03612
  ],
  [
    86.7920329,
    49.035731
  ],
  [
    86.7913189,
    49.034967
  ],
  [
    86.7911369,
    49.03464
  ],
  [
    86.7907879,
    49.03453
  ],
  [
    86.7906109,
    49.034408
  ],
  [
    86.7899139,
    49.034245
  ],
  [
    86.7892609,
    49.033799
  ],
  [
    86.7892169,
    49.033436
  ],
  [
    86.7886209,
    49.033171
  ],
  [
    86.7883919,
    49.032401
  ],
  [
    86.7871919,
    49.03189
  ],
  [
    86.7866629,
    49.031533
  ],
  [
    86.7864129,
    49.031264
  ],
  [
    86.7862659,
    49.030959
  ],
  [
    86.786203,
    49.030551
  ],
  [
    86.7857479,
    49.030374
  ],
  [
    86.7850409,
    49.030485
  ],
  [
    86.7844539,
    49.030526
  ],
  [
    86.7840889,
    49.030485
  ],
  [
    86.7837749,
    49.030386
  ],
  [
    86.7832789,
    49.029995
  ],
  [
    86.782742,
    49.029354
  ],
  [
    86.782448,
    49.02871
  ],
  [
    86.7808859,
    49.027309
  ],
  [
    86.7806589,
    49.027232
  ],
  [
    86.7804339,
    49.027235
  ],
  [
    86.7799539,
    49.027444
  ],
  [
    86.7796099,
    49.027528
  ],
  [
    86.7786759,
    49.027539
  ],
  [
    86.7768329,
    49.027169
  ],
  [
    86.7761149,
    49.026871
  ],
  [
    86.7755689,
    49.026525
  ],
  [
    86.7753089,
    49.026506
  ],
  [
    86.7751019,
    49.026542
  ],
  [
    86.7748649,
    49.026738
  ],
  [
    86.7743699,
    49.026993
  ],
  [
    86.7731039,
    49.027564
  ],
  [
    86.772605,
    49.027684
  ],
  [
    86.772259,
    49.02771
  ],
  [
    86.7720499,
    49.027679
  ],
  [
    86.7717519,
    49.027535
  ],
  [
    86.7714149,
    49.027243
  ],
  [
    86.7712049,
    49.027166
  ],
  [
    86.7705949,
    49.027003
  ],
  [
    86.7698429,
    49.026728
  ],
  [
    86.7689429,
    49.02651
  ],
  [
    86.7674049,
    49.026005
  ],
  [
    86.76688,
    49.025773
  ],
  [
    86.7662689,
    49.02561
  ],
  [
    86.7649129,
    49.024762
  ],
  [
    86.7647479,
    49.024412
  ],
  [
    86.7643099,
    49.024201
  ],
  [
    86.7641309,
    49.02401
  ],
  [
    86.7640339,
    49.023614
  ],
  [
    86.764504,
    49.022405
  ],
  [
    86.764578,
    49.021939
  ],
  [
    86.764297,
    49.021783
  ],
  [
    86.764171,
    49.021626
  ],
  [
    86.763157,
    49.021251
  ],
  [
    86.762962,
    49.021061
  ],
  [
    86.762884,
    49.020755
  ],
  [
    86.762691,
    49.020655
  ],
  [
    86.7619849,
    49.020799
  ],
  [
    86.76169,
    49.02078
  ],
  [
    86.761375,
    49.020647
  ],
  [
    86.761075,
    49.020435
  ],
  [
    86.7606289,
    49.01994
  ],
  [
    86.7604579,
    49.019648
  ],
  [
    86.7602099,
    49.019435
  ],
  [
    86.7597359,
    49.019191
  ],
  [
    86.7587739,
    49.018804
  ],
  [
    86.7584429,
    49.018751
  ],
  [
    86.7580809,
    49.018789
  ],
  [
    86.7575429,
    49.018739
  ],
  [
    86.7573669,
    49.018639
  ],
  [
    86.7572009,
    49.018266
  ],
  [
    86.756721,
    49.017794
  ],
  [
    86.756817,
    49.017498
  ],
  [
    86.756681,
    49.016943
  ],
  [
    86.756213,
    49.016915
  ],
  [
    86.7555,
    49.016775
  ],
  [
    86.754498,
    49.016855
  ],
  [
    86.753281,
    49.016375
  ],
  [
    86.752329,
    49.016352
  ],
  [
    86.751388,
    49.016147
  ],
  [
    86.750742,
    49.015904
  ],
  [
    86.750459,
    49.01568
  ],
  [
    86.750313,
    49.015432
  ],
  [
    86.750119,
    49.015276
  ],
  [
    86.749645,
    49.015031
  ],
  [
    86.749164,
    49.014537
  ],
  [
    86.749156,
    49.014231
  ],
  [
    86.749456,
    49.013762
  ],
  [
    86.74955,
    49.013397
  ],
  [
    86.749194,
    49.013038
  ],
  [
    86.748966,
    49.01203
  ],
  [
    86.749114,
    49.011616
  ],
  [
    86.748974,
    49.011225
  ],
  [
    86.749071,
    49.010951
  ],
  [
    86.749673,
    49.010108
  ],
  [
    86.749866,
    49.00956
  ],
  [
    86.749756,
    49.009323
  ],
  [
    86.749232,
    49.009192
  ],
  [
    86.748869,
    49.009213
  ],
  [
    86.748099,
    49.009597
  ],
  [
    86.747626,
    49.009432
  ],
  [
    86.747197,
    49.008942
  ],
  [
    86.747181,
    49.008345
  ],
  [
    86.746303,
    49.007588
  ],
  [
    86.745776,
    49.007338
  ],
  [
    86.744913,
    49.007143
  ],
  [
    86.743914,
    49.006762
  ],
  [
    86.742947,
    49.006568
  ],
  [
    86.741976,
    49.006238
  ],
  [
    86.741288,
    49.005785
  ],
  [
    86.739973,
    49.004399
  ],
  [
    86.739476,
    49.003296
  ],
  [
    86.738292,
    49.001791
  ],
  [
    86.737518,
    49.001015
  ],
  [
    86.737352,
    49.000641
  ],
  [
    86.7375,
    49.000349
  ],
  [
    86.737467,
    49.00006
  ],
  [
    86.736329,
    48.999322
  ],
  [
    86.735655,
    48.999398
  ],
  [
    86.734947,
    48.99915
  ],
  [
    86.734915,
    48.998945
  ],
  [
    86.735113,
    48.998568
  ],
  [
    86.735055,
    48.998313
  ],
  [
    86.734864,
    48.997991
  ],
  [
    86.735218,
    48.997611
  ],
  [
    86.735142,
    48.997436
  ],
  [
    86.734593,
    48.997005
  ],
  [
    86.733095,
    48.996501
  ],
  [
    86.732211,
    48.995957
  ],
  [
    86.731571,
    48.994973
  ],
  [
    86.731207,
    48.994759
  ],
  [
    86.730927,
    48.994812
  ],
  [
    86.73051,
    48.994531
  ],
  [
    86.730024,
    48.994537
  ],
  [
    86.729303,
    48.994394
  ],
  [
    86.728784,
    48.994148
  ],
  [
    86.728907,
    48.993945
  ],
  [
    86.728586,
    48.993394
  ],
  [
    86.728601,
    48.993007
  ],
  [
    86.728516,
    48.992672
  ],
  [
    86.728605,
    48.992184
  ],
  [
    86.729094,
    48.991272
  ],
  [
    86.729768,
    48.990845
  ],
  [
    86.72968,
    48.990575
  ],
  [
    86.730013,
    48.989901
  ],
  [
    86.729908,
    48.989546
  ],
  [
    86.72965,
    48.989423
  ],
  [
    86.729335,
    48.989594
  ],
  [
    86.728791,
    48.989538
  ],
  [
    86.72872,
    48.989266
  ],
  [
    86.728225,
    48.988643
  ],
  [
    86.727874,
    48.988647
  ],
  [
    86.727695,
    48.989131
  ],
  [
    86.726959,
    48.989076
  ],
  [
    86.726698,
    48.98887
  ],
  [
    86.726388,
    48.987993
  ],
  [
    86.726695,
    48.987529
  ],
  [
    86.727447,
    48.986976
  ],
  [
    86.728333,
    48.986673
  ],
  [
    86.728641,
    48.986229
  ],
  [
    86.7298,
    48.985378
  ],
  [
    86.73004,
    48.98481
  ],
  [
    86.730346,
    48.984283
  ],
  [
    86.730333,
    48.98378
  ],
  [
    86.73013,
    48.983364
  ],
  [
    86.729613,
    48.983097
  ],
  [
    86.727623,
    48.982679
  ],
  [
    86.727042,
    48.982434
  ],
  [
    86.726879,
    48.98222
  ],
  [
    86.726917,
    48.981664
  ],
  [
    86.727658,
    48.980612
  ],
  [
    86.727596,
    48.980265
  ],
  [
    86.727403,
    48.979989
  ],
  [
    86.726679,
    48.979649
  ],
  [
    86.726434,
    48.979356
  ],
  [
    86.726428,
    48.979148
  ],
  [
    86.726607,
    48.97892
  ],
  [
    86.727384,
    48.978233
  ],
  [
    86.729259,
    48.976943
  ],
  [
    86.72949,
    48.976697
  ],
  [
    86.729591,
    48.976504
  ],
  [
    86.72956,
    48.976331
  ],
  [
    86.730069,
    48.975508
  ],
  [
    86.730319,
    48.974949
  ],
  [
    86.73046,
    48.974252
  ],
  [
    86.730393,
    48.973696
  ],
  [
    86.730544,
    48.973416
  ],
  [
    86.731114,
    48.972923
  ],
  [
    86.731242,
    48.972748
  ],
  [
    86.731231,
    48.972348
  ],
  [
    86.731521,
    48.97156
  ],
  [
    86.731458,
    48.971178
  ],
  [
    86.731263,
    48.970833
  ],
  [
    86.73078,
    48.97056
  ],
  [
    86.730372,
    48.970147
  ],
  [
    86.73015,
    48.969767
  ],
  [
    86.729501,
    48.969253
  ],
  [
    86.72901,
    48.968702
  ],
  [
    86.728836,
    48.96813
  ],
  [
    86.728787,
    48.967261
  ],
  [
    86.728543,
    48.967056
  ],
  [
    86.728455,
    48.966726
  ],
  [
    86.728737,
    48.96634
  ],
  [
    86.72918,
    48.966092
  ],
  [
    86.729888,
    48.965806
  ],
  [
    86.730165,
    48.965247
  ],
  [
    86.729843,
    48.965094
  ],
  [
    86.729337,
    48.964978
  ],
  [
    86.728799,
    48.964653
  ],
  [
    86.728596,
    48.964012
  ],
  [
    86.728527,
    48.963387
  ],
  [
    86.728355,
    48.962867
  ],
  [
    86.727779,
    48.961946
  ],
  [
    86.727282,
    48.961473
  ],
  [
    86.727202,
    48.961065
  ],
  [
    86.727421,
    48.960152
  ],
  [
    86.727689,
    48.959785
  ],
  [
    86.72778,
    48.959283
  ],
  [
    86.72763,
    48.958875
  ],
  [
    86.72675,
    48.958339
  ],
  [
    86.726356,
    48.957843
  ],
  [
    86.726239,
    48.957343
  ],
  [
    86.726474,
    48.957045
  ],
  [
    86.72692,
    48.956858
  ],
  [
    86.727574,
    48.956714
  ],
  [
    86.72795,
    48.956505
  ],
  [
    86.72791,
    48.956278
  ],
  [
    86.727518,
    48.955896
  ],
  [
    86.726637,
    48.955336
  ],
  [
    86.726419,
    48.954929
  ],
  [
    86.726299,
    48.954316
  ],
  [
    86.72645,
    48.953889
  ],
  [
    86.726746,
    48.953572
  ],
  [
    86.727816,
    48.95299
  ],
  [
    86.727894,
    48.952676
  ],
  [
    86.727621,
    48.952166
  ],
  [
    86.727043,
    48.951659
  ],
  [
    86.726706,
    48.951153
  ],
  [
    86.726771,
    48.950935
  ],
  [
    86.727072,
    48.950636
  ],
  [
    86.727424,
    48.950539
  ],
  [
    86.728535,
    48.950464
  ],
  [
    86.729559,
    48.950733
  ],
  [
    86.72982,
    48.95073
  ],
  [
    86.730123,
    48.950571
  ],
  [
    86.730281,
    48.950258
  ],
  [
    86.730788,
    48.94974
  ],
  [
    86.731211,
    48.949626
  ],
  [
    86.731514,
    48.949452
  ],
  [
    86.731579,
    48.949202
  ],
  [
    86.731523,
    48.948876
  ],
  [
    86.731352,
    48.948661
  ],
  [
    86.730899,
    48.948541
  ],
  [
    86.73054,
    48.94839
  ],
  [
    86.730118,
    48.947633
  ],
  [
    86.729988,
    48.947184
  ],
  [
    86.730025,
    48.94681
  ],
  [
    86.7303,
    48.946434
  ],
  [
    86.730623,
    48.946135
  ],
  [
    86.731625,
    48.945518
  ],
  [
    86.731738,
    48.945314
  ],
  [
    86.731609,
    48.944896
  ],
  [
    86.73098,
    48.944374
  ],
  [
    86.730662,
    48.943974
  ],
  [
    86.730435,
    48.943432
  ],
  [
    86.730376,
    48.943013
  ],
  [
    86.730528,
    48.942636
  ],
  [
    86.731077,
    48.94193
  ],
  [
    86.731188,
    48.941634
  ],
  [
    86.731036,
    48.941247
  ],
  [
    86.730353,
    48.940477
  ],
  [
    86.730179,
    48.940168
  ],
  [
    86.73022,
    48.939919
  ],
  [
    86.730358,
    48.939762
  ],
  [
    86.730708,
    48.939556
  ],
  [
    86.730821,
    48.939353
  ],
  [
    86.731397,
    48.938771
  ],
  [
    86.731482,
    48.938382
  ],
  [
    86.7313,
    48.937746
  ],
  [
    86.731195,
    48.937343
  ],
  [
    86.731416,
    48.936766
  ],
  [
    86.732163,
    48.936384
  ],
  [
    86.732512,
    48.936147
  ],
  [
    86.732787,
    48.935771
  ],
  [
    86.733096,
    48.934959
  ],
  [
    86.733417,
    48.93452
  ],
  [
    86.733753,
    48.933575
  ],
  [
    86.73379,
    48.93317
  ],
  [
    86.733606,
    48.932457
  ],
  [
    86.733667,
    48.931198
  ],
  [
    86.733793,
    48.93059
  ],
  [
    86.734018,
    48.930121
  ],
  [
    86.734871,
    48.929257
  ],
  [
    86.735462,
    48.92785
  ],
  [
    86.735591,
    48.927366
  ],
  [
    86.735197,
    48.92678
  ],
  [
    86.735164,
    48.926423
  ],
  [
    86.734956,
    48.925695
  ],
  [
    86.735172,
    48.924915
  ],
  [
    86.73518,
    48.924309
  ],
  [
    86.735406,
    48.923887
  ],
  [
    86.735443,
    48.923498
  ],
  [
    86.735714,
    48.923013
  ],
  [
    86.736083,
    48.922635
  ],
  [
    86.737271,
    48.921938
  ],
  [
    86.7378,
    48.921357
  ],
  [
    86.737926,
    48.920734
  ],
  [
    86.738217,
    48.92014
  ],
  [
    86.738611,
    48.919809
  ],
  [
    86.739615,
    48.919316
  ],
  [
    86.740089,
    48.919357
  ],
  [
    86.740603,
    48.919102
  ],
  [
    86.740908,
    48.918549
  ],
  [
    86.740825,
    48.918083
  ],
  [
    86.740508,
    48.917729
  ],
  [
    86.740268,
    48.917592
  ],
  [
    86.740074,
    48.917392
  ],
  [
    86.740038,
    48.916957
  ],
  [
    86.740097,
    48.916475
  ],
  [
    86.740419,
    48.916145
  ],
  [
    86.740509,
    48.915957
  ],
  [
    86.7405259,
    48.9152826
  ],
  [
    86.740151,
    48.91492
  ],
  [
    86.740145,
    48.914687
  ],
  [
    86.74028,
    48.914436
  ],
  [
    86.740745,
    48.914136
  ],
  [
    86.741174,
    48.913338
  ],
  [
    86.741458,
    48.912464
  ],
  [
    86.741709,
    48.912088
  ],
  [
    86.742009,
    48.911836
  ],
  [
    86.742455,
    48.911707
  ],
  [
    86.743185,
    48.91159
  ],
  [
    86.744213,
    48.911096
  ],
  [
    86.744653,
    48.910734
  ],
  [
    86.744859,
    48.910365
  ],
  [
    86.745714,
    48.909408
  ],
  [
    86.746019,
    48.908826
  ],
  [
    86.746373,
    48.907809
  ],
  [
    86.74724,
    48.907299
  ],
  [
    86.747432,
    48.907021
  ],
  [
    86.747444,
    48.906705
  ],
  [
    86.747556,
    48.906401
  ],
  [
    86.747769,
    48.906123
  ],
  [
    86.748081,
    48.905817
  ],
  [
    86.748789,
    48.905348
  ],
  [
    86.749061,
    48.90503
  ],
  [
    86.750223,
    48.904332
  ],
  [
    86.750601,
    48.904236
  ],
  [
    86.750912,
    48.903917
  ],
  [
    86.751337,
    48.903333
  ],
  [
    86.751699,
    48.902645
  ],
  [
    86.751706,
    48.901496
  ],
  [
    86.751801,
    48.901311
  ],
  [
    86.752135,
    48.90107
  ],
  [
    86.752614,
    48.900289
  ],
  [
    86.753004,
    48.89914
  ],
  [
    86.752895,
    48.898813
  ],
  [
    86.752191,
    48.8979
  ],
  [
    86.751086,
    48.896952
  ],
  [
    86.751,
    48.89673
  ],
  [
    86.751112,
    48.896413
  ],
  [
    86.751382,
    48.896068
  ],
  [
    86.751677,
    48.895867
  ],
  [
    86.752054,
    48.895758
  ],
  [
    86.752591,
    48.895491
  ],
  [
    86.755236,
    48.894908
  ],
  [
    86.755474,
    48.8948
  ],
  [
    86.756471,
    48.893921
  ],
  [
    86.756905,
    48.893718
  ],
  [
    86.757462,
    48.89358
  ],
  [
    86.757941,
    48.893535
  ],
  [
    86.758445,
    48.893688
  ],
  [
    86.759139,
    48.893443
  ],
  [
    86.7628,
    48.89273
  ],
  [
    86.763415,
    48.892499
  ],
  [
    86.76373,
    48.892312
  ],
  [
    86.764178,
    48.891859
  ],
  [
    86.764474,
    48.891711
  ],
  [
    86.764752,
    48.891655
  ],
  [
    86.765453,
    48.891702
  ],
  [
    86.766724,
    48.892082
  ],
  [
    86.767085,
    48.892118
  ],
  [
    86.768403,
    48.892036
  ],
  [
    86.7687,
    48.891915
  ],
  [
    86.768855,
    48.891729
  ],
  [
    86.768882,
    48.891242
  ],
  [
    86.768973,
    48.890912
  ],
  [
    86.76954,
    48.890406
  ],
  [
    86.769973,
    48.890164
  ],
  [
    86.770373,
    48.890146
  ],
  [
    86.771421,
    48.889713
  ],
  [
    86.77196,
    48.889667
  ],
  [
    86.773342,
    48.889717
  ],
  [
    86.773805,
    48.889843
  ],
  [
    86.774205,
    48.889812
  ],
  [
    86.77476,
    48.889648
  ],
  [
    86.775156,
    48.889472
  ],
  [
    86.775251,
    48.889313
  ],
  [
    86.775303,
    48.888984
  ],
  [
    86.775235,
    48.888722
  ],
  [
    86.775486,
    48.888364
  ],
  [
    86.776191,
    48.887627
  ],
  [
    86.777114,
    48.886972
  ],
  [
    86.778183,
    48.886578
  ],
  [
    86.778477,
    48.886351
  ],
  [
    86.779038,
    48.885634
  ],
  [
    86.779641,
    48.885009
  ],
  [
    86.780314,
    48.883239
  ],
  [
    86.780584,
    48.882867
  ],
  [
    86.780858,
    48.882654
  ],
  [
    86.781285,
    48.882175
  ],
  [
    86.781611,
    48.881645
  ],
  [
    86.781798,
    48.881183
  ],
  [
    86.781821,
    48.880285
  ],
  [
    86.782087,
    48.879589
  ],
  [
    86.782171,
    48.878732
  ],
  [
    86.782326,
    48.878531
  ],
  [
    86.782959,
    48.878207
  ],
  [
    86.78308,
    48.878041
  ],
  [
    86.783164,
    48.877208
  ],
  [
    86.783534,
    48.876382
  ],
  [
    86.783576,
    48.875267
  ],
  [
    86.783712,
    48.875031
  ],
  [
    86.783921,
    48.874853
  ],
  [
    86.785098,
    48.874203
  ],
  [
    86.785469,
    48.87407
  ],
  [
    86.786216,
    48.874003
  ],
  [
    86.786446,
    48.873941
  ],
  [
    86.786656,
    48.873786
  ],
  [
    86.78672,
    48.873492
  ],
  [
    86.78684,
    48.873327
  ],
  [
    86.787172,
    48.8731
  ],
  [
    86.787631,
    48.872907
  ],
  [
    86.788216,
    48.872771
  ],
  [
    86.789239,
    48.872372
  ],
  [
    86.789697,
    48.872156
  ],
  [
    86.789905,
    48.87193
  ],
  [
    86.790694,
    48.87144
  ],
  [
    86.791901,
    48.87125
  ],
  [
    86.793686,
    48.871106
  ],
  [
    86.794946,
    48.870869
  ],
  [
    86.796238,
    48.870513
  ],
  [
    86.796891,
    48.870283
  ],
  [
    86.797982,
    48.869742
  ],
  [
    86.798717,
    48.869229
  ],
  [
    86.799135,
    48.868872
  ],
  [
    86.799532,
    48.868398
  ],
  [
    86.799913,
    48.867641
  ],
  [
    86.800434,
    48.867131
  ],
  [
    86.80195,
    48.866526
  ],
  [
    86.802659,
    48.86633
  ],
  [
    86.803116,
    48.866114
  ],
  [
    86.803377,
    48.865888
  ],
  [
    86.803529,
    48.865581
  ],
  [
    86.803608,
    48.865193
  ],
  [
    86.803565,
    48.864924
  ],
  [
    86.803719,
    48.864711
  ],
  [
    86.804508,
    48.864197
  ],
  [
    86.804829,
    48.864193
  ],
  [
    86.805786,
    48.863982
  ],
  [
    86.806474,
    48.86374
  ],
  [
    86.806755,
    48.86356
  ],
  [
    86.806926,
    48.8633
  ],
  [
    86.806968,
    48.862889
  ],
  [
    86.806842,
    48.862062
  ],
  [
    86.80693,
    48.861916
  ],
  [
    86.808171,
    48.861217
  ],
  [
    86.809433,
    48.860313
  ],
  [
    86.809954,
    48.85987
  ],
  [
    86.810249,
    48.859739
  ],
  [
    86.810911,
    48.859705
  ],
  [
    86.811221,
    48.859642
  ],
  [
    86.811746,
    48.859362
  ],
  [
    86.813031,
    48.858842
  ],
  [
    86.813377,
    48.85865
  ],
  [
    86.814195,
    48.857691
  ],
  [
    86.814879,
    48.857281
  ],
  [
    86.815342,
    48.857087
  ],
  [
    86.815635,
    48.856887
  ],
  [
    86.815918,
    48.856789
  ],
  [
    86.816276,
    48.856554
  ],
  [
    86.816503,
    48.856312
  ],
  [
    86.816588,
    48.856106
  ],
  [
    86.816581,
    48.855849
  ],
  [
    86.816497,
    48.855628
  ],
  [
    86.816296,
    48.855391
  ],
  [
    86.816031,
    48.855232
  ],
  [
    86.815585,
    48.855083
  ],
  [
    86.815492,
    48.854999
  ],
  [
    86.815489,
    48.854896
  ],
  [
    86.81561,
    48.854578
  ],
  [
    86.816979,
    48.852501
  ],
  [
    86.817285,
    48.851832
  ],
  [
    86.817406,
    48.851386
  ],
  [
    86.817258,
    48.850429
  ],
  [
    86.817324,
    48.850183
  ],
  [
    86.817473,
    48.849919
  ],
  [
    86.818195,
    48.849007
  ],
  [
    86.818793,
    48.847533
  ],
  [
    86.818846,
    48.846811
  ],
  [
    86.818802,
    48.84655
  ],
  [
    86.81869,
    48.846416
  ],
  [
    86.817799,
    48.845991
  ],
  [
    86.81766,
    48.845739
  ],
  [
    86.817718,
    48.845255
  ],
  [
    86.818111,
    48.844632
  ],
  [
    86.818518,
    48.843789
  ],
  [
    86.818445,
    48.84333
  ],
  [
    86.818638,
    48.84202
  ],
  [
    86.818704,
    48.841813
  ],
  [
    86.819095,
    48.841436
  ],
  [
    86.819145,
    48.84107
  ],
  [
    86.818992,
    48.840787
  ],
  [
    86.818757,
    48.840552
  ],
  [
    86.818144,
    48.840163
  ],
  [
    86.817806,
    48.839676
  ],
  [
    86.81718,
    48.839255
  ],
  [
    86.816975,
    48.838837
  ],
  [
    86.817275,
    48.837486
  ],
  [
    86.817255,
    48.837177
  ],
  [
    86.817334,
    48.836692
  ],
  [
    86.817284,
    48.836178
  ],
  [
    86.817408,
    48.835907
  ],
  [
    86.817549,
    48.835754
  ],
  [
    86.818774,
    48.835185
  ],
  [
    86.818965,
    48.834715
  ],
  [
    86.819222,
    48.833126
  ],
  [
    86.819158,
    48.832992
  ],
  [
    86.818746,
    48.832761
  ],
  [
    86.818432,
    48.832297
  ],
  [
    86.817893,
    48.831987
  ],
  [
    86.81755,
    48.831333
  ],
  [
    86.817172,
    48.830727
  ],
  [
    86.816999,
    48.830547
  ],
  [
    86.815511,
    48.829877
  ],
  [
    86.815197,
    48.829841
  ],
  [
    86.814009,
    48.829562
  ],
  [
    86.813603,
    48.829265
  ],
  [
    86.812482,
    48.828764
  ],
  [
    86.811754,
    48.828582
  ],
  [
    86.810745,
    48.828206
  ],
  [
    86.81026,
    48.8281
  ],
  [
    86.809846,
    48.827923
  ],
  [
    86.809527,
    48.827737
  ],
  [
    86.808959,
    48.827259
  ],
  [
    86.808762,
    48.826691
  ],
  [
    86.808638,
    48.82655
  ],
  [
    86.808432,
    48.826505
  ],
  [
    86.808192,
    48.826523
  ],
  [
    86.80769,
    48.826656
  ],
  [
    86.806522,
    48.82667
  ],
  [
    86.806194,
    48.826571
  ],
  [
    86.80551,
    48.82623
  ],
  [
    86.805337,
    48.826066
  ],
  [
    86.805131,
    48.825989
  ],
  [
    86.804756,
    48.825938
  ],
  [
    86.804622,
    48.825868
  ],
  [
    86.804312,
    48.825539
  ],
  [
    86.804306,
    48.825341
  ],
  [
    86.804431,
    48.825054
  ],
  [
    86.804431,
    48.824741
  ],
  [
    86.804184,
    48.824104
  ],
  [
    86.803965,
    48.823862
  ],
  [
    86.802962,
    48.823442
  ],
  [
    86.801321,
    48.822922
  ],
  [
    86.800184,
    48.82276
  ],
  [
    86.799827,
    48.82266
  ],
  [
    86.798984,
    48.822227
  ],
  [
    86.79878,
    48.821868
  ],
  [
    86.798436,
    48.821593
  ],
  [
    86.797771,
    48.821216
  ],
  [
    86.797128,
    48.820979
  ],
  [
    86.795559,
    48.820544
  ],
  [
    86.7952,
    48.820373
  ],
  [
    86.794893,
    48.820121
  ],
  [
    86.794527,
    48.819694
  ],
  [
    86.794171,
    48.818953
  ],
  [
    86.793988,
    48.818745
  ],
  [
    86.793309,
    48.8182
  ],
  [
    86.791425,
    48.817232
  ],
  [
    86.789761,
    48.816518
  ],
  [
    86.789314,
    48.816372
  ],
  [
    86.788884,
    48.816132
  ],
  [
    86.78807,
    48.815501
  ],
  [
    86.787263,
    48.815091
  ],
  [
    86.786172,
    48.814649
  ],
  [
    86.784828,
    48.814013
  ],
  [
    86.783676,
    48.813587
  ],
  [
    86.780773,
    48.812188
  ],
  [
    86.779149,
    48.811671
  ],
  [
    86.776638,
    48.811036
  ],
  [
    86.776011,
    48.810729
  ],
  [
    86.77522,
    48.810238
  ],
  [
    86.774026,
    48.809914
  ],
  [
    86.772728,
    48.809643
  ],
  [
    86.770935,
    48.809407
  ],
  [
    86.76919,
    48.808973
  ],
  [
    86.768482,
    48.808946
  ],
  [
    86.768108,
    48.808846
  ],
  [
    86.767606,
    48.808607
  ],
  [
    86.767244,
    48.808285
  ],
  [
    86.76671,
    48.807511
  ],
  [
    86.766476,
    48.807374
  ],
  [
    86.766086,
    48.80732
  ],
  [
    86.765854,
    48.807241
  ],
  [
    86.765632,
    48.806883
  ],
  [
    86.765381,
    48.806734
  ],
  [
    86.764988,
    48.806611
  ],
  [
    86.763835,
    48.806027
  ],
  [
    86.762519,
    48.805099
  ],
  [
    86.761641,
    48.80469
  ],
  [
    86.761101,
    48.8043
  ],
  [
    86.760847,
    48.804081
  ],
  [
    86.760772,
    48.803919
  ],
  [
    86.760796,
    48.803488
  ],
  [
    86.761191,
    48.803064
  ],
  [
    86.761201,
    48.802761
  ],
  [
    86.76107,
    48.802471
  ],
  [
    86.760888,
    48.802287
  ],
  [
    86.760583,
    48.802127
  ],
  [
    86.760381,
    48.801839
  ],
  [
    86.760285,
    48.801572
  ],
  [
    86.760589,
    48.801032
  ],
  [
    86.760627,
    48.800795
  ],
  [
    86.760439,
    48.800377
  ],
  [
    86.760375,
    48.799959
  ],
  [
    86.760173,
    48.799658
  ],
  [
    86.759799,
    48.799593
  ],
  [
    86.759241,
    48.799215
  ],
  [
    86.759131,
    48.799053
  ],
  [
    86.759168,
    48.79847
  ],
  [
    86.758992,
    48.797797
  ],
  [
    86.758962,
    48.797354
  ],
  [
    86.758812,
    48.797041
  ],
  [
    86.75934,
    48.796267
  ],
  [
    86.759386,
    48.79601
  ],
  [
    86.759378,
    48.795684
  ],
  [
    86.759263,
    48.795399
  ],
  [
    86.759199,
    48.79498
  ],
  [
    86.759242,
    48.794595
  ],
  [
    86.759198,
    48.794281
  ],
  [
    86.758992,
    48.79383
  ],
  [
    86.758682,
    48.793484
  ],
  [
    86.757585,
    48.792797
  ],
  [
    86.757348,
    48.792532
  ],
  [
    86.757236,
    48.792301
  ],
  [
    86.757245,
    48.791963
  ],
  [
    86.757618,
    48.791341
  ],
  [
    86.75771,
    48.790851
  ],
  [
    86.75736,
    48.789216
  ],
  [
    86.757066,
    48.788812
  ],
  [
    86.756776,
    48.788524
  ],
  [
    86.756453,
    48.788365
  ],
  [
    86.754903,
    48.787893
  ],
  [
    86.754512,
    48.787828
  ],
  [
    86.75384,
    48.787835
  ],
  [
    86.753307,
    48.787702
  ],
  [
    86.753382,
    48.787235
  ],
  [
    86.753969,
    48.786017
  ],
  [
    86.754008,
    48.785469
  ],
  [
    86.753751,
    48.785122
  ],
  [
    86.753262,
    48.784662
  ],
  [
    86.752327,
    48.783947
  ],
  [
    86.751454,
    48.78299
  ],
  [
    86.751076,
    48.782086
  ],
  [
    86.751115,
    48.781549
  ],
  [
    86.751318,
    48.781209
  ],
  [
    86.751885,
    48.780585
  ],
  [
    86.75195,
    48.780363
  ],
  [
    86.75194,
    48.779956
  ],
  [
    86.751862,
    48.7797
  ],
  [
    86.751348,
    48.778972
  ],
  [
    86.751209,
    48.778088
  ],
  [
    86.751221,
    48.777878
  ],
  [
    86.751323,
    48.777725
  ],
  [
    86.752574,
    48.776884
  ],
  [
    86.752798,
    48.776684
  ],
  [
    86.753183,
    48.775864
  ],
  [
    86.754135,
    48.77442
  ],
  [
    86.756691,
    48.772412
  ],
  [
    86.757409,
    48.771666
  ],
  [
    86.7577,
    48.771134
  ],
  [
    86.758629,
    48.76807
  ],
  [
    86.760522,
    48.766069
  ],
  [
    86.760829,
    48.765638
  ],
  [
    86.761084,
    48.765166
  ],
  [
    86.761165,
    48.764896
  ],
  [
    86.761234,
    48.764124
  ],
  [
    86.761321,
    48.763803
  ],
  [
    86.761467,
    48.76355
  ],
  [
    86.764317,
    48.761169
  ],
  [
    86.766215,
    48.759864
  ],
  [
    86.767602,
    48.758341
  ],
  [
    86.767891,
    48.757775
  ],
  [
    86.768229,
    48.756339
  ],
  [
    86.768546,
    48.755686
  ],
  [
    86.768636,
    48.754527
  ],
  [
    86.769014,
    48.753867
  ],
  [
    86.769465,
    48.753253
  ],
  [
    86.769964,
    48.7521
  ],
  [
    86.770921,
    48.751815
  ],
  [
    86.771512,
    48.75154
  ],
  [
    86.772455,
    48.7512
  ],
  [
    86.773463,
    48.75012
  ],
  [
    86.773493,
    48.749935
  ],
  [
    86.773428,
    48.749683
  ],
  [
    86.773306,
    48.7495
  ],
  [
    86.773092,
    48.749325
  ],
  [
    86.772716,
    48.749215
  ],
  [
    86.772146,
    48.748384
  ],
  [
    86.772022,
    48.748086
  ],
  [
    86.771996,
    48.747548
  ],
  [
    86.772191,
    48.747247
  ],
  [
    86.772602,
    48.746927
  ],
  [
    86.772867,
    48.746378
  ],
  [
    86.773305,
    48.745774
  ],
  [
    86.773556,
    48.745556
  ],
  [
    86.774257,
    48.745202
  ],
  [
    86.774768,
    48.743747
  ],
  [
    86.775031,
    48.742715
  ],
  [
    86.775438,
    48.739991
  ],
  [
    86.776313,
    48.738298
  ],
  [
    86.776623,
    48.737372
  ],
  [
    86.777728,
    48.736038
  ],
  [
    86.777837,
    48.735753
  ],
  [
    86.777842,
    48.735492
  ],
  [
    86.77764,
    48.734941
  ],
  [
    86.776564,
    48.73386
  ],
  [
    86.776406,
    48.733632
  ],
  [
    86.776305,
    48.733318
  ],
  [
    86.776325,
    48.732757
  ],
  [
    86.777047,
    48.731911
  ],
  [
    86.777136,
    48.731733
  ],
  [
    86.777174,
    48.731433
  ],
  [
    86.777115,
    48.730958
  ],
  [
    86.776822,
    48.730441
  ],
  [
    86.776818,
    48.729865
  ],
  [
    86.77674,
    48.729559
  ],
  [
    86.776483,
    48.72907
  ],
  [
    86.776368,
    48.728703
  ],
  [
    86.776139,
    48.728421
  ],
  [
    86.775926,
    48.728301
  ],
  [
    86.775738,
    48.728234
  ],
  [
    86.775528,
    48.728221
  ],
  [
    86.774176,
    48.727745
  ],
  [
    86.77395,
    48.72754
  ],
  [
    86.773839,
    48.727342
  ],
  [
    86.773819,
    48.727004
  ],
  [
    86.773668,
    48.726591
  ],
  [
    86.773322,
    48.726045
  ],
  [
    86.772441,
    48.72488
  ],
  [
    86.772327,
    48.724543
  ],
  [
    86.772353,
    48.724228
  ],
  [
    86.772483,
    48.723827
  ],
  [
    86.773125,
    48.723036
  ],
  [
    86.773032,
    48.722176
  ],
  [
    86.773197,
    48.721172
  ],
  [
    86.773135,
    48.720619
  ],
  [
    86.773001,
    48.720398
  ],
  [
    86.772799,
    48.720224
  ],
  [
    86.772149,
    48.719886
  ],
  [
    86.771927,
    48.719842
  ],
  [
    86.771683,
    48.71986
  ],
  [
    86.771143,
    48.720128
  ],
  [
    86.770516,
    48.720212
  ],
  [
    86.769977,
    48.72008
  ],
  [
    86.769729,
    48.719929
  ],
  [
    86.769467,
    48.719709
  ],
  [
    86.769094,
    48.718408
  ],
  [
    86.768732,
    48.717604
  ],
  [
    86.768574,
    48.71736
  ],
  [
    86.768324,
    48.717133
  ],
  [
    86.767981,
    48.716968
  ],
  [
    86.766818,
    48.716566
  ],
  [
    86.766334,
    48.716326
  ],
  [
    86.765884,
    48.716024
  ],
  [
    86.765272,
    48.715778
  ],
  [
    86.762925,
    48.714222
  ],
  [
    86.762708,
    48.714003
  ],
  [
    86.762183,
    48.713064
  ],
  [
    86.761715,
    48.71254
  ],
  [
    86.761179,
    48.7121
  ],
  [
    86.759991,
    48.711622
  ],
  [
    86.756375,
    48.709459
  ],
  [
    86.756139,
    48.709362
  ],
  [
    86.754102,
    48.707611
  ],
  [
    86.7512922,
    48.7065729
  ],
  [
    86.74891,
    48.704825
  ],
  [
    86.748731,
    48.704643
  ],
  [
    86.748596,
    48.704391
  ],
  [
    86.748518,
    48.704077
  ],
  [
    86.7477908,
    48.7027335
  ],
  [
    86.747622,
    48.702495
  ],
  [
    86.7459358,
    48.7019796
  ],
  [
    86.7454362,
    48.7014644
  ],
  [
    86.744854,
    48.70036
  ],
  [
    86.744327,
    48.699677
  ],
  [
    86.743956,
    48.699274
  ],
  [
    86.743591,
    48.698687
  ],
  [
    86.743233,
    48.698368
  ],
  [
    86.742645,
    48.698129
  ],
  [
    86.742376,
    48.698071
  ],
  [
    86.740728,
    48.698193
  ],
  [
    86.739902,
    48.698171
  ],
  [
    86.739815,
    48.697973
  ],
  [
    86.739855,
    48.697726
  ],
  [
    86.739731,
    48.697421
  ],
  [
    86.739416,
    48.696956
  ],
  [
    86.739116,
    48.696621
  ],
  [
    86.738139,
    48.695741
  ],
  [
    86.737533,
    48.694844
  ],
  [
    86.737294,
    48.694616
  ],
  [
    86.736975,
    48.694413
  ],
  [
    86.735999,
    48.694047
  ],
  [
    86.735763,
    48.693912
  ],
  [
    86.735607,
    48.693752
  ],
  [
    86.735374,
    48.693278
  ],
  [
    86.735368,
    48.693056
  ],
  [
    86.735257,
    48.692803
  ],
  [
    86.735329,
    48.692283
  ],
  [
    86.7352,
    48.69137
  ],
  [
    86.73469,
    48.690531
  ],
  [
    86.733732,
    48.689958
  ],
  [
    86.732956,
    48.689628
  ],
  [
    86.732558,
    48.689355
  ],
  [
    86.731698,
    48.688482
  ],
  [
    86.731085,
    48.687743
  ],
  [
    86.73073,
    48.687509
  ],
  [
    86.730046,
    48.687164
  ],
  [
    86.729672,
    48.687106
  ],
  [
    86.729437,
    48.687009
  ],
  [
    86.729117,
    48.686782
  ],
  [
    86.728648,
    48.686642
  ],
  [
    86.727798,
    48.686175
  ],
  [
    86.727488,
    48.685894
  ],
  [
    86.727134,
    48.685706
  ],
  [
    86.7267,
    48.68555
  ],
  [
    86.726336,
    48.685292
  ],
  [
    86.726133,
    48.685095
  ],
  [
    86.72598,
    48.684559
  ],
  [
    86.72603,
    48.683805
  ],
  [
    86.726171,
    48.683412
  ],
  [
    86.726156,
    48.683266
  ],
  [
    86.726037,
    48.68316
  ],
  [
    86.725754,
    48.683017
  ],
  [
    86.724591,
    48.682608
  ],
  [
    86.723952,
    48.682169
  ],
  [
    86.723,
    48.681788
  ],
  [
    86.722173,
    48.681321
  ],
  [
    86.721353,
    48.680731
  ],
  [
    86.720905,
    48.68049
  ],
  [
    86.718516,
    48.679449
  ],
  [
    86.71786,
    48.67928
  ],
  [
    86.717018,
    48.678198
  ],
  [
    86.716283,
    48.677676
  ],
  [
    86.715558,
    48.677322
  ],
  [
    86.713902,
    48.676734
  ],
  [
    86.713259,
    48.676579
  ],
  [
    86.711922,
    48.676156
  ],
  [
    86.711375,
    48.67617
  ],
  [
    86.71039,
    48.675874
  ],
  [
    86.710271,
    48.675745
  ],
  [
    86.710222,
    48.675223
  ],
  [
    86.710146,
    48.675065
  ],
  [
    86.709851,
    48.67463
  ],
  [
    86.70919,
    48.67406
  ],
  [
    86.707462,
    48.673302
  ],
  [
    86.706798,
    48.673074
  ],
  [
    86.706142,
    48.67293
  ],
  [
    86.705768,
    48.672783
  ],
  [
    86.705409,
    48.67253
  ],
  [
    86.70463,
    48.671712
  ],
  [
    86.704123,
    48.671342
  ],
  [
    86.703827,
    48.671021
  ],
  [
    86.702769,
    48.670215
  ],
  [
    86.702657,
    48.669798
  ],
  [
    86.702322,
    48.669639
  ],
  [
    86.701744,
    48.669579
  ],
  [
    86.701047,
    48.66933
  ],
  [
    86.700475,
    48.668937
  ],
  [
    86.700464,
    48.668259
  ],
  [
    86.700307,
    48.667981
  ],
  [
    86.700008,
    48.666957
  ],
  [
    86.699754,
    48.666644
  ],
  [
    86.699449,
    48.665918
  ],
  [
    86.699423,
    48.6652
  ],
  [
    86.699286,
    48.664666
  ],
  [
    86.69935,
    48.664385
  ],
  [
    86.699601,
    48.663847
  ],
  [
    86.699942,
    48.663401
  ],
  [
    86.699986,
    48.663051
  ],
  [
    86.699857,
    48.662819
  ],
  [
    86.699569,
    48.662613
  ],
  [
    86.698736,
    48.662436
  ],
  [
    86.698503,
    48.662275
  ],
  [
    86.698032,
    48.661791
  ],
  [
    86.697699,
    48.661178
  ],
  [
    86.697747,
    48.660968
  ],
  [
    86.697935,
    48.660709
  ],
  [
    86.698177,
    48.660544
  ],
  [
    86.698787,
    48.660269
  ],
  [
    86.69915,
    48.659962
  ],
  [
    86.699267,
    48.657981
  ],
  [
    86.699221,
    48.657221
  ],
  [
    86.698954,
    48.655781
  ],
  [
    86.698765,
    48.655191
  ],
  [
    86.698372,
    48.65447
  ],
  [
    86.698039,
    48.654005
  ],
  [
    86.697801,
    48.653061
  ],
  [
    86.69759,
    48.652656
  ],
  [
    86.696915,
    48.652734
  ],
  [
    86.695936,
    48.652555
  ],
  [
    86.69473,
    48.652158
  ],
  [
    86.694207,
    48.651608
  ],
  [
    86.6942,
    48.651344
  ],
  [
    86.694873,
    48.650796
  ],
  [
    86.69418,
    48.649662
  ],
  [
    86.693887,
    48.64946
  ],
  [
    86.693353,
    48.649378
  ],
  [
    86.69269,
    48.649415
  ],
  [
    86.692179,
    48.649376
  ],
  [
    86.691754,
    48.649205
  ],
  [
    86.691234,
    48.648772
  ],
  [
    86.690831,
    48.648615
  ],
  [
    86.690058,
    48.648726
  ],
  [
    86.689344,
    48.648514
  ],
  [
    86.689007,
    48.648328
  ],
  [
    86.689047,
    48.648152
  ],
  [
    86.689289,
    48.648091
  ],
  [
    86.689328,
    48.647871
  ],
  [
    86.688947,
    48.647637
  ],
  [
    86.688752,
    48.647347
  ],
  [
    86.688708,
    48.647179
  ],
  [
    86.68877,
    48.647011
  ],
  [
    86.689362,
    48.646553
  ],
  [
    86.68962,
    48.646188
  ],
  [
    86.689885,
    48.645548
  ],
  [
    86.69004,
    48.645325
  ],
  [
    86.690513,
    48.644957
  ],
  [
    86.690877,
    48.644546
  ],
  [
    86.691774,
    48.643195
  ],
  [
    86.691688,
    48.642957
  ],
  [
    86.691509,
    48.642764
  ],
  [
    86.689899,
    48.642065
  ],
  [
    86.688836,
    48.641308
  ],
  [
    86.688603,
    48.641089
  ],
  [
    86.688395,
    48.640826
  ],
  [
    86.688387,
    48.640516
  ],
  [
    86.68853,
    48.640329
  ],
  [
    86.688927,
    48.640157
  ],
  [
    86.688992,
    48.640085
  ],
  [
    86.68895,
    48.640015
  ],
  [
    86.687535,
    48.639624
  ],
  [
    86.686617,
    48.639524
  ],
  [
    86.685534,
    48.639041
  ],
  [
    86.684472,
    48.638831
  ],
  [
    86.683564,
    48.638912
  ],
  [
    86.683245,
    48.639039
  ],
  [
    86.682925,
    48.638882
  ],
  [
    86.682212,
    48.638969
  ],
  [
    86.682003,
    48.638932
  ],
  [
    86.681584,
    48.63847
  ],
  [
    86.680985,
    48.637952
  ],
  [
    86.681243,
    48.637523
  ],
  [
    86.681258,
    48.637332
  ],
  [
    86.681087,
    48.637249
  ],
  [
    86.680732,
    48.637338
  ],
  [
    86.680413,
    48.637295
  ],
  [
    86.678831,
    48.636841
  ],
  [
    86.678571,
    48.636675
  ],
  [
    86.678217,
    48.636307
  ],
  [
    86.678353,
    48.635824
  ],
  [
    86.678596,
    48.635679
  ],
  [
    86.678803,
    48.635286
  ],
  [
    86.6781129,
    48.634547
  ],
  [
    86.6776629,
    48.63418
  ],
  [
    86.6770089,
    48.633719
  ],
  [
    86.6767249,
    48.633598
  ],
  [
    86.6750489,
    48.633483
  ],
  [
    86.6743669,
    48.633508
  ],
  [
    86.6731269,
    48.633707
  ],
  [
    86.6727829,
    48.633853
  ],
  [
    86.6723569,
    48.633919
  ],
  [
    86.6708879,
    48.633971
  ],
  [
    86.6699489,
    48.633858
  ],
  [
    86.6693439,
    48.633367
  ],
  [
    86.6692829,
    48.633042
  ],
  [
    86.6693829,
    48.632776
  ],
  [
    86.6699529,
    48.632082
  ],
  [
    86.6699219,
    48.631906
  ],
  [
    86.6698109,
    48.631731
  ],
  [
    86.6695399,
    48.631566
  ],
  [
    86.6693479,
    48.631374
  ],
  [
    86.6691589,
    48.631279
  ],
  [
    86.6686619,
    48.631144
  ],
  [
    86.667338,
    48.631017
  ],
  [
    86.66674,
    48.63112
  ],
  [
    86.6660189,
    48.63111
  ],
  [
    86.6657369,
    48.631034
  ],
  [
    86.664941,
    48.630663
  ],
  [
    86.6644559,
    48.630492
  ],
  [
    86.6639069,
    48.630436
  ],
  [
    86.6637259,
    48.63051
  ],
  [
    86.6633419,
    48.63047
  ],
  [
    86.6623079,
    48.630463
  ],
  [
    86.6617309,
    48.630263
  ],
  [
    86.6606179,
    48.630103
  ],
  [
    86.6596389,
    48.630022
  ],
  [
    86.6584499,
    48.630232
  ],
  [
    86.6575159,
    48.629699
  ],
  [
    86.6571079,
    48.629584
  ],
  [
    86.6561159,
    48.629479
  ],
  [
    86.6555849,
    48.629462
  ],
  [
    86.6541759,
    48.629522
  ],
  [
    86.6528719,
    48.629715
  ],
  [
    86.6519939,
    48.629381
  ],
  [
    86.6509319,
    48.629243
  ],
  [
    86.6492839,
    48.629149
  ],
  [
    86.6484209,
    48.629329
  ],
  [
    86.6475659,
    48.629375
  ],
  [
    86.6463749,
    48.629624
  ],
  [
    86.6455549,
    48.629692
  ],
  [
    86.6449109,
    48.629647
  ],
  [
    86.6447509,
    48.629544
  ],
  [
    86.6447029,
    48.629426
  ],
  [
    86.6438299,
    48.629196
  ],
  [
    86.6433229,
    48.629209
  ],
  [
    86.6422049,
    48.629094
  ],
  [
    86.6416169,
    48.629004
  ],
  [
    86.6412509,
    48.628902
  ],
  [
    86.6403329,
    48.628823
  ],
  [
    86.6400889,
    48.628528
  ],
  [
    86.6394989,
    48.62834
  ],
  [
    86.6390609,
    48.627935
  ],
  [
    86.6388449,
    48.627524
  ],
  [
    86.6377389,
    48.626985
  ],
  [
    86.6371499,
    48.626857
  ],
  [
    86.6365309,
    48.626871
  ],
  [
    86.6356269,
    48.626783
  ],
  [
    86.6350179,
    48.62646
  ],
  [
    86.6348139,
    48.626061
  ],
  [
    86.6345019,
    48.625734
  ],
  [
    86.6341289,
    48.625537
  ],
  [
    86.6334949,
    48.62444
  ],
  [
    86.6333549,
    48.62404
  ],
  [
    86.6331889,
    48.62306
  ],
  [
    86.6330929,
    48.621785
  ],
  [
    86.6332809,
    48.621496
  ],
  [
    86.6335779,
    48.621207
  ],
  [
    86.6336589,
    48.620976
  ],
  [
    86.6336109,
    48.620762
  ],
  [
    86.6329969,
    48.620467
  ],
  [
    86.6327409,
    48.620222
  ],
  [
    86.6327329,
    48.619876
  ],
  [
    86.6331129,
    48.619098
  ],
  [
    86.6337509,
    48.618663
  ],
  [
    86.6333489,
    48.61753
  ],
  [
    86.6336129,
    48.617066
  ],
  [
    86.6333009,
    48.616559
  ],
  [
    86.6332849,
    48.615867
  ],
  [
    86.6338109,
    48.615511
  ],
  [
    86.6340189,
    48.615165
  ],
  [
    86.6341669,
    48.61461
  ],
  [
    86.6343309,
    48.613304
  ],
  [
    86.6343379,
    48.612701
  ],
  [
    86.6338749,
    48.611459
  ],
  [
    86.6332619,
    48.610304
  ],
  [
    86.6330149,
    48.609976
  ],
  [
    86.6314609,
    48.608444
  ],
  [
    86.6294269,
    48.606914
  ],
  [
    86.6293769,
    48.606642
  ],
  [
    86.6294429,
    48.605781
  ],
  [
    86.6294119,
    48.605366
  ],
  [
    86.6291419,
    48.604953
  ],
  [
    86.6280759,
    48.603903
  ],
  [
    86.6277039,
    48.603749
  ],
  [
    86.6262199,
    48.603391
  ],
  [
    86.6256949,
    48.603167
  ],
  [
    86.6250079,
    48.602572
  ],
  [
    86.623875,
    48.601836
  ],
  [
    86.6232529,
    48.601197
  ],
  [
    86.6231509,
    48.600764
  ],
  [
    86.6227109,
    48.600436
  ],
  [
    86.6223359,
    48.600195
  ],
  [
    86.6218799,
    48.600037
  ],
  [
    86.6214269,
    48.599967
  ],
  [
    86.6212059,
    48.599653
  ],
  [
    86.6205749,
    48.599297
  ],
  [
    86.6205429,
    48.599017
  ],
  [
    86.6194089,
    48.598545
  ],
  [
    86.6190629,
    48.598276
  ],
  [
    86.6182199,
    48.597816
  ],
  [
    86.6181139,
    48.597896
  ],
  [
    86.6178909,
    48.597947
  ],
  [
    86.6170949,
    48.597989
  ],
  [
    86.6166449,
    48.597927
  ],
  [
    86.6161919,
    48.597751
  ],
  [
    86.6147919,
    48.597534
  ],
  [
    86.6142929,
    48.597523
  ],
  [
    86.6138009,
    48.597792
  ],
  [
    86.6135279,
    48.597795
  ],
  [
    86.6133059,
    48.597912
  ],
  [
    86.6130299,
    48.597816
  ],
  [
    86.6119179,
    48.597136
  ],
  [
    86.6116029,
    48.596463
  ],
  [
    86.6114879,
    48.595822
  ],
  [
    86.6112299,
    48.595491
  ],
  [
    86.6109249,
    48.594767
  ],
  [
    86.6105299,
    48.594113
  ],
  [
    86.6104619,
    48.593869
  ],
  [
    86.610579,
    48.592032
  ],
  [
    86.6105139,
    48.591435
  ],
  [
    86.610416,
    48.591212
  ],
  [
    86.6102369,
    48.59101
  ],
  [
    86.60982,
    48.590729
  ],
  [
    86.6096869,
    48.590252
  ],
  [
    86.6097209,
    48.589485
  ],
  [
    86.6101329,
    48.588508
  ],
  [
    86.6101739,
    48.588161
  ],
  [
    86.6100929,
    48.587866
  ],
  [
    86.6091869,
    48.587463
  ],
  [
    86.6090579,
    48.587283
  ],
  [
    86.6090509,
    48.58697
  ],
  [
    86.6089479,
    48.586856
  ],
  [
    86.608598,
    48.586794
  ],
  [
    86.608221,
    48.586666
  ],
  [
    86.608108,
    48.58609
  ],
  [
    86.607903,
    48.585845
  ],
  [
    86.607475,
    48.585635
  ],
  [
    86.60727,
    48.585374
  ],
  [
    86.60694,
    48.585114
  ],
  [
    86.605542,
    48.585012
  ],
  [
    86.604592,
    48.584857
  ],
  [
    86.604195,
    48.584894
  ],
  [
    86.603097,
    48.584711
  ],
  [
    86.60241,
    48.584759
  ],
  [
    86.602039,
    48.584729
  ],
  [
    86.601377,
    48.584518
  ],
  [
    86.601383,
    48.583085
  ],
  [
    86.60128,
    48.582938
  ],
  [
    86.60116,
    48.582049
  ],
  [
    86.600804,
    48.581773
  ],
  [
    86.600326,
    48.581563
  ],
  [
    86.598672,
    48.581102
  ],
  [
    86.598574,
    48.580847
  ],
  [
    86.598601,
    48.579874
  ],
  [
    86.598366,
    48.579382
  ],
  [
    86.598381,
    48.578987
  ],
  [
    86.598642,
    48.578407
  ],
  [
    86.598118,
    48.577292
  ],
  [
    86.598084,
    48.576914
  ],
  [
    86.597718,
    48.57616
  ],
  [
    86.596919,
    48.576036
  ],
  [
    86.596565,
    48.575842
  ],
  [
    86.596132,
    48.575401
  ],
  [
    86.59578,
    48.57524
  ],
  [
    86.594734,
    48.575267
  ],
  [
    86.594259,
    48.57519
  ],
  [
    86.593918,
    48.574974
  ],
  [
    86.594185,
    48.574675
  ],
  [
    86.594326,
    48.574344
  ],
  [
    86.594292,
    48.573932
  ],
  [
    86.594081,
    48.573424
  ],
  [
    86.594128,
    48.573292
  ],
  [
    86.594275,
    48.573191
  ],
  [
    86.595019,
    48.573085
  ],
  [
    86.596154,
    48.572628
  ],
  [
    86.596774,
    48.572523
  ],
  [
    86.597019,
    48.572323
  ],
  [
    86.596962,
    48.572011
  ],
  [
    86.596478,
    48.571554
  ],
  [
    86.596032,
    48.57057
  ],
  [
    86.5966733,
    48.569927
  ],
  [
    86.5972117,
    48.5693025
  ],
  [
    86.5968981,
    48.5689109
  ],
  [
    86.596474,
    48.5686179
  ],
  [
    86.5957646,
    48.5680942
  ],
  [
    86.5950301,
    48.5675926
  ],
  [
    86.5944752,
    48.566999
  ],
  [
    86.5944076,
    48.5660739
  ],
  [
    86.5946908,
    48.5654381
  ],
  [
    86.594896,
    48.56521
  ],
  [
    86.594941,
    48.564749
  ],
  [
    86.5953632,
    48.5642403
  ],
  [
    86.5956572,
    48.5638109
  ],
  [
    86.5958026,
    48.5631721
  ],
  [
    86.5956416,
    48.5626714
  ],
  [
    86.595421,
    48.561498
  ],
  [
    86.5952096,
    48.5609196
  ],
  [
    86.5952317,
    48.5604558
  ],
  [
    86.59519,
    48.559851
  ],
  [
    86.595515,
    48.559461
  ],
  [
    86.5958271,
    48.5592575
  ],
  [
    86.596498,
    48.55882
  ],
  [
    86.597088,
    48.558597
  ],
  [
    86.597351,
    48.558417
  ],
  [
    86.597429,
    48.558267
  ],
  [
    86.597085,
    48.557564
  ],
  [
    86.5965687,
    48.5554884
  ],
  [
    86.596226,
    48.554754
  ],
  [
    86.5959789,
    48.554551
  ],
  [
    86.5952664,
    48.5536961
  ],
  [
    86.5948003,
    48.5533644
  ],
  [
    86.5943618,
    48.5525492
  ],
  [
    86.5941608,
    48.5518126
  ],
  [
    86.593645,
    48.551263
  ],
  [
    86.5933239,
    48.5510916
  ],
  [
    86.5924351,
    48.5506442
  ],
  [
    86.5920619,
    48.5505698
  ],
  [
    86.5915328,
    48.5500688
  ],
  [
    86.5926114,
    48.5478637
  ],
  [
    86.5921931,
    48.5463577
  ],
  [
    86.5922606,
    48.5452011
  ],
  [
    86.5921991,
    48.5449434
  ],
  [
    86.5916613,
    48.5442313
  ],
  [
    86.5905673,
    48.5442314
  ],
  [
    86.5898628,
    48.544075
  ],
  [
    86.5894033,
    48.543459
  ],
  [
    86.5893325,
    48.5428159
  ],
  [
    86.589096,
    48.542257
  ],
  [
    86.588783,
    48.541948
  ],
  [
    86.588473,
    48.541748
  ],
  [
    86.58822,
    48.541659
  ],
  [
    86.588113,
    48.541456
  ],
  [
    86.5873723,
    48.541046
  ],
  [
    86.5873599,
    48.5406266
  ],
  [
    86.5873692,
    48.5406046
  ],
  [
    86.5868647,
    48.5395766
  ],
  [
    86.5854302,
    48.5380353
  ],
  [
    86.5841717,
    48.5374522
  ],
  [
    86.5833792,
    48.5375841
  ],
  [
    86.5808486,
    48.5391137
  ],
  [
    86.5359073,
    48.5234932
  ],
  [
    86.4069771,
    48.478654
  ],
  [
    86.4061885,
    48.4786504
  ],
  [
    86.4059739,
    48.478718
  ],
  [
    86.4057111,
    48.4788247
  ],
  [
    86.4057701,
    48.4788816
  ],
  [
    86.4055179,
    48.4790807
  ],
  [
    86.4052229,
    48.479006
  ],
  [
    86.4052014,
    48.4788816
  ],
  [
    86.4049547,
    48.478942
  ],
  [
    86.4048313,
    48.4789029
  ],
  [
    86.4046006,
    48.4789242
  ],
  [
    86.4045738,
    48.4789669
  ],
  [
    86.4046811,
    48.4790096
  ],
  [
    86.4045913,
    48.4790283
  ],
  [
    86.4044451,
    48.4790398
  ],
  [
    86.4044075,
    48.478878
  ],
  [
    86.4042037,
    48.478846
  ],
  [
    86.4039837,
    48.4790238
  ],
  [
    86.4039059,
    48.4790967
  ],
  [
    86.4037329,
    48.479126
  ],
  [
    86.4037262,
    48.4790674
  ],
  [
    86.4035908,
    48.4790487
  ],
  [
    86.4035868,
    48.4791376
  ],
  [
    86.4035036,
    48.4792123
  ],
  [
    86.4034017,
    48.4791963
  ],
  [
    86.4033507,
    48.4792158
  ],
  [
    86.4034392,
    48.479271
  ],
  [
    86.4031442,
    48.4793065
  ],
  [
    86.4031388,
    48.4792532
  ],
  [
    86.4030476,
    48.4792674
  ],
  [
    86.4029243,
    48.4794648
  ],
  [
    86.4025836,
    48.4794985
  ],
  [
    86.4025997,
    48.4795946
  ],
  [
    86.4025353,
    48.4797155
  ],
  [
    86.4024334,
    48.4797066
  ],
  [
    86.4024173,
    48.479655
  ],
  [
    86.402302,
    48.4796639
  ],
  [
    86.4022618,
    48.4797955
  ],
  [
    86.4021706,
    48.4798524
  ],
  [
    86.401838,
    48.4799466
  ],
  [
    86.401618,
    48.4801084
  ],
  [
    86.401551,
    48.4801671
  ],
  [
    86.4014061,
    48.4801439
  ],
  [
    86.4012586,
    48.480192
  ],
  [
    86.401213,
    48.480256
  ],
  [
    86.4010199,
    48.4802897
  ],
  [
    86.400977,
    48.4803324
  ],
  [
    86.4010467,
    48.4804302
  ],
  [
    86.4009475,
    48.4805067
  ],
  [
    86.4007865,
    48.4805173
  ],
  [
    86.4005773,
    48.4805884
  ],
  [
    86.4000945,
    48.4804889
  ],
  [
    86.3997619,
    48.4804355
  ],
  [
    86.399601,
    48.4804747
  ],
  [
    86.399483,
    48.480496
  ],
  [
    86.3990324,
    48.4803538
  ],
  [
    86.3988124,
    48.4803751
  ],
  [
    86.398732,
    48.4803217
  ],
  [
    86.3981258,
    48.4806525
  ],
  [
    86.3978254,
    48.4807911
  ],
  [
    86.3976537,
    48.4806311
  ],
  [
    86.3975357,
    48.4806489
  ],
  [
    86.397423,
    48.4809618
  ],
  [
    86.3972353,
    48.4809654
  ],
  [
    86.3970958,
    48.4810472
  ],
  [
    86.3968598,
    48.4808338
  ],
  [
    86.3966237,
    48.4809049
  ],
  [
    86.3967149,
    48.48104
  ],
  [
    86.3963555,
    48.481072
  ],
  [
    86.396157,
    48.4811609
  ],
  [
    86.3959478,
    48.4810827
  ],
  [
    86.3957493,
    48.4811361
  ],
  [
    86.3959156,
    48.4812854
  ],
  [
    86.395685,
    48.4813885
  ],
  [
    86.3955562,
    48.4815272
  ],
  [
    86.3953685,
    48.4816765
  ],
  [
    86.3953256,
    48.4817868
  ],
  [
    86.3954436,
    48.4818437
  ],
  [
    86.3953685,
    48.4819539
  ],
  [
    86.3952022,
    48.4819397
  ],
  [
    86.39517,
    48.481993
  ],
  [
    86.3953631,
    48.482089
  ],
  [
    86.3952183,
    48.4821495
  ],
  [
    86.3951861,
    48.4822419
  ],
  [
    86.3953953,
    48.4822988
  ],
  [
    86.3953202,
    48.4826153
  ],
  [
    86.395288,
    48.4827717
  ],
  [
    86.3951807,
    48.482786
  ],
  [
    86.3951914,
    48.4830029
  ],
  [
    86.3953148,
    48.4830384
  ],
  [
    86.3953524,
    48.4831664
  ],
  [
    86.3952022,
    48.4832944
  ],
  [
    86.3952183,
    48.4834118
  ],
  [
    86.3951914,
    48.4836216
  ],
  [
    86.3954436,
    48.4836856
  ],
  [
    86.3954758,
    48.4837816
  ],
  [
    86.3953363,
    48.4839665
  ],
  [
    86.3954328,
    48.484098
  ],
  [
    86.3955777,
    48.4842118
  ],
  [
    86.3954758,
    48.4844003
  ],
  [
    86.3952826,
    48.4843718
  ],
  [
    86.3951432,
    48.4844714
  ],
  [
    86.3951432,
    48.4847345
  ],
  [
    86.3950037,
    48.4848518
  ],
  [
    86.3950144,
    48.4851398
  ],
  [
    86.3947355,
    48.485339
  ],
  [
    86.3944887,
    48.4855238
  ],
  [
    86.3938933,
    48.485755
  ],
  [
    86.3937591,
    48.4858723
  ],
  [
    86.393786,
    48.4860678
  ],
  [
    86.393389,
    48.4862598
  ],
  [
    86.3929598,
    48.4864447
  ],
  [
    86.3929759,
    48.4867078
  ],
  [
    86.3928204,
    48.4867612
  ],
  [
    86.392638,
    48.4866723
  ],
  [
    86.3925307,
    48.4866936
  ],
  [
    86.3925736,
    48.4868643
  ],
  [
    86.392359,
    48.4869674
  ],
  [
    86.3920908,
    48.4869425
  ],
  [
    86.3911467,
    48.4873976
  ],
  [
    86.3911252,
    48.4875967
  ],
  [
    86.3909804,
    48.4876429
  ],
  [
    86.3908677,
    48.4875683
  ],
  [
    86.3906531,
    48.4875896
  ],
  [
    86.3904922,
    48.4877638
  ],
  [
    86.390106,
    48.4882616
  ],
  [
    86.3900684,
    48.4883682
  ],
  [
    86.390342,
    48.4883789
  ],
  [
    86.3905459,
    48.4887878
  ],
  [
    86.3904654,
    48.4892997
  ],
  [
    86.3908516,
    48.4894633
  ],
  [
    86.3908892,
    48.4895877
  ],
  [
    86.390798,
    48.4896233
  ],
  [
    86.3904922,
    48.4895557
  ],
  [
    86.3903957,
    48.4896268
  ],
  [
    86.3904654,
    48.4898472
  ],
  [
    86.3901918,
    48.4898295
  ],
  [
    86.3900362,
    48.4898828
  ],
  [
    86.3901757,
    48.4900143
  ],
  [
    86.3901328,
    48.4901175
  ],
  [
    86.3893603,
    48.4899752
  ],
  [
    86.3891779,
    48.4900748
  ],
  [
    86.3891672,
    48.4904019
  ],
  [
    86.3887917,
    48.4907929
  ],
  [
    86.3882338,
    48.4907858
  ],
  [
    86.388148,
    48.4906436
  ],
  [
    86.3879763,
    48.4906401
  ],
  [
    86.3878905,
    48.4907609
  ],
  [
    86.387515,
    48.4908249
  ],
  [
    86.3867908,
    48.4908285
  ],
  [
    86.3865816,
    48.4909565
  ],
  [
    86.3859164,
    48.4910418
  ],
  [
    86.3856857,
    48.4914187
  ],
  [
    86.3851707,
    48.4917244
  ],
  [
    86.3848113,
    48.491696
  ],
  [
    86.3843768,
    48.4919128
  ],
  [
    86.3840335,
    48.4917493
  ],
  [
    86.3840388,
    48.491632
  ],
  [
    86.3836097,
    48.4915324
  ],
  [
    86.3833361,
    48.4915964
  ],
  [
    86.3830249,
    48.4920835
  ],
  [
    86.3824778,
    48.492311
  ],
  [
    86.3820701,
    48.4922719
  ],
  [
    86.3814639,
    48.492375
  ],
  [
    86.381244,
    48.4929083
  ],
  [
    86.3810884,
    48.4929616
  ],
  [
    86.3808899,
    48.4928443
  ],
  [
    86.3800209,
    48.4928478
  ],
  [
    86.3796293,
    48.4923377
  ],
  [
    86.3788595,
    48.4926238
  ],
  [
    86.3789882,
    48.4929065
  ],
  [
    86.3785457,
    48.4931536
  ],
  [
    86.3783713,
    48.4936121
  ],
  [
    86.3783069,
    48.4938326
  ],
  [
    86.3781675,
    48.4938859
  ],
  [
    86.3778161,
    48.4937544
  ],
  [
    86.3773387,
    48.4939605
  ],
  [
    86.3774406,
    48.4942272
  ],
  [
    86.3777356,
    48.4941987
  ],
  [
    86.3782721,
    48.4942663
  ],
  [
    86.3781755,
    48.4946929
  ],
  [
    86.3776069,
    48.4948102
  ],
  [
    86.3777625,
    48.4949453
  ],
  [
    86.3783204,
    48.4952332
  ],
  [
    86.3793235,
    48.4953967
  ],
  [
    86.3791411,
    48.4955496
  ],
  [
    86.3791089,
    48.4957629
  ],
  [
    86.3793181,
    48.495866
  ],
  [
    86.3792538,
    48.4960402
  ],
  [
    86.3788782,
    48.4959975
  ],
  [
    86.3786959,
    48.4961468
  ],
  [
    86.3787978,
    48.4968791
  ],
  [
    86.3782667,
    48.4983863
  ],
  [
    86.3783043,
    48.4988804
  ],
  [
    86.3780146,
    48.498916
  ],
  [
    86.3779878,
    48.4992394
  ],
  [
    86.3778054,
    48.4992963
  ],
  [
    86.3774084,
    48.4996731
  ],
  [
    86.377446,
    48.4997584
  ],
  [
    86.3773226,
    48.4998793
  ],
  [
    86.3767593,
    48.5000392
  ],
  [
    86.3768237,
    48.5003414
  ],
  [
    86.3767378,
    48.500416
  ],
  [
    86.3764053,
    48.5003947
  ],
  [
    86.3750427,
    48.5009776
  ],
  [
    86.3749944,
    48.5013722
  ],
  [
    86.3745009,
    48.5013331
  ],
  [
    86.3740449,
    48.5012335
  ],
  [
    86.3737606,
    48.5012371
  ],
  [
    86.373546,
    48.5013722
  ],
  [
    86.3729988,
    48.5013651
  ],
  [
    86.3723444,
    48.5013295
  ],
  [
    86.3718616,
    48.50123
  ],
  [
    86.3701128,
    48.5014219
  ],
  [
    86.3691794,
    48.5010736
  ],
  [
    86.3681172,
    48.5009883
  ],
  [
    86.3681816,
    48.5001778
  ],
  [
    86.3683533,
    48.4998153
  ],
  [
    86.367967,
    48.4984503
  ],
  [
    86.3682138,
    48.4974479
  ],
  [
    86.3677095,
    48.497032
  ],
  [
    86.3677203,
    48.4967618
  ],
  [
    86.3678329,
    48.4965805
  ],
  [
    86.3688146,
    48.4962428
  ],
  [
    86.3690399,
    48.4955212
  ],
  [
    86.3686483,
    48.4949986
  ],
  [
    86.3682621,
    48.4947142
  ],
  [
    86.3682674,
    48.4944938
  ],
  [
    86.3685035,
    48.4942734
  ],
  [
    86.3687449,
    48.4941561
  ],
  [
    86.368675,
    48.4932887
  ],
  [
    86.3685464,
    48.4929047
  ],
  [
    86.3681011,
    48.4927909
  ],
  [
    86.3674681,
    48.4928052
  ],
  [
    86.3673984,
    48.4929882
  ],
  [
    86.3666554,
    48.4930398
  ],
  [
    86.3665884,
    48.4930256
  ],
  [
    86.3666554,
    48.4928798
  ],
  [
    86.3667869,
    48.4928674
  ],
  [
    86.3668727,
    48.4927696
  ],
  [
    86.3667627,
    48.4925865
  ],
  [
    86.3660975,
    48.4925599
  ],
  [
    86.3659473,
    48.4926523
  ],
  [
    86.3653331,
    48.4932353
  ],
  [
    86.3651158,
    48.4932495
  ],
  [
    86.3645445,
    48.4930469
  ],
  [
    86.3634716,
    48.4922257
  ],
  [
    86.3628976,
    48.4920906
  ],
  [
    86.3624363,
    48.4920906
  ],
  [
    86.3617389,
    48.4924461
  ],
  [
    86.3615511,
    48.4924355
  ],
  [
    86.3610094,
    48.4923039
  ],
  [
    86.3608431,
    48.4921439
  ],
  [
    86.360827,
    48.491959
  ],
  [
    86.3612293,
    48.4916142
  ],
  [
    86.361181,
    48.4912409
  ],
  [
    86.3608645,
    48.491184
  ],
  [
    86.3601833,
    48.4911876
  ],
  [
    86.3598828,
    48.4913369
  ],
  [
    86.3594966,
    48.4912587
  ],
  [
    86.3596978,
    48.4908943
  ],
  [
    86.3594671,
    48.4907307
  ],
  [
    86.3589521,
    48.4906578
  ],
  [
    86.3575922,
    48.4909565
  ],
  [
    86.3564657,
    48.4910703
  ],
  [
    86.3562082,
    48.4912302
  ],
  [
    86.3564443,
    48.4913013
  ],
  [
    86.3566266,
    48.4915111
  ],
  [
    86.3561653,
    48.4919697
  ],
  [
    86.3564496,
    48.4923288
  ],
  [
    86.3565515,
    48.4927838
  ],
  [
    86.3560097,
    48.4928905
  ],
  [
    86.3555055,
    48.4927838
  ],
  [
    86.3550656,
    48.4928905
  ],
  [
    86.3547813,
    48.4927803
  ],
  [
    86.3546364,
    48.4925243
  ],
  [
    86.3539766,
    48.4923963
  ],
  [
    86.3537138,
    48.4920159
  ],
  [
    86.3539122,
    48.491216
  ],
  [
    86.3535475,
    48.4908463
  ],
  [
    86.3531237,
    48.4907858
  ],
  [
    86.3523512,
    48.4908569
  ],
  [
    86.3509082,
    48.4907361
  ],
  [
    86.3502269,
    48.4908249
  ],
  [
    86.3495349,
    48.4914187
  ],
  [
    86.3490038,
    48.4915964
  ],
  [
    86.3482957,
    48.4923928
  ],
  [
    86.3474535,
    48.4930078
  ],
  [
    86.3468688,
    48.4930362
  ],
  [
    86.3465898,
    48.4929154
  ],
  [
    86.3458549,
    48.4929651
  ],
  [
    86.3444816,
    48.4929118
  ],
  [
    86.3440149,
    48.4925705
  ],
  [
    86.3417404,
    48.4912907
  ],
  [
    86.3408606,
    48.4912231
  ],
  [
    86.3404958,
    48.4910809
  ],
  [
    86.3403564,
    48.4907538
  ],
  [
    86.3399165,
    48.4905974
  ],
  [
    86.3389455,
    48.4905939
  ],
  [
    86.3383447,
    48.4908712
  ],
  [
    86.3374864,
    48.4915466
  ],
  [
    86.3370036,
    48.4913689
  ],
  [
    86.3349651,
    48.4909067
  ],
  [
    86.3324278,
    48.4907823
  ],
  [
    86.3316124,
    48.4904588
  ],
  [
    86.3312315,
    48.490505
  ],
  [
    86.3298957,
    48.4913404
  ],
  [
    86.3297509,
    48.4917351
  ],
  [
    86.3299977,
    48.4922008
  ],
  [
    86.3305341,
    48.4928905
  ],
  [
    86.3305234,
    48.4933526
  ],
  [
    86.3292788,
    48.4940281
  ],
  [
    86.3284205,
    48.4941987
  ],
  [
    86.3268756,
    48.4938575
  ],
  [
    86.3251697,
    48.4936441
  ],
  [
    86.3238822,
    48.4928052
  ],
  [
    86.3233136,
    48.4918951
  ],
  [
    86.3232063,
    48.4903308
  ],
  [
    86.3222944,
    48.4894206
  ],
  [
    86.3222729,
    48.4887664
  ],
  [
    86.3214146,
    48.4881407
  ],
  [
    86.3197194,
    48.487842
  ],
  [
    86.3182281,
    48.4876358
  ],
  [
    86.3171016,
    48.4877638
  ],
  [
    86.3150953,
    48.4884891
  ],
  [
    86.3135504,
    48.4891291
  ],
  [
    86.3123702,
    48.4892215
  ],
  [
    86.3106965,
    48.4896837
  ],
  [
    86.3101708,
    48.4895699
  ],
  [
    86.3086902,
    48.4896908
  ],
  [
    86.3078426,
    48.4895131
  ],
  [
    86.3051604,
    48.4893637
  ],
  [
    86.302446,
    48.4901103
  ],
  [
    86.3006972,
    48.4905441
  ],
  [
    86.3000106,
    48.4901814
  ],
  [
    86.2994849,
    48.490025
  ],
  [
    86.2994849,
    48.4895984
  ],
  [
    86.3002681,
    48.4889726
  ],
  [
    86.3002573,
    48.4881976
  ],
  [
    86.3008796,
    48.4873443
  ],
  [
    86.3006221,
    48.4860998
  ],
  [
    86.2998389,
    48.4849549
  ],
  [
    86.2986909,
    48.4840447
  ],
  [
    86.2963413,
    48.4823237
  ],
  [
    86.2961375,
    48.4813636
  ],
  [
    86.2971352,
    48.4791945
  ],
  [
    86.2970601,
    48.4778503
  ],
  [
    86.296867,
    48.4770182
  ],
  [
    86.2962877,
    48.4766625
  ],
  [
    86.2927793,
    48.4763283
  ],
  [
    86.2916635,
    48.4750693
  ],
  [
    86.2903546,
    48.4745288
  ],
  [
    86.2869214,
    48.4737322
  ],
  [
    86.2859129,
    48.4733196
  ],
  [
    86.2852906,
    48.4728217
  ],
  [
    86.2826728,
    48.4722953
  ],
  [
    86.2824475,
    48.4714987
  ],
  [
    86.2824367,
    48.4707162
  ],
  [
    86.2818788,
    48.4700333
  ],
  [
    86.2815355,
    48.4680558
  ],
  [
    86.2813424,
    48.4656087
  ],
  [
    86.2810634,
    48.464947
  ],
  [
    86.2813746,
    48.4639795
  ],
  [
    86.2805592,
    48.4636309
  ],
  [
    86.2796043,
    48.4619662
  ],
  [
    86.27645,
    48.4604863
  ],
  [
    86.2724588,
    48.4586935
  ],
  [
    86.2700986,
    48.4557335
  ],
  [
    86.2687467,
    48.4527592
  ],
  [
    86.2677168,
    48.4516064
  ],
  [
    86.2656783,
    48.4514214
  ],
  [
    86.2627171,
    48.4531719
  ],
  [
    86.259477,
    48.454097
  ],
  [
    86.25746,
    48.4541539
  ],
  [
    86.2510227,
    48.4525742
  ],
  [
    86.2497137,
    48.451578
  ],
  [
    86.2481903,
    48.4485465
  ],
  [
    86.2473749,
    48.4478918
  ],
  [
    86.2476538,
    48.4471374
  ],
  [
    86.2467955,
    48.4464827
  ],
  [
    86.2461518,
    48.4463546
  ],
  [
    86.2449716,
    48.4446466
  ],
  [
    86.2437485,
    48.4437783
  ],
  [
    86.2414097,
    48.4430524
  ],
  [
    86.240208,
    48.4410453
  ],
  [
    86.2375902,
    48.4392802
  ],
  [
    86.2364958,
    48.4377427
  ],
  [
    86.2367748,
    48.4361768
  ],
  [
    86.2357663,
    48.4350379
  ],
  [
    86.2357019,
    48.4338705
  ],
  [
    86.2345861,
    48.4329735
  ],
  [
    86.2322687,
    48.432219
  ],
  [
    86.2305306,
    48.4322617
  ],
  [
    86.2285994,
    48.4329308
  ],
  [
    86.2267755,
    48.432162
  ],
  [
    86.2259172,
    48.4321193
  ],
  [
    86.223986,
    48.4319485
  ],
  [
    86.222999,
    48.4321905
  ],
  [
    86.1458585,
    48.435579
  ],
  [
    86.0532742,
    48.4388602
  ],
  [
    85.962524,
    48.4369457
  ],
  [
    85.9307668,
    48.436604
  ],
  [
    85.914781,
    48.4362195
  ],
  [
    85.9117341,
    48.4362052
  ],
  [
    85.9109938,
    48.4358493
  ],
  [
    85.9097707,
    48.4355575
  ],
  [
    85.9090519,
    48.4355077
  ],
  [
    85.9082258,
    48.4354009
  ],
  [
    85.906552,
    48.4351019
  ],
  [
    85.9061229,
    48.4343474
  ],
  [
    85.9057903,
    48.4340271
  ],
  [
    85.9050286,
    48.4339915
  ],
  [
    85.9042776,
    48.4336355
  ],
  [
    85.9034836,
    48.4336
  ],
  [
    85.9028613,
    48.4331586
  ],
  [
    85.9019387,
    48.4328454
  ],
  [
    85.9015739,
    48.4324468
  ],
  [
    85.9008443,
    48.4321905
  ],
  [
    85.9002435,
    48.4317278
  ],
  [
    85.8992886,
    48.4315356
  ],
  [
    85.8978509,
    48.4307667
  ],
  [
    85.89607,
    48.43066
  ],
  [
    85.8947503,
    48.4304464
  ],
  [
    85.8927441,
    48.4305318
  ],
  [
    85.8909523,
    48.430304
  ],
  [
    85.8904266,
    48.430482
  ],
  [
    85.8892894,
    48.43108
  ],
  [
    85.8882594,
    48.4307667
  ],
  [
    85.8882594,
    48.4299837
  ],
  [
    85.8874225,
    48.4293714
  ],
  [
    85.8854055,
    48.4291152
  ],
  [
    85.8845687,
    48.4290724
  ],
  [
    85.8823585,
    48.4296135
  ],
  [
    85.8802128,
    48.4288019
  ],
  [
    85.8789897,
    48.4292006
  ],
  [
    85.8772516,
    48.4288873
  ],
  [
    85.8759211,
    48.429286
  ],
  [
    85.8747625,
    48.4288873
  ],
  [
    85.8739686,
    48.429286
  ],
  [
    85.8728742,
    48.4289158
  ],
  [
    85.8708143,
    48.4298413
  ],
  [
    85.8696985,
    48.4302542
  ],
  [
    85.8681965,
    48.4314786
  ],
  [
    85.8668875,
    48.4317491
  ],
  [
    85.8652782,
    48.4310088
  ],
  [
    85.8649564,
    48.4299694
  ],
  [
    85.8647203,
    48.4296847
  ],
  [
    85.8634328,
    48.4302115
  ],
  [
    85.8612013,
    48.4302399
  ],
  [
    85.8591199,
    48.430781
  ],
  [
    85.8575535,
    48.4300691
  ],
  [
    85.85603,
    48.4300121
  ],
  [
    85.8550215,
    48.429827
  ],
  [
    85.8532833,
    48.4296704
  ],
  [
    85.8506226,
    48.428389
  ],
  [
    85.8490562,
    48.4279903
  ],
  [
    85.8465242,
    48.4281897
  ],
  [
    85.8452581,
    48.4277127
  ],
  [
    85.8433592,
    48.4275418
  ],
  [
    85.8415997,
    48.4273923
  ],
  [
    85.8406877,
    48.4273994
  ],
  [
    85.8399153,
    48.427435
  ],
  [
    85.8393037,
    48.4269722
  ],
  [
    85.838542,
    48.4267231
  ],
  [
    85.8382952,
    48.427314
  ],
  [
    85.8375334,
    48.4275347
  ],
  [
    85.8361709,
    48.4272285
  ],
  [
    85.835849,
    48.4265095
  ],
  [
    85.8353019,
    48.4263244
  ],
  [
    85.8340573,
    48.4265451
  ],
  [
    85.8330488,
    48.4260538
  ],
  [
    85.8314395,
    48.4255626
  ],
  [
    85.8307743,
    48.424338
  ],
  [
    85.8297872,
    48.4237399
  ],
  [
    85.8299696,
    48.4223373
  ],
  [
    85.8295298,
    48.4219101
  ],
  [
    85.8285642,
    48.4224512
  ],
  [
    85.8207319,
    48.4188912
  ],
  [
    85.8057974,
    48.4157011
  ],
  [
    85.7995224,
    48.4160858
  ],
  [
    85.7979011,
    48.4161853
  ],
  [
    85.7924938,
    48.417111
  ],
  [
    85.7922792,
    48.4180366
  ],
  [
    85.7910132,
    48.4181079
  ],
  [
    85.790112,
    48.4178942
  ],
  [
    85.7895326,
    48.4168119
  ],
  [
    85.788846,
    48.4175097
  ],
  [
    85.7874298,
    48.4174528
  ],
  [
    85.7865929,
    48.4167264
  ],
  [
    85.7853054,
    48.4141344
  ],
  [
    85.783267,
    48.4141771
  ],
  [
    85.7811427,
    48.4132229
  ],
  [
    85.7803917,
    48.4133653
  ],
  [
    85.7789111,
    48.4134223
  ],
  [
    85.7777309,
    48.4130092
  ],
  [
    85.7782592,
    48.4119911
  ],
  [
    85.7768512,
    48.4113286
  ],
  [
    85.7756924,
    48.410887
  ],
  [
    85.7751989,
    48.409776
  ],
  [
    85.7745552,
    48.4088217
  ],
  [
    85.7720875,
    48.4079671
  ],
  [
    85.7701027,
    48.407732
  ],
  [
    85.7669163,
    48.406158
  ],
  [
    85.7655215,
    48.4047336
  ],
  [
    85.7636547,
    48.404235
  ],
  [
    85.7626247,
    48.403943
  ],
  [
    85.7616483,
    48.4037578
  ],
  [
    85.760951,
    48.4043703
  ],
  [
    85.7599318,
    48.4044558
  ],
  [
    85.7592773,
    48.4041495
  ],
  [
    85.7585156,
    48.4040356
  ],
  [
    85.7582581,
    48.4036153
  ],
  [
    85.7566595,
    48.4033874
  ],
  [
    85.7559085,
    48.4029529
  ],
  [
    85.7565522,
    48.4021836
  ],
  [
    85.7562625,
    48.4015711
  ],
  [
    85.7564664,
    48.4012007
  ],
  [
    85.7556188,
    48.4004314
  ],
  [
    85.7553077,
    48.3996122
  ],
  [
    85.7561552,
    48.3994057
  ],
  [
    85.7559836,
    48.3988928
  ],
  [
    85.7553939,
    48.3981667
  ],
  [
    85.7548571,
    48.3981306
  ],
  [
    85.754621,
    48.3978172
  ],
  [
    85.7549643,
    48.3973043
  ],
  [
    85.7544923,
    48.3967771
  ],
  [
    85.7538378,
    48.3971618
  ],
  [
    85.7529688,
    48.3970478
  ],
  [
    85.7527971,
    48.3965848
  ],
  [
    85.7531619,
    48.3955233
  ],
  [
    85.7526469,
    48.395388
  ],
  [
    85.7522821,
    48.3950033
  ],
  [
    85.7524967,
    48.3945403
  ],
  [
    85.7530439,
    48.3938706
  ],
  [
    85.753001,
    48.3932864
  ],
  [
    85.7524323,
    48.3932864
  ],
  [
    85.75122,
    48.39303
  ],
  [
    85.750587,
    48.3925313
  ],
  [
    85.7507586,
    48.3919898
  ],
  [
    85.7514024,
    48.3917049
  ],
  [
    85.7515182,
    48.3912621
  ],
  [
    85.7525182,
    48.3908784
  ],
  [
    85.7527757,
    48.3901446
  ],
  [
    85.7526898,
    48.3896388
  ],
  [
    85.7514882,
    48.3896958
  ],
  [
    85.750705,
    48.3893538
  ],
  [
    85.7501686,
    48.3894393
  ],
  [
    85.7493853,
    48.3903868
  ],
  [
    85.7484627,
    48.3908143
  ],
  [
    85.7464456,
    48.3902159
  ],
  [
    85.7458663,
    48.3895675
  ],
  [
    85.7461882,
    48.3893324
  ],
  [
    85.746274,
    48.3886556
  ],
  [
    85.7465207,
    48.3880215
  ],
  [
    85.7462311,
    48.3873802
  ],
  [
    85.7454693,
    48.3871237
  ],
  [
    85.7449757,
    48.3862616
  ],
  [
    85.7440853,
    48.3862687
  ],
  [
    85.7431948,
    48.3866535
  ],
  [
    85.7425296,
    48.3865608
  ],
  [
    85.7421541,
    48.3863043
  ],
  [
    85.7420361,
    48.385827
  ],
  [
    85.7418537,
    48.3855918
  ],
  [
    85.7421863,
    48.3853994
  ],
  [
    85.742315,
    48.3850218
  ],
  [
    85.7426476,
    48.3838604
  ],
  [
    85.7414782,
    48.3833759
  ],
  [
    85.7414782,
    48.3830196
  ],
  [
    85.7419503,
    48.3827559
  ],
  [
    85.7416713,
    48.3814234
  ],
  [
    85.7408988,
    48.3812025
  ],
  [
    85.7397936,
    48.381224
  ],
  [
    85.7392788,
    48.3814021
  ],
  [
    85.7388389,
    48.381117
  ],
  [
    85.7367039,
    48.3793213
  ],
  [
    85.7354271,
    48.3794638
  ],
  [
    85.7352233,
    48.3786942
  ],
  [
    85.7330668,
    48.3783236
  ],
  [
    85.7325303,
    48.3774471
  ],
  [
    85.7312643,
    48.3772191
  ],
  [
    85.7315433,
    48.3768627
  ],
  [
    85.7310283,
    48.3761929
  ],
  [
    85.7307923,
    48.3763425
  ],
  [
    85.7307601,
    48.3766561
  ],
  [
    85.7303846,
    48.3767772
  ],
  [
    85.7291937,
    48.3760361
  ],
  [
    85.7282494,
    48.3759577
  ],
  [
    85.7268762,
    48.3757154
  ],
  [
    85.7280135,
    48.3750098
  ],
  [
    85.7287431,
    48.3736059
  ],
  [
    85.7276487,
    48.3725155
  ],
  [
    85.7265544,
    48.3728504
  ],
  [
    85.7253528,
    48.3728718
  ],
  [
    85.725224,
    48.3722019
  ],
  [
    85.7256316,
    48.3718313
  ],
  [
    85.7255781,
    48.3710972
  ],
  [
    85.7252133,
    48.3694864
  ],
  [
    85.724988,
    48.3688592
  ],
  [
    85.7253849,
    48.3680395
  ],
  [
    85.7255459,
    48.3666068
  ],
  [
    85.725224,
    48.3663146
  ],
  [
    85.7245481,
    48.3663787
  ],
  [
    85.7235181,
    48.3654521
  ],
  [
    85.7227778,
    48.3653095
  ],
  [
    85.722928,
    48.3659154
  ],
  [
    85.7224345,
    48.3659226
  ],
  [
    85.7218873,
    48.365502
  ],
  [
    85.7219408,
    48.3650886
  ],
  [
    85.7211041,
    48.3649175
  ],
  [
    85.720557,
    48.3650458
  ],
  [
    85.7199132,
    48.3648961
  ],
  [
    85.7189047,
    48.3649175
  ],
  [
    85.7183361,
    48.365167
  ],
  [
    85.7177246,
    48.3651955
  ],
  [
    85.7170915,
    48.3649674
  ],
  [
    85.7170808,
    48.3644399
  ],
  [
    85.7179391,
    48.3637984
  ],
  [
    85.7179499,
    48.3636202
  ],
  [
    85.7175636,
    48.363499
  ],
  [
    85.717113,
    48.3634634
  ],
  [
    85.7166516,
    48.3622515
  ],
  [
    85.7146346,
    48.3610112
  ],
  [
    85.7136798,
    48.3608401
  ],
  [
    85.7134008,
    48.3597922
  ],
  [
    85.7135403,
    48.359393
  ],
  [
    85.7141089,
    48.3592147
  ],
  [
    85.7150316,
    48.3592789
  ],
  [
    85.7158685,
    48.3586658
  ],
  [
    85.7153106,
    48.3579244
  ],
  [
    85.7155251,
    48.3575679
  ],
  [
    85.7152998,
    48.3572685
  ],
  [
    85.7143557,
    48.3569691
  ],
  [
    85.7134759,
    48.3565128
  ],
  [
    85.7134223,
    48.3558427
  ],
  [
    85.7126927,
    48.3555432
  ],
  [
    85.7125318,
    48.3550798
  ],
  [
    85.7118451,
    48.3547091
  ],
  [
    85.7110727,
    48.3541815
  ],
  [
    85.71015,
    48.3535541
  ],
  [
    85.7104826,
    48.353055
  ],
  [
    85.7101606,
    48.3526558
  ],
  [
    85.7104075,
    48.3518144
  ],
  [
    85.7118451,
    48.3519428
  ],
  [
    85.7115555,
    48.3515078
  ],
  [
    85.7126391,
    48.3511371
  ],
  [
    85.7128429,
    48.3505025
  ],
  [
    85.7138944,
    48.3499392
  ],
  [
    85.7142161,
    48.349376
  ],
  [
    85.7139373,
    48.3491478
  ],
  [
    85.7126498,
    48.3492547
  ],
  [
    85.71177,
    48.3488626
  ],
  [
    85.7115126,
    48.3483421
  ],
  [
    85.7121348,
    48.3476718
  ],
  [
    85.7119632,
    48.3474436
  ],
  [
    85.7108688,
    48.3474151
  ],
  [
    85.7103646,
    48.34785
  ],
  [
    85.7094204,
    48.3476005
  ],
  [
    85.7092649,
    48.347686
  ],
  [
    85.709474,
    48.347982
  ],
  [
    85.7093561,
    48.3483278
  ],
  [
    85.7086211,
    48.3482993
  ],
  [
    85.7077038,
    48.3474935
  ],
  [
    85.7074892,
    48.3471441
  ],
  [
    85.7086104,
    48.3466735
  ],
  [
    85.7094902,
    48.3463598
  ],
  [
    85.7096082,
    48.3461209
  ],
  [
    85.7092595,
    48.3459105
  ],
  [
    85.7086962,
    48.3461066
  ],
  [
    85.7081544,
    48.3457643
  ],
  [
    85.7082778,
    48.3453579
  ],
  [
    85.7073283,
    48.3452331
  ],
  [
    85.7071673,
    48.3456253
  ],
  [
    85.7067274,
    48.3457537
  ],
  [
    85.7057619,
    48.3456039
  ],
  [
    85.7054508,
    48.3451119
  ],
  [
    85.7060623,
    48.3445699
  ],
  [
    85.7059979,
    48.3440779
  ],
  [
    85.7051825,
    48.3441563
  ],
  [
    85.7040238,
    48.3444059
  ],
  [
    85.703482,
    48.3437927
  ],
  [
    85.7034874,
    48.3434932
  ],
  [
    85.7030368,
    48.3430867
  ],
  [
    85.7027686,
    48.34283
  ],
  [
    85.7025969,
    48.3423379
  ],
  [
    85.703069,
    48.3418102
  ],
  [
    85.7028115,
    48.3413681
  ],
  [
    85.703069,
    48.3410258
  ],
  [
    85.7032621,
    48.3402484
  ],
  [
    85.7030368,
    48.3399346
  ],
  [
    85.7024573,
    48.3402484
  ],
  [
    85.7020605,
    48.339792
  ],
  [
    85.7018566,
    48.3393784
  ],
  [
    85.7020068,
    48.339143
  ],
  [
    85.7025003,
    48.3393213
  ],
  [
    85.7028544,
    48.3391929
  ],
  [
    85.7029617,
    48.3389219
  ],
  [
    85.7026613,
    48.3387223
  ],
  [
    85.7025754,
    48.3382872
  ],
  [
    85.702157,
    48.3383942
  ],
  [
    85.701642,
    48.3384869
  ],
  [
    85.7013845,
    48.338166
  ],
  [
    85.7016635,
    48.3379021
  ],
  [
    85.7016528,
    48.3376026
  ],
  [
    85.7014382,
    48.337517
  ],
  [
    85.7008695,
    48.337788
  ],
  [
    85.7005584,
    48.3375027
  ],
  [
    85.701009,
    48.3368965
  ],
  [
    85.6995714,
    48.3364614
  ],
  [
    85.6984019,
    48.3365684
  ],
  [
    85.6981015,
    48.3362047
  ],
  [
    85.6972861,
    48.336062
  ],
  [
    85.6968461,
    48.3363331
  ],
  [
    85.6950652,
    48.3352133
  ],
  [
    85.6948714,
    48.3349445
  ],
  [
    85.694443,
    48.3343503
  ],
  [
    85.6945075,
    48.3336445
  ],
  [
    85.6945395,
    48.3332947
  ],
  [
    85.6949917,
    48.3332301
  ],
  [
    85.6959879,
    48.3330879
  ],
  [
    85.6961167,
    48.3325458
  ],
  [
    85.6954192,
    48.331911
  ],
  [
    85.6944966,
    48.3317755
  ],
  [
    85.694561,
    48.3313832
  ],
  [
    85.6935418,
    48.3308553
  ],
  [
    85.6922436,
    48.3305914
  ],
  [
    85.6912994,
    48.3295215
  ],
  [
    85.6909668,
    48.3289794
  ],
  [
    85.6913209,
    48.3283089
  ],
  [
    85.6923187,
    48.3280592
  ],
  [
    85.6924151,
    48.3274814
  ],
  [
    85.6921792,
    48.3267609
  ],
  [
    85.6917286,
    48.3264827
  ],
  [
    85.6909025,
    48.3264185
  ],
  [
    85.6912243,
    48.325377
  ],
  [
    85.6906557,
    48.3250703
  ],
  [
    85.6902373,
    48.3241001
  ],
  [
    85.6903124,
    48.323401
  ],
  [
    85.6909132,
    48.3230871
  ],
  [
    85.6908595,
    48.3226805
  ],
  [
    85.6900549,
    48.3224807
  ],
  [
    85.6899047,
    48.3216032
  ],
  [
    85.6893736,
    48.321432
  ],
  [
    85.6893897,
    48.3211823
  ],
  [
    85.6898724,
    48.321168
  ],
  [
    85.690366,
    48.3209469
  ],
  [
    85.6907093,
    48.3209255
  ],
  [
    85.6907523,
    48.3206972
  ],
  [
    85.6914282,
    48.3204546
  ],
  [
    85.6933272,
    48.3185283
  ],
  [
    85.6929301,
    48.3176508
  ],
  [
    85.6923509,
    48.3174225
  ],
  [
    85.6915784,
    48.3176436
  ],
  [
    85.6910205,
    48.3174581
  ],
  [
    85.6908274,
    48.3168802
  ],
  [
    85.6905913,
    48.3168303
  ],
  [
    85.6905591,
    48.3166376
  ],
  [
    85.6907308,
    48.3161525
  ],
  [
    85.6905806,
    48.3156744
  ],
  [
    85.6899261,
    48.3154318
  ],
  [
    85.6899368,
    48.3139406
  ],
  [
    85.6906557,
    48.3139905
  ],
  [
    85.6908381,
    48.3138835
  ],
  [
    85.6906664,
    48.313591
  ],
  [
    85.6909025,
    48.3135053
  ],
  [
    85.6916428,
    48.3135838
  ],
  [
    85.69337,
    48.3131129
  ],
  [
    85.6932843,
    48.3126705
  ],
  [
    85.6933164,
    48.3120426
  ],
  [
    85.6927693,
    48.3118999
  ],
  [
    85.6925225,
    48.3111721
  ],
  [
    85.6925654,
    48.3102872
  ],
  [
    85.6922649,
    48.3099519
  ],
  [
    85.6903553,
    48.3095879
  ],
  [
    85.6894969,
    48.3090599
  ],
  [
    85.6891537,
    48.3087673
  ],
  [
    85.6894112,
    48.3084177
  ],
  [
    85.6899798,
    48.3084034
  ],
  [
    85.6899798,
    48.3076041
  ],
  [
    85.6903552,
    48.3072473
  ],
  [
    85.6910419,
    48.3068834
  ],
  [
    85.6912243,
    48.3068977
  ],
  [
    85.6914711,
    48.3074043
  ],
  [
    85.6925332,
    48.3075328
  ],
  [
    85.6929517,
    48.3072902
  ],
  [
    85.6935738,
    48.3072545
  ],
  [
    85.6943464,
    48.3068406
  ],
  [
    85.6944001,
    48.3064624
  ],
  [
    85.694164,
    48.3061769
  ],
  [
    85.6946468,
    48.3057987
  ],
  [
    85.6944643,
    48.3054062
  ],
  [
    85.6932413,
    48.3052991
  ],
  [
    85.6929088,
    48.3046854
  ],
  [
    85.6923454,
    48.3044856
  ],
  [
    85.6924474,
    48.3039575
  ],
  [
    85.692265,
    48.3034865
  ],
  [
    85.6924312,
    48.3033116
  ],
  [
    85.693059,
    48.3031617
  ],
  [
    85.6935686,
    48.303069
  ],
  [
    85.6936598,
    48.3025658
  ],
  [
    85.6938958,
    48.3022875
  ],
  [
    85.6939763,
    48.3015274
  ],
  [
    85.6944537,
    48.3012883
  ],
  [
    85.6944537,
    48.3007245
  ],
  [
    85.6939334,
    48.300332
  ],
  [
    85.6935363,
    48.3002713
  ],
  [
    85.6925815,
    48.2995968
  ],
  [
    85.6920987,
    48.2996182
  ],
  [
    85.6919378,
    48.2998466
  ],
  [
    85.6924635,
    48.3001321
  ],
  [
    85.6924528,
    48.3004569
  ],
  [
    85.6921953,
    48.300828
  ],
  [
    85.6919002,
    48.3008101
  ],
  [
    85.6908273,
    48.3003391
  ],
  [
    85.6900978,
    48.3005389
  ],
  [
    85.6891535,
    48.3003106
  ],
  [
    85.6878875,
    48.2989973
  ],
  [
    85.6882739,
    48.297941
  ],
  [
    85.6902909,
    48.297113
  ],
  [
    85.6903123,
    48.2961994
  ],
  [
    85.686364,
    48.2948147
  ],
  [
    85.687394,
    48.2926162
  ],
  [
    85.68233,
    48.2919309
  ],
  [
    85.68336,
    48.2896466
  ],
  [
    85.6810425,
    48.285706
  ],
  [
    85.6805275,
    48.2823076
  ],
  [
    85.6824587,
    48.2787091
  ],
  [
    85.6798838,
    48.2781379
  ],
  [
    85.6783818,
    48.2765956
  ],
  [
    85.6812142,
    48.2749676
  ],
  [
    85.6776951,
    48.2720827
  ],
  [
    85.6747769,
    48.272454
  ],
  [
    85.673189,
    48.2675693
  ],
  [
    85.6754607,
    48.2670212
  ],
  [
    85.6755494,
    48.2641126
  ],
  [
    85.6728972,
    48.262188
  ],
  [
    85.6691121,
    48.2606557
  ],
  [
    85.6656788,
    48.2575414
  ],
  [
    85.6628893,
    48.2548841
  ],
  [
    85.6645201,
    48.251598
  ],
  [
    85.6621598,
    48.2509408
  ],
  [
    85.6621169,
    48.2476544
  ],
  [
    85.6594132,
    48.2418242
  ],
  [
    85.656967,
    48.2415384
  ],
  [
    85.6507014,
    48.2464827
  ],
  [
    85.6488989,
    48.2453682
  ],
  [
    85.6510447,
    48.2420529
  ],
  [
    85.6447524,
    48.2366104
  ],
  [
    85.6371401,
    48.2338781
  ],
  [
    85.6326769,
    48.2334779
  ],
  [
    85.6326769,
    48.2280749
  ],
  [
    85.6352948,
    48.2278747
  ],
  [
    85.6342648,
    48.2258449
  ],
  [
    85.6313036,
    48.2237006
  ],
  [
    85.6267089,
    48.2159958
  ],
  [
    85.6266688,
    48.211891
  ],
  [
    85.6227635,
    48.2100893
  ],
  [
    85.6237076,
    48.20374
  ],
  [
    85.623536,
    48.2027389
  ],
  [
    85.6216477,
    48.2026817
  ],
  [
    85.6186007,
    48.2025387
  ],
  [
    85.6165408,
    48.2039402
  ],
  [
    85.6139444,
    48.2048269
  ],
  [
    85.6107687,
    48.2051558
  ],
  [
    85.6072711,
    48.2044551
  ],
  [
    85.6067346,
    48.2036256
  ],
  [
    85.6070565,
    48.2027532
  ],
  [
    85.6063699,
    48.2022097
  ],
  [
    85.6034731,
    48.2020095
  ],
  [
    85.6033658,
    48.2011371
  ],
  [
    85.6026791,
    48.1998213
  ],
  [
    85.60225,
    48.1992921
  ],
  [
    85.6014346,
    48.1994495
  ],
  [
    85.6011127,
    48.1991348
  ],
  [
    85.6012415,
    48.1983053
  ],
  [
    85.5985163,
    48.1980764
  ],
  [
    85.5936455,
    48.1961884
  ],
  [
    85.5927013,
    48.1961884
  ],
  [
    85.5899118,
    48.1967748
  ],
  [
    85.5885814,
    48.1965603
  ],
  [
    85.5889462,
    48.1957164
  ],
  [
    85.5924009,
    48.1945578
  ],
  [
    85.5921434,
    48.1937854
  ],
  [
    85.5904053,
    48.1899661
  ],
  [
    85.5887746,
    48.1901521
  ],
  [
    85.5881094,
    48.1915825
  ],
  [
    85.586779,
    48.1918257
  ],
  [
    85.5834316,
    48.1903809
  ],
  [
    85.5834101,
    48.1896228
  ],
  [
    85.5849551,
    48.187105
  ],
  [
    85.5834531,
    48.1854312
  ],
  [
    85.5847405,
    48.18463
  ],
  [
    85.5871867,
    48.184673
  ],
  [
    85.586264,
    48.1827845
  ],
  [
    85.586543,
    48.1818974
  ],
  [
    85.5851911,
    48.1815111
  ],
  [
    85.5846761,
    48.1795224
  ],
  [
    85.5828093,
    48.1783491
  ],
  [
    85.5850195,
    48.1772903
  ],
  [
    85.5846547,
    48.1767895
  ],
  [
    85.5830239,
    48.1765891
  ],
  [
    85.5828737,
    48.1756448
  ],
  [
    85.5822729,
    48.175888
  ],
  [
    85.5804919,
    48.1750867
  ],
  [
    85.5802559,
    48.1742425
  ],
  [
    85.5832814,
    48.1729832
  ],
  [
    85.5835174,
    48.1717239
  ],
  [
    85.5820584,
    48.1703931
  ],
  [
    85.581758,
    48.1690908
  ],
  [
    85.5812859,
    48.1675166
  ],
  [
    85.5795908,
    48.167259
  ],
  [
    85.579419,
    48.1665863
  ],
  [
    85.5776596,
    48.1664862
  ],
  [
    85.576415,
    48.1659137
  ],
  [
    85.5772733,
    48.1655273
  ],
  [
    85.5773163,
    48.1639959
  ],
  [
    85.5752563,
    48.1615484
  ],
  [
    85.5751061,
    48.159745
  ],
  [
    85.5741833,
    48.159201
  ],
  [
    85.574001,
    48.1583494
  ],
  [
    85.5742717,
    48.1574732
  ],
  [
    85.5745271,
    48.1570739
  ],
  [
    85.5744755,
    48.1567782
  ],
  [
    85.5742412,
    48.1566041
  ],
  [
    85.573798,
    48.1566037
  ],
  [
    85.5734328,
    48.1566904
  ],
  [
    85.5730672,
    48.1570031
  ],
  [
    85.5725451,
    48.1573679
  ],
  [
    85.5718931,
    48.1574891
  ],
  [
    85.5713713,
    48.15768
  ],
  [
    85.5709282,
    48.1576274
  ],
  [
    85.5706941,
    48.1573489
  ],
  [
    85.5708509,
    48.1571752
  ],
  [
    85.571242,
    48.1571581
  ],
  [
    85.5715028,
    48.1571062
  ],
  [
    85.5714509,
    48.1569844
  ],
  [
    85.5711381,
    48.1569319
  ],
  [
    85.5710341,
    48.1567927
  ],
  [
    85.5705653,
    48.1565488
  ],
  [
    85.5702266,
    48.1564616
  ],
  [
    85.5697832,
    48.1565655
  ],
  [
    85.5696003,
    48.1567567
  ],
  [
    85.5696521,
    48.1569133
  ],
  [
    85.5698602,
    48.1571743
  ],
  [
    85.5697556,
    48.1573307
  ],
  [
    85.5694427,
    48.1573826
  ],
  [
    85.5690777,
    48.1573649
  ],
  [
    85.568556,
    48.1574862
  ],
  [
    85.568217,
    48.1575207
  ],
  [
    85.5679825,
    48.1574857
  ],
  [
    85.5679047,
    48.157277
  ],
  [
    85.5677226,
    48.1570681
  ],
  [
    85.567697,
    48.1568246
  ],
  [
    85.5678801,
    48.1565291
  ],
  [
    85.5676981,
    48.1562507
  ],
  [
    85.5673073,
    48.156146
  ],
  [
    85.5671514,
    48.1559023
  ],
  [
    85.5672301,
    48.1556242
  ],
  [
    85.5672827,
    48.1553807
  ],
  [
    85.5669449,
    48.1548413
  ],
  [
    85.566503,
    48.1542148
  ],
  [
    85.5661134,
    48.1534839
  ],
  [
    85.5656447,
    48.1531878
  ],
  [
    85.5650974,
    48.1531004
  ],
  [
    85.5644979,
    48.1530477
  ],
  [
    85.5637418,
    48.1530817
  ],
  [
    85.5630275,
    48.1529978
  ],
  [
    85.5625326,
    48.152806
  ],
  [
    85.5621685,
    48.1524057
  ],
  [
    85.5620652,
    48.1519012
  ],
  [
    85.5623267,
    48.1515188
  ],
  [
    85.5626659,
    48.1513626
  ],
  [
    85.5631876,
    48.1512066
  ],
  [
    85.563683,
    48.1511722
  ],
  [
    85.56397,
    48.1510682
  ],
  [
    85.5641792,
    48.1507379
  ],
  [
    85.564389,
    48.150112
  ],
  [
    85.5643638,
    48.1496946
  ],
  [
    85.5641299,
    48.1493291
  ],
  [
    85.5638695,
    48.1491898
  ],
  [
    85.5634262,
    48.1492937
  ],
  [
    85.5628262,
    48.1495018
  ],
  [
    85.5621996,
    48.1499708
  ],
  [
    85.5612344,
    48.1503178
  ],
  [
    85.5604784,
    48.1503171
  ],
  [
    85.5600096,
    48.1501079
  ],
  [
    85.5593333,
    48.1494464
  ],
  [
    85.559282,
    48.1490463
  ],
  [
    85.5595701,
    48.1484205
  ],
  [
    85.5600408,
    48.1477079
  ],
  [
    85.5604847,
    48.1473431
  ],
  [
    85.5613199,
    48.1468569
  ],
  [
    85.5618937,
    48.1467009
  ],
  [
    85.5624933,
    48.1466493
  ],
  [
    85.5630667,
    48.1466672
  ],
  [
    85.5633016,
    48.1465283
  ],
  [
    85.5634324,
    48.1463023
  ],
  [
    85.5633285,
    48.1461109
  ],
  [
    85.5629898,
    48.1460236
  ],
  [
    85.5626254,
    48.1457798
  ],
  [
    85.5624697,
    48.145484
  ],
  [
    85.5622092,
    48.1454142
  ],
  [
    85.5620786,
    48.145501
  ],
  [
    85.5618964,
    48.1453965
  ],
  [
    85.5617408,
    48.1450137
  ],
  [
    85.5612204,
    48.1445958
  ],
  [
    85.5608557,
    48.1445085
  ],
  [
    85.5602296,
    48.1447341
  ],
  [
    85.5602032,
    48.144908
  ],
  [
    85.5603853,
    48.1450647
  ],
  [
    85.5603067,
    48.1452907
  ],
  [
    85.5601831,
    48.1453684
  ],
  [
    85.5595058,
    48.1451591
  ],
  [
    85.5588021,
    48.1451584
  ],
  [
    85.5582026,
    48.1451752
  ],
  [
    85.5575778,
    48.1448268
  ],
  [
    85.557318,
    48.1444439
  ],
  [
    85.5572666,
    48.1441134
  ],
  [
    85.5570588,
    48.1437828
  ],
  [
    85.5564858,
    48.1435909
  ],
  [
    85.556304,
    48.1433125
  ],
  [
    85.5562786,
    48.142982
  ],
  [
    85.5562537,
    48.1424429
  ],
  [
    85.5557074,
    48.1419727
  ],
  [
    85.5547711,
    48.1411022
  ],
  [
    85.5539652,
    48.140197
  ],
  [
    85.5536021,
    48.1393966
  ],
  [
    85.5533959,
    48.1383529
  ],
  [
    85.5535462,
    48.1377177
  ],
  [
    85.5540691,
    48.1370051
  ],
  [
    85.5548786,
    48.1363276
  ],
  [
    85.5556092,
    48.1359109
  ],
  [
    85.5568348,
    48.1355643
  ],
  [
    85.5573301,
    48.1355126
  ],
  [
    85.5579553,
    48.1356002
  ],
  [
    85.5584243,
    48.1356354
  ],
  [
    85.5587632,
    48.1356009
  ],
  [
    85.5591025,
    48.1353578
  ],
  [
    85.5592598,
    48.1349232
  ],
  [
    85.5595733,
    48.1345408
  ],
  [
    85.5601993,
    48.1342979
  ],
  [
    85.5609815,
    48.1340726
  ],
  [
    85.5618161,
    48.1337429
  ],
  [
    85.562911,
    48.1335179
  ],
  [
    85.5635634,
    48.1331011
  ],
  [
    85.5640069,
    48.1328232
  ],
  [
    85.5639554,
    48.1325623
  ],
  [
    85.5637475,
    48.1322664
  ],
  [
    85.5632266,
    48.1321442
  ],
  [
    85.5625489,
    48.1322479
  ],
  [
    85.5616108,
    48.1322123
  ],
  [
    85.5609074,
    48.132142
  ],
  [
    85.5605953,
    48.1318809
  ],
  [
    85.559619,
    48.1319783
  ],
  [
    85.5591761,
    48.1319257
  ],
  [
    85.5588114,
    48.1318732
  ],
  [
    85.5585513,
    48.1316816
  ],
  [
    85.5585779,
    48.1314381
  ],
  [
    85.5585785,
    48.1311425
  ],
  [
    85.5588656,
    48.1309167
  ],
  [
    85.558736,
    48.1306209
  ],
  [
    85.5585021,
    48.130325
  ],
  [
    85.5586328,
    48.1301164
  ],
  [
    85.5586332,
    48.1299599
  ],
  [
    85.5587915,
    48.1290209
  ],
  [
    85.558688,
    48.1286904
  ],
  [
    85.5588968,
    48.1285514
  ],
  [
    85.5593409,
    48.1279779
  ],
  [
    85.5592372,
    48.1277691
  ],
  [
    85.5591856,
    48.1275256
  ],
  [
    85.5589772,
    48.127508
  ],
  [
    85.5583518,
    48.1275422
  ],
  [
    85.5581167,
    48.1277854
  ],
  [
    85.5576998,
    48.1278024
  ],
  [
    85.557361,
    48.1278195
  ],
  [
    85.5569964,
    48.1277496
  ],
  [
    85.5569186,
    48.1275582
  ],
  [
    85.5571016,
    48.1272801
  ],
  [
    85.5575191,
    48.1270196
  ],
  [
    85.5577283,
    48.1266894
  ],
  [
    85.5581979,
    48.1263768
  ],
  [
    85.5585113,
    48.1260814
  ],
  [
    85.5585379,
    48.1258206
  ],
  [
    85.5583558,
    48.1256813
  ],
  [
    85.5580692,
    48.125681
  ],
  [
    85.5577826,
    48.1256633
  ],
  [
    85.5573134,
    48.1257672
  ],
  [
    85.5567661,
    48.1258537
  ],
  [
    85.5563233,
    48.1257837
  ],
  [
    85.5558542,
    48.1258006
  ],
  [
    85.5556974,
    48.1260265
  ],
  [
    85.5555669,
    48.1261134
  ],
  [
    85.5552282,
    48.1261304
  ],
  [
    85.5545243,
    48.1263036
  ],
  [
    85.5545498,
    48.1265645
  ],
  [
    85.5547312,
    48.1270169
  ],
  [
    85.5547305,
    48.1272952
  ],
  [
    85.5547816,
    48.1277648
  ],
  [
    85.5549371,
    48.1281476
  ],
  [
    85.5548577,
    48.1286866
  ],
  [
    85.5545443,
    48.1290167
  ],
  [
    85.5540746,
    48.1293293
  ],
  [
    85.5536053,
    48.1294332
  ],
  [
    85.5531884,
    48.1294154
  ],
  [
    85.5526679,
    48.1291366
  ],
  [
    85.5522519,
    48.1287362
  ],
  [
    85.5522264,
    48.1284927
  ],
  [
    85.5524874,
    48.128319
  ],
  [
    85.5526962,
    48.1281627
  ],
  [
    85.5525924,
    48.1279887
  ],
  [
    85.5521493,
    48.1280578
  ],
  [
    85.5517843,
    48.1281444
  ],
  [
    85.551523,
    48.1284572
  ],
  [
    85.5517829,
    48.1287531
  ],
  [
    85.5517563,
    48.1289965
  ],
  [
    85.5513388,
    48.1292222
  ],
  [
    85.5509219,
    48.1292392
  ],
  [
    85.5505052,
    48.1291344
  ],
  [
    85.5504537,
    48.1288735
  ],
  [
    85.5505847,
    48.1285605
  ],
  [
    85.5499858,
    48.128386
  ],
  [
    85.5494915,
    48.1280551
  ],
  [
    85.5492841,
    48.1276026
  ],
  [
    85.5491558,
    48.1267503
  ],
  [
    85.5492079,
    48.1263204
  ],
  [
    85.5495228,
    48.1254163
  ],
  [
    85.5499679,
    48.124495
  ],
  [
    85.550336,
    48.1230344
  ],
  [
    85.550547,
    48.1219564
  ],
  [
    85.5505487,
    48.1211911
  ],
  [
    85.550368,
    48.1204953
  ],
  [
    85.5495357,
    48.1199031
  ],
  [
    85.5495372,
    48.1192422
  ],
  [
    85.5498501,
    48.1191382
  ],
  [
    85.5503455,
    48.1189474
  ],
  [
    85.5506593,
    48.1184434
  ],
  [
    85.5508423,
    48.1181653
  ],
  [
    85.5511821,
    48.1176613
  ],
  [
    85.5515025,
    48.1169892
  ],
  [
    85.5514776,
    48.1164674
  ],
  [
    85.551427,
    48.1158412
  ],
  [
    85.5515324,
    48.1153022
  ],
  [
    85.5514032,
    48.1148325
  ],
  [
    85.5507785,
    48.1146579
  ],
  [
    85.5500751,
    48.1146572
  ],
  [
    85.5493714,
    48.1148304
  ],
  [
    85.54872,
    48.1148993
  ],
  [
    85.5480164,
    48.1149855
  ],
  [
    85.5473909,
    48.1151066
  ],
  [
    85.5470005,
    48.114967
  ],
  [
    85.5468188,
    48.114706
  ],
  [
    85.5468974,
    48.1145147
  ],
  [
    85.5472103,
    48.1143933
  ],
  [
    85.5475753,
    48.114272
  ],
  [
    85.5479409,
    48.1138723
  ],
  [
    85.5486199,
    48.1131426
  ],
  [
    85.5490899,
    48.1126735
  ],
  [
    85.5496904,
    48.1120654
  ],
  [
    85.5499522,
    48.1115266
  ],
  [
    85.5499531,
    48.1111092
  ],
  [
    85.5497193,
    48.110848
  ],
  [
    85.5497199,
    48.1106046
  ],
  [
    85.5499547,
    48.1104135
  ],
  [
    85.5503747,
    48.1102513
  ],
  [
    85.5508702,
    48.1099909
  ],
  [
    85.5508969,
    48.10973
  ],
  [
    85.5506633,
    48.109382
  ],
  [
    85.5502989,
    48.1092772
  ],
  [
    85.5496224,
    48.1089287
  ],
  [
    85.5489986,
    48.1083889
  ],
  [
    85.5490521,
    48.1077802
  ],
  [
    85.5493391,
    48.1075718
  ],
  [
    85.5496517,
    48.1075722
  ],
  [
    85.5498859,
    48.1076594
  ],
  [
    85.5499374,
    48.1078855
  ],
  [
    85.5503016,
    48.1080946
  ],
  [
    85.550614,
    48.1081645
  ],
  [
    85.5513698,
    48.1079565
  ],
  [
    85.5518909,
    48.1078875
  ],
  [
    85.5523596,
    48.1079402
  ],
  [
    85.5528024,
    48.1079232
  ],
  [
    85.5532195,
    48.1077845
  ],
  [
    85.5534281,
    48.1076456
  ],
  [
    85.5537412,
    48.1074198
  ],
  [
    85.5545759,
    48.1068641
  ],
  [
    85.5545767,
    48.1065337
  ],
  [
    85.5544733,
    48.1061857
  ],
  [
    85.5542135,
    48.1058724
  ],
  [
    85.5536665,
    48.1058719
  ],
  [
    85.5530669,
    48.1061495
  ],
  [
    85.5525456,
    48.1063229
  ],
  [
    85.5513735,
    48.1063391
  ],
  [
    85.5509304,
    48.1064778
  ],
  [
    85.5502003,
    48.1068423
  ],
  [
    85.5496271,
    48.1069286
  ],
  [
    85.5490805,
    48.1067889
  ],
  [
    85.5485862,
    48.1065275
  ],
  [
    85.5486649,
    48.1063015
  ],
  [
    85.5486914,
    48.1060929
  ],
  [
    85.5489004,
    48.1058322
  ],
  [
    85.5490833,
    48.1055889
  ],
  [
    85.5496308,
    48.105346
  ],
  [
    85.5497619,
    48.1049635
  ],
  [
    85.5497367,
    48.1045983
  ],
  [
    85.5494771,
    48.104285
  ],
  [
    85.5490865,
    48.1042324
  ],
  [
    85.5487217,
    48.1043537
  ],
  [
    85.5485128,
    48.1045274
  ],
  [
    85.548278,
    48.1047185
  ],
  [
    85.5479389,
    48.1049268
  ],
  [
    85.5478604,
    48.1050833
  ],
  [
    85.5479118,
    48.1053616
  ],
  [
    85.5478074,
    48.1054658
  ],
  [
    85.5475729,
    48.1055004
  ],
  [
    85.5471302,
    48.1054999
  ],
  [
    85.5468432,
    48.1057083
  ],
  [
    85.5464,
    48.1058817
  ],
  [
    85.5459834,
    48.1058291
  ],
  [
    85.5452288,
    48.1055153
  ],
  [
    85.5449432,
    48.1051845
  ],
  [
    85.5449698,
    48.1049237
  ],
  [
    85.5452046,
    48.10475
  ],
  [
    85.5456476,
    48.1046809
  ],
  [
    85.5457002,
    48.1044723
  ],
  [
    85.5454663,
    48.1042807
  ],
  [
    85.545545,
    48.1040025
  ],
  [
    85.5459625,
    48.1036725
  ],
  [
    85.5466928,
    48.1032559
  ],
  [
    85.5472663,
    48.1030304
  ],
  [
    85.5479438,
    48.1028398
  ],
  [
    85.5486216,
    48.1025797
  ],
  [
    85.5489607,
    48.1023365
  ],
  [
    85.549144,
    48.1019019
  ],
  [
    85.5491186,
    48.101641
  ],
  [
    85.5488585,
    48.1014842
  ],
  [
    85.5483377,
    48.1014663
  ],
  [
    85.5480721,
    48.1014114
  ],
  [
    85.5476299,
    48.1011848
  ],
  [
    85.5470061,
    48.1006798
  ],
  [
    85.5464863,
    48.100227
  ],
  [
    85.5461225,
    48.0999136
  ],
  [
    85.5451338,
    48.0995125
  ],
  [
    85.5435477,
    48.0985194
  ],
  [
    85.5432619,
    48.0982235
  ],
  [
    85.5432367,
    48.0979104
  ],
  [
    85.5434458,
    48.0975976
  ],
  [
    85.5438371,
    48.0973023
  ],
  [
    85.5441761,
    48.0971114
  ],
  [
    85.5445666,
    48.097164
  ],
  [
    85.5448798,
    48.096886
  ],
  [
    85.5452975,
    48.0964865
  ],
  [
    85.5454546,
    48.0960866
  ],
  [
    85.5457418,
    48.0958087
  ],
  [
    85.5460283,
    48.0957742
  ],
  [
    85.5463141,
    48.096018
  ],
  [
    85.5468609,
    48.096036
  ],
  [
    85.5481369,
    48.0960547
  ],
  [
    85.5489702,
    48.0960208
  ],
  [
    85.5496477,
    48.0958302
  ],
  [
    85.5500912,
    48.0955002
  ],
  [
    85.5500917,
    48.0952567
  ],
  [
    85.5498057,
    48.0950825
  ],
  [
    85.5495723,
    48.0946475
  ],
  [
    85.5493907,
    48.094369
  ],
  [
    85.5494182,
    48.0937951
  ],
  [
    85.5492636,
    48.093219
  ],
  [
    85.5487693,
    48.0930098
  ],
  [
    85.5483789,
    48.0929051
  ],
  [
    85.54778,
    48.092887
  ],
  [
    85.5471551,
    48.0929038
  ],
  [
    85.5466344,
    48.0928684
  ],
  [
    85.5462963,
    48.0926942
  ],
  [
    85.5463491,
    48.0924333
  ],
  [
    85.5461409,
    48.0923461
  ],
  [
    85.5457766,
    48.0922588
  ],
  [
    85.5456989,
    48.0920674
  ],
  [
    85.5458564,
    48.0915632
  ],
  [
    85.5457267,
    48.0913717
  ],
  [
    85.5454408,
    48.0911454
  ],
  [
    85.5453632,
    48.0909366
  ],
  [
    85.5452603,
    48.0904321
  ],
  [
    85.5452096,
    48.0898581
  ],
  [
    85.5450808,
    48.089284
  ],
  [
    85.5451335,
    48.0890058
  ],
  [
    85.5455253,
    48.0885193
  ],
  [
    85.5459946,
    48.0882241
  ],
  [
    85.5462553,
    48.0881027
  ],
  [
    85.5462297,
    48.0878939
  ],
  [
    85.5458398,
    48.08765
  ],
  [
    85.5454501,
    48.0873018
  ],
  [
    85.5453732,
    48.0868147
  ],
  [
    85.545323,
    48.086032
  ],
  [
    85.5454612,
    48.0853548
  ],
  [
    85.5451513,
    48.0843283
  ],
  [
    85.5447366,
    48.0835452
  ],
  [
    85.5447377,
    48.083093
  ],
  [
    85.5449213,
    48.082554
  ],
  [
    85.5452609,
    48.0820674
  ],
  [
    85.5458087,
    48.0815984
  ],
  [
    85.5463302,
    48.0812338
  ],
  [
    85.5469562,
    48.0807301
  ],
  [
    85.5476336,
    48.0805047
  ],
  [
    85.5481803,
    48.0804705
  ],
  [
    85.5483374,
    48.0800881
  ],
  [
    85.5483383,
    48.0797402
  ],
  [
    85.5482872,
    48.0793228
  ],
  [
    85.5478716,
    48.0789397
  ],
  [
    85.5475075,
    48.0788002
  ],
  [
    85.5469869,
    48.0787997
  ],
  [
    85.5467004,
    48.0788515
  ],
  [
    85.5464139,
    48.0789034
  ],
  [
    85.5456334,
    48.0787112
  ],
  [
    85.5446965,
    48.0786407
  ],
  [
    85.5433432,
    48.0785174
  ],
  [
    85.5425887,
    48.0783427
  ],
  [
    85.5421208,
    48.0780639
  ],
  [
    85.5411656,
    48.0778033
  ],
  [
    85.5403592,
    48.0775763
  ],
  [
    85.5398915,
    48.0772279
  ],
  [
    85.5396318,
    48.0769667
  ],
  [
    85.5397368,
    48.076619
  ],
  [
    85.540024,
    48.0763063
  ],
  [
    85.5404932,
    48.0760459
  ],
  [
    85.5409885,
    48.0757508
  ],
  [
    85.5413799,
    48.0753339
  ],
  [
    85.5416151,
    48.0749689
  ],
  [
    85.5416162,
    48.0745341
  ],
  [
    85.5415128,
    48.0742557
  ],
  [
    85.5411487,
    48.0741336
  ],
  [
    85.5408629,
    48.0739071
  ],
  [
    85.5403944,
    48.0739066
  ],
  [
    85.5400037,
    48.0740105
  ],
  [
    85.5397433,
    48.0740798
  ],
  [
    85.5382605,
    48.0737303
  ],
  [
    85.5377406,
    48.0734688
  ],
  [
    85.5374296,
    48.0729641
  ],
  [
    85.5364939,
    48.0724239
  ],
  [
    85.5355582,
    48.0719358
  ],
  [
    85.5350903,
    48.0717092
  ],
  [
    85.5347529,
    48.0713436
  ],
  [
    85.53452,
    48.0708216
  ],
  [
    85.5345731,
    48.0704216
  ],
  [
    85.5349387,
    48.0699351
  ],
  [
    85.535408,
    48.0696226
  ],
  [
    85.5357726,
    48.0695534
  ],
  [
    85.5363189,
    48.0696584
  ],
  [
    85.5369956,
    48.0696592
  ],
  [
    85.5377767,
    48.069521
  ],
  [
    85.5384278,
    48.0693478
  ],
  [
    85.5388189,
    48.0691047
  ],
  [
    85.5392365,
    48.0686356
  ],
  [
    85.5395755,
    48.0683751
  ],
  [
    85.5397063,
    48.0681144
  ],
  [
    85.5396289,
    48.067836
  ],
  [
    85.5394409,
    48.0674617
  ],
  [
    85.5389735,
    48.0670611
  ],
  [
    85.5386355,
    48.0669042
  ],
  [
    85.5382191,
    48.0669037
  ],
  [
    85.5378812,
    48.0667468
  ],
  [
    85.5378037,
    48.066538
  ],
  [
    85.5378047,
    48.0661554
  ],
  [
    85.5373625,
    48.0660505
  ],
  [
    85.5371806,
    48.0659286
  ],
  [
    85.5372595,
    48.0656156
  ],
  [
    85.5375207,
    48.0652681
  ],
  [
    85.5378857,
    48.0649902
  ],
  [
    85.5383545,
    48.0648516
  ],
  [
    85.5387708,
    48.0648869
  ],
  [
    85.5394731,
    48.0650442
  ],
  [
    85.5400714,
    48.0651318
  ],
  [
    85.5404358,
    48.0651149
  ],
  [
    85.5407744,
    48.0650109
  ],
  [
    85.5408269,
    48.0648196
  ],
  [
    85.5411398,
    48.0645591
  ],
  [
    85.5411926,
    48.0642635
  ],
  [
    85.5410112,
    48.063985
  ],
  [
    85.540908,
    48.0636023
  ],
  [
    85.5410645,
    48.0634633
  ],
  [
    85.541329,
    48.0634391
  ],
  [
    85.5416346,
    48.0634697
  ],
  [
    85.541895,
    48.0634322
  ],
  [
    85.5421218,
    48.0632584
  ],
  [
    85.5423374,
    48.0630091
  ],
  [
    85.5424966,
    48.0627142
  ],
  [
    85.5425311,
    48.0625025
  ],
  [
    85.5424523,
    48.062336
  ],
  [
    85.542243,
    48.0621785
  ],
  [
    85.5419829,
    48.062095
  ],
  [
    85.5416773,
    48.0621022
  ],
  [
    85.5412927,
    48.0620413
  ],
  [
    85.5410663,
    48.062041
  ],
  [
    85.5408738,
    48.0620938
  ],
  [
    85.5404888,
    48.0621463
  ],
  [
    85.5402624,
    48.0621612
  ],
  [
    85.5401155,
    48.0620702
  ],
  [
    85.5401955,
    48.0617677
  ],
  [
    85.5401733,
    48.0615862
  ],
  [
    85.5402302,
    48.0614652
  ],
  [
    85.5404004,
    48.0613066
  ],
  [
    85.5406609,
    48.0612009
  ],
  [
    85.5407631,
    48.0610952
  ],
  [
    85.5407407,
    48.0609817
  ],
  [
    85.5405484,
    48.0609436
  ],
  [
    85.5403119,
    48.0604744
  ],
  [
    85.5402105,
    48.0603003
  ],
  [
    85.5398372,
    48.0602394
  ],
  [
    85.5397354,
    48.0601863
  ],
  [
    85.5397243,
    48.0601031
  ],
  [
    85.5396113,
    48.0600273
  ],
  [
    85.5395211,
    48.0599138
  ],
  [
    85.539329,
    48.0597698
  ],
  [
    85.5390695,
    48.0594594
  ],
  [
    85.5390359,
    48.0593383
  ],
  [
    85.5389342,
    48.0592474
  ],
  [
    85.5387871,
    48.0592473
  ],
  [
    85.5386741,
    48.0591942
  ],
  [
    85.5386742,
    48.0591412
  ],
  [
    85.5388101,
    48.059096
  ],
  [
    85.5389127,
    48.0589228
  ],
  [
    85.5389132,
    48.058711
  ],
  [
    85.5389486,
    48.0581362
  ],
  [
    85.5390173,
    48.0578337
  ],
  [
    85.5390517,
    48.0576371
  ],
  [
    85.5389729,
    48.0574706
  ],
  [
    85.5390186,
    48.0572966
  ],
  [
    85.5391776,
    48.0571002
  ],
  [
    85.5393476,
    48.0569793
  ],
  [
    85.5394496,
    48.0569416
  ],
  [
    85.53954,
    48.0570098
  ],
  [
    85.5397324,
    48.0569722
  ],
  [
    85.5398233,
    48.0568361
  ],
  [
    85.539948,
    48.0567606
  ],
  [
    85.5401065,
    48.056723
  ],
  [
    85.5402423,
    48.0567534
  ],
  [
    85.5404461,
    48.0567007
  ],
  [
    85.5404915,
    48.0566327
  ],
  [
    85.5404125,
    48.0565569
  ],
  [
    85.5403336,
    48.0564434
  ],
  [
    85.5402546,
    48.0563298
  ],
  [
    85.5398928,
    48.0562008
  ],
  [
    85.5397347,
    48.0560645
  ],
  [
    85.5395652,
    48.0559887
  ],
  [
    85.5395655,
    48.0558525
  ],
  [
    85.5397128,
    48.0557846
  ],
  [
    85.5398828,
    48.0556789
  ],
  [
    85.5401094,
    48.0555959
  ],
  [
    85.5404268,
    48.0553845
  ],
  [
    85.5407438,
    48.0553168
  ],
  [
    85.5408798,
    48.0552337
  ],
  [
    85.5408461,
    48.0551429
  ],
  [
    85.5409821,
    48.0550447
  ],
  [
    85.5411853,
    48.0549202
  ],
  [
    85.5414233,
    48.0547843
  ],
  [
    85.5415707,
    48.0547012
  ],
  [
    85.5418317,
    48.0543763
  ],
  [
    85.5418892,
    48.0540208
  ],
  [
    85.5419019,
    48.0534611
  ],
  [
    85.5418232,
    48.0532492
  ],
  [
    85.5415746,
    48.0531279
  ],
  [
    85.5415069,
    48.0530295
  ],
  [
    85.5412357,
    48.0528854
  ],
  [
    85.5410549,
    48.0527869
  ],
  [
    85.5408512,
    48.0527715
  ],
  [
    85.5407495,
    48.0527034
  ],
  [
    85.5406594,
    48.052552
  ],
  [
    85.5406486,
    48.0523553
  ],
  [
    85.5407849,
    48.0521512
  ],
  [
    85.5407837,
    48.0519556
  ],
  [
    85.5407165,
    48.0516605
  ],
  [
    85.5399369,
    48.0511831
  ],
  [
    85.5394958,
    48.0511145
  ],
  [
    85.5393373,
    48.0511294
  ],
  [
    85.5389753,
    48.0511215
  ],
  [
    85.5387376,
    48.0511439
  ],
  [
    85.5383187,
    48.0512266
  ],
  [
    85.5378322,
    48.0511731
  ],
  [
    85.53764,
    48.0511124
  ],
  [
    85.5375386,
    48.050961
  ],
  [
    85.5375278,
    48.0507567
  ],
  [
    85.5374941,
    48.0506432
  ],
  [
    85.5374944,
    48.0505373
  ],
  [
    85.5375737,
    48.0504845
  ],
  [
    85.5377662,
    48.0504317
  ],
  [
    85.5379248,
    48.0503865
  ],
  [
    85.5381171,
    48.0504019
  ],
  [
    85.5385925,
    48.0503344
  ],
  [
    85.5386605,
    48.050289
  ],
  [
    85.5386043,
    48.0501528
  ],
  [
    85.5385716,
    48.049646
  ],
  [
    85.5383688,
    48.0493205
  ],
  [
    85.5380863,
    48.0491689
  ],
  [
    85.5378601,
    48.0491308
  ],
  [
    85.5375885,
    48.0491456
  ],
  [
    85.5369432,
    48.0492508
  ],
  [
    85.5365582,
    48.0493714
  ],
  [
    85.5360596,
    48.0496582
  ],
  [
    85.5357652,
    48.049726
  ],
  [
    85.535256,
    48.0497405
  ],
  [
    85.534962,
    48.0496494
  ],
  [
    85.5347701,
    48.0494525
  ],
  [
    85.5345985,
    48.0492442
  ],
  [
    85.5345763,
    48.0490627
  ],
  [
    85.5346899,
    48.0489267
  ],
  [
    85.5348939,
    48.0487907
  ],
  [
    85.5351204,
    48.0487229
  ],
  [
    85.5355733,
    48.0486175
  ],
  [
    85.5357432,
    48.0485345
  ],
  [
    85.5358793,
    48.0484137
  ],
  [
    85.5359249,
    48.0483078
  ],
  [
    85.5358573,
    48.0481716
  ],
  [
    85.5358349,
    48.0480959
  ],
  [
    85.535903,
    48.0480203
  ],
  [
    85.5359936,
    48.0479751
  ],
  [
    85.5364142,
    48.0472418
  ],
  [
    85.5365963,
    48.0468336
  ],
  [
    85.5366421,
    48.0466143
  ],
  [
    85.5365863,
    48.0462965
  ],
  [
    85.5366093,
    48.0461528
  ],
  [
    85.536621,
    48.0460242
  ],
  [
    85.5364518,
    48.0458274
  ],
  [
    85.5361649,
    48.0456521
  ],
  [
    85.5355998,
    48.0454245
  ],
  [
    85.5350008,
    48.0451818
  ],
  [
    85.5344241,
    48.0450298
  ],
  [
    85.5340851,
    48.0448706
  ],
  [
    85.5338821,
    48.0446056
  ],
  [
    85.5336562,
    48.0444616
  ],
  [
    85.53343,
    48.0444386
  ],
  [
    85.5330791,
    48.0445138
  ],
  [
    85.5326492,
    48.044468
  ],
  [
    85.5324229,
    48.0444904
  ],
  [
    85.5317214,
    48.0445047
  ],
  [
    85.5315171,
    48.0447238
  ],
  [
    85.5311093,
    48.0448973
  ],
  [
    85.530883,
    48.0449121
  ],
  [
    85.5304534,
    48.044783
  ],
  [
    85.530137,
    48.0446238
  ],
  [
    85.5300921,
    48.0445103
  ],
  [
    85.5300699,
    48.0443363
  ],
  [
    85.5298553,
    48.044215
  ],
  [
    85.529697,
    48.0441543
  ],
  [
    85.5295954,
    48.0440785
  ],
  [
    85.5294263,
    48.0438514
  ],
  [
    85.5292706,
    48.0435527
  ],
  [
    85.5292261,
    48.043303
  ],
  [
    85.5293169,
    48.0431669
  ],
  [
    85.5294416,
    48.0431066
  ],
  [
    85.5299396,
    48.0430467
  ],
  [
    85.5301659,
    48.0430091
  ],
  [
    85.530268,
    48.0429336
  ],
  [
    85.530246,
    48.0427066
  ],
  [
    85.530337,
    48.0425025
  ],
  [
    85.5303722,
    48.0420487
  ],
  [
    85.5304864,
    48.0416479
  ],
  [
    85.530487,
    48.0414437
  ],
  [
    85.5303969,
    48.0412999
  ],
  [
    85.5301597,
    48.0411483
  ],
  [
    85.5299338,
    48.0410119
  ],
  [
    85.5296743,
    48.0407468
  ],
  [
    85.5295396,
    48.0403306
  ],
  [
    85.5295338,
    48.0401593
  ],
  [
    85.5296022,
    48.0399854
  ],
  [
    85.5298178,
    48.0397511
  ],
  [
    85.5303049,
    48.0395021
  ],
  [
    85.5305882,
    48.0393209
  ],
  [
    85.5309389,
    48.0393289
  ],
  [
    85.5311993,
    48.0392611
  ],
  [
    85.5313014,
    48.039178
  ],
  [
    85.531313,
    48.0390646
  ],
  [
    85.531449,
    48.038974
  ],
  [
    85.531438,
    48.0388454
  ],
  [
    85.5311787,
    48.0385198
  ],
  [
    85.5309982,
    48.0383305
  ],
  [
    85.530637,
    48.0380577
  ],
  [
    85.5304337,
    48.0378986
  ],
  [
    85.5302877,
    48.0375127
  ],
  [
    85.5301075,
    48.0372477
  ],
  [
    85.5298481,
    48.03696
  ],
  [
    85.5295871,
    48.0366349
  ],
  [
    85.5293617,
    48.0363018
  ],
  [
    85.529114,
    48.0358855
  ],
  [
    85.5289684,
    48.0353331
  ],
  [
    85.5289238,
    48.0351061
  ],
  [
    85.5289809,
    48.0349246
  ],
  [
    85.5291513,
    48.0346601
  ],
  [
    85.5296164,
    48.0341614
  ],
  [
    85.5297979,
    48.0339952
  ],
  [
    85.5300356,
    48.033935
  ],
  [
    85.53059,
    48.0338903
  ],
  [
    85.5309746,
    48.033921
  ],
  [
    85.5313592,
    48.0339139
  ],
  [
    85.5319477,
    48.0338163
  ],
  [
    85.5321969,
    48.0336653
  ],
  [
    85.5321972,
    48.033567
  ],
  [
    85.5320618,
    48.0334306
  ],
  [
    85.5320282,
    48.0333247
  ],
  [
    85.5318706,
    48.0330219
  ],
  [
    85.5318146,
    48.0328025
  ],
  [
    85.5318496,
    48.0324016
  ],
  [
    85.5319199,
    48.0321661
  ],
  [
    85.5319884,
    48.0319317
  ],
  [
    85.5322496,
    48.0315159
  ],
  [
    85.5324766,
    48.0312212
  ],
  [
    85.5326133,
    48.0308885
  ],
  [
    85.532682,
    48.0305558
  ],
  [
    85.5325921,
    48.0303363
  ],
  [
    85.5322538,
    48.0299501
  ],
  [
    85.5319266,
    48.0296321
  ],
  [
    85.531577,
    48.0292686
  ],
  [
    85.5311366,
    48.0289881
  ],
  [
    85.5306849,
    48.0287229
  ],
  [
    85.5303572,
    48.0286014
  ],
  [
    85.5292592,
    48.0280004
  ],
  [
    85.5287849,
    48.0277351
  ],
  [
    85.5284007,
    48.0275909
  ],
  [
    85.527305,
    48.02706
  ],
  [
    85.5270342,
    48.0268555
  ],
  [
    85.5268428,
    48.0265148
  ],
  [
    85.526685,
    48.0263255
  ],
  [
    85.5265731,
    48.0258791
  ],
  [
    85.5266425,
    48.0253497
  ],
  [
    85.5265095,
    48.0251322
  ],
  [
    85.5264543,
    48.0246631
  ],
  [
    85.5268063,
    48.0238854
  ],
  [
    85.529814,
    48.0244728
  ],
  [
    85.5314759,
    48.023592
  ],
  [
    85.5333298,
    48.0228035
  ],
  [
    85.534873,
    48.0223525
  ],
  [
    85.5359726,
    48.0220162
  ],
  [
    85.536493,
    48.0219488
  ],
  [
    85.5369117,
    48.021866
  ],
  [
    85.5373531,
    48.0217001
  ],
  [
    85.5394803,
    48.0205887
  ],
  [
    85.5397075,
    48.0201805
  ],
  [
    85.5399114,
    48.0200521
  ],
  [
    85.5400023,
    48.0198631
  ],
  [
    85.539664,
    48.0194921
  ],
  [
    85.5398921,
    48.0187132
  ],
  [
    85.5401487,
    48.0182057
  ],
  [
    85.5404366,
    48.0176889
  ],
  [
    85.5412245,
    48.0167837
  ],
  [
    85.5419111,
    48.0143938
  ],
  [
    85.5423403,
    48.0131091
  ],
  [
    85.5425227,
    48.0115947
  ],
  [
    85.5427909,
    48.0093625
  ],
  [
    85.5401087,
    48.0045102
  ],
  [
    85.5392074,
    48.0003178
  ],
  [
    85.5384135,
    47.9984513
  ],
  [
    85.5391216,
    47.9963262
  ],
  [
    85.5373191,
    47.9920183
  ],
  [
    85.5382204,
    47.9900079
  ],
  [
    85.5390787,
    47.9884569
  ],
  [
    85.5394191,
    47.9879953
  ],
  [
    85.5394041,
    47.9876444
  ],
  [
    85.5395809,
    47.986826
  ],
  [
    85.539582,
    47.9864113
  ],
  [
    85.5394085,
    47.9859221
  ],
  [
    85.5394152,
    47.9856539
  ],
  [
    85.5397181,
    47.9852077
  ],
  [
    85.5403391,
    47.9846237
  ],
  [
    85.5414207,
    47.9840508
  ],
  [
    85.541993,
    47.98386
  ],
  [
    85.5430575,
    47.9837443
  ],
  [
    85.5434864,
    47.9837235
  ],
  [
    85.5439632,
    47.983607
  ],
  [
    85.5443608,
    47.9833736
  ],
  [
    85.5446631,
    47.9831932
  ],
  [
    85.5452196,
    47.9829493
  ],
  [
    85.5465232,
    47.9824616
  ],
  [
    85.5474043,
    47.9822237
  ],
  [
    85.5480051,
    47.978087
  ],
  [
    85.5496359,
    47.9653445
  ],
  [
    85.5518675,
    47.9518228
  ],
  [
    85.5592917,
    47.9031097
  ],
  [
    85.5620384,
    47.8856002
  ],
  [
    85.5714796,
    47.8237592
  ],
  [
    85.579376,
    47.7718682
  ],
  [
    85.5865858,
    47.7178466
  ],
  [
    85.5977438,
    47.6433019
  ],
  [
    85.6080705,
    47.58146
  ],
  [
    85.6140733,
    47.5414182
  ],
  [
    85.6142503,
    47.5404875
  ],
  [
    85.6143361,
    47.5400928
  ],
  [
    85.61422,
    47.5400064
  ],
  [
    85.6141839,
    47.5398692
  ],
  [
    85.614039,
    47.5396683
  ],
  [
    85.6138361,
    47.5394037
  ],
  [
    85.6137605,
    47.538981
  ],
  [
    85.6134852,
    47.5385155
  ],
  [
    85.6133984,
    47.5382803
  ],
  [
    85.6134205,
    47.5379667
  ],
  [
    85.6135589,
    47.5375553
  ],
  [
    85.6137262,
    47.5371978
  ],
  [
    85.6136393,
    47.5369773
  ],
  [
    85.6134509,
    47.5367959
  ],
  [
    85.6131752,
    47.5367125
  ],
  [
    85.6129055,
    47.5367162
  ],
  [
    85.6127531,
    47.5367308
  ],
  [
    85.612637,
    47.5367356
  ],
  [
    85.6125645,
    47.536667
  ],
  [
    85.6125284,
    47.5365053
  ],
  [
    85.612565,
    47.5361526
  ],
  [
    85.6124853,
    47.5360546
  ],
  [
    85.612217,
    47.5359075
  ],
  [
    85.6119267,
    47.5359073
  ],
  [
    85.6118252,
    47.5358387
  ],
  [
    85.6118253,
    47.5357407
  ],
  [
    85.6118981,
    47.5355595
  ],
  [
    85.6119346,
    47.5353978
  ],
  [
    85.6118767,
    47.5352214
  ],
  [
    85.6117173,
    47.5350009
  ],
  [
    85.6115578,
    47.5349126
  ],
  [
    85.6112821,
    47.5348341
  ],
  [
    85.6110717,
    47.5348095
  ],
  [
    85.6108396,
    47.53478
  ],
  [
    85.6106292,
    47.5347211
  ],
  [
    85.610506,
    47.5346034
  ],
  [
    85.6104336,
    47.5344515
  ],
  [
    85.6105135,
    47.5344075
  ],
  [
    85.610753,
    47.5343439
  ],
  [
    85.6108039,
    47.5342754
  ],
  [
    85.6108054,
    47.5341319
  ],
  [
    85.6105808,
    47.533828
  ],
  [
    85.6103705,
    47.5337348
  ],
  [
    85.6103198,
    47.5336711
  ],
  [
    85.6102691,
    47.5335437
  ],
  [
    85.6103563,
    47.533436
  ],
  [
    85.6104943,
    47.5333773
  ],
  [
    85.6104943,
    47.5333283
  ],
  [
    85.6103855,
    47.5332841
  ],
  [
    85.6100519,
    47.5331762
  ],
  [
    85.6096238,
    47.5332005
  ],
  [
    85.6094859,
    47.5332004
  ],
  [
    85.6093191,
    47.5331415
  ],
  [
    85.6092103,
    47.5330435
  ],
  [
    85.609167,
    47.5329161
  ],
  [
    85.609109,
    47.5328572
  ],
  [
    85.6091962,
    47.532725
  ],
  [
    85.6093196,
    47.5326516
  ],
  [
    85.6094867,
    47.532539
  ],
  [
    85.6097116,
    47.5325342
  ],
  [
    85.6098567,
    47.5325833
  ],
  [
    85.6100018,
    47.5325981
  ],
  [
    85.6101978,
    47.5325051
  ],
  [
    85.6102414,
    47.5324267
  ],
  [
    85.6101472,
    47.5322993
  ],
  [
    85.6100385,
    47.5322454
  ],
  [
    85.6098064,
    47.5321669
  ],
  [
    85.6096613,
    47.5321472
  ],
  [
    85.6095887,
    47.5321471
  ],
  [
    85.6094726,
    47.5321912
  ],
  [
    85.6093492,
    47.5322107
  ],
  [
    85.609204,
    47.5322302
  ],
  [
    85.6091242,
    47.5322694
  ],
  [
    85.6090734,
    47.5322302
  ],
  [
    85.6090808,
    47.5321616
  ],
  [
    85.6091389,
    47.5320881
  ],
  [
    85.6092188,
    47.5320147
  ],
  [
    85.6092624,
    47.5319314
  ],
  [
    85.6091756,
    47.5317109
  ],
  [
    85.6090825,
    47.531538
  ],
  [
    85.6090028,
    47.5313959
  ],
  [
    85.6089667,
    47.5313126
  ],
  [
    85.6089014,
    47.5312537
  ],
  [
    85.6088071,
    47.5312341
  ],
  [
    85.6087855,
    47.5311361
  ],
  [
    85.6087929,
    47.5310234
  ],
  [
    85.6088147,
    47.5309499
  ],
  [
    85.608989,
    47.530857
  ],
  [
    85.6091197,
    47.5307933
  ],
  [
    85.6091851,
    47.5307101
  ],
  [
    85.6090836,
    47.5306463
  ],
  [
    85.6089385,
    47.5305581
  ],
  [
    85.6088806,
    47.5304356
  ],
  [
    85.6088809,
    47.5302249
  ],
  [
    85.6089319,
    47.530078
  ],
  [
    85.6090481,
    47.5299213
  ],
  [
    85.6091861,
    47.5298626
  ],
  [
    85.6093239,
    47.529843
  ],
  [
    85.6094546,
    47.5298333
  ],
  [
    85.6095271,
    47.5297991
  ],
  [
    85.60952,
    47.5297109
  ],
  [
    85.609404,
    47.5295883
  ],
  [
    85.6093606,
    47.5294756
  ],
  [
    85.609397,
    47.5293728
  ],
  [
    85.6094843,
    47.5292307
  ],
  [
    85.6094928,
    47.5291538
  ],
  [
    85.6094125,
    47.5291018
  ],
  [
    85.6091937,
    47.5290946
  ],
  [
    85.6089437,
    47.5290242
  ],
  [
    85.6089333,
    47.5289468
  ],
  [
    85.6090377,
    47.5288483
  ],
  [
    85.6092253,
    47.5287569
  ],
  [
    85.6095067,
    47.5287571
  ],
  [
    85.6095798,
    47.5286445
  ],
  [
    85.6095799,
    47.5285249
  ],
  [
    85.609705,
    47.5284757
  ],
  [
    85.6097989,
    47.5283984
  ],
  [
    85.6097261,
    47.5282647
  ],
  [
    85.6097158,
    47.5281732
  ],
  [
    85.6098723,
    47.5280396
  ],
  [
    85.609914,
    47.5279693
  ],
  [
    85.6097683,
    47.5277933
  ],
  [
    85.6094665,
    47.5275398
  ],
  [
    85.6094249,
    47.5274554
  ],
  [
    85.6095083,
    47.5274062
  ],
  [
    85.6097272,
    47.5273007
  ],
  [
    85.6098524,
    47.5271812
  ],
  [
    85.609863,
    47.5270686
  ],
  [
    85.6096548,
    47.5268997
  ],
  [
    85.6096131,
    47.526773
  ],
  [
    85.6096134,
    47.5266323
  ],
  [
    85.6094779,
    47.5265829
  ],
  [
    85.6093738,
    47.5265125
  ],
  [
    85.6093427,
    47.5263436
  ],
  [
    85.6093741,
    47.5262452
  ],
  [
    85.6098432,
    47.5260977
  ],
  [
    85.6099059,
    47.5260062
  ],
  [
    85.6098748,
    47.525774
  ],
  [
    85.6097394,
    47.5256825
  ],
  [
    85.6094374,
    47.5255838
  ],
  [
    85.6092172,
    47.525503
  ],
  [
    85.6089258,
    47.5251932
  ],
  [
    85.6089364,
    47.5250736
  ],
  [
    85.6090511,
    47.5250103
  ],
  [
    85.6090824,
    47.524933
  ],
  [
    85.6090826,
    47.5248274
  ],
  [
    85.6089472,
    47.5247429
  ],
  [
    85.6088432,
    47.5246303
  ],
  [
    85.6087287,
    47.5245177
  ],
  [
    85.6084059,
    47.5243486
  ],
  [
    85.6083956,
    47.5242149
  ],
  [
    85.6079062,
    47.5239965
  ],
  [
    85.6078854,
    47.5239473
  ],
  [
    85.6079584,
    47.5238981
  ],
  [
    85.6080731,
    47.5238137
  ],
  [
    85.6080732,
    47.5237433
  ],
  [
    85.6077713,
    47.5235532
  ],
  [
    85.6077922,
    47.5234617
  ],
  [
    85.6079486,
    47.5233915
  ],
  [
    85.6080424,
    47.5233282
  ],
  [
    85.6079801,
    47.5231945
  ],
  [
    85.6078239,
    47.5230607
  ],
  [
    85.6080328,
    47.5226739
  ],
  [
    85.6081477,
    47.5224558
  ],
  [
    85.6081272,
    47.5221532
  ],
  [
    85.6079502,
    47.5220195
  ],
  [
    85.6079191,
    47.5218998
  ],
  [
    85.6079505,
    47.5217802
  ],
  [
    85.6081174,
    47.5216607
  ],
  [
    85.6083987,
    47.5216046
  ],
  [
    85.6085852,
    47.5213088
  ],
  [
    85.6085451,
    47.5211125
  ],
  [
    85.6082064,
    47.5208561
  ],
  [
    85.6080853,
    47.5208179
  ],
  [
    85.6080773,
    47.5207961
  ],
  [
    85.6083519,
    47.5207036
  ],
  [
    85.6086343,
    47.5207637
  ],
  [
    85.6089328,
    47.5209001
  ],
  [
    85.6090055,
    47.520862
  ],
  [
    85.6090461,
    47.5207094
  ],
  [
    85.6088929,
    47.5205676
  ],
  [
    85.6084896,
    47.520273
  ],
  [
    85.6084816,
    47.5201912
  ],
  [
    85.608627,
    47.5201368
  ],
  [
    85.6088369,
    47.5201587
  ],
  [
    85.6094584,
    47.5201481
  ],
  [
    85.6095796,
    47.5200883
  ],
  [
    85.6096604,
    47.5199793
  ],
  [
    85.609596,
    47.5198375
  ],
  [
    85.6092249,
    47.5196138
  ],
  [
    85.6091444,
    47.5194284
  ],
  [
    85.6091446,
    47.5192921
  ],
  [
    85.6092336,
    47.5191286
  ],
  [
    85.6091772,
    47.5190196
  ],
  [
    85.6090078,
    47.518905
  ],
  [
    85.6090322,
    47.5187906
  ],
  [
    85.6091555,
    47.5186813
  ],
  [
    85.6094642,
    47.5184248
  ],
  [
    85.6096187,
    47.5182128
  ],
  [
    85.6096629,
    47.5180826
  ],
  [
    85.6095915,
    47.5179412
  ],
  [
    85.6093879,
    47.5178035
  ],
  [
    85.6092118,
    47.5176062
  ],
  [
    85.6091844,
    47.5175132
  ],
  [
    85.6092341,
    47.5174128
  ],
  [
    85.609394,
    47.5173013
  ],
  [
    85.6094877,
    47.5171711
  ],
  [
    85.6094824,
    47.5170781
  ],
  [
    85.6094329,
    47.5170111
  ],
  [
    85.6094606,
    47.5168586
  ],
  [
    85.609323,
    47.5167841
  ],
  [
    85.6093582,
    47.5166005
  ],
  [
    85.609397,
    47.5164368
  ],
  [
    85.6094962,
    47.5163662
  ],
  [
    85.609623,
    47.5163291
  ],
  [
    85.609678,
    47.5163514
  ],
  [
    85.6097386,
    47.5163254
  ],
  [
    85.6098104,
    47.5161952
  ],
  [
    85.6098436,
    47.5160836
  ],
  [
    85.6097942,
    47.5159497
  ],
  [
    85.6097171,
    47.515905
  ],
  [
    85.6095243,
    47.5158975
  ],
  [
    85.6094913,
    47.5158491
  ],
  [
    85.6095741,
    47.5157078
  ],
  [
    85.6097175,
    47.5155888
  ],
  [
    85.6097727,
    47.5154437
  ],
  [
    85.6098278,
    47.5154066
  ],
  [
    85.6099545,
    47.5154029
  ],
  [
    85.6100317,
    47.5153732
  ],
  [
    85.6101253,
    47.5153584
  ],
  [
    85.6102632,
    47.515258
  ],
  [
    85.6102743,
    47.515165
  ],
  [
    85.6102413,
    47.515098
  ],
  [
    85.6101422,
    47.5150459
  ],
  [
    85.6099165,
    47.5149081
  ],
  [
    85.6099001,
    47.5148486
  ],
  [
    85.6099553,
    47.5147742
  ],
  [
    85.6100541,
    47.5147436
  ],
  [
    85.6101259,
    47.5145985
  ],
  [
    85.6100601,
    47.5143158
  ],
  [
    85.6099886,
    47.5142376
  ],
  [
    85.6100052,
    47.5141706
  ],
  [
    85.6098347,
    47.5139287
  ],
  [
    85.609923,
    47.5138023
  ],
  [
    85.6099066,
    47.5136684
  ],
  [
    85.6098958,
    47.513527
  ],
  [
    85.6100335,
    47.5134862
  ],
  [
    85.6100171,
    47.513382
  ],
  [
    85.6098795,
    47.5133112
  ],
  [
    85.6098026,
    47.5131177
  ],
  [
    85.6097348,
    47.512931
  ],
  [
    85.6097475,
    47.5126752
  ],
  [
    85.6097724,
    47.5125662
  ],
  [
    85.6096796,
    47.5123355
  ],
  [
    85.6098287,
    47.5122433
  ],
  [
    85.6100212,
    47.5122266
  ],
  [
    85.6098352,
    47.5120043
  ],
  [
    85.6095931,
    47.5119873
  ],
  [
    85.6095746,
    47.5118489
  ],
  [
    85.6096058,
    47.5117513
  ],
  [
    85.6094445,
    47.5116212
  ],
  [
    85.6093143,
    47.5114953
  ],
  [
    85.6091779,
    47.5113568
  ],
  [
    85.6090476,
    47.5112939
  ],
  [
    85.6090104,
    47.5112309
  ],
  [
    85.6089235,
    47.5111596
  ],
  [
    85.6088244,
    47.5110002
  ],
  [
    85.6087688,
    47.5107276
  ],
  [
    85.6086572,
    47.5106478
  ],
  [
    85.6085829,
    47.510501
  ],
  [
    85.6083525,
    47.5103939
  ],
  [
    85.6082409,
    47.510289
  ],
  [
    85.6081666,
    47.5101421
  ],
  [
    85.6080426,
    47.5099659
  ],
  [
    85.6080303,
    47.5098695
  ],
  [
    85.6079373,
    47.5097814
  ],
  [
    85.6079002,
    47.5096597
  ],
  [
    85.6079314,
    47.509471
  ],
  [
    85.6079635,
    47.5093304
  ],
  [
    85.6079388,
    47.5092213
  ],
  [
    85.607852,
    47.5091207
  ],
  [
    85.6078087,
    47.5090493
  ],
  [
    85.6078336,
    47.5089193
  ],
  [
    85.6078772,
    47.5088649
  ],
  [
    85.6080076,
    47.5087811
  ],
  [
    85.6082002,
    47.5087392
  ],
  [
    85.6083279,
    47.5087349
  ],
  [
    85.6084831,
    47.5087349
  ],
  [
    85.6086073,
    47.508714
  ],
  [
    85.6087377,
    47.5086596
  ],
  [
    85.6088495,
    47.5086303
  ],
  [
    85.6089178,
    47.5086303
  ],
  [
    85.608955,
    47.5086681
  ],
  [
    85.6089923,
    47.5086472
  ],
  [
    85.6090234,
    47.508601
  ],
  [
    85.6089862,
    47.5085633
  ],
  [
    85.608949,
    47.5084962
  ],
  [
    85.6088311,
    47.5084877
  ],
  [
    85.6087257,
    47.5083828
  ],
  [
    85.6086846,
    47.5082617
  ],
  [
    85.6087348,
    47.5081041
  ],
  [
    85.6088351,
    47.5078846
  ],
  [
    85.6088436,
    47.5077101
  ],
  [
    85.6087021,
    47.50762
  ],
  [
    85.6083354,
    47.5075804
  ],
  [
    85.6082605,
    47.5074903
  ],
  [
    85.6083189,
    47.507434
  ],
  [
    85.6084523,
    47.5073947
  ],
  [
    85.6085607,
    47.5073328
  ],
  [
    85.6086109,
    47.5072202
  ],
  [
    85.6087026,
    47.5071753
  ],
  [
    85.6088609,
    47.5071922
  ],
  [
    85.609011,
    47.507136
  ],
  [
    85.6090527,
    47.5070572
  ],
  [
    85.6090529,
    47.5069108
  ],
  [
    85.608953,
    47.5067982
  ],
  [
    85.6089282,
    47.5066856
  ],
  [
    85.6089866,
    47.5066293
  ],
  [
    85.6091616,
    47.5066069
  ],
  [
    85.6092783,
    47.5066239
  ],
  [
    85.6093783,
    47.5066239
  ],
  [
    85.6094367,
    47.5065845
  ],
  [
    85.6093701,
    47.5065169
  ],
  [
    85.6093368,
    47.5064719
  ],
  [
    85.6094788,
    47.5063945
  ],
  [
    85.6097873,
    47.5061366
  ],
  [
    85.609923,
    47.5060117
  ],
  [
    85.6098863,
    47.5058119
  ],
  [
    85.6096403,
    47.5053704
  ],
  [
    85.6092213,
    47.5052537
  ],
  [
    85.6090119,
    47.5051786
  ],
  [
    85.6090122,
    47.5049538
  ],
  [
    85.6090863,
    47.5048039
  ],
  [
    85.6092713,
    47.5047041
  ],
  [
    85.6093209,
    47.5044544
  ],
  [
    85.6093089,
    47.5042046
  ],
  [
    85.6093476,
    47.5040856
  ],
  [
    85.6100331,
    47.5036233
  ],
  [
    85.6103678,
    47.503172
  ],
  [
    85.6104478,
    47.5030662
  ],
  [
    85.6103449,
    47.5029133
  ],
  [
    85.6103525,
    47.502807
  ],
  [
    85.6103788,
    47.502731
  ],
  [
    85.6104951,
    47.5026526
  ],
  [
    85.6105888,
    47.5026856
  ],
  [
    85.610675,
    47.5026476
  ],
  [
    85.6106826,
    47.5025919
  ],
  [
    85.6107538,
    47.502554
  ],
  [
    85.6107801,
    47.5025034
  ],
  [
    85.610788,
    47.5022122
  ],
  [
    85.6107506,
    47.5021337
  ],
  [
    85.6107356,
    47.5020881
  ],
  [
    85.6107919,
    47.5020375
  ],
  [
    85.6110048,
    47.5019329
  ],
  [
    85.6113459,
    47.5018521
  ],
  [
    85.6114922,
    47.5017838
  ],
  [
    85.6115934,
    47.5017383
  ],
  [
    85.6117997,
    47.5015763
  ],
  [
    85.6118673,
    47.5015181
  ],
  [
    85.6118936,
    47.5014473
  ],
  [
    85.6118788,
    47.5012219
  ],
  [
    85.6118191,
    47.5009965
  ],
  [
    85.6118343,
    47.5008066
  ],
  [
    85.6118878,
    47.5006496
  ],
  [
    85.6119441,
    47.5005914
  ],
  [
    85.6120904,
    47.5004852
  ],
  [
    85.6121429,
    47.5004194
  ],
  [
    85.612173,
    47.5002877
  ],
  [
    85.6121919,
    47.5001915
  ],
  [
    85.6121732,
    47.5001206
  ],
  [
    85.6121171,
    47.5000674
  ],
  [
    85.6121284,
    47.4999661
  ],
  [
    85.6121361,
    47.4997914
  ],
  [
    85.6121553,
    47.4994344
  ],
  [
    85.6121367,
    47.4992648
  ],
  [
    85.612151,
    47.4991146
  ],
  [
    85.6122337,
    47.4989552
  ],
  [
    85.6123014,
    47.4987273
  ],
  [
    85.6123539,
    47.4986387
  ],
  [
    85.6124514,
    47.4985831
  ],
  [
    85.6126052,
    47.4984971
  ],
  [
    85.6126654,
    47.4982996
  ],
  [
    85.612628,
    47.4982439
  ],
  [
    85.6125643,
    47.4982109
  ],
  [
    85.6125531,
    47.4981805
  ],
  [
    85.6126019,
    47.4981274
  ],
  [
    85.6126469,
    47.4981072
  ],
  [
    85.6127668,
    47.4980996
  ],
  [
    85.6128455,
    47.4980743
  ],
  [
    85.6128868,
    47.4980339
  ],
  [
    85.6128794,
    47.497968
  ],
  [
    85.6128719,
    47.4979199
  ],
  [
    85.6129494,
    47.4978827
  ],
  [
    85.6130506,
    47.4978828
  ],
  [
    85.613208,
    47.4978347
  ],
  [
    85.6133505,
    47.4978019
  ],
  [
    85.6134629,
    47.4978146
  ],
  [
    85.6135453,
    47.4977893
  ],
  [
    85.6136541,
    47.4977235
  ],
  [
    85.6137629,
    47.4976299
  ],
  [
    85.6137742,
    47.4975666
  ],
  [
    85.6135157,
    47.4974475
  ],
  [
    85.6132835,
    47.4973537
  ],
  [
    85.6132498,
    47.4972904
  ],
  [
    85.6132911,
    47.4972043
  ],
  [
    85.6134524,
    47.4971487
  ],
  [
    85.6135161,
    47.4971082
  ],
  [
    85.6135125,
    47.4968886
  ],
  [
    85.6135726,
    47.4967291
  ],
  [
    85.6136365,
    47.4965873
  ],
  [
    85.6136142,
    47.4964354
  ],
  [
    85.6135768,
    47.4963594
  ],
  [
    85.6133857,
    47.4963062
  ],
  [
    85.613247,
    47.4963112
  ],
  [
    85.6131647,
    47.4962098
  ],
  [
    85.6131543,
    47.4960834
  ],
  [
    85.6132038,
    47.495954
  ],
  [
    85.6133681,
    47.4958727
  ],
  [
    85.6134174,
    47.4957803
  ],
  [
    85.6134942,
    47.495662
  ],
  [
    85.6134749,
    47.4954794
  ],
  [
    85.6135139,
    47.4953302
  ],
  [
    85.613502,
    47.4952597
  ],
  [
    85.6133828,
    47.4951124
  ],
  [
    85.6133501,
    47.4950459
  ],
  [
    85.6134293,
    47.4949455
  ],
  [
    85.6135115,
    47.4949011
  ],
  [
    85.6136299,
    47.4948656
  ],
  [
    85.6137141,
    47.4948132
  ],
  [
    85.6138153,
    47.4947272
  ],
  [
    85.6138979,
    47.494626
  ],
  [
    85.6140292,
    47.4945374
  ],
  [
    85.6140854,
    47.4944716
  ],
  [
    85.6140256,
    47.4944057
  ],
  [
    85.6140069,
    47.49435
  ],
  [
    85.6140894,
    47.4942969
  ],
  [
    85.6142056,
    47.4942514
  ],
  [
    85.6143368,
    47.4942084
  ],
  [
    85.6143781,
    47.4941324
  ],
  [
    85.6143819,
    47.4940641
  ],
  [
    85.6143258,
    47.4939957
  ],
  [
    85.6141947,
    47.4939526
  ],
  [
    85.6140561,
    47.4938613
  ],
  [
    85.6140525,
    47.4937879
  ],
  [
    85.6141462,
    47.4937348
  ],
  [
    85.6142737,
    47.4937019
  ],
  [
    85.6143611,
    47.4936686
  ],
  [
    85.6144511,
    47.4936104
  ],
  [
    85.6146011,
    47.4935345
  ],
  [
    85.6148335,
    47.4934384
  ],
  [
    85.6149985,
    47.4933828
  ],
  [
    85.6151785,
    47.4933018
  ],
  [
    85.6153697,
    47.493231
  ],
  [
    85.6154746,
    47.4931981
  ],
  [
    85.6155384,
    47.4931374
  ],
  [
    85.615501,
    47.4930614
  ],
  [
    85.6154149,
    47.4929778
  ],
  [
    85.6153812,
    47.4929044
  ],
  [
    85.6154038,
    47.492836
  ],
  [
    85.6155275,
    47.4927804
  ],
  [
    85.6156887,
    47.4927425
  ],
  [
    85.6159136,
    47.4927249
  ],
  [
    85.6160683,
    47.4927036
  ],
  [
    85.6162504,
    47.4926257
  ],
  [
    85.6162631,
    47.4925794
  ],
  [
    85.6161332,
    47.4924778
  ],
  [
    85.6161304,
    47.4923919
  ],
  [
    85.6162489,
    47.4922651
  ],
  [
    85.6162519,
    47.4921695
  ],
  [
    85.6161308,
    47.4920055
  ],
  [
    85.6161366,
    47.4919645
  ],
  [
    85.616258,
    47.4919079
  ],
  [
    85.6162552,
    47.4918455
  ],
  [
    85.6162119,
    47.4918103
  ],
  [
    85.6160559,
    47.4918317
  ],
  [
    85.6159663,
    47.4918219
  ],
  [
    85.6158652,
    47.4918492
  ],
  [
    85.6157728,
    47.4918316
  ],
  [
    85.6156832,
    47.4918471
  ],
  [
    85.6156658,
    47.491894
  ],
  [
    85.6157322,
    47.4919487
  ],
  [
    85.6157293,
    47.4919877
  ],
  [
    85.6156166,
    47.4920091
  ],
  [
    85.6155192,
    47.4920143
  ],
  [
    85.6152876,
    47.4919314
  ],
  [
    85.6151982,
    47.4918866
  ],
  [
    85.6151189,
    47.4918128
  ],
  [
    85.6151123,
    47.4917524
  ],
  [
    85.6151587,
    47.4916965
  ],
  [
    85.6153276,
    47.4916496
  ],
  [
    85.6154136,
    47.4916676
  ],
  [
    85.6154798,
    47.4917235
  ],
  [
    85.6155228,
    47.4917191
  ],
  [
    85.6155394,
    47.4916877
  ],
  [
    85.6154931,
    47.4916162
  ],
  [
    85.6154799,
    47.4915804
  ],
  [
    85.6155727,
    47.4915155
  ],
  [
    85.6157713,
    47.4914709
  ],
  [
    85.6160793,
    47.491395
  ],
  [
    85.6161852,
    47.4913906
  ],
  [
    85.6163903,
    47.4914667
  ],
  [
    85.6165194,
    47.4914713
  ],
  [
    85.6167413,
    47.4913372
  ],
  [
    85.6167315,
    47.4912991
  ],
  [
    85.6165958,
    47.4912543
  ],
  [
    85.616384,
    47.491176
  ],
  [
    85.6163874,
    47.4911357
  ],
  [
    85.6167115,
    47.4910669
  ],
  [
    85.616966,
    47.4910512
  ],
  [
    85.6170913,
    47.4910274
  ],
  [
    85.6173694,
    47.4909032
  ],
  [
    85.6178158,
    47.4907632
  ],
  [
    85.6177493,
    47.4906548
  ],
  [
    85.617585,
    47.4905515
  ],
  [
    85.6176516,
    47.4904881
  ],
  [
    85.61782,
    47.4904485
  ],
  [
    85.6181431,
    47.4904351
  ],
  [
    85.618374,
    47.4904511
  ],
  [
    85.6185306,
    47.4904009
  ],
  [
    85.6188242,
    47.4904407
  ],
  [
    85.6193097,
    47.4903192
  ],
  [
    85.6194821,
    47.4901447
  ],
  [
    85.619537,
    47.490121
  ],
  [
    85.6196662,
    47.4900628
  ],
  [
    85.6197421,
    47.4900017
  ],
  [
    85.6198206,
    47.4898695
  ],
  [
    85.619942,
    47.4897717
  ],
  [
    85.6201299,
    47.4897347
  ],
  [
    85.6203022,
    47.4897348
  ],
  [
    85.6204822,
    47.4897454
  ],
  [
    85.6206192,
    47.4897772
  ],
  [
    85.6207367,
    47.4897694
  ],
  [
    85.6208385,
    47.4897482
  ],
  [
    85.6209427,
    47.4897305
  ],
  [
    85.6210328,
    47.489712
  ],
  [
    85.6210955,
    47.4896591
  ],
  [
    85.6210994,
    47.4895824
  ],
  [
    85.6210174,
    47.489421
  ],
  [
    85.6209863,
    47.4891618
  ],
  [
    85.621006,
    47.4890296
  ],
  [
    85.6210648,
    47.4889449
  ],
  [
    85.6210687,
    47.4889397
  ],
  [
    85.6212645,
    47.4888577
  ],
  [
    85.6214591,
    47.4888077
  ],
  [
    85.6217057,
    47.4887522
  ],
  [
    85.6219289,
    47.4887206
  ],
  [
    85.6221481,
    47.4886969
  ],
  [
    85.6224378,
    47.4887155
  ],
  [
    85.622563,
    47.4887129
  ],
  [
    85.622614,
    47.4886653
  ],
  [
    85.6226336,
    47.4885886
  ],
  [
    85.6225594,
    47.4884643
  ],
  [
    85.6225986,
    47.4883876
  ],
  [
    85.6226391,
    47.4883547
  ],
  [
    85.6227096,
    47.4883547
  ],
  [
    85.6228153,
    47.4883547
  ],
  [
    85.6229562,
    47.4883283
  ],
  [
    85.6230431,
    47.4883193
  ],
  [
    85.6230907,
    47.4882872
  ],
  [
    85.623105,
    47.4882422
  ],
  [
    85.623086,
    47.4882004
  ],
  [
    85.62301,
    47.4881457
  ],
  [
    85.6230195,
    47.4881168
  ],
  [
    85.6230433,
    47.4880976
  ],
  [
    85.6231955,
    47.4880558
  ],
  [
    85.6233335,
    47.4880302
  ],
  [
    85.6235617,
    47.4880271
  ],
  [
    85.6236854,
    47.4880046
  ],
  [
    85.623733,
    47.4879757
  ],
  [
    85.6237711,
    47.4879083
  ],
  [
    85.6237617,
    47.4877958
  ],
  [
    85.623814,
    47.4877091
  ],
  [
    85.6238716,
    47.4875799
  ],
  [
    85.6239531,
    47.4875157
  ],
  [
    85.6240754,
    47.4874149
  ],
  [
    85.62414,
    47.4873162
  ],
  [
    85.6241741,
    47.4872016
  ],
  [
    85.6242182,
    47.4871603
  ],
  [
    85.6242386,
    47.4871442
  ],
  [
    85.6243168,
    47.4870502
  ],
  [
    85.6243509,
    47.4868896
  ],
  [
    85.6244159,
    47.486846
  ],
  [
    85.6245042,
    47.4868276
  ],
  [
    85.624528,
    47.4867703
  ],
  [
    85.6245722,
    47.4867084
  ],
  [
    85.6246469,
    47.4866786
  ],
  [
    85.6247487,
    47.4866626
  ],
  [
    85.6247895,
    47.4866236
  ],
  [
    85.624793,
    47.4865502
  ],
  [
    85.6247048,
    47.4864951
  ],
  [
    85.6245928,
    47.4864285
  ],
  [
    85.6245522,
    47.4863138
  ],
  [
    85.6244028,
    47.4862862
  ],
  [
    85.6242433,
    47.4862357
  ],
  [
    85.6242231,
    47.4860888
  ],
  [
    85.6242231,
    47.4860842
  ],
  [
    85.6242639,
    47.4860131
  ],
  [
    85.6244575,
    47.4859077
  ],
  [
    85.6245695,
    47.4858596
  ],
  [
    85.6245934,
    47.4857999
  ],
  [
    85.6248039,
    47.4857151
  ],
  [
    85.6251774,
    47.4856533
  ],
  [
    85.6253234,
    47.4855976
  ],
  [
    85.6254038,
    47.485547
  ],
  [
    85.6254947,
    47.4854828
  ],
  [
    85.6257238,
    47.4854391
  ],
  [
    85.6258968,
    47.4853749
  ],
  [
    85.6260957,
    47.4853545
  ],
  [
    85.6262167,
    47.4854101
  ],
  [
    85.6263248,
    47.4853867
  ],
  [
    85.6264718,
    47.485305
  ],
  [
    85.6265367,
    47.4852203
  ],
  [
    85.6267341,
    47.4849219
  ],
  [
    85.6267641,
    47.4847988
  ],
  [
    85.6267685,
    47.4846703
  ],
  [
    85.6266994,
    47.4845563
  ],
  [
    85.6267644,
    47.4844366
  ],
  [
    85.6267817,
    47.4843577
  ],
  [
    85.6268164,
    47.4842964
  ],
  [
    85.6267127,
    47.4841386
  ],
  [
    85.6267585,
    47.4840849
  ],
  [
    85.6269296,
    47.4839855
  ],
  [
    85.6270104,
    47.4839245
  ],
  [
    85.6270722,
    47.4838058
  ],
  [
    85.6271483,
    47.4837352
  ],
  [
    85.6271958,
    47.4836614
  ],
  [
    85.6272529,
    47.4835844
  ],
  [
    85.6271818,
    47.4834559
  ],
  [
    85.6270916,
    47.4833981
  ],
  [
    85.6270299,
    47.483244
  ],
  [
    85.6270632,
    47.4831798
  ],
  [
    85.6270634,
    47.4829776
  ],
  [
    85.6271062,
    47.482891
  ],
  [
    85.6271823,
    47.4828332
  ],
  [
    85.6272393,
    47.4827498
  ],
  [
    85.6272394,
    47.4826824
  ],
  [
    85.6273681,
    47.4826734
  ],
  [
    85.6274252,
    47.4825996
  ],
  [
    85.6275155,
    47.4824455
  ],
  [
    85.6275963,
    47.482391
  ],
  [
    85.6276249,
    47.4822915
  ],
  [
    85.627606,
    47.4822145
  ],
  [
    85.6275063,
    47.4821021
  ],
  [
    85.6274304,
    47.481964
  ],
  [
    85.6273212,
    47.4819287
  ],
  [
    85.6273024,
    47.4817393
  ],
  [
    85.6273434,
    47.4816127
  ],
  [
    85.6273292,
    47.4815356
  ],
  [
    85.6273483,
    47.4814233
  ],
  [
    85.6274386,
    47.4813238
  ],
  [
    85.627567,
    47.4811826
  ],
  [
    85.6276384,
    47.4810478
  ],
  [
    85.627724,
    47.4809098
  ],
  [
    85.6278333,
    47.4807911
  ],
  [
    85.6278666,
    47.4807109
  ],
  [
    85.6279125,
    47.4806186
  ],
  [
    85.6279981,
    47.4804839
  ],
  [
    85.6280837,
    47.4803362
  ],
  [
    85.628212,
    47.480179
  ],
  [
    85.6283166,
    47.4800603
  ],
  [
    85.6284165,
    47.4799159
  ],
  [
    85.628504,
    47.4798449
  ],
  [
    85.628637,
    47.4798257
  ],
  [
    85.6286845,
    47.4797936
  ],
  [
    85.6287463,
    47.4797423
  ],
  [
    85.628846,
    47.479723
  ],
  [
    85.6289316,
    47.4796428
  ],
  [
    85.6289791,
    47.4795786
  ],
  [
    85.6290314,
    47.4795177
  ],
  [
    85.6291407,
    47.4794631
  ],
  [
    85.6292927,
    47.479476
  ],
  [
    85.6293592,
    47.4794696
  ],
  [
    85.6294258,
    47.4792963
  ],
  [
    85.6295208,
    47.4792514
  ],
  [
    85.6295826,
    47.4792194
  ],
  [
    85.6296253,
    47.4792065
  ],
  [
    85.6296254,
    47.4791712
  ],
  [
    85.6295684,
    47.4791198
  ],
  [
    85.6296159,
    47.4790749
  ],
  [
    85.6296777,
    47.4790782
  ],
  [
    85.6297441,
    47.4791263
  ],
  [
    85.6298343,
    47.4791681
  ],
  [
    85.6299026,
    47.4791527
  ],
  [
    85.6300214,
    47.4790918
  ],
  [
    85.6301497,
    47.4790244
  ],
  [
    85.6302352,
    47.4789987
  ],
  [
    85.6303208,
    47.4789378
  ],
  [
    85.630392,
    47.478925
  ],
  [
    85.6304442,
    47.4789314
  ],
  [
    85.6304965,
    47.4789186
  ],
  [
    85.6305821,
    47.4788287
  ],
  [
    85.6307151,
    47.478755
  ],
  [
    85.6307864,
    47.4786779
  ],
  [
    85.6307818,
    47.4785399
  ],
  [
    85.6308009,
    47.4783345
  ],
  [
    85.6307462,
    47.4781432
  ],
  [
    85.6307036,
    47.4780084
  ],
  [
    85.6305896,
    47.4779121
  ],
  [
    85.6304947,
    47.4778639
  ],
  [
    85.6304425,
    47.4778061
  ],
  [
    85.6303476,
    47.4777483
  ],
  [
    85.6303238,
    47.4777065
  ],
  [
    85.6303666,
    47.4776359
  ],
  [
    85.6304664,
    47.4775525
  ],
  [
    85.6305995,
    47.4774884
  ],
  [
    85.6308417,
    47.4774403
  ],
  [
    85.6309605,
    47.4773697
  ],
  [
    85.6310175,
    47.4773023
  ],
  [
    85.6309606,
    47.4771964
  ],
  [
    85.6308698,
    47.4771494
  ],
  [
    85.6308336,
    47.4770637
  ],
  [
    85.6308941,
    47.4769699
  ],
  [
    85.6309847,
    47.4769495
  ],
  [
    85.631173,
    47.4770143
  ],
  [
    85.6312274,
    47.4769735
  ],
  [
    85.6312877,
    47.4769695
  ],
  [
    85.6313602,
    47.4770103
  ],
  [
    85.6314326,
    47.4770022
  ],
  [
    85.6314688,
    47.4770185
  ],
  [
    85.6315413,
    47.4770144
  ],
  [
    85.6316319,
    47.4769165
  ],
  [
    85.6316863,
    47.4768268
  ],
  [
    85.6316381,
    47.4767411
  ],
  [
    85.6313786,
    47.4765288
  ],
  [
    85.6312943,
    47.4763166
  ],
  [
    85.6313065,
    47.4761575
  ],
  [
    85.6315921,
    47.4760294
  ],
  [
    85.6316707,
    47.4759518
  ],
  [
    85.6318217,
    47.4758295
  ],
  [
    85.6319244,
    47.4757683
  ],
  [
    85.6320934,
    47.4757316
  ],
  [
    85.6321961,
    47.4757235
  ],
  [
    85.6322021,
    47.4756827
  ],
  [
    85.632178,
    47.4756256
  ],
  [
    85.6321117,
    47.4755807
  ],
  [
    85.6319909,
    47.4755276
  ],
  [
    85.6318824,
    47.4753847
  ],
  [
    85.6319066,
    47.4752868
  ],
  [
    85.632112,
    47.4751726
  ],
  [
    85.6321844,
    47.4751726
  ],
  [
    85.6322568,
    47.4752012
  ],
  [
    85.6322809,
    47.4752788
  ],
  [
    85.6323231,
    47.4753604
  ],
  [
    85.6323955,
    47.4753808
  ],
  [
    85.6324982,
    47.4753441
  ],
  [
    85.6326311,
    47.4752014
  ],
  [
    85.6327459,
    47.4751361
  ],
  [
    85.6328787,
    47.4750953
  ],
  [
    85.6330901,
    47.4749526
  ],
  [
    85.6330721,
    47.4748547
  ],
  [
    85.6330299,
    47.4747649
  ],
  [
    85.6328971,
    47.4747077
  ],
  [
    85.6326557,
    47.4746668
  ],
  [
    85.6324505,
    47.4745892
  ],
  [
    85.6323298,
    47.4744749
  ],
  [
    85.6323058,
    47.4743525
  ],
  [
    85.6322998,
    47.4742301
  ],
  [
    85.6323663,
    47.4741159
  ],
  [
    85.632436,
    47.4740656
  ],
  [
    85.6326292,
    47.474082
  ],
  [
    85.6327016,
    47.4741596
  ],
  [
    85.6327619,
    47.47418
  ],
  [
    85.6329491,
    47.4741556
  ],
  [
    85.6330518,
    47.4740821
  ],
  [
    85.6330398,
    47.4739475
  ],
  [
    85.6331244,
    47.4738251
  ],
  [
    85.6330943,
    47.47367
  ],
  [
    85.6331428,
    47.4734946
  ],
  [
    85.6332696,
    47.4733885
  ],
  [
    85.6335172,
    47.4732213
  ],
  [
    85.6336863,
    47.4731275
  ],
  [
    85.6338615,
    47.4730256
  ],
  [
    85.6340185,
    47.4729195
  ],
  [
    85.634103,
    47.4728991
  ],
  [
    85.6341573,
    47.47294
  ],
  [
    85.6342479,
    47.4730094
  ],
  [
    85.6343263,
    47.4730176
  ],
  [
    85.6344833,
    47.4729646
  ],
  [
    85.6346464,
    47.4728789
  ],
  [
    85.6349965,
    47.4728219
  ],
  [
    85.6351716,
    47.4728546
  ],
  [
    85.6353769,
    47.4728302
  ],
  [
    85.6355037,
    47.47272
  ],
  [
    85.6355158,
    47.4726752
  ],
  [
    85.6354555,
    47.4726139
  ],
  [
    85.6353053,
    47.4725351
  ],
  [
    85.6352148,
    47.4724575
  ],
  [
    85.6352451,
    47.4722903
  ],
  [
    85.6353358,
    47.4721515
  ],
  [
    85.6354203,
    47.4720863
  ],
  [
    85.6355894,
    47.4720863
  ],
  [
    85.6357524,
    47.4720537
  ],
  [
    85.635849,
    47.4720375
  ],
  [
    85.6359093,
    47.4721028
  ],
  [
    85.63603,
    47.4721599
  ],
  [
    85.6361929,
    47.4722783
  ],
  [
    85.6365249,
    47.4722866
  ],
  [
    85.6366879,
    47.4722744
  ],
  [
    85.6368691,
    47.4722132
  ],
  [
    85.6369958,
    47.4722173
  ],
  [
    85.637189,
    47.4721521
  ],
  [
    85.6372796,
    47.4720868
  ],
  [
    85.6373038,
    47.472042
  ],
  [
    85.6370383,
    47.4718868
  ],
  [
    85.6369297,
    47.4718134
  ],
  [
    85.6368452,
    47.4717562
  ],
  [
    85.6367426,
    47.471695
  ],
  [
    85.6367246,
    47.4716133
  ],
  [
    85.63661,
    47.471397
  ],
  [
    85.6365075,
    47.4712909
  ],
  [
    85.6364894,
    47.4712215
  ],
  [
    85.6363989,
    47.4711807
  ],
  [
    85.6362662,
    47.4710827
  ],
  [
    85.6362602,
    47.470997
  ],
  [
    85.6361939,
    47.4709644
  ],
  [
    85.6360791,
    47.4709807
  ],
  [
    85.6359826,
    47.4709235
  ],
  [
    85.6359102,
    47.4709194
  ],
  [
    85.6358861,
    47.470899
  ],
  [
    85.6358771,
    47.4708491
  ],
  [
    85.6357685,
    47.4707919
  ],
  [
    85.6357867,
    47.4706736
  ],
  [
    85.6360223,
    47.4704492
  ],
  [
    85.6361491,
    47.470384
  ],
  [
    85.6363182,
    47.4702249
  ],
  [
    85.6362701,
    47.470029
  ],
  [
    85.6363426,
    47.4698862
  ],
  [
    85.6364755,
    47.4697883
  ],
  [
    85.6366686,
    47.469768
  ],
  [
    85.636723,
    47.4697476
  ],
  [
    85.6368196,
    47.4696211
  ],
  [
    85.6368801,
    47.4694824
  ],
  [
    85.6369164,
    47.4693314
  ],
  [
    85.637007,
    47.4692091
  ],
  [
    85.6371009,
    47.4691354
  ],
  [
    85.637276,
    47.4690824
  ],
  [
    85.6375054,
    47.4690824
  ],
  [
    85.6376381,
    47.4690294
  ],
  [
    85.6380969,
    47.468948
  ],
  [
    85.6382841,
    47.468948
  ],
  [
    85.6383988,
    47.4688664
  ],
  [
    85.6387972,
    47.4687849
  ],
  [
    85.638918,
    47.4687442
  ],
  [
    85.6389663,
    47.4686748
  ],
  [
    85.6389786,
    47.4684708
  ],
  [
    85.6390813,
    47.4681811
  ],
  [
    85.6392021,
    47.4680873
  ],
  [
    85.6392203,
    47.468022
  ],
  [
    85.6391529,
    47.4678663
  ],
  [
    85.6391071,
    47.4677866
  ],
  [
    85.639081,
    47.4677291
  ],
  [
    85.6390679,
    47.4677247
  ],
  [
    85.6386098,
    47.4676582
  ],
  [
    85.6385183,
    47.467583
  ],
  [
    85.6384922,
    47.4674635
  ],
  [
    85.6385839,
    47.4673087
  ],
  [
    85.6386952,
    47.467238
  ],
  [
    85.6388392,
    47.4672291
  ],
  [
    85.6390224,
    47.4672513
  ],
  [
    85.639114,
    47.4672513
  ],
  [
    85.6392645,
    47.4672116
  ],
  [
    85.6393824,
    47.4671408
  ],
  [
    85.6395919,
    47.4669728
  ],
  [
    85.639821,
    47.4667782
  ],
  [
    85.6399127,
    47.4667472
  ],
  [
    85.6399585,
    47.4667119
  ],
  [
    85.6399585,
    47.4666765
  ],
  [
    85.6399062,
    47.4666189
  ],
  [
    85.6398539,
    47.4665393
  ],
  [
    85.6398736,
    47.4664508
  ],
  [
    85.6398671,
    47.4663889
  ],
  [
    85.6398213,
    47.4663181
  ],
  [
    85.6396708,
    47.4662871
  ],
  [
    85.6395203,
    47.4663092
  ],
  [
    85.6393894,
    47.4663888
  ],
  [
    85.6391603,
    47.4664993
  ],
  [
    85.6390817,
    47.466486
  ],
  [
    85.6389967,
    47.4663887
  ],
  [
    85.6390229,
    47.4663312
  ],
  [
    85.6390949,
    47.4662958
  ],
  [
    85.6391277,
    47.4662427
  ],
  [
    85.6390689,
    47.4660967
  ],
  [
    85.6391017,
    47.4659286
  ],
  [
    85.6390036,
    47.4658312
  ],
  [
    85.638984,
    47.4657516
  ],
  [
    85.6390364,
    47.4656676
  ],
  [
    85.6390299,
    47.4655879
  ],
  [
    85.6389253,
    47.4655083
  ],
  [
    85.6389188,
    47.4654419
  ],
  [
    85.6390039,
    47.46538
  ],
  [
    85.6390699,
    47.4652913
  ],
  [
    85.6392466,
    47.4651984
  ],
  [
    85.6391681,
    47.4651453
  ],
  [
    85.6391617,
    47.4650347
  ],
  [
    85.6392533,
    47.4650303
  ],
  [
    85.6392206,
    47.4649905
  ],
  [
    85.6391486,
    47.4649905
  ],
  [
    85.639057,
    47.4649551
  ],
  [
    85.6391814,
    47.4648843
  ],
  [
    85.6392861,
    47.4648888
  ],
  [
    85.6393319,
    47.4648091
  ],
  [
    85.6391553,
    47.4647472
  ],
  [
    85.6391029,
    47.4647604
  ],
  [
    85.6390179,
    47.4647471
  ],
  [
    85.6389917,
    47.4646763
  ],
  [
    85.6390572,
    47.4646056
  ],
  [
    85.6392012,
    47.4645127
  ],
  [
    85.6392079,
    47.4643667
  ],
  [
    85.6392864,
    47.4642871
  ],
  [
    85.6392341,
    47.4641986
  ],
  [
    85.6392866,
    47.4641057
  ],
  [
    85.6394895,
    47.4639598
  ],
  [
    85.6395223,
    47.4638094
  ],
  [
    85.6395943,
    47.4637431
  ],
  [
    85.6397187,
    47.4636502
  ],
  [
    85.6397907,
    47.4635529
  ],
  [
    85.6398497,
    47.4635219
  ],
  [
    85.6400002,
    47.4635264
  ],
  [
    85.6400133,
    47.463491
  ],
  [
    85.639961,
    47.4634335
  ],
  [
    85.6401049,
    47.4634247
  ],
  [
    85.6401572,
    47.4634822
  ],
  [
    85.6402161,
    47.4634822
  ],
  [
    85.6403012,
    47.4634292
  ],
  [
    85.6403732,
    47.463438
  ],
  [
    85.6404321,
    47.4633894
  ],
  [
    85.6406153,
    47.4634027
  ],
  [
    85.6409677,
    47.4632258
  ],
  [
    85.6411968,
    47.4631241
  ],
  [
    85.6412427,
    47.462987
  ],
  [
    85.6411707,
    47.4629073
  ],
  [
    85.6412362,
    47.4627746
  ],
  [
    85.641354,
    47.4627525
  ],
  [
    85.6417009,
    47.4625845
  ],
  [
    85.6419105,
    47.4622749
  ],
  [
    85.6420219,
    47.4620714
  ],
  [
    85.6421855,
    47.4619387
  ],
  [
    85.6422445,
    47.461753
  ],
  [
    85.64231,
    47.461576
  ],
  [
    85.6424148,
    47.4615053
  ],
  [
    85.6428794,
    47.4613948
  ],
  [
    85.6429347,
    47.4612931
  ],
  [
    85.6428497,
    47.4612267
  ],
  [
    85.6426142,
    47.4611382
  ],
  [
    85.6426077,
    47.4610143
  ],
  [
    85.6426798,
    47.4608905
  ],
  [
    85.6428892,
    47.4607622
  ],
  [
    85.643223,
    47.4606075
  ],
  [
    85.6435044,
    47.460519
  ],
  [
    85.6439887,
    47.4603555
  ],
  [
    85.6440934,
    47.4603732
  ],
  [
    85.6441523,
    47.4603201
  ],
  [
    85.644087,
    47.4601741
  ],
  [
    85.6440019,
    47.4601431
  ],
  [
    85.6439234,
    47.4600546
  ],
  [
    85.6439562,
    47.4599839
  ],
  [
    85.6440282,
    47.4599839
  ],
  [
    85.6441655,
    47.4600547
  ],
  [
    85.6444992,
    47.4601742
  ],
  [
    85.6446562,
    47.4601875
  ],
  [
    85.6447544,
    47.4601433
  ],
  [
    85.6448068,
    47.4600548
  ],
  [
    85.6447676,
    47.4598381
  ],
  [
    85.6448265,
    47.4598071
  ],
  [
    85.6449246,
    47.4598823
  ],
  [
    85.6449966,
    47.4598735
  ],
  [
    85.6450098,
    47.4597496
  ],
  [
    85.6448267,
    47.459524
  ],
  [
    85.6447089,
    47.4594886
  ],
  [
    85.6447089,
    47.4594488
  ],
  [
    85.6451015,
    47.4594002
  ],
  [
    85.645375,
    47.4594465
  ],
  [
    85.645676,
    47.4594908
  ],
  [
    85.6458854,
    47.4593537
  ],
  [
    85.6460032,
    47.4593139
  ],
  [
    85.646108,
    47.4592122
  ],
  [
    85.6461866,
    47.4589866
  ],
  [
    85.6460951,
    47.458699
  ],
  [
    85.6461803,
    47.4584114
  ],
  [
    85.6462196,
    47.4583495
  ],
  [
    85.6462981,
    47.458323
  ],
  [
    85.6464094,
    47.4582434
  ],
  [
    85.646488,
    47.4580443
  ],
  [
    85.6466974,
    47.4580001
  ],
  [
    85.6465536,
    47.4577656
  ],
  [
    85.6467679,
    47.4576937
  ],
  [
    85.6467322,
    47.4575968
  ],
  [
    85.6467322,
    47.4575242
  ],
  [
    85.6468128,
    47.4575
  ],
  [
    85.6469023,
    47.4574516
  ],
  [
    85.6470097,
    47.4574759
  ],
  [
    85.6472067,
    47.4574759
  ],
  [
    85.6473499,
    47.4574517
  ],
  [
    85.6475469,
    47.4573549
  ],
  [
    85.6476365,
    47.4573247
  ],
  [
    85.6476544,
    47.4571915
  ],
  [
    85.6478961,
    47.4571371
  ],
  [
    85.6480125,
    47.4571613
  ],
  [
    85.6481505,
    47.4571286
  ],
  [
    85.6484012,
    47.4571166
  ],
  [
    85.6486071,
    47.4570258
  ],
  [
    85.6488399,
    47.4569108
  ],
  [
    85.6489833,
    47.4566203
  ],
  [
    85.6491176,
    47.4564388
  ],
  [
    85.6491177,
    47.4562754
  ],
  [
    85.6491625,
    47.4561483
  ],
  [
    85.6491268,
    47.4558759
  ],
  [
    85.6494133,
    47.4556883
  ],
  [
    85.6495387,
    47.4556944
  ],
  [
    85.6496282,
    47.4556762
  ],
  [
    85.6497715,
    47.4554341
  ],
  [
    85.6499417,
    47.455301
  ],
  [
    85.6502103,
    47.4551255
  ],
  [
    85.6501387,
    47.4549863
  ],
  [
    85.6499687,
    47.4548894
  ],
  [
    85.6499419,
    47.4548047
  ],
  [
    85.6500135,
    47.454726
  ],
  [
    85.6499598,
    47.4546534
  ],
  [
    85.6497539,
    47.4546896
  ],
  [
    85.6497146,
    47.4545741
  ],
  [
    85.6497651,
    47.454398
  ],
  [
    85.6495551,
    47.4541934
  ],
  [
    85.6495299,
    47.454057
  ],
  [
    85.6497066,
    47.453915
  ],
  [
    85.6501436,
    47.453898
  ],
  [
    85.6503285,
    47.4537389
  ],
  [
    85.6503201,
    47.4536253
  ],
  [
    85.6501772,
    47.4535798
  ],
  [
    85.6500008,
    47.4535172
  ],
  [
    85.6498749,
    47.4533638
  ],
  [
    85.6499168,
    47.4532388
  ],
  [
    85.6500429,
    47.4531763
  ],
  [
    85.650211,
    47.4531479
  ],
  [
    85.6506588,
    47.4531638
  ],
  [
    85.6509265,
    47.4530639
  ],
  [
    85.6514406,
    47.4530449
  ],
  [
    85.6517013,
    47.4529497
  ],
  [
    85.6520886,
    47.4528974
  ],
  [
    85.6524548,
    47.4528213
  ],
  [
    85.652645,
    47.4527451
  ],
  [
    85.6527718,
    47.4526642
  ],
  [
    85.6530534,
    47.4527833
  ],
  [
    85.6532436,
    47.4527262
  ],
  [
    85.6533161,
    47.4525165
  ],
  [
    85.6537442,
    47.4524007
  ],
  [
    85.6540921,
    47.4523932
  ],
  [
    85.6545186,
    47.4523022
  ],
  [
    85.6548104,
    47.4523477
  ],
  [
    85.6553197,
    47.4521197
  ],
  [
    85.6557125,
    47.452059
  ],
  [
    85.6560155,
    47.4520743
  ],
  [
    85.656341,
    47.4520591
  ],
  [
    85.6566665,
    47.4519302
  ],
  [
    85.657295,
    47.4517026
  ],
  [
    85.6575755,
    47.4517785
  ],
  [
    85.6581479,
    47.4518469
  ],
  [
    85.6583948,
    47.4517406
  ],
  [
    85.6587315,
    47.4518014
  ],
  [
    85.6591243,
    47.451809
  ],
  [
    85.6593038,
    47.4519305
  ],
  [
    85.6595171,
    47.4518394
  ],
  [
    85.6596968,
    47.4516275
  ],
  [
    85.6600784,
    47.4516275
  ],
  [
    85.6606621,
    47.4513999
  ],
  [
    85.6609202,
    47.4512026
  ],
  [
    85.6611335,
    47.4512254
  ],
  [
    85.6614814,
    47.451233
  ],
  [
    85.6618405,
    47.4512027
  ],
  [
    85.6619976,
    47.4511344
  ],
  [
    85.662166,
    47.4509598
  ],
  [
    85.662267,
    47.4507094
  ],
  [
    85.6623344,
    47.4504362
  ],
  [
    85.6628731,
    47.4502162
  ],
  [
    85.6631425,
    47.45011
  ],
  [
    85.6632772,
    47.45011
  ],
  [
    85.6636699,
    47.4503604
  ],
  [
    85.6639056,
    47.4504439
  ],
  [
    85.6642086,
    47.4503908
  ],
  [
    85.664433,
    47.4503074
  ],
  [
    85.6645677,
    47.4501404
  ],
  [
    85.6647473,
    47.4500645
  ],
  [
    85.6649942,
    47.4500949
  ],
  [
    85.665286,
    47.4500418
  ],
  [
    85.6655216,
    47.4499356
  ],
  [
    85.6657461,
    47.4496927
  ],
  [
    85.6658696,
    47.449541
  ],
  [
    85.6659145,
    47.4493209
  ],
  [
    85.6659931,
    47.4491387
  ],
  [
    85.6659931,
    47.44882
  ],
  [
    85.666038,
    47.4485468
  ],
  [
    85.6659034,
    47.448304
  ],
  [
    85.665862,
    47.4480984
  ],
  [
    85.6659518,
    47.4479922
  ],
  [
    85.666109,
    47.4478936
  ],
  [
    85.6669506,
    47.4476204
  ],
  [
    85.6671526,
    47.4475749
  ],
  [
    85.6673546,
    47.447499
  ],
  [
    85.6675117,
    47.44737
  ],
  [
    85.6676689,
    47.4472865
  ],
  [
    85.6679045,
    47.4472713
  ],
  [
    85.6683983,
    47.4472789
  ],
  [
    85.6687461,
    47.4472638
  ],
  [
    85.6689706,
    47.4472714
  ],
  [
    85.6691165,
    47.4471727
  ],
  [
    85.6688921,
    47.4464518
  ],
  [
    85.6688921,
    47.4462393
  ],
  [
    85.6690043,
    47.4461103
  ],
  [
    85.6692063,
    47.446004
  ],
  [
    85.6694532,
    47.4459357
  ],
  [
    85.6696215,
    47.4457612
  ],
  [
    85.6697786,
    47.4456777
  ],
  [
    85.6701601,
    47.4455942
  ],
  [
    85.6703059,
    47.4455259
  ],
  [
    85.6703397,
    47.4454804
  ],
  [
    85.670306,
    47.4454045
  ],
  [
    85.670306,
    47.4453134
  ],
  [
    85.6704519,
    47.4452375
  ],
  [
    85.670698,
    47.4452319
  ],
  [
    85.6711693,
    47.4450802
  ],
  [
    85.6715171,
    47.4448297
  ],
  [
    85.6716294,
    47.4446324
  ],
  [
    85.6715395,
    47.4444503
  ],
  [
    85.6715844,
    47.4442985
  ],
  [
    85.6717415,
    47.4441846
  ],
  [
    85.6719098,
    47.4440556
  ],
  [
    85.6719996,
    47.443957
  ],
  [
    85.6723699,
    47.4437445
  ],
  [
    85.6725045,
    47.4437293
  ],
  [
    85.6727177,
    47.4437596
  ],
  [
    85.6728412,
    47.4437369
  ],
  [
    85.6729198,
    47.4436686
  ],
  [
    85.6728861,
    47.4435699
  ],
  [
    85.6726842,
    47.4434257
  ],
  [
    85.6723027,
    47.443365
  ],
  [
    85.6718874,
    47.4432133
  ],
  [
    85.6717304,
    47.4428869
  ],
  [
    85.6717639,
    47.4427351
  ],
  [
    85.6719099,
    47.4426744
  ],
  [
    85.6722015,
    47.4426213
  ],
  [
    85.6725045,
    47.4426365
  ],
  [
    85.6727065,
    47.4424088
  ],
  [
    85.6728973,
    47.4422722
  ],
  [
    85.6729421,
    47.4421432
  ],
  [
    85.6728869,
    47.4419324
  ],
  [
    85.6729655,
    47.4416288
  ],
  [
    85.6732235,
    47.441386
  ],
  [
    85.6735377,
    47.4413556
  ],
  [
    85.6738743,
    47.4412494
  ],
  [
    85.6740651,
    47.4413253
  ],
  [
    85.6741548,
    47.4413177
  ],
  [
    85.6742446,
    47.441257
  ],
  [
    85.6742109,
    47.4411431
  ],
  [
    85.6742109,
    47.4410217
  ],
  [
    85.6743568,
    47.4408927
  ],
  [
    85.6744914,
    47.4408851
  ],
  [
    85.6745139,
    47.4407789
  ],
  [
    85.6744016,
    47.4405967
  ],
  [
    85.674368,
    47.4404753
  ],
  [
    85.6742507,
    47.4402962
  ],
  [
    85.6743405,
    47.4401824
  ],
  [
    85.67452,
    47.4400989
  ],
  [
    85.674823,
    47.4400002
  ],
  [
    85.6750586,
    47.4397877
  ],
  [
    85.6752045,
    47.4396283
  ],
  [
    85.6752942,
    47.4395145
  ],
  [
    85.675283,
    47.4393172
  ],
  [
    85.6753503,
    47.4391654
  ],
  [
    85.6753279,
    47.4390061
  ],
  [
    85.6751931,
    47.438915
  ],
  [
    85.6747893,
    47.4387025
  ],
  [
    85.674778,
    47.4385735
  ],
  [
    85.6748901,
    47.4384141
  ],
  [
    85.6749462,
    47.4383155
  ],
  [
    85.6749126,
    47.4381485
  ],
  [
    85.6747779,
    47.4378601
  ],
  [
    85.6748116,
    47.4377387
  ],
  [
    85.6749125,
    47.437678
  ],
  [
    85.6751033,
    47.437678
  ],
  [
    85.6753838,
    47.4377387
  ],
  [
    85.6756979,
    47.4377387
  ],
  [
    85.6757316,
    47.4376704
  ],
  [
    85.6757091,
    47.4374883
  ],
  [
    85.6754413,
    47.4373069
  ],
  [
    85.6753852,
    47.4372538
  ],
  [
    85.6754525,
    47.4371475
  ],
  [
    85.6756208,
    47.4370716
  ],
  [
    85.6758564,
    47.4368288
  ],
  [
    85.6758564,
    47.4367225
  ],
  [
    85.6754749,
    47.4365101
  ],
  [
    85.6754861,
    47.4363051
  ],
  [
    85.6756207,
    47.4362141
  ],
  [
    85.6758227,
    47.4361609
  ],
  [
    85.6761144,
    47.4362141
  ],
  [
    85.676451,
    47.4362368
  ],
  [
    85.6765856,
    47.4361533
  ],
  [
    85.6767314,
    47.4358725
  ],
  [
    85.6769221,
    47.4357207
  ],
  [
    85.6771802,
    47.4356297
  ],
  [
    85.6774831,
    47.4356069
  ],
  [
    85.6776963,
    47.4355537
  ],
  [
    85.67773,
    47.435493
  ],
  [
    85.6778422,
    47.4354399
  ],
  [
    85.6780666,
    47.4355537
  ],
  [
    85.6782235,
    47.4355765
  ],
  [
    85.6785153,
    47.4355537
  ],
  [
    85.6786611,
    47.4356372
  ],
  [
    85.6787733,
    47.4355537
  ],
  [
    85.6787734,
    47.4354095
  ],
  [
    85.6786723,
    47.4352046
  ],
  [
    85.6788629,
    47.4348934
  ],
  [
    85.6792332,
    47.4347416
  ],
  [
    85.6794127,
    47.4347189
  ],
  [
    85.6794575,
    47.4346354
  ],
  [
    85.6791546,
    47.4344381
  ],
  [
    85.6793453,
    47.4342939
  ],
  [
    85.6796145,
    47.4342559
  ],
  [
    85.6798052,
    47.4342104
  ],
  [
    85.6799511,
    47.4342255
  ],
  [
    85.680254,
    47.4341041
  ],
  [
    85.6803324,
    47.433873
  ],
  [
    85.6803772,
    47.433577
  ],
  [
    85.6801304,
    47.4333797
  ],
  [
    85.6798611,
    47.4332204
  ],
  [
    85.6797152,
    47.432932
  ],
  [
    85.6798161,
    47.4325981
  ],
  [
    85.680377,
    47.4324083
  ],
  [
    85.6804555,
    47.4322338
  ],
  [
    85.680635,
    47.432044
  ],
  [
    85.6809379,
    47.4319681
  ],
  [
    85.6810725,
    47.4318543
  ],
  [
    85.681207,
    47.4315431
  ],
  [
    85.6811308,
    47.431127
  ],
  [
    85.6814112,
    47.4310055
  ],
  [
    85.6819721,
    47.4309675
  ],
  [
    85.6821853,
    47.4309068
  ],
  [
    85.6823087,
    47.4308005
  ],
  [
    85.6826003,
    47.4308612
  ],
  [
    85.6827349,
    47.4307246
  ],
  [
    85.6827798,
    47.4304818
  ],
  [
    85.6827797,
    47.4302541
  ],
  [
    85.6829592,
    47.4302161
  ],
  [
    85.6830714,
    47.4302616
  ],
  [
    85.6831836,
    47.4302465
  ],
  [
    85.683262,
    47.4299808
  ],
  [
    85.6834078,
    47.4299125
  ],
  [
    85.683419,
    47.4298366
  ],
  [
    85.6833181,
    47.429738
  ],
  [
    85.6832395,
    47.4296545
  ],
  [
    85.68306,
    47.4296014
  ],
  [
    85.6828805,
    47.4293434
  ],
  [
    85.682858,
    47.429055
  ],
  [
    85.6826559,
    47.4286073
  ],
  [
    85.6827569,
    47.4283493
  ],
  [
    85.6829924,
    47.4281216
  ],
  [
    85.6831045,
    47.4280836
  ],
  [
    85.6828128,
    47.4278408
  ],
  [
    85.6828044,
    47.4277488
  ],
  [
    85.6829502,
    47.4276729
  ],
  [
    85.682995,
    47.4274073
  ],
  [
    85.6829165,
    47.4272783
  ],
  [
    85.6831183,
    47.4270126
  ],
  [
    85.6830846,
    47.426914
  ],
  [
    85.6830931,
    47.4267756
  ],
  [
    85.6831491,
    47.4265555
  ],
  [
    85.6832388,
    47.4263734
  ],
  [
    85.6833846,
    47.4262595
  ],
  [
    85.6835192,
    47.4259484
  ],
  [
    85.6836537,
    47.4257662
  ],
  [
    85.6836985,
    47.4255385
  ],
  [
    85.6836873,
    47.4252426
  ],
  [
    85.6836872,
    47.4251287
  ],
  [
    85.6838554,
    47.4249086
  ],
  [
    85.6840349,
    47.4248024
  ],
  [
    85.6842592,
    47.4247037
  ],
  [
    85.684405,
    47.4244381
  ],
  [
    85.6846293,
    47.4242635
  ],
  [
    85.6847862,
    47.4240965
  ],
  [
    85.6850666,
    47.423884
  ],
  [
    85.6850721,
    47.4237728
  ],
  [
    85.6852963,
    47.4232415
  ],
  [
    85.6852963,
    47.4230518
  ],
  [
    85.6848811,
    47.422422
  ],
  [
    85.6846007,
    47.4221792
  ],
  [
    85.6846231,
    47.4220729
  ],
  [
    85.6845782,
    47.4219515
  ],
  [
    85.6843538,
    47.4218301
  ],
  [
    85.6843201,
    47.4217542
  ],
  [
    85.6843762,
    47.42161
  ],
  [
    85.6845107,
    47.4214734
  ],
  [
    85.6846004,
    47.4213292
  ],
  [
    85.6846004,
    47.4211319
  ],
  [
    85.6844321,
    47.4209422
  ],
  [
    85.6841292,
    47.4207904
  ],
  [
    85.6841628,
    47.420669
  ],
  [
    85.684275,
    47.4206083
  ],
  [
    85.684432,
    47.4205627
  ],
  [
    85.6847573,
    47.420502
  ],
  [
    85.6849367,
    47.4204488
  ],
  [
    85.6850825,
    47.4203426
  ],
  [
    85.6851049,
    47.4201529
  ],
  [
    85.6850039,
    47.419948
  ],
  [
    85.6846673,
    47.4197431
  ],
  [
    85.6843763,
    47.4195723
  ],
  [
    85.6841246,
    47.4193534
  ],
  [
    85.6840887,
    47.4191913
  ],
  [
    85.6841246,
    47.4190373
  ],
  [
    85.6842443,
    47.4188833
  ],
  [
    85.6842802,
    47.4187941
  ],
  [
    85.6842203,
    47.4186482
  ],
  [
    85.6841604,
    47.4184861
  ],
  [
    85.6842083,
    47.4183726
  ],
  [
    85.684328,
    47.4183483
  ],
  [
    85.6843879,
    47.4181294
  ],
  [
    85.68428,
    47.418016
  ],
  [
    85.684304,
    47.4178457
  ],
  [
    85.6846633,
    47.4177646
  ],
  [
    85.6849748,
    47.4177484
  ],
  [
    85.6850586,
    47.4175944
  ],
  [
    85.6850346,
    47.4174728
  ],
  [
    85.6849028,
    47.4173836
  ],
  [
    85.684807,
    47.4173026
  ],
  [
    85.6846752,
    47.4172945
  ],
  [
    85.6844834,
    47.4169703
  ],
  [
    85.6844834,
    47.4168001
  ],
  [
    85.6845313,
    47.4166542
  ],
  [
    85.6846151,
    47.4164353
  ],
  [
    85.6847588,
    47.4163218
  ],
  [
    85.6849505,
    47.4163218
  ],
  [
    85.6851541,
    47.4163218
  ],
  [
    85.6853577,
    47.4162974
  ],
  [
    85.6855254,
    47.4162001
  ],
  [
    85.6856092,
    47.4160947
  ],
  [
    85.6857051,
    47.4160542
  ],
  [
    85.6858129,
    47.4160866
  ],
  [
    85.6858488,
    47.4160623
  ],
  [
    85.685693,
    47.415811
  ],
  [
    85.6854534,
    47.4156408
  ],
  [
    85.6853695,
    47.4155355
  ],
  [
    85.6852257,
    47.4154463
  ],
  [
    85.6852045,
    47.4153378
  ],
  [
    85.6853243,
    47.4152729
  ],
  [
    85.685468,
    47.4153377
  ],
  [
    85.6855399,
    47.4153296
  ],
  [
    85.6855399,
    47.4152648
  ],
  [
    85.6854919,
    47.4151432
  ],
  [
    85.6852403,
    47.4149649
  ],
  [
    85.6850246,
    47.4146083
  ],
  [
    85.6848209,
    47.4143327
  ],
  [
    85.6844584,
    47.4141208
  ],
  [
    85.6843985,
    47.4140235
  ],
  [
    85.6845421,
    47.4136587
  ],
  [
    85.6846499,
    47.413529
  ],
  [
    85.6848774,
    47.4133345
  ],
  [
    85.6849253,
    47.4131399
  ],
  [
    85.6849132,
    47.4128562
  ],
  [
    85.6850689,
    47.4127346
  ],
  [
    85.6852007,
    47.4126292
  ],
  [
    85.6853803,
    47.412686
  ],
  [
    85.6855121,
    47.4126373
  ],
  [
    85.6856917,
    47.4125157
  ],
  [
    85.6858594,
    47.4122968
  ],
  [
    85.6859072,
    47.4121104
  ],
  [
    85.6858473,
    47.4119483
  ],
  [
    85.6858233,
    47.4118186
  ],
  [
    85.6859071,
    47.4117051
  ],
  [
    85.6861467,
    47.4115754
  ],
  [
    85.6862675,
    47.411372
  ],
  [
    85.6861717,
    47.4112747
  ],
  [
    85.6857884,
    47.41121
  ],
  [
    85.6856686,
    47.41121
  ],
  [
    85.6856086,
    47.410829
  ],
  [
    85.6854049,
    47.4105859
  ],
  [
    85.6854768,
    47.4104886
  ],
  [
    85.6858959,
    47.4102535
  ],
  [
    85.6860516,
    47.4101156
  ],
  [
    85.686327,
    47.4098319
  ],
  [
    85.6862551,
    47.4096131
  ],
  [
    85.6861353,
    47.409532
  ],
  [
    85.6860274,
    47.409378
  ],
  [
    85.6859795,
    47.4092402
  ],
  [
    85.6860633,
    47.4091592
  ],
  [
    85.686231,
    47.4091105
  ],
  [
    85.686207,
    47.4090376
  ],
  [
    85.6860992,
    47.408916
  ],
  [
    85.6853924,
    47.408608
  ],
  [
    85.6853503,
    47.4083639
  ],
  [
    85.6851826,
    47.408218
  ],
  [
    85.6850628,
    47.4081046
  ],
  [
    85.6850268,
    47.4079181
  ],
  [
    85.6850866,
    47.4077965
  ],
  [
    85.6850147,
    47.4076668
  ],
  [
    85.6847033,
    47.4074967
  ],
  [
    85.6846793,
    47.4073913
  ],
  [
    85.684811,
    47.407294
  ],
  [
    85.684823,
    47.4071724
  ],
  [
    85.6846194,
    47.4071076
  ],
  [
    85.6840684,
    47.4070915
  ],
  [
    85.6838528,
    47.4070347
  ],
  [
    85.6836372,
    47.4068726
  ],
  [
    85.6835893,
    47.4067186
  ],
  [
    85.6834934,
    47.4066133
  ],
  [
    85.6836251,
    47.4064511
  ],
  [
    85.683649,
    47.406289
  ],
  [
    85.6835292,
    47.406054
  ],
  [
    85.6833735,
    47.4059891
  ],
  [
    85.6833016,
    47.4058838
  ],
  [
    85.6834921,
    47.4056383
  ],
  [
    85.683516,
    47.4054275
  ],
  [
    85.6834561,
    47.4052735
  ],
  [
    85.683456,
    47.4051033
  ],
  [
    85.6832763,
    47.4048925
  ],
  [
    85.6832883,
    47.4047466
  ],
  [
    85.6833242,
    47.4045359
  ],
  [
    85.6832882,
    47.4043981
  ],
  [
    85.6832882,
    47.4042117
  ],
  [
    85.6832402,
    47.4039604
  ],
  [
    85.6833479,
    47.4035794
  ],
  [
    85.6833359,
    47.403393
  ],
  [
    85.6834317,
    47.403239
  ],
  [
    85.6836422,
    47.4029256
  ],
  [
    85.683726,
    47.4027149
  ],
  [
    85.6840613,
    47.4024311
  ],
  [
    85.6842528,
    47.4021312
  ],
  [
    85.6844923,
    47.4018313
  ],
  [
    85.684576,
    47.4016286
  ],
  [
    85.6847197,
    47.4014665
  ],
  [
    85.6849113,
    47.4012719
  ],
  [
    85.6850429,
    47.400972
  ],
  [
    85.6850069,
    47.4007126
  ],
  [
    85.6851027,
    47.4004613
  ],
  [
    85.6852583,
    47.4003397
  ],
  [
    85.6852582,
    47.4000965
  ],
  [
    85.6853899,
    47.3998939
  ],
  [
    85.6853659,
    47.3996183
  ],
  [
    85.6854471,
    47.3993798
  ],
  [
    85.6856065,
    47.399029
  ],
  [
    85.6856329,
    47.3986872
  ],
  [
    85.6852209,
    47.3981026
  ],
  [
    85.6850745,
    47.3974101
  ],
  [
    85.6850877,
    47.3971762
  ],
  [
    85.6853534,
    47.3969424
  ],
  [
    85.6856856,
    47.3969963
  ],
  [
    85.6858185,
    47.3969513
  ],
  [
    85.6859646,
    47.3966814
  ],
  [
    85.6862861,
    47.3965339
  ],
  [
    85.6862861,
    47.3964169
  ],
  [
    85.6860601,
    47.3962461
  ],
  [
    85.6859803,
    47.3960212
  ],
  [
    85.6860334,
    47.3957964
  ],
  [
    85.6859005,
    47.3955805
  ],
  [
    85.6859005,
    47.3954006
  ],
  [
    85.686113,
    47.3952477
  ],
  [
    85.6863256,
    47.3952117
  ],
  [
    85.6865116,
    47.3950947
  ],
  [
    85.6867507,
    47.3948968
  ],
  [
    85.6868702,
    47.3947169
  ],
  [
    85.686817,
    47.394564
  ],
  [
    85.6868435,
    47.3944381
  ],
  [
    85.6874148,
    47.3943571
  ],
  [
    85.6875211,
    47.3943121
  ],
  [
    85.6875742,
    47.3942221
  ],
  [
    85.6874413,
    47.3940243
  ],
  [
    85.687335,
    47.3938084
  ],
  [
    85.6872951,
    47.3936555
  ],
  [
    85.6874277,
    47.3930889
  ],
  [
    85.6876402,
    47.392774
  ],
  [
    85.6879324,
    47.3924412
  ],
  [
    85.6882911,
    47.3923152
  ],
  [
    85.6886232,
    47.3921893
  ],
  [
    85.6888889,
    47.3921263
  ],
  [
    85.6890217,
    47.3919014
  ],
  [
    85.6890747,
    47.3915776
  ],
  [
    85.6892474,
    47.3913617
  ],
  [
    85.6895796,
    47.391332
  ],
  [
    85.6897125,
    47.391224
  ],
  [
    85.6898718,
    47.3909632
  ],
  [
    85.6903102,
    47.3907832
  ],
  [
    85.6906688,
    47.3905223
  ],
  [
    85.6914659,
    47.3904952
  ],
  [
    85.6917183,
    47.3904142
  ],
  [
    85.6918777,
    47.3902972
  ],
  [
    85.691915,
    47.3901033
  ],
  [
    85.6918151,
    47.389956
  ],
  [
    85.6915056,
    47.3897862
  ],
  [
    85.6914522,
    47.3895707
  ],
  [
    85.6915985,
    47.3894412
  ],
  [
    85.6916904,
    47.3892767
  ],
  [
    85.6919036,
    47.3892641
  ],
  [
    85.6922069,
    47.3891944
  ],
  [
    85.6922425,
    47.3890887
  ],
  [
    85.692029,
    47.3886886
  ],
  [
    85.6918849,
    47.388363
  ],
  [
    85.6919562,
    47.3880756
  ],
  [
    85.6921478,
    47.3879796
  ],
  [
    85.6927391,
    47.3880025
  ],
  [
    85.6928478,
    47.387892
  ],
  [
    85.692816,
    47.3875811
  ],
  [
    85.6929349,
    47.3872892
  ],
  [
    85.6931762,
    47.3871019
  ],
  [
    85.6935326,
    47.3870442
  ],
  [
    85.693878,
    47.387171
  ],
  [
    85.6941052,
    47.3871908
  ],
  [
    85.6943026,
    47.3870908
  ],
  [
    85.6943845,
    47.3868459
  ],
  [
    85.6947867,
    47.3867454
  ],
  [
    85.6948044,
    47.3866391
  ],
  [
    85.6945682,
    47.3864497
  ],
  [
    85.6945687,
    47.386309
  ],
  [
    85.6946688,
    47.3862398
  ],
  [
    85.6948403,
    47.3862155
  ],
  [
    85.6949449,
    47.3861555
  ],
  [
    85.6950918,
    47.3859508
  ],
  [
    85.6953166,
    47.3859479
  ],
  [
    85.6956912,
    47.3860628
  ],
  [
    85.6960525,
    47.3860698
  ],
  [
    85.6966373,
    47.3858605
  ],
  [
    85.696925,
    47.3856835
  ],
  [
    85.6968344,
    47.385205
  ],
  [
    85.6969864,
    47.3851118
  ],
  [
    85.6975602,
    47.3851965
  ],
  [
    85.6976931,
    47.3851252
  ],
  [
    85.6977898,
    47.3848648
  ],
  [
    85.6980046,
    47.3847395
  ],
  [
    85.6982847,
    47.3847708
  ],
  [
    85.698579,
    47.3846344
  ],
  [
    85.6988381,
    47.384577
  ],
  [
    85.6993108,
    47.3844861
  ],
  [
    85.699309,
    47.3844188
  ],
  [
    85.6991893,
    47.384158
  ],
  [
    85.6992025,
    47.3839871
  ],
  [
    85.6993884,
    47.3837262
  ],
  [
    85.699654,
    47.3835913
  ],
  [
    85.7003977,
    47.3832673
  ],
  [
    85.7006101,
    47.3830603
  ],
  [
    85.7010218,
    47.3828084
  ],
  [
    85.7010464,
    47.3824835
  ],
  [
    85.7011792,
    47.3824295
  ],
  [
    85.7014448,
    47.3824204
  ],
  [
    85.7017105,
    47.3823574
  ],
  [
    85.702069,
    47.3821144
  ],
  [
    85.7021088,
    47.3820245
  ],
  [
    85.7018031,
    47.3816648
  ],
  [
    85.7019888,
    47.381287
  ],
  [
    85.7023607,
    47.381152
  ],
  [
    85.7024403,
    47.38099
  ],
  [
    85.7023604,
    47.3807652
  ],
  [
    85.7023869,
    47.3806843
  ],
  [
    85.7025994,
    47.3805493
  ],
  [
    85.702918,
    47.3802524
  ],
  [
    85.7029577,
    47.3800725
  ],
  [
    85.7023465,
    47.3796319
  ],
  [
    85.7022534,
    47.3794791
  ],
  [
    85.7020939,
    47.3793262
  ],
  [
    85.7020842,
    47.3792405
  ],
  [
    85.701938,
    47.3790786
  ],
  [
    85.7016059,
    47.3790427
  ],
  [
    85.7013136,
    47.3789439
  ],
  [
    85.7011408,
    47.378764
  ],
  [
    85.7011806,
    47.3785661
  ],
  [
    85.7013797,
    47.3783592
  ],
  [
    85.7014725,
    47.3781703
  ],
  [
    85.7013396,
    47.3779095
  ],
  [
    85.7012332,
    47.3777836
  ],
  [
    85.7012198,
    47.3774958
  ],
  [
    85.7009806,
    47.377208
  ],
  [
    85.7009672,
    47.3770551
  ],
  [
    85.7010867,
    47.3769382
  ],
  [
    85.7015117,
    47.3769201
  ],
  [
    85.7017108,
    47.3767491
  ],
  [
    85.7016576,
    47.3766052
  ],
  [
    85.7016045,
    47.3764703
  ],
  [
    85.7014982,
    47.3763714
  ],
  [
    85.701352,
    47.3762186
  ],
  [
    85.7013519,
    47.3760387
  ],
  [
    85.7012322,
    47.3758678
  ],
  [
    85.7010463,
    47.3757869
  ],
  [
    85.7009,
    47.375643
  ],
  [
    85.7007255,
    47.3754438
  ],
  [
    85.7007521,
    47.3753718
  ],
  [
    85.7009247,
    47.3752728
  ],
  [
    85.7012036,
    47.3752728
  ],
  [
    85.7012567,
    47.3751378
  ],
  [
    85.7012033,
    47.374922
  ],
  [
    85.7013094,
    47.3746162
  ],
  [
    85.7012961,
    47.3745172
  ],
  [
    85.7010437,
    47.3744363
  ],
  [
    85.700964,
    47.3743284
  ],
  [
    85.7010037,
    47.3742295
  ],
  [
    85.7006714,
    47.3737169
  ],
  [
    85.7005783,
    47.3734111
  ],
  [
    85.7006446,
    47.3731323
  ],
  [
    85.7007641,
    47.3730423
  ],
  [
    85.700764,
    47.3729434
  ],
  [
    85.700671,
    47.3728175
  ],
  [
    85.7003256,
    47.3726826
  ],
  [
    85.6996879,
    47.372431
  ],
  [
    85.699555,
    47.3723051
  ],
  [
    85.6995017,
    47.3718014
  ],
  [
    85.6992891,
    47.3715226
  ],
  [
    85.699342,
    47.3712708
  ],
  [
    85.6995413,
    47.3713337
  ],
  [
    85.6997538,
    47.3713336
  ],
  [
    85.69986,
    47.3712706
  ],
  [
    85.6998334,
    47.3711537
  ],
  [
    85.6996474,
    47.3710278
  ],
  [
    85.6992334,
    47.3710422
  ],
  [
    85.698888,
    47.3709434
  ],
  [
    85.6985958,
    47.3707726
  ],
  [
    85.6983832,
    47.3705118
  ],
  [
    85.698144,
    47.370215
  ],
  [
    85.697533,
    47.3701612
  ],
  [
    85.6974134,
    47.3700443
  ],
  [
    85.6974531,
    47.3699454
  ],
  [
    85.6979179,
    47.3697834
  ],
  [
    85.6985155,
    47.3697922
  ],
  [
    85.698728,
    47.3698371
  ],
  [
    85.6990334,
    47.3697201
  ],
  [
    85.69902,
    47.3695223
  ],
  [
    85.6988207,
    47.3693694
  ],
  [
    85.6989932,
    47.3691085
  ],
  [
    85.6991392,
    47.3689196
  ],
  [
    85.6990063,
    47.3687218
  ],
  [
    85.6991257,
    47.3685688
  ],
  [
    85.6990327,
    47.3684699
  ],
  [
    85.6986483,
    47.3683255
  ],
  [
    85.6985022,
    47.3681547
  ],
  [
    85.6986349,
    47.3680287
  ],
  [
    85.6982495,
    47.3675701
  ],
  [
    85.6980502,
    47.3673363
  ],
  [
    85.6980766,
    47.3670035
  ],
  [
    85.6976382,
    47.3666708
  ],
  [
    85.6973859,
    47.3665899
  ],
  [
    85.6970804,
    47.3664641
  ],
  [
    85.6966021,
    47.3660235
  ],
  [
    85.6966684,
    47.3657986
  ],
  [
    85.6965754,
    47.3656817
  ],
  [
    85.6963894,
    47.3656278
  ],
  [
    85.6962035,
    47.3656368
  ],
  [
    85.6958849,
    47.3657358
  ],
  [
    85.6956857,
    47.3657808
  ],
  [
    85.6951296,
    47.3657471
  ],
  [
    85.6949304,
    47.3657831
  ],
  [
    85.6947711,
    47.3658281
  ],
  [
    85.694625,
    47.3657472
  ],
  [
    85.6944257,
    47.3655853
  ],
  [
    85.6942265,
    47.3655134
  ],
  [
    85.693629,
    47.3657384
  ],
  [
    85.6932571,
    47.3656485
  ],
  [
    85.6929118,
    47.3654777
  ],
  [
    85.692633,
    47.3654598
  ],
  [
    85.6922213,
    47.3655228
  ],
  [
    85.6920886,
    47.3656398
  ],
  [
    85.6922348,
    47.3660984
  ],
  [
    85.6921419,
    47.3662694
  ],
  [
    85.6919295,
    47.3663234
  ],
  [
    85.6916373,
    47.3662515
  ],
  [
    85.6914513,
    47.3660176
  ],
  [
    85.6907741,
    47.3661077
  ],
  [
    85.6902562,
    47.3661438
  ],
  [
    85.6898312,
    47.3660989
  ],
  [
    85.6894594,
    47.3661529
  ],
  [
    85.6892203,
    47.365955
  ],
  [
    85.6892203,
    47.3657842
  ],
  [
    85.6894194,
    47.3656132
  ],
  [
    85.6894195,
    47.3655143
  ],
  [
    85.6892069,
    47.3655053
  ],
  [
    85.6891139,
    47.3653614
  ],
  [
    85.6888748,
    47.3652895
  ],
  [
    85.6885694,
    47.3653525
  ],
  [
    85.6882906,
    47.3653885
  ],
  [
    85.6880515,
    47.3653166
  ],
  [
    85.6879187,
    47.3651907
  ],
  [
    85.6879319,
    47.3650108
  ],
  [
    85.6881842,
    47.3648039
  ],
  [
    85.6884707,
    47.3645882
  ],
  [
    85.6886035,
    47.3644713
  ],
  [
    85.68863,
    47.3643363
  ],
  [
    85.688391,
    47.3642284
  ],
  [
    85.6881386,
    47.3641025
  ],
  [
    85.6880723,
    47.3639676
  ],
  [
    85.6878597,
    47.3637518
  ],
  [
    85.6877933,
    47.3636349
  ],
  [
    85.6878198,
    47.3635359
  ],
  [
    85.6879526,
    47.363419
  ],
  [
    85.6881783,
    47.36332
  ],
  [
    85.6883907,
    47.3632031
  ],
  [
    85.6885367,
    47.3629692
  ],
  [
    85.6885499,
    47.3627803
  ],
  [
    85.6876071,
    47.3626275
  ],
  [
    85.6874477,
    47.3624027
  ],
  [
    85.6870493,
    47.3622499
  ],
  [
    85.6868766,
    47.362079
  ],
  [
    85.6868499,
    47.3616113
  ],
  [
    85.6869694,
    47.3614584
  ],
  [
    85.6875137,
    47.3612244
  ],
  [
    85.6875801,
    47.3611345
  ],
  [
    85.6872878,
    47.3607298
  ],
  [
    85.6871683,
    47.360478
  ],
  [
    85.6869956,
    47.360442
  ],
  [
    85.6868495,
    47.3603341
  ],
  [
    85.6868361,
    47.3599024
  ],
  [
    85.6871005,
    47.3597601
  ],
  [
    85.6873263,
    47.3597061
  ],
  [
    85.6874989,
    47.3596431
  ],
  [
    85.687605,
    47.3594722
  ],
  [
    85.6874854,
    47.3591664
  ],
  [
    85.6876049,
    47.3589416
  ],
  [
    85.688189,
    47.3586267
  ],
  [
    85.6880827,
    47.3584108
  ],
  [
    85.688441,
    47.3578171
  ],
  [
    85.6882816,
    47.3576732
  ],
  [
    85.6881621,
    47.3575563
  ],
  [
    85.6881488,
    47.3574304
  ],
  [
    85.6882948,
    47.3573225
  ],
  [
    85.6885338,
    47.3572055
  ],
  [
    85.6887064,
    47.3571785
  ],
  [
    85.6888126,
    47.3571245
  ],
  [
    85.6888656,
    47.3570166
  ],
  [
    85.6888656,
    47.3569086
  ],
  [
    85.6886797,
    47.3567108
  ],
  [
    85.6884406,
    47.3566029
  ],
  [
    85.6883344,
    47.356495
  ],
  [
    85.6883343,
    47.3563151
  ],
  [
    85.6884538,
    47.3561802
  ],
  [
    85.6885068,
    47.3559823
  ],
  [
    85.6885557,
    47.3557871
  ],
  [
    85.688657,
    47.3554893
  ],
  [
    85.688843,
    47.3553404
  ],
  [
    85.6889613,
    47.3552258
  ],
  [
    85.6888936,
    47.355054
  ],
  [
    85.6887414,
    47.3549166
  ],
  [
    85.6882341,
    47.3548365
  ],
  [
    85.6879128,
    47.3547678
  ],
  [
    85.687676,
    47.35447
  ],
  [
    85.6877098,
    47.3542524
  ],
  [
    85.6874899,
    47.3541379
  ],
  [
    85.6874223,
    47.3540119
  ],
  [
    85.6874729,
    47.3538171
  ],
  [
    85.6878449,
    47.3536453
  ],
  [
    85.6879801,
    47.3535422
  ],
  [
    85.6879124,
    47.3534276
  ],
  [
    85.6875911,
    47.3532444
  ],
  [
    85.6873543,
    47.3529466
  ],
  [
    85.6875233,
    47.3528091
  ],
  [
    85.6876754,
    47.35258
  ],
  [
    85.6876077,
    47.3523624
  ],
  [
    85.6869918,
    47.3520416
  ],
  [
    85.6868564,
    47.3516751
  ],
  [
    85.6869409,
    47.3514574
  ],
  [
    85.6872789,
    47.3511595
  ],
  [
    85.6873972,
    47.3508503
  ],
  [
    85.6876,
    47.350667
  ],
  [
    85.6876676,
    47.3505753
  ],
  [
    85.6875154,
    47.3504035
  ],
  [
    85.6871603,
    47.3502432
  ],
  [
    85.6869912,
    47.3500141
  ],
  [
    85.6870419,
    47.3497736
  ],
  [
    85.686822,
    47.3495331
  ],
  [
    85.6864669,
    47.3493155
  ],
  [
    85.6864161,
    47.3491322
  ],
  [
    85.6865344,
    47.3489833
  ],
  [
    85.6868218,
    47.3487656
  ],
  [
    85.6869231,
    47.3484907
  ],
  [
    85.686754,
    47.3481815
  ],
  [
    85.6865004,
    47.3481013
  ],
  [
    85.6859422,
    47.3472881
  ],
  [
    85.6858915,
    47.347082
  ],
  [
    85.6860267,
    47.3470018
  ],
  [
    85.6859868,
    47.3462718
  ],
  [
    85.6857162,
    47.3459396
  ],
  [
    85.6857162,
    47.3457678
  ],
  [
    85.6858852,
    47.3456533
  ],
  [
    85.6861387,
    47.3455845
  ],
  [
    85.6861894,
    47.3454699
  ],
  [
    85.6861386,
    47.345218
  ],
  [
    85.6859357,
    47.3449431
  ],
  [
    85.6857834,
    47.3443475
  ],
  [
    85.6858002,
    47.3440382
  ],
  [
    85.6861044,
    47.3438205
  ],
  [
    85.6867467,
    47.3434883
  ],
  [
    85.6868988,
    47.3433164
  ],
  [
    85.6869833,
    47.3431102
  ],
  [
    85.686831,
    47.342801
  ],
  [
    85.6869155,
    47.3424688
  ],
  [
    85.6869154,
    47.342297
  ],
  [
    85.686628,
    47.3421023
  ],
  [
    85.6863237,
    47.3420909
  ],
  [
    85.6860532,
    47.3419764
  ],
  [
    85.6859686,
    47.3415755
  ],
  [
    85.6861491,
    47.3411523
  ],
  [
    85.6862505,
    47.3408888
  ],
  [
    85.6862504,
    47.3407284
  ],
  [
    85.6858615,
    47.3403161
  ],
  [
    85.6855741,
    47.34011
  ],
  [
    85.685405,
    47.339858
  ],
  [
    85.6854726,
    47.3396404
  ],
  [
    85.685743,
    47.3394914
  ],
  [
    85.685912,
    47.3393425
  ],
  [
    85.6855231,
    47.3389302
  ],
  [
    85.6854216,
    47.338598
  ],
  [
    85.6855399,
    47.338472
  ],
  [
    85.6861315,
    47.3384032
  ],
  [
    85.6863173,
    47.3382543
  ],
  [
    85.6863849,
    47.3379908
  ],
  [
    85.6863002,
    47.3373837
  ],
  [
    85.6863508,
    47.337189
  ],
  [
    85.6863508,
    47.3370286
  ],
  [
    85.6859958,
    47.3369256
  ],
  [
    85.6860465,
    47.3367309
  ],
  [
    85.6863169,
    47.3366621
  ],
  [
    85.6865028,
    47.3365017
  ],
  [
    85.6864344,
    47.3362754
  ],
  [
    85.686147,
    47.3360921
  ],
  [
    85.6859272,
    47.3359204
  ],
  [
    85.6859948,
    47.3357027
  ],
  [
    85.6861637,
    47.3355194
  ],
  [
    85.6864003,
    47.3352788
  ],
  [
    85.6864171,
    47.3350841
  ],
  [
    85.686265,
    47.3348436
  ],
  [
    85.6861804,
    47.3346374
  ],
  [
    85.6858254,
    47.3343855
  ],
  [
    85.6858591,
    47.334122
  ],
  [
    85.6860788,
    47.3338814
  ],
  [
    85.6862985,
    47.3337783
  ],
  [
    85.6864336,
    47.333595
  ],
  [
    85.6866702,
    47.333343
  ],
  [
    85.6865518,
    47.3331712
  ],
  [
    85.6860785,
    47.3329422
  ],
  [
    85.6858925,
    47.3327475
  ],
  [
    85.6858925,
    47.3325413
  ],
  [
    85.6860952,
    47.3323695
  ],
  [
    85.6861966,
    47.3321977
  ],
  [
    85.6863825,
    47.3320029
  ],
  [
    85.6864669,
    47.3318196
  ],
  [
    85.6858584,
    47.3316593
  ],
  [
    85.6852837,
    47.3313501
  ],
  [
    85.685403,
    47.3308572
  ],
  [
    85.6856564,
    47.3304104
  ],
  [
    85.6856394,
    47.330021
  ],
  [
    85.6854028,
    47.3299293
  ],
  [
    85.6851662,
    47.3299523
  ],
  [
    85.6846592,
    47.3301814
  ],
  [
    85.6843719,
    47.3302044
  ],
  [
    85.6842029,
    47.3300898
  ],
  [
    85.6841014,
    47.3298264
  ],
  [
    85.6837634,
    47.3296661
  ],
  [
    85.6833747,
    47.3296432
  ],
  [
    85.6832733,
    47.3295516
  ],
  [
    85.6832225,
    47.3292538
  ],
  [
    85.683138,
    47.3290476
  ],
  [
    85.6829858,
    47.3288644
  ],
  [
    85.6825127,
    47.3289331
  ],
  [
    85.6823098,
    47.3286697
  ],
  [
    85.6822929,
    47.3283833
  ],
  [
    85.6820731,
    47.3281657
  ],
  [
    85.6821914,
    47.3279366
  ],
  [
    85.6820223,
    47.327719
  ],
  [
    85.681735,
    47.3274098
  ],
  [
    85.6813632,
    47.3273296
  ],
  [
    85.6808055,
    47.3270433
  ],
  [
    85.6804762,
    47.3268659
  ],
  [
    85.680172,
    47.3266712
  ],
  [
    85.6799861,
    47.3264306
  ],
  [
    85.6797157,
    47.3262474
  ],
  [
    85.6794622,
    47.326339
  ],
  [
    85.6792425,
    47.3262818
  ],
  [
    85.6790059,
    47.3260985
  ],
  [
    85.6780257,
    47.3256289
  ],
  [
    85.6778905,
    47.325228
  ],
  [
    85.677958,
    47.3249302
  ],
  [
    85.6774003,
    47.3246783
  ],
  [
    85.677113,
    47.3243117
  ],
  [
    85.6766399,
    47.3240827
  ],
  [
    85.6757105,
    47.3237162
  ],
  [
    85.6754569,
    47.3233038
  ],
  [
    85.6752034,
    47.32288
  ],
  [
    85.6734461,
    47.3227083
  ],
  [
    85.6732095,
    47.322525
  ],
  [
    85.6731371,
    47.3223491
  ],
  [
    85.6731202,
    47.3221543
  ],
  [
    85.6735764,
    47.3215472
  ],
  [
    85.6736102,
    47.3211921
  ],
  [
    85.6729343,
    47.32086
  ],
  [
    85.6729343,
    47.3206767
  ],
  [
    85.6730695,
    47.3205393
  ],
  [
    85.6733061,
    47.3204934
  ],
  [
    85.6737454,
    47.3202414
  ],
  [
    85.6737454,
    47.3200582
  ],
  [
    85.6735426,
    47.319852
  ],
  [
    85.6734412,
    47.3194167
  ],
  [
    85.6732384,
    47.3189815
  ],
  [
    85.6732046,
    47.3188211
  ],
  [
    85.6732891,
    47.3186493
  ],
  [
    85.6732891,
    47.3185347
  ],
  [
    85.6729849,
    47.3184431
  ],
  [
    85.6727653,
    47.3182369
  ],
  [
    85.6727315,
    47.318088
  ],
  [
    85.6726639,
    47.3179277
  ],
  [
    85.6728666,
    47.3176642
  ],
  [
    85.6729676,
    47.3174327
  ],
  [
    85.6733562,
    47.3170891
  ],
  [
    85.6733055,
    47.3168944
  ],
  [
    85.6733393,
    47.3167111
  ],
  [
    85.6732379,
    47.3165966
  ],
  [
    85.6731027,
    47.3164477
  ],
  [
    85.6732885,
    47.3158291
  ],
  [
    85.6736265,
    47.3156344
  ],
  [
    85.674184,
    47.3147753
  ],
  [
    85.674336,
    47.3144775
  ],
  [
    85.6747752,
    47.3140422
  ],
  [
    85.6746232,
    47.313962
  ],
  [
    85.6742975,
    47.3137634
  ],
  [
    85.6742975,
    47.3135343
  ],
  [
    85.6743651,
    47.3131678
  ],
  [
    85.6744495,
    47.3129043
  ],
  [
    85.6741961,
    47.3127325
  ],
  [
    85.6741454,
    47.3126065
  ],
  [
    85.6743143,
    47.3121483
  ],
  [
    85.6746353,
    47.3118391
  ],
  [
    85.6748549,
    47.3113923
  ],
  [
    85.6750914,
    47.3112091
  ],
  [
    85.6757165,
    47.3110601
  ],
  [
    85.6759699,
    47.3109456
  ],
  [
    85.6764767,
    47.3108196
  ],
  [
    85.6771017,
    47.3104415
  ],
  [
    85.6774396,
    47.3103041
  ],
  [
    85.6783349,
    47.3102697
  ],
  [
    85.6790951,
    47.3099947
  ],
  [
    85.6796249,
    47.3099139
  ],
  [
    85.6800952,
    47.3100525
  ],
  [
    85.680361,
    47.3100663
  ],
  [
    85.6806676,
    47.3099693
  ],
  [
    85.6808312,
    47.3097891
  ],
  [
    85.6806676,
    47.3095118
  ],
  [
    85.6805449,
    47.3093039
  ],
  [
    85.6807084,
    47.3092069
  ],
  [
    85.6811786,
    47.3093038
  ],
  [
    85.6817102,
    47.3093592
  ],
  [
    85.6820169,
    47.3092622
  ],
  [
    85.6823235,
    47.309179
  ],
  [
    85.6826915,
    47.3092621
  ],
  [
    85.6830595,
    47.3092621
  ],
  [
    85.683775,
    47.3090679
  ],
  [
    85.684327,
    47.3089431
  ],
  [
    85.6846745,
    47.3087767
  ],
  [
    85.684838,
    47.3085272
  ],
  [
    85.6847357,
    47.3083054
  ],
  [
    85.6848992,
    47.3079588
  ],
  [
    85.6848991,
    47.3077648
  ],
  [
    85.6850365,
    47.3074993
  ],
  [
    85.6848916,
    47.3073785
  ],
  [
    85.6847245,
    47.3070509
  ],
  [
    85.6845607,
    47.3067342
  ],
  [
    85.6846383,
    47.3064191
  ],
  [
    85.6845549,
    47.3061857
  ],
  [
    85.6842424,
    47.3060176
  ],
  [
    85.6839828,
    47.305531
  ],
  [
    85.6837799,
    47.3053637
  ],
  [
    85.6838022,
    47.3051466
  ],
  [
    85.6840822,
    47.3049213
  ],
  [
    85.6844209,
    47.3046175
  ],
  [
    85.684608,
    47.3045459
  ],
  [
    85.6850905,
    47.3041833
  ],
  [
    85.6854324,
    47.3036392
  ],
  [
    85.6854437,
    47.303348
  ],
  [
    85.6855843,
    47.3030394
  ],
  [
    85.6858776,
    47.3028463
  ],
  [
    85.6859087,
    47.3026157
  ],
  [
    85.6863177,
    47.3023607
  ],
  [
    85.686549,
    47.3022862
  ],
  [
    85.6867074,
    47.302069
  ],
  [
    85.6870492,
    47.3018198
  ],
  [
    85.6873284,
    47.3015325
  ],
  [
    85.6872959,
    47.3012279
  ],
  [
    85.6871511,
    47.3009637
  ],
  [
    85.6874014,
    47.3007755
  ],
  [
    85.6878504,
    47.3007942
  ],
  [
    85.6881022,
    47.3007941
  ],
  [
    85.6886757,
    47.3002703
  ],
  [
    85.6892488,
    47.3001081
  ],
  [
    85.6898772,
    47.3001132
  ],
  [
    85.6898445,
    47.299407
  ],
  [
    85.6903029,
    47.2991865
  ],
  [
    85.6904408,
    47.2987895
  ],
  [
    85.6907601,
    47.2984967
  ],
  [
    85.690965,
    47.2982097
  ],
  [
    85.6913958,
    47.2981011
  ],
  [
    85.691682,
    47.2978454
  ],
  [
    85.6917314,
    47.2973982
  ],
  [
    85.6921809,
    47.2970655
  ],
  [
    85.6918333,
    47.2966219
  ],
  [
    85.6919558,
    47.2963446
  ],
  [
    85.6922419,
    47.2962198
  ],
  [
    85.6924053,
    47.2960119
  ],
  [
    85.6923643,
    47.2956099
  ],
  [
    85.692446,
    47.2954435
  ],
  [
    85.6927525,
    47.295388
  ],
  [
    85.6930795,
    47.2953325
  ],
  [
    85.693386,
    47.2950691
  ],
  [
    85.6933451,
    47.2948889
  ],
  [
    85.6933245,
    47.2946255
  ],
  [
    85.6933858,
    47.2944453
  ],
  [
    85.6935901,
    47.2943205
  ],
  [
    85.6938149,
    47.2943759
  ],
  [
    85.6939785,
    47.2943897
  ],
  [
    85.694101,
    47.2942788
  ],
  [
    85.6941213,
    47.2939461
  ],
  [
    85.6942848,
    47.2937936
  ],
  [
    85.6945504,
    47.2937103
  ],
  [
    85.6947753,
    47.2936964
  ],
  [
    85.6949387,
    47.2935855
  ],
  [
    85.6949182,
    47.2933637
  ],
  [
    85.6951225,
    47.2931696
  ],
  [
    85.6954699,
    47.2930448
  ],
  [
    85.6957968,
    47.2927952
  ],
  [
    85.6954286,
    47.292213
  ],
  [
    85.6954285,
    47.2920051
  ],
  [
    85.6957758,
    47.2915892
  ],
  [
    85.6961641,
    47.2915336
  ],
  [
    85.6966954,
    47.2913533
  ],
  [
    85.6969792,
    47.2908068
  ],
  [
    85.697163,
    47.2905157
  ],
  [
    85.6974489,
    47.2901274
  ],
  [
    85.6979393,
    47.2898778
  ],
  [
    85.6979392,
    47.2896837
  ],
  [
    85.6977961,
    47.2895174
  ],
  [
    85.6978777,
    47.2892817
  ],
  [
    85.698082,
    47.2891292
  ],
  [
    85.6983884,
    47.2888103
  ],
  [
    85.698654,
    47.2887132
  ],
  [
    85.6986539,
    47.2884082
  ],
  [
    85.698715,
    47.2880062
  ],
  [
    85.6985309,
    47.2876597
  ],
  [
    85.6983265,
    47.2875766
  ],
  [
    85.6981,
    47.287256
  ],
  [
    85.6977115,
    47.2868402
  ],
  [
    85.6977319,
    47.28666
  ],
  [
    85.6976705,
    47.2864382
  ],
  [
    85.6978134,
    47.2861748
  ],
  [
    85.6977724,
    47.285856
  ],
  [
    85.6975066,
    47.2855649
  ],
  [
    85.697629,
    47.2851213
  ],
  [
    85.6976288,
    47.2847054
  ],
  [
    85.6978739,
    47.2845113
  ],
  [
    85.6980984,
    47.2839984
  ],
  [
    85.6981391,
    47.2835963
  ],
  [
    85.6976892,
    47.2830004
  ],
  [
    85.6974233,
    47.2825291
  ],
  [
    85.6971285,
    47.2822406
  ],
  [
    85.697067,
    47.2818386
  ],
  [
    85.6971486,
    47.2814643
  ],
  [
    85.6969442,
    47.2813534
  ],
  [
    85.6965559,
    47.2812288
  ],
  [
    85.6962902,
    47.2810763
  ],
  [
    85.696004,
    47.2807714
  ],
  [
    85.6959425,
    47.2804942
  ],
  [
    85.6959629,
    47.2802863
  ],
  [
    85.6958402,
    47.2801477
  ],
  [
    85.6956154,
    47.2800507
  ],
  [
    85.6949206,
    47.2799399
  ],
  [
    85.6949205,
    47.2797597
  ],
  [
    85.6953086,
    47.2794963
  ],
  [
    85.6955334,
    47.2794408
  ],
  [
    85.6955129,
    47.2792883
  ],
  [
    85.6954107,
    47.2791497
  ],
  [
    85.6952472,
    47.2790943
  ],
  [
    85.6949611,
    47.2791082
  ],
  [
    85.694675,
    47.2790805
  ],
  [
    85.6943276,
    47.278942
  ],
  [
    85.6941232,
    47.2787202
  ],
  [
    85.6941712,
    47.2781067
  ],
  [
    85.6943346,
    47.2778849
  ],
  [
    85.6944367,
    47.2776353
  ],
  [
    85.6946,
    47.2774689
  ],
  [
    85.6949065,
    47.2773441
  ],
  [
    85.6949473,
    47.27715
  ],
  [
    85.6947019,
    47.2768451
  ],
  [
    85.6943542,
    47.2760134
  ],
  [
    85.694252,
    47.2757778
  ],
  [
    85.6942315,
    47.2755976
  ],
  [
    85.6940883,
    47.2753342
  ],
  [
    85.6939656,
    47.2749045
  ],
  [
    85.6938632,
    47.2743639
  ],
  [
    85.6942103,
    47.2739341
  ],
  [
    85.6942715,
    47.2736708
  ],
  [
    85.6950886,
    47.2734072
  ],
  [
    85.6953541,
    47.2731854
  ],
  [
    85.6953539,
    47.2727418
  ],
  [
    85.6955377,
    47.2724922
  ],
  [
    85.6952923,
    47.2721873
  ],
  [
    85.6951696,
    47.2717715
  ],
  [
    85.694904,
    47.2717438
  ],
  [
    85.6944342,
    47.2720073
  ],
  [
    85.6941278,
    47.2720628
  ],
  [
    85.693903,
    47.2720212
  ],
  [
    85.6935965,
    47.2717579
  ],
  [
    85.6937393,
    47.2713559
  ],
  [
    85.6938572,
    47.2709204
  ],
  [
    85.6933872,
    47.2706848
  ],
  [
    85.6934075,
    47.2702689
  ],
  [
    85.6931621,
    47.2698392
  ],
  [
    85.6926104,
    47.2694512
  ],
  [
    85.6925694,
    47.2691601
  ],
  [
    85.6926714,
    47.2688828
  ],
  [
    85.6926713,
    47.2686056
  ],
  [
    85.6921604,
    47.2682037
  ],
  [
    85.6921603,
    47.2679542
  ],
  [
    85.6923033,
    47.2677739
  ],
  [
    85.6926913,
    47.2674828
  ],
  [
    85.6926503,
    47.2672333
  ],
  [
    85.691874,
    47.2669562
  ],
  [
    85.6917921,
    47.2666928
  ],
  [
    85.6921801,
    47.2662491
  ],
  [
    85.6921186,
    47.2658056
  ],
  [
    85.691567,
    47.2654868
  ],
  [
    85.6915669,
    47.2651957
  ],
  [
    85.6912399,
    47.2649324
  ],
  [
    85.6908894,
    47.2644274
  ],
  [
    85.6906851,
    47.2643026
  ],
  [
    85.6901948,
    47.2642473
  ],
  [
    85.689827,
    47.2640394
  ],
  [
    85.6899903,
    47.2637067
  ],
  [
    85.689929,
    47.263471
  ],
  [
    85.6896021,
    47.2633047
  ],
  [
    85.6887441,
    47.2631524
  ],
  [
    85.68807,
    47.2631386
  ],
  [
    85.6877636,
    47.2630277
  ],
  [
    85.6876001,
    47.2628337
  ],
  [
    85.6876614,
    47.2627228
  ],
  [
    85.687314,
    47.2624317
  ],
  [
    85.6869666,
    47.2617803
  ],
  [
    85.6869665,
    47.2612812
  ],
  [
    85.6865783,
    47.2609763
  ],
  [
    85.6862922,
    47.2604219
  ],
  [
    85.686047,
    47.2602694
  ],
  [
    85.6857814,
    47.2598813
  ],
  [
    85.685577,
    47.2593268
  ],
  [
    85.6858424,
    47.2590773
  ],
  [
    85.6858627,
    47.2585782
  ],
  [
    85.6854722,
    47.25762
  ],
  [
    85.6853495,
    47.2570932
  ],
  [
    85.6853086,
    47.2567466
  ],
  [
    85.685043,
    47.2564971
  ],
  [
    85.6848387,
    47.2562754
  ],
  [
    85.6848386,
    47.256012
  ],
  [
    85.6846956,
    47.2558595
  ],
  [
    85.6845321,
    47.2555268
  ],
  [
    85.6841236,
    47.2553467
  ],
  [
    85.6838172,
    47.2550556
  ],
  [
    85.6830819,
    47.2548755
  ],
  [
    85.6827347,
    47.2546398
  ],
  [
    85.6824894,
    47.2538636
  ],
  [
    85.6825915,
    47.2535586
  ],
  [
    85.6824689,
    47.2531428
  ],
  [
    85.682142,
    47.2529071
  ],
  [
    85.681897,
    47.2528794
  ],
  [
    85.6817744,
    47.2527547
  ],
  [
    85.6816722,
    47.2524081
  ],
  [
    85.6801683,
    47.2510416
  ],
  [
    85.6800457,
    47.2508753
  ],
  [
    85.6801682,
    47.2507089
  ],
  [
    85.6802499,
    47.2505703
  ],
  [
    85.6801273,
    47.2501683
  ],
  [
    85.6802906,
    47.2498633
  ],
  [
    85.6806173,
    47.2495306
  ],
  [
    85.6807602,
    47.2494751
  ],
  [
    85.680944,
    47.2494058
  ],
  [
    85.680944,
    47.2492533
  ],
  [
    85.6810256,
    47.2489622
  ],
  [
    85.6809643,
    47.2487681
  ],
  [
    85.6809847,
    47.2485048
  ],
  [
    85.68076,
    47.2483107
  ],
  [
    85.6806375,
    47.2480473
  ],
  [
    85.6807191,
    47.2479087
  ],
  [
    85.6813113,
    47.2476453
  ],
  [
    85.6815358,
    47.247368
  ],
  [
    85.6818217,
    47.2472987
  ],
  [
    85.6822097,
    47.2470353
  ],
  [
    85.6824546,
    47.2466332
  ],
  [
    85.6826996,
    47.2464807
  ],
  [
    85.684346,
    47.2459517
  ],
  [
    85.6846522,
    47.2456329
  ],
  [
    85.6851219,
    47.2454665
  ],
  [
    85.6860204,
    47.2455634
  ],
  [
    85.6874498,
    47.2452721
  ],
  [
    85.6876539,
    47.2451334
  ],
  [
    85.6875312,
    47.2445373
  ],
  [
    85.687572,
    47.2442462
  ],
  [
    85.6879802,
    47.2437887
  ],
  [
    85.6879801,
    47.2435253
  ],
  [
    85.6875511,
    47.2426937
  ],
  [
    85.6872651,
    47.2424165
  ],
  [
    85.6871221,
    47.2421531
  ],
  [
    85.6876937,
    47.2417649
  ],
  [
    85.6879183,
    47.2417233
  ],
  [
    85.6881224,
    47.2415153
  ],
  [
    85.6884899,
    47.2414182
  ],
  [
    85.6886328,
    47.2413627
  ],
  [
    85.6886939,
    47.2408637
  ],
  [
    85.6889388,
    47.2404339
  ],
  [
    85.6885304,
    47.2403092
  ],
  [
    85.6877688,
    47.2402886
  ],
  [
    85.6874216,
    47.2398173
  ],
  [
    85.6873602,
    47.2393737
  ],
  [
    85.6870538,
    47.2389163
  ],
  [
    85.6869924,
    47.2386529
  ],
  [
    85.6864615,
    47.2383341
  ],
  [
    85.6865226,
    47.2377796
  ],
  [
    85.6867471,
    47.2374746
  ],
  [
    85.685379,
    47.2367817
  ],
  [
    85.6853177,
    47.2365599
  ],
  [
    85.6854605,
    47.2362688
  ],
  [
    85.6854809,
    47.2360609
  ],
  [
    85.6855013,
    47.2359222
  ],
  [
    85.6857462,
    47.2357559
  ],
  [
    85.6859502,
    47.2352429
  ],
  [
    85.6862359,
    47.2348548
  ],
  [
    85.6864401,
    47.2347161
  ],
  [
    85.68644,
    47.2345914
  ],
  [
    85.6862154,
    47.2343973
  ],
  [
    85.6861337,
    47.234231
  ],
  [
    85.6862357,
    47.2339953
  ],
  [
    85.6865623,
    47.233718
  ],
  [
    85.6868071,
    47.233413
  ],
  [
    85.6868887,
    47.2330526
  ],
  [
    85.6865619,
    47.2324843
  ],
  [
    85.6862293,
    47.232135
  ],
  [
    85.6866985,
    47.2311368
  ],
  [
    85.6862287,
    47.2302358
  ],
  [
    85.6862082,
    47.2299447
  ],
  [
    85.6857386,
    47.2294735
  ],
  [
    85.6855956,
    47.229224
  ],
  [
    85.6857181,
    47.2289883
  ],
  [
    85.6856976,
    47.2287249
  ],
  [
    85.6853913,
    47.2285447
  ],
  [
    85.6852687,
    47.2281289
  ],
  [
    85.6850237,
    47.2278655
  ],
  [
    85.6847991,
    47.2275467
  ],
  [
    85.6843703,
    47.2272834
  ],
  [
    85.6840845,
    47.2270616
  ],
  [
    85.6840845,
    47.2267983
  ],
  [
    85.6837578,
    47.2263824
  ],
  [
    85.683574,
    47.2260359
  ],
  [
    85.6832882,
    47.2258973
  ],
  [
    85.6832473,
    47.2256478
  ],
  [
    85.6833697,
    47.2254121
  ],
  [
    85.6831247,
    47.225121
  ],
  [
    85.682963,
    47.2246319
  ],
  [
    85.6825748,
    47.2235645
  ],
  [
    85.682289,
    47.2231486
  ],
  [
    85.6822685,
    47.2228298
  ],
  [
    85.6820439,
    47.2226496
  ],
  [
    85.6818397,
    47.2222892
  ],
  [
    85.6816152,
    47.2221229
  ],
  [
    85.6809824,
    47.2221229
  ],
  [
    85.6806354,
    47.2219566
  ],
  [
    85.680472,
    47.2215823
  ],
  [
    85.680125,
    47.2211804
  ],
  [
    85.6789819,
    47.2208062
  ],
  [
    85.6786349,
    47.2203903
  ],
  [
    85.6787165,
    47.2201408
  ],
  [
    85.6783287,
    47.2198774
  ],
  [
    85.6782062,
    47.2194339
  ],
  [
    85.6781449,
    47.2191982
  ],
  [
    85.6775122,
    47.2190042
  ],
  [
    85.6773489,
    47.2187824
  ],
  [
    85.677104,
    47.2186299
  ],
  [
    85.6765937,
    47.2186715
  ],
  [
    85.6758182,
    47.2188518
  ],
  [
    85.6750018,
    47.2188102
  ],
  [
    85.6742874,
    47.218436
  ],
  [
    85.6737976,
    47.2182142
  ],
  [
    85.673022,
    47.218131
  ],
  [
    85.6725934,
    47.2179092
  ],
  [
    85.6724619,
    47.2175508
  ],
  [
    85.6720945,
    47.2169548
  ],
  [
    85.6716251,
    47.216927
  ],
  [
    85.6713394,
    47.2168161
  ],
  [
    85.6704822,
    47.2167884
  ],
  [
    85.6700128,
    47.2165666
  ],
  [
    85.6695842,
    47.2162478
  ],
  [
    85.6691148,
    47.2161507
  ],
  [
    85.6679515,
    47.2162893
  ],
  [
    85.6668086,
    47.216317
  ],
  [
    85.6658087,
    47.2161229
  ],
  [
    85.6654822,
    47.2157625
  ],
  [
    85.664523,
    47.2154297
  ],
  [
    85.6637067,
    47.2152356
  ],
  [
    85.6632169,
    47.2150554
  ],
  [
    85.6630537,
    47.214584
  ],
  [
    85.6622374,
    47.2145147
  ],
  [
    85.6617885,
    47.2143899
  ],
  [
    85.6605515,
    47.2135916
  ],
  [
    85.6590297,
    47.2129404
  ],
  [
    85.6586841,
    47.2126047
  ],
  [
    85.6589282,
    47.2118037
  ],
  [
    85.6586111,
    47.2110557
  ],
  [
    85.6553531,
    47.2088674
  ],
  [
    85.653706,
    47.207178
  ],
  [
    85.6499343,
    47.205569
  ],
  [
    85.6496084,
    47.2050739
  ],
  [
    85.6494596,
    47.2043088
  ],
  [
    85.6492658,
    47.2039123
  ],
  [
    85.6488338,
    47.2030857
  ],
  [
    85.6480996,
    47.2027789
  ],
  [
    85.6479153,
    47.2024641
  ],
  [
    85.6480448,
    47.2019306
  ],
  [
    85.6477232,
    47.2013267
  ],
  [
    85.6480284,
    47.2007891
  ],
  [
    85.6479341,
    47.2003846
  ],
  [
    85.6476191,
    47.1998318
  ],
  [
    85.6470899,
    47.199287
  ],
  [
    85.6464359,
    47.1988993
  ],
  [
    85.6465303,
    47.1984772
  ],
  [
    85.646956,
    47.1976287
  ],
  [
    85.6470397,
    47.1966555
  ],
  [
    85.6465774,
    47.1962363
  ],
  [
    85.6464726,
    47.1943398
  ],
  [
    85.6457419,
    47.1939053
  ],
  [
    85.6441901,
    47.1932932
  ],
  [
    85.6440402,
    47.1929335
  ],
  [
    85.6436601,
    47.1925834
  ],
  [
    85.6435292,
    47.192094
  ],
  [
    85.6432502,
    47.191749
  ],
  [
    85.6430582,
    47.1912732
  ],
  [
    85.6427237,
    47.1909687
  ],
  [
    85.6424283,
    47.1894348
  ],
  [
    85.6421761,
    47.1886749
  ],
  [
    85.6422916,
    47.1880372
  ],
  [
    85.6419192,
    47.1877793
  ],
  [
    85.6417023,
    47.1873277
  ],
  [
    85.6413129,
    47.1869293
  ],
  [
    85.6408404,
    47.1865341
  ],
  [
    85.6407524,
    47.1862585
  ],
  [
    85.640789,
    47.1857714
  ],
  [
    85.6408153,
    47.1851752
  ],
  [
    85.640561,
    47.184607
  ],
  [
    85.6399051,
    47.1840212
  ],
  [
    85.6393534,
    47.1838023
  ],
  [
    85.6383757,
    47.1834597
  ],
  [
    85.6374487,
    47.1827935
  ],
  [
    85.6361082,
    47.1822418
  ],
  [
    85.6356349,
    47.1818626
  ],
  [
    85.6343576,
    47.1814287
  ],
  [
    85.6338603,
    47.1814743
  ],
  [
    85.633134,
    47.1813649
  ],
  [
    85.6323248,
    47.1811414
  ],
  [
    85.631883,
    47.1802454
  ],
  [
    85.6313753,
    47.1797976
  ],
  [
    85.6305355,
    47.179433
  ],
  [
    85.6300577,
    47.1791328
  ],
  [
    85.6295335,
    47.1788688
  ],
  [
    85.6292078,
    47.1786044
  ],
  [
    85.629257,
    47.1784623
  ],
  [
    85.6292541,
    47.1783198
  ],
  [
    85.6289863,
    47.1781845
  ],
  [
    85.6286969,
    47.177984
  ],
  [
    85.6287091,
    47.1773506
  ],
  [
    85.6285047,
    47.1768295
  ],
  [
    85.6283208,
    47.1766698
  ],
  [
    85.6282838,
    47.1763606
  ],
  [
    85.6283974,
    47.1761407
  ],
  [
    85.6286025,
    47.1758404
  ],
  [
    85.6286822,
    47.1755293
  ],
  [
    85.6285253,
    47.175241
  ],
  [
    85.6282576,
    47.1750681
  ],
  [
    85.6278754,
    47.1749462
  ],
  [
    85.6277025,
    47.1745954
  ],
  [
    85.6275092,
    47.1740249
  ],
  [
    85.6273261,
    47.1739265
  ],
  [
    85.6269663,
    47.1738221
  ],
  [
    85.626392,
    47.1738847
  ],
  [
    85.6260889,
    47.17409
  ],
  [
    85.6254643,
    47.1741059
  ],
  [
    85.6252063,
    47.1739923
  ],
  [
    85.6245191,
    47.1733448
  ],
  [
    85.6243412,
    47.1730401
  ],
  [
    85.6244139,
    47.172832
  ],
  [
    85.6244818,
    47.1724873
  ],
  [
    85.6244519,
    47.172016
  ],
  [
    85.6241667,
    47.1715647
  ],
  [
    85.6234942,
    47.1710735
  ],
  [
    85.6228139,
    47.1707746
  ],
  [
    85.6223914,
    47.1704695
  ],
  [
    85.622054,
    47.1699735
  ],
  [
    85.6218663,
    47.1697486
  ],
  [
    85.6214551,
    47.1695959
  ],
  [
    85.6211467,
    47.1694432
  ],
  [
    85.620571,
    47.1690412
  ],
  [
    85.6200797,
    47.1689598
  ],
  [
    85.6192447,
    47.1687937
  ],
  [
    85.618823,
    47.1685643
  ],
  [
    85.6184335,
    47.1682739
  ],
  [
    85.6182458,
    47.1676783
  ],
  [
    85.6179012,
    47.1676094
  ],
  [
    85.6176588,
    47.1674023
  ],
  [
    85.6172352,
    47.1672794
  ],
  [
    85.616594,
    47.1672402
  ],
  [
    85.6160089,
    47.1670092
  ],
  [
    85.6160496,
    47.166694
  ],
  [
    85.6153448,
    47.1664232
  ],
  [
    85.6148842,
    47.1662341
  ],
  [
    85.6145027,
    47.1659407
  ],
  [
    85.6140165,
    47.1659955
  ],
  [
    85.6135154,
    47.1657621
  ],
  [
    85.6132731,
    47.1655723
  ],
  [
    85.6131886,
    47.1650972
  ],
  [
    85.6128275,
    47.1649501
  ],
  [
    85.6125636,
    47.1642781
  ],
  [
    85.6126406,
    47.1639403
  ],
  [
    85.6125983,
    47.163591
  ],
  [
    85.6123792,
    47.1634463
  ],
  [
    85.612235,
    47.1631774
  ],
  [
    85.611975,
    47.1630173
  ],
  [
    85.6119555,
    47.1627551
  ],
  [
    85.6116613,
    47.1625816
  ],
  [
    85.6117215,
    47.1623867
  ],
  [
    85.6116032,
    47.1618379
  ],
  [
    85.6112481,
    47.1615538
  ],
  [
    85.6107515,
    47.1612567
  ],
  [
    85.6103715,
    47.1608612
  ],
  [
    85.6101954,
    47.1604865
  ],
  [
    85.6097892,
    47.1603105
  ],
  [
    85.609641,
    47.1602217
  ],
  [
    85.6093174,
    47.1600172
  ],
  [
    85.6090458,
    47.1598846
  ],
  [
    85.6088448,
    47.1596191
  ],
  [
    85.6084006,
    47.159371
  ],
  [
    85.6078868,
    47.1592726
  ],
  [
    85.6071223,
    47.1590716
  ],
  [
    85.6065758,
    47.1586566
  ],
  [
    85.6055951,
    47.1582272
  ],
  [
    85.6050322,
    47.1580117
  ],
  [
    85.6041057,
    47.1573894
  ],
  [
    85.603289,
    47.1571784
  ],
  [
    85.602167,
    47.1567295
  ],
  [
    85.6012654,
    47.1561363
  ],
  [
    85.6005864,
    47.1555648
  ],
  [
    85.6003526,
    47.1550425
  ],
  [
    85.5992884,
    47.1544051
  ],
  [
    85.5981597,
    47.1539282
  ],
  [
    85.597336,
    47.1534048
  ],
  [
    85.5963233,
    47.1531014
  ],
  [
    85.5953954,
    47.1524269
  ],
  [
    85.5939644,
    47.151942
  ],
  [
    85.5931558,
    47.1517294
  ],
  [
    85.5928381,
    47.151416
  ],
  [
    85.5926303,
    47.1507859
  ],
  [
    85.5922268,
    47.1499966
  ],
  [
    85.5918992,
    47.1496466
  ],
  [
    85.5911998,
    47.1490343
  ],
  [
    85.5912157,
    47.1485822
  ],
  [
    85.5911102,
    47.1485427
  ],
  [
    85.5906134,
    47.1479177
  ],
  [
    85.5901279,
    47.1475556
  ],
  [
    85.589574,
    47.1476329
  ],
  [
    85.5890559,
    47.147428
  ],
  [
    85.5884032,
    47.1469015
  ],
  [
    85.5880562,
    47.1469269
  ],
  [
    85.5875863,
    47.1471527
  ],
  [
    85.5872558,
    47.1469544
  ],
  [
    85.5872274,
    47.1463175
  ],
  [
    85.586639,
    47.1455578
  ],
  [
    85.5859608,
    47.1450447
  ],
  [
    85.5857071,
    47.1446864
  ],
  [
    85.5833512,
    47.1439285
  ],
  [
    85.5821891,
    47.1430766
  ],
  [
    85.5813793,
    47.1428029
  ],
  [
    85.5795851,
    47.1416344
  ],
  [
    85.5787285,
    47.1407306
  ],
  [
    85.5791279,
    47.1398951
  ],
  [
    85.578929,
    47.1391851
  ],
  [
    85.5782265,
    47.1388466
  ],
  [
    85.5774206,
    47.1380927
  ],
  [
    85.5766557,
    47.137718
  ],
  [
    85.576507,
    47.1373977
  ],
  [
    85.5762801,
    47.1370279
  ],
  [
    85.5762945,
    47.1366253
  ],
  [
    85.5771255,
    47.1356686
  ],
  [
    85.5770619,
    47.1351229
  ],
  [
    85.5768044,
    47.1344818
  ],
  [
    85.5762598,
    47.134101
  ],
  [
    85.5759729,
    47.1331315
  ],
  [
    85.5760728,
    47.1326797
  ],
  [
    85.5756448,
    47.1316287
  ],
  [
    85.5763573,
    47.1306868
  ],
  [
    85.5765143,
    47.1298707
  ],
  [
    85.5759093,
    47.1290281
  ],
  [
    85.5758858,
    47.1284195
  ],
  [
    85.5755608,
    47.1277498
  ],
  [
    85.5750118,
    47.1270462
  ],
  [
    85.5743389,
    47.1258302
  ],
  [
    85.5723381,
    47.1248119
  ],
  [
    85.5694627,
    47.1232351
  ],
  [
    85.5647848,
    47.1210743
  ],
  [
    85.5582617,
    47.1164018
  ],
  [
    85.5481337,
    47.1061208
  ],
  [
    85.5414389,
    47.0932669
  ],
  [
    85.5409239,
    47.0841504
  ],
  [
    85.5443571,
    47.0795915
  ],
  [
    85.5350873,
    47.0745647
  ],
  [
    85.5331992,
    47.0698296
  ],
  [
    85.5329417,
    47.0675203
  ],
  [
    85.5361174,
    47.0620538
  ],
  [
    85.5376194,
    47.0569668
  ],
  [
    85.5369757,
    47.0518647
  ],
  [
    85.5360316,
    47.050315
  ],
  [
    85.5346574,
    47.049095
  ],
  [
    85.5339931,
    47.0489407
  ],
  [
    85.5336058,
    47.0489806
  ],
  [
    85.5326198,
    47.04935
  ],
  [
    85.5319118,
    47.0496863
  ],
  [
    85.5312671,
    47.0497571
  ],
  [
    85.53059,
    47.0502161
  ],
  [
    85.5302723,
    47.0506909
  ],
  [
    85.5297844,
    47.0512864
  ],
  [
    85.529359,
    47.0516429
  ],
  [
    85.5287214,
    47.0516384
  ],
  [
    85.5271342,
    47.0513762
  ],
  [
    85.5260467,
    47.0514048
  ],
  [
    85.525356,
    47.051582
  ],
  [
    85.5241889,
    47.0525093
  ],
  [
    85.5215899,
    47.0530232
  ],
  [
    85.5206409,
    47.053415
  ],
  [
    85.5184155,
    47.0537771
  ],
  [
    85.5171683,
    47.0541623
  ],
  [
    85.5164742,
    47.0545342
  ],
  [
    85.5142691,
    47.054252
  ],
  [
    85.5133126,
    47.0547421
  ],
  [
    85.5126989,
    47.0548692
  ],
  [
    85.5115141,
    47.055122
  ],
  [
    85.510918,
    47.0554343
  ],
  [
    85.5103183,
    47.0555414
  ],
  [
    85.5086663,
    47.0557588
  ],
  [
    85.5070973,
    47.0559419
  ],
  [
    85.5061824,
    47.0561328
  ],
  [
    85.5052592,
    47.0562482
  ],
  [
    85.5043651,
    47.0562499
  ],
  [
    85.5030771,
    47.0561854
  ],
  [
    85.5021785,
    47.0558489
  ],
  [
    85.5012537,
    47.0555733
  ],
  [
    85.5009666,
    47.0553798
  ],
  [
    85.5002112,
    47.0552966
  ],
  [
    85.4987644,
    47.0543724
  ],
  [
    85.4977074,
    47.0538178
  ],
  [
    85.4969935,
    47.0533019
  ],
  [
    85.4959614,
    47.0529521
  ],
  [
    85.4952869,
    47.0529579
  ],
  [
    85.4945481,
    47.0530519
  ],
  [
    85.4931037,
    47.0532632
  ],
  [
    85.4921797,
    47.0533647
  ],
  [
    85.4910619,
    47.0532145
  ],
  [
    85.4903294,
    47.0529326
  ],
  [
    85.4895835,
    47.0529688
  ],
  [
    85.4880105,
    47.0526246
  ],
  [
    85.485282,
    47.0525324
  ],
  [
    85.4824728,
    47.0531086
  ],
  [
    85.481099,
    47.0531249
  ],
  [
    85.4802334,
    47.0533679
  ],
  [
    85.4799477,
    47.0535689
  ],
  [
    85.4794196,
    47.05383
  ],
  [
    85.4792528,
    47.0542094
  ],
  [
    85.4789932,
    47.0547797
  ],
  [
    85.478206,
    47.0554184
  ],
  [
    85.4770704,
    47.0561548
  ],
  [
    85.4763287,
    47.0566483
  ],
  [
    85.4750374,
    47.0573377
  ],
  [
    85.4739213,
    47.0577562
  ],
  [
    85.472668,
    47.0582986
  ],
  [
    85.472137,
    47.0583908
  ],
  [
    85.4703868,
    47.0589687
  ],
  [
    85.4675788,
    47.0598624
  ],
  [
    85.4662839,
    47.0599832
  ],
  [
    85.4651948,
    47.0607559
  ],
  [
    85.463807,
    47.0618653
  ],
  [
    85.4633712,
    47.0619005
  ],
  [
    85.4628947,
    47.0626133
  ],
  [
    85.4628261,
    47.0631042
  ],
  [
    85.4627321,
    47.0635169
  ],
  [
    85.4624882,
    47.0636669
  ],
  [
    85.4614794,
    47.0639603
  ],
  [
    85.4603512,
    47.0639884
  ],
  [
    85.4595908,
    47.063487
  ],
  [
    85.4580642,
    47.0624033
  ],
  [
    85.4566749,
    47.0617447
  ],
  [
    85.4548269,
    47.0608022
  ],
  [
    85.4533527,
    47.0603675
  ],
  [
    85.4509015,
    47.0601542
  ],
  [
    85.4472467,
    47.0602198
  ],
  [
    85.445269,
    47.0604166
  ],
  [
    85.4443244,
    47.0607254
  ],
  [
    85.4431112,
    47.0605474
  ],
  [
    85.4406284,
    47.060572
  ],
  [
    85.4383196,
    47.0605062
  ],
  [
    85.4371399,
    47.0599878
  ],
  [
    85.4362708,
    47.0594756
  ],
  [
    85.4360529,
    47.0591566
  ],
  [
    85.4344147,
    47.0584068
  ],
  [
    85.4326746,
    47.0575718
  ],
  [
    85.430099,
    47.057211
  ],
  [
    85.428723,
    47.0572672
  ],
  [
    85.4273754,
    47.0573106
  ],
  [
    85.4265195,
    47.0578784
  ],
  [
    85.4255999,
    47.0590715
  ],
  [
    85.4252797,
    47.0595089
  ],
  [
    85.4244203,
    47.060194
  ],
  [
    85.4240605,
    47.0606247
  ],
  [
    85.4223715,
    47.0604868
  ],
  [
    85.4212792,
    47.0604057
  ],
  [
    85.4197862,
    47.0603826
  ],
  [
    85.4176188,
    47.0604792
  ],
  [
    85.4160371,
    47.0605462
  ],
  [
    85.4143703,
    47.0602751
  ],
  [
    85.4129727,
    47.0598848
  ],
  [
    85.4125171,
    47.0599526
  ],
  [
    85.4124203,
    47.0598086
  ],
  [
    85.412178,
    47.0592003
  ],
  [
    85.4120353,
    47.0589243
  ],
  [
    85.4114117,
    47.0586077
  ],
  [
    85.4110302,
    47.0581135
  ],
  [
    85.4097073,
    47.0570244
  ],
  [
    85.4091631,
    47.0559346
  ],
  [
    85.4086324,
    47.0550911
  ],
  [
    85.4083428,
    47.0540775
  ],
  [
    85.4079143,
    47.0531926
  ],
  [
    85.4073831,
    47.0528574
  ],
  [
    85.4067239,
    47.052375
  ],
  [
    85.4064407,
    47.0520356
  ],
  [
    85.4059322,
    47.0516737
  ],
  [
    85.4053899,
    47.0516371
  ],
  [
    85.4050836,
    47.0517735
  ],
  [
    85.404809,
    47.0518097
  ],
  [
    85.404413,
    47.0518376
  ],
  [
    85.4042287,
    47.0518587
  ],
  [
    85.4039408,
    47.0517359
  ],
  [
    85.4031119,
    47.0513176
  ],
  [
    85.4026973,
    47.0510124
  ],
  [
    85.40195,
    47.0505492
  ],
  [
    85.4015414,
    47.0504373
  ],
  [
    85.4010549,
    47.0502016
  ],
  [
    85.3997988,
    47.0498826
  ],
  [
    85.3986722,
    47.0495677
  ],
  [
    85.397917,
    47.0495164
  ],
  [
    85.3970017,
    47.0493423
  ],
  [
    85.3962296,
    47.04911
  ],
  [
    85.3948747,
    47.0484921
  ],
  [
    85.3942703,
    47.0482558
  ],
  [
    85.3937081,
    47.0482158
  ],
  [
    85.3932765,
    47.0482787
  ],
  [
    85.3928541,
    47.0484214
  ],
  [
    85.3923476,
    47.048584
  ],
  [
    85.3917424,
    47.0486661
  ],
  [
    85.3906482,
    47.0485689
  ],
  [
    85.3888495,
    47.0483424
  ],
  [
    85.3869824,
    47.0477644
  ],
  [
    85.3868178,
    47.047721
  ],
  [
    85.3860849,
    47.0474846
  ],
  [
    85.3854497,
    47.0469827
  ],
  [
    85.384676,
    47.0465637
  ],
  [
    85.3841759,
    47.0463141
  ],
  [
    85.3829835,
    47.0461896
  ],
  [
    85.3824177,
    47.0460355
  ],
  [
    85.3819379,
    47.0462617
  ],
  [
    85.3812113,
    47.0464409
  ],
  [
    85.3801431,
    47.0466908
  ],
  [
    85.3796764,
    47.0466695
  ],
  [
    85.3780067,
    47.0470057
  ],
  [
    85.3772904,
    47.0470287
  ],
  [
    85.3759102,
    47.0474152
  ],
  [
    85.3745711,
    47.0484518
  ],
  [
    85.3733158,
    47.0492514
  ],
  [
    85.3728727,
    47.0498142
  ],
  [
    85.3723108,
    47.0501914
  ],
  [
    85.3718274,
    47.0502537
  ],
  [
    85.3716516,
    47.0504088
  ],
  [
    85.3709448,
    47.0506171
  ],
  [
    85.3704355,
    47.0508673
  ],
  [
    85.3700824,
    47.0510573
  ],
  [
    85.3696314,
    47.0512404
  ],
  [
    85.368948,
    47.0515863
  ],
  [
    85.3684794,
    47.0517805
  ],
  [
    85.3678261,
    47.0520174
  ],
  [
    85.3670429,
    47.0522865
  ],
  [
    85.3664567,
    47.0524407
  ],
  [
    85.3660665,
    47.0524865
  ],
  [
    85.3656223,
    47.0526192
  ],
  [
    85.3649405,
    47.0526433
  ],
  [
    85.3642397,
    47.0527268
  ],
  [
    85.3627874,
    47.0528504
  ],
  [
    85.3620326,
    47.0528882
  ],
  [
    85.3610714,
    47.0527555
  ],
  [
    85.3596323,
    47.0524867
  ],
  [
    85.3592046,
    47.0524722
  ],
  [
    85.3567166,
    47.0519921
  ],
  [
    85.355829,
    47.0519733
  ],
  [
    85.3548509,
    47.0521469
  ],
  [
    85.3536189,
    47.0522643
  ],
  [
    85.3521406,
    47.0521624
  ],
  [
    85.35146,
    47.0522923
  ],
  [
    85.3509119,
    47.0523275
  ],
  [
    85.3506283,
    47.0524165
  ],
  [
    85.3504741,
    47.0525665
  ],
  [
    85.3502676,
    47.0527996
  ],
  [
    85.3501149,
    47.0527878
  ],
  [
    85.3501071,
    47.0528802
  ],
  [
    85.3496409,
    47.0530326
  ],
  [
    85.3491499,
    47.0532705
  ],
  [
    85.3490608,
    47.0535169
  ],
  [
    85.3490061,
    47.053721
  ],
  [
    85.3489,
    47.0538593
  ],
  [
    85.3486597,
    47.0538695
  ],
  [
    85.3485168,
    47.0539313
  ],
  [
    85.3483434,
    47.0540559
  ],
  [
    85.3480668,
    47.0540902
  ],
  [
    85.3477845,
    47.0542126
  ],
  [
    85.3474354,
    47.0543598
  ],
  [
    85.347313,
    47.0545091
  ],
  [
    85.347064,
    47.0546169
  ],
  [
    85.3467781,
    47.0546536
  ],
  [
    85.3465679,
    47.054794
  ],
  [
    85.3464144,
    47.0548602
  ],
  [
    85.3461071,
    47.0548566
  ],
  [
    85.3457082,
    47.0548428
  ],
  [
    85.3452963,
    47.0546732
  ],
  [
    85.3449431,
    47.0544991
  ],
  [
    85.344688,
    47.054327
  ],
  [
    85.3443972,
    47.0542467
  ],
  [
    85.3440747,
    47.0542455
  ],
  [
    85.3428425,
    47.0541712
  ],
  [
    85.3418672,
    47.0540841
  ],
  [
    85.3414631,
    47.0535282
  ],
  [
    85.3414871,
    47.0530311
  ],
  [
    85.3413501,
    47.052898
  ],
  [
    85.3412473,
    47.0527838
  ],
  [
    85.3411963,
    47.0525037
  ],
  [
    85.3411167,
    47.0522828
  ],
  [
    85.3410894,
    47.0521296
  ],
  [
    85.341008,
    47.0520527
  ],
  [
    85.3409433,
    47.0517901
  ],
  [
    85.3407558,
    47.0516635
  ],
  [
    85.3405947,
    47.0512837
  ],
  [
    85.3405146,
    47.0512189
  ],
  [
    85.3404086,
    47.0509687
  ],
  [
    85.3403688,
    47.050727
  ],
  [
    85.3398432,
    47.049735
  ],
  [
    85.3398619,
    47.0493884
  ],
  [
    85.3396637,
    47.0491215
  ],
  [
    85.3395203,
    47.0488035
  ],
  [
    85.3395302,
    47.0485674
  ],
  [
    85.3395388,
    47.0483419
  ],
  [
    85.3395603,
    47.0480943
  ],
  [
    85.3392874,
    47.0479642
  ],
  [
    85.3388441,
    47.0478085
  ],
  [
    85.3382255,
    47.047569
  ],
  [
    85.3374211,
    47.0472572
  ],
  [
    85.3362004,
    47.0469304
  ],
  [
    85.3352069,
    47.0470027
  ],
  [
    85.3347063,
    47.0469966
  ],
  [
    85.3339227,
    47.0470881
  ],
  [
    85.331991,
    47.0473344
  ],
  [
    85.3315929,
    47.0473719
  ],
  [
    85.3309259,
    47.0470138
  ],
  [
    85.33023,
    47.0469079
  ],
  [
    85.3301974,
    47.0468542
  ],
  [
    85.3299034,
    47.0465895
  ],
  [
    85.3296039,
    47.0464514
  ],
  [
    85.3291576,
    47.0459568
  ],
  [
    85.329086,
    47.0457423
  ],
  [
    85.3289475,
    47.0455164
  ],
  [
    85.3287715,
    47.0453404
  ],
  [
    85.3284755,
    47.045196
  ],
  [
    85.3277894,
    47.0447702
  ],
  [
    85.3275976,
    47.0446338
  ],
  [
    85.3274397,
    47.0445327
  ],
  [
    85.3273286,
    47.0443977
  ],
  [
    85.3269711,
    47.0441938
  ],
  [
    85.3265607,
    47.0440514
  ],
  [
    85.3263522,
    47.0439228
  ],
  [
    85.3260704,
    47.0437889
  ],
  [
    85.3255292,
    47.0436937
  ],
  [
    85.3247908,
    47.0434095
  ],
  [
    85.324083,
    47.0431287
  ],
  [
    85.3238375,
    47.0429413
  ],
  [
    85.3235233,
    47.0429154
  ],
  [
    85.3234335,
    47.0429462
  ],
  [
    85.3230947,
    47.0430469
  ],
  [
    85.3228985,
    47.0431607
  ],
  [
    85.3216652,
    47.0434816
  ],
  [
    85.3211876,
    47.0435638
  ],
  [
    85.3206935,
    47.0438537
  ],
  [
    85.3202125,
    47.043996
  ],
  [
    85.3198574,
    47.0441722
  ],
  [
    85.3196065,
    47.0442341
  ],
  [
    85.318742,
    47.044577
  ],
  [
    85.3184668,
    47.0448183
  ],
  [
    85.3182807,
    47.0448933
  ],
  [
    85.3180282,
    47.0450832
  ],
  [
    85.3177062,
    47.0452573
  ],
  [
    85.3173807,
    47.045398
  ],
  [
    85.3169244,
    47.0457141
  ],
  [
    85.3165045,
    47.0459297
  ],
  [
    85.3164625,
    47.0459555
  ],
  [
    85.3159447,
    47.0462683
  ],
  [
    85.3155036,
    47.0464705
  ],
  [
    85.3152186,
    47.0467069
  ],
  [
    85.3149504,
    47.0467859
  ],
  [
    85.3145345,
    47.0468609
  ],
  [
    85.3143597,
    47.0470012
  ],
  [
    85.3143518,
    47.0471478
  ],
  [
    85.3144457,
    47.0473849
  ],
  [
    85.3143521,
    47.0474124
  ],
  [
    85.3142272,
    47.047472
  ],
  [
    85.3138793,
    47.0475947
  ],
  [
    85.3136278,
    47.0480393
  ],
  [
    85.3130234,
    47.0484777
  ],
  [
    85.3128883,
    47.0487103
  ],
  [
    85.3127542,
    47.0489194
  ],
  [
    85.312467,
    47.0490794
  ],
  [
    85.3122486,
    47.0491457
  ],
  [
    85.3119734,
    47.049189
  ],
  [
    85.3117074,
    47.0491512
  ],
  [
    85.3111277,
    47.049325
  ],
  [
    85.3105462,
    47.0493741
  ],
  [
    85.3097614,
    47.0495759
  ],
  [
    85.3091402,
    47.0496495
  ],
  [
    85.3087066,
    47.0498447
  ],
  [
    85.3083306,
    47.0499517
  ],
  [
    85.3079449,
    47.050337
  ],
  [
    85.3077314,
    47.0504085
  ],
  [
    85.3069613,
    47.0508575
  ],
  [
    85.3066844,
    47.0513651
  ],
  [
    85.3064522,
    47.0514622
  ],
  [
    85.3063599,
    47.0515872
  ],
  [
    85.3060538,
    47.0517734
  ],
  [
    85.3060155,
    47.0524078
  ],
  [
    85.3054977,
    47.0528227
  ],
  [
    85.3050864,
    47.053577
  ],
  [
    85.3046651,
    47.0543045
  ],
  [
    85.304473,
    47.0548272
  ],
  [
    85.3042432,
    47.0550373
  ],
  [
    85.304084,
    47.0558897
  ],
  [
    85.3040996,
    47.05624
  ],
  [
    85.304247,
    47.0563613
  ],
  [
    85.3045627,
    47.0564844
  ],
  [
    85.3049017,
    47.0568774
  ],
  [
    85.3043118,
    47.0572802
  ],
  [
    85.3036304,
    47.0575713
  ],
  [
    85.3027515,
    47.0582689
  ],
  [
    85.3022247,
    47.0586615
  ],
  [
    85.3016272,
    47.0591828
  ],
  [
    85.3012442,
    47.0598118
  ],
  [
    85.3008356,
    47.0607269
  ],
  [
    85.3004797,
    47.0615538
  ],
  [
    85.3001525,
    47.0625131
  ],
  [
    85.2998376,
    47.0631246
  ],
  [
    85.2994635,
    47.06432
  ],
  [
    85.2993583,
    47.0649837
  ],
  [
    85.2986894,
    47.0648232
  ],
  [
    85.2976685,
    47.064634
  ],
  [
    85.2967543,
    47.064366
  ],
  [
    85.2966962,
    47.0643529
  ],
  [
    85.2964624,
    47.0643261
  ],
  [
    85.294834,
    47.0641224
  ],
  [
    85.2939304,
    47.0639237
  ],
  [
    85.29341,
    47.0637193
  ],
  [
    85.2923483,
    47.0633043
  ],
  [
    85.2908271,
    47.0633177
  ],
  [
    85.289612,
    47.0630384
  ],
  [
    85.2889042,
    47.0631008
  ],
  [
    85.2881385,
    47.0634983
  ],
  [
    85.2874156,
    47.0636296
  ],
  [
    85.2858309,
    47.0640158
  ],
  [
    85.2852099,
    47.0643831
  ],
  [
    85.2840192,
    47.0646386
  ],
  [
    85.2831555,
    47.0640209
  ],
  [
    85.282335,
    47.0634736
  ],
  [
    85.2808042,
    47.0637495
  ],
  [
    85.2798253,
    47.0642727
  ],
  [
    85.278575,
    47.0647563
  ],
  [
    85.2778583,
    47.0652074
  ],
  [
    85.2773309,
    47.0657525
  ],
  [
    85.2769814,
    47.0666647
  ],
  [
    85.2767685,
    47.0674027
  ],
  [
    85.2770875,
    47.0684164
  ],
  [
    85.2767468,
    47.0685718
  ],
  [
    85.2763604,
    47.0687742
  ],
  [
    85.2758031,
    47.0684733
  ],
  [
    85.2749407,
    47.068053
  ],
  [
    85.2742811,
    47.0676605
  ],
  [
    85.2735814,
    47.0668936
  ],
  [
    85.2728596,
    47.066295
  ],
  [
    85.2726604,
    47.064985
  ],
  [
    85.2723964,
    47.063949
  ],
  [
    85.2731308,
    47.0623702
  ],
  [
    85.2737464,
    47.0611313
  ],
  [
    85.2743842,
    47.0600009
  ],
  [
    85.275002,
    47.0595243
  ],
  [
    85.2747951,
    47.058427
  ],
  [
    85.2748347,
    47.0579132
  ],
  [
    85.2753897,
    47.0572321
  ],
  [
    85.2748211,
    47.0563955
  ],
  [
    85.2739329,
    47.0555303
  ],
  [
    85.2736512,
    47.0551334
  ],
  [
    85.2734328,
    47.0546548
  ],
  [
    85.2724391,
    47.054891
  ],
  [
    85.2715943,
    47.0548684
  ],
  [
    85.2701839,
    47.0545859
  ],
  [
    85.2681788,
    47.054129
  ],
  [
    85.267787,
    47.0535292
  ],
  [
    85.2674799,
    47.053058
  ],
  [
    85.2657524,
    47.0514614
  ],
  [
    85.264417,
    47.0509746
  ],
  [
    85.262918,
    47.0505868
  ],
  [
    85.2619018,
    47.0501205
  ],
  [
    85.2614626,
    47.0498754
  ],
  [
    85.2607801,
    47.0502776
  ],
  [
    85.2596241,
    47.0509411
  ],
  [
    85.2587437,
    47.0512376
  ],
  [
    85.2578002,
    47.0513148
  ],
  [
    85.2567297,
    47.0515303
  ],
  [
    85.2555177,
    47.0521109
  ],
  [
    85.2550865,
    47.0522442
  ],
  [
    85.2541049,
    47.0529558
  ],
  [
    85.2538153,
    47.0530468
  ],
  [
    85.2534699,
    47.0530664
  ],
  [
    85.2525857,
    47.0529377
  ],
  [
    85.2519919,
    47.0529014
  ],
  [
    85.2512222,
    47.0528751
  ],
  [
    85.2499518,
    47.0526118
  ],
  [
    85.2481856,
    47.0524825
  ],
  [
    85.2473797,
    47.0525567
  ],
  [
    85.2463378,
    47.0527152
  ],
  [
    85.2456762,
    47.0528779
  ],
  [
    85.2449873,
    47.0530905
  ],
  [
    85.2443069,
    47.0532266
  ],
  [
    85.2434108,
    47.0531889
  ],
  [
    85.240839,
    47.0537355
  ],
  [
    85.240637,
    47.0535752
  ],
  [
    85.2403713,
    47.0533337
  ],
  [
    85.2402956,
    47.052883
  ],
  [
    85.2399726,
    47.0523998
  ],
  [
    85.2393096,
    47.0521838
  ],
  [
    85.2390911,
    47.0519985
  ],
  [
    85.2387537,
    47.0516377
  ],
  [
    85.2384102,
    47.0514107
  ],
  [
    85.2380248,
    47.0510847
  ],
  [
    85.2376937,
    47.0507233
  ],
  [
    85.2374921,
    47.0505816
  ],
  [
    85.2371726,
    47.0505533
  ],
  [
    85.2369399,
    47.0505029
  ],
  [
    85.2366029,
    47.0504631
  ],
  [
    85.2361839,
    47.0503463
  ],
  [
    85.2359368,
    47.0502839
  ],
  [
    85.2357668,
    47.0502201
  ],
  [
    85.2355972,
    47.0498199
  ],
  [
    85.2354837,
    47.0496962
  ],
  [
    85.2351027,
    47.0495455
  ],
  [
    85.2345337,
    47.0494739
  ],
  [
    85.2340774,
    47.0493284
  ],
  [
    85.2338098,
    47.0492259
  ],
  [
    85.2333134,
    47.0492313
  ],
  [
    85.2323476,
    47.0495324
  ],
  [
    85.2316086,
    47.0495637
  ],
  [
    85.230227,
    47.0496963
  ],
  [
    85.2290991,
    47.0499267
  ],
  [
    85.2282582,
    47.0499834
  ],
  [
    85.2276321,
    47.0499078
  ],
  [
    85.2266417,
    47.0496421
  ],
  [
    85.2260693,
    47.0495812
  ],
  [
    85.2258622,
    47.0496748
  ],
  [
    85.2255656,
    47.0498119
  ],
  [
    85.2251324,
    47.050033
  ],
  [
    85.2251098,
    47.0502763
  ],
  [
    85.2250559,
    47.0507221
  ],
  [
    85.2249527,
    47.0510875
  ],
  [
    85.2250725,
    47.0513709
  ],
  [
    85.2250743,
    47.0516225
  ],
  [
    85.2247943,
    47.0519742
  ],
  [
    85.2242933,
    47.0521698
  ],
  [
    85.22363,
    47.0522895
  ],
  [
    85.2222766,
    47.0524615
  ],
  [
    85.2216683,
    47.0525488
  ],
  [
    85.2208828,
    47.0527397
  ],
  [
    85.2200198,
    47.0528136
  ],
  [
    85.2187718,
    47.0526861
  ],
  [
    85.217727,
    47.0525284
  ],
  [
    85.2168977,
    47.0523679
  ],
  [
    85.2161212,
    47.0521179
  ],
  [
    85.2154916,
    47.0518181
  ],
  [
    85.2147665,
    47.0513747
  ],
  [
    85.2144819,
    47.0510675
  ],
  [
    85.2141959,
    47.0505712
  ],
  [
    85.2141259,
    47.0500539
  ],
  [
    85.2141265,
    47.0494444
  ],
  [
    85.2141055,
    47.0490531
  ],
  [
    85.214008,
    47.0487039
  ],
  [
    85.2138496,
    47.0484904
  ],
  [
    85.2137836,
    47.0483503
  ],
  [
    85.2136639,
    47.0477244
  ],
  [
    85.2135664,
    47.0473942
  ],
  [
    85.2134319,
    47.0471984
  ],
  [
    85.213079,
    47.046864
  ],
  [
    85.2126387,
    47.0464109
  ],
  [
    85.2122246,
    47.0459938
  ],
  [
    85.2118078,
    47.0455492
  ],
  [
    85.2114549,
    47.0451424
  ],
  [
    85.2111803,
    47.0448213
  ],
  [
    85.2109332,
    47.0445116
  ],
  [
    85.2106767,
    47.044153
  ],
  [
    85.2105731,
    47.0438525
  ],
  [
    85.2105702,
    47.0434806
  ],
  [
    85.2106327,
    47.0433069
  ],
  [
    85.2106839,
    47.0430181
  ],
  [
    85.2111045,
    47.0418992
  ],
  [
    85.2113053,
    47.0410168
  ],
  [
    85.2112886,
    47.040868
  ],
  [
    85.2112639,
    47.040642
  ],
  [
    85.211386,
    47.040457
  ],
  [
    85.211478,
    47.040243
  ],
  [
    85.2115413,
    47.040081
  ],
  [
    85.21168,
    47.0398117
  ],
  [
    85.2117942,
    47.0395969
  ],
  [
    85.2118142,
    47.0394627
  ],
  [
    85.2118161,
    47.0393796
  ],
  [
    85.2117828,
    47.039275
  ],
  [
    85.2117002,
    47.0391956
  ],
  [
    85.2116152,
    47.0390856
  ],
  [
    85.2115588,
    47.0389353
  ],
  [
    85.2114616,
    47.038779
  ],
  [
    85.2112347,
    47.0385159
  ],
  [
    85.2110408,
    47.0383262
  ],
  [
    85.210699,
    47.0380589
  ],
  [
    85.2105154,
    47.0378647
  ],
  [
    85.2102568,
    47.0375001
  ],
  [
    85.2099805,
    47.0369628
  ],
  [
    85.2097153,
    47.0367192
  ],
  [
    85.2095553,
    47.0364937
  ],
  [
    85.2094664,
    47.0362454
  ],
  [
    85.2093896,
    47.0359945
  ],
  [
    85.2092887,
    47.0357924
  ],
  [
    85.2089614,
    47.0354824
  ],
  [
    85.2085975,
    47.0352533
  ],
  [
    85.2082073,
    47.0350497
  ],
  [
    85.2078694,
    47.0348622
  ],
  [
    85.2076369,
    47.0347765
  ],
  [
    85.2071655,
    47.0345029
  ],
  [
    85.2068636,
    47.0343323
  ],
  [
    85.2066972,
    47.0341948
  ],
  [
    85.2064278,
    47.0340025
  ],
  [
    85.2061948,
    47.0338756
  ],
  [
    85.2059178,
    47.0337423
  ],
  [
    85.205745,
    47.0336104
  ],
  [
    85.2054855,
    47.0333761
  ],
  [
    85.2052226,
    47.0331357
  ],
  [
    85.2050064,
    47.0329003
  ],
  [
    85.2048162,
    47.0326456
  ],
  [
    85.2046518,
    47.0325125
  ],
  [
    85.2045295,
    47.0323668
  ],
  [
    85.2044931,
    47.0322147
  ],
  [
    85.2045135,
    47.0320622
  ],
  [
    85.2045179,
    47.0319372
  ],
  [
    85.2044057,
    47.0316443
  ],
  [
    85.2043049,
    47.0313614
  ],
  [
    85.2041229,
    47.0308602
  ],
  [
    85.2040017,
    47.0305025
  ],
  [
    85.2039229,
    47.0302183
  ],
  [
    85.203887,
    47.0299575
  ],
  [
    85.2038383,
    47.029608
  ],
  [
    85.2037929,
    47.0293577
  ],
  [
    85.2037456,
    47.0290693
  ],
  [
    85.2036952,
    47.0287475
  ],
  [
    85.2036298,
    47.0284278
  ],
  [
    85.2035272,
    47.0282185
  ],
  [
    85.2032857,
    47.0279427
  ],
  [
    85.2030256,
    47.0277281
  ],
  [
    85.2025399,
    47.0274186
  ],
  [
    85.201887,
    47.0270072
  ],
  [
    85.2014998,
    47.0266831
  ],
  [
    85.2011964,
    47.0263192
  ],
  [
    85.2009912,
    47.0259131
  ],
  [
    85.2007512,
    47.0255006
  ],
  [
    85.200451,
    47.0250457
  ],
  [
    85.2001451,
    47.0245822
  ],
  [
    85.2000641,
    47.0244885
  ],
  [
    85.199924,
    47.0241453
  ],
  [
    85.1997662,
    47.0236859
  ],
  [
    85.1996001,
    47.0230525
  ],
  [
    85.1995441,
    47.0228891
  ],
  [
    85.1995552,
    47.0225378
  ],
  [
    85.1995306,
    47.0222266
  ],
  [
    85.1995036,
    47.0219964
  ],
  [
    85.1994116,
    47.0217012
  ],
  [
    85.1993205,
    47.0216072
  ],
  [
    85.1991771,
    47.0215559
  ],
  [
    85.1991002,
    47.0214663
  ],
  [
    85.1989406,
    47.0212753
  ],
  [
    85.1988403,
    47.0211987
  ],
  [
    85.1986643,
    47.0209929
  ],
  [
    85.1985572,
    47.0208546
  ],
  [
    85.1984124,
    47.0207302
  ],
  [
    85.198192,
    47.020705
  ],
  [
    85.1979428,
    47.0206853
  ],
  [
    85.1976695,
    47.0206592
  ],
  [
    85.1974762,
    47.0206084
  ],
  [
    85.197444,
    47.020474
  ],
  [
    85.1973884,
    47.0203216
  ],
  [
    85.1972669,
    47.0201734
  ],
  [
    85.1970797,
    47.020002
  ],
  [
    85.1969239,
    47.0198598
  ],
  [
    85.1967696,
    47.0196638
  ],
  [
    85.1966847,
    47.0195443
  ],
  [
    85.1965205,
    47.0193839
  ],
  [
    85.1962227,
    47.0189456
  ],
  [
    85.1961251,
    47.0187723
  ],
  [
    85.195967,
    47.0185895
  ],
  [
    85.1956904,
    47.0183632
  ],
  [
    85.1953996,
    47.0181278
  ],
  [
    85.1951685,
    47.017883
  ],
  [
    85.1949953,
    47.0176591
  ],
  [
    85.1948175,
    47.017417
  ],
  [
    85.1945161,
    47.017093
  ],
  [
    85.1943066,
    47.0168457
  ],
  [
    85.1941412,
    47.016649
  ],
  [
    85.1940406,
    47.0165127
  ],
  [
    85.1939614,
    47.0163752
  ],
  [
    85.1938969,
    47.0162433
  ],
  [
    85.1937253,
    47.0160919
  ],
  [
    85.1937383,
    47.0159438
  ],
  [
    85.1938042,
    47.0157319
  ],
  [
    85.1939284,
    47.0154697
  ],
  [
    85.1940798,
    47.0150951
  ],
  [
    85.1942433,
    47.0147829
  ],
  [
    85.1944024,
    47.0144867
  ],
  [
    85.1944898,
    47.0142899
  ],
  [
    85.1945608,
    47.0141033
  ],
  [
    85.1946057,
    47.0139454
  ],
  [
    85.1946032,
    47.0137464
  ],
  [
    85.1946027,
    47.0136403
  ],
  [
    85.1945759,
    47.013534
  ],
  [
    85.1944707,
    47.0133356
  ],
  [
    85.194397,
    47.0132114
  ],
  [
    85.1942767,
    47.0130538
  ],
  [
    85.1942267,
    47.0129669
  ],
  [
    85.1941558,
    47.0127957
  ],
  [
    85.1940949,
    47.0126442
  ],
  [
    85.1940253,
    47.0125702
  ],
  [
    85.1938442,
    47.0124436
  ],
  [
    85.1937012,
    47.0123321
  ],
  [
    85.1935937,
    47.0122277
  ],
  [
    85.1934934,
    47.0121109
  ],
  [
    85.1933914,
    47.0120067
  ],
  [
    85.1932512,
    47.0119208
  ],
  [
    85.19321,
    47.0118846
  ],
  [
    85.193065,
    47.0117867
  ],
  [
    85.1928959,
    47.0116619
  ],
  [
    85.1927682,
    47.0115337
  ],
  [
    85.1926286,
    47.0113749
  ],
  [
    85.1924384,
    47.0112243
  ],
  [
    85.1920119,
    47.0109573
  ],
  [
    85.1916597,
    47.0107543
  ],
  [
    85.1914123,
    47.0106154
  ],
  [
    85.1910446,
    47.01048
  ],
  [
    85.1904114,
    47.0102173
  ],
  [
    85.1896308,
    47.0101458
  ],
  [
    85.1888496,
    47.010091
  ],
  [
    85.1884153,
    47.0101114
  ],
  [
    85.1883905,
    47.0100394
  ],
  [
    85.18839,
    47.0099342
  ],
  [
    85.1884326,
    47.0098365
  ],
  [
    85.1884594,
    47.0097396
  ],
  [
    85.1884794,
    47.0094661
  ],
  [
    85.1884932,
    47.0090525
  ],
  [
    85.1884539,
    47.0089444
  ],
  [
    85.1882478,
    47.0086765
  ],
  [
    85.1880811,
    47.0083119
  ],
  [
    85.1879379,
    47.0077582
  ],
  [
    85.1879116,
    47.0072102
  ],
  [
    85.1877865,
    47.0066844
  ],
  [
    85.1870271,
    47.0064497
  ],
  [
    85.1861936,
    47.0061291
  ],
  [
    85.1847511,
    47.0055311
  ],
  [
    85.183467,
    47.0049091
  ],
  [
    85.1820341,
    47.0041695
  ],
  [
    85.1806061,
    47.0039525
  ],
  [
    85.1799381,
    47.004002
  ],
  [
    85.1791245,
    47.0040996
  ],
  [
    85.1783609,
    47.0042597
  ],
  [
    85.1776434,
    47.0043205
  ],
  [
    85.1772842,
    47.0043308
  ],
  [
    85.1769478,
    47.0042714
  ],
  [
    85.1762274,
    47.0039727
  ],
  [
    85.1758552,
    47.0037115
  ],
  [
    85.1756069,
    47.0034542
  ],
  [
    85.1756053,
    47.0021471
  ],
  [
    85.1758529,
    47.0008475
  ],
  [
    85.1760622,
    47.0005453
  ],
  [
    85.1765516,
    47.0000723
  ],
  [
    85.1770697,
    46.9993687
  ],
  [
    85.1772975,
    46.9992419
  ],
  [
    85.1775358,
    46.9990255
  ],
  [
    85.1777232,
    46.998744
  ],
  [
    85.1778544,
    46.9985594
  ],
  [
    85.1782465,
    46.9981491
  ],
  [
    85.1784271,
    46.9977407
  ],
  [
    85.178546,
    46.9971668
  ],
  [
    85.1786116,
    46.9967371
  ],
  [
    85.1786166,
    46.9964611
  ],
  [
    85.1785571,
    46.9961949
  ],
  [
    85.1784091,
    46.9957904
  ],
  [
    85.1781485,
    46.9953723
  ],
  [
    85.1780231,
    46.9950471
  ],
  [
    85.1780007,
    46.9948745
  ],
  [
    85.1780029,
    46.9946411
  ],
  [
    85.1778814,
    46.9942445
  ],
  [
    85.1776482,
    46.9938962
  ],
  [
    85.177523,
    46.9937776
  ],
  [
    85.1773165,
    46.9936841
  ],
  [
    85.1770251,
    46.9936109
  ],
  [
    85.1768256,
    46.9935396
  ],
  [
    85.1766521,
    46.9933981
  ],
  [
    85.1765516,
    46.9929524
  ],
  [
    85.1763803,
    46.9926794
  ],
  [
    85.1761725,
    46.9923816
  ],
  [
    85.1759708,
    46.9921661
  ],
  [
    85.1754004,
    46.9918993
  ],
  [
    85.1749843,
    46.9916737
  ],
  [
    85.1745217,
    46.9914577
  ],
  [
    85.1743774,
    46.9913746
  ],
  [
    85.1736783,
    46.9908545
  ],
  [
    85.1733351,
    46.9906665
  ],
  [
    85.172821,
    46.9904584
  ],
  [
    85.1722942,
    46.9903199
  ],
  [
    85.1714574,
    46.9900105
  ],
  [
    85.1709996,
    46.9897887
  ],
  [
    85.1708143,
    46.9896929
  ],
  [
    85.1703937,
    46.9894794
  ],
  [
    85.1700101,
    46.989327
  ],
  [
    85.1697383,
    46.9891484
  ],
  [
    85.169435,
    46.9889454
  ],
  [
    85.1689194,
    46.9886182
  ],
  [
    85.1688159,
    46.9883564
  ],
  [
    85.1687878,
    46.988209
  ],
  [
    85.1687808,
    46.9878444
  ],
  [
    85.1685504,
    46.9878129
  ],
  [
    85.1682621,
    46.9877734
  ],
  [
    85.168156,
    46.9875815
  ],
  [
    85.1676974,
    46.987345
  ],
  [
    85.1673939,
    46.9870279
  ],
  [
    85.1668413,
    46.986551
  ],
  [
    85.1662271,
    46.9864777
  ],
  [
    85.1654205,
    46.9865581
  ],
  [
    85.1644618,
    46.9866237
  ],
  [
    85.1638228,
    46.9867421
  ],
  [
    85.1636266,
    46.9867556
  ],
  [
    85.1632458,
    46.9867011
  ],
  [
    85.1625187,
    46.9866533
  ],
  [
    85.1621677,
    46.9866772
  ],
  [
    85.1616784,
    46.9867744
  ],
  [
    85.1605152,
    46.9869416
  ],
  [
    85.1601845,
    46.9869634
  ],
  [
    85.1597026,
    46.9870347
  ],
  [
    85.1594903,
    46.9870323
  ],
  [
    85.1593301,
    46.9869993
  ],
  [
    85.1589361,
    46.9868398
  ],
  [
    85.1585569,
    46.9868013
  ],
  [
    85.1579898,
    46.9868342
  ],
  [
    85.157189,
    46.9867005
  ],
  [
    85.1564033,
    46.9865089
  ],
  [
    85.1553899,
    46.9862741
  ],
  [
    85.154764,
    46.9861942
  ],
  [
    85.1536978,
    46.9861803
  ],
  [
    85.1533565,
    46.9862801
  ],
  [
    85.1531214,
    46.9865586
  ],
  [
    85.1454992,
    46.9892856
  ],
  [
    85.1442958,
    46.9884748
  ],
  [
    85.1431121,
    46.9875982
  ],
  [
    85.1411377,
    46.9861231
  ],
  [
    85.1401961,
    46.9854878
  ],
  [
    85.1392572,
    46.9848898
  ],
  [
    85.1374922,
    46.9836674
  ],
  [
    85.1367676,
    46.9831486
  ],
  [
    85.1347621,
    46.9817274
  ],
  [
    85.1345447,
    46.9815605
  ],
  [
    85.134295,
    46.9814494
  ],
  [
    85.1340743,
    46.981373
  ],
  [
    85.1334035,
    46.9813516
  ],
  [
    85.1307336,
    46.9812646
  ],
  [
    85.1299097,
    46.9812391
  ],
  [
    85.1286217,
    46.9811572
  ],
  [
    85.1284232,
    46.9810621
  ],
  [
    85.1282286,
    46.9808971
  ],
  [
    85.1279935,
    46.9805487
  ],
  [
    85.1277001,
    46.9800859
  ],
  [
    85.1272896,
    46.9796946
  ],
  [
    85.1271271,
    46.9795044
  ],
  [
    85.1270575,
    46.9792689
  ],
  [
    85.1269482,
    46.9782724
  ],
  [
    85.1269523,
    46.9781084
  ],
  [
    85.1270408,
    46.9779961
  ],
  [
    85.1272215,
    46.9778741
  ],
  [
    85.1276812,
    46.9775166
  ],
  [
    85.127866,
    46.9772237
  ],
  [
    85.1280633,
    46.9767673
  ],
  [
    85.1282136,
    46.976176
  ],
  [
    85.1282245,
    46.9757089
  ],
  [
    85.1279621,
    46.9752979
  ],
  [
    85.1278164,
    46.9752114
  ],
  [
    85.127269,
    46.9749633
  ],
  [
    85.1265984,
    46.9746536
  ],
  [
    85.126325,
    46.9744941
  ],
  [
    85.1254335,
    46.9737544
  ],
  [
    85.1250949,
    46.9734591
  ],
  [
    85.1248167,
    46.9732396
  ],
  [
    85.1244125,
    46.9730651
  ],
  [
    85.1238876,
    46.9729114
  ],
  [
    85.1234433,
    46.9727038
  ],
  [
    85.123039,
    46.9724881
  ],
  [
    85.1230203,
    46.9724711
  ],
  [
    85.1225884,
    46.9723265
  ],
  [
    85.1222646,
    46.9722474
  ],
  [
    85.1219097,
    46.9720687
  ],
  [
    85.1216236,
    46.9719676
  ],
  [
    85.1214561,
    46.9718589
  ],
  [
    85.1213433,
    46.9717043
  ],
  [
    85.1212254,
    46.9715078
  ],
  [
    85.1209881,
    46.9713051
  ],
  [
    85.120578,
    46.9710372
  ],
  [
    85.1201326,
    46.9708138
  ],
  [
    85.1199387,
    46.9707076
  ],
  [
    85.1197614,
    46.970541
  ],
  [
    85.1195133,
    46.9702721
  ],
  [
    85.1192535,
    46.97002
  ],
  [
    85.1191217,
    46.969935
  ],
  [
    85.1189291,
    46.9698645
  ],
  [
    85.1187539,
    46.9698284
  ],
  [
    85.1186604,
    46.9698229
  ],
  [
    85.1185206,
    46.9698453
  ],
  [
    85.1183637,
    46.9698893
  ],
  [
    85.1182035,
    46.9699209
  ],
  [
    85.1178462,
    46.969986
  ],
  [
    85.1176943,
    46.9700254
  ],
  [
    85.1175872,
    46.9700702
  ],
  [
    85.1174318,
    46.9701851
  ],
  [
    85.1173074,
    46.9703287
  ],
  [
    85.1170819,
    46.9707273
  ],
  [
    85.1169151,
    46.9709909
  ],
  [
    85.1166874,
    46.9710718
  ],
  [
    85.1164534,
    46.971125
  ],
  [
    85.1163513,
    46.9711405
  ],
  [
    85.1155549,
    46.9710376
  ],
  [
    85.1149845,
    46.9709686
  ],
  [
    85.1145627,
    46.9710088
  ],
  [
    85.1140816,
    46.9710924
  ],
  [
    85.113721,
    46.9711601
  ],
  [
    85.1133885,
    46.9711875
  ],
  [
    85.1131232,
    46.9711594
  ],
  [
    85.112317,
    46.9709625
  ],
  [
    85.1117527,
    46.9708728
  ],
  [
    85.1115277,
    46.9708031
  ],
  [
    85.1113403,
    46.97071
  ],
  [
    85.1112307,
    46.9705745
  ],
  [
    85.1112053,
    46.9704549
  ],
  [
    85.1111655,
    46.9702545
  ],
  [
    85.111089,
    46.9700739
  ],
  [
    85.1108823,
    46.9697773
  ],
  [
    85.1105705,
    46.9694307
  ],
  [
    85.1103584,
    46.9691844
  ],
  [
    85.1100512,
    46.9690675
  ],
  [
    85.1098514,
    46.9689556
  ],
  [
    85.1098161,
    46.9688513
  ],
  [
    85.1095971,
    46.9686589
  ],
  [
    85.1095142,
    46.9686034
  ],
  [
    85.1094165,
    46.9683084
  ],
  [
    85.1093477,
    46.9682115
  ],
  [
    85.1092497,
    46.9681129
  ],
  [
    85.1091992,
    46.9680139
  ],
  [
    85.1091359,
    46.9677406
  ],
  [
    85.10891,
    46.9674946
  ],
  [
    85.1087445,
    46.9673934
  ],
  [
    85.1083267,
    46.967261
  ],
  [
    85.1080948,
    46.9672326
  ],
  [
    85.1077683,
    46.967285
  ],
  [
    85.10731,
    46.9673139
  ],
  [
    85.1069242,
    46.9673917
  ],
  [
    85.1066806,
    46.9674271
  ],
  [
    85.1064319,
    46.96742
  ],
  [
    85.1061671,
    46.9673572
  ],
  [
    85.1054992,
    46.9671102
  ],
  [
    85.1051721,
    46.9670156
  ],
  [
    85.1050158,
    46.9669365
  ],
  [
    85.1046123,
    46.9667676
  ],
  [
    85.1043478,
    46.96667
  ],
  [
    85.104143,
    46.9666024
  ],
  [
    85.1038986,
    46.9664608
  ],
  [
    85.1037138,
    46.9664005
  ],
  [
    85.1034563,
    46.9663243
  ],
  [
    85.1033404,
    46.966295
  ],
  [
    85.1032134,
    46.9662315
  ],
  [
    85.103146,
    46.9661434
  ],
  [
    85.1030985,
    46.9660007
  ],
  [
    85.103029,
    46.9657687
  ],
  [
    85.102936,
    46.9656305
  ],
  [
    85.1028198,
    46.9655326
  ],
  [
    85.1025525,
    46.9654481
  ],
  [
    85.1022939,
    46.9654222
  ],
  [
    85.1016834,
    46.9654848
  ],
  [
    85.1014095,
    46.9654688
  ],
  [
    85.1012057,
    46.9653934
  ],
  [
    85.1009754,
    46.965159
  ],
  [
    85.1008187,
    46.9650991
  ],
  [
    85.1006509,
    46.9650776
  ],
  [
    85.0998647,
    46.9651407
  ],
  [
    85.099404,
    46.9651986
  ],
  [
    85.0987043,
    46.9652368
  ],
  [
    85.0984251,
    46.9652726
  ],
  [
    85.0980091,
    46.9653721
  ],
  [
    85.0977524,
    46.965401
  ],
  [
    85.0973343,
    46.9654134
  ],
  [
    85.0971716,
    46.9652003
  ],
  [
    85.0970572,
    46.965086
  ],
  [
    85.0965516,
    46.9648083
  ],
  [
    85.0963434,
    46.9647442
  ],
  [
    85.0961866,
    46.9646805
  ],
  [
    85.0959313,
    46.9644908
  ],
  [
    85.0957504,
    46.9643439
  ],
  [
    85.0955945,
    46.9641925
  ],
  [
    85.0954851,
    46.9639587
  ],
  [
    85.0954604,
    46.9636939
  ],
  [
    85.0955716,
    46.9630846
  ],
  [
    85.0956679,
    46.9628047
  ],
  [
    85.095679,
    46.9625304
  ],
  [
    85.0956505,
    46.9622406
  ],
  [
    85.095661,
    46.9621159
  ],
  [
    85.0957414,
    46.9619289
  ],
  [
    85.0957881,
    46.9617662
  ],
  [
    85.0957805,
    46.9616468
  ],
  [
    85.0956819,
    46.9615184
  ],
  [
    85.0952642,
    46.961163
  ],
  [
    85.0951085,
    46.9609786
  ],
  [
    85.0949599,
    46.9605519
  ],
  [
    85.094549,
    46.9602479
  ],
  [
    85.094234,
    46.960051
  ],
  [
    85.0938135,
    46.959701
  ],
  [
    85.0931254,
    46.9592925
  ],
  [
    85.0927633,
    46.9589441
  ],
  [
    85.0925822,
    46.958844
  ],
  [
    85.0924466,
    46.9587834
  ],
  [
    85.0922106,
    46.9587569
  ],
  [
    85.0920512,
    46.9587367
  ],
  [
    85.0910248,
    46.9584778
  ],
  [
    85.0908378,
    46.9583594
  ],
  [
    85.0903171,
    46.9579499
  ],
  [
    85.0900118,
    46.9577551
  ],
  [
    85.0899257,
    46.9577278
  ],
  [
    85.089434,
    46.9576777
  ],
  [
    85.089071,
    46.9576773
  ],
  [
    85.0888644,
    46.9576965
  ],
  [
    85.0886438,
    46.9577541
  ],
  [
    85.0884088,
    46.9578042
  ],
  [
    85.0878765,
    46.9578746
  ],
  [
    85.0872044,
    46.957996
  ],
  [
    85.0870292,
    46.9580457
  ],
  [
    85.0868493,
    46.9581391
  ],
  [
    85.0865993,
    46.9582367
  ],
  [
    85.0858923,
    46.9584541
  ],
  [
    85.0855347,
    46.9585534
  ],
  [
    85.0850035,
    46.9586821
  ],
  [
    85.084871,
    46.9587143
  ],
  [
    85.0847248,
    46.958666
  ],
  [
    85.0843286,
    46.9585343
  ],
  [
    85.0838821,
    46.9583006
  ],
  [
    85.0835725,
    46.9581433
  ],
  [
    85.0832741,
    46.9579342
  ],
  [
    85.0831494,
    46.9578786
  ],
  [
    85.082433,
    46.9579217
  ],
  [
    85.0816801,
    46.9580445
  ],
  [
    85.0812313,
    46.9580577
  ],
  [
    85.0805809,
    46.9580159
  ],
  [
    85.0800726,
    46.958059
  ],
  [
    85.0792221,
    46.9580378
  ],
  [
    85.0788427,
    46.9578936
  ],
  [
    85.0789786,
    46.9574094
  ],
  [
    85.0789398,
    46.956999
  ],
  [
    85.0789969,
    46.9563405
  ],
  [
    85.0788632,
    46.9559262
  ],
  [
    85.0786603,
    46.955725
  ],
  [
    85.0784955,
    46.9555799
  ],
  [
    85.0784768,
    46.9553007
  ],
  [
    85.078609,
    46.9550767
  ],
  [
    85.0789225,
    46.9548829
  ],
  [
    85.0790392,
    46.9547139
  ],
  [
    85.0791928,
    46.9543662
  ],
  [
    85.0793594,
    46.9541548
  ],
  [
    85.079608,
    46.9539961
  ],
  [
    85.0800216,
    46.9538634
  ],
  [
    85.0803203,
    46.9537591
  ],
  [
    85.0805382,
    46.9535983
  ],
  [
    85.0807118,
    46.9533353
  ],
  [
    85.0808492,
    46.9529904
  ],
  [
    85.0809157,
    46.9525556
  ],
  [
    85.0810888,
    46.952244
  ],
  [
    85.081226,
    46.9518321
  ],
  [
    85.081433,
    46.9515943
  ],
  [
    85.081665,
    46.9514836
  ],
  [
    85.0825956,
    46.9510872
  ],
  [
    85.0829479,
    46.950819
  ],
  [
    85.0840365,
    46.950413
  ],
  [
    85.0852345,
    46.9496307
  ],
  [
    85.0851937,
    46.9490139
  ],
  [
    85.0851103,
    46.9488816
  ],
  [
    85.0850489,
    46.9487577
  ],
  [
    85.0849855,
    46.9485022
  ],
  [
    85.0848278,
    46.9480175
  ],
  [
    85.0845801,
    46.9474317
  ],
  [
    85.0840835,
    46.9465343
  ],
  [
    85.0836964,
    46.945861
  ],
  [
    85.0831115,
    46.9450295
  ],
  [
    85.0829371,
    46.9445443
  ],
  [
    85.0828957,
    46.9441195
  ],
  [
    85.0827257,
    46.9429195
  ],
  [
    85.0826998,
    46.9418274
  ],
  [
    85.082656,
    46.9407183
  ],
  [
    85.082602,
    46.9404773
  ],
  [
    85.0821715,
    46.9402595
  ],
  [
    85.0818165,
    46.9399927
  ],
  [
    85.0813886,
    46.9395691
  ],
  [
    85.0802347,
    46.9389824
  ],
  [
    85.0783191,
    46.9380965
  ],
  [
    85.0779096,
    46.937831
  ],
  [
    85.0776322,
    46.937591
  ],
  [
    85.0775861,
    46.9373954
  ],
  [
    85.0776695,
    46.9366503
  ],
  [
    85.0776665,
    46.9354855
  ],
  [
    85.0777419,
    46.9351666
  ],
  [
    85.0780748,
    46.9347025
  ],
  [
    85.0782232,
    46.9343269
  ],
  [
    85.0781073,
    46.9336638
  ],
  [
    85.0782234,
    46.9324213
  ],
  [
    85.0782609,
    46.9311845
  ],
  [
    85.0781001,
    46.9309561
  ],
  [
    85.0778708,
    46.9309091
  ],
  [
    85.0774452,
    46.930953
  ],
  [
    85.0768002,
    46.9310793
  ],
  [
    85.0759916,
    46.9311922
  ],
  [
    85.0750743,
    46.931207
  ],
  [
    85.0745925,
    46.9308246
  ],
  [
    85.0742441,
    46.9307581
  ],
  [
    85.073913,
    46.9306511
  ],
  [
    85.0734656,
    46.930345
  ],
  [
    85.0723912,
    46.9298041
  ],
  [
    85.070391,
    46.9291757
  ],
  [
    85.0692635,
    46.9287899
  ],
  [
    85.0679745,
    46.9286585
  ],
  [
    85.0677708,
    46.9283691
  ],
  [
    85.0675866,
    46.9278901
  ],
  [
    85.0674156,
    46.9273437
  ],
  [
    85.0671191,
    46.9268806
  ],
  [
    85.0666914,
    46.9261042
  ],
  [
    85.0663278,
    46.9256637
  ],
  [
    85.0648066,
    46.9257933
  ],
  [
    85.0639795,
    46.9257029
  ],
  [
    85.063454,
    46.9260595
  ],
  [
    85.0624207,
    46.9267194
  ],
  [
    85.0618679,
    46.9271139
  ],
  [
    85.0610815,
    46.9277565
  ],
  [
    85.0606439,
    46.9279457
  ],
  [
    85.0599554,
    46.9280584
  ],
  [
    85.0590287,
    46.9279763
  ],
  [
    85.0574007,
    46.927558
  ],
  [
    85.0566761,
    46.9273304
  ],
  [
    85.056358,
    46.9272595
  ],
  [
    85.0556446,
    46.92724
  ],
  [
    85.0547746,
    46.9272503
  ],
  [
    85.0539074,
    46.9272509
  ],
  [
    85.053435,
    46.9272511
  ],
  [
    85.0531101,
    46.9273158
  ],
  [
    85.0526123,
    46.9275366
  ],
  [
    85.0521297,
    46.9277649
  ],
  [
    85.051244,
    46.927936
  ],
  [
    85.0506412,
    46.9281442
  ],
  [
    85.049981,
    46.9284207
  ],
  [
    85.049498,
    46.9285659
  ],
  [
    85.0492473,
    46.928572
  ],
  [
    85.0482961,
    46.9282666
  ],
  [
    85.0479816,
    46.9281141
  ],
  [
    85.0478223,
    46.9278532
  ],
  [
    85.047606,
    46.9272298
  ],
  [
    85.0473156,
    46.9268808
  ],
  [
    85.0470676,
    46.9266587
  ],
  [
    85.0464675,
    46.9263231
  ],
  [
    85.045486,
    46.925824
  ],
  [
    85.0447967,
    46.9254455
  ],
  [
    85.0441609,
    46.9252962
  ],
  [
    85.0435199,
    46.9250823
  ],
  [
    85.0419679,
    46.9244242
  ],
  [
    85.0410676,
    46.9242685
  ],
  [
    85.0393807,
    46.9238344
  ],
  [
    85.0375959,
    46.9232285
  ],
  [
    85.0360505,
    46.9230651
  ],
  [
    85.0351103,
    46.9226287
  ],
  [
    85.0343902,
    46.9220768
  ],
  [
    85.0335786,
    46.921729
  ],
  [
    85.0329429,
    46.9218692
  ],
  [
    85.0318922,
    46.9223637
  ],
  [
    85.03118,
    46.9226983
  ],
  [
    85.0307647,
    46.9231107
  ],
  [
    85.0300678,
    46.923199
  ],
  [
    85.0289405,
    46.923065
  ],
  [
    85.0284749,
    46.923064
  ],
  [
    85.0280192,
    46.9232399
  ],
  [
    85.0278043,
    46.9233691
  ],
  [
    85.0259596,
    46.923698
  ],
  [
    85.0248983,
    46.9231909
  ],
  [
    85.0241352,
    46.9229578
  ],
  [
    85.0234243,
    46.9227587
  ],
  [
    85.0232443,
    46.9228861
  ],
  [
    85.0227785,
    46.9231635
  ],
  [
    85.0222,
    46.9234466
  ],
  [
    85.0213047,
    46.9236881
  ],
  [
    85.0203937,
    46.9239147
  ],
  [
    85.0193307,
    46.9242997
  ],
  [
    85.0182149,
    46.9244886
  ],
  [
    85.0176613,
    46.924554
  ],
  [
    85.017329,
    46.924503
  ],
  [
    85.0161499,
    46.9240097
  ],
  [
    85.0155283,
    46.9237586
  ],
  [
    85.0144514,
    46.9236006
  ],
  [
    85.0137755,
    46.9234908
  ],
  [
    85.0135887,
    46.9234922
  ],
  [
    85.0130651,
    46.9238075
  ],
  [
    85.0126752,
    46.9241596
  ],
  [
    85.0122211,
    46.9246678
  ],
  [
    85.0118907,
    46.9248383
  ],
  [
    85.0115,
    46.9248854
  ],
  [
    85.0104251,
    46.924892
  ],
  [
    85.0099332,
    46.9250227
  ],
  [
    85.0090014,
    46.9251633
  ],
  [
    85.008477,
    46.9251896
  ],
  [
    85.0079858,
    46.9253471
  ],
  [
    85.0076728,
    46.9255352
  ],
  [
    85.0073026,
    46.9258774
  ],
  [
    85.006804,
    46.9261223
  ],
  [
    85.006147,
    46.9264562
  ],
  [
    85.0054482,
    46.9263004
  ],
  [
    85.0047013,
    46.926101
  ],
  [
    85.0038839,
    46.925821
  ],
  [
    85.0034708,
    46.9257143
  ],
  [
    85.003092,
    46.9256652
  ],
  [
    85.0027175,
    46.9255613
  ],
  [
    85.0024016,
    46.9253765
  ],
  [
    85.0020145,
    46.9251042
  ],
  [
    85.0019552,
    46.9249822
  ],
  [
    85.0020731,
    46.9246728
  ],
  [
    85.0020901,
    46.924323
  ],
  [
    85.0020405,
    46.9240825
  ],
  [
    85.0019274,
    46.9238487
  ],
  [
    85.0019081,
    46.9237078
  ],
  [
    85.0020292,
    46.9234788
  ],
  [
    85.0022461,
    46.9231232
  ],
  [
    85.0024158,
    46.9228121
  ],
  [
    85.0024119,
    46.9225181
  ],
  [
    85.0023292,
    46.9223443
  ],
  [
    85.0020732,
    46.9221497
  ],
  [
    85.0018995,
    46.9219932
  ],
  [
    85.0018053,
    46.9217255
  ],
  [
    85.001659,
    46.9214385
  ],
  [
    85.0015104,
    46.9213068
  ],
  [
    85.0012637,
    46.9211469
  ],
  [
    85.0005699,
    46.9209181
  ],
  [
    85.0004574,
    46.9209094
  ],
  [
    85.0003377,
    46.9209228
  ],
  [
    85.0000407,
    46.9210573
  ],
  [
    84.9995802,
    46.9212878
  ],
  [
    84.9991475,
    46.9213506
  ],
  [
    84.9985503,
    46.9214027
  ],
  [
    84.9978431,
    46.9213894
  ],
  [
    84.997313,
    46.9213375
  ],
  [
    84.9965815,
    46.9212435
  ],
  [
    84.9959726,
    46.9211703
  ],
  [
    84.995411,
    46.9210111
  ],
  [
    84.9948556,
    46.9207993
  ],
  [
    84.9945348,
    46.9206926
  ],
  [
    84.9936061,
    46.9204474
  ],
  [
    84.9933326,
    46.9203195
  ],
  [
    84.9930647,
    46.920251
  ],
  [
    84.9926688,
    46.920218
  ],
  [
    84.9922473,
    46.9201885
  ],
  [
    84.991856,
    46.9200889
  ],
  [
    84.9916649,
    46.9199984
  ],
  [
    84.9915775,
    46.9198459
  ],
  [
    84.9912137,
    46.919207
  ],
  [
    84.9910513,
    46.9191022
  ],
  [
    84.9907917,
    46.9190365
  ],
  [
    84.990338,
    46.9189534
  ],
  [
    84.9896947,
    46.9188461
  ],
  [
    84.9888926,
    46.9187477
  ],
  [
    84.9884782,
    46.9185869
  ],
  [
    84.9882688,
    46.918408
  ],
  [
    84.9880432,
    46.9180124
  ],
  [
    84.9878133,
    46.9177482
  ],
  [
    84.9871891,
    46.9174234
  ],
  [
    84.986335,
    46.9170152
  ],
  [
    84.9857905,
    46.9167398
  ],
  [
    84.985481,
    46.9165562
  ],
  [
    84.9851105,
    46.9163026
  ],
  [
    84.9848259,
    46.9161881
  ],
  [
    84.984374,
    46.9160233
  ],
  [
    84.9843388,
    46.916018
  ],
  [
    84.983648,
    46.9157781
  ],
  [
    84.9833543,
    46.9156185
  ],
  [
    84.982987,
    46.9152646
  ],
  [
    84.9828791,
    46.9151082
  ],
  [
    84.9828585,
    46.9148024
  ],
  [
    84.9828223,
    46.9144701
  ],
  [
    84.9826707,
    46.9140194
  ],
  [
    84.9825785,
    46.9137372
  ],
  [
    84.9826003,
    46.9134312
  ],
  [
    84.982592,
    46.913016
  ],
  [
    84.9823453,
    46.9125641
  ],
  [
    84.9818573,
    46.9119758
  ],
  [
    84.9815477,
    46.9115466
  ],
  [
    84.9813355,
    46.9109926
  ],
  [
    84.9812612,
    46.9107295
  ],
  [
    84.9812397,
    46.9103763
  ],
  [
    84.9811561,
    46.9096978
  ],
  [
    84.9809236,
    46.9091878
  ],
  [
    84.9804592,
    46.9086949
  ],
  [
    84.9800495,
    46.9082841
  ],
  [
    84.979988,
    46.9079785
  ],
  [
    84.9799738,
    46.9074135
  ],
  [
    84.9798466,
    46.9067267
  ],
  [
    84.9798075,
    46.90647
  ],
  [
    84.9799261,
    46.905778
  ],
  [
    84.9799859,
    46.9053288
  ],
  [
    84.9799168,
    46.9049652
  ],
  [
    84.9797303,
    46.9045948
  ],
  [
    84.9793971,
    46.9041557
  ],
  [
    84.9791367,
    46.9036042
  ],
  [
    84.9790855,
    46.9033508
  ],
  [
    84.9793223,
    46.9028662
  ],
  [
    84.9795098,
    46.902173
  ],
  [
    84.979316,
    46.9018731
  ],
  [
    84.9793086,
    46.9014974
  ],
  [
    84.9796491,
    46.9011314
  ],
  [
    84.9801053,
    46.9007209
  ],
  [
    84.9802369,
    46.9004731
  ],
  [
    84.9800026,
    46.9001017
  ],
  [
    84.9799799,
    46.899738
  ],
  [
    84.9800696,
    46.8993213
  ],
  [
    84.9801249,
    46.898886
  ],
  [
    84.9800333,
    46.8983284
  ],
  [
    84.980168,
    46.8978818
  ],
  [
    84.9801484,
    46.8970941
  ],
  [
    84.9799496,
    46.8965357
  ],
  [
    84.9791653,
    46.8962288
  ],
  [
    84.9786432,
    46.8958134
  ],
  [
    84.9783543,
    46.8952947
  ],
  [
    84.9782226,
    46.8946481
  ],
  [
    84.9781621,
    46.8941457
  ],
  [
    84.9783226,
    46.8934657
  ],
  [
    84.9786113,
    46.8931063
  ],
  [
    84.9790127,
    46.892697
  ],
  [
    84.9791898,
    46.8923305
  ],
  [
    84.9791395,
    46.8920164
  ],
  [
    84.9789704,
    46.8916294
  ],
  [
    84.9784998,
    46.8912573
  ],
  [
    84.9776352,
    46.8906655
  ],
  [
    84.9768661,
    46.8902023
  ],
  [
    84.9763087,
    46.8899948
  ],
  [
    84.975607,
    46.8899475
  ],
  [
    84.9750717,
    46.8895812
  ],
  [
    84.9744939,
    46.8891521
  ],
  [
    84.9740109,
    46.8884075
  ],
  [
    84.9735395,
    46.8878374
  ],
  [
    84.9733384,
    46.8873595
  ],
  [
    84.9733036,
    46.8870367
  ],
  [
    84.9733906,
    46.8867278
  ],
  [
    84.9737078,
    46.8861524
  ],
  [
    84.9738905,
    46.8856375
  ],
  [
    84.9739042,
    46.8852345
  ],
  [
    84.9739412,
    46.8847984
  ],
  [
    84.9741385,
    46.8844981
  ],
  [
    84.9748179,
    46.8839714
  ],
  [
    84.9755112,
    46.8834034
  ],
  [
    84.9762857,
    46.8826451
  ],
  [
    84.9766698,
    46.882005
  ],
  [
    84.9769847,
    46.8817366
  ],
  [
    84.9772237,
    46.8815418
  ],
  [
    84.9772957,
    46.8812848
  ],
  [
    84.9770827,
    46.8809529
  ],
  [
    84.9768228,
    46.8806827
  ],
  [
    84.9766953,
    46.8804636
  ],
  [
    84.9765337,
    46.8792223
  ],
  [
    84.9763688,
    46.8783347
  ],
  [
    84.9763468,
    46.8779179
  ],
  [
    84.9764161,
    46.877482
  ],
  [
    84.9766778,
    46.8770915
  ],
  [
    84.9772916,
    46.8763217
  ],
  [
    84.9779395,
    46.8755798
  ],
  [
    84.9791158,
    46.8748578
  ],
  [
    84.9795502,
    46.874441
  ],
  [
    84.9796555,
    46.8739751
  ],
  [
    84.9795496,
    46.8735525
  ],
  [
    84.9790421,
    46.8730053
  ],
  [
    84.9787467,
    46.8727061
  ],
  [
    84.9782949,
    46.8722365
  ],
  [
    84.9779582,
    46.872233
  ],
  [
    84.9770671,
    46.8722229
  ],
  [
    84.9763294,
    46.8721794
  ],
  [
    84.9755379,
    46.8722012
  ],
  [
    84.97478,
    46.8723395
  ],
  [
    84.9740378,
    46.872359
  ],
  [
    84.9733462,
    46.8722223
  ],
  [
    84.9725066,
    46.8720982
  ],
  [
    84.9720215,
    46.8721426
  ],
  [
    84.9713445,
    46.8725338
  ],
  [
    84.9709196,
    46.8726491
  ],
  [
    84.9699559,
    46.8726023
  ],
  [
    84.9690178,
    46.8726292
  ],
  [
    84.9671737,
    46.8728034
  ],
  [
    84.9658387,
    46.8726692
  ],
  [
    84.9651276,
    46.8722854
  ],
  [
    84.9641151,
    46.871511
  ],
  [
    84.9636897,
    46.8708824
  ],
  [
    84.9636799,
    46.8702877
  ],
  [
    84.9643795,
    46.8696859
  ],
  [
    84.965644,
    46.8688047
  ],
  [
    84.9657432,
    46.8680058
  ],
  [
    84.9653196,
    46.867533
  ],
  [
    84.9644821,
    46.8673038
  ],
  [
    84.9639611,
    46.8668559
  ],
  [
    84.9631078,
    46.8663738
  ],
  [
    84.9616521,
    46.8662053
  ],
  [
    84.9601151,
    46.8658866
  ],
  [
    84.9594017,
    46.8654832
  ],
  [
    84.9578313,
    46.8651377
  ],
  [
    84.9568802,
    46.864935
  ],
  [
    84.955633,
    46.8646509
  ],
  [
    84.9545372,
    46.8643247
  ],
  [
    84.9535216,
    46.8642243
  ],
  [
    84.9528202,
    46.8638627
  ],
  [
    84.9519999,
    46.8633227
  ],
  [
    84.9510745,
    46.8633283
  ],
  [
    84.9499917,
    46.8633773
  ],
  [
    84.9494858,
    46.8635527
  ],
  [
    84.9488397,
    46.8634128
  ],
  [
    84.9481897,
    46.8633933
  ],
  [
    84.9478571,
    46.8634055
  ],
  [
    84.946851,
    46.8633759
  ],
  [
    84.9464666,
    46.8634291
  ],
  [
    84.945753,
    46.8633878
  ],
  [
    84.9448692,
    46.8631269
  ],
  [
    84.944232,
    46.8628509
  ],
  [
    84.94386,
    46.862966
  ],
  [
    84.9430086,
    46.8632624
  ],
  [
    84.9425036,
    46.8635759
  ],
  [
    84.9408588,
    46.86452
  ],
  [
    84.94001,
    46.8650488
  ],
  [
    84.9393313,
    46.865767
  ],
  [
    84.9389169,
    46.8663285
  ],
  [
    84.9380603,
    46.8672479
  ],
  [
    84.9365743,
    46.8685767
  ],
  [
    84.9355066,
    46.8693636
  ],
  [
    84.9339135,
    46.8704399
  ],
  [
    84.9328877,
    46.8710811
  ],
  [
    84.9319117,
    46.8719916
  ],
  [
    84.9312407,
    46.872796
  ],
  [
    84.9309685,
    46.8733398
  ],
  [
    84.9305978,
    46.8753289
  ],
  [
    84.9301126,
    46.8756592
  ],
  [
    84.9284442,
    46.8756456
  ],
  [
    84.9268895,
    46.8756027
  ],
  [
    84.923363,
    46.8756931
  ],
  [
    84.9228984,
    46.8757374
  ],
  [
    84.9221697,
    46.876495
  ],
  [
    84.9214293,
    46.8772161
  ],
  [
    84.920743,
    46.8777699
  ],
  [
    84.9196566,
    46.8783499
  ],
  [
    84.9183034,
    46.8790244
  ],
  [
    84.9172722,
    46.8793276
  ],
  [
    84.9162818,
    46.8796331
  ],
  [
    84.915534,
    46.8800309
  ],
  [
    84.9151807,
    46.8803736
  ],
  [
    84.9150126,
    46.8810226
  ],
  [
    84.9148846,
    46.8816539
  ],
  [
    84.9144506,
    46.8831624
  ],
  [
    84.9143967,
    46.8835723
  ],
  [
    84.9143407,
    46.883837
  ],
  [
    84.9139457,
    46.8847276
  ],
  [
    84.9138329,
    46.8851448
  ],
  [
    84.913851,
    46.8854491
  ],
  [
    84.9139096,
    46.8858108
  ],
  [
    84.9142192,
    46.8864549
  ],
  [
    84.9144059,
    46.8869674
  ],
  [
    84.9145585,
    46.8880031
  ],
  [
    84.9144698,
    46.8883764
  ],
  [
    84.9142048,
    46.8894324
  ],
  [
    84.9137799,
    46.890312
  ],
  [
    84.9131194,
    46.891052
  ],
  [
    84.9102269,
    46.893227
  ],
  [
    84.9092003,
    46.8938914
  ],
  [
    84.9085397,
    46.8945388
  ],
  [
    84.9075916,
    46.8951089
  ],
  [
    84.9062333,
    46.8960181
  ],
  [
    84.9063724,
    46.8978238
  ],
  [
    84.9056567,
    46.8984707
  ],
  [
    84.9049315,
    46.8987732
  ],
  [
    84.9035669,
    46.899136
  ],
  [
    84.9024993,
    46.8991371
  ],
  [
    84.8998962,
    46.8998502
  ],
  [
    84.8994555,
    46.9005508
  ],
  [
    84.8977214,
    46.900397
  ],
  [
    84.8958636,
    46.9008831
  ],
  [
    84.8957734,
    46.9014124
  ],
  [
    84.8957177,
    46.9018575
  ],
  [
    84.8956545,
    46.9023286
  ],
  [
    84.8955751,
    46.9028676
  ],
  [
    84.8955386,
    46.9031914
  ],
  [
    84.8955289,
    46.9039558
  ],
  [
    84.8954616,
    46.9047327
  ],
  [
    84.8927435,
    46.9055747
  ],
  [
    84.8912409,
    46.9060333
  ],
  [
    84.8901075,
    46.9062031
  ],
  [
    84.8891617,
    46.9063532
  ],
  [
    84.8885532,
    46.907115
  ],
  [
    84.8881292,
    46.907742
  ],
  [
    84.8874269,
    46.9086983
  ],
  [
    84.8838732,
    46.9092192
  ],
  [
    84.8820408,
    46.9095098
  ],
  [
    84.8813191,
    46.9096327
  ],
  [
    84.8809879,
    46.9097138
  ],
  [
    84.8805029,
    46.9099379
  ],
  [
    84.8797939,
    46.9102983
  ],
  [
    84.8791475,
    46.9106458
  ],
  [
    84.8782072,
    46.9111637
  ],
  [
    84.8776815,
    46.9114784
  ],
  [
    84.8774889,
    46.9116029
  ],
  [
    84.8772345,
    46.9119721
  ],
  [
    84.8769871,
    46.9124079
  ],
  [
    84.8766699,
    46.913183
  ],
  [
    84.8764935,
    46.9135177
  ],
  [
    84.8763249,
    46.9137636
  ],
  [
    84.8760225,
    46.9139984
  ],
  [
    84.8755906,
    46.9142537
  ],
  [
    84.8754066,
    46.9143765
  ],
  [
    84.8749277,
    46.9148883
  ],
  [
    84.8746012,
    46.9152978
  ],
  [
    84.8741851,
    46.9157282
  ],
  [
    84.8739525,
    46.9159328
  ],
  [
    84.8736517,
    46.916062
  ],
  [
    84.8731966,
    46.9161982
  ],
  [
    84.8729731,
    46.9163503
  ],
  [
    84.8728582,
    46.9165346
  ],
  [
    84.8726936,
    46.9167771
  ],
  [
    84.8722143,
    46.917112
  ],
  [
    84.871837,
    46.9173548
  ],
  [
    84.8715355,
    46.9176547
  ],
  [
    84.8712169,
    46.9180785
  ],
  [
    84.870915,
    46.9184356
  ],
  [
    84.8706253,
    46.9187979
  ],
  [
    84.8703811,
    46.9191612
  ],
  [
    84.8701122,
    46.9196367
  ],
  [
    84.8700528,
    46.9198285
  ],
  [
    84.8701067,
    46.9200485
  ],
  [
    84.87033,
    46.9202563
  ],
  [
    84.8702602,
    46.9204112
  ],
  [
    84.8701713,
    46.9206652
  ],
  [
    84.8699735,
    46.9208349
  ],
  [
    84.8696826,
    46.9210596
  ],
  [
    84.86911,
    46.9214242
  ],
  [
    84.8689637,
    46.9216659
  ],
  [
    84.8688388,
    46.9222695
  ],
  [
    84.8683974,
    46.9224135
  ],
  [
    84.8681322,
    46.9226442
  ],
  [
    84.8677229,
    46.9227338
  ],
  [
    84.8675103,
    46.9229668
  ],
  [
    84.8672528,
    46.9232283
  ],
  [
    84.8668962,
    46.9232904
  ],
  [
    84.8657954,
    46.9237342
  ],
  [
    84.8655211,
    46.9239118
  ],
  [
    84.8651298,
    46.9241396
  ],
  [
    84.8646582,
    46.9243682
  ],
  [
    84.8645202,
    46.9246316
  ],
  [
    84.8644726,
    46.9250144
  ],
  [
    84.864094,
    46.9254537
  ],
  [
    84.8636773,
    46.9258744
  ],
  [
    84.8633141,
    46.9260284
  ],
  [
    84.8622022,
    46.9261527
  ],
  [
    84.861577,
    46.9262179
  ],
  [
    84.861345,
    46.9264545
  ],
  [
    84.8611826,
    46.9268125
  ],
  [
    84.8609082,
    46.9272435
  ],
  [
    84.860615,
    46.9273684
  ],
  [
    84.8599076,
    46.9274353
  ],
  [
    84.8591702,
    46.9275312
  ],
  [
    84.8584897,
    46.9278512
  ],
  [
    84.8580086,
    46.928332
  ],
  [
    84.8580132,
    46.9285889
  ],
  [
    84.8581687,
    46.9290915
  ],
  [
    84.8581877,
    46.9295236
  ],
  [
    84.8580949,
    46.9299461
  ],
  [
    84.8579848,
    46.9303967
  ],
  [
    84.8584426,
    46.9306841
  ],
  [
    84.8588054,
    46.9309809
  ],
  [
    84.8587635,
    46.931214
  ],
  [
    84.8586472,
    46.9314764
  ],
  [
    84.8584248,
    46.9318466
  ],
  [
    84.8587249,
    46.9321161
  ],
  [
    84.8587832,
    46.9325089
  ],
  [
    84.8589563,
    46.9328398
  ],
  [
    84.8589029,
    46.9333515
  ],
  [
    84.8586122,
    46.9342228
  ],
  [
    84.8586626,
    46.9345734
  ],
  [
    84.8589281,
    46.9348431
  ],
  [
    84.859012,
    46.9359223
  ],
  [
    84.859034,
    46.9363467
  ],
  [
    84.8590316,
    46.9369393
  ],
  [
    84.8588888,
    46.9376658
  ],
  [
    84.8586598,
    46.9384218
  ],
  [
    84.8583843,
    46.9387087
  ],
  [
    84.8583349,
    46.9390989
  ],
  [
    84.8585913,
    46.9395389
  ],
  [
    84.8592453,
    46.9400768
  ],
  [
    84.8598488,
    46.9408595
  ],
  [
    84.8604931,
    46.9418669
  ],
  [
    84.8609788,
    46.9429723
  ],
  [
    84.8612451,
    46.9440668
  ],
  [
    84.8610302,
    46.9445432
  ],
  [
    84.8601127,
    46.9451131
  ],
  [
    84.8593929,
    46.945684
  ],
  [
    84.8586051,
    46.9463955
  ],
  [
    84.8581209,
    46.9469439
  ],
  [
    84.85623,
    46.9471067
  ],
  [
    84.8552746,
    46.9472794
  ],
  [
    84.8544486,
    46.9475552
  ],
  [
    84.8538323,
    46.9476871
  ],
  [
    84.8520082,
    46.9476424
  ],
  [
    84.8508625,
    46.9476022
  ],
  [
    84.8506299,
    46.9482885
  ],
  [
    84.8506223,
    46.9488689
  ],
  [
    84.8506813,
    46.9496091
  ],
  [
    84.8505464,
    46.9499321
  ],
  [
    84.8503287,
    46.9502685
  ],
  [
    84.8502347,
    46.950653
  ],
  [
    84.849912,
    46.9510147
  ],
  [
    84.8495069,
    46.9511758
  ],
  [
    84.8485467,
    46.951433
  ],
  [
    84.847733,
    46.9516215
  ],
  [
    84.8472854,
    46.9525292
  ],
  [
    84.8471925,
    46.9532198
  ],
  [
    84.8472672,
    46.95382
  ],
  [
    84.8468555,
    46.9540576
  ],
  [
    84.8452446,
    46.9546974
  ],
  [
    84.8443764,
    46.9547862
  ],
  [
    84.8440627,
    46.9548896
  ],
  [
    84.8435619,
    46.954965
  ],
  [
    84.8432123,
    46.9551071
  ],
  [
    84.8425987,
    46.9552614
  ],
  [
    84.8414509,
    46.9554245
  ],
  [
    84.8410028,
    46.9556964
  ],
  [
    84.8406727,
    46.9560788
  ],
  [
    84.8404129,
    46.9566507
  ],
  [
    84.8400853,
    46.9571103
  ],
  [
    84.839453,
    46.9571833
  ],
  [
    84.8385348,
    46.9571047
  ],
  [
    84.8378392,
    46.9567764
  ],
  [
    84.8367063,
    46.9566166
  ],
  [
    84.8364333,
    46.9566446
  ],
  [
    84.8362783,
    46.956865
  ],
  [
    84.8360736,
    46.957099
  ],
  [
    84.8358038,
    46.957184
  ],
  [
    84.8347881,
    46.9571158
  ],
  [
    84.8345249,
    46.9571565
  ],
  [
    84.8340903,
    46.9573088
  ],
  [
    84.8337242,
    46.9573589
  ],
  [
    84.8334224,
    46.9573418
  ],
  [
    84.8320991,
    46.957811
  ],
  [
    84.8307131,
    46.9583146
  ],
  [
    84.8288832,
    46.9590652
  ],
  [
    84.828064,
    46.9596782
  ],
  [
    84.8275129,
    46.9598288
  ],
  [
    84.8255178,
    46.9601256
  ],
  [
    84.8241472,
    46.9603345
  ],
  [
    84.8236438,
    46.9603605
  ],
  [
    84.8231368,
    46.9602571
  ],
  [
    84.821088,
    46.9595119
  ],
  [
    84.8207975,
    46.95946
  ],
  [
    84.819122,
    46.9596271
  ],
  [
    84.8183668,
    46.9596975
  ],
  [
    84.8180455,
    46.9596966
  ],
  [
    84.8177272,
    46.9595612
  ],
  [
    84.8169842,
    46.9589809
  ],
  [
    84.8156917,
    46.9578756
  ],
  [
    84.8141912,
    46.9574119
  ],
  [
    84.8138399,
    46.9573441
  ],
  [
    84.8134555,
    46.9575932
  ],
  [
    84.8130869,
    46.9578876
  ],
  [
    84.8125491,
    46.9585056
  ],
  [
    84.8119506,
    46.9593834
  ],
  [
    84.8116165,
    46.959813
  ],
  [
    84.8112319,
    46.9601817
  ],
  [
    84.8105002,
    46.9607736
  ],
  [
    84.8101003,
    46.9609744
  ],
  [
    84.8094046,
    46.9612226
  ],
  [
    84.8086038,
    46.9615903
  ],
  [
    84.8078916,
    46.9619707
  ],
  [
    84.8072808,
    46.9623998
  ],
  [
    84.8066111,
    46.9627997
  ],
  [
    84.8061104,
    46.9630848
  ],
  [
    84.8052378,
    46.963433
  ],
  [
    84.8039131,
    46.963941
  ],
  [
    84.8030256,
    46.964371
  ],
  [
    84.8021767,
    46.9648234
  ],
  [
    84.801365,
    46.9651533
  ],
  [
    84.8000991,
    46.9656163
  ],
  [
    84.7987632,
    46.9659937
  ],
  [
    84.7966123,
    46.9665678
  ],
  [
    84.7959586,
    46.9668388
  ],
  [
    84.7949315,
    46.9677951
  ],
  [
    84.7942761,
    46.9682933
  ],
  [
    84.7933297,
    46.9689787
  ],
  [
    84.7925448,
    46.9696495
  ],
  [
    84.7919567,
    46.9701147
  ],
  [
    84.7908868,
    46.9707314
  ],
  [
    84.7895549,
    46.971448
  ],
  [
    84.788059,
    46.9722407
  ],
  [
    84.787234,
    46.972828
  ],
  [
    84.7859611,
    46.97358
  ],
  [
    84.7855805,
    46.9736531
  ],
  [
    84.7837114,
    46.973352
  ],
  [
    84.7827629,
    46.9734511
  ],
  [
    84.7823139,
    46.9736877
  ],
  [
    84.7822931,
    46.9741193
  ],
  [
    84.7823807,
    46.9744051
  ],
  [
    84.7826327,
    46.9750957
  ],
  [
    84.7824487,
    46.9756351
  ],
  [
    84.781795,
    46.9760612
  ],
  [
    84.7811826,
    46.9763481
  ],
  [
    84.7806651,
    46.9767282
  ],
  [
    84.7808098,
    46.9773593
  ],
  [
    84.7807361,
    46.9791644
  ],
  [
    84.7806824,
    46.9810423
  ],
  [
    84.7806474,
    46.9818847
  ],
  [
    84.7804658,
    46.9823283
  ],
  [
    84.7800261,
    46.9827306
  ],
  [
    84.778877,
    46.9834589
  ],
  [
    84.7776311,
    46.9841952
  ],
  [
    84.7766897,
    46.9848374
  ],
  [
    84.7764412,
    46.9852357
  ],
  [
    84.7764249,
    46.9856149
  ],
  [
    84.7764913,
    46.9859923
  ],
  [
    84.7765625,
    46.9864362
  ],
  [
    84.776426,
    46.9867342
  ],
  [
    84.7763408,
    46.987054
  ],
  [
    84.7763607,
    46.9872393
  ],
  [
    84.7764036,
    46.9873546
  ],
  [
    84.7764799,
    46.9874241
  ],
  [
    84.7767389,
    46.9876466
  ],
  [
    84.7766435,
    46.9881019
  ],
  [
    84.7767961,
    46.9886663
  ],
  [
    84.7766939,
    46.9893621
  ],
  [
    84.776525,
    46.989748
  ],
  [
    84.7762659,
    46.9902132
  ],
  [
    84.7760351,
    46.9906699
  ],
  [
    84.7758893,
    46.9911971
  ],
  [
    84.775684,
    46.9918357
  ],
  [
    84.7751551,
    46.9918737
  ],
  [
    84.7748096,
    46.9918337
  ],
  [
    84.7744711,
    46.9918121
  ],
  [
    84.7743399,
    46.9918495
  ],
  [
    84.7741358,
    46.9919564
  ],
  [
    84.7739319,
    46.9921958
  ],
  [
    84.7736286,
    46.9922914
  ],
  [
    84.7733665,
    46.9923102
  ],
  [
    84.7731353,
    46.9924253
  ],
  [
    84.7728216,
    46.9926233
  ],
  [
    84.7723187,
    46.9929085
  ],
  [
    84.771639,
    46.99318
  ],
  [
    84.770694,
    46.993374
  ],
  [
    84.7700739,
    46.9935315
  ],
  [
    84.7697173,
    46.9937991
  ],
  [
    84.7694724,
    46.9939215
  ],
  [
    84.7694119,
    46.9941147
  ],
  [
    84.7692993,
    46.9944833
  ],
  [
    84.7691864,
    46.9947263
  ],
  [
    84.7691721,
    46.9949144
  ],
  [
    84.7691694,
    46.9951985
  ],
  [
    84.7690541,
    46.9954492
  ],
  [
    84.7688992,
    46.9957662
  ],
  [
    84.7684414,
    46.9959655
  ],
  [
    84.768116,
    46.9961382
  ],
  [
    84.7677374,
    46.9963346
  ],
  [
    84.7672016,
    46.9967361
  ],
  [
    84.7668095,
    46.9967602
  ],
  [
    84.7664811,
    46.9968197
  ],
  [
    84.7661268,
    46.9969115
  ],
  [
    84.7657585,
    46.9970531
  ],
  [
    84.7654201,
    46.9973185
  ],
  [
    84.7649862,
    46.9975157
  ],
  [
    84.7639093,
    46.9979654
  ],
  [
    84.7633292,
    46.9983584
  ],
  [
    84.7625205,
    46.9987343
  ],
  [
    84.7617931,
    46.9989479
  ],
  [
    84.7609447,
    46.9989988
  ],
  [
    84.7600414,
    46.9992028
  ],
  [
    84.7593283,
    47.0000962
  ],
  [
    84.7594355,
    47.0009779
  ],
  [
    84.7591834,
    47.0046508
  ],
  [
    84.7520112,
    47.0048557
  ],
  [
    84.7498762,
    47.0049216
  ],
  [
    84.7467648,
    47.0049069
  ],
  [
    84.7415291,
    47.0050606
  ],
  [
    84.7360896,
    47.0052288
  ],
  [
    84.7228073,
    47.0056239
  ],
  [
    84.7184943,
    47.005719
  ],
  [
    84.7182905,
    47.006319
  ],
  [
    84.7176467,
    47.0062824
  ],
  [
    84.7151362,
    47.0067214
  ],
  [
    84.7136341,
    47.006436
  ],
  [
    84.7115849,
    47.0071823
  ],
  [
    84.7097396,
    47.0094941
  ],
  [
    84.7061347,
    47.0088503
  ],
  [
    84.7048044,
    47.0078991
  ],
  [
    84.7019719,
    47.0075188
  ],
  [
    84.6996975,
    47.0063335
  ],
  [
    84.6977233,
    47.0054264
  ],
  [
    84.6963071,
    47.0056898
  ],
  [
    84.6927022,
    47.0039631
  ],
  [
    84.6915436,
    47.0042703
  ],
  [
    84.6896337,
    47.0041387
  ],
  [
    84.6860504,
    47.00468
  ],
  [
    84.6830462,
    47.0031876
  ],
  [
    84.6817589,
    47.0038313
  ],
  [
    84.679141,
    47.0046215
  ],
  [
    84.6777343,
    47.0037346
  ],
  [
    84.677185,
    47.0036964
  ],
  [
    84.6765017,
    47.0029826
  ],
  [
    84.6717166,
    47.0008169
  ],
  [
    84.6713519,
    47.0003339
  ],
  [
    84.6712231,
    46.9986802
  ],
  [
    84.6703863,
    46.9983583
  ],
  [
    84.6685838,
    46.9984022
  ],
  [
    84.667232,
    46.999251
  ],
  [
    84.6660948,
    46.9993388
  ],
  [
    84.6646141,
    47.0007145
  ],
  [
    84.6628116,
    47.0015779
  ],
  [
    84.6587132,
    47.0015486
  ],
  [
    84.6578978,
    47.0004072
  ],
  [
    84.655602,
    46.9997046
  ],
  [
    84.6543145,
    46.9987388
  ],
  [
    84.6526407,
    46.9985194
  ],
  [
    84.6489715,
    46.9993973
  ],
  [
    84.648156,
    46.9984608
  ],
  [
    84.6464181,
    46.9987827
  ],
  [
    84.6456456,
    46.9983875
  ],
  [
    84.6436714,
    46.9983291
  ],
  [
    84.6425128,
    46.997729
  ],
  [
    84.6407962,
    46.9971729
  ],
  [
    84.6400236,
    46.9959729
  ],
  [
    84.6356462,
    46.9962217
  ],
  [
    84.6337795,
    46.9956947
  ],
  [
    84.633007,
    46.9951093
  ],
  [
    84.6319771,
    46.9950215
  ],
  [
    84.6305394,
    46.9946995
  ],
  [
    84.6291017,
    46.9952703
  ],
  [
    84.6285009,
    46.9953435
  ],
  [
    84.6275139,
    46.9950069
  ],
  [
    84.6270633,
    46.9953142
  ],
  [
    84.6270846,
    46.9965437
  ],
  [
    84.6262908,
    46.9974803
  ],
  [
    84.6252608,
    46.9974656
  ],
  [
    84.6234798,
    46.9983729
  ],
  [
    84.6220207,
    46.9983876
  ],
  [
    84.61833,
    46.9972168
  ],
  [
    84.6161198,
    46.9962363
  ],
  [
    84.6130085,
    46.9966606
  ],
  [
    84.6120858,
    46.9966753
  ],
  [
    84.6121073,
    46.9969241
  ],
  [
    84.6132874,
    46.997407
  ],
  [
    84.6137595,
    46.9981241
  ],
  [
    84.6136308,
    46.9986511
  ],
  [
    84.6101975,
    46.998373
  ],
  [
    84.6050262,
    46.9981389
  ],
  [
    84.6021724,
    46.9978608
  ],
  [
    84.5983743,
    46.9985485
  ],
  [
    84.5949478,
    46.9987144
  ],
  [
    84.5937824,
    46.9991924
  ],
  [
    84.5922375,
    46.9991633
  ],
  [
    84.5886969,
    46.9986803
  ],
  [
    84.5851457,
    46.9991558
  ],
  [
    84.5823133,
    46.9991852
  ],
  [
    84.579438,
    46.9996389
  ],
  [
    84.5778394,
    46.9993388
  ],
  [
    84.5770883,
    46.9986802
  ],
  [
    84.5763159,
    46.9981535
  ],
  [
    84.5751786,
    46.9979339
  ],
  [
    84.5723355,
    46.9982193
  ],
  [
    84.5708763,
    46.9979705
  ],
  [
    84.5665204,
    46.9959729
  ],
  [
    84.5656192,
    46.9956362
  ],
  [
    84.5632589,
    46.9955849
  ],
  [
    84.5620358,
    46.9948752
  ],
  [
    84.5596218,
    46.9939238
  ],
  [
    84.5584202,
    46.9938654
  ],
  [
    84.5569396,
    46.9944434
  ],
  [
    84.5542467,
    46.9932873
  ],
  [
    84.5526588,
    46.9923213
  ],
  [
    84.552058,
    46.9919626
  ],
  [
    84.5523691,
    46.9912674
  ],
  [
    84.5523906,
    46.9907332
  ],
  [
    84.5500195,
    46.9882009
  ],
  [
    84.549526,
    46.987996
  ],
  [
    84.5477557,
    46.9880106
  ],
  [
    84.5470905,
    46.9877107
  ],
  [
    84.5464468,
    46.9866421
  ],
  [
    84.5430994,
    46.9848269
  ],
  [
    84.5425522,
    46.9838095
  ],
  [
    84.5422625,
    46.9836339
  ],
  [
    84.5411038,
    46.9838974
  ],
  [
    84.5403528,
    46.9836485
  ],
  [
    84.5396232,
    46.982902
  ],
  [
    84.5393228,
    46.9836339
  ],
  [
    84.5382499,
    46.9838975
  ],
  [
    84.5356321,
    46.9838682
  ],
  [
    84.5341623,
    46.983795
  ],
  [
    84.5322096,
    46.9834289
  ],
  [
    84.5312333,
    46.9829386
  ],
  [
    84.5268237,
    46.9824408
  ],
  [
    84.526062,
    46.9821041
  ],
  [
    84.5251286,
    46.9812917
  ],
  [
    84.5236909,
    46.9810795
  ],
  [
    84.5216954,
    46.9811672
  ],
  [
    84.5201397,
    46.980406
  ],
  [
    84.5192921,
    46.9805011
  ],
  [
    84.5181548,
    46.9812624
  ],
  [
    84.5175648,
    46.9813503
  ],
  [
    84.5140457,
    46.9801645
  ],
  [
    84.5131229,
    46.9794472
  ],
  [
    84.513638,
    46.9778368
  ],
  [
    84.5110308,
    46.9750331
  ],
  [
    84.510194,
    46.9749892
  ],
  [
    84.5065998,
    46.9756261
  ],
  [
    84.5049261,
    46.9770755
  ],
  [
    84.4989824,
    46.9798571
  ],
  [
    84.4979954,
    46.9811747
  ],
  [
    84.4959997,
    46.9819066
  ],
  [
    84.4947982,
    46.9845123
  ],
  [
    84.4923734,
    46.9861225
  ],
  [
    84.4891763,
    46.9861518
  ],
  [
    84.4885003,
    46.9867922
  ],
  [
    84.4879532,
    46.9878205
  ],
  [
    84.4864726,
    46.9899136
  ],
  [
    84.4860219,
    46.9916114
  ],
  [
    84.4843053,
    46.9925774
  ],
  [
    84.4828676,
    46.9924457
  ],
  [
    84.479606,
    46.9918163
  ],
  [
    84.4781792,
    46.9913187
  ],
  [
    84.4739305,
    46.9908942
  ],
  [
    84.4666241,
    46.9923579
  ],
  [
    84.4621824,
    46.9932507
  ],
  [
    84.4602512,
    46.9932653
  ],
  [
    84.4600795,
    46.9934848
  ],
  [
    84.4602512,
    46.9945167
  ],
  [
    84.4603156,
    46.9977291
  ],
  [
    84.4595431,
    47.000612
  ],
  [
    84.4587063,
    47.0028364
  ],
  [
    84.4572042,
    47.0061288
  ],
  [
    84.4585775,
    47.0082065
  ],
  [
    84.4580196,
    47.0086601
  ],
  [
    84.4568824,
    47.0086016
  ],
  [
    84.4546722,
    47.0072701
  ],
  [
    84.4539427,
    47.0072115
  ],
  [
    84.4528054,
    47.008426
  ],
  [
    84.4490288,
    47.0085869
  ],
  [
    84.4463252,
    47.0078407
  ],
  [
    84.4448446,
    47.0081187
  ],
  [
    84.4410251,
    47.0081334
  ],
  [
    84.4400166,
    47.0074164
  ],
  [
    84.4393514,
    47.0078261
  ],
  [
    84.436004,
    47.008982
  ],
  [
    84.4340514,
    47.0071823
  ],
  [
    84.4328068,
    47.007709
  ],
  [
    84.4298886,
    47.0076212
  ],
  [
    84.4282364,
    47.0077383
  ],
  [
    84.4270992,
    47.0070066
  ],
  [
    84.4266271,
    47.007109
  ],
  [
    84.4246744,
    47.0059239
  ],
  [
    84.4227217,
    47.0065238
  ],
  [
    84.4212197,
    47.0065385
  ],
  [
    84.4193958,
    47.0046216
  ],
  [
    84.4168424,
    47.0050751
  ],
  [
    84.4146322,
    47.0039558
  ],
  [
    84.4135165,
    47.0042703
  ],
  [
    84.4119394,
    47.0041459
  ],
  [
    84.4115424,
    47.0049141
  ],
  [
    84.4105339,
    47.0052873
  ],
  [
    84.408195,
    47.0046288
  ],
  [
    84.4071113,
    47.0047313
  ],
  [
    84.4064355,
    47.0041459
  ],
  [
    84.4046759,
    47.0042996
  ],
  [
    84.4034314,
    47.0039191
  ],
  [
    84.4009423,
    47.0056166
  ],
  [
    84.3999337,
    47.0040655
  ],
  [
    84.3993329,
    47.0029388
  ],
  [
    84.3980025,
    47.001095
  ],
  [
    84.3967581,
    47.0013583
  ],
  [
    84.3954491,
    47.0013875
  ],
  [
    84.3942904,
    47.002368
  ],
  [
    84.3918443,
    47.0027777
  ],
  [
    84.3910289,
    47.0006412
  ],
  [
    84.3875655,
    47.0001488
  ],
  [
    84.3863295,
    46.9996608
  ],
  [
    84.3864155,
    46.9991193
  ],
  [
    84.385321,
    46.9982998
  ],
  [
    84.3847418,
    46.9974363
  ],
  [
    84.3826604,
    46.9964997
  ],
  [
    84.3791628,
    46.9959874
  ],
  [
    84.3733477,
    46.9973777
  ],
  [
    84.3720817,
    46.9973631
  ],
  [
    84.3709874,
    46.9980948
  ],
  [
    84.3694209,
    46.9984901
  ],
  [
    84.3666743,
    46.997934
  ],
  [
    84.3651724,
    46.9979631
  ],
  [
    84.3642497,
    46.9983436
  ],
  [
    84.3635629,
    46.9991633
  ],
  [
    84.3619323,
    46.9994559
  ],
  [
    84.3602586,
    47.0000266
  ],
  [
    84.3581341,
    46.9993389
  ],
  [
    84.3572116,
    46.999529
  ],
  [
    84.3551516,
    47.0004949
  ],
  [
    84.3541859,
    47.0004218
  ],
  [
    84.3526196,
    46.9998071
  ],
  [
    84.3500447,
    46.99789
  ],
  [
    84.3485211,
    46.9978315
  ],
  [
    84.3468046,
    46.9994998
  ],
  [
    84.3451952,
    46.9996901
  ],
  [
    84.3412685,
    46.9992363
  ],
  [
    84.339702,
    46.9994706
  ],
  [
    84.3372129,
    47.0006998
  ],
  [
    84.3327926,
    47.0020169
  ],
  [
    84.330883,
    47.0028655
  ],
  [
    84.3294883,
    47.0028948
  ],
  [
    84.3282865,
    47.0017974
  ],
  [
    84.3261031,
    47.0004501
  ],
  [
    84.3246602,
    46.9997194
  ],
  [
    84.3229222,
    46.9969241
  ],
  [
    84.3222999,
    46.9970704
  ],
  [
    84.3222356,
    46.9979046
  ],
  [
    84.3219995,
    46.9982119
  ],
  [
    84.3200683,
    46.9976997
  ],
  [
    84.3198967,
    46.9968363
  ],
  [
    84.3191671,
    46.9966021
  ],
  [
    84.3170428,
    46.997729
  ],
  [
    84.3155621,
    46.9981974
  ],
  [
    84.3147253,
    46.9992803
  ],
  [
    84.313674,
    46.9998802
  ],
  [
    84.3125796,
    46.9987827
  ],
  [
    84.3111848,
    46.9970412
  ],
  [
    84.3100691,
    46.9969533
  ],
  [
    84.3085027,
    46.9973924
  ],
  [
    84.3074083,
    46.9981827
  ],
  [
    84.3056487,
    46.998212
  ],
  [
    84.3041467,
    46.9976119
  ],
  [
    84.3035029,
    46.9963534
  ],
  [
    84.302237,
    46.9961045
  ],
  [
    84.3011427,
    46.9959435
  ],
  [
    84.3002629,
    46.9955191
  ],
  [
    84.2991042,
    46.9954313
  ],
  [
    84.297924,
    46.9956947
  ],
  [
    84.2968726,
    46.9964411
  ],
  [
    84.2952417,
    46.9969681
  ],
  [
    84.2942548,
    46.9967924
  ],
  [
    84.2940402,
    46.9979192
  ],
  [
    84.2909502,
    46.9990169
  ],
  [
    84.2897916,
    47.0003485
  ],
  [
    84.2889118,
    47.0017534
  ],
  [
    84.2863582,
    47.002251
  ],
  [
    84.2845344,
    47.0010217
  ],
  [
    84.2845559,
    46.9999827
  ],
  [
    84.2836547,
    46.9988266
  ],
  [
    84.2807998,
    47.0002441
  ],
  [
    84.2778181,
    47.001073
  ],
  [
    84.274578,
    47.0016657
  ],
  [
    84.2728185,
    47.0012558
  ],
  [
    84.2722392,
    47.0007071
  ],
  [
    84.2714453,
    47.0008388
  ],
  [
    84.2703937,
    47.0011535
  ],
  [
    84.2689991,
    47.000729
  ],
  [
    84.2682695,
    47.0007071
  ],
  [
    84.2671752,
    47.0009631
  ],
  [
    84.2664884,
    47.0020022
  ],
  [
    84.2636775,
    47.0017242
  ],
  [
    84.2605448,
    47.0029241
  ],
  [
    84.2585492,
    47.0029972
  ],
  [
    84.2562531,
    47.0010218
  ],
  [
    84.2530346,
    46.9997778
  ],
  [
    84.2524123,
    46.9989875
  ],
  [
    84.252026,
    46.9989291
  ],
  [
    84.2520153,
    46.9997339
  ],
  [
    84.2500627,
    47.000129
  ],
  [
    84.2490648,
    46.9997925
  ],
  [
    84.2473911,
    46.9987974
  ],
  [
    84.2425846,
    46.9992364
  ],
  [
    84.238336,
    47.0003779
  ],
  [
    84.2361258,
    47.0005828
  ],
  [
    84.2336797,
    47.0014314
  ],
  [
    84.2325636,
    47.0011836
  ],
  [
    84.2320286,
    47.0008705
  ],
  [
    84.231851,
    47.0008187
  ],
  [
    84.2313047,
    47.0006882
  ],
  [
    84.2310498,
    47.0006104
  ],
  [
    84.230796,
    47.0005874
  ],
  [
    84.2305943,
    47.0006072
  ],
  [
    84.2302868,
    47.0006228
  ],
  [
    84.2300334,
    47.0006747
  ],
  [
    84.2299215,
    47.0007434
  ],
  [
    84.2297922,
    47.0007425
  ],
  [
    84.2295918,
    47.000648
  ],
  [
    84.2292622,
    47.0004413
  ],
  [
    84.2289386,
    47.0001624
  ],
  [
    84.2286324,
    46.9999083
  ],
  [
    84.2284269,
    46.9997953
  ],
  [
    84.2282721,
    46.9996453
  ],
  [
    84.2278007,
    46.9996536
  ],
  [
    84.227598,
    46.9996314
  ],
  [
    84.2273619,
    46.9994669
  ],
  [
    84.2271763,
    46.9987674
  ],
  [
    84.2269267,
    46.9982818
  ],
  [
    84.2264559,
    46.9976855
  ],
  [
    84.2262044,
    46.9972031
  ],
  [
    84.2258084,
    46.9972057
  ],
  [
    84.2252679,
    46.9971937
  ],
  [
    84.2249981,
    46.9971921
  ],
  [
    84.224808,
    46.9972344
  ],
  [
    84.2247428,
    46.9973269
  ],
  [
    84.2246268,
    46.9975071
  ],
  [
    84.2242114,
    46.9978126
  ],
  [
    84.2239627,
    46.9979004
  ],
  [
    84.2236589,
    46.9978686
  ],
  [
    84.2232719,
    46.997716
  ],
  [
    84.2229641,
    46.9976064
  ],
  [
    84.2228548,
    46.997623
  ],
  [
    84.2226439,
    46.9976835
  ],
  [
    84.2223588,
    46.9978902
  ],
  [
    84.2219284,
    46.9980285
  ],
  [
    84.2215586,
    46.9981318
  ],
  [
    84.2213519,
    46.9982862
  ],
  [
    84.2206615,
    46.9985537
  ],
  [
    84.2200508,
    46.9984742
  ],
  [
    84.2194397,
    46.9985324
  ],
  [
    84.2191104,
    46.9986704
  ],
  [
    84.2187253,
    46.9987841
  ],
  [
    84.2182429,
    46.9989892
  ],
  [
    84.2179345,
    46.9990537
  ],
  [
    84.2170969,
    46.9993354
  ],
  [
    84.2166324,
    46.9995228
  ],
  [
    84.2164525,
    46.9997667
  ],
  [
    84.2162747,
    46.9999086
  ],
  [
    84.2158784,
    47.0000528
  ],
  [
    84.2156207,
    47.0003132
  ],
  [
    84.2150529,
    47.0004104
  ],
  [
    84.2146364,
    47.0006939
  ],
  [
    84.2142274,
    47.0008197
  ],
  [
    84.2136852,
    47.0008531
  ],
  [
    84.2127215,
    47.0007738
  ],
  [
    84.2118537,
    47.0004999
  ],
  [
    84.2113462,
    47.0004204
  ],
  [
    84.2108815,
    47.0005103
  ],
  [
    84.2105507,
    47.0008364
  ],
  [
    84.2103487,
    47.0011507
  ],
  [
    84.2099488,
    47.0014136
  ],
  [
    84.208959,
    47.0013853
  ],
  [
    84.2085789,
    47.0012303
  ],
  [
    84.2079397,
    47.0009586
  ],
  [
    84.2077198,
    47.0009268
  ],
  [
    84.2074975,
    47.0007415
  ],
  [
    84.2070365,
    47.000642
  ],
  [
    84.2068589,
    47.0003293
  ],
  [
    84.2066832,
    47.0001978
  ],
  [
    84.2065662,
    47.0001065
  ],
  [
    84.2062854,
    47.0002087
  ],
  [
    84.2060455,
    47.0003958
  ],
  [
    84.2058029,
    47.0005941
  ],
  [
    84.2053424,
    47.0008134
  ],
  [
    84.2050503,
    47.000794
  ],
  [
    84.2048034,
    47.0007612
  ],
  [
    84.2044545,
    47.0007906
  ],
  [
    84.2042787,
    47.0009034
  ],
  [
    84.2041986,
    47.0009516
  ],
  [
    84.2040978,
    47.0009985
  ],
  [
    84.2039893,
    47.0010469
  ],
  [
    84.2033749,
    47.0013453
  ],
  [
    84.2028999,
    47.0016968
  ],
  [
    84.202662,
    47.0017421
  ],
  [
    84.2021459,
    47.0017772
  ],
  [
    84.2015635,
    47.0020427
  ],
  [
    84.2011445,
    47.0022784
  ],
  [
    84.2007444,
    47.0020532
  ],
  [
    84.1999142,
    47.0015391
  ],
  [
    84.1996146,
    47.0014045
  ],
  [
    84.1990163,
    47.001301
  ],
  [
    84.1981861,
    47.0013018
  ],
  [
    84.1978649,
    47.0012244
  ],
  [
    84.1974418,
    47.0007969
  ],
  [
    84.1973069,
    47.0005713
  ],
  [
    84.1972609,
    47.0003573
  ],
  [
    84.1972955,
    47.0002276
  ],
  [
    84.1972539,
    47.0000359
  ],
  [
    84.1970484,
    46.9998806
  ],
  [
    84.1969407,
    46.9996341
  ],
  [
    84.1968484,
    46.9995294
  ],
  [
    84.1961563,
    46.9992024
  ],
  [
    84.195926,
    46.9990448
  ],
  [
    84.1958796,
    46.9990208
  ],
  [
    84.1958142,
    46.9990106
  ],
  [
    84.1957392,
    46.9989947
  ],
  [
    84.1956378,
    46.9989668
  ],
  [
    84.1952265,
    46.9989678
  ],
  [
    84.1949114,
    46.9990078
  ],
  [
    84.19472,
    46.999014
  ],
  [
    84.1944007,
    46.9989922
  ],
  [
    84.1940791,
    46.9989672
  ],
  [
    84.1935294,
    46.9990106
  ],
  [
    84.1932152,
    46.999024
  ],
  [
    84.1928678,
    46.9988244
  ],
  [
    84.1925921,
    46.9986576
  ],
  [
    84.1923965,
    46.9986485
  ],
  [
    84.1919771,
    46.9987382
  ],
  [
    84.19159,
    46.9988025
  ],
  [
    84.1912853,
    46.9988646
  ],
  [
    84.1908162,
    46.9991055
  ],
  [
    84.1903741,
    46.9992709
  ],
  [
    84.189643,
    46.9994942
  ],
  [
    84.1892501,
    46.9996471
  ],
  [
    84.1889941,
    46.9998462
  ],
  [
    84.1884767,
    46.9999143
  ],
  [
    84.1882279,
    46.9998642
  ],
  [
    84.1880297,
    46.9997764
  ],
  [
    84.1878458,
    46.999574
  ],
  [
    84.1876335,
    46.9993181
  ],
  [
    84.1874751,
    46.9988991
  ],
  [
    84.1874139,
    46.9987359
  ],
  [
    84.1873642,
    46.9986096
  ],
  [
    84.1872948,
    46.9984672
  ],
  [
    84.1871868,
    46.9982742
  ],
  [
    84.1871131,
    46.9981639
  ],
  [
    84.1869672,
    46.9976955
  ],
  [
    84.1869505,
    46.9973698
  ],
  [
    84.1866646,
    46.9973833
  ],
  [
    84.1864808,
    46.9974624
  ],
  [
    84.1863497,
    46.9974557
  ],
  [
    84.1859876,
    46.9975024
  ],
  [
    84.1858002,
    46.9974459
  ],
  [
    84.1855191,
    46.9973743
  ],
  [
    84.1848741,
    46.9973695
  ],
  [
    84.1842538,
    46.9974222
  ],
  [
    84.1838897,
    46.9976185
  ],
  [
    84.1836878,
    46.9976568
  ],
  [
    84.1832353,
    46.9976424
  ],
  [
    84.1829212,
    46.9976256
  ],
  [
    84.1825117,
    46.9976066
  ],
  [
    84.182188,
    46.9976617
  ],
  [
    84.181904,
    46.9977117
  ],
  [
    84.1817628,
    46.9977694
  ],
  [
    84.1811871,
    46.9978174
  ],
  [
    84.1808692,
    46.9979174
  ],
  [
    84.1806733,
    46.9979346
  ],
  [
    84.1804581,
    46.9978683
  ],
  [
    84.1801636,
    46.9976874
  ],
  [
    84.180137,
    46.9976817
  ],
  [
    84.1801103,
    46.9976573
  ],
  [
    84.180087,
    46.9976306
  ],
  [
    84.1800578,
    46.9975907
  ],
  [
    84.179997,
    46.9975554
  ],
  [
    84.1794493,
    46.9973563
  ],
  [
    84.1789459,
    46.9973222
  ],
  [
    84.1780135,
    46.9975329
  ],
  [
    84.1778054,
    46.9974572
  ],
  [
    84.1774362,
    46.997093
  ],
  [
    84.1774421,
    46.9968987
  ],
  [
    84.1777564,
    46.996405
  ],
  [
    84.1776677,
    46.995885
  ],
  [
    84.1773277,
    46.9955053
  ],
  [
    84.1773809,
    46.9951495
  ],
  [
    84.1774186,
    46.9947418
  ],
  [
    84.177375,
    46.9942456
  ],
  [
    84.1772793,
    46.9934652
  ],
  [
    84.177402,
    46.9931882
  ],
  [
    84.1767698,
    46.9928252
  ],
  [
    84.1759853,
    46.9926167
  ],
  [
    84.1755782,
    46.9927496
  ],
  [
    84.1754065,
    46.9929795
  ],
  [
    84.1753513,
    46.9932087
  ],
  [
    84.1751209,
    46.9934022
  ],
  [
    84.1747586,
    46.9936252
  ],
  [
    84.1742845,
    46.9937846
  ],
  [
    84.1737119,
    46.9938799
  ],
  [
    84.1733617,
    46.9939474
  ],
  [
    84.1726924,
    46.9941857
  ],
  [
    84.1720041,
    46.9941605
  ],
  [
    84.1707544,
    46.9945636
  ],
  [
    84.1701475,
    46.9949035
  ],
  [
    84.1696832,
    46.9950621
  ],
  [
    84.1694937,
    46.9948084
  ],
  [
    84.1690432,
    46.9949039
  ],
  [
    84.1684787,
    46.9947503
  ],
  [
    84.1680999,
    46.9945926
  ],
  [
    84.1676631,
    46.9944724
  ],
  [
    84.167352,
    46.9941926
  ],
  [
    84.1673263,
    46.9938543
  ],
  [
    84.1668527,
    46.9938192
  ],
  [
    84.1664166,
    46.9939056
  ],
  [
    84.1660196,
    46.9942571
  ],
  [
    84.1657154,
    46.9942883
  ],
  [
    84.1654391,
    46.9938869
  ],
  [
    84.1653942,
    46.9934269
  ],
  [
    84.1652305,
    46.9930591
  ],
  [
    84.1651419,
    46.9925227
  ],
  [
    84.1653091,
    46.9921292
  ],
  [
    84.1653226,
    46.9918776
  ],
  [
    84.165351,
    46.9916618
  ],
  [
    84.1648132,
    46.9909495
  ],
  [
    84.1646626,
    46.9907725
  ],
  [
    84.1646776,
    46.9905481
  ],
  [
    84.1642228,
    46.9902705
  ],
  [
    84.1640232,
    46.9901224
  ],
  [
    84.1638511,
    46.9898945
  ],
  [
    84.1635548,
    46.989709
  ],
  [
    84.1633468,
    46.989671
  ],
  [
    84.1620011,
    46.9896609
  ],
  [
    84.1610305,
    46.9890415
  ],
  [
    84.1604315,
    46.9888953
  ],
  [
    84.1599736,
    46.9890159
  ],
  [
    84.1598503,
    46.9889804
  ],
  [
    84.159607,
    46.9888169
  ],
  [
    84.1592672,
    46.9882778
  ],
  [
    84.159471,
    46.9878761
  ],
  [
    84.1593494,
    46.9874908
  ],
  [
    84.159518,
    46.9873855
  ],
  [
    84.1597943,
    46.9869585
  ],
  [
    84.1597993,
    46.9867974
  ],
  [
    84.1592756,
    46.9863777
  ],
  [
    84.1589498,
    46.9860744
  ],
  [
    84.1590979,
    46.9854948
  ],
  [
    84.1587485,
    46.9849006
  ],
  [
    84.1584413,
    46.9846101
  ],
  [
    84.158286,
    46.9841836
  ],
  [
    84.1584085,
    46.9836094
  ],
  [
    84.1585936,
    46.9833005
  ],
  [
    84.1586149,
    46.9831248
  ],
  [
    84.1585991,
    46.9830567
  ],
  [
    84.1581481,
    46.9827129
  ],
  [
    84.1582109,
    46.982407
  ],
  [
    84.1581631,
    46.9819201
  ],
  [
    84.1582853,
    46.9813884
  ],
  [
    84.1582345,
    46.9810758
  ],
  [
    84.1583456,
    46.980858
  ],
  [
    84.1579663,
    46.9808052
  ],
  [
    84.1576766,
    46.9809587
  ],
  [
    84.1571938,
    46.980966
  ],
  [
    84.1569095,
    46.9807684
  ],
  [
    84.1559581,
    46.9808389
  ],
  [
    84.1555109,
    46.98093
  ],
  [
    84.1553127,
    46.9811185
  ],
  [
    84.1545216,
    46.9809775
  ],
  [
    84.1537757,
    46.9807431
  ],
  [
    84.1531954,
    46.9805682
  ],
  [
    84.1526736,
    46.9804628
  ],
  [
    84.1523949,
    46.9804526
  ],
  [
    84.1521581,
    46.9803763
  ],
  [
    84.1518768,
    46.9799472
  ],
  [
    84.1516953,
    46.9793227
  ],
  [
    84.1516738,
    46.9789311
  ],
  [
    84.1515449,
    46.9785232
  ],
  [
    84.1514144,
    46.9784008
  ],
  [
    84.1511845,
    46.9783045
  ],
  [
    84.1508551,
    46.9783209
  ],
  [
    84.1506295,
    46.9783711
  ],
  [
    84.1501306,
    46.978428
  ],
  [
    84.1498533,
    46.9785929
  ],
  [
    84.1495912,
    46.978679
  ],
  [
    84.1488848,
    46.9787304
  ],
  [
    84.1486804,
    46.978683
  ],
  [
    84.1483253,
    46.9783463
  ],
  [
    84.1480438,
    46.9780185
  ],
  [
    84.1476618,
    46.9777898
  ],
  [
    84.1471753,
    46.9774474
  ],
  [
    84.147019,
    46.9768905
  ],
  [
    84.146855,
    46.9766248
  ],
  [
    84.1466409,
    46.9763898
  ],
  [
    84.1464326,
    46.9763126
  ],
  [
    84.1457063,
    46.9762159
  ],
  [
    84.1445031,
    46.9758564
  ],
  [
    84.1440394,
    46.9751994
  ],
  [
    84.1438209,
    46.9750785
  ],
  [
    84.1436115,
    46.9748183
  ],
  [
    84.1434114,
    46.9746972
  ],
  [
    84.1429482,
    46.9745141
  ],
  [
    84.1427841,
    46.974416
  ],
  [
    84.142595,
    46.9740893
  ],
  [
    84.1420628,
    46.9736205
  ],
  [
    84.1413587,
    46.9734335
  ],
  [
    84.1398114,
    46.9736329
  ],
  [
    84.1389292,
    46.9737236
  ],
  [
    84.1386529,
    46.97369
  ],
  [
    84.1381902,
    46.9733447
  ],
  [
    84.137405,
    46.9730286
  ],
  [
    84.1367774,
    46.9729538
  ],
  [
    84.1363672,
    46.9727179
  ],
  [
    84.135666,
    46.9724372
  ],
  [
    84.1350738,
    46.9722736
  ],
  [
    84.1347118,
    46.9723505
  ],
  [
    84.1339854,
    46.9722325
  ],
  [
    84.1334745,
    46.9720006
  ],
  [
    84.1330696,
    46.9718005
  ],
  [
    84.132754,
    46.9717244
  ],
  [
    84.1322561,
    46.9716604
  ],
  [
    84.1315952,
    46.9716608
  ],
  [
    84.1313558,
    46.9716937
  ],
  [
    84.1312055,
    46.9717052
  ],
  [
    84.1308938,
    46.9716231
  ],
  [
    84.1305859,
    46.9714881
  ],
  [
    84.1304267,
    46.9713496
  ],
  [
    84.1302657,
    46.9711557
  ],
  [
    84.1299594,
    46.9709903
  ],
  [
    84.1297541,
    46.9708046
  ],
  [
    84.1291902,
    46.969976
  ],
  [
    84.1290033,
    46.9696591
  ],
  [
    84.1289243,
    46.9695207
  ],
  [
    84.1286826,
    46.9692281
  ],
  [
    84.1285789,
    46.969149
  ],
  [
    84.1283815,
    46.9690955
  ],
  [
    84.1282017,
    46.9689988
  ],
  [
    84.1280689,
    46.9688849
  ],
  [
    84.1279271,
    46.9686628
  ],
  [
    84.1278662,
    46.9685282
  ],
  [
    84.1274881,
    46.9681474
  ],
  [
    84.1273799,
    46.9679719
  ],
  [
    84.1267634,
    46.9676047
  ],
  [
    84.1264104,
    46.9673945
  ],
  [
    84.1254227,
    46.9671867
  ],
  [
    84.1251146,
    46.9670554
  ],
  [
    84.1246187,
    46.9670148
  ],
  [
    84.123148,
    46.9677594
  ],
  [
    84.1226547,
    46.9681344
  ],
  [
    84.1224426,
    46.9682548
  ],
  [
    84.1223122,
    46.9684373
  ],
  [
    84.122151,
    46.9685423
  ],
  [
    84.1217662,
    46.9685803
  ],
  [
    84.1215389,
    46.9686276
  ],
  [
    84.1209613,
    46.9689124
  ],
  [
    84.1205038,
    46.9690724
  ],
  [
    84.120251,
    46.9690992
  ],
  [
    84.1199868,
    46.9690866
  ],
  [
    84.1198319,
    46.969025
  ],
  [
    84.1194122,
    46.9686775
  ],
  [
    84.1192573,
    46.9684956
  ],
  [
    84.1192039,
    46.96828
  ],
  [
    84.119093,
    46.9681606
  ],
  [
    84.1186928,
    46.9679153
  ],
  [
    84.1184784,
    46.9677127
  ],
  [
    84.1167797,
    46.9670588
  ],
  [
    84.1152485,
    46.9669417
  ],
  [
    84.1137596,
    46.9670501
  ],
  [
    84.1117427,
    46.9673572
  ],
  [
    84.1115786,
    46.9674606
  ],
  [
    84.1107516,
    46.967745
  ],
  [
    84.1102101,
    46.9682672
  ],
  [
    84.10937,
    46.968434
  ],
  [
    84.1089305,
    46.9684014
  ],
  [
    84.1081565,
    46.9688738
  ],
  [
    84.1074743,
    46.9691621
  ],
  [
    84.1057457,
    46.9692455
  ],
  [
    84.1056062,
    46.9692389
  ],
  [
    84.105536,
    46.9692189
  ],
  [
    84.1046008,
    46.9687091
  ],
  [
    84.1043003,
    46.9686613
  ],
  [
    84.1037456,
    46.9686238
  ],
  [
    84.1034493,
    46.9686253
  ],
  [
    84.102901,
    46.968707
  ],
  [
    84.1027276,
    46.9688299
  ],
  [
    84.1025344,
    46.9690753
  ],
  [
    84.102428,
    46.9691524
  ],
  [
    84.1021109,
    46.9691922
  ],
  [
    84.1018457,
    46.9692807
  ],
  [
    84.1016937,
    46.9693002
  ],
  [
    84.1014564,
    46.9693035
  ],
  [
    84.1013151,
    46.9693053
  ],
  [
    84.101106,
    46.9693697
  ],
  [
    84.100207,
    46.9692531
  ],
  [
    84.0990747,
    46.9691549
  ],
  [
    84.0982542,
    46.9690433
  ],
  [
    84.0972365,
    46.9687483
  ],
  [
    84.0964134,
    46.9686997
  ],
  [
    84.0962441,
    46.9687098
  ],
  [
    84.0958326,
    46.9688219
  ],
  [
    84.0952867,
    46.9688628
  ],
  [
    84.0944639,
    46.968677
  ],
  [
    84.0942481,
    46.9686541
  ],
  [
    84.0940033,
    46.9685738
  ],
  [
    84.0937386,
    46.968599
  ],
  [
    84.0933434,
    46.9685917
  ],
  [
    84.0932081,
    46.9686099
  ],
  [
    84.0929095,
    46.9686032
  ],
  [
    84.0925522,
    46.9685652
  ],
  [
    84.0916342,
    46.9684285
  ],
  [
    84.091362,
    46.9684254
  ],
  [
    84.0912109,
    46.9684432
  ],
  [
    84.0909253,
    46.9685544
  ],
  [
    84.0907588,
    46.968593
  ],
  [
    84.0903584,
    46.9688293
  ],
  [
    84.0900493,
    46.9687695
  ],
  [
    84.0899139,
    46.9687645
  ],
  [
    84.0898422,
    46.9687827
  ],
  [
    84.089766,
    46.9688293
  ],
  [
    84.0896605,
    46.9689519
  ],
  [
    84.0895666,
    46.968985
  ],
  [
    84.0892613,
    46.9689657
  ],
  [
    84.0889394,
    46.9689882
  ],
  [
    84.0887324,
    46.9690534
  ],
  [
    84.0886609,
    46.9690624
  ],
  [
    84.0885144,
    46.9690476
  ],
  [
    84.0882885,
    46.9689394
  ],
  [
    84.0881197,
    46.9688388
  ],
  [
    84.0880776,
    46.9688307
  ],
  [
    84.0880255,
    46.9688275
  ],
  [
    84.0877298,
    46.9688301
  ],
  [
    84.087433,
    46.9687816
  ],
  [
    84.0870918,
    46.9688041
  ],
  [
    84.0867535,
    46.9687897
  ],
  [
    84.0864745,
    46.9688753
  ],
  [
    84.0856075,
    46.9689274
  ],
  [
    84.0854034,
    46.9689282
  ],
  [
    84.0844186,
    46.9688525
  ],
  [
    84.0841455,
    46.9688688
  ],
  [
    84.083813,
    46.9688278
  ],
  [
    84.0836596,
    46.9689098
  ],
  [
    84.0834925,
    46.9691316
  ],
  [
    84.0832536,
    46.9691959
  ],
  [
    84.0830056,
    46.9691591
  ],
  [
    84.0820168,
    46.9689641
  ],
  [
    84.0810083,
    46.9687591
  ],
  [
    84.0801929,
    46.9686127
  ],
  [
    84.0794097,
    46.9686566
  ],
  [
    84.0787982,
    46.9688177
  ],
  [
    84.0779828,
    46.969213
  ],
  [
    84.0768348,
    46.9699598
  ],
  [
    84.0755049,
    46.9702648
  ],
  [
    84.0734244,
    46.9709732
  ],
  [
    84.0705822,
    46.971418
  ],
  [
    84.068005,
    46.9717827
  ],
  [
    84.0662991,
    46.971995
  ],
  [
    84.0643464,
    46.9719218
  ],
  [
    84.0618681,
    46.9723465
  ],
  [
    84.0603446,
    46.9726503
  ],
  [
    84.0597491,
    46.9727564
  ],
  [
    84.058628,
    46.9709481
  ],
  [
    84.057877,
    46.96985
  ],
  [
    84.0564715,
    46.9687005
  ],
  [
    84.0550553,
    46.9687005
  ],
  [
    84.0529534,
    46.9687298
  ],
  [
    84.0507779,
    46.969089
  ],
  [
    84.0476541,
    46.9702495
  ],
  [
    84.0459786,
    46.9702548
  ],
  [
    84.0447025,
    46.9709132
  ],
  [
    84.0437422,
    46.9719691
  ],
  [
    84.0431205,
    46.9730736
  ],
  [
    84.0420734,
    46.9735837
  ],
  [
    84.0413101,
    46.9742998
  ],
  [
    84.0406601,
    46.9744094
  ],
  [
    84.0395309,
    46.9749438
  ],
  [
    84.0369986,
    46.9754943
  ],
  [
    84.0353413,
    46.9759823
  ],
  [
    84.0343057,
    46.9765192
  ],
  [
    84.0338227,
    46.977147
  ],
  [
    84.0326082,
    46.9778911
  ],
  [
    84.0289529,
    46.9780092
  ],
  [
    84.025572,
    46.9788731
  ],
  [
    84.0251792,
    46.9795272
  ],
  [
    84.0242957,
    46.9805891
  ],
  [
    84.0231155,
    46.981321
  ],
  [
    84.0217347,
    46.9822963
  ],
  [
    84.0180515,
    46.9843659
  ],
  [
    84.0159487,
    46.9847611
  ],
  [
    84.0148329,
    46.9843659
  ],
  [
    84.01376,
    46.9835901
  ],
  [
    84.0120219,
    46.9828874
  ],
  [
    84.0111304,
    46.982884
  ],
  [
    84.0102775,
    46.9831715
  ],
  [
    84.0099474,
    46.9839193
  ],
  [
    84.0101765,
    46.9853906
  ],
  [
    84.0098118,
    46.9866787
  ],
  [
    84.008932,
    46.9880254
  ],
  [
    84.0075462,
    46.9892662
  ],
  [
    84.0052754,
    46.9897516
  ],
  [
    84.0033124,
    46.990485
  ],
  [
    83.9980644,
    46.9914233
  ],
  [
    83.9954652,
    46.9920577
  ],
  [
    83.9930692,
    46.9930167
  ],
  [
    83.9911821,
    46.9938898
  ],
  [
    83.9898928,
    46.9941742
  ],
  [
    83.9887504,
    46.9942651
  ],
  [
    83.9879516,
    46.994398
  ],
  [
    83.9873137,
    46.9953264
  ],
  [
    83.9865235,
    46.9956643
  ],
  [
    83.9856482,
    46.9956887
  ],
  [
    83.9847167,
    46.9952583
  ],
  [
    83.9839859,
    46.994303
  ],
  [
    83.9832246,
    46.9931644
  ],
  [
    83.9822273,
    46.9924287
  ],
  [
    83.9788582,
    46.9913575
  ],
  [
    83.9776465,
    46.9908358
  ],
  [
    83.9760269,
    46.9897879
  ],
  [
    83.973955,
    46.9881699
  ],
  [
    83.9725315,
    46.9873166
  ],
  [
    83.9722562,
    46.9867317
  ],
  [
    83.9714357,
    46.9858655
  ],
  [
    83.9684233,
    46.9846192
  ],
  [
    83.9674574,
    46.984007
  ],
  [
    83.9650864,
    46.9839837
  ],
  [
    83.9631842,
    46.9835901
  ],
  [
    83.9607246,
    46.9824428
  ],
  [
    83.9592951,
    46.9834217
  ],
  [
    83.9588661,
    46.9839803
  ],
  [
    83.9587342,
    46.9847263
  ],
  [
    83.9579093,
    46.9871008
  ],
  [
    83.9575172,
    46.9884457
  ],
  [
    83.956551,
    46.989376
  ],
  [
    83.9552102,
    46.9902117
  ],
  [
    83.9538469,
    46.990767
  ],
  [
    83.9525413,
    46.9910912
  ],
  [
    83.951514,
    46.991203
  ],
  [
    83.9497697,
    46.9912358
  ],
  [
    83.9472097,
    46.9909876
  ],
  [
    83.9457036,
    46.9907005
  ],
  [
    83.9444438,
    46.9901767
  ],
  [
    83.9406322,
    46.988406
  ],
  [
    83.9385723,
    46.9868397
  ],
  [
    83.9369412,
    46.9858449
  ],
  [
    83.9357985,
    46.9851954
  ],
  [
    83.9346331,
    46.9849145
  ],
  [
    83.9336838,
    46.984476
  ],
  [
    83.9328562,
    46.984347
  ],
  [
    83.9321538,
    46.9844879
  ],
  [
    83.9316681,
    46.9848609
  ],
  [
    83.9313506,
    46.9853567
  ],
  [
    83.9306773,
    46.9856634
  ],
  [
    83.9293227,
    46.9855825
  ],
  [
    83.9270392,
    46.9849912
  ],
  [
    83.9262246,
    46.9847255
  ],
  [
    83.9228692,
    46.9807705
  ],
  [
    83.9221644,
    46.979689
  ],
  [
    83.9208697,
    46.9768266
  ],
  [
    83.9193122,
    46.975557
  ],
  [
    83.9172649,
    46.9728881
  ],
  [
    83.916428,
    46.9728003
  ],
  [
    83.9156126,
    46.9744548
  ],
  [
    83.9138101,
    46.974894
  ],
  [
    83.9111854,
    46.9742778
  ],
  [
    83.9069008,
    46.9742791
  ],
  [
    83.9044117,
    46.9745134
  ],
  [
    83.9031886,
    46.9748062
  ],
  [
    83.9020728,
    46.9750697
  ],
  [
    83.903489,
    46.9762556
  ],
  [
    83.9048408,
    46.9775879
  ],
  [
    83.9049596,
    46.9797841
  ],
  [
    83.9047917,
    46.9808269
  ],
  [
    83.9040731,
    46.9816352
  ],
  [
    83.9025204,
    46.9827497
  ],
  [
    83.9011403,
    46.9834148
  ],
  [
    83.8996695,
    46.984
  ],
  [
    83.8981375,
    46.983991
  ],
  [
    83.8969015,
    46.9851564
  ],
  [
    83.8963865,
    46.9862542
  ],
  [
    83.8950347,
    46.9866495
  ],
  [
    83.8941871,
    46.987052
  ],
  [
    83.8932147,
    46.9877265
  ],
  [
    83.8919521,
    46.9883669
  ],
  [
    83.8911752,
    46.9884619
  ],
  [
    83.8903885,
    46.9883495
  ],
  [
    83.8895555,
    46.9879466
  ],
  [
    83.8888429,
    46.9877247
  ],
  [
    83.8877097,
    46.9867806
  ],
  [
    83.8873109,
    46.9865825
  ],
  [
    83.8869696,
    46.9863271
  ],
  [
    83.8865863,
    46.9863079
  ],
  [
    83.8862757,
    46.9861473
  ],
  [
    83.8857635,
    46.9853531
  ],
  [
    83.8827227,
    46.9860007
  ],
  [
    83.8793663,
    46.9865643
  ],
  [
    83.8783033,
    46.9865787
  ],
  [
    83.8766454,
    46.9862418
  ],
  [
    83.8755032,
    46.9861943
  ],
  [
    83.87485,
    46.9869029
  ],
  [
    83.8743896,
    46.9871846
  ],
  [
    83.8737315,
    46.9873207
  ],
  [
    83.8732787,
    46.9873016
  ],
  [
    83.8719738,
    46.9868178
  ],
  [
    83.8708286,
    46.9869775
  ],
  [
    83.8709702,
    46.9878368
  ],
  [
    83.8708372,
    46.9882424
  ],
  [
    83.870295,
    46.9889542
  ],
  [
    83.8700536,
    46.9899469
  ],
  [
    83.8702041,
    46.9911857
  ],
  [
    83.8704224,
    46.9917798
  ],
  [
    83.8709847,
    46.9926472
  ],
  [
    83.8716759,
    46.993037
  ],
  [
    83.8718826,
    46.9932478
  ],
  [
    83.8714351,
    46.9942859
  ],
  [
    83.8708301,
    46.9950365
  ],
  [
    83.869727,
    46.995355
  ],
  [
    83.8685479,
    46.995555
  ],
  [
    83.8677404,
    46.9958249
  ],
  [
    83.8652254,
    46.9963362
  ],
  [
    83.8641697,
    46.9964016
  ],
  [
    83.8630087,
    46.9961975
  ],
  [
    83.8623749,
    46.9961554
  ],
  [
    83.8613071,
    46.9965769
  ],
  [
    83.8592352,
    46.9979424
  ],
  [
    83.8568715,
    46.9972238
  ],
  [
    83.8559917,
    46.9970003
  ],
  [
    83.8542179,
    46.9963868
  ],
  [
    83.8521589,
    46.9953371
  ],
  [
    83.8510397,
    46.995125
  ],
  [
    83.849387,
    46.9953684
  ],
  [
    83.8484096,
    46.9955752
  ],
  [
    83.8474743,
    46.99543
  ],
  [
    83.8469676,
    46.9954925
  ],
  [
    83.8463844,
    46.9954117
  ],
  [
    83.8462945,
    46.9957223
  ],
  [
    83.8459119,
    46.9958432
  ],
  [
    83.8455811,
    46.9961062
  ],
  [
    83.8429607,
    46.9962926
  ],
  [
    83.8420874,
    46.9965339
  ],
  [
    83.840539,
    46.9967256
  ],
  [
    83.8400054,
    46.9967782
  ],
  [
    83.8392052,
    46.9967488
  ],
  [
    83.838396,
    46.9964484
  ],
  [
    83.8375592,
    46.9957339
  ],
  [
    83.836683,
    46.9952096
  ],
  [
    83.836306,
    46.994816
  ],
  [
    83.8356901,
    46.9946104
  ],
  [
    83.8353888,
    46.9945833
  ],
  [
    83.8349936,
    46.9947106
  ],
  [
    83.834783,
    46.9950009
  ],
  [
    83.8346698,
    46.995709
  ],
  [
    83.8345217,
    46.9962011
  ],
  [
    83.8344701,
    46.9968583
  ],
  [
    83.8345127,
    46.9972505
  ],
  [
    83.8346387,
    46.9976044
  ],
  [
    83.8348985,
    46.9980098
  ],
  [
    83.8353033,
    46.9982681
  ],
  [
    83.835098,
    46.999089
  ],
  [
    83.8353388,
    46.9993818
  ],
  [
    83.8352905,
    47.0000724
  ],
  [
    83.8353524,
    47.0006803
  ],
  [
    83.8357313,
    47.0013348
  ],
  [
    83.8363859,
    47.0018618
  ],
  [
    83.8367106,
    47.0020266
  ],
  [
    83.8370772,
    47.0024065
  ],
  [
    83.8372799,
    47.0029636
  ],
  [
    83.8372802,
    47.0033519
  ],
  [
    83.8370137,
    47.0037101
  ],
  [
    83.8361605,
    47.0041796
  ],
  [
    83.8357785,
    47.0045655
  ],
  [
    83.8349891,
    47.0052249
  ],
  [
    83.8343083,
    47.0066192
  ],
  [
    83.8336095,
    47.0073666
  ],
  [
    83.8320882,
    47.0085674
  ],
  [
    83.8299917,
    47.009626
  ],
  [
    83.8291174,
    47.0103767
  ],
  [
    83.8277606,
    47.0107341
  ],
  [
    83.8265407,
    47.0112479
  ],
  [
    83.8249814,
    47.0116059
  ],
  [
    83.8220046,
    47.0117423
  ],
  [
    83.821235,
    47.0122111
  ],
  [
    83.8204592,
    47.0123133
  ],
  [
    83.8190433,
    47.0124061
  ],
  [
    83.8173789,
    47.0129769
  ],
  [
    83.8148569,
    47.0139912
  ],
  [
    83.8137162,
    47.0150076
  ],
  [
    83.8134185,
    47.0159498
  ],
  [
    83.8122753,
    47.0164403
  ],
  [
    83.8112355,
    47.0165052
  ],
  [
    83.8103339,
    47.0163219
  ],
  [
    83.8089759,
    47.016279
  ],
  [
    83.8080447,
    47.016189
  ],
  [
    83.8069064,
    47.0158483
  ],
  [
    83.8063647,
    47.0159161
  ],
  [
    83.8051381,
    47.0163207
  ],
  [
    83.8036294,
    47.016658
  ],
  [
    83.8021492,
    47.0167882
  ],
  [
    83.8008599,
    47.0170267
  ],
  [
    83.7997201,
    47.0176569
  ],
  [
    83.7983851,
    47.0182621
  ],
  [
    83.7975973,
    47.0183522
  ],
  [
    83.7966898,
    47.0183542
  ],
  [
    83.7959452,
    47.0182285
  ],
  [
    83.7947393,
    47.0174014
  ],
  [
    83.7936998,
    47.0169185
  ],
  [
    83.7922673,
    47.017878
  ],
  [
    83.7917031,
    47.018024
  ],
  [
    83.7913882,
    47.0182003
  ],
  [
    83.7905204,
    47.0189319
  ],
  [
    83.7897439,
    47.019484
  ],
  [
    83.7887734,
    47.0200533
  ],
  [
    83.7881471,
    47.02063
  ],
  [
    83.7867193,
    47.0215683
  ],
  [
    83.7862782,
    47.0217948
  ],
  [
    83.7860665,
    47.0222747
  ],
  [
    83.7859921,
    47.0227139
  ],
  [
    83.7856096,
    47.0231472
  ],
  [
    83.7850695,
    47.0236266
  ],
  [
    83.7845704,
    47.0239789
  ],
  [
    83.783512,
    47.024266
  ],
  [
    83.7824337,
    47.0243799
  ],
  [
    83.7801235,
    47.0244548
  ],
  [
    83.7781839,
    47.0243729
  ],
  [
    83.7766682,
    47.024631
  ],
  [
    83.7750851,
    47.0250449
  ],
  [
    83.7739414,
    47.0255797
  ],
  [
    83.77302,
    47.0258256
  ],
  [
    83.772085,
    47.0258905
  ],
  [
    83.7705566,
    47.0265336
  ],
  [
    83.7695769,
    47.0268353
  ],
  [
    83.7683409,
    47.0268847
  ],
  [
    83.7655549,
    47.0267769
  ],
  [
    83.7641992,
    47.0268305
  ],
  [
    83.7625948,
    47.026816
  ],
  [
    83.7624458,
    47.0263567
  ],
  [
    83.762097,
    47.025892
  ],
  [
    83.7614141,
    47.0255396
  ],
  [
    83.760811,
    47.0253458
  ],
  [
    83.7597332,
    47.0248626
  ],
  [
    83.7588177,
    47.024299
  ],
  [
    83.7584629,
    47.0241826
  ],
  [
    83.7579633,
    47.024186
  ],
  [
    83.7563649,
    47.0246667
  ],
  [
    83.7541292,
    47.025429
  ],
  [
    83.7540347,
    47.0257612
  ],
  [
    83.753978,
    47.0264679
  ],
  [
    83.7537067,
    47.0269999
  ],
  [
    83.7531789,
    47.0273831
  ],
  [
    83.7523873,
    47.0278515
  ],
  [
    83.7514951,
    47.0285105
  ],
  [
    83.7506507,
    47.028781
  ],
  [
    83.7495061,
    47.0292375
  ],
  [
    83.7491927,
    47.0294604
  ],
  [
    83.7482063,
    47.0322459
  ],
  [
    83.747827,
    47.0326582
  ],
  [
    83.7472712,
    47.0327774
  ],
  [
    83.7462352,
    47.0327564
  ],
  [
    83.7433037,
    47.0330133
  ],
  [
    83.7415975,
    47.0330728
  ],
  [
    83.7402354,
    47.0330737
  ],
  [
    83.7393796,
    47.0328721
  ],
  [
    83.7387185,
    47.0325743
  ],
  [
    83.737175,
    47.0315349
  ],
  [
    83.7357659,
    47.0302674
  ],
  [
    83.7337549,
    47.0286434
  ],
  [
    83.7327497,
    47.0275791
  ],
  [
    83.7324469,
    47.0274798
  ],
  [
    83.7308302,
    47.0274217
  ],
  [
    83.7300401,
    47.0273478
  ],
  [
    83.7282593,
    47.0266568
  ],
  [
    83.7277296,
    47.0265437
  ],
  [
    83.7266144,
    47.0265905
  ],
  [
    83.725405,
    47.0265121
  ],
  [
    83.7218313,
    47.0261062
  ],
  [
    83.7187456,
    47.0258113
  ],
  [
    83.7178218,
    47.0256147
  ],
  [
    83.7168518,
    47.0252311
  ],
  [
    83.7157158,
    47.0246416
  ],
  [
    83.7148968,
    47.0244041
  ],
  [
    83.7132745,
    47.0242114
  ],
  [
    83.712,
    47.0238876
  ],
  [
    83.7114892,
    47.0228059
  ],
  [
    83.7106108,
    47.0220158
  ],
  [
    83.7087576,
    47.0206325
  ],
  [
    83.7084301,
    47.0201101
  ],
  [
    83.707526,
    47.0178498
  ],
  [
    83.7073975,
    47.0176522
  ],
  [
    83.7070593,
    47.0175119
  ],
  [
    83.705704,
    47.017476
  ],
  [
    83.7028871,
    47.0175864
  ],
  [
    83.701735,
    47.0175909
  ],
  [
    83.6997055,
    47.0177017
  ],
  [
    83.698697,
    47.0175993
  ],
  [
    83.6979889,
    47.0177895
  ],
  [
    83.6980318,
    47.0181991
  ],
  [
    83.7021133,
    47.0204998
  ],
  [
    83.702295,
    47.0207155
  ],
  [
    83.7019831,
    47.0212955
  ],
  [
    83.7014966,
    47.0218898
  ],
  [
    83.7010282,
    47.0226524
  ],
  [
    83.7008617,
    47.0231334
  ],
  [
    83.7008375,
    47.0242435
  ],
  [
    83.7010063,
    47.0252378
  ],
  [
    83.7010168,
    47.0257454
  ],
  [
    83.7008808,
    47.0262205
  ],
  [
    83.7001266,
    47.0277578
  ],
  [
    83.7000651,
    47.0280396
  ],
  [
    83.7005749,
    47.0290104
  ],
  [
    83.7007208,
    47.0294002
  ],
  [
    83.7006138,
    47.0297229
  ],
  [
    83.6998042,
    47.0304495
  ],
  [
    83.6990641,
    47.0309299
  ],
  [
    83.6988902,
    47.0311342
  ],
  [
    83.6988825,
    47.0317309
  ],
  [
    83.6987175,
    47.0325784
  ],
  [
    83.6981085,
    47.0333026
  ],
  [
    83.6968089,
    47.034115
  ],
  [
    83.6960258,
    47.0346711
  ],
  [
    83.6957878,
    47.0349609
  ],
  [
    83.695776,
    47.0356253
  ],
  [
    83.6956364,
    47.0357719
  ],
  [
    83.6944998,
    47.0357134
  ],
  [
    83.6934731,
    47.035843
  ],
  [
    83.6885266,
    47.0352908
  ],
  [
    83.687921,
    47.0353072
  ],
  [
    83.6863652,
    47.0354542
  ],
  [
    83.6850054,
    47.0353203
  ],
  [
    83.6840345,
    47.03528
  ],
  [
    83.6827369,
    47.0353163
  ],
  [
    83.6802047,
    47.035182
  ],
  [
    83.6795935,
    47.0353558
  ],
  [
    83.6774242,
    47.0361441
  ],
  [
    83.6751101,
    47.0368867
  ],
  [
    83.6741835,
    47.0372991
  ],
  [
    83.6733985,
    47.0378146
  ],
  [
    83.6726312,
    47.0384427
  ],
  [
    83.6722439,
    47.0385732
  ],
  [
    83.6718158,
    47.0385807
  ],
  [
    83.6714052,
    47.0385259
  ],
  [
    83.670723,
    47.0383654
  ],
  [
    83.6700968,
    47.0383092
  ],
  [
    83.669295,
    47.0383114
  ],
  [
    83.668771,
    47.0382505
  ],
  [
    83.6671895,
    47.0378939
  ],
  [
    83.6668492,
    47.0379179
  ],
  [
    83.6664697,
    47.0380822
  ],
  [
    83.6660503,
    47.0387044
  ],
  [
    83.6656255,
    47.0389819
  ],
  [
    83.6631293,
    47.0394742
  ],
  [
    83.6599438,
    47.0398215
  ],
  [
    83.6586494,
    47.0400617
  ],
  [
    83.6575933,
    47.0404757
  ],
  [
    83.6555082,
    47.0417122
  ],
  [
    83.654939,
    47.0418864
  ],
  [
    83.652932,
    47.0421871
  ],
  [
    83.6480854,
    47.0430233
  ],
  [
    83.645902,
    47.0434287
  ],
  [
    83.6438913,
    47.0439557
  ],
  [
    83.6433412,
    47.0440115
  ],
  [
    83.6427436,
    47.0439751
  ],
  [
    83.6406279,
    47.04323
  ],
  [
    83.6401408,
    47.04318
  ],
  [
    83.6386535,
    47.0436189
  ],
  [
    83.6363884,
    47.0443925
  ],
  [
    83.6349768,
    47.0446613
  ],
  [
    83.6267819,
    47.0457355
  ],
  [
    83.6258072,
    47.0458231
  ],
  [
    83.6253025,
    47.0459669
  ],
  [
    83.6249469,
    47.0462925
  ],
  [
    83.6247101,
    47.0467897
  ],
  [
    83.6244897,
    47.047482
  ],
  [
    83.624202,
    47.0477972
  ],
  [
    83.6236525,
    47.0481453
  ],
  [
    83.6219307,
    47.048455
  ],
  [
    83.6199657,
    47.0486427
  ],
  [
    83.6191092,
    47.0487655
  ],
  [
    83.6177951,
    47.048857
  ],
  [
    83.6172743,
    47.0488638
  ],
  [
    83.6164754,
    47.0487547
  ],
  [
    83.614314,
    47.0487077
  ],
  [
    83.6134346,
    47.048611
  ],
  [
    83.6122141,
    47.0483222
  ],
  [
    83.6103882,
    47.0477989
  ],
  [
    83.6089382,
    47.0467015
  ],
  [
    83.6076546,
    47.0462861
  ],
  [
    83.607391,
    47.0462812
  ],
  [
    83.6070942,
    47.0463934
  ],
  [
    83.6068753,
    47.0465962
  ],
  [
    83.6067229,
    47.0468465
  ],
  [
    83.6066801,
    47.0472553
  ],
  [
    83.6067983,
    47.0485347
  ],
  [
    83.6067314,
    47.0495551
  ],
  [
    83.6068657,
    47.0513445
  ],
  [
    83.6067959,
    47.0517985
  ],
  [
    83.6066252,
    47.052055
  ],
  [
    83.6053912,
    47.0529088
  ],
  [
    83.6043279,
    47.0542407
  ],
  [
    83.6038532,
    47.0546344
  ],
  [
    83.6030121,
    47.0549334
  ],
  [
    83.6020212,
    47.0551661
  ],
  [
    83.5995147,
    47.0555421
  ],
  [
    83.598195,
    47.055799
  ],
  [
    83.5968343,
    47.0561796
  ],
  [
    83.5956407,
    47.0565789
  ],
  [
    83.5930953,
    47.0579004
  ],
  [
    83.5914236,
    47.058558
  ],
  [
    83.5902152,
    47.0589234
  ],
  [
    83.5883091,
    47.0591605
  ],
  [
    83.587491,
    47.0593274
  ],
  [
    83.5866457,
    47.0593977
  ],
  [
    83.5850221,
    47.0598134
  ],
  [
    83.5842266,
    47.0598916
  ],
  [
    83.5837105,
    47.0598147
  ],
  [
    83.5826751,
    47.0594659
  ],
  [
    83.5822274,
    47.0594277
  ],
  [
    83.5807911,
    47.0597636
  ],
  [
    83.579695,
    47.0602889
  ],
  [
    83.5787928,
    47.0605058
  ],
  [
    83.5779714,
    47.06054
  ],
  [
    83.5741499,
    47.0603801
  ],
  [
    83.5734987,
    47.0602946
  ],
  [
    83.5726852,
    47.0599219
  ],
  [
    83.5720199,
    47.0593847
  ],
  [
    83.570826,
    47.0581084
  ],
  [
    83.5693793,
    47.0562788
  ],
  [
    83.5689291,
    47.05605
  ],
  [
    83.5682285,
    47.0560249
  ],
  [
    83.5669234,
    47.0562447
  ],
  [
    83.5660434,
    47.056267
  ],
  [
    83.5651078,
    47.0563578
  ],
  [
    83.5647625,
    47.0565509
  ],
  [
    83.5641053,
    47.0570618
  ],
  [
    83.5626968,
    47.0579247
  ],
  [
    83.562175,
    47.0583487
  ],
  [
    83.561451,
    47.0591784
  ],
  [
    83.5608881,
    47.0596004
  ],
  [
    83.560154,
    47.0599509
  ],
  [
    83.5598109,
    47.0603638
  ],
  [
    83.5597158,
    47.060693
  ],
  [
    83.5596966,
    47.0611355
  ],
  [
    83.5598851,
    47.0618251
  ],
  [
    83.5599554,
    47.062546
  ],
  [
    83.559911,
    47.0629982
  ],
  [
    83.5601559,
    47.0635051
  ],
  [
    83.5609922,
    47.0642854
  ],
  [
    83.5617005,
    47.0650964
  ],
  [
    83.5619705,
    47.0656166
  ],
  [
    83.5623769,
    47.0669935
  ],
  [
    83.5628138,
    47.0677354
  ],
  [
    83.5642629,
    47.0698934
  ],
  [
    83.5648104,
    47.0713409
  ],
  [
    83.5653688,
    47.0742131
  ],
  [
    83.5652818,
    47.0750534
  ],
  [
    83.5653335,
    47.0759527
  ],
  [
    83.565577,
    47.0770684
  ],
  [
    83.5660067,
    47.0781228
  ],
  [
    83.5655686,
    47.0783995
  ],
  [
    83.5650154,
    47.0786391
  ],
  [
    83.5644832,
    47.0787779
  ],
  [
    83.5634156,
    47.0789161
  ],
  [
    83.5624424,
    47.0789115
  ],
  [
    83.5615678,
    47.0789991
  ],
  [
    83.5605175,
    47.0792271
  ],
  [
    83.5563853,
    47.0799158
  ],
  [
    83.5529382,
    47.080162
  ],
  [
    83.5501267,
    47.0801436
  ],
  [
    83.5492088,
    47.0801955
  ],
  [
    83.5488761,
    47.0802805
  ],
  [
    83.5483094,
    47.0814775
  ],
  [
    83.5475089,
    47.0824787
  ],
  [
    83.546587,
    47.0831011
  ],
  [
    83.5460959,
    47.0833384
  ],
  [
    83.5456612,
    47.0834679
  ],
  [
    83.5445865,
    47.0835453
  ],
  [
    83.5429125,
    47.0835765
  ],
  [
    83.5393714,
    47.0833662
  ],
  [
    83.5367429,
    47.0830082
  ],
  [
    83.5359197,
    47.0829887
  ],
  [
    83.5341248,
    47.0832976
  ],
  [
    83.5326487,
    47.0838294
  ],
  [
    83.5313338,
    47.0842035
  ],
  [
    83.5305297,
    47.0843475
  ],
  [
    83.5281841,
    47.0844987
  ],
  [
    83.527544,
    47.084694
  ],
  [
    83.5249814,
    47.0852174
  ],
  [
    83.5247835,
    47.0853578
  ],
  [
    83.5246995,
    47.0855959
  ],
  [
    83.5248231,
    47.0858635
  ],
  [
    83.5261485,
    47.0873173
  ],
  [
    83.5265582,
    47.0882259
  ],
  [
    83.5271027,
    47.0897006
  ],
  [
    83.5271245,
    47.0900607
  ],
  [
    83.5270624,
    47.0904409
  ],
  [
    83.5268646,
    47.0907856
  ],
  [
    83.5257788,
    47.0917204
  ],
  [
    83.5250273,
    47.0921917
  ],
  [
    83.5247062,
    47.0923302
  ],
  [
    83.5242327,
    47.0924458
  ],
  [
    83.5204915,
    47.0929116
  ],
  [
    83.520128,
    47.0930244
  ],
  [
    83.5199146,
    47.0932497
  ],
  [
    83.5198279,
    47.0939453
  ],
  [
    83.5200139,
    47.0947468
  ],
  [
    83.5200005,
    47.0949275
  ],
  [
    83.5199017,
    47.0950587
  ],
  [
    83.5196133,
    47.0950681
  ],
  [
    83.519294,
    47.094999
  ],
  [
    83.5189587,
    47.0947939
  ],
  [
    83.5181044,
    47.094123
  ],
  [
    83.5175557,
    47.0939309
  ],
  [
    83.5172897,
    47.0939198
  ],
  [
    83.5168725,
    47.0939995
  ],
  [
    83.5165783,
    47.0941147
  ],
  [
    83.5160122,
    47.0945999
  ],
  [
    83.5155737,
    47.0952451
  ],
  [
    83.5152029,
    47.0967109
  ],
  [
    83.5148329,
    47.0974718
  ],
  [
    83.5138909,
    47.0982536
  ],
  [
    83.5119098,
    47.0995674
  ],
  [
    83.5115641,
    47.0999977
  ],
  [
    83.5106577,
    47.102308
  ],
  [
    83.5103846,
    47.1028262
  ],
  [
    83.5099548,
    47.1034055
  ],
  [
    83.5097613,
    47.1038317
  ],
  [
    83.509783,
    47.1044832
  ],
  [
    83.5097028,
    47.1051407
  ],
  [
    83.5094135,
    47.1058423
  ],
  [
    83.509177,
    47.1069914
  ],
  [
    83.5089197,
    47.1074873
  ],
  [
    83.5074307,
    47.1084946
  ],
  [
    83.5067048,
    47.1088176
  ],
  [
    83.5059296,
    47.1095069
  ],
  [
    83.5053934,
    47.1096057
  ],
  [
    83.5038332,
    47.1091584
  ],
  [
    83.5023685,
    47.1085991
  ],
  [
    83.501123,
    47.107894
  ],
  [
    83.5001321,
    47.1075335
  ],
  [
    83.4994793,
    47.1073796
  ],
  [
    83.4986421,
    47.1073653
  ],
  [
    83.4976197,
    47.1076453
  ],
  [
    83.4966321,
    47.1081906
  ],
  [
    83.4951793,
    47.1094073
  ],
  [
    83.4946396,
    47.1097264
  ],
  [
    83.4938648,
    47.1099607
  ],
  [
    83.4926229,
    47.1099958
  ],
  [
    83.4909774,
    47.1098911
  ],
  [
    83.4890497,
    47.109634
  ],
  [
    83.486302,
    47.1091635
  ],
  [
    83.4852059,
    47.1090626
  ],
  [
    83.4832017,
    47.1091283
  ],
  [
    83.4823303,
    47.1093471
  ],
  [
    83.4816235,
    47.109785
  ],
  [
    83.4827524,
    47.1108371
  ],
  [
    83.4842406,
    47.1131113
  ],
  [
    83.4842204,
    47.1134315
  ],
  [
    83.4832349,
    47.1140476
  ],
  [
    83.4826033,
    47.1147054
  ],
  [
    83.4820633,
    47.1157559
  ],
  [
    83.4816675,
    47.1170496
  ],
  [
    83.4811573,
    47.1175318
  ],
  [
    83.4803429,
    47.1177416
  ],
  [
    83.4785233,
    47.1178473
  ],
  [
    83.4760145,
    47.1178908
  ],
  [
    83.474863,
    47.1178299
  ],
  [
    83.4729306,
    47.1180498
  ],
  [
    83.4711842,
    47.1181267
  ],
  [
    83.4685769,
    47.1181349
  ],
  [
    83.4676177,
    47.1182931
  ],
  [
    83.4671884,
    47.11841
  ],
  [
    83.4665515,
    47.1187367
  ],
  [
    83.4656729,
    47.1188234
  ],
  [
    83.4649029,
    47.1190278
  ],
  [
    83.4645365,
    47.1193598
  ],
  [
    83.4643622,
    47.1199945
  ],
  [
    83.4641822,
    47.1202383
  ],
  [
    83.463061,
    47.1212527
  ],
  [
    83.4629652,
    47.1215788
  ],
  [
    83.463106,
    47.1218375
  ],
  [
    83.4640572,
    47.1228613
  ],
  [
    83.4644327,
    47.1234983
  ],
  [
    83.4650377,
    47.1248899
  ],
  [
    83.4657866,
    47.1256597
  ],
  [
    83.4667027,
    47.1262158
  ],
  [
    83.4678058,
    47.1266133
  ],
  [
    83.4706184,
    47.1274048
  ],
  [
    83.4709527,
    47.1276157
  ],
  [
    83.4709722,
    47.1278081
  ],
  [
    83.4707805,
    47.1280202
  ],
  [
    83.4703752,
    47.1281123
  ],
  [
    83.4692625,
    47.12815
  ],
  [
    83.4663977,
    47.1276452
  ],
  [
    83.4658459,
    47.1277181
  ],
  [
    83.4650683,
    47.128096
  ],
  [
    83.4645896,
    47.1281422
  ],
  [
    83.4637564,
    47.127939
  ],
  [
    83.4627612,
    47.1278496
  ],
  [
    83.4621493,
    47.1280036
  ],
  [
    83.4614448,
    47.1283355
  ],
  [
    83.4600398,
    47.1295775
  ],
  [
    83.4596665,
    47.130637
  ],
  [
    83.4580554,
    47.1317834
  ],
  [
    83.4575394,
    47.1320721
  ],
  [
    83.4567899,
    47.1320599
  ],
  [
    83.4551187,
    47.1314113
  ],
  [
    83.4536147,
    47.1309837
  ],
  [
    83.4530074,
    47.1310452
  ],
  [
    83.4521189,
    47.1315694
  ],
  [
    83.4515326,
    47.1316933
  ],
  [
    83.449236,
    47.131404
  ],
  [
    83.4485124,
    47.1308146
  ],
  [
    83.4468499,
    47.1299821
  ],
  [
    83.445705,
    47.1291164
  ],
  [
    83.4433588,
    47.1265145
  ],
  [
    83.4407552,
    47.1253496
  ],
  [
    83.4373702,
    47.1248438
  ],
  [
    83.4325936,
    47.1240469
  ],
  [
    83.4298918,
    47.1232471
  ],
  [
    83.4286586,
    47.1216105
  ],
  [
    83.4279677,
    47.1201103
  ],
  [
    83.4278958,
    47.1196214
  ],
  [
    83.4281856,
    47.1183486
  ],
  [
    83.4295829,
    47.1155227
  ],
  [
    83.4292668,
    47.1148225
  ],
  [
    83.4287509,
    47.1145463
  ],
  [
    83.4275639,
    47.1145633
  ],
  [
    83.4248233,
    47.1149999
  ],
  [
    83.4233537,
    47.1150401
  ],
  [
    83.4208087,
    47.1155042
  ],
  [
    83.4189721,
    47.1156948
  ],
  [
    83.4170858,
    47.1155085
  ],
  [
    83.4159213,
    47.1157795
  ],
  [
    83.4134661,
    47.1159925
  ],
  [
    83.4130773,
    47.1163075
  ],
  [
    83.4127236,
    47.1171198
  ],
  [
    83.4127467,
    47.117929
  ],
  [
    83.4137477,
    47.1199216
  ],
  [
    83.4137281,
    47.1205132
  ],
  [
    83.4131043,
    47.1211668
  ],
  [
    83.4118505,
    47.1218656
  ],
  [
    83.4105168,
    47.1222781
  ],
  [
    83.409813,
    47.1226778
  ],
  [
    83.4091186,
    47.1234535
  ],
  [
    83.4087416,
    47.1242322
  ],
  [
    83.4084167,
    47.1253987
  ],
  [
    83.4083805,
    47.1267223
  ],
  [
    83.4080982,
    47.1273636
  ],
  [
    83.4072869,
    47.1284465
  ],
  [
    83.4039483,
    47.131533
  ],
  [
    83.4023263,
    47.1324915
  ],
  [
    83.401316,
    47.133389
  ],
  [
    83.399482,
    47.1348535
  ],
  [
    83.3982179,
    47.1353344
  ],
  [
    83.3965238,
    47.1358283
  ],
  [
    83.3949057,
    47.1365039
  ],
  [
    83.3928163,
    47.1384197
  ],
  [
    83.391878,
    47.1394139
  ],
  [
    83.3900841,
    47.1401321
  ],
  [
    83.3888252,
    47.1409534
  ],
  [
    83.3880651,
    47.141844
  ],
  [
    83.3875685,
    47.1430428
  ],
  [
    83.3873422,
    47.144295
  ],
  [
    83.3874315,
    47.1448957
  ],
  [
    83.3879299,
    47.1466602
  ],
  [
    83.3879674,
    47.1476245
  ],
  [
    83.3878177,
    47.148388
  ],
  [
    83.387302,
    47.1491274
  ],
  [
    83.38628,
    47.1502218
  ],
  [
    83.3855535,
    47.1507934
  ],
  [
    83.3842659,
    47.1511994
  ],
  [
    83.3820393,
    47.1515434
  ],
  [
    83.3806565,
    47.1541963
  ],
  [
    83.3794615,
    47.1553906
  ],
  [
    83.3790373,
    47.1556167
  ],
  [
    83.376965,
    47.1563501
  ],
  [
    83.3764592,
    47.1566287
  ],
  [
    83.3759955,
    47.1571205
  ],
  [
    83.3752784,
    47.1575868
  ],
  [
    83.3748864,
    47.1576109
  ],
  [
    83.3747278,
    47.1579079
  ],
  [
    83.3744776,
    47.1581223
  ],
  [
    83.3729763,
    47.1584182
  ],
  [
    83.3696478,
    47.1602114
  ],
  [
    83.3683504,
    47.1610007
  ],
  [
    83.3670782,
    47.1619175
  ],
  [
    83.3653237,
    47.1629946
  ],
  [
    83.3645324,
    47.1638665
  ],
  [
    83.3642628,
    47.1653444
  ],
  [
    83.3643653,
    47.1670508
  ],
  [
    83.3642461,
    47.1677913
  ],
  [
    83.362676,
    47.1692218
  ],
  [
    83.3622929,
    47.1693753
  ],
  [
    83.3621691,
    47.1695253
  ],
  [
    83.3621324,
    47.1701377
  ],
  [
    83.3617583,
    47.1710714
  ],
  [
    83.3619772,
    47.1716733
  ],
  [
    83.3626194,
    47.1725461
  ],
  [
    83.3642703,
    47.174437
  ],
  [
    83.3652108,
    47.1756683
  ],
  [
    83.3652956,
    47.1760834
  ],
  [
    83.3648906,
    47.1777807
  ],
  [
    83.3632216,
    47.177702
  ],
  [
    83.3624067,
    47.1778122
  ],
  [
    83.3617893,
    47.1779937
  ],
  [
    83.361146,
    47.1780366
  ],
  [
    83.3602894,
    47.1777783
  ],
  [
    83.3598784,
    47.1777048
  ],
  [
    83.3596152,
    47.1775256
  ],
  [
    83.3593378,
    47.1774155
  ],
  [
    83.358576,
    47.1773755
  ],
  [
    83.3577902,
    47.1772597
  ],
  [
    83.3562084,
    47.1768314
  ],
  [
    83.3542657,
    47.1767057
  ],
  [
    83.354255,
    47.1763087
  ],
  [
    83.3540285,
    47.1760864
  ],
  [
    83.3539182,
    47.1754874
  ],
  [
    83.3538845,
    47.1751703
  ],
  [
    83.3539786,
    47.1745596
  ],
  [
    83.3537513,
    47.1743167
  ],
  [
    83.3533515,
    47.1740903
  ],
  [
    83.3523147,
    47.1737916
  ],
  [
    83.3507149,
    47.1738406
  ],
  [
    83.3498884,
    47.1737982
  ],
  [
    83.3495307,
    47.1737201
  ],
  [
    83.3491743,
    47.173505
  ],
  [
    83.3489375,
    47.1732871
  ],
  [
    83.3477984,
    47.1729237
  ],
  [
    83.3470138,
    47.1727506
  ],
  [
    83.3463592,
    47.172667
  ],
  [
    83.3459083,
    47.172781
  ],
  [
    83.3453197,
    47.1726902
  ],
  [
    83.3449239,
    47.1727466
  ],
  [
    83.3438975,
    47.1726679
  ],
  [
    83.3427057,
    47.1727307
  ],
  [
    83.3423238,
    47.1726118
  ],
  [
    83.3409345,
    47.1725565
  ],
  [
    83.339367,
    47.1723277
  ],
  [
    83.3390536,
    47.1722153
  ],
  [
    83.3388272,
    47.1720461
  ],
  [
    83.3384742,
    47.1716293
  ],
  [
    83.3374968,
    47.1712296
  ],
  [
    83.3366034,
    47.1701885
  ],
  [
    83.3360137,
    47.1699977
  ],
  [
    83.3355093,
    47.1699743
  ],
  [
    83.3350891,
    47.1700044
  ],
  [
    83.3348322,
    47.1701173
  ],
  [
    83.3343451,
    47.1707772
  ],
  [
    83.3340919,
    47.1709123
  ],
  [
    83.3337469,
    47.1709427
  ],
  [
    83.3329945,
    47.170918
  ],
  [
    83.3326113,
    47.1708475
  ],
  [
    83.3310656,
    47.1707278
  ],
  [
    83.3266528,
    47.1714415
  ],
  [
    83.3256113,
    47.1714676
  ],
  [
    83.3199266,
    47.1712376
  ],
  [
    83.3185649,
    47.1713511
  ],
  [
    83.3181207,
    47.1714321
  ],
  [
    83.3178366,
    47.1717175
  ],
  [
    83.3164278,
    47.1724707
  ],
  [
    83.3161746,
    47.1727742
  ],
  [
    83.3152217,
    47.1733609
  ],
  [
    83.314969,
    47.1735664
  ],
  [
    83.314834,
    47.1738422
  ],
  [
    83.3145828,
    47.1740458
  ],
  [
    83.3138795,
    47.1742975
  ],
  [
    83.3128318,
    47.1747855
  ],
  [
    83.3114306,
    47.1750112
  ],
  [
    83.3098071,
    47.1757921
  ],
  [
    83.308903,
    47.1761237
  ],
  [
    83.3083717,
    47.1766958
  ],
  [
    83.3076861,
    47.1772308
  ],
  [
    83.3070706,
    47.1775543
  ],
  [
    83.306361,
    47.1777188
  ],
  [
    83.3055111,
    47.177831
  ],
  [
    83.3040996,
    47.1777923
  ],
  [
    83.3019177,
    47.1782434
  ],
  [
    83.3015466,
    47.1783998
  ],
  [
    83.3013541,
    47.1785641
  ],
  [
    83.3009766,
    47.1786449
  ],
  [
    83.2999284,
    47.1794258
  ],
  [
    83.2996309,
    47.1798076
  ],
  [
    83.2990806,
    47.1801441
  ],
  [
    83.2981777,
    47.1802875
  ],
  [
    83.2969851,
    47.1806033
  ],
  [
    83.2955497,
    47.18117
  ],
  [
    83.2955259,
    47.1813816
  ],
  [
    83.2952005,
    47.1815046
  ],
  [
    83.2945328,
    47.1816526
  ],
  [
    83.2937201,
    47.1817268
  ],
  [
    83.2930714,
    47.1819985
  ],
  [
    83.291634,
    47.1820762
  ],
  [
    83.2909782,
    47.182045
  ],
  [
    83.2903452,
    47.181872
  ],
  [
    83.2898798,
    47.1819586
  ],
  [
    83.2892247,
    47.1820068
  ],
  [
    83.2884538,
    47.1819923
  ],
  [
    83.2873865,
    47.1813043
  ],
  [
    83.2864969,
    47.1802661
  ],
  [
    83.2851865,
    47.1783468
  ],
  [
    83.2843873,
    47.1769095
  ],
  [
    83.2838209,
    47.1765695
  ],
  [
    83.2829926,
    47.1761675
  ],
  [
    83.2807434,
    47.1753496
  ],
  [
    83.2800874,
    47.1751998
  ],
  [
    83.2797767,
    47.1751904
  ],
  [
    83.2781993,
    47.1755651
  ],
  [
    83.2776285,
    47.1756223
  ],
  [
    83.2761391,
    47.1756128
  ],
  [
    83.2731859,
    47.1754503
  ],
  [
    83.2716201,
    47.1754286
  ],
  [
    83.2684187,
    47.1757282
  ],
  [
    83.2667042,
    47.1757837
  ],
  [
    83.2650529,
    47.1759626
  ],
  [
    83.262777,
    47.1763499
  ],
  [
    83.2609735,
    47.1767867
  ],
  [
    83.2574603,
    47.1763123
  ],
  [
    83.2554529,
    47.1762194
  ],
  [
    83.2535005,
    47.1763271
  ],
  [
    83.2516246,
    47.176571
  ],
  [
    83.2496753,
    47.176906
  ],
  [
    83.2458888,
    47.1776864
  ],
  [
    83.2441187,
    47.1782909
  ],
  [
    83.2422274,
    47.1784043
  ],
  [
    83.2405908,
    47.1788367
  ],
  [
    83.2393403,
    47.1792098
  ],
  [
    83.2371545,
    47.1805544
  ],
  [
    83.2359785,
    47.1811823
  ],
  [
    83.2354481,
    47.1817231
  ],
  [
    83.2353497,
    47.1823604
  ],
  [
    83.2357859,
    47.1835615
  ],
  [
    83.2361036,
    47.184017
  ],
  [
    83.2362973,
    47.1850891
  ],
  [
    83.2362555,
    47.1854354
  ],
  [
    83.2366726,
    47.1859232
  ],
  [
    83.2372078,
    47.1861001
  ],
  [
    83.2375159,
    47.1862713
  ],
  [
    83.2376238,
    47.1866789
  ],
  [
    83.2378805,
    47.186847
  ],
  [
    83.2381094,
    47.1871876
  ],
  [
    83.2379904,
    47.1876044
  ],
  [
    83.2375113,
    47.1880753
  ],
  [
    83.2368569,
    47.1885821
  ],
  [
    83.2364815,
    47.1890704
  ],
  [
    83.2359517,
    47.189178
  ],
  [
    83.2344139,
    47.189257
  ],
  [
    83.23199,
    47.1892944
  ],
  [
    83.2282936,
    47.1889724
  ],
  [
    83.2270799,
    47.1887393
  ],
  [
    83.2260918,
    47.1886614
  ],
  [
    83.2242751,
    47.1887218
  ],
  [
    83.2234826,
    47.1886969
  ],
  [
    83.2225542,
    47.1883298
  ],
  [
    83.2217704,
    47.187734
  ],
  [
    83.2208861,
    47.1879509
  ],
  [
    83.2201455,
    47.1882942
  ],
  [
    83.2189373,
    47.1890805
  ],
  [
    83.2181441,
    47.1896856
  ],
  [
    83.2171638,
    47.1902137
  ],
  [
    83.2161665,
    47.1905556
  ],
  [
    83.215399,
    47.1906031
  ],
  [
    83.2149209,
    47.1906983
  ],
  [
    83.2146203,
    47.1911049
  ],
  [
    83.2137031,
    47.1928057
  ],
  [
    83.2130431,
    47.1936939
  ],
  [
    83.2120136,
    47.1946385
  ],
  [
    83.2112701,
    47.1950305
  ],
  [
    83.2090509,
    47.195967
  ],
  [
    83.2085736,
    47.1970577
  ],
  [
    83.207143,
    47.1983366
  ],
  [
    83.2068776,
    47.1989022
  ],
  [
    83.2069774,
    47.1998257
  ],
  [
    83.2072899,
    47.200899
  ],
  [
    83.2079627,
    47.2021575
  ],
  [
    83.2078612,
    47.2028329
  ],
  [
    83.2076184,
    47.2035414
  ],
  [
    83.2071133,
    47.2046008
  ],
  [
    83.2072065,
    47.2050554
  ],
  [
    83.2074008,
    47.2055333
  ],
  [
    83.2083379,
    47.206842
  ],
  [
    83.2084929,
    47.2074661
  ],
  [
    83.2085782,
    47.2086063
  ],
  [
    83.2085674,
    47.2093908
  ],
  [
    83.208348,
    47.2101926
  ],
  [
    83.2078326,
    47.2111433
  ],
  [
    83.2071566,
    47.2120006
  ],
  [
    83.206453,
    47.2125245
  ],
  [
    83.2055123,
    47.2129204
  ],
  [
    83.2043374,
    47.2131435
  ],
  [
    83.203562,
    47.2133931
  ],
  [
    83.2026569,
    47.2140467
  ],
  [
    83.2004593,
    47.2163289
  ],
  [
    83.1991693,
    47.2169099
  ],
  [
    83.197921,
    47.2173806
  ],
  [
    83.3175632,
    47.3946326
  ],
  [
    83.0264255,
    47.5932006
  ],
  [
    83.1170627,
    47.9163439
  ],
  [
    83.3724948,
    48.011977
  ],
  [
    83.4299739,
    48.234614
  ],
  [
    83.4603881,
    48.2378227
  ],
  [
    83.4651945,
    48.2381086
  ],
  [
    83.4695719,
    48.2403952
  ],
  [
    83.4696577,
    48.2443965
  ],
  [
    83.4660958,
    48.2513408
  ],
  [
    83.4658812,
    48.2540555
  ],
  [
    83.46807,
    48.2555985
  ],
  [
    83.4724043,
    48.2573414
  ],
  [
    83.4793732,
    48.2586902
  ],
  [
    83.4849356,
    48.2592272
  ],
  [
    83.4886264,
    48.2608557
  ],
  [
    83.4912442,
    48.2643983
  ],
  [
    83.4923601,
    48.2691405
  ],
  [
    83.4911282,
    48.2721622
  ],
  [
    83.4909867,
    48.2736537
  ],
  [
    83.486309,
    48.2768812
  ],
  [
    83.4805153,
    48.2785662
  ],
  [
    83.4772967,
    48.2799657
  ],
  [
    83.4764385,
    48.282736
  ],
  [
    83.4780607,
    48.2852165
  ],
  [
    83.4827241,
    48.2886321
  ],
  [
    83.5181375,
    48.3175514
  ],
  [
    83.5579617,
    48.3379707
  ],
  [
    83.5960985,
    48.3482895
  ],
  [
    83.6196896,
    48.3678829
  ],
  [
    83.6155697,
    48.3909143
  ],
  [
    83.5589215,
    48.4171254
  ],
  [
    83.4857937,
    48.4378568
  ],
  [
    83.4377282,
    48.4706455
  ],
  [
    83.4233086,
    48.5038678
  ],
  [
    83.4233086,
    48.5407054
  ],
  [
    83.2351657,
    48.5620694
  ],
  [
    83.0703708,
    48.7742946
  ],
  [
    83.1143161,
    48.9369368
  ],
  [
    82.9193088,
    48.9639924
  ],
  [
    82.8149387,
    48.9229522
  ],
  [
    82.7160617,
    49.104895
  ],
  [
    82.8049421,
    49.1955732
  ],
  [
    82.8038048,
    49.1971016
  ],
  [
    82.8046416,
    49.1981953
  ],
  [
    82.8035043,
    49.2007191
  ],
  [
    82.804942,
    49.2017426
  ],
  [
    82.8020238,
    49.2029624
  ],
  [
    82.8017985,
    49.2042732
  ],
  [
    82.8021633,
    49.2050793
  ],
  [
    82.8028499,
    49.2061588
  ],
  [
    82.8026782,
    49.2064112
  ],
  [
    82.8008436,
    49.207049
  ],
  [
    82.8008329,
    49.2073855
  ],
  [
    82.8020989,
    49.2083878
  ],
  [
    82.8017448,
    49.2087593
  ],
  [
    82.8007363,
    49.2089626
  ],
  [
    82.7975499,
    49.209327
  ],
  [
    82.7966915,
    49.2095934
  ],
  [
    82.7966057,
    49.2098107
  ],
  [
    82.7972709,
    49.2104625
  ],
  [
    82.7991163,
    49.2115559
  ],
  [
    82.8001999,
    49.2125862
  ],
  [
    82.8023778,
    49.2130347
  ],
  [
    82.802528,
    49.2135043
  ],
  [
    82.8019379,
    49.2138477
  ],
  [
    82.8007256,
    49.2141351
  ],
  [
    82.8007792,
    49.2148219
  ],
  [
    82.8011547,
    49.2153896
  ],
  [
    82.8019165,
    49.2159152
  ],
  [
    82.8026782,
    49.2159993
  ],
  [
    82.8031181,
    49.2163006
  ],
  [
    82.8028499,
    49.216644
  ],
  [
    82.8012084,
    49.2164969
  ],
  [
    82.7996849,
    49.2170785
  ],
  [
    82.7987193,
    49.2172467
  ],
  [
    82.7964233,
    49.2178704
  ],
  [
    82.7960371,
    49.218403
  ],
  [
    82.7961873,
    49.2189076
  ],
  [
    82.7967452,
    49.219307
  ],
  [
    82.7981614,
    49.2204983
  ],
  [
    82.7981936,
    49.2210029
  ],
  [
    82.7971529,
    49.2216405
  ],
  [
    82.7966915,
    49.2222081
  ],
  [
    82.7968847,
    49.223035
  ],
  [
    82.7976035,
    49.2241631
  ],
  [
    82.7970028,
    49.2244574
  ],
  [
    82.7936982,
    49.2237918
  ],
  [
    82.7916865,
    49.2234974
  ],
  [
    82.7899645,
    49.2234274
  ],
  [
    82.7891814,
    49.2239739
  ],
  [
    82.7893745,
    49.2243803
  ],
  [
    82.7900397,
    49.2243593
  ],
  [
    82.7915632,
    49.224002
  ],
  [
    82.7917563,
    49.2242542
  ],
  [
    82.7902972,
    49.2256206
  ],
  [
    82.7903508,
    49.226062
  ],
  [
    82.7914237,
    49.2271831
  ],
  [
    82.7925824,
    49.2279958
  ],
  [
    82.7909087,
    49.2289767
  ],
  [
    82.787175,
    49.2286124
  ],
  [
    82.7848576,
    49.2289207
  ],
  [
    82.7817677,
    49.2311627
  ],
  [
    82.780094,
    49.2309665
  ],
  [
    82.7788065,
    49.2295092
  ],
  [
    82.7741288,
    49.2289767
  ],
  [
    82.7729271,
    49.2293411
  ],
  [
    82.7724122,
    49.2300137
  ],
  [
    82.7730559,
    49.2307703
  ],
  [
    82.7730988,
    49.231527
  ],
  [
    82.7711676,
    49.2314709
  ],
  [
    82.7706526,
    49.2331523
  ],
  [
    82.767949,
    49.2334045
  ],
  [
    82.7663611,
    49.2345535
  ],
  [
    82.76134,
    49.234021
  ],
  [
    82.7607821,
    49.2358425
  ],
  [
    82.7585934,
    49.236515
  ],
  [
    82.7539587,
    49.2355903
  ],
  [
    82.7471994,
    49.2323256
  ],
  [
    82.7478646,
    49.2290608
  ],
  [
    82.7458046,
    49.2279678
  ],
  [
    82.7415989,
    49.2287525
  ],
  [
    82.7399682,
    49.2276595
  ],
  [
    82.7368353,
    49.226062
  ],
  [
    82.73396,
    49.2263423
  ],
  [
    82.7331017,
    49.2273512
  ],
  [
    82.7328442,
    49.2291449
  ],
  [
    82.7319859,
    49.2298175
  ],
  [
    82.7300118,
    49.2297334
  ],
  [
    82.7277373,
    49.2310506
  ],
  [
    82.7276944,
    49.2321155
  ],
  [
    82.7259348,
    49.2331523
  ],
  [
    82.7233599,
    49.2321995
  ],
  [
    82.7208279,
    49.2331804
  ],
  [
    82.7175663,
    49.2334045
  ],
  [
    82.7155864,
    49.2365763
  ],
  [
    82.7135323,
    49.2374677
  ],
  [
    82.7130057,
    49.2373132
  ],
  [
    82.7125639,
    49.2367708
  ],
  [
    82.711644,
    49.2366271
  ],
  [
    82.7054213,
    49.2395972
  ],
  [
    82.699499,
    49.2404658
  ],
  [
    82.6918172,
    49.2401856
  ],
  [
    82.679157,
    49.2357585
  ],
  [
    82.6755951,
    49.2341611
  ],
  [
    82.6729344,
    49.2318913
  ],
  [
    82.6701879,
    49.2276315
  ],
  [
    82.6673447,
    49.2268537
  ],
  [
    82.6671194,
    49.2241071
  ],
  [
    82.6661753,
    49.2231331
  ],
  [
    82.665494,
    49.2215705
  ],
  [
    82.6653599,
    49.2207471
  ],
  [
    82.6643353,
    49.2201024
  ],
  [
    82.6641314,
    49.2198571
  ],
  [
    82.664051,
    49.2196539
  ],
  [
    82.66389,
    49.2183575
  ],
  [
    82.6636325,
    49.2177898
  ],
  [
    82.663375,
    49.2171381
  ],
  [
    82.6626508,
    49.2163917
  ],
  [
    82.6624631,
    49.215768
  ],
  [
    82.6617013,
    49.2154281
  ],
  [
    82.660945,
    49.2147763
  ],
  [
    82.6606499,
    49.2142822
  ],
  [
    82.6600437,
    49.2131118
  ],
  [
    82.6586275,
    49.2116155
  ],
  [
    82.6581876,
    49.2113631
  ],
  [
    82.6577424,
    49.2113561
  ],
  [
    82.6570343,
    49.2115699
  ],
  [
    82.6565354,
    49.2114963
  ],
  [
    82.6549314,
    49.2106588
  ],
  [
    82.6546097,
    49.2106237
  ],
  [
    82.6539122,
    49.2106342
  ],
  [
    82.6526998,
    49.2111564
  ],
  [
    82.6517074,
    49.2114613
  ],
  [
    82.6513856,
    49.2114262
  ],
  [
    82.6507955,
    49.2111424
  ],
  [
    82.6503449,
    49.2111143
  ],
  [
    82.6502,
    49.2109391
  ],
  [
    82.6497548,
    49.2109987
  ],
  [
    82.6470136,
    49.2115279
  ],
  [
    82.6437949,
    49.211612
  ],
  [
    82.643162,
    49.2120185
  ],
  [
    82.6423572,
    49.2134693
  ],
  [
    82.6419174,
    49.2136375
  ],
  [
    82.6416813,
    49.2131328
  ],
  [
    82.6382481,
    49.210862
  ],
  [
    82.6358664,
    49.210876
  ],
  [
    82.6349222,
    49.2114297
  ],
  [
    82.6336132,
    49.2130558
  ],
  [
    82.6328086,
    49.2155578
  ],
  [
    82.6316606,
    49.2171486
  ],
  [
    82.62915,
    49.2183329
  ],
  [
    82.6285385,
    49.2186203
  ],
  [
    82.6278733,
    49.2190618
  ],
  [
    82.627058,
    49.2199868
  ],
  [
    82.626661,
    49.2210589
  ],
  [
    82.6268112,
    49.2223973
  ],
  [
    82.6273584,
    49.2235745
  ],
  [
    82.6291608,
    49.2244434
  ],
  [
    82.6298367,
    49.2254944
  ],
  [
    82.6326476,
    49.2269378
  ],
  [
    82.6335167,
    49.2280729
  ],
  [
    82.633227,
    49.2288366
  ],
  [
    82.6333237,
    49.229271
  ],
  [
    82.6329159,
    49.2302799
  ],
  [
    82.6308237,
    49.2321855
  ],
  [
    82.6310061,
    49.2335446
  ],
  [
    82.6301586,
    49.2344903
  ],
  [
    82.6292788,
    49.2352049
  ],
  [
    82.6277446,
    49.2356813
  ],
  [
    82.6262533,
    49.2363468
  ],
  [
    82.6230561,
    49.2375657
  ],
  [
    82.6091408,
    49.2416846
  ],
  [
    82.5986266,
    49.2449906
  ],
  [
    82.6092909,
    49.2541372
  ],
  [
    82.5238035,
    49.3290379
  ],
  [
    82.4042127,
    49.337848
  ],
  [
    82.3743219,
    49.2963906
  ],
  [
    82.3322217,
    49.353745
  ],
  [
    82.2603218,
    49.3695554
  ],
  [
    82.255996,
    49.391312
  ],
  [
    82.2346724,
    49.4031612
  ],
  [
    82.2084281,
    49.3863116
  ],
  [
    82.1855479,
    49.3941837
  ],
  [
    82.1776963,
    49.3743826
  ],
  [
    82.0878687,
    49.409732
  ],
  [
    82.0225669,
    49.4579585
  ],
  [
    82.05793,
    49.498714
  ],
  [
    82.0167323,
    49.5094259
  ],
  [
    81.9787893,
    49.5617366
  ],
  [
    81.8918676,
    49.5711062
  ],
  [
    81.899505,
    49.5909802
  ],
  [
    81.8614275,
    49.6095438
  ],
  [
    81.7869043,
    49.6168598
  ],
  [
    81.7944026,
    49.6536132
  ],
  [
    81.7516939,
    49.6938855
  ],
  [
    81.7167958,
    49.6865968
  ],
  [
    81.7438879,
    49.7414283
  ],
  [
    81.6888741,
    49.7835398
  ],
  [
    81.7329708,
    49.7978779
  ],
  [
    81.7081829,
    49.8690918
  ],
  [
    81.619675,
    49.8740626
  ],
  [
    81.5919789,
    49.9035044
  ],
  [
    81.5386654,
    49.9156955
  ],
  [
    81.5172575,
    49.9273077
  ],
  [
    81.3257031,
    49.9640993
  ],
  [
    81.327215,
    50.0291761
  ],
  [
    81.3550784,
    50.0540741
  ],
  [
    81.3721662,
    50.1181656
  ],
  [
    81.1469101,
    50.177109
  ]
]