import {LatLngExpression} from "leaflet";

export const ridderDistrict: LatLngExpression[] = [
    [
        83.1270808,
        50.2598563
    ],
    [
        83.1283467,
        50.2599798
    ],
    [
        83.1291192,
        50.2601993
    ],
    [
        83.1300418,
        50.2612281
    ],
    [
        83.1304924,
        50.262545
    ],
    [
        83.1307768,
        50.2631674
    ],
    [
        83.1311685,
        50.2637367
    ],
    [
        83.1322842,
        50.2637675
    ],
    [
        83.1328367,
        50.2636681
    ],
    [
        83.1338667,
        50.2635926
    ],
    [
        83.1346499,
        50.2633114
    ],
    [
        83.1353687,
        50.2628656
    ],
    [
        83.1365596,
        50.2626462
    ],
    [
        83.1377505,
        50.2628451
    ],
    [
        83.1387912,
        50.2633251
    ],
    [
        83.1406313,
        50.2635669
    ],
    [
        83.1539619,
        50.2913562
    ],
    [
        83.1747007,
        50.2956264
    ],
    [
        83.1744592,
        50.2963289
    ],
    [
        83.1738048,
        50.2972644
    ],
    [
        83.1732899,
        50.2978333
    ],
    [
        83.1724423,
        50.298265
    ],
    [
        83.1722276,
        50.298666
    ],
    [
        83.1720775,
        50.299084
    ],
    [
        83.1720239,
        50.2996905
    ],
    [
        83.1722491,
        50.3003416
    ],
    [
        83.1725656,
        50.3010098
    ],
    [
        83.1730216,
        50.3016471
    ],
    [
        83.1732093,
        50.3025209
    ],
    [
        83.1735741,
        50.303213
    ],
    [
        83.1754785,
        50.3104319
    ],
    [
        83.1757682,
        50.3116857
    ],
    [
        83.1758754,
        50.3124599
    ],
    [
        83.1760739,
        50.3130046
    ],
    [
        83.1766372,
        50.3133849
    ],
    [
        83.1772648,
        50.3139021
    ],
    [
        83.1778336,
        50.3144399
    ],
    [
        83.1780373,
        50.3150428
    ],
    [
        83.1779783,
        50.3159643
    ],
    [
        83.17815,
        50.3168926
    ],
    [
        83.1780641,
        50.3185128
    ],
    [
        83.1781392,
        50.3187594
    ],
    [
        83.1780212,
        50.3191499
    ],
    [
        83.1783002,
        50.320859
    ],
    [
        83.1784611,
        50.3215989
    ],
    [
        83.1789224,
        50.3223935
    ],
    [
        83.1794053,
        50.3233148
    ],
    [
        83.1882082,
        50.3314586
    ],
    [
        83.1890504,
        50.3321845
    ],
    [
        83.1898605,
        50.3330714
    ],
    [
        83.1909495,
        50.3337255
    ],
    [
        83.1916522,
        50.3340987
    ],
    [
        83.1927466,
        50.3343966
    ],
    [
        83.1934493,
        50.3350438
    ],
    [
        83.1939106,
        50.3359854
    ],
    [
        83.1945758,
        50.3372351
    ],
    [
        83.1953805,
        50.3380911
    ],
    [
        83.1958257,
        50.339019
    ],
    [
        83.1958633,
        50.3398338
    ],
    [
        83.1958472,
        50.3407034
    ],
    [
        83.1953322,
        50.3419462
    ],
    [
        83.1953162,
        50.3424426
    ],
    [
        83.1947367,
        50.3425453
    ],
    [
        83.194034,
        50.342874
    ],
    [
        83.1931328,
        50.3436374
    ],
    [
        83.1924354,
        50.3446987
    ],
    [
        83.1920062,
        50.345736
    ],
    [
        83.1918614,
        50.347119
    ],
    [
        83.1918829,
        50.3485636
    ],
    [
        83.191722,
        50.3493132
    ],
    [
        83.1910621,
        50.3497959
    ],
    [
        83.18978,
        50.3503915
    ],
    [
        83.1884175,
        50.3509118
    ],
    [
        83.187291,
        50.3514149
    ],
    [
        83.1843566,
        50.3530545
    ],
    [
        83.1823342,
        50.3543517
    ],
    [
        83.1810146,
        50.355666
    ],
    [
        83.1805371,
        50.3563334
    ],
    [
        83.1801455,
        50.3571309
    ],
    [
        83.1797004,
        50.3584519
    ],
    [
        83.1795019,
        50.359198
    ],
    [
        83.179298,
        50.361159
    ],
    [
        83.1793141,
        50.3619974
    ],
    [
        83.1800169,
        50.3635134
    ],
    [
        83.1808484,
        50.3641294
    ],
    [
        83.1817979,
        50.3651628
    ],
    [
        83.1822967,
        50.3682527
    ],
    [
        83.1825435,
        50.3710823
    ],
    [
        83.1827527,
        50.3723414
    ],
    [
        83.1828332,
        50.3731249
    ],
    [
        83.1829834,
        50.3738947
    ],
    [
        83.1832999,
        50.3745995
    ],
    [
        83.1833053,
        50.374969
    ],
    [
        83.1830102,
        50.3758568
    ],
    [
        83.1837934,
        50.3761784
    ],
    [
        83.1846142,
        50.3764213
    ],
    [
        83.1853759,
        50.37676
    ],
    [
        83.1866258,
        50.376931
    ],
    [
        83.1875431,
        50.3767873
    ],
    [
        83.1882781,
        50.3765137
    ],
    [
        83.1888789,
        50.3762263
    ],
    [
        83.1891793,
        50.3760279
    ],
    [
        83.1896889,
        50.3760963
    ],
    [
        83.1905365,
        50.3762708
    ],
    [
        83.1929558,
        50.3762229
    ],
    [
        83.1940019,
        50.3764452
    ],
    [
        83.1950855,
        50.3767292
    ],
    [
        83.1960136,
        50.3770439
    ],
    [
        83.1971937,
        50.3776358
    ],
    [
        83.1981862,
        50.3780976
    ],
    [
        83.1988406,
        50.3783542
    ],
    [
        83.1997204,
        50.3785355
    ],
    [
        83.2007021,
        50.3785868
    ],
    [
        83.20207,
        50.3785902
    ],
    [
        83.2032609,
        50.3785663
    ],
    [
        83.2039958,
        50.37845
    ],
    [
        83.2047254,
        50.3787989
    ],
    [
        83.2057661,
        50.3795139
    ],
    [
        83.2062167,
        50.3800509
    ],
    [
        83.2065439,
        50.3805914
    ],
    [
        83.2067531,
        50.3812688
    ],
    [
        83.2065707,
        50.3818092
    ],
    [
        83.2062703,
        50.3829483
    ],
    [
        83.2060933,
        50.383328
    ],
    [
        83.2062596,
        50.3839198
    ],
    [
        83.2069033,
        50.3847681
    ],
    [
        83.2070106,
        50.3851888
    ],
    [
        83.207531,
        50.3855206
    ],
    [
        83.2080996,
        50.3864543
    ],
    [
        83.2081264,
        50.3869537
    ],
    [
        83.2092959,
        50.3890948
    ],
    [
        83.210004,
        50.3895463
    ],
    [
        83.2106316,
        50.3901414
    ],
    [
        83.2109749,
        50.3906749
    ],
    [
        83.2138395,
        50.390928
    ],
    [
        83.2178736,
        50.3896968
    ],
    [
        83.2217574,
        50.3905586
    ],
    [
        83.222959,
        50.3914752
    ],
    [
        83.2257485,
        50.3960441
    ],
    [
        83.2298255,
        50.3981643
    ],
    [
        83.2367563,
        50.4014743
    ],
    [
        83.239696,
        50.4022265
    ],
    [
        83.2415414,
        50.4031702
    ],
    [
        83.2448244,
        50.4036489
    ],
    [
        83.2478499,
        50.4050166
    ],
    [
        83.249824,
        50.4059329
    ],
    [
        83.2579135,
        50.4108696
    ],
    [
        83.2541369,
        50.4152725
    ],
    [
        83.2541369,
        50.420386
    ],
    [
        83.2510042,
        50.4256084
    ],
    [
        83.2549523,
        50.4313222
    ],
    [
        83.2646083,
        50.4416273
    ],
    [
        83.2665825,
        50.4552636
    ],
    [
        83.2820318,
        50.4581052
    ],
    [
        83.2973956,
        50.4668473
    ],
    [
        83.302803,
        50.4835075
    ],
    [
        83.3093261,
        50.4915351
    ],
    [
        83.3066653,
        50.5037104
    ],
    [
        83.3185958,
        50.5099334
    ],
    [
        83.3092403,
        50.5221039
    ],
    [
        83.3163642,
        50.5399993
    ],
    [
        83.3132743,
        50.5475265
    ],
    [
        83.3023738,
        50.5563066
    ],
    [
        83.2858084,
        50.5632858
    ],
    [
        83.2513045,
        50.5849806
    ],
    [
        83.2382582,
        50.5847625
    ],
    [
        83.2229803,
        50.589776
    ],
    [
        83.2043551,
        50.5922825
    ],
    [
        83.1954825,
        50.5933995
    ],
    [
        83.1926607,
        50.5932905
    ],
    [
        83.1904076,
        50.5937877
    ],
    [
        83.1873177,
        50.594244
    ],
    [
        83.1842171,
        50.5922416
    ],
    [
        83.1803333,
        50.5923914
    ],
    [
        83.1771146,
        50.5922348
    ],
    [
        83.1753122,
        50.5925004
    ],
    [
        83.1736599,
        50.5922688
    ],
    [
        83.1711708,
        50.5926434
    ],
    [
        83.1692826,
        50.5924936
    ],
    [
        83.1666969,
        50.5927184
    ],
    [
        83.1615256,
        50.5924187
    ],
    [
        83.1588327,
        50.5922348
    ],
    [
        83.1565046,
        50.5916151
    ],
    [
        83.1552278,
        50.5923846
    ],
    [
        83.155099,
        50.5934812
    ],
    [
        83.156129,
        50.5945913
    ],
    [
        83.1547879,
        50.5966958
    ],
    [
        83.1553565,
        50.5979285
    ],
    [
        83.1549703,
        50.5989908
    ],
    [
        83.1518267,
        50.6006933
    ],
    [
        83.1488334,
        50.6044794
    ],
    [
        83.1487905,
        50.6062226
    ],
    [
        83.1493377,
        50.607026
    ],
    [
        83.1507002,
        50.6074958
    ],
    [
        83.1522774,
        50.6085103
    ],
    [
        83.1511723,
        50.609484
    ],
    [
        83.149917,
        50.6099878
    ],
    [
        83.1497668,
        50.6122482
    ],
    [
        83.1499814,
        50.6135894
    ],
    [
        83.1422459,
        50.6167549
    ],
    [
        83.1401217,
        50.6186407
    ],
    [
        83.1414627,
        50.6216084
    ],
    [
        83.1412159,
        50.6228336
    ],
    [
        83.1421708,
        50.6248824
    ],
    [
        83.1442093,
        50.626897
    ],
    [
        83.1467306,
        50.6274619
    ],
    [
        83.1485115,
        50.6275776
    ],
    [
        83.1491982,
        50.6278975
    ],
    [
        83.1490587,
        50.6289796
    ],
    [
        83.1509684,
        50.630191
    ],
    [
        83.1516551,
        50.631171
    ],
    [
        83.1529962,
        50.6314773
    ],
    [
        83.153951,
        50.6340429
    ],
    [
        83.1528567,
        50.6356283
    ],
    [
        83.1517195,
        50.6359822
    ],
    [
        83.1510865,
        50.6367511
    ],
    [
        83.1509363,
        50.6376154
    ],
    [
        83.1519126,
        50.6385747
    ],
    [
        83.1535112,
        50.6392347
    ],
    [
        83.1547772,
        50.6400784
    ],
    [
        83.1548845,
        50.640963
    ],
    [
        83.1561934,
        50.6417454
    ],
    [
        83.1577276,
        50.6420925
    ],
    [
        83.1587254,
        50.6429633
    ],
    [
        83.1585108,
        50.643079
    ],
    [
        83.1576096,
        50.6428885
    ],
    [
        83.1567191,
        50.6432286
    ],
    [
        83.15688,
        50.6437185
    ],
    [
        83.159353,
        50.6454182
    ],
    [
        83.1604527,
        50.6455078
    ],
    [
        83.1611608,
        50.6460317
    ],
    [
        83.1629311,
        50.6462018
    ],
    [
        83.1632959,
        50.6465759
    ],
    [
        83.1629847,
        50.6468345
    ],
    [
        83.1624161,
        50.6469501
    ],
    [
        83.1628453,
        50.6473855
    ],
    [
        83.1635749,
        50.6473924
    ],
    [
        83.1662034,
        50.6478345
    ],
    [
        83.1690251,
        50.6475352
    ],
    [
        83.1714283,
        50.6466031
    ],
    [
        83.1725656,
        50.6465147
    ],
    [
        83.1744002,
        50.6471882
    ],
    [
        83.178048,
        50.6461133
    ],
    [
        83.1810521,
        50.6462494
    ],
    [
        83.186438,
        50.6468481
    ],
    [
        83.1892704,
        50.646263
    ],
    [
        83.1922959,
        50.646712
    ],
    [
        83.1962656,
        50.6481815
    ],
    [
        83.1999134,
        50.6488073
    ],
    [
        83.2050632,
        50.6487325
    ],
    [
        83.2066083,
        50.6492972
    ],
    [
        83.2075308,
        50.6504128
    ],
    [
        83.2085608,
        50.6506849
    ],
    [
        83.2103632,
        50.6523175
    ],
    [
        83.2163286,
        50.6548208
    ],
    [
        83.2207058,
        50.6550111
    ],
    [
        83.2258128,
        50.6534875
    ],
    [
        83.2334517,
        50.6526168
    ],
    [
        83.2432364,
        50.652127
    ],
    [
        83.2504462,
        50.6507393
    ],
    [
        83.2562397,
        50.650059
    ],
    [
        83.2609175,
        50.648576
    ],
    [
        83.2662605,
        50.6497733
    ],
    [
        83.2715605,
        50.6509026
    ],
    [
        83.273835,
        50.6509842
    ],
    [
        83.2772254,
        50.6504944
    ],
    [
        83.2824611,
        50.6508755
    ],
    [
        83.2865595,
        50.652195
    ],
    [
        83.2970523,
        50.6565893
    ],
    [
        83.3034037,
        50.6537051
    ],
    [
        83.3073948,
        50.6526984
    ],
    [
        83.3106564,
        50.6512835
    ],
    [
        83.316214,
        50.647644
    ],
    [
        83.3175873,
        50.6471338
    ],
    [
        83.3191966,
        50.6467664
    ],
    [
        83.3201515,
        50.6463446
    ],
    [
        83.3223616,
        50.6453786
    ],
    [
        83.3236812,
        50.6452425
    ],
    [
        83.3260738,
        50.6460861
    ],
    [
        83.3281444,
        50.6460521
    ],
    [
        83.3303009,
        50.6450316
    ],
    [
        83.331524,
        50.6440927
    ],
    [
        83.3320819,
        50.6442832
    ],
    [
        83.3328437,
        50.645399
    ],
    [
        83.3338415,
        50.6463514
    ],
    [
        83.333187,
        50.6469501
    ],
    [
        83.3333265,
        50.6474944
    ],
    [
        83.3341955,
        50.647576
    ],
    [
        83.3347212,
        50.6471882
    ],
    [
        83.3357459,
        50.6474162
    ],
    [
        83.3361697,
        50.6473685
    ],
    [
        83.3362716,
        50.647076
    ],
    [
        83.3361161,
        50.646712
    ],
    [
        83.3357298,
        50.6462086
    ],
    [
        83.3346891,
        50.645916
    ],
    [
        83.3338523,
        50.6452901
    ],
    [
        83.3334982,
        50.6449023
    ],
    [
        83.3337879,
        50.6444329
    ],
    [
        83.3340776,
        50.6438002
    ],
    [
        83.3337986,
        50.6428068
    ],
    [
        83.3333157,
        50.6423443
    ],
    [
        83.3334339,
        50.6414393
    ],
    [
        83.333863,
        50.6407793
    ],
    [
        83.3354509,
        50.6392279
    ],
    [
        83.3363306,
        50.6387108
    ],
    [
        83.336792,
        50.6387108
    ],
    [
        83.3370495,
        50.6388401
    ],
    [
        83.3374357,
        50.6389081
    ],
    [
        83.3381545,
        50.6388945
    ],
    [
        83.3394849,
        50.6385883
    ],
    [
        83.3400965,
        50.6379351
    ],
    [
        83.3406007,
        50.6377174
    ],
    [
        83.3410728,
        50.6371526
    ],
    [
        83.3410942,
        50.6369348
    ],
    [
        83.3406973,
        50.6367851
    ],
    [
        83.3401286,
        50.6367511
    ],
    [
        83.3396566,
        50.6363224
    ],
    [
        83.3398175,
        50.6358937
    ],
    [
        83.3401394,
        50.635676
    ],
    [
        83.3405685,
        50.6357917
    ],
    [
        83.3409977,
        50.6356964
    ],
    [
        83.3416629,
        50.6352201
    ],
    [
        83.3417916,
        50.6348322
    ],
    [
        83.3415769,
        50.6344172
    ],
    [
        83.340193,
        50.633165
    ],
    [
        83.3400214,
        50.6326273
    ],
    [
        83.3402145,
        50.6324844
    ],
    [
        83.3408046,
        50.6324504
    ],
    [
        83.3409548,
        50.632151
    ],
    [
        83.340987,
        50.6317767
    ],
    [
        83.3406436,
        50.6313139
    ],
    [
        83.3395386,
        50.6304224
    ],
    [
        83.3392811,
        50.629878
    ],
    [
        83.3390772,
        50.6290885
    ],
    [
        83.3382189,
        50.6282446
    ],
    [
        83.3380794,
        50.6277682
    ],
    [
        83.3382188,
        50.6271626
    ],
    [
        83.3371138,
        50.6260122
    ],
    [
        83.3365559,
        50.6258625
    ],
    [
        83.3362555,
        50.6256311
    ],
    [
        83.3362985,
        50.6252976
    ],
    [
        83.3366525,
        50.625107
    ],
    [
        83.3372962,
        50.6250049
    ],
    [
        83.3372426,
        50.6246238
    ],
    [
        83.3369851,
        50.6240792
    ],
    [
        83.3374143,
        50.6238002
    ],
    [
        83.3378649,
        50.6237934
    ],
    [
        83.3383691,
        50.623664
    ],
    [
        83.3384013,
        50.6232761
    ],
    [
        83.3379292,
        50.6228405
    ],
    [
        83.3373928,
        50.6225205
    ],
    [
        83.3377468,
        50.6220917
    ],
    [
        83.338294,
        50.6218875
    ],
    [
        83.3389056,
        50.6220781
    ],
    [
        83.3393025,
        50.6218263
    ],
    [
        83.3392811,
        50.6215472
    ],
    [
        83.338691,
        50.6212273
    ],
    [
        83.3385421,
        50.6205796
    ],
    [
        83.3391952,
        50.6196957
    ],
    [
        83.341074,
        50.6186752
    ],
    [
        83.3428929,
        50.6161699
    ],
    [
        83.3451819,
        50.615339
    ],
    [
        83.34722,
        50.61529
    ],
    [
        83.3474779,
        50.6155058
    ],
    [
        83.347832,
        50.6155126
    ],
    [
        83.3478856,
        50.6156726
    ],
    [
        83.3476764,
        50.6159346
    ],
    [
        83.3484006,
        50.6160061
    ],
    [
        83.3488297,
        50.6159687
    ],
    [
        83.348996,
        50.6160946
    ],
    [
        83.3493018,
        50.6166256
    ],
    [
        83.3496183,
        50.6170715
    ],
    [
        83.3504391,
        50.6171634
    ],
    [
        83.3513242,
        50.6169898
    ],
    [
        83.3517264,
        50.617218
    ],
    [
        83.3516191,
        50.6179668
    ],
    [
        83.3527349,
        50.6184297
    ],
    [
        83.3537005,
        50.6184977
    ],
    [
        83.3541726,
        50.6191921
    ],
    [
        83.3561896,
        50.6195733
    ],
    [
        83.3567904,
        50.6201587
    ],
    [
        83.3573054,
        50.6204718
    ],
    [
        83.3575522,
        50.6218944
    ],
    [
        83.3573805,
        50.6223027
    ],
    [
        83.3578526,
        50.622575
    ],
    [
        83.3586894,
        50.6228949
    ],
    [
        83.3590649,
        50.6229562
    ],
    [
        83.3603524,
        50.6237458
    ],
    [
        83.3604275,
        50.6240112
    ],
    [
        83.3602344,
        50.6242494
    ],
    [
        83.3611142,
        50.6246101
    ],
    [
        83.361597,
        50.6250731
    ],
    [
        83.3620797,
        50.6252909
    ],
    [
        83.3621548,
        50.6260736
    ],
    [
        83.3624874,
        50.6265568
    ],
    [
        83.3623158,
        50.6268359
    ],
    [
        83.3626484,
        50.6270196
    ],
    [
        83.3634316,
        50.627006
    ],
    [
        83.3642684,
        50.6272442
    ],
    [
        83.3658134,
        50.6274756
    ],
    [
        83.3661567,
        50.6277479
    ],
    [
        83.3665,
        50.6277887
    ],
    [
        83.3666824,
        50.6275777
    ],
    [
        83.3671867,
        50.6275505
    ],
    [
        83.3679913,
        50.6278159
    ],
    [
        83.3683239,
        50.6277274
    ],
    [
        83.3684741,
        50.6275097
    ],
    [
        83.3687209,
        50.6275233
    ],
    [
        83.369193,
        50.627639
    ],
    [
        83.3698796,
        50.6275437
    ],
    [
        83.370062,
        50.6273463
    ],
    [
        83.3723151,
        50.6271745
    ],
    [
        83.3735141,
        50.6269618
    ],
    [
        83.3742543,
        50.6267389
    ],
    [
        83.3761265,
        50.6265636
    ],
    [
        83.3768265,
        50.6262863
    ],
    [
        83.3771404,
        50.6259834
    ],
    [
        83.3772423,
        50.62582
    ],
    [
        83.3773657,
        50.6257197
    ],
    [
        83.3780443,
        50.6255988
    ],
    [
        83.378122,
        50.6254917
    ],
    [
        83.378165,
        50.6251905
    ],
    [
        83.3782588,
        50.6250969
    ],
    [
        83.3787765,
        50.6247889
    ],
    [
        83.3789213,
        50.6244843
    ],
    [
        83.3786022,
        50.6241763
    ],
    [
        83.3786022,
        50.62395
    ],
    [
        83.3788811,
        50.623562
    ],
    [
        83.3789187,
        50.6233919
    ],
    [
        83.3787416,
        50.6231809
    ],
    [
        83.3787524,
        50.622844
    ],
    [
        83.3789991,
        50.6223913
    ],
    [
        83.3797716,
        50.6219999
    ],
    [
        83.38019,
        50.6215541
    ],
    [
        83.3806084,
        50.6201178
    ],
    [
        83.3813165,
        50.6194644
    ],
    [
        83.3831402,
        50.6189744
    ],
    [
        83.3853291,
        50.6185386
    ],
    [
        83.3873461,
        50.6185658
    ],
    [
        83.3887194,
        50.6187428
    ],
    [
        83.3898996,
        50.6189334
    ],
    [
        83.3908652,
        50.6187292
    ],
    [
        83.3920883,
        50.6192193
    ],
    [
        83.393483,
        50.6198592
    ],
    [
        83.394792,
        50.6199681
    ],
    [
        83.3975385,
        50.6197503
    ],
    [
        83.4003924,
        50.6192329
    ],
    [
        83.4025382,
        50.6192601
    ],
    [
        83.404448,
        50.6198047
    ],
    [
        83.407173,
        50.621452
    ],
    [
        83.4101986,
        50.6225275
    ],
    [
        83.4126448,
        50.6227589
    ],
    [
        83.4141253,
        50.6224185
    ],
    [
        83.4148763,
        50.6223233
    ],
    [
        83.4154128,
        50.6220374
    ],
    [
        83.4160994,
        50.6213431
    ],
    [
        83.417816,
        50.6206896
    ],
    [
        83.4189318,
        50.6194644
    ],
    [
        83.4205197,
        50.618593
    ],
    [
        83.4225583,
        50.6182663
    ],
    [
        83.4265493,
        50.6183888
    ],
    [
        83.4283088,
        50.6175583
    ],
    [
        83.4335231,
        50.6162785
    ],
    [
        83.4362053,
        50.6163466
    ],
    [
        83.4381365,
        50.6170001
    ],
    [
        83.4393595,
        50.6179395
    ],
    [
        83.4408401,
        50.6183616
    ],
    [
        83.4435009,
        50.6178374
    ],
    [
        83.4455286,
        50.6171635
    ],
    [
        83.4477066,
        50.6173881
    ],
    [
        83.4489404,
        50.6181506
    ],
    [
        83.4505926,
        50.61877
    ],
    [
        83.4511077,
        50.6190627
    ],
    [
        83.4591435,
        50.6158429
    ],
    [
        83.4652804,
        50.6138005
    ],
    [
        83.4687136,
        50.6121121
    ],
    [
        83.4709452,
        50.6122006
    ],
    [
        83.4722971,
        50.6127248
    ],
    [
        83.4734236,
        50.6132763
    ],
    [
        83.4751724,
        50.6131606
    ],
    [
        83.4764706,
        50.6127248
    ],
    [
        83.477168,
        50.6129291
    ],
    [
        83.4796356,
        50.6124321
    ],
    [
        83.4815346,
        50.6123776
    ],
    [
        83.4838735,
        50.6112066
    ],
    [
        83.4850859,
        50.611193
    ],
    [
        83.4861802,
        50.6105326
    ],
    [
        83.4869419,
        50.6104509
    ],
    [
        83.4888624,
        50.6094228
    ],
    [
        83.4891843,
        50.6091096
    ],
    [
        83.492993,
        50.6085241
    ],
    [
        83.4939479,
        50.6092458
    ],
    [
        83.4954499,
        50.6092322
    ],
    [
        83.4965657,
        50.6089122
    ],
    [
        83.4989046,
        50.6091709
    ],
    [
        83.5005354,
        50.6087079
    ],
    [
        83.5024237,
        50.6074551
    ],
    [
        83.5045801,
        50.6067878
    ],
    [
        83.5055136,
        50.6065836
    ],
    [
        83.5056638,
        50.606781
    ],
    [
        83.5060715,
        50.606924
    ],
    [
        83.5065435,
        50.6068763
    ],
    [
        83.5069727,
        50.6069512
    ],
    [
        83.5070371,
        50.6066789
    ],
    [
        83.5066723,
        50.606461
    ],
    [
        83.5064684,
        50.6062499
    ],
    [
        83.5065435,
        50.6060524
    ],
    [
        83.5069512,
        50.6062431
    ],
    [
        83.5075735,
        50.6064201
    ],
    [
        83.5081958,
        50.6051332
    ],
    [
        83.5081099,
        50.6047996
    ],
    [
        83.507595,
        50.6044523
    ],
    [
        83.5077988,
        50.6041867
    ],
    [
        83.5084103,
        50.6043297
    ],
    [
        83.5084962,
        50.6039688
    ],
    [
        83.5090219,
        50.6038258
    ],
    [
        83.5096978,
        50.6025184
    ],
    [
        83.5105347,
        50.6024299
    ],
    [
        83.5112106,
        50.6020486
    ],
    [
        83.5118758,
        50.6021167
    ],
    [
        83.5122513,
        50.6018987
    ],
    [
        83.5125624,
        50.6019668
    ],
    [
        83.5126053,
        50.6022052
    ],
    [
        83.5128092,
        50.6022324
    ],
    [
        83.5132276,
        50.6021167
    ],
    [
        83.5138606,
        50.6014697
    ],
    [
        83.5139786,
        50.6009318
    ],
    [
        83.5147725,
        50.6009454
    ],
    [
        83.5152875,
        50.6007002
    ],
    [
        83.5158025,
        50.5997605
    ],
    [
        83.5164248,
        50.5994472
    ],
    [
        83.5175084,
        50.5992293
    ],
    [
        83.5181843,
        50.5988956
    ],
    [
        83.5183346,
        50.5984325
    ],
    [
        83.5193859,
        50.5977106
    ],
    [
        83.5207163,
        50.5973633
    ],
    [
        83.5221969,
        50.5969479
    ],
    [
        83.5228621,
        50.5972952
    ],
    [
        83.5234522,
        50.597445
    ],
    [
        83.5236453,
        50.5973224
    ],
    [
        83.5242246,
        50.5974586
    ],
    [
        83.5246431,
        50.5973497
    ],
    [
        83.5252761,
        50.5968593
    ],
    [
        83.5256087,
        50.5972339
    ],
    [
        83.5265206,
        50.5971181
    ],
    [
        83.527218,
        50.5966278
    ],
    [
        83.5276579,
        50.5967027
    ],
    [
        83.5279368,
        50.595756
    ],
    [
        83.5292565,
        50.5959876
    ],
    [
        83.529471,
        50.595436
    ],
    [
        83.531016,
        50.5951499
    ],
    [
        83.5314773,
        50.5949524
    ],
    [
        83.5314773,
        50.5947208
    ],
    [
        83.5328077,
        50.594176
    ],
    [
        83.5333227,
        50.5942032
    ],
    [
        83.5338806,
        50.5939172
    ],
    [
        83.5339879,
        50.5936311
    ],
    [
        83.5345136,
        50.5936652
    ],
    [
        83.5348784,
        50.5935765
    ],
    [
        83.5355758,
        50.5928069
    ],
    [
        83.5371499,
        50.5924853
    ],
    [
        83.538612,
        50.5929977
    ],
    [
        83.5392848,
        50.593039
    ],
    [
        83.5405003,
        50.5926299
    ],
    [
        83.5408007,
        50.5921668
    ],
    [
        83.5411762,
        50.5921736
    ],
    [
        83.541541,
        50.5920237
    ],
    [
        83.5425173,
        50.5920304
    ],
    [
        83.5431396,
        50.591833
    ],
    [
        83.544073,
        50.5918943
    ],
    [
        83.5459935,
        50.5925346
    ],
    [
        83.5466372,
        50.5934064
    ],
    [
        83.5475813,
        50.5932974
    ],
    [
        83.5486435,
        50.5936379
    ],
    [
        83.5493623,
        50.5934608
    ],
    [
        83.5507141,
        50.5936379
    ],
    [
        83.5515403,
        50.5939308
    ],
    [
        83.5526346,
        50.5940466
    ],
    [
        83.5529994,
        50.5939785
    ],
    [
        83.5529457,
        50.5936243
    ],
    [
        83.5530852,
        50.5934745
    ],
    [
        83.5540723,
        50.5936447
    ],
    [
        83.5546731,
        50.5936652
    ],
    [
        83.5550164,
        50.5935834
    ],
    [
        83.5557889,
        50.5936311
    ],
    [
        83.5564755,
        50.5932701
    ],
    [
        83.5566686,
        50.5928887
    ],
    [
        83.55713,
        50.5927321
    ],
    [
        83.557999,
        50.5928342
    ],
    [
        83.5584389,
        50.5928206
    ],
    [
        83.55875,
        50.5925482
    ],
    [
        83.5599409,
        50.5923437
    ],
    [
        83.5607563,
        50.5925414
    ],
    [
        83.5616361,
        50.5924869
    ],
    [
        83.5623549,
        50.5927457
    ],
    [
        83.5630523,
        50.5927661
    ],
    [
        83.5642322,
        50.592542
    ],
    [
        83.564726,
        50.5921668
    ],
    [
        83.5656916,
        50.5916695
    ],
    [
        83.5661851,
        50.59122
    ],
    [
        83.5662924,
        50.5909271
    ],
    [
        83.5666357,
        50.5907773
    ],
    [
        83.5672902,
        50.5907228
    ],
    [
        83.5680519,
        50.5904027
    ],
    [
        83.5685991,
        50.5901983
    ],
    [
        83.5688351,
        50.5900417
    ],
    [
        83.5690497,
        50.5897352
    ],
    [
        83.5692536,
        50.5896807
    ],
    [
        83.5700582,
        50.5897215
    ],
    [
        83.5707127,
        50.5895376
    ],
    [
        83.572322,
        50.5893537
    ],
    [
        83.5728585,
        50.5894423
    ],
    [
        83.5731589,
        50.5896057
    ],
    [
        83.5734056,
        50.5895921
    ],
    [
        83.5738133,
        50.5893878
    ],
    [
        83.5748111,
        50.5892856
    ],
    [
        83.5752939,
        50.5893401
    ],
    [
        83.5761522,
        50.5891902
    ],
    [
        83.5764312,
        50.5890268
    ],
    [
        83.5767852,
        50.5891494
    ],
    [
        83.5770749,
        50.5889723
    ],
    [
        83.5777508,
        50.5889246
    ],
    [
        83.5785018,
        50.5887815
    ],
    [
        83.5794889,
        50.5885023
    ],
    [
        83.5804974,
        50.5885023
    ],
    [
        83.5808085,
        50.5882162
    ],
    [
        83.5815917,
        50.5882298
    ],
    [
        83.5817098,
        50.588005
    ],
    [
        83.5829221,
        50.5876031
    ],
    [
        83.5835873,
        50.5872966
    ],
    [
        83.5858404,
        50.5863021
    ],
    [
        83.5864626,
        50.5861454
    ],
    [
        83.5868703,
        50.5856686
    ],
    [
        83.5868274,
        50.585001
    ],
    [
        83.587042,
        50.5846944
    ],
    [
        83.5874175,
        50.5843266
    ],
    [
        83.5876643,
        50.5838429
    ],
    [
        83.5880076,
        50.5834682
    ],
    [
        83.5884045,
        50.5832707
    ],
    [
        83.5892843,
        50.5832707
    ],
    [
        83.5901212,
        50.5831957
    ],
    [
        83.5904645,
        50.5830323
    ],
    [
        83.591076,
        50.5830186
    ],
    [
        83.5913013,
        50.5831549
    ],
    [
        83.5915374,
        50.58355
    ],
    [
        83.5921811,
        50.5837135
    ],
    [
        83.5946273,
        50.5841972
    ],
    [
        83.5952174,
        50.5842448
    ],
    [
        83.5962902,
        50.5841699
    ],
    [
        83.5971915,
        50.584095
    ],
    [
        83.5978353,
        50.583802
    ],
    [
        83.5994982,
        50.5838089
    ],
    [
        83.5998522,
        50.5835977
    ],
    [
        83.6005389,
        50.5835977
    ],
    [
        83.6014615,
        50.5835159
    ],
    [
        83.601601,
        50.5836862
    ],
    [
        83.6018692,
        50.5841018
    ],
    [
        83.6021348,
        50.5850691
    ],
    [
        83.6025183,
        50.5855459
    ],
    [
        83.6031755,
        50.5858763
    ],
    [
        83.6035751,
        50.586268
    ],
    [
        83.6035215,
        50.5864383
    ],
    [
        83.6026417,
        50.5870037
    ],
    [
        83.6022018,
        50.5875282
    ],
    [
        83.6022662,
        50.5879233
    ],
    [
        83.6031352,
        50.5879914
    ],
    [
        83.6036609,
        50.5882434
    ],
    [
        83.6039506,
        50.5887952
    ],
    [
        83.6043905,
        50.5888769
    ],
    [
        83.6049913,
        50.5890744
    ],
    [
        83.6055278,
        50.5892992
    ],
    [
        83.6056243,
        50.5897215
    ],
    [
        83.6054849,
        50.5901711
    ],
    [
        83.6053883,
        50.5904367
    ],
    [
        83.6056672,
        50.5907024
    ],
    [
        83.6061822,
        50.5908863
    ],
    [
        83.6063432,
        50.5911519
    ],
    [
        83.6062144,
        50.5915538
    ],
    [
        83.6058925,
        50.5919624
    ],
    [
        83.6055626,
        50.5921855
    ],
    [
        83.6054902,
        50.5924784
    ],
    [
        83.6058818,
        50.5927219
    ],
    [
        83.6060025,
        50.5929075
    ],
    [
        83.6059435,
        50.5932173
    ],
    [
        83.6065041,
        50.5936345
    ],
    [
        83.6065819,
        50.5939887
    ],
    [
        83.6065631,
        50.5943139
    ],
    [
        83.6067589,
        50.5945574
    ],
    [
        83.6074482,
        50.5950477
    ],
    [
        83.6097043,
        50.5937857
    ],
    [
        83.6118704,
        50.593189
    ],
    [
        83.6132107,
        50.5929709
    ],
    [
        83.6157102,
        50.5931934
    ],
    [
        83.616248,
        50.5937834
    ],
    [
        83.6161375,
        50.5951437
    ],
    [
        83.616543,
        50.5962825
    ],
    [
        83.6176609,
        50.5966338
    ],
    [
        83.619243,
        50.5966456
    ],
    [
        83.6223938,
        50.5958843
    ],
    [
        83.6237559,
        50.5946791
    ],
    [
        83.6257513,
        50.5938486
    ],
    [
        83.6295066,
        50.5936161
    ],
    [
        83.6332034,
        50.5948165
    ],
    [
        83.6371389,
        50.5958083
    ],
    [
        83.6408084,
        50.5957995
    ],
    [
        83.6434748,
        50.5954371
    ],
    [
        83.6443332,
        50.594994
    ],
    [
        83.6466894,
        50.5934525
    ],
    [
        83.647279,
        50.5927934
    ],
    [
        83.6488724,
        50.5925482
    ],
    [
        83.6557686,
        50.6119327
    ],
    [
        83.7035893,
        50.6162241
    ],
    [
        83.708589,
        50.6164011
    ],
    [
        83.7198971,
        50.617463
    ],
    [
        83.7272143,
        50.619546
    ],
    [
        83.7349604,
        50.6234804
    ],
    [
        83.745389,
        50.6376494
    ],
    [
        83.7536072,
        50.6403303
    ],
    [
        83.761053,
        50.6405072
    ],
    [
        83.7809442,
        50.6365471
    ],
    [
        83.7945698,
        50.639228
    ],
    [
        83.8086462,
        50.6426845
    ],
    [
        83.822379,
        50.6446983
    ],
    [
        83.8319491,
        50.6531066
    ],
    [
        83.8373564,
        50.6534332
    ],
    [
        83.8375281,
        50.6574599
    ],
    [
        83.8549088,
        50.6596635
    ],
    [
        83.8627194,
        50.6615407
    ],
    [
        83.8760231,
        50.6709251
    ],
    [
        83.8834047,
        50.6779962
    ],
    [
        83.8940047,
        50.6747872
    ],
    [
        83.9043473,
        50.6745152
    ],
    [
        83.9148615,
        50.6813954
    ],
    [
        83.9525413,
        50.6879347
    ],
    [
        83.9756512,
        50.6722714
    ],
    [
        83.9897274,
        50.671809
    ],
    [
        84.0031813,
        50.6700411
    ],
    [
        84.0345525,
        50.6755214
    ],
    [
        84.0410213,
        50.6769688
    ],
    [
        84.0447754,
        50.6762481
    ],
    [
        84.0460393,
        50.674113
    ],
    [
        84.048622,
        50.6732866
    ],
    [
        84.0494013,
        50.6728834
    ],
    [
        84.0500733,
        50.6719954
    ],
    [
        84.0495592,
        50.6692302
    ],
    [
        84.0518101,
        50.6670278
    ],
    [
        84.0551841,
        50.6664167
    ],
    [
        84.0553766,
        50.6651219
    ],
    [
        84.0577963,
        50.6626821
    ],
    [
        84.0628614,
        50.6614582
    ],
    [
        84.0633462,
        50.6609834
    ],
    [
        84.0648001,
        50.6604551
    ],
    [
        84.0656512,
        50.6604551
    ],
    [
        84.0687561,
        50.6596771
    ],
    [
        84.0716653,
        50.6591169
    ],
    [
        84.0709556,
        50.6580601
    ],
    [
        84.0705858,
        50.6555683
    ],
    [
        84.0708929,
        50.6531281
    ],
    [
        84.0742063,
        50.651787
    ],
    [
        84.0743034,
        50.6506763
    ],
    [
        84.0737892,
        50.6488045
    ],
    [
        84.074114,
        50.646451
    ],
    [
        84.0736484,
        50.6452542
    ],
    [
        84.0739046,
        50.6439219
    ],
    [
        84.0754969,
        50.6413459
    ],
    [
        84.077553,
        50.6403123
    ],
    [
        84.0812913,
        50.6399929
    ],
    [
        84.0812665,
        50.6387651
    ],
    [
        84.0802137,
        50.6370822
    ],
    [
        84.0802771,
        50.6360297
    ],
    [
        84.0807346,
        50.6348461
    ],
    [
        84.0826229,
        50.6336146
    ],
    [
        84.0859963,
        50.6326759
    ],
    [
        84.0906351,
        50.6320397
    ],
    [
        84.0940578,
        50.6311125
    ],
    [
        84.0944494,
        50.6297134
    ],
    [
        84.095515,
        50.628099
    ],
    [
        84.0992261,
        50.6259675
    ],
    [
        84.1038273,
        50.6239791
    ],
    [
        84.107825,
        50.6230903
    ],
    [
        84.1092555,
        50.61941
    ],
    [
        84.1124654,
        50.6173405
    ],
    [
        84.1138527,
        50.6151274
    ],
    [
        84.1134857,
        50.6128961
    ],
    [
        84.1135744,
        50.6113322
    ],
    [
        84.1152943,
        50.6091944
    ],
    [
        84.1184858,
        50.6081479
    ],
    [
        84.1222092,
        50.6079454
    ],
    [
        84.1280479,
        50.6068805
    ],
    [
        84.1287839,
        50.6070942
    ],
    [
        84.1296208,
        50.6074755
    ],
    [
        84.1311334,
        50.6084043
    ],
    [
        84.1325125,
        50.6068087
    ],
    [
        84.1354026,
        50.6056046
    ],
    [
        84.137548,
        50.6052333
    ],
    [
        84.1428041,
        50.6061654
    ],
    [
        84.1439734,
        50.6062389
    ],
    [
        84.144622,
        50.6045741
    ],
    [
        84.145274,
        50.6028394
    ],
    [
        84.1477814,
        50.6009455
    ],
    [
        84.1501886,
        50.5984626
    ],
    [
        84.1516429,
        50.5950564
    ],
    [
        84.1519062,
        50.5925779
    ],
    [
        84.1547648,
        50.588837
    ],
    [
        84.156031,
        50.5877385
    ],
    [
        84.156871,
        50.5873405
    ],
    [
        84.156031,
        50.5856371
    ],
    [
        84.1558206,
        50.5841151
    ],
    [
        84.1539624,
        50.5827338
    ],
    [
        84.1529092,
        50.5817427
    ],
    [
        84.152921,
        50.5802099
    ],
    [
        84.1517989,
        50.5753056
    ],
    [
        84.1517487,
        50.5739441
    ],
    [
        84.1534462,
        50.5720809
    ],
    [
        84.1592678,
        50.5714608
    ],
    [
        84.1600302,
        50.570605
    ],
    [
        84.1612005,
        50.5702108
    ],
    [
        84.1625493,
        50.5703135
    ],
    [
        84.1652298,
        50.5701551
    ],
    [
        84.1673904,
        50.5699771
    ],
    [
        84.1668552,
        50.5674962
    ],
    [
        84.1682559,
        50.565638
    ],
    [
        84.1710162,
        50.5637455
    ],
    [
        84.1720818,
        50.563411
    ],
    [
        84.1697123,
        50.5598589
    ],
    [
        84.1695709,
        50.5589244
    ],
    [
        84.1700257,
        50.5567367
    ],
    [
        84.1687845,
        50.5544586
    ],
    [
        84.1685839,
        50.5532319
    ],
    [
        84.1691364,
        50.5517446
    ],
    [
        84.1709449,
        50.5505308
    ],
    [
        84.1761508,
        50.5516105
    ],
    [
        84.1804273,
        50.548982
    ],
    [
        84.1901675,
        50.5454922
    ],
    [
        84.1967257,
        50.5416217
    ],
    [
        84.2087203,
        50.5435516
    ],
    [
        84.213016,
        50.5437221
    ],
    [
        84.2156352,
        50.5433263
    ],
    [
        84.2186574,
        50.5415774
    ],
    [
        84.2229956,
        50.5405992
    ],
    [
        84.2305773,
        50.5402423
    ],
    [
        84.2320713,
        50.5355949
    ],
    [
        84.2346301,
        50.5322224
    ],
    [
        84.2382417,
        50.5295933
    ],
    [
        84.2419136,
        50.5237414
    ],
    [
        84.2421382,
        50.5198901
    ],
    [
        84.2432417,
        50.5176226
    ],
    [
        84.2487069,
        50.5155657
    ],
    [
        84.2438998,
        50.5106791
    ],
    [
        84.2383101,
        50.5082815
    ],
    [
        84.2353063,
        50.5042146
    ],
    [
        84.2375128,
        50.5009828
    ],
    [
        84.2352078,
        50.49637
    ],
    [
        84.2344986,
        50.4933923
    ],
    [
        84.2356185,
        50.4897509
    ],
    [
        84.2334137,
        50.4865334
    ],
    [
        84.2325711,
        50.4830655
    ],
    [
        84.2300152,
        50.4811671
    ],
    [
        84.2188308,
        50.4762889
    ],
    [
        84.2125876,
        50.4735732
    ],
    [
        84.2089086,
        50.4727408
    ],
    [
        84.2045909,
        50.4703535
    ],
    [
        84.2039747,
        50.4689746
    ],
    [
        84.2040314,
        50.4676535
    ],
    [
        84.2045571,
        50.465313
    ],
    [
        84.2065435,
        50.4541438
    ],
    [
        84.2117553,
        50.4456801
    ],
    [
        84.2150463,
        50.4387252
    ],
    [
        84.2164508,
        50.4342309
    ],
    [
        84.2267093,
        50.4307652
    ],
    [
        84.2283682,
        50.4259772
    ],
    [
        84.2357557,
        50.4145156
    ],
    [
        84.2383437,
        50.4124773
    ],
    [
        84.2398065,
        50.410155
    ],
    [
        84.2419879,
        50.4086615
    ],
    [
        84.2433309,
        50.4030801
    ],
    [
        84.2447691,
        50.3988153
    ],
    [
        84.2447489,
        50.3969514
    ],
    [
        84.245728,
        50.3927506
    ],
    [
        84.2416643,
        50.390669
    ],
    [
        84.2396862,
        50.3875249
    ],
    [
        84.2420265,
        50.3768546
    ],
    [
        84.2493409,
        50.3708774
    ],
    [
        84.2550939,
        50.3695448
    ],
    [
        84.2604326,
        50.3661125
    ],
    [
        84.2621957,
        50.3646262
    ],
    [
        84.2642151,
        50.3613601
    ],
    [
        84.2682897,
        50.3565842
    ],
    [
        84.2695591,
        50.3537549
    ],
    [
        84.2690978,
        50.3518764
    ],
    [
        84.2661971,
        50.3506259
    ],
    [
        84.2659785,
        50.3483399
    ],
    [
        84.2690733,
        50.3413522
    ],
    [
        84.2695448,
        50.3383385
    ],
    [
        84.2684821,
        50.3367524
    ],
    [
        84.2660479,
        50.3352562
    ],
    [
        84.2660265,
        50.3325995
    ],
    [
        84.2644118,
        50.3307742
    ],
    [
        84.262238,
        50.3280283
    ],
    [
        84.2617622,
        50.3255682
    ],
    [
        84.2629635,
        50.3238977
    ],
    [
        84.2659458,
        50.3227257
    ],
    [
        84.2693185,
        50.3229141
    ],
    [
        84.2720293,
        50.3206593
    ],
    [
        84.2707698,
        50.318923
    ],
    [
        84.2664349,
        50.3173484
    ],
    [
        84.2632161,
        50.3144311
    ],
    [
        84.2622297,
        50.3101543
    ],
    [
        84.2591285,
        50.3093235
    ],
    [
        84.254837,
        50.3089672
    ],
    [
        84.2435073,
        50.3061167
    ],
    [
        84.2358255,
        50.3048834
    ],
    [
        84.229002,
        50.3063634
    ],
    [
        84.2166423,
        50.3109953
    ],
    [
        84.2129516,
        50.3137085
    ],
    [
        84.2058706,
        50.3173532
    ],
    [
        84.2012786,
        50.3178739
    ],
    [
        84.1918373,
        50.3177917
    ],
    [
        84.1832113,
        50.3192714
    ],
    [
        84.1819238,
        50.3186687
    ],
    [
        84.1784477,
        50.3154076
    ],
    [
        84.1762161,
        50.311982
    ],
    [
        84.1787481,
        50.3093509
    ],
    [
        84.18437,
        50.3055959
    ],
    [
        84.188919,
        50.3038417
    ],
    [
        84.1936826,
        50.303595
    ],
    [
        84.1957855,
        50.3033757
    ],
    [
        84.1974592,
        50.3025259
    ],
    [
        84.1977596,
        50.300662
    ],
    [
        84.1946268,
        50.2947405
    ],
    [
        84.1920948,
        50.2904633
    ],
    [
        84.1864299,
        50.2853908
    ],
    [
        84.1809368,
        50.282292
    ],
    [
        84.1733837,
        50.2815241
    ],
    [
        84.1693067,
        50.2809208
    ],
    [
        84.1635561,
        50.2789737
    ],
    [
        84.1621398,
        50.277959
    ],
    [
        84.160037,
        50.2774928
    ],
    [
        84.1563463,
        50.2771637
    ],
    [
        84.1548872,
        50.2759569
    ],
    [
        84.1522264,
        50.2743386
    ],
    [
        84.1488361,
        50.2744758
    ],
    [
        84.1402101,
        50.2760665
    ],
    [
        84.1379785,
        50.2767522
    ],
    [
        84.1370344,
        50.2785897
    ],
    [
        84.1373777,
        50.2822372
    ],
    [
        84.136219,
        50.2840471
    ],
    [
        84.1342878,
        50.2857198
    ],
    [
        84.1308546,
        50.2877489
    ],
    [
        84.1283655,
        50.290957
    ],
    [
        84.1264772,
        50.2930407
    ],
    [
        84.1236877,
        50.2940277
    ],
    [
        84.1155338,
        50.2949324
    ],
    [
        84.1064786,
        50.292931
    ],
    [
        84.1034317,
        50.2913134
    ],
    [
        84.0995264,
        50.2902167
    ],
    [
        84.0872097,
        50.2894215
    ],
    [
        84.0783262,
        50.2891748
    ],
    [
        84.0750217,
        50.2898602
    ],
    [
        84.0690994,
        50.2897231
    ],
    [
        84.0642499,
        50.2898054
    ],
    [
        84.0607309,
        50.2910118
    ],
    [
        84.0564394,
        50.2932326
    ],
    [
        84.0521478,
        50.2950969
    ],
    [
        84.0485,
        50.296358
    ],
    [
        84.0446376,
        50.297345
    ],
    [
        84.0411615,
        50.2969612
    ],
    [
        84.0391874,
        50.2956727
    ],
    [
        84.0374708,
        50.2937535
    ],
    [
        84.03481,
        50.2911763
    ],
    [
        84.0332651,
        50.289449
    ],
    [
        84.0310764,
        50.2883796
    ],
    [
        84.0290594,
        50.2879957
    ],
    [
        84.0265274,
        50.288078
    ],
    [
        84.0252828,
        50.2889828
    ],
    [
        84.0245533,
        50.2902715
    ],
    [
        84.023137,
        50.290107
    ],
    [
        84.0213775,
        50.2881328
    ],
    [
        84.0200901,
        50.2865425
    ],
    [
        84.0192318,
        50.2858295
    ],
    [
        84.0174722,
        50.285473
    ],
    [
        84.0143394,
        50.2855279
    ],
    [
        84.0121936,
        50.2865425
    ],
    [
        84.0099191,
        50.2886264
    ],
    [
        84.0014218,
        50.2943704
    ],
    [
        83.9840196,
        50.2939729
    ],
    [
        83.9838051,
        50.2869126
    ],
    [
        83.9777111,
        50.2780824
    ],
    [
        84.0006493,
        50.2713901
    ],
    [
        83.9998769,
        50.2584416
    ],
    [
        84.0002202,
        50.2433352
    ],
    [
        83.9910578,
        50.2407004
    ],
    [
        83.9676903,
        50.2412356
    ],
    [
        83.9583885,
        50.2348469
    ],
    [
        83.9555991,
        50.2188475
    ],
    [
        83.9407234,
        50.2020358
    ],
    [
        83.9180052,
        50.2017267
    ],
    [
        83.9024449,
        50.2117905
    ],
    [
        83.9013218,
        50.211371
    ],
    [
        83.9011287,
        50.2109247
    ],
    [
        83.8980495,
        50.2096888
    ],
    [
        83.8971268,
        50.2090159
    ],
    [
        83.8941656,
        50.2075877
    ],
    [
        83.8933395,
        50.2074984
    ],
    [
        83.8920521,
        50.2070384
    ],
    [
        83.8902925,
        50.20572
    ],
    [
        83.8887476,
        50.2053629
    ],
    [
        83.8868164,
        50.2051981
    ],
    [
        83.8860332,
        50.2054316
    ],
    [
        83.8852929,
        50.2053629
    ],
    [
        83.8833081,
        50.2058985
    ],
    [
        83.8820421,
        50.2058573
    ],
    [
        83.8816451,
        50.205974
    ],
    [
        83.8816987,
        50.2063723
    ],
    [
        83.8814842,
        50.2066126
    ],
    [
        83.8798534,
        50.2064684
    ],
    [
        83.8782333,
        50.2061869
    ],
    [
        83.8769351,
        50.2063311
    ],
    [
        83.8755082,
        50.2062487
    ],
    [
        83.8745641,
        50.206132
    ],
    [
        83.8740276,
        50.2065646
    ],
    [
        83.8734268,
        50.2065234
    ],
    [
        83.8728582,
        50.2067019
    ],
    [
        83.8724934,
        50.2071963
    ],
    [
        83.8717746,
        50.207725
    ],
    [
        83.8691676,
        50.2084666
    ],
    [
        83.867998,
        50.2090846
    ],
    [
        83.8668608,
        50.2092562
    ],
    [
        83.866378,
        50.2091532
    ],
    [
        83.8651227,
        50.2092906
    ],
    [
        83.8641679,
        50.2091326
    ],
    [
        83.8634919,
        50.2091807
    ],
    [
        83.8626336,
        50.2090228
    ],
    [
        83.8619684,
        50.208652
    ],
    [
        83.861153,
        50.2079654
    ],
    [
        83.8605629,
        50.207828
    ],
    [
        83.8600694,
        50.2073748
    ],
    [
        83.8592326,
        50.2070933
    ],
    [
        83.8583635,
        50.2066813
    ],
    [
        83.857237,
        50.2065852
    ],
    [
        83.8562285,
        50.2062006
    ],
    [
        83.8555418,
        50.2058985
    ],
    [
        83.8548337,
        50.2057406
    ],
    [
        83.8543938,
        50.2054934
    ],
    [
        83.8540934,
        50.2046831
    ],
    [
        83.8536645,
        50.2045022
    ],
    [
        83.8523768,
        50.2042367
    ],
    [
        83.8518833,
        50.2039277
    ],
    [
        83.8515722,
        50.202877
    ],
    [
        83.8512718,
        50.202671
    ],
    [
        83.8503598,
        50.2024444
    ],
    [
        83.8485574,
        50.202671
    ],
    [
        83.8473021,
        50.2027397
    ],
    [
        83.8467656,
        50.2029251
    ],
    [
        83.8464867,
        50.2031654
    ],
    [
        83.8466584,
        50.2034058
    ],
    [
        83.8470661,
        50.2034745
    ],
    [
        83.8474094,
        50.2037423
    ],
    [
        83.8473986,
        50.2039552
    ],
    [
        83.8460683,
        50.2043397
    ],
    [
        83.8448559,
        50.2041268
    ],
    [
        83.843783,
        50.2036461
    ],
    [
        83.8430536,
        50.2034745
    ],
    [
        83.8425063,
        50.2036324
    ],
    [
        83.8419269,
        50.2040994
    ],
    [
        83.841369,
        50.2042024
    ],
    [
        83.8406824,
        50.2040856
    ],
    [
        83.8402532,
        50.2036599
    ],
    [
        83.8400494,
        50.2031654
    ],
    [
        83.8391804,
        50.2024924
    ],
    [
        83.8379573,
        50.2023345
    ],
    [
        83.8368629,
        50.202362
    ],
    [
        83.8358973,
        50.2022246
    ],
    [
        83.8345991,
        50.2014623
    ],
    [
        83.8337408,
        50.2011464
    ],
    [
        83.8332151,
        50.2008374
    ],
    [
        83.8315951,
        50.2004734
    ],
    [
        83.8305115,
        50.2000888
    ],
    [
        83.8299106,
        50.1997111
    ],
    [
        83.8301359,
        50.1989763
    ],
    [
        83.8301252,
        50.198626
    ],
    [
        83.8296853,
        50.1985436
    ],
    [
        83.8283765,
        50.1988458
    ],
    [
        83.8279151,
        50.1992166
    ],
    [
        83.8271962,
        50.199251
    ],
    [
        83.8266705,
        50.198729
    ],
    [
        83.8262414,
        50.1986672
    ],
    [
        83.8250719,
        50.1990175
    ],
    [
        83.8236665,
        50.1989625
    ],
    [
        83.8228618,
        50.1986191
    ],
    [
        83.8223468,
        50.1986878
    ],
    [
        83.8221322,
        50.1990243
    ],
    [
        83.8222181,
        50.1997317
    ],
    [
        83.8224005,
        50.2001438
    ],
    [
        83.8220357,
        50.2009473
    ],
    [
        83.8217246,
        50.2011808
    ],
    [
        83.8211666,
        50.2014967
    ],
    [
        83.8210272,
        50.2017508
    ],
    [
        83.8212739,
        50.2022521
    ],
    [
        83.8214885,
        50.2025337
    ],
    [
        83.8222181,
        50.2028427
    ],
    [
        83.822143,
        50.2032959
    ],
    [
        83.8223255,
        50.2038247
    ],
    [
        83.8217567,
        50.2043191
    ],
    [
        83.820834,
        50.2044564
    ],
    [
        83.8198899,
        50.2042985
    ],
    [
        83.8190316,
        50.2037903
    ],
    [
        83.8183664,
        50.2035363
    ],
    [
        83.8171326,
        50.2031998
    ],
    [
        83.8159417,
        50.2031517
    ],
    [
        83.8148366,
        50.2034333
    ],
    [
        83.8142573,
        50.2038315
    ],
    [
        83.8139569,
        50.2045801
    ],
    [
        83.8139676,
        50.2050401
    ],
    [
        83.8138067,
        50.2055689
    ],
    [
        83.8139998,
        50.2068392
    ],
    [
        83.8146542,
        50.2073062
    ],
    [
        83.8145255,
        50.2081095
    ],
    [
        83.8141285,
        50.2087138
    ],
    [
        83.8130449,
        50.2089404
    ],
    [
        83.8124441,
        50.2102793
    ],
    [
        83.8119077,
        50.2105677
    ],
    [
        83.8110601,
        50.2106569
    ],
    [
        83.8098585,
        50.2110964
    ],
    [
        83.8095151,
        50.211577
    ],
    [
        83.8093435,
        50.2122224
    ],
    [
        83.8094722,
        50.2131973
    ],
    [
        83.8098155,
        50.2140006
    ],
    [
        83.8094829,
        50.2146872
    ],
    [
        83.8098048,
        50.2152501
    ],
    [
        83.8096984,
        50.215464
    ],
    [
        83.8096546,
        50.2155522
    ],
    [
        83.8090645,
        50.2155728
    ],
    [
        83.8075839,
        50.2148245
    ],
    [
        83.8065433,
        50.215154
    ],
    [
        83.8060901,
        50.2153847
    ],
    [
        83.8055992,
        50.2156346
    ],
    [
        83.8049554,
        50.2152913
    ],
    [
        83.804773,
        50.2150442
    ],
    [
        83.8048588,
        50.2147146
    ],
    [
        83.8046335,
        50.2143851
    ],
    [
        83.8039683,
        50.2141997
    ],
    [
        83.8034104,
        50.2139251
    ],
    [
        83.8025521,
        50.2138702
    ],
    [
        83.801608,
        50.214083
    ],
    [
        83.8011574,
        50.2140487
    ],
    [
        83.8005029,
        50.2138152
    ],
    [
        83.8002025,
        50.2137809
    ],
    [
        83.7996339,
        50.2138015
    ],
    [
        83.7992584,
        50.2136985
    ],
    [
        83.7984322,
        50.212957
    ],
    [
        83.7969946,
        50.2128746
    ],
    [
        83.7965547,
        50.2127167
    ],
    [
        83.7958788,
        50.2128403
    ],
    [
        83.7954604,
        50.2131836
    ],
    [
        83.7945591,
        50.2133278
    ],
    [
        83.7933253,
        50.2138358
    ],
    [
        83.7929927,
        50.2140899
    ],
    [
        83.7917804,
        50.2145499
    ],
    [
        83.7901281,
        50.2144606
    ],
    [
        83.7893342,
        50.2147215
    ],
    [
        83.7884973,
        50.2147421
    ],
    [
        83.7879073,
        50.2150716
    ],
    [
        83.7869738,
        50.2153394
    ],
    [
        83.7860833,
        50.2153257
    ],
    [
        83.7845277,
        50.2155316
    ],
    [
        83.7837015,
        50.2157444
    ],
    [
        83.782854,
        50.2161976
    ],
    [
        83.7818669,
        50.2161976
    ],
    [
        83.7795173,
        50.2160053
    ],
    [
        83.7782298,
        50.2156689
    ],
    [
        83.7752687,
        50.2144812
    ],
    [
        83.7749468,
        50.2140967
    ],
    [
        83.7740456,
        50.2137123
    ],
    [
        83.7734448,
        50.2131149
    ],
    [
        83.7729513,
        50.2105608
    ],
    [
        83.7733482,
        50.2090296
    ],
    [
        83.7731658,
        50.2083842
    ],
    [
        83.7725865,
        50.2081233
    ],
    [
        83.7719749,
        50.2081439
    ],
    [
        83.769636,
        50.2086795
    ],
    [
        83.7691211,
        50.2091601
    ],
    [
        83.7675332,
        50.2097918
    ],
    [
        83.7648724,
        50.2096614
    ],
    [
        83.7645291,
        50.2095172
    ],
    [
        83.7645184,
        50.2092356
    ],
    [
        83.7647866,
        50.2085765
    ],
    [
        83.7633704,
        50.2080066
    ],
    [
        83.7632417,
        50.2074916
    ],
    [
        83.7620186,
        50.2067362
    ],
    [
        83.7612246,
        50.2064753
    ],
    [
        83.7608599,
        50.2063242
    ],
    [
        83.7605487,
        50.2059534
    ],
    [
        83.7590252,
        50.2053285
    ],
    [
        83.7588536,
        50.2048685
    ],
    [
        83.7584673,
        50.2046968
    ],
    [
        83.756976,
        50.2048273
    ],
    [
        83.7556993,
        50.2036187
    ],
    [
        83.7552379,
        50.2035706
    ],
    [
        83.7543153,
        50.203962
    ],
    [
        83.7537896,
        50.2039552
    ],
    [
        83.7532853,
        50.2036255
    ],
    [
        83.7517511,
        50.2036942
    ],
    [
        83.7513541,
        50.203289
    ],
    [
        83.751515,
        50.2027946
    ],
    [
        83.7515043,
        50.2025268
    ],
    [
        83.7511824,
        50.2024169
    ],
    [
        83.7505065,
        50.2020735
    ],
    [
        83.7499701,
        50.2014692
    ],
    [
        83.7492298,
        50.2009748
    ],
    [
        83.7482642,
        50.2007069
    ],
    [
        83.7479423,
        50.200082
    ],
    [
        83.7468909,
        50.199821
    ],
    [
        83.7463598,
        50.1992063
    ],
    [
        83.7457805,
        50.1987634
    ],
    [
        83.7452172,
        50.1985367
    ],
    [
        83.7436937,
        50.1985505
    ],
    [
        83.7430714,
        50.1986604
    ],
    [
        83.7392091,
        50.1985093
    ],
    [
        83.7377607,
        50.1983307
    ],
    [
        83.7362908,
        50.1980629
    ],
    [
        83.7345742,
        50.1981041
    ],
    [
        83.7328361,
        50.1983788
    ],
    [
        83.7308191,
        50.1988252
    ],
    [
        83.7289094,
        50.1986329
    ],
    [
        83.7270211,
        50.1983101
    ],
    [
        83.7234591,
        50.1975752
    ],
    [
        83.7110995,
        50.1972456
    ],
    [
        83.6795675,
        50.1888315
    ],
    [
        83.6703299,
        50.194773
    ],
    [
        83.6420059,
        50.1884262
    ],
    [
        83.6009144,
        50.1891818
    ],
    [
        83.5677193,
        50.192891
    ],
    [
        83.5212636,
        50.1953912
    ],
    [
        83.5140966,
        50.1837136
    ],
    [
        83.5196542,
        50.1802785
    ],
    [
        83.5210705,
        50.1671541
    ],
    [
        83.5134958,
        50.1659033
    ],
    [
        83.472619,
        50.1510839
    ],
    [
        83.4558606,
        50.1572294
    ],
    [
        83.4475778,
        50.1515926
    ],
    [
        83.4456896,
        50.144305
    ],
    [
        83.4397458,
        50.1390517
    ],
    [
        83.4171723,
        50.1329588
    ],
    [
        83.3974527,
        50.1311432
    ],
    [
        83.3820246,
        50.1338941
    ],
    [
        83.3686994,
        50.1345405
    ],
    [
        83.3438944,
        50.1381165
    ],
    [
        83.3418131,
        50.1418159
    ],
    [
        83.3222436,
        50.1421322
    ],
    [
        83.3057426,
        50.1398768
    ],
    [
        83.2758835,
        50.1256195
    ],
    [
        83.2481288,
        50.1441537
    ],
    [
        83.2234954,
        50.1626044
    ],
    [
        83.2132172,
        50.164948
    ],
    [
        83.2130992,
        50.1705696
    ],
    [
        83.2145691,
        50.1752491
    ],
    [
        83.2180935,
        50.1907102
    ],
    [
        83.1708923,
        50.2212744
    ],
    [
        83.1591439,
        50.2283956
    ],
    [
        83.1471598,
        50.2328773
    ],
    [
        83.1470257,
        50.2336803
    ],
    [
        83.145867,
        50.2355469
    ],
    [
        83.1452608,
        50.2363224
    ],
    [
        83.1450248,
        50.2368302
    ],
    [
        83.144102,
        50.2376674
    ],
    [
        83.1435227,
        50.2383536
    ],
    [
        83.1425464,
        50.2397946
    ],
    [
        83.1392955,
        50.2409886
    ],
    [
        83.1360124,
        50.2442821
    ],
    [
        83.1347679,
        50.2459562
    ],
    [
        83.1330299,
        50.2479047
    ],
    [
        83.1328367,
        50.2485633
    ],
    [
        83.1332873,
        50.2493454
    ],
    [
        83.1331156,
        50.2501824
    ],
    [
        83.1311415,
        50.2542573
    ],
    [
        83.1315707,
        50.2567268
    ],
    [
        83.1309055,
        50.2577969
    ],
    [
        83.1300043,
        50.2587023
    ],
    [
        83.1270808,
        50.2598563
    ]
]