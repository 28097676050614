import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Arrow } from "../Icons";
import { getDistrictNameById } from "../../utils";
import ProvisionOfTheRegion from "./ProvisionOfTheRegion";
import PopulationCommunicationsStatuses from "./PopulationCommunicationsStatuses";
import ConstructionOfAntennas from "./ConstructionOfAntennas";
import Modal from "../UI/Modal";
import PopulationCommunicationsModal from "./modals/PopulationCommunicationsModal";

const InternetSideInfo: React.FC = () => {
    const params = useParams();
    const districtId = useMemo(() => params.id, [params]);
    const navigate = useNavigate();

    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="side-info side-info-scroll">
            {districtId && (
                <div
                    className="side-info-district"
                    id="side-info-district-name"
                >
                    <div
                        className="side-info-card back-btn floods-back-btn"
                        onClick={() => navigate("/internet")}
                    >
                        <Arrow />
                    </div>
                    <div className="side-info-card side-info-district-name">
                        {getDistrictNameById(Number(districtId))}
                    </div>
                </div>
            )}

            <ProvisionOfTheRegion />
            <PopulationCommunicationsStatuses />

            <div className="open-modal-card" onClick={() => setModalShow(true)}>
                Подробнее
            </div>

            <ConstructionOfAntennas />

            <Modal
                title=""
                isOpen={modalShow}
                onClose={() => setModalShow(false)}
            >
                <PopulationCommunicationsModal />
            </Modal>
        </div>
    );
};

export default InternetSideInfo;
