import React, {useEffect, useRef, useState} from 'react';
import {ChevronOutline} from "../../Icons";

const WaterSliderCard = () => {
    const [page, setPage] = useState<number>(0);
    const titles = ["ВОДОСНАБЖЕНИЕ", "ПРОТЯЖЕННОСТЬ", "УРОВЕНЬ ТАРИФОВ"];
    const intervalRef = useRef<number | null>(null);

    useEffect(() => {
        startInterval();

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        }
    }, []);

    const handleChangePage = (next?: boolean) => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }

        next ?
            setPage((prevIndex) => (prevIndex + 1) % titles.length)
        :
            setPage((prevIndex) => (prevIndex - 1 + titles.length) % titles.length)

        startInterval();
    };

    const startInterval = () => {
        intervalRef.current = window.setInterval(() => {
            setPage((prevIndex) => (prevIndex + 1) % titles.length);
        }, 30000);
    };

    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-slider">
                <span className="side-info-big-card-slider-arrow prev" onClick={() => handleChangePage()}><ChevronOutline /></span>
                <span className="side-info-big-card-title">{titles[page]}</span>
                <span className="side-info-big-card-slider-arrow" onClick={() => handleChangePage(true)}><ChevronOutline /></span>
            </div>

            {page === 0 &&
                <div className="side-info-big-card-items" style={{gap: "8px"}}>
                    <div className="side-info-big-card-item">
                        <div>Бюджетных организаций (единиц)</div>
                        <div>541</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Количество населения имеющих доступ к  централизованному водоснабжению (чел.)</div>
                        <div>541</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>в том числе с дистанционной передачей данных в АСУЭ обслуживающего предприятия (единиц)</div>
                        <div>23</div>
                    </div>
                    <ul>
                        <li>
                            <div className="data-list-item">
                                <div>охват в %, гр.15/гр. 9*100</div>
                                <div>89%</div>
                            </div>
                        </li>
                    </ul>
                    <div className="side-info-big-card-item">
                        <div>Охват общедомовыми приборами учета воды по состоянию на конец отчетного года</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Количество зданий и сооружений, подлежащих к установке общедомовых приборов учета (единиц)</div>
                        <div>800</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Количество зданий и сооружений с установленными общедомовыми приборами учета (единиц)</div>
                        <div>409</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Количество установленных общедомовых приборов учета (единиц)</div>
                        <div>409</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>в том числе с дистанционной передачей данных в АСУЭ обслуживающего предприятия (единиц)</div>
                        <div>294</div>
                    </div>
                    <ul>
                        <li>
                            <div className="data-list-item">
                                <div>охват в %, гр.19/гр. 18*100</div>
                                <div>51%</div>
                            </div>
                        </li>
                    </ul>
                </div>
            }

            {page === 1 &&
                <div className="side-info-big-card-items" style={{gap: "8px"}}>
                    <div className="side-info-big-card-item">
                        <div>Протяженность водопроводных сетей, км (по состоянию на конец отчетного года)</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Общая, км</div>
                        <div>541</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>в том числе изношенных, км</div>
                        <div>346168</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Износ, % гр.59/гр.58</div>
                        <div>23</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Общая протяженность построенных (новых) сетей в отчетном году, км</div>
                        <div>541</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Общая протяженность реконструированных (замененных) сетей в отчетном году, км</div>
                        <div>541</div>
                    </div>
                </div>
            }

            {page === 2 &&
                <div className="side-info-big-card-items" style={{gap: "8px"}}>
                    <div className="side-info-big-card-item">
                        <div>Усредненный, тенге/м3</div>
                        <div>541</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Физическим лицам/населению, тенге/м3</div>
                        <div>346168</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Юридическим лицам (в том числе бюджетные организации), тенге/м3</div>
                        <div>23</div>
                    </div>
                    <div className="side-info-big-card-item">
                        <div>Бюджетным организациям, тенге/м3</div>
                        <div>23</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default WaterSliderCard;