import React, {useEffect, useRef} from 'react';
import {divIcon, DivIconOptions} from "leaflet";
import ReactDOMServer from "react-dom/server";
import {FireStationIcon} from "../../Icons";
import {Marker, Popup} from "react-leaflet";
import {IFiresStation} from "../../../types/fires";
import {getDistrictNameById} from "../../../utils";

interface IFireStationMarker {
    point: IFiresStation;
}

const FireStationMarker: React.FC<IFireStationMarker> = ({point}) => {
    const markerRef = useRef<any>(null);

    const createFireMarker = () => {
        const options: DivIconOptions = {
            className: "marker-wrapper",
            html: ReactDOMServer.renderToString(
                <FireStationIcon />
            )}
        return divIcon(options);
    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    return (
        <>
            {point.latitude && point.longitude &&
                <Marker
                    position={[point.latitude, point.longitude]}
                    icon={createFireMarker()}
                    ref={markerRef}
                >
                    <Popup>
                        <span style={{ fontWeight: 600, fontSize: "14px" }}>{getDistrictNameById(point.district)}</span> <br />
                        Состояние: <span style={{ fontWeight: 600 }}>{point.state}</span> <br />
                        Период работы: <span style={{ fontWeight: 600 }}>{point.period}</span> <br />
                        Техника: <span style={{ fontWeight: 600 }}>{point.equipment}</span> <br />
                        Количество работников: <span style={{ fontWeight: 600 }}>{point.employees}</span> <br />
                    </Popup>
                </Marker>
            }
        </>
    );
};

export default FireStationMarker;