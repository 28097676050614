import {LatLngExpression} from "leaflet";

export const shemonaikhaDistrict: LatLngExpression[] = [
    [
        81.5908286,
        50.7435026
    ],
    [
        81.5849699,
        50.7043253
    ],
    [
        81.6340411,
        50.6959405
    ],
    [
        81.6340826,
        50.6737265
    ],
    [
        81.6641232,
        50.6479368
    ],
    [
        81.6645525,
        50.5844901
    ],
    [
        81.6355417,
        50.5846537
    ],
    [
        81.6301344,
        50.5790945
    ],
    [
        81.6378592,
        50.5668843
    ],
    [
        81.6545103,
        50.5558704
    ],
    [
        81.6787576,
        50.5649488
    ],
    [
        81.6846402,
        50.5605461
    ],
    [
        81.6783712,
        50.5499808
    ],
    [
        81.6981692,
        50.5387263
    ],
    [
        81.709957,
        50.5328528
    ],
    [
        81.7134581,
        50.5286301
    ],
    [
        81.7335942,
        50.5043358
    ],
    [
        81.6989706,
        50.4828522
    ],
    [
        81.6898724,
        50.4814324
    ],
    [
        81.6883276,
        50.4625311
    ],
    [
        81.6761397,
        50.4627497
    ],
    [
        81.6649817,
        50.4456995
    ],
    [
        81.6602596,
        50.4312486
    ],
    [
        81.6692732,
        50.4302835
    ],
    [
        81.6780279,
        50.4270029
    ],
    [
        81.682872,
        50.4144931
    ],
    [
        81.6900442,
        50.407533
    ],
    [
        81.6972541,
        50.4046337
    ],
    [
        81.7048072,
        50.4035943
    ],
    [
        81.7148494,
        50.4044696
    ],
    [
        81.7184543,
        50.4035396
    ],
    [
        81.7203426,
        50.4023907
    ],
    [
        81.72163,
        50.4009409
    ],
    [
        81.6883276,
        50.3991082
    ],
    [
        81.6887279,
        50.3605305
    ],
    [
        81.6821478,
        50.3327514
    ],
    [
        81.6795729,
        50.3303406
    ],
    [
        81.6824911,
        50.3152159
    ],
    [
        81.6747549,
        50.304443
    ],
    [
        81.6480629,
        50.2865251
    ],
    [
        81.6269688,
        50.2688259
    ],
    [
        81.7511557,
        50.2454486
    ],
    [
        81.7635154,
        50.2466012
    ],
    [
        81.7731284,
        50.2440763
    ],
    [
        81.7760467,
        50.2408377
    ],
    [
        81.7754459,
        50.2379282
    ],
    [
        81.7736434,
        50.2349636
    ],
    [
        81.7695235,
        50.2313401
    ],
    [
        81.7679786,
        50.2281006
    ],
    [
        81.7684077,
        50.2250256
    ],
    [
        81.7722701,
        50.2217856
    ],
    [
        81.7771625,
        50.2208521
    ],
    [
        81.7841148,
        50.2221151
    ],
    [
        81.7886638,
        50.2207971
    ],
    [
        81.7923545,
        50.216129
    ],
    [
        81.7914104,
        50.2100871
    ],
    [
        81.7896079,
        50.2049234
    ],
    [
        81.7939853,
        50.2006383
    ],
    [
        81.8024824,
        50.1953638
    ],
    [
        81.8151854,
        50.1876707
    ],
    [
        81.8350124,
        50.1947593
    ],
    [
        81.8579291,
        50.1986605
    ],
    [
        81.8859278,
        50.2035551
    ],
    [
        81.9102858,
        50.2041544
    ],
    [
        81.9197272,
        50.2010779
    ],
    [
        81.9183539,
        50.1975616
    ],
    [
        82.0289038,
        50.1883301
    ],
    [
        82.0395898,
        50.2029732
    ],
    [
        82.0271872,
        50.2129434
    ],
    [
        82.011051,
        50.2177767
    ],
    [
        81.9928549,
        50.2130532
    ],
    [
        81.980152,
        50.2130532
    ],
    [
        81.989765,
        50.2245863
    ],
    [
        82.0041846,
        50.2288144
    ],
    [
        82.0316504,
        50.246217
    ],
    [
        82.0274448,
        50.2734336
    ],
    [
        82.0292472,
        50.2735433
    ],
    [
        82.0472003,
        50.3140464
    ],
    [
        82.0273278,
        50.3232337
    ],
    [
        82.0787955,
        50.3838048
    ],
    [
        82.0845222,
        50.3877815
    ],
    [
        82.0904445,
        50.3907912
    ],
    [
        82.0938777,
        50.3920225
    ],
    [
        82.095766,
        50.3920771
    ],
    [
        82.0974183,
        50.3918172
    ],
    [
        82.1055507,
        50.3874395
    ],
    [
        82.1074176,
        50.3866322
    ],
    [
        82.1095306,
        50.3860612
    ],
    [
        82.1118916,
        50.3890789
    ],
    [
        82.1377363,
        50.391302
    ],
    [
        82.2569806,
        50.3730041
    ],
    [
        82.3114861,
        50.3987652
    ],
    [
        82.3278434,
        50.3748206
    ],
    [
        82.3693083,
        50.3708686
    ],
    [
        82.3681067,
        50.3616707
    ],
    [
        82.3816679,
        50.3561949
    ],
    [
        82.3962591,
        50.3547711
    ],
    [
        82.4094771,
        50.3614517
    ],
    [
        82.4098204,
        50.3865229
    ],
    [
        82.4144552,
        50.3921046
    ],
    [
        82.4160002,
        50.3876174
    ],
    [
        82.42115,
        50.3778753
    ],
    [
        82.4345396,
        50.3788606
    ],
    [
        82.4414061,
        50.3765617
    ],
    [
        82.4487875,
        50.3809405
    ],
    [
        82.4539374,
        50.3576187
    ],
    [
        82.4616621,
        50.3525805
    ],
    [
        82.4685286,
        50.3520329
    ],
    [
        82.5002859,
        50.3817067
    ],
    [
        82.487583,
        50.4165033
    ],
    [
        82.5064657,
        50.4228471
    ],
    [
        82.5341032,
        50.4441691
    ],
    [
        82.5349615,
        50.4491975
    ],
    [
        82.5669511,
        50.4892269
    ],
    [
        82.5672769,
        50.4904976
    ],
    [
        82.5715255,
        50.4938011
    ],
    [
        82.5711822,
        50.4969134
    ],
    [
        82.5701522,
        50.4981418
    ],
    [
        82.5694656,
        50.5008989
    ],
    [
        82.5685644,
        50.5022637
    ],
    [
        82.5675344,
        50.5031372
    ],
    [
        82.5686502,
        50.5045293
    ],
    [
        82.5709247,
        50.5057848
    ],
    [
        82.5710105,
        50.506822
    ],
    [
        82.5691652,
        50.5069584
    ],
    [
        82.5662899,
        50.5060032
    ],
    [
        82.5592517,
        50.5056483
    ],
    [
        82.5563335,
        50.5082685
    ],
    [
        82.5559473,
        50.5113798
    ],
    [
        82.5580501,
        50.5145454
    ],
    [
        82.5607538,
        50.5156097
    ],
    [
        82.5619983,
        50.5167831
    ],
    [
        82.5613975,
        50.5183931
    ],
    [
        82.55908,
        50.519812
    ],
    [
        82.5561618,
        50.5207397
    ],
    [
        82.549467,
        50.5220494
    ],
    [
        82.5458265,
        50.5268084
    ],
    [
        82.5689077,
        50.534735
    ],
    [
        82.5896787,
        50.5477173
    ],
    [
        82.5942707,
        50.5545343
    ],
    [
        82.5978755,
        50.5615957
    ],
    [
        82.598133,
        50.5686287
    ],
    [
        82.5937556,
        50.5749521
    ],
    [
        82.5947856,
        50.5789311
    ],
    [
        82.6002788,
        50.5812747
    ],
    [
        82.6062011,
        50.5800756
    ],
    [
        82.6119517,
        50.5748431
    ],
    [
        82.620449,
        50.5738074
    ],
    [
        82.6280021,
        50.5734259
    ],
    [
        82.6343533,
        50.5751703
    ],
    [
        82.6412197,
        50.573208
    ],
    [
        82.653236,
        50.5766965
    ],
    [
        82.6608752,
        50.5702642
    ],
    [
        82.6694583,
        50.5753882
    ],
    [
        82.6825046,
        50.5698281
    ],
    [
        82.7134894,
        50.5861796
    ],
    [
        82.767391,
        50.6086263
    ],
    [
        82.789192,
        50.6390104
    ],
    [
        82.8032683,
        50.6432561
    ],
    [
        82.8097914,
        50.6515286
    ],
    [
        82.865238,
        50.6556643
    ],
    [
        82.8717612,
        50.6469571
    ],
    [
        82.8959654,
        50.6530523
    ],
    [
        82.9155348,
        50.6465217
    ],
    [
        82.9383658,
        50.6534877
    ],
    [
        82.9419707,
        50.6605614
    ],
    [
        82.9601666,
        50.661432
    ],
    [
        82.9721829,
        50.6825377
    ],
    [
        82.9615399,
        50.6884108
    ],
    [
        82.9416272,
        50.6901508
    ],
    [
        82.9320144,
        50.6981431
    ],
    [
        82.9368206,
        50.7197214
    ],
    [
        82.9706382,
        50.7701765
    ],
    [
        82.9652309,
        50.7762015
    ],
    [
        82.9811094,
        50.8021378
    ],
    [
        82.9850577,
        50.8018124
    ],
    [
        82.9920959,
        50.7981235
    ],
    [
        82.999413,
        50.7956821
    ],
    [
        83.0060004,
        50.8021378
    ],
    [
        83.0140685,
        50.8052839
    ],
    [
        83.0193042,
        50.8127686
    ],
    [
        83.0187033,
        50.8141787
    ],
    [
        83.0178451,
        50.818788
    ],
    [
        83.0149267,
        50.8222042
    ],
    [
        83.0070304,
        50.8247525
    ],
    [
        83.0046271,
        50.8248609
    ],
    [
        83.0016231,
        50.8262706
    ],
    [
        83.0005931,
        50.8285476
    ],
    [
        82.9990481,
        50.8293609
    ],
    [
        82.9922674,
        50.8310957
    ],
    [
        82.9836844,
        50.8301199
    ],
    [
        82.9783629,
        50.8320715
    ],
    [
        82.9776762,
        50.8349988
    ],
    [
        82.9795645,
        50.8379258
    ],
    [
        82.9879759,
        50.8397687
    ],
    [
        82.9965588,
        50.8462725
    ],
    [
        82.9821092,
        50.8548927
    ],
    [
        82.9796426,
        50.8838498
    ],
    [
        82.9759755,
        50.8856366
    ],
    [
        82.9715379,
        50.8892551
    ],
    [
        82.9617873,
        50.8932929
    ],
    [
        82.9422652,
        50.8947386
    ],
    [
        82.9309757,
        50.8981577
    ],
    [
        82.9266862,
        50.8986557
    ],
    [
        82.9232389,
        50.9007635
    ],
    [
        82.918581,
        50.9024065
    ],
    [
        82.9149494,
        50.9044145
    ],
    [
        82.9110476,
        50.9076824
    ],
    [
        82.9061311,
        50.9071687
    ],
    [
        82.9008551,
        50.9061412
    ],
    [
        82.8993665,
        50.9051789
    ],
    [
        82.8958682,
        50.9048269
    ],
    [
        82.8891154,
        50.9064951
    ],
    [
        82.886806,
        50.905132
    ],
    [
        82.8854664,
        50.8979673
    ],
    [
        82.8788882,
        50.8975302
    ],
    [
        82.8748348,
        50.8980669
    ],
    [
        82.870203,
        50.8992395
    ],
    [
        82.8683171,
        50.9010875
    ],
    [
        82.8663085,
        50.9044233
    ],
    [
        82.861305,
        50.90597
    ],
    [
        82.847812,
        50.90966
    ],
    [
        82.8379931,
        50.9180323
    ],
    [
        82.7942112,
        50.9180652
    ],
    [
        82.7876445,
        50.9207321
    ],
    [
        82.7852547,
        50.92099
    ],
    [
        82.7830155,
        50.9198092
    ],
    [
        82.7812715,
        50.9199856
    ],
    [
        82.7780307,
        50.9234016
    ],
    [
        82.7736295,
        50.9245427
    ],
    [
        82.7713517,
        50.9271287
    ],
    [
        82.7328173,
        50.9272716
    ],
    [
        82.7300602,
        50.9252994
    ],
    [
        82.7288007,
        50.9228584
    ],
    [
        82.7331552,
        50.9153598
    ],
    [
        82.7332484,
        50.9125905
    ],
    [
        82.7314149,
        50.9072059
    ],
    [
        82.7279056,
        50.9045275
    ],
    [
        82.7173386,
        50.90093
    ],
    [
        82.7187596,
        50.8975625
    ],
    [
        82.717451,
        50.8939978
    ],
    [
        82.7253925,
        50.8768276
    ],
    [
        82.729081,
        50.8669107
    ],
    [
        82.7306832,
        50.857299
    ],
    [
        82.7391938,
        50.8504319
    ],
    [
        82.7064741,
        50.8318982
    ],
    [
        82.7106871,
        50.8281648
    ],
    [
        82.7092671,
        50.8256997
    ],
    [
        82.7099723,
        50.8225069
    ],
    [
        82.7039386,
        50.8163435
    ],
    [
        82.7015323,
        50.8122002
    ],
    [
        82.6968226,
        50.8098545
    ],
    [
        82.6886775,
        50.8107648
    ],
    [
        82.6820407,
        50.8082264
    ],
    [
        82.6776917,
        50.8088453
    ],
    [
        82.6751058,
        50.807954
    ],
    [
        82.6749695,
        50.8059781
    ],
    [
        82.6798858,
        50.8019868
    ],
    [
        82.680216,
        50.8005861
    ],
    [
        82.6731737,
        50.7970066
    ],
    [
        82.6680143,
        50.7932451
    ],
    [
        82.6633479,
        50.7928822
    ],
    [
        82.6544189,
        50.7954493
    ],
    [
        82.6470531,
        50.7943349
    ],
    [
        82.6376108,
        50.7876972
    ],
    [
        82.6304219,
        50.7790917
    ],
    [
        82.6234429,
        50.7779755
    ],
    [
        82.6054564,
        50.7759965
    ],
    [
        82.6033094,
        50.773777
    ],
    [
        82.5674695,
        50.7468424
    ],
    [
        82.5368069,
        50.7470549
    ],
    [
        82.5022682,
        50.7417798
    ],
    [
        82.4854762,
        50.7422289
    ],
    [
        82.4854816,
        50.7422451
    ],
    [
        82.4854763,
        50.7422773
    ],
    [
        82.4853489,
        50.7423842
    ],
    [
        82.4853569,
        50.74252
    ],
    [
        82.4852858,
        50.7426032
    ],
    [
        82.4851638,
        50.7426286
    ],
    [
        82.4850015,
        50.7425922
    ],
    [
        82.4849264,
        50.7425913
    ],
    [
        82.4848191,
        50.7426575
    ],
    [
        82.4847869,
        50.742722
    ],
    [
        82.4847896,
        50.7427856
    ],
    [
        82.4848942,
        50.7429579
    ],
    [
        82.48485,
        50.743036
    ],
    [
        82.4847708,
        50.7430784
    ],
    [
        82.4846998,
        50.7430861
    ],
    [
        82.4845254,
        50.7430343
    ],
    [
        82.4844396,
        50.7430632
    ],
    [
        82.4843953,
        50.7431013
    ],
    [
        82.4843497,
        50.7431786
    ],
    [
        82.4843578,
        50.7433237
    ],
    [
        82.4844476,
        50.7434552
    ],
    [
        82.4844691,
        50.7435248
    ],
    [
        82.4843739,
        50.7436165
    ],
    [
        82.4841834,
        50.7436971
    ],
    [
        82.4841539,
        50.7437573
    ],
    [
        82.4841901,
        50.7438422
    ],
    [
        82.4842639,
        50.7439169
    ],
    [
        82.4843403,
        50.7439568
    ],
    [
        82.4844114,
        50.743967
    ],
    [
        82.4845107,
        50.7439602
    ],
    [
        82.4846193,
        50.7439279
    ],
    [
        82.4848111,
        50.7437777
    ],
    [
        82.4849076,
        50.743776
    ],
    [
        82.4849908,
        50.7438057
    ],
    [
        82.4850391,
        50.7438524
    ],
    [
        82.4850471,
        50.7439076
    ],
    [
        82.4850109,
        50.7439491
    ],
    [
        82.4848728,
        50.744034
    ],
    [
        82.484858,
        50.7440976
    ],
    [
        82.4848835,
        50.744146
    ],
    [
        82.4849371,
        50.7441791
    ],
    [
        82.4851355,
        50.7441926
    ],
    [
        82.4852214,
        50.7442223
    ],
    [
        82.4853648,
        50.7443224
    ],
    [
        82.4854104,
        50.7444073
    ],
    [
        82.4854051,
        50.7444718
    ],
    [
        82.4853032,
        50.7446025
    ],
    [
        82.4848338,
        50.7449165
    ],
    [
        82.4846085,
        50.7452627
    ],
    [
        82.4845012,
        50.7453323
    ],
    [
        82.4843617,
        50.7453696
    ],
    [
        82.4842088,
        50.7453747
    ],
    [
        82.4835383,
        50.7452576
    ],
    [
        82.4833532,
        50.7452372
    ],
    [
        82.4832164,
        50.7452491
    ],
    [
        82.4831359,
        50.7452865
    ],
    [
        82.4830474,
        50.7453628
    ],
    [
        82.4830099,
        50.7454579
    ],
    [
        82.4830206,
        50.7455207
    ],
    [
        82.4831467,
        50.7456446
    ],
    [
        82.4831467,
        50.7456768
    ],
    [
        82.4830984,
        50.7457277
    ],
    [
        82.4830152,
        50.7457498
    ],
    [
        82.4827738,
        50.74576
    ],
    [
        82.4825163,
        50.7458669
    ],
    [
        82.4824198,
        50.7458771
    ],
    [
        82.4822696,
        50.7458499
    ],
    [
        82.4821972,
        50.7458126
    ],
    [
        82.4820523,
        50.7456887
    ],
    [
        82.4819155,
        50.7456582
    ],
    [
        82.4817921,
        50.7456955
    ],
    [
        82.4817117,
        50.7458041
    ],
    [
        82.4817546,
        50.7461962
    ],
    [
        82.48165,
        50.7465118
    ],
    [
        82.4816473,
        50.7467019
    ],
    [
        82.4817305,
        50.7467376
    ],
    [
        82.4817787,
        50.7467342
    ],
    [
        82.4820068,
        50.7466443
    ],
    [
        82.4821838,
        50.7466172
    ],
    [
        82.4823153,
        50.746646
    ],
    [
        82.4823501,
        50.7466664
    ],
    [
        82.4823877,
        50.7467886
    ],
    [
        82.4824118,
        50.7472434
    ],
    [
        82.4823823,
        50.7474352
    ],
    [
        82.4823314,
        50.7475727
    ],
    [
        82.4821973,
        50.7477067
    ],
    [
        82.4821758,
        50.7477678
    ],
    [
        82.482275,
        50.7482108
    ],
    [
        82.482267,
        50.7484382
    ],
    [
        82.4820095,
        50.7492375
    ],
    [
        82.4819451,
        50.7493784
    ],
    [
        82.4819559,
        50.7494836
    ],
    [
        82.4820658,
        50.7496482
    ],
    [
        82.4820792,
        50.7499265
    ],
    [
        82.4819827,
        50.7501947
    ],
    [
        82.481929,
        50.7502846
    ],
    [
        82.4818137,
        50.7503932
    ],
    [
        82.4814033,
        50.7506783
    ],
    [
        82.4812665,
        50.7511433
    ],
    [
        82.4810949,
        50.7514097
    ],
    [
        82.4809661,
        50.7516949
    ],
    [
        82.4809098,
        50.7517593
    ],
    [
        82.4806629,
        50.7518985
    ],
    [
        82.4718233,
        50.756125
    ],
    [
        82.4107141,
        50.7646687
    ],
    [
        82.4082922,
        50.7638408
    ],
    [
        82.3821881,
        50.7751716
    ],
    [
        82.3807238,
        50.7757198
    ],
    [
        82.3778319,
        50.7804293
    ],
    [
        82.3760019,
        50.7807007
    ],
    [
        82.3745184,
        50.7798531
    ],
    [
        82.3702484,
        50.7790246
    ],
    [
        82.3682615,
        50.7777413
    ],
    [
        82.3665957,
        50.7778932
    ],
    [
        82.3622183,
        50.7828491
    ],
    [
        82.3573142,
        50.7845959
    ],
    [
        82.3559722,
        50.7844074
    ],
    [
        82.3558444,
        50.7828587
    ],
    [
        82.3521169,
        50.7769057
    ],
    [
        82.3507749,
        50.7756261
    ],
    [
        82.346451,
        50.7749392
    ],
    [
        82.3457055,
        50.7736999
    ],
    [
        82.3435328,
        50.7729995
    ],
    [
        82.3423717,
        50.7702787
    ],
    [
        82.3403695,
        50.7697129
    ],
    [
        82.3374622,
        50.7702245
    ],
    [
        82.3353213,
        50.7679212
    ],
    [
        82.3319132,
        50.7673958
    ],
    [
        82.326439,
        50.7644185
    ],
    [
        82.3272322,
        50.7628752
    ],
    [
        82.3213316,
        50.7612487
    ],
    [
        82.3167363,
        50.7529586
    ],
    [
        82.3051638,
        50.7557875
    ],
    [
        82.2958581,
        50.7693844
    ],
    [
        82.2703799,
        50.7697911
    ],
    [
        82.2642156,
        50.7657256
    ],
    [
        82.263589,
        50.7642731
    ],
    [
        82.2599794,
        50.7628135
    ],
    [
        82.2570166,
        50.7611348
    ],
    [
        82.2535057,
        50.7540743
    ],
    [
        82.2500255,
        50.7519307
    ],
    [
        82.2491418,
        50.7488949
    ],
    [
        82.2477975,
        50.7489978
    ],
    [
        82.2434395,
        50.7473613
    ],
    [
        82.241228,
        50.7475808
    ],
    [
        82.2386479,
        50.7458796
    ],
    [
        82.2370218,
        50.7455915
    ],
    [
        82.2341164,
        50.7428476
    ],
    [
        82.230127,
        50.7419009
    ],
    [
        82.2290213,
        50.7394037
    ],
    [
        82.2240075,
        50.7368288
    ],
    [
        82.2224366,
        50.7369171
    ],
    [
        82.2170247,
        50.741225
    ],
    [
        82.2158289,
        50.7412541
    ],
    [
        82.2119058,
        50.7369226
    ],
    [
        82.204179,
        50.7366342
    ],
    [
        82.1994524,
        50.7349052
    ],
    [
        82.1959183,
        50.7351933
    ],
    [
        82.1916253,
        50.735015
    ],
    [
        82.1891536,
        50.7352208
    ],
    [
        82.1855111,
        50.7370321
    ],
    [
        82.184697,
        50.7347572
    ],
    [
        82.1813548,
        50.7337481
    ],
    [
        82.1797603,
        50.7317879
    ],
    [
        82.172702,
        50.7300643
    ],
    [
        82.1723916,
        50.7386358
    ],
    [
        82.17463,
        50.7398972
    ],
    [
        82.1753045,
        50.7452719
    ],
    [
        82.1721616,
        50.7468046
    ],
    [
        82.1663907,
        50.745845
    ],
    [
        82.158932,
        50.7469534
    ],
    [
        82.1559373,
        50.7443433
    ],
    [
        82.1523774,
        50.7437712
    ],
    [
        82.1468078,
        50.7451018
    ],
    [
        82.1472347,
        50.7473268
    ],
    [
        82.0783819,
        50.7508377
    ],
    [
        82.061132,
        50.7573664
    ],
    [
        82.0629188,
        50.7657995
    ],
    [
        82.0481439,
        50.7693719
    ],
    [
        82.035972,
        50.769114
    ],
    [
        82.0262941,
        50.7791634
    ],
    [
        82.0125734,
        50.7857508
    ],
    [
        81.997179,
        50.7879389
    ],
    [
        81.9919293,
        50.7847519
    ],
    [
        81.9819892,
        50.7847499
    ],
    [
        81.9650382,
        50.7895315
    ],
    [
        81.9569988,
        50.7903423
    ],
    [
        81.9278138,
        50.7932854
    ],
    [
        81.9062043,
        50.795451
    ],
    [
        81.8854153,
        50.804307
    ],
    [
        81.7967794,
        50.7719818
    ],
    [
        81.7903996,
        50.789776
    ],
    [
        81.7913122,
        50.7933049
    ],
    [
        81.791456,
        50.793651
    ],
    [
        81.7912978,
        50.7938968
    ],
    [
        81.7912616,
        50.7943433
    ],
    [
        81.7900159,
        50.7953903
    ],
    [
        81.7891946,
        50.8026059
    ],
    [
        81.7893734,
        50.8057968
    ],
    [
        81.7880174,
        50.8067064
    ],
    [
        81.7895368,
        50.8092495
    ],
    [
        81.7888387,
        50.8113168
    ],
    [
        81.7887292,
        50.815036
    ],
    [
        81.7895734,
        50.8165709
    ],
    [
        81.775857,
        50.820919
    ],
    [
        81.7554322,
        50.7818034
    ],
    [
        81.7414631,
        50.7708353
    ],
    [
        81.7278273,
        50.7601264
    ],
    [
        81.6597187,
        50.7526851
    ],
    [
        81.6534113,
        50.7518814
    ],
    [
        81.5908286,
        50.7435026
    ]
]