import React, { useEffect, useState } from "react";
import DonutChart from "../Charts/DonutChart";
import CardCarousel from "../CardCarousel/CardCarousel";

type ProvisionOfTheRegionAboutInfoProps = {
    totalPopulationCenters: number;

    totalCities: number;
    countOfPeopleInCities: number;

    totalRuralCenters: number;
    countOfPeopleInRuralCenters: number;
};

const ProvisionOfTheRegionAboutInfo = (
    props: ProvisionOfTheRegionAboutInfoProps
) => {
    return (
        <div className="internet-page-provision-region-static-info">
            <h4>Всего населенных пунктов - {props.totalPopulationCenters}</h4>

            <div className="internet-page-provision-region-static-info-counts">
                <p>
                    Городов - {props.totalCities} (
                    {`${props.countOfPeopleInCities} чел.`})
                </p>

                <div className="line" />

                <p>
                    СНП - {props.totalRuralCenters} (
                    {`${props.countOfPeopleInRuralCenters} чел.`})
                </p>
            </div>
        </div>
    );
};

const ProvisionOfTheRegion: React.FC = () => {
    const [info, setInfo] = useState({
        totalPopulationCenters: 0,

        totalCities: 0,
        countOfPeopleInCities: 0,

        totalRuralCenters: 0,
        countOfPeopleInRuralCenters: 0,

        providedPopulationLocations: 0,
        notProvidedPopulationLocations: 0,

        providedPopulationCount: 0,
        notProvidedPopulationCount: 0,
    });

    const fetchInfo = async () => {
        await new Promise((res) => setTimeout(() => res(true), 500));

        setInfo({
            totalPopulationCenters: 17,

            totalCities: 4,
            countOfPeopleInCities: 456_349,

            totalRuralCenters: 13,
            countOfPeopleInRuralCenters: 634_923,

            providedPopulationLocations: 12,
            notProvidedPopulationLocations: 5,

            providedPopulationCount: 752_369,
            notProvidedPopulationCount: 338_903,
        });
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    return (
        <>
            <CardCarousel title="Обеспеченность области ШПД">
                <div className="internet-page-provision-region-slide">
                    <div className="internet-page-provision-region-dynamic-info">
                        <div className="internet-page-provision-region-dynamic-info-legends">
                            <div className="internet-page-provision-region-dynamic-info-legends-item">
                                <div
                                    className="internet-page-provision-region-dynamic-info-legends-item-color"
                                    style={{ backgroundColor: "#00b894" }}
                                />

                                <p>
                                    Обеспеченно населенных
                                    <br />
                                    пунктов — {info.providedPopulationLocations}
                                </p>
                            </div>

                            <div className="internet-page-provision-region-dynamic-info-legends-item">
                                <div
                                    className="internet-page-provision-region-dynamic-info-legends-item-color"
                                    style={{
                                        backgroundColor: "#fdcb6e",
                                    }}
                                />

                                <p>
                                    Не обеспеченно населенных
                                    <br />
                                    пунктов —{" "}
                                    {info.notProvidedPopulationLocations}
                                </p>
                            </div>
                        </div>

                        <div className="internet-page-provision-region-dynamic-info-chart">
                            <DonutChart
                                labels={[
                                    "Обеспеченно населенных пунктов",
                                    "Не обеспеченно населенных пунктов",
                                ]}
                                values={[
                                    info.providedPopulationLocations,
                                    info.notProvidedPopulationLocations,
                                ]}
                                backgroundColor={["#00b894", "#fdcb6e"]}
                                thickness="85%"
                                borderRadius={50}
                                distanceBetweenSegments={7}
                                rotation={150}
                                customTooltip={{ top: "185", left: "290" }}
                            />

                            <span className="percent">%</span>
                        </div>
                    </div>

                    <ProvisionOfTheRegionAboutInfo
                        countOfPeopleInCities={info.countOfPeopleInCities}
                        countOfPeopleInRuralCenters={
                            info.countOfPeopleInRuralCenters
                        }
                        totalCities={info.totalCities}
                        totalPopulationCenters={info.totalPopulationCenters}
                        totalRuralCenters={info.totalRuralCenters}
                    />
                </div>

                <div className="internet-page-provision-region-slide">
                    <div className="internet-page-provision-region-dynamic-info">
                        <div className="internet-page-provision-region-dynamic-info-legends">
                            <div className="internet-page-provision-region-dynamic-info-legends-item">
                                <div
                                    className="internet-page-provision-region-dynamic-info-legends-item-color"
                                    style={{
                                        backgroundColor: "#00b894",
                                    }}
                                />

                                <p>
                                    Кол-во обеспеченного
                                    <br />
                                    населения - {info.providedPopulationCount}
                                </p>
                            </div>

                            <div className="internet-page-provision-region-dynamic-info-legends-item">
                                <div
                                    className="internet-page-provision-region-dynamic-info-legends-item-color"
                                    style={{
                                        backgroundColor: "#fdcb6e",
                                    }}
                                />

                                <p>
                                    Кол-во не обеспеченного
                                    <br />
                                    населения -{" "}
                                    {info.notProvidedPopulationCount}
                                </p>
                            </div>
                        </div>

                        <div className="internet-page-provision-region-dynamic-info-chart">
                            <DonutChart
                                labels={[
                                    "Количество обеспеченного населения",
                                    "Количество не обеспеченного населения",
                                ]}
                                values={[
                                    info.providedPopulationCount,
                                    info.notProvidedPopulationCount,
                                ]}
                                backgroundColor={["#00b894", "#fdcb6e"]}
                                thickness="85%"
                                borderRadius={50}
                                distanceBetweenSegments={7}
                                rotation={150}
                                customTooltip={{ top: "185", left: "290" }}
                            />

                            <span className="percent">%</span>
                        </div>
                    </div>

                    <ProvisionOfTheRegionAboutInfo
                        countOfPeopleInCities={info.countOfPeopleInCities}
                        countOfPeopleInRuralCenters={
                            info.countOfPeopleInRuralCenters
                        }
                        totalCities={info.totalCities}
                        totalPopulationCenters={info.totalPopulationCenters}
                        totalRuralCenters={info.totalRuralCenters}
                    />
                </div>
            </CardCarousel>
        </>
    );
};

export default ProvisionOfTheRegion;
