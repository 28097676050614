import React, {useState} from 'react';
import Modal from "../UI/Modal";
import {useNavigate, useParams} from "react-router-dom";
import {Arrow} from "../Icons";
import {getDistrictNameById} from "../../utils";
import TenderPlanExecutionCard from "./TenderPlanExecutionCard";
import TenderStatusesCard from "./TenderStatusesCard";
import TendersContractsCard from "./TendersContractsCard";
import AdditionalAgreementsCard from "./AdditionalAgreementsCard";
import TendersContractsModal from './modals/TendersContractsModal';

const TendersSideInfo = () => {
    const params = useParams();
    const districtId = params.id;
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);

    return (
        <div className="side-info side-info-scroll">
            {districtId &&
                <div className="side-info-district" id="side-info-district-name">
                    <div
                        className="side-info-card back-btn floods-back-btn"
                        onClick={() => navigate("/tenders")}
                    >
                        <Arrow />
                    </div>
                    <div className="side-info-card side-info-district-name">
                        {getDistrictNameById(Number(districtId))}
                    </div>
                </div>
            }

            <TenderPlanExecutionCard />
            <TenderStatusesCard />
            <TendersContractsCard />
            <div className="open-modal-card" onClick={() => setOpenModal(true)}>Подробнее</div>
            <AdditionalAgreementsCard />

            <Modal
                title=""
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
            >
                <TendersContractsModal />
            </Modal>
        </div>
    );
};

export default TendersSideInfo;