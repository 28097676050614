import { createContext, ReactNode, useState } from "react";
import { DebtsState } from "../types/debts";

interface IDebtsProvider {
    children: ReactNode;
}

interface IDefaultValues {
    type: DebtsState;
    setType: (arg: DebtsState) => void;
    kse: DebtsState;
    setKse: (arg: DebtsState) => void;
    krp: DebtsState;
    setKrp: (arg: DebtsState) => void;
    text: string;
    setText: (arg: string) => void;
}

const defaultValues: IDefaultValues = {
    type: {open: false, value: "", selected: []},
    setType: () => {},
    kse: {open: false, value: "", selected: []},
    setKse: () => {},
    krp: {open: false, value: "", selected: []},
    setKrp: () => {},
    text: "",
    setText: () => {}
}

export const DebtsContext = createContext(defaultValues);

export const DebtsProvider: React.FC<IDebtsProvider> = ({children}) => {
    const [type, setType] = useState<DebtsState>({open: false, value: "", selected: []});
    const [kse, setKse] = useState<DebtsState>({open: false, value: "", selected: []});
    const [krp, setKrp] = useState<DebtsState>({open: false, value: "", selected: []});
    const [text, setText] = useState<string>("");
    
    return (
        <DebtsContext.Provider value={{type, setType, kse, setKse, krp, setKrp, text, setText}}>
            {children}
        </DebtsContext.Provider>
    )
}