import React, { useEffect, useState } from "react";
import BarChart from "../../Charts/BarChart";

type Info = {
    year: number;
    count: number;
};

type AdditionalInfo = {
    name: string;
    year: string;
    pularCenter: string;
    operator: string;
    type: string;
};

const PopulationCommunicationsModal: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<Info[]>([]);
    const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo[]>([]);

    const colorPastYears = "#2980b9";
    const colorNextYears = "#f39c12";

    const getSortedInfo = () => info.sort((a, b) => a.year - b.year);

    const getLabels = () => getSortedInfo().map(({ year }) => year);
    const getValues = () =>
        getSortedInfo().map(({ count, year }) => ({ x: year, y: count }));

    const getBackgroundColors = () => {
        const crntYear = new Date().getFullYear();
        const pastYears = info.filter(({ year }) => crntYear >= year);

        const countPastYears = pastYears.length;
        const countNextYears = info.length - countPastYears;

        return [
            ...[...new Array(countPastYears)].map(() => colorPastYears),
            ...[...new Array(countNextYears)].map(() => colorNextYears),
        ];
    };

    const getChartDataByPural = () => {
        return {
            labels: getLabels(),
            datasets: [
                {
                    data: getValues(),
                    borderWidth: 0,
                    backgroundColor: getBackgroundColors(),
                },
            ],
        };
    };

    const fetchInfo = async () => {
        await new Promise((res) => setTimeout(res, 500));

        setInfo([
            {
                year: 2026,
                count: 125,
            },
            {
                year: 2025,
                count: 80,
            },
            {
                year: 2024,
                count: 50,
            },
            {
                year: 2023,
                count: 60,
            },
            {
                year: 2022,
                count: 20,
            },
            {
                year: 2021,
                count: 34,
            },
            {
                year: 2020,
                count: 40,
            },
        ]);
    };

    const fetchAdditionalInfo = async () => {
        await new Promise((res) => setTimeout(res, 500));

        setAdditionalInfo(
            [...new Array(10)].map(() => {
                return {
                    name: "Название",
                    year: "2024",
                    pularCenter: "Риддер",
                    operator: "Актив",
                    type: "4G",
                };
            })
        );
    };

    useEffect(() => {
        /* prettier-ignore */
        Promise.allSettled([
            fetchInfo(), 
            fetchAdditionalInfo(),
        ])
        .finally(() => 
            setLoading(false)
        );
    }, []);

    if (loading) {
        return <h1 className="internet-page-modal-loader">Загрузка...</h1>;
    }

    return (
        <div className="internet-page-modal">
            <div>
                <BarChart
                    data={getChartDataByPural()}
                    isLegend={false}
                    responsive
                    chartHeight={400}
                    chartWidth={1000}
                    onlyGraph
                    withTicksOnX
                />
            </div>

            <div>
                <table className="table">
                    <thead className="table-header">
                        <tr className="row">
                            <th className="cell">#</th>
                            <th className="cell">Имя</th>
                            <th className="cell">Год</th>
                            <th className="cell">НП</th>
                            <th className="cell">Оператор</th>
                            <th className="cell">Тип</th>
                        </tr>
                    </thead>

                    <tbody>
                        {additionalInfo.map(
                            (
                                { name, year, pularCenter, operator, type },
                                index
                            ) => {
                                return (
                                    <tr className="row" key={index}>
                                        <td className="cell">{index + 1}</td>
                                        <td className="cell">{name}</td>
                                        <td className="cell">{year}</td>
                                        <td className="cell">{pularCenter}</td>
                                        <td className="cell">{operator}</td>
                                        <td className="cell">{type}</td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PopulationCommunicationsModal;
