import { BarChartData, DebtFilter, DebtsDistrict, DebtsTopfive, DonutChartData, GeneralDebt } from "../types/debts";
import {
    IPowerGenerationLateralInflow,
    IPowerGenerationStatisticData, IPowerPlantAverage, IPowerPlant, IPowerPlantSummary,
    IPowerGenerationStatistic
} from "../types/power-generation";
import axiosInstance from "./axiosInstance";

type TResult<DataType> =
    | {
    isError: true;
    error: string;
}
    | {
    isError: false;
    data: DataType;
};

export const getPowerGenerationStatistic = async (startDate?: string, endDate?: string): Promise<TResult<IPowerGenerationStatisticData>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/electricity-generation/', {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getLateralInflow = async (startDate?: string, endDate?: string): Promise<TResult<IPowerGenerationLateralInflow[]>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/river-average-daily-output/', {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getPowerPlantAverage = async (startDate?: string, endDate?: string, id?: number): Promise<TResult<IPowerPlantAverage[]>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/power-plant-average-daily-output/', {
            params: {
                start_date: startDate,
                end_date: endDate,
                id: id
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getPowerPlantSummary = async (startDate?: string, endDate?: string): Promise<TResult<IPowerPlantSummary>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/powerplant-generation-summary/', {
            params: {
                date_start: startDate,
                date_end: endDate,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getPowerPlants = async (): Promise<TResult<IPowerPlant[]>> => {
    try {
        const response = await axiosInstance.get('/api/core/powerplants/');
        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getPowerPlantTopFive = async (startDate: string, endDate: string): Promise<TResult<IPowerGenerationStatistic[]>> => {
    try {
        const response = await axiosInstance.get('api/house_and_utilities/top-electricity-generation/', {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getGeneralDebts = async (startDate: string, endDate: string, searchQuery?: string, krp?: string[], kse?: string[], activity?: string[], districtId?: string): Promise<TResult<GeneralDebt>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/debt-payment-records/?', {
            params: {
                start_date: startDate,
                end_date: endDate,
                search_query: searchQuery,
                krp_name: krp?.length ? krp : undefined,
                kse_name: kse?.length ? kse : undefined,
                main_activity_name: activity?.length ? activity : undefined,
                district_id: districtId,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getDebtsBarChartData = async (startDate: string, endDate: string, districtId?: string, krp?: string[], kse?: string[], activity?: string[]): Promise<TResult<BarChartData>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/outstanding-amount-by-kse/', {
            params: {
                start_date: startDate,
                end_date: endDate,
                district_id: districtId,
                krp_name: krp?.length ? krp : undefined,
                kse_name: kse?.length ? kse : undefined,
                main_activity_name: activity?.length ? activity : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getDebtsDonutChartData = async (startDate: string, endDate: string, districtId?: string, krp?: string[], kse?: string[], activity?: string[]): Promise<TResult<DonutChartData>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/debt-summary/', {
            params: {
                start_date: startDate,
                end_date: endDate,
                district_id: districtId,
                krp_name: krp?.length ? krp : undefined,
                kse_name: kse?.length ? kse : undefined,
                main_activity_name: activity?.length ? activity : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getFilter = async (): Promise<TResult<DebtFilter>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/unique-codes/');

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getDebtsDistricts = async (endDate: string, krp?: string[], kse?: string[], activity?: string[]): Promise<TResult<DebtsDistrict[]>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/district-debt-summary/', {
            params: {
                end_date: endDate,
                krp_name: krp?.length ? krp : undefined,
                kse_name: kse?.length ? kse : undefined,
                main_activity_name: activity?.length ? activity : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getDebtsFile = async (startDate?: string, endDate?: string): Promise<TResult<Blob>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/debt-payment-report/', {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
            responseType: 'blob'
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};

export const getDebtsTopFive = async (startDate: string, endDate: string, districtId: number): Promise<TResult<DebtsTopfive[]>> => {
    try {
        const response = await axiosInstance.get('/api/house_and_utilities/top-legal-entities-debt/', {
            params: {
                start_date: startDate,
                end_date: endDate,
                district_id: districtId,
            },
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};