import {LatLngExpression} from "leaflet";

export const altaiDistrict: LatLngExpression[] = [
    [
        82.8934114,
        49.8408387
    ],
    [
        82.9084683,
        49.8488765
    ],
    [
        82.9145322,
        49.863381
    ],
    [
        82.9080609,
        49.8738646
    ],
    [
        82.9079704,
        49.8740112
    ],
    [
        82.9095523,
        49.8750684
    ],
    [
        82.9101089,
        49.8764843
    ],
    [
        82.9117786,
        49.8782399
    ],
    [
        82.9150888,
        49.8790328
    ],
    [
        82.9193658,
        49.8805052
    ],
    [
        82.9209476,
        49.8819209
    ],
    [
        82.9224416,
        49.8839594
    ],
    [
        82.9237306,
        49.8854883
    ],
    [
        82.9271872,
        49.886734
    ],
    [
        82.9278464,
        49.8866019
    ],
    [
        82.9288131,
        49.8869228
    ],
    [
        82.9294136,
        49.8873427
    ],
    [
        82.9288785,
        49.8880447
    ],
    [
        82.9293733,
        49.8882321
    ],
    [
        82.9299262,
        49.8883548
    ],
    [
        82.9304901,
        49.8887724
    ],
    [
        82.9310174,
        49.889013
    ],
    [
        82.9315667,
        49.8890885
    ],
    [
        82.9321306,
        49.8890083
    ],
    [
        82.9334488,
        49.8889941
    ],
    [
        82.935331,
        49.889315
    ],
    [
        82.9376179,
        49.889984
    ],
    [
        82.9382384,
        49.8906361
    ],
    [
        82.9387071,
        49.8915183
    ],
    [
        82.939725,
        49.8918958
    ],
    [
        82.9403622,
        49.8918109
    ],
    [
        82.9408529,
        49.8925657
    ],
    [
        82.9414534,
        49.8929196
    ],
    [
        82.9418831,
        49.8929561
    ],
    [
        82.9431744,
        49.8930658
    ],
    [
        82.9455399,
        49.8935188
    ],
    [
        82.9467702,
        49.8933489
    ],
    [
        82.9488647,
        49.8944152
    ],
    [
        82.9503734,
        49.8942359
    ],
    [
        82.9518381,
        49.8948492
    ],
    [
        82.9536836,
        49.8945756
    ],
    [
        82.9545038,
        49.8955191
    ],
    [
        82.9562761,
        49.8954531
    ],
    [
        82.9579605,
        49.8968117
    ],
    [
        82.9591908,
        49.8971325
    ],
    [
        82.9598939,
        49.8979439
    ],
    [
        82.9610364,
        49.8980854
    ],
    [
        82.9612268,
        49.8983968
    ],
    [
        82.9616955,
        49.8984534
    ],
    [
        82.9626768,
        49.8982741
    ],
    [
        82.963043,
        49.8977552
    ],
    [
        82.9636435,
        49.8978213
    ],
    [
        82.9641854,
        49.8986327
    ],
    [
        82.9656355,
        49.8990289
    ],
    [
        82.9668365,
        49.899812
    ],
    [
        82.9678325,
        49.8999346
    ],
    [
        82.9690482,
        49.900812
    ],
    [
        82.9712013,
        49.9012366
    ],
    [
        82.9715382,
        49.9023781
    ],
    [
        82.9721827,
        49.9032743
    ],
    [
        82.9731494,
        49.9035573
    ],
    [
        82.973706,
        49.9040384
    ],
    [
        82.9743065,
        49.9052459
    ],
    [
        82.9770601,
        49.9072363
    ],
    [
        82.9852478,
        49.9106982
    ],
    [
        82.9851892,
        49.9108113
    ],
    [
        82.987767,
        49.9105095
    ],
    [
        82.9906671,
        49.909189
    ],
    [
        82.9923662,
        49.9093587
    ],
    [
        82.9935086,
        49.9088305
    ],
    [
        82.9950319,
        49.9092267
    ],
    [
        82.9969946,
        49.9088682
    ],
    [
        82.998928,
        49.9076608
    ],
    [
        83.0005685,
        49.9074533
    ],
    [
        83.0020039,
        49.9078118
    ],
    [
        83.0030292,
        49.9076231
    ],
    [
        83.0045524,
        49.9067364
    ],
    [
        83.0074232,
        49.9069251
    ],
    [
        83.0097668,
        49.9059251
    ],
    [
        83.0134871,
        49.905095
    ],
    [
        83.0151861,
        49.9043969
    ],
    [
        83.017515,
        49.9042271
    ],
    [
        83.0179471,
        49.9045714
    ],
    [
        83.0181155,
        49.9049535
    ],
    [
        83.0188772,
        49.9053025
    ],
    [
        83.0192287,
        49.9056704
    ],
    [
        83.0201075,
        49.9058214
    ],
    [
        83.0206202,
        49.9060949
    ],
    [
        83.0209424,
        49.9066326
    ],
    [
        83.0216308,
        49.906727
    ],
    [
        83.021997,
        49.9076231
    ],
    [
        83.0226268,
        49.9084438
    ],
    [
        83.0251021,
        49.9091701
    ],
    [
        83.025937,
        49.9101228
    ],
    [
        83.0349664,
        49.9080006
    ],
    [
        83.050647,
        49.9100514
    ],
    [
        83.0508082,
        49.9133481
    ],
    [
        83.064957,
        49.9179423
    ],
    [
        83.0641105,
        49.9243008
    ],
    [
        83.0561694,
        49.9341873
    ],
    [
        83.043109,
        49.9442793
    ],
    [
        83.0364982,
        49.9540839
    ],
    [
        83.0515467,
        49.9579339
    ],
    [
        83.0561485,
        49.9569757
    ],
    [
        83.059189,
        49.9569493
    ],
    [
        83.0651055,
        49.9562091
    ],
    [
        83.0665025,
        49.9553368
    ],
    [
        83.0683103,
        49.9554425
    ],
    [
        83.0689266,
        49.9564734
    ],
    [
        83.0725012,
        49.9563677
    ],
    [
        83.0774317,
        49.9569757
    ],
    [
        83.0805955,
        49.9564999
    ],
    [
        83.084129,
        49.9546494
    ],
    [
        83.0881555,
        49.9536185
    ],
    [
        83.0908673,
        49.9532483
    ],
    [
        83.0924697,
        49.9538299
    ],
    [
        83.0951815,
        49.9533805
    ],
    [
        83.1010569,
        49.9520851
    ],
    [
        83.1050423,
        49.9499436
    ],
    [
        83.1062339,
        49.9499966
    ],
    [
        83.1099728,
        49.9511334
    ],
    [
        83.1089456,
        49.9507633
    ],
    [
        83.1107124,
        49.9511995
    ],
    [
        83.1130544,
        49.9510805
    ],
    [
        83.1144719,
        49.9513846
    ],
    [
        83.1153039,
        49.9533276
    ],
    [
        83.1160537,
        49.9535788
    ],
    [
        83.1188169,
        49.9533409
    ],
    [
        83.1213745,
        49.9525213
    ],
    [
        83.128873,
        49.9554028
    ],
    [
        83.1325708,
        49.955231
    ],
    [
        83.134276,
        49.9559844
    ],
    [
        83.1369466,
        49.9567642
    ],
    [
        83.1384052,
        49.9582974
    ],
    [
        83.1394735,
        49.9590111
    ],
    [
        83.1398433,
        49.9599891
    ],
    [
        83.1420209,
        49.9606763
    ],
    [
        83.1439931,
        49.9612314
    ],
    [
        83.1536898,
        49.9629891
    ],
    [
        83.1571821,
        49.9597248
    ],
    [
        83.1584969,
        49.9537771
    ],
    [
        83.1687688,
        49.9531426
    ],
    [
        83.1859844,
        49.9472734
    ],
    [
        83.1988036,
        49.9386534
    ],
    [
        83.1974888,
        49.9348982
    ],
    [
        83.2100616,
        49.9304286
    ],
    [
        83.2075552,
        49.9230224
    ],
    [
        83.2000362,
        49.9195041
    ],
    [
        83.2052955,
        49.9108791
    ],
    [
        83.2119105,
        49.9065396
    ],
    [
        83.223908,
        49.9066189
    ],
    [
        83.2287152,
        49.9001089
    ],
    [
        83.2318378,
        49.8986798
    ],
    [
        83.2355357,
        49.8916393
    ],
    [
        83.2388637,
        49.8917452
    ],
    [
        83.2418632,
        49.8905805
    ],
    [
        83.2498752,
        49.8927246
    ],
    [
        83.2611743,
        49.903417
    ],
    [
        83.275226,
        49.8928833
    ],
    [
        83.2972488,
        49.8950273
    ],
    [
        83.2988923,
        49.8964036
    ],
    [
        83.3001045,
        49.9002147
    ],
    [
        83.300536,
        49.9029141
    ],
    [
        83.3007722,
        49.9035393
    ],
    [
        83.3013577,
        49.9063808
    ],
    [
        83.3041105,
        49.9079948
    ],
    [
        83.307151,
        49.9081271
    ],
    [
        83.3116501,
        49.9102439
    ],
    [
        83.3128622,
        49.9103762
    ],
    [
        83.3143002,
        49.911342
    ],
    [
        83.3161902,
        49.9139085
    ],
    [
        83.3181624,
        49.9152843
    ],
    [
        83.3205249,
        49.9157076
    ],
    [
        83.3220452,
        49.9154298
    ],
    [
        83.3270732,
        49.9166071
    ],
    [
        83.3296617,
        49.917715
    ],
    [
        83.3107462,
        49.9308518
    ],
    [
        83.3199292,
        49.9448673
    ],
    [
        83.3216137,
        49.9462157
    ],
    [
        83.3279823,
        49.9503138
    ],
    [
        83.3477042,
        49.9583501
    ],
    [
        83.3581404,
        49.9616807
    ],
    [
        83.3857926,
        49.9587165
    ],
    [
        83.3878054,
        49.9704815
    ],
    [
        83.4049799,
        49.9822664
    ],
    [
        83.4067056,
        49.9895579
    ],
    [
        83.3713704,
        49.9863349
    ],
    [
        83.3574829,
        49.9919353
    ],
    [
        83.3045624,
        50.0038732
    ],
    [
        83.2679947,
        50.0160196
    ],
    [
        83.2667621,
        50.0357638
    ],
    [
        83.256819,
        50.0535479
    ],
    [
        83.2517241,
        50.0598263
    ],
    [
        83.2513133,
        50.0664729
    ],
    [
        83.2559972,
        50.0761775
    ],
    [
        83.2518885,
        50.0817674
    ],
    [
        83.2636395,
        50.0995871
    ],
    [
        83.2653651,
        50.1112875
    ],
    [
        83.2758835,
        50.1256195
    ],
    [
        83.3057426,
        50.1398768
    ],
    [
        83.3222436,
        50.1421322
    ],
    [
        83.3418131,
        50.1418159
    ],
    [
        83.3438944,
        50.1381165
    ],
    [
        83.3686994,
        50.1345405
    ],
    [
        83.3820246,
        50.1338941
    ],
    [
        83.3974527,
        50.1311432
    ],
    [
        83.4171723,
        50.1329588
    ],
    [
        83.4397458,
        50.1390517
    ],
    [
        83.4456896,
        50.144305
    ],
    [
        83.4475778,
        50.1515926
    ],
    [
        83.4558606,
        50.1572294
    ],
    [
        83.472619,
        50.1510839
    ],
    [
        83.5134958,
        50.1659033
    ],
    [
        83.5210705,
        50.1671541
    ],
    [
        83.5196542,
        50.1802785
    ],
    [
        83.5140966,
        50.1837136
    ],
    [
        83.5212636,
        50.1953912
    ],
    [
        83.5677193,
        50.192891
    ],
    [
        83.6009144,
        50.1891818
    ],
    [
        83.6420059,
        50.1884262
    ],
    [
        83.6703299,
        50.194773
    ],
    [
        83.6795675,
        50.1888315
    ],
    [
        83.7110995,
        50.1972456
    ],
    [
        83.7234591,
        50.1975752
    ],
    [
        83.7270211,
        50.1983101
    ],
    [
        83.7289094,
        50.1986329
    ],
    [
        83.7308191,
        50.1988252
    ],
    [
        83.7328361,
        50.1983788
    ],
    [
        83.7345742,
        50.1981041
    ],
    [
        83.7362908,
        50.1980629
    ],
    [
        83.7377607,
        50.1983307
    ],
    [
        83.7392091,
        50.1985093
    ],
    [
        83.7430714,
        50.1986604
    ],
    [
        83.7436937,
        50.1985505
    ],
    [
        83.7452172,
        50.1985367
    ],
    [
        83.7457805,
        50.1987634
    ],
    [
        83.7463598,
        50.1992063
    ],
    [
        83.7468909,
        50.199821
    ],
    [
        83.7479423,
        50.200082
    ],
    [
        83.7482642,
        50.2007069
    ],
    [
        83.7492298,
        50.2009748
    ],
    [
        83.7499701,
        50.2014692
    ],
    [
        83.7505065,
        50.2020735
    ],
    [
        83.7511824,
        50.2024169
    ],
    [
        83.7515043,
        50.2025268
    ],
    [
        83.751515,
        50.2027946
    ],
    [
        83.7513541,
        50.203289
    ],
    [
        83.7517511,
        50.2036942
    ],
    [
        83.7532853,
        50.2036255
    ],
    [
        83.7537896,
        50.2039552
    ],
    [
        83.7543153,
        50.203962
    ],
    [
        83.7552379,
        50.2035706
    ],
    [
        83.7556993,
        50.2036187
    ],
    [
        83.756976,
        50.2048273
    ],
    [
        83.7584673,
        50.2046968
    ],
    [
        83.7588536,
        50.2048685
    ],
    [
        83.7590252,
        50.2053285
    ],
    [
        83.7605487,
        50.2059534
    ],
    [
        83.7608599,
        50.2063242
    ],
    [
        83.7612246,
        50.2064753
    ],
    [
        83.7620186,
        50.2067362
    ],
    [
        83.7632417,
        50.2074916
    ],
    [
        83.7633704,
        50.2080066
    ],
    [
        83.7647866,
        50.2085765
    ],
    [
        83.7645184,
        50.2092356
    ],
    [
        83.7645291,
        50.2095172
    ],
    [
        83.7648724,
        50.2096614
    ],
    [
        83.7675332,
        50.2097918
    ],
    [
        83.7691211,
        50.2091601
    ],
    [
        83.769636,
        50.2086795
    ],
    [
        83.7719749,
        50.2081439
    ],
    [
        83.7725865,
        50.2081233
    ],
    [
        83.7731658,
        50.2083842
    ],
    [
        83.7733482,
        50.2090296
    ],
    [
        83.7729513,
        50.2105608
    ],
    [
        83.7734448,
        50.2131149
    ],
    [
        83.7740456,
        50.2137123
    ],
    [
        83.7749468,
        50.2140967
    ],
    [
        83.7752687,
        50.2144812
    ],
    [
        83.7782298,
        50.2156689
    ],
    [
        83.7795173,
        50.2160053
    ],
    [
        83.7818669,
        50.2161976
    ],
    [
        83.782854,
        50.2161976
    ],
    [
        83.7837015,
        50.2157444
    ],
    [
        83.7845277,
        50.2155316
    ],
    [
        83.7860833,
        50.2153257
    ],
    [
        83.7869738,
        50.2153394
    ],
    [
        83.7879073,
        50.2150716
    ],
    [
        83.7884973,
        50.2147421
    ],
    [
        83.7893342,
        50.2147215
    ],
    [
        83.7901281,
        50.2144606
    ],
    [
        83.7917804,
        50.2145499
    ],
    [
        83.7929927,
        50.2140899
    ],
    [
        83.7933253,
        50.2138358
    ],
    [
        83.7945591,
        50.2133278
    ],
    [
        83.7954604,
        50.2131836
    ],
    [
        83.7958788,
        50.2128403
    ],
    [
        83.7965547,
        50.2127167
    ],
    [
        83.7969946,
        50.2128746
    ],
    [
        83.7984322,
        50.212957
    ],
    [
        83.7992584,
        50.2136985
    ],
    [
        83.7996339,
        50.2138015
    ],
    [
        83.8002025,
        50.2137809
    ],
    [
        83.8005029,
        50.2138152
    ],
    [
        83.8011574,
        50.2140487
    ],
    [
        83.801608,
        50.214083
    ],
    [
        83.8025521,
        50.2138702
    ],
    [
        83.8034104,
        50.2139251
    ],
    [
        83.8039683,
        50.2141997
    ],
    [
        83.8046335,
        50.2143851
    ],
    [
        83.8048588,
        50.2147146
    ],
    [
        83.804773,
        50.2150442
    ],
    [
        83.8049554,
        50.2152913
    ],
    [
        83.8055992,
        50.2156346
    ],
    [
        83.8060901,
        50.2153847
    ],
    [
        83.8065433,
        50.215154
    ],
    [
        83.8075839,
        50.2148245
    ],
    [
        83.8090645,
        50.2155728
    ],
    [
        83.8096546,
        50.2155522
    ],
    [
        83.8096984,
        50.215464
    ],
    [
        83.8098048,
        50.2152501
    ],
    [
        83.8094829,
        50.2146872
    ],
    [
        83.8098155,
        50.2140006
    ],
    [
        83.8094722,
        50.2131973
    ],
    [
        83.8093435,
        50.2122224
    ],
    [
        83.8095151,
        50.211577
    ],
    [
        83.8098585,
        50.2110964
    ],
    [
        83.8110601,
        50.2106569
    ],
    [
        83.8119077,
        50.2105677
    ],
    [
        83.8124441,
        50.2102793
    ],
    [
        83.8130449,
        50.2089404
    ],
    [
        83.8141285,
        50.2087138
    ],
    [
        83.8145255,
        50.2081095
    ],
    [
        83.8146542,
        50.2073062
    ],
    [
        83.8139998,
        50.2068392
    ],
    [
        83.8138067,
        50.2055689
    ],
    [
        83.8139676,
        50.2050401
    ],
    [
        83.8139569,
        50.2045801
    ],
    [
        83.8142573,
        50.2038315
    ],
    [
        83.8148366,
        50.2034333
    ],
    [
        83.8159417,
        50.2031517
    ],
    [
        83.8171326,
        50.2031998
    ],
    [
        83.8183664,
        50.2035363
    ],
    [
        83.8190316,
        50.2037903
    ],
    [
        83.8198899,
        50.2042985
    ],
    [
        83.820834,
        50.2044564
    ],
    [
        83.8217567,
        50.2043191
    ],
    [
        83.8223255,
        50.2038247
    ],
    [
        83.822143,
        50.2032959
    ],
    [
        83.8222181,
        50.2028427
    ],
    [
        83.8214885,
        50.2025337
    ],
    [
        83.8212739,
        50.2022521
    ],
    [
        83.8210272,
        50.2017508
    ],
    [
        83.8211666,
        50.2014967
    ],
    [
        83.8217246,
        50.2011808
    ],
    [
        83.8220357,
        50.2009473
    ],
    [
        83.8224005,
        50.2001438
    ],
    [
        83.8222181,
        50.1997317
    ],
    [
        83.8221322,
        50.1990243
    ],
    [
        83.8223468,
        50.1986878
    ],
    [
        83.8228618,
        50.1986191
    ],
    [
        83.8236665,
        50.1989625
    ],
    [
        83.8250719,
        50.1990175
    ],
    [
        83.8262414,
        50.1986672
    ],
    [
        83.8266705,
        50.198729
    ],
    [
        83.8271962,
        50.199251
    ],
    [
        83.8279151,
        50.1992166
    ],
    [
        83.8283765,
        50.1988458
    ],
    [
        83.8296853,
        50.1985436
    ],
    [
        83.8301252,
        50.198626
    ],
    [
        83.8301359,
        50.1989763
    ],
    [
        83.8299106,
        50.1997111
    ],
    [
        83.8305115,
        50.2000888
    ],
    [
        83.8315951,
        50.2004734
    ],
    [
        83.8332151,
        50.2008374
    ],
    [
        83.8337408,
        50.2011464
    ],
    [
        83.8345991,
        50.2014623
    ],
    [
        83.8358973,
        50.2022246
    ],
    [
        83.8368629,
        50.202362
    ],
    [
        83.8379573,
        50.2023345
    ],
    [
        83.8391804,
        50.2024924
    ],
    [
        83.8400494,
        50.2031654
    ],
    [
        83.8402532,
        50.2036599
    ],
    [
        83.8406824,
        50.2040856
    ],
    [
        83.841369,
        50.2042024
    ],
    [
        83.8419269,
        50.2040994
    ],
    [
        83.8425063,
        50.2036324
    ],
    [
        83.8430536,
        50.2034745
    ],
    [
        83.843783,
        50.2036461
    ],
    [
        83.8448559,
        50.2041268
    ],
    [
        83.8460683,
        50.2043397
    ],
    [
        83.8473986,
        50.2039552
    ],
    [
        83.8474094,
        50.2037423
    ],
    [
        83.8470661,
        50.2034745
    ],
    [
        83.8466584,
        50.2034058
    ],
    [
        83.8464867,
        50.2031654
    ],
    [
        83.8467656,
        50.2029251
    ],
    [
        83.8473021,
        50.2027397
    ],
    [
        83.8485574,
        50.202671
    ],
    [
        83.8503598,
        50.2024444
    ],
    [
        83.8512718,
        50.202671
    ],
    [
        83.8515722,
        50.202877
    ],
    [
        83.8518833,
        50.2039277
    ],
    [
        83.8523768,
        50.2042367
    ],
    [
        83.8536645,
        50.2045022
    ],
    [
        83.8540934,
        50.2046831
    ],
    [
        83.8543938,
        50.2054934
    ],
    [
        83.8548337,
        50.2057406
    ],
    [
        83.8555418,
        50.2058985
    ],
    [
        83.8562285,
        50.2062006
    ],
    [
        83.857237,
        50.2065852
    ],
    [
        83.8583635,
        50.2066813
    ],
    [
        83.8592326,
        50.2070933
    ],
    [
        83.8600694,
        50.2073748
    ],
    [
        83.8605629,
        50.207828
    ],
    [
        83.861153,
        50.2079654
    ],
    [
        83.8619684,
        50.208652
    ],
    [
        83.8626336,
        50.2090228
    ],
    [
        83.8634919,
        50.2091807
    ],
    [
        83.8641679,
        50.2091326
    ],
    [
        83.8651227,
        50.2092906
    ],
    [
        83.866378,
        50.2091532
    ],
    [
        83.8668608,
        50.2092562
    ],
    [
        83.867998,
        50.2090846
    ],
    [
        83.8691676,
        50.2084666
    ],
    [
        83.8717746,
        50.207725
    ],
    [
        83.8724934,
        50.2071963
    ],
    [
        83.8728582,
        50.2067019
    ],
    [
        83.8734268,
        50.2065234
    ],
    [
        83.8740276,
        50.2065646
    ],
    [
        83.8745641,
        50.206132
    ],
    [
        83.8755082,
        50.2062487
    ],
    [
        83.8769351,
        50.2063311
    ],
    [
        83.8782333,
        50.2061869
    ],
    [
        83.8798534,
        50.2064684
    ],
    [
        83.8814842,
        50.2066126
    ],
    [
        83.8816987,
        50.2063723
    ],
    [
        83.8816451,
        50.205974
    ],
    [
        83.8820421,
        50.2058573
    ],
    [
        83.8833081,
        50.2058985
    ],
    [
        83.8852929,
        50.2053629
    ],
    [
        83.8860332,
        50.2054316
    ],
    [
        83.8868164,
        50.2051981
    ],
    [
        83.8887476,
        50.2053629
    ],
    [
        83.8902925,
        50.20572
    ],
    [
        83.8920521,
        50.2070384
    ],
    [
        83.8933395,
        50.2074984
    ],
    [
        83.8941656,
        50.2075877
    ],
    [
        83.8971268,
        50.2090159
    ],
    [
        83.8980495,
        50.2096888
    ],
    [
        83.9011287,
        50.2109247
    ],
    [
        83.9013218,
        50.211371
    ],
    [
        83.9024449,
        50.2117905
    ],
    [
        83.9180052,
        50.2017267
    ],
    [
        83.9407234,
        50.2020358
    ],
    [
        83.9555991,
        50.2188475
    ],
    [
        83.9583885,
        50.2348469
    ],
    [
        83.9676903,
        50.2412356
    ],
    [
        83.9910578,
        50.2407004
    ],
    [
        84.0002202,
        50.2433352
    ],
    [
        83.9998769,
        50.2584416
    ],
    [
        84.0006493,
        50.2713901
    ],
    [
        83.9777111,
        50.2780824
    ],
    [
        83.9838051,
        50.2869126
    ],
    [
        83.9840196,
        50.2939729
    ],
    [
        84.0014218,
        50.2943704
    ],
    [
        84.0099191,
        50.2886264
    ],
    [
        84.0121936,
        50.2865425
    ],
    [
        84.0143394,
        50.2855279
    ],
    [
        84.0174722,
        50.285473
    ],
    [
        84.0192318,
        50.2858295
    ],
    [
        84.0200901,
        50.2865425
    ],
    [
        84.0213775,
        50.2881328
    ],
    [
        84.023137,
        50.290107
    ],
    [
        84.0245533,
        50.2902715
    ],
    [
        84.0252828,
        50.2889828
    ],
    [
        84.0265274,
        50.288078
    ],
    [
        84.0290594,
        50.2879957
    ],
    [
        84.0310764,
        50.2883796
    ],
    [
        84.0332651,
        50.289449
    ],
    [
        84.03481,
        50.2911763
    ],
    [
        84.0374708,
        50.2937535
    ],
    [
        84.0391874,
        50.2956727
    ],
    [
        84.0411615,
        50.2969612
    ],
    [
        84.0446376,
        50.297345
    ],
    [
        84.0485,
        50.296358
    ],
    [
        84.0521478,
        50.2950969
    ],
    [
        84.0564394,
        50.2932326
    ],
    [
        84.0607309,
        50.2910118
    ],
    [
        84.0642499,
        50.2898054
    ],
    [
        84.0690994,
        50.2897231
    ],
    [
        84.0750217,
        50.2898602
    ],
    [
        84.0783262,
        50.2891748
    ],
    [
        84.0872097,
        50.2894215
    ],
    [
        84.0995264,
        50.2902167
    ],
    [
        84.1034317,
        50.2913134
    ],
    [
        84.1064786,
        50.292931
    ],
    [
        84.1155338,
        50.2949324
    ],
    [
        84.1236877,
        50.2940277
    ],
    [
        84.1264772,
        50.2930407
    ],
    [
        84.1283655,
        50.290957
    ],
    [
        84.1308546,
        50.2877489
    ],
    [
        84.1342878,
        50.2857198
    ],
    [
        84.136219,
        50.2840471
    ],
    [
        84.1373777,
        50.2822372
    ],
    [
        84.1370344,
        50.2785897
    ],
    [
        84.1379785,
        50.2767522
    ],
    [
        84.1402101,
        50.2760665
    ],
    [
        84.1488361,
        50.2744758
    ],
    [
        84.1522264,
        50.2743386
    ],
    [
        84.1548872,
        50.2759569
    ],
    [
        84.1563463,
        50.2771637
    ],
    [
        84.160037,
        50.2774928
    ],
    [
        84.1621398,
        50.277959
    ],
    [
        84.1635561,
        50.2789737
    ],
    [
        84.1693067,
        50.2809208
    ],
    [
        84.1733837,
        50.2815241
    ],
    [
        84.1809368,
        50.282292
    ],
    [
        84.1864299,
        50.2853908
    ],
    [
        84.1920948,
        50.2904633
    ],
    [
        84.1946268,
        50.2947405
    ],
    [
        84.1977596,
        50.300662
    ],
    [
        84.1974592,
        50.3025259
    ],
    [
        84.1957855,
        50.3033757
    ],
    [
        84.1936826,
        50.303595
    ],
    [
        84.188919,
        50.3038417
    ],
    [
        84.18437,
        50.3055959
    ],
    [
        84.1787481,
        50.3093509
    ],
    [
        84.1762161,
        50.311982
    ],
    [
        84.1784477,
        50.3154076
    ],
    [
        84.1819238,
        50.3186687
    ],
    [
        84.1832113,
        50.3192714
    ],
    [
        84.1918373,
        50.3177917
    ],
    [
        84.2012786,
        50.3178739
    ],
    [
        84.2058706,
        50.3173532
    ],
    [
        84.2129516,
        50.3137085
    ],
    [
        84.2166423,
        50.3109953
    ],
    [
        84.229002,
        50.3063634
    ],
    [
        84.2358255,
        50.3048834
    ],
    [
        84.2435073,
        50.3061167
    ],
    [
        84.254837,
        50.3089672
    ],
    [
        84.2591285,
        50.3093235
    ],
    [
        84.2622297,
        50.3101543
    ],
    [
        84.2603348,
        50.3073527
    ],
    [
        84.2597117,
        50.3010523
    ],
    [
        84.2518466,
        50.2971724
    ],
    [
        84.2507044,
        50.2951328
    ],
    [
        84.2506785,
        50.2925126
    ],
    [
        84.251587,
        50.2901411
    ],
    [
        84.2560518,
        50.2893119
    ],
    [
        84.2549615,
        50.2861109
    ],
    [
        84.2576871,
        50.2834903
    ],
    [
        84.2599195,
        50.2825117
    ],
    [
        84.2605944,
        50.2788624
    ],
    [
        84.2648255,
        50.2761086
    ],
    [
        84.265734,
        50.273222
    ],
    [
        84.2714967,
        50.2712975
    ],
    [
        84.2747495,
        50.2682557
    ],
    [
        84.2787915,
        50.2669981
    ],
    [
        84.2786048,
        50.2642363
    ],
    [
        84.280651,
        50.2615431
    ],
    [
        84.2832857,
        50.2605685
    ],
    [
        84.2849441,
        50.2587815
    ],
    [
        84.288448,
        50.2573536
    ],
    [
        84.2904408,
        50.2570714
    ],
    [
        84.2902,
        50.2561394
    ],
    [
        84.2916578,
        50.2539076
    ],
    [
        84.2939448,
        50.2522828
    ],
    [
        84.2969138,
        50.2513593
    ],
    [
        84.300498,
        50.2484516
    ],
    [
        84.3032531,
        50.2477161
    ],
    [
        84.3080945,
        50.2482464
    ],
    [
        84.3107096,
        50.2468052
    ],
    [
        84.3183194,
        50.2470276
    ],
    [
        84.3200903,
        50.2443136
    ],
    [
        84.3194095,
        50.2424266
    ],
    [
        84.3208601,
        50.2401855
    ],
    [
        84.3249793,
        50.2389024
    ],
    [
        84.3235884,
        50.235994
    ],
    [
        84.3259685,
        50.2329756
    ],
    [
        84.3258485,
        50.2285811
    ],
    [
        84.329085,
        50.2264592
    ],
    [
        84.3342741,
        50.2256036
    ],
    [
        84.3367349,
        50.2216504
    ],
    [
        84.3394632,
        50.2204867
    ],
    [
        84.3473983,
        50.218383
    ],
    [
        84.3574604,
        50.2138081
    ],
    [
        84.3594452,
        50.2117952
    ],
    [
        84.3618265,
        50.2086679
    ],
    [
        84.3615778,
        50.2071083
    ],
    [
        84.3625226,
        50.2054649
    ],
    [
        84.3668318,
        50.2064617
    ],
    [
        84.37112,
        50.2092937
    ],
    [
        84.371403,
        50.2116777
    ],
    [
        84.3765497,
        50.2144663
    ],
    [
        84.3850833,
        50.2152607
    ],
    [
        84.3910652,
        50.2158311
    ],
    [
        84.3937061,
        50.2155162
    ],
    [
        84.3992079,
        50.2137525
    ],
    [
        84.4032891,
        50.2096671
    ],
    [
        84.4074208,
        50.2088382
    ],
    [
        84.4098473,
        50.2092264
    ],
    [
        84.4125216,
        50.2120305
    ],
    [
        84.412569,
        50.2164529
    ],
    [
        84.4159301,
        50.2170509
    ],
    [
        84.4216721,
        50.2248435
    ],
    [
        84.4239331,
        50.2268933
    ],
    [
        84.4315582,
        50.2300155
    ],
    [
        84.4338384,
        50.236084
    ],
    [
        84.4469701,
        50.2463503
    ],
    [
        84.4568008,
        50.2472562
    ],
    [
        84.4765198,
        50.2406672
    ],
    [
        84.4769495,
        50.2388349
    ],
    [
        84.4793493,
        50.2388942
    ],
    [
        84.482009,
        50.2386007
    ],
    [
        84.48498,
        50.2392599
    ],
    [
        84.4900346,
        50.2376727
    ],
    [
        84.4919382,
        50.2363249
    ],
    [
        84.4949042,
        50.2350564
    ],
    [
        84.4964698,
        50.2340216
    ],
    [
        84.4981258,
        50.2324688
    ],
    [
        84.49894,
        50.232052
    ],
    [
        84.5001013,
        50.2317897
    ],
    [
        84.5028077,
        50.2314659
    ],
    [
        84.5069313,
        50.2284123
    ],
    [
        84.509332,
        50.2257525
    ],
    [
        84.5084941,
        50.224448
    ],
    [
        84.5124901,
        50.2207179
    ],
    [
        84.5157846,
        50.215063
    ],
    [
        84.5201126,
        50.2130023
    ],
    [
        84.5216297,
        50.2110808
    ],
    [
        84.5240652,
        50.2092387
    ],
    [
        84.5278785,
        50.2084515
    ],
    [
        84.5302577,
        50.2060354
    ],
    [
        84.5333953,
        50.2053699
    ],
    [
        84.5366859,
        50.205696
    ],
    [
        84.5413602,
        50.2073965
    ],
    [
        84.5477989,
        50.2047927
    ],
    [
        84.5540969,
        50.2034542
    ],
    [
        84.562647,
        50.1983912
    ],
    [
        84.5675221,
        50.1968412
    ],
    [
        84.5695942,
        50.1979632
    ],
    [
        84.5696638,
        50.2012537
    ],
    [
        84.5811688,
        50.2080688
    ],
    [
        84.5986706,
        50.2100696
    ],
    [
        84.6027517,
        50.2117073
    ],
    [
        84.6055569,
        50.2119278
    ],
    [
        84.6120259,
        50.2108297
    ],
    [
        84.6158706,
        50.2109803
    ],
    [
        84.6281869,
        50.2106789
    ],
    [
        84.6339276,
        50.2071385
    ],
    [
        84.6498239,
        50.2001086
    ],
    [
        84.6584938,
        50.1997841
    ],
    [
        84.66137,
        50.1983222
    ],
    [
        84.6606739,
        50.1952727
    ],
    [
        84.6626991,
        50.1937373
    ],
    [
        84.6714263,
        50.1932761
    ],
    [
        84.6785772,
        50.1913609
    ],
    [
        84.6920117,
        50.1886227
    ],
    [
        84.6946697,
        50.1829185
    ],
    [
        84.6994711,
        50.1778279
    ],
    [
        84.7105207,
        50.1731235
    ],
    [
        84.7271804,
        50.1642278
    ],
    [
        84.7323641,
        50.1537314
    ],
    [
        84.7441032,
        50.1441937
    ],
    [
        84.7492686,
        50.1400029
    ],
    [
        84.7577173,
        50.1368998
    ],
    [
        84.7747927,
        50.1393227
    ],
    [
        84.7811801,
        50.1429497
    ],
    [
        84.7854656,
        50.1446079
    ],
    [
        84.7916944,
        50.144316
    ],
    [
        84.8052256,
        50.1472004
    ],
    [
        84.8063425,
        50.1466761
    ],
    [
        84.8175394,
        50.1451985
    ],
    [
        84.8253745,
        50.1429846
    ],
    [
        84.8134244,
        50.1361621
    ],
    [
        84.8151104,
        50.1318951
    ],
    [
        84.8132264,
        50.1288199
    ],
    [
        84.8142713,
        50.1249682
    ],
    [
        84.8139079,
        50.120772
    ],
    [
        84.8166148,
        50.1166971
    ],
    [
        84.8325813,
        50.1142754
    ],
    [
        84.8334999,
        50.1050999
    ],
    [
        84.8412514,
        50.0988472
    ],
    [
        84.8506999,
        50.0938999
    ],
    [
        84.8563631,
        50.0921128
    ],
    [
        84.8603597,
        50.0930984
    ],
    [
        84.8643733,
        50.0960678
    ],
    [
        84.87278,
        50.095991
    ],
    [
        84.8748756,
        50.097573
    ],
    [
        84.8768451,
        50.1020799
    ],
    [
        84.8838259,
        50.1019615
    ],
    [
        84.8865853,
        50.1042151
    ],
    [
        84.8963578,
        50.1002258
    ],
    [
        84.9052835,
        50.0954081
    ],
    [
        84.908574,
        50.0955906
    ],
    [
        84.9183892,
        50.0930631
    ],
    [
        84.9239949,
        50.0921596
    ],
    [
        84.9287388,
        50.0928507
    ],
    [
        84.9366773,
        50.0958828
    ],
    [
        84.942693,
        50.097015
    ],
    [
        84.9458311,
        50.0966095
    ],
    [
        84.9515999,
        50.0911999
    ],
    [
        84.9662999,
        50.0724999
    ],
    [
        84.9748274,
        50.0669581
    ],
    [
        84.9864452,
        50.0644708
    ],
    [
        85.0062454,
        50.0581669
    ],
    [
        85.0241391,
        50.0602182
    ],
    [
        85.026135,
        50.0540746
    ],
    [
        85.0355435,
        50.0524943
    ],
    [
        85.0394823,
        50.0484214
    ],
    [
        85.0335224,
        50.0418265
    ],
    [
        85.0339848,
        50.0365873
    ],
    [
        85.0323637,
        50.0333558
    ],
    [
        85.0325326,
        50.0326016
    ],
    [
        85.0366276,
        50.0268588
    ],
    [
        85.0373823,
        50.0201948
    ],
    [
        85.035365,
        50.0188758
    ],
    [
        85.0492581,
        50.0103973
    ],
    [
        85.0378305,
        50.0090701
    ],
    [
        85.0328986,
        50.0035715
    ],
    [
        85.0321626,
        49.9990559
    ],
    [
        85.0252916,
        49.9999241
    ],
    [
        85.0084594,
        49.995345
    ],
    [
        84.9996891,
        49.9905195
    ],
    [
        84.9934653,
        49.988835
    ],
    [
        84.983576,
        49.983967
    ],
    [
        84.9796927,
        49.9803063
    ],
    [
        84.9780463,
        49.9762943
    ],
    [
        84.9820148,
        49.975141
    ],
    [
        84.9894279,
        49.9741098
    ],
    [
        84.9930481,
        49.9725033
    ],
    [
        84.9947261,
        49.9701275
    ],
    [
        84.9946295,
        49.9682302
    ],
    [
        84.9729465,
        49.9542789
    ],
    [
        84.9785302,
        49.9536065
    ],
    [
        84.9849266,
        49.9499191
    ],
    [
        84.9793021,
        49.9456416
    ],
    [
        84.978111,
        49.9425262
    ],
    [
        84.9836473,
        49.9345574
    ],
    [
        84.9836972,
        49.9316846
    ],
    [
        84.9871379,
        49.9234157
    ],
    [
        84.9875942,
        49.9206429
    ],
    [
        84.9841429,
        49.9133174
    ],
    [
        84.9854889,
        49.9067722
    ],
    [
        84.9952089,
        49.9055937
    ],
    [
        85.0001047,
        49.9033687
    ],
    [
        85.0006323,
        49.9008908
    ],
    [
        85.0039838,
        49.8999075
    ],
    [
        85.004756,
        49.8982758
    ],
    [
        85.0114973,
        49.8982907
    ],
    [
        85.0196749,
        49.8946373
    ],
    [
        85.0232711,
        49.8910524
    ],
    [
        85.0344073,
        49.8888854
    ],
    [
        85.0417282,
        49.8862801
    ],
    [
        85.0546588,
        49.8863312
    ],
    [
        85.0596988,
        49.8850363
    ],
    [
        85.069781,
        49.8759228
    ],
    [
        85.0803856,
        49.8685762
    ],
    [
        85.0845827,
        49.8601758
    ],
    [
        85.0874357,
        49.858013
    ],
    [
        85.1004266,
        49.8521156
    ],
    [
        85.1008287,
        49.8453266
    ],
    [
        85.1054987,
        49.8431157
    ],
    [
        85.1043555,
        49.8411592
    ],
    [
        85.1051909,
        49.8385789
    ],
    [
        85.1049323,
        49.8344192
    ],
    [
        85.100237,
        49.827851
    ],
    [
        85.1000836,
        49.8258171
    ],
    [
        85.101607,
        49.8242126
    ],
    [
        85.1084661,
        49.8227708
    ],
    [
        85.1102666,
        49.8196772
    ],
    [
        85.1124649,
        49.8183156
    ],
    [
        85.1162021,
        49.8176631
    ],
    [
        85.1212802,
        49.817961
    ],
    [
        85.1233376,
        49.8145637
    ],
    [
        85.1285916,
        49.8128614
    ],
    [
        85.1305481,
        49.8095561
    ],
    [
        85.1323508,
        49.808109
    ],
    [
        85.1324387,
        49.8041223
    ],
    [
        85.1355603,
        49.8003481
    ],
    [
        85.139913,
        49.7980494
    ],
    [
        85.1440966,
        49.7966549
    ],
    [
        85.1445651,
        49.7964186
    ],
    [
        85.1434882,
        49.7931025
    ],
    [
        85.1396815,
        49.7906451
    ],
    [
        85.1403613,
        49.788129
    ],
    [
        85.1395003,
        49.7853787
    ],
    [
        85.1415849,
        49.7831549
    ],
    [
        85.1448704,
        49.7811213
    ],
    [
        85.1431256,
        49.778634
    ],
    [
        85.1481531,
        49.7737659
    ],
    [
        85.1489489,
        49.7703956
    ],
    [
        85.1479305,
        49.7691308
    ],
    [
        85.1487477,
        49.7679097
    ],
    [
        85.1505902,
        49.7672577
    ],
    [
        85.1560308,
        49.7673655
    ],
    [
        85.1590154,
        49.7678174
    ],
    [
        85.163943,
        49.7693233
    ],
    [
        85.1656995,
        49.7690824
    ],
    [
        85.166959,
        49.7653945
    ],
    [
        85.1718613,
        49.7627249
    ],
    [
        85.1738618,
        49.759615
    ],
    [
        85.1728286,
        49.7580528
    ],
    [
        85.1752877,
        49.7545638
    ],
    [
        85.1819553,
        49.7545221
    ],
    [
        85.1862973,
        49.752661
    ],
    [
        85.1923857,
        49.7505149
    ],
    [
        85.1974004,
        49.7472636
    ],
    [
        85.2033689,
        49.7452678
    ],
    [
        85.2040504,
        49.7417661
    ],
    [
        85.2027863,
        49.7399547
    ],
    [
        85.1983069,
        49.7379392
    ],
    [
        85.1985789,
        49.7371908
    ],
    [
        85.1998614,
        49.7358094
    ],
    [
        85.2024962,
        49.7344129
    ],
    [
        85.2038042,
        49.7308744
    ],
    [
        85.2059046,
        49.729459
    ],
    [
        85.2077374,
        49.7291744
    ],
    [
        85.2088178,
        49.7280871
    ],
    [
        85.2098028,
        49.7261041
    ],
    [
        85.2123075,
        49.7232156
    ],
    [
        85.215526,
        49.7185296
    ],
    [
        85.2133497,
        49.7151468
    ],
    [
        85.2109095,
        49.7150473
    ],
    [
        85.2097224,
        49.7154168
    ],
    [
        85.2068298,
        49.7154085
    ],
    [
        85.2064781,
        49.7133189
    ],
    [
        85.2067969,
        49.7113502
    ],
    [
        85.2054614,
        49.7098626
    ],
    [
        85.2056403,
        49.7078142
    ],
    [
        85.208244,
        49.7040595
    ],
    [
        85.2097208,
        49.7031849
    ],
    [
        85.2103348,
        49.7025264
    ],
    [
        85.2091845,
        49.7018126
    ],
    [
        85.2085705,
        49.7004754
    ],
    [
        85.2086249,
        49.6992438
    ],
    [
        85.208002,
        49.6978015
    ],
    [
        85.2088523,
        49.6964468
    ],
    [
        85.2129262,
        49.6938946
    ],
    [
        85.2149409,
        49.6890682
    ],
    [
        85.2131999,
        49.6867451
    ],
    [
        85.2132621,
        49.6836274
    ],
    [
        85.2126257,
        49.6801858
    ],
    [
        85.2141206,
        49.6752514
    ],
    [
        85.2083133,
        49.672758
    ],
    [
        85.2082978,
        49.6640455
    ],
    [
        85.2100854,
        49.661842
    ],
    [
        85.2065102,
        49.6586019
    ],
    [
        85.2036298,
        49.6574402
    ],
    [
        85.2007165,
        49.657712
    ],
    [
        85.1989655,
        49.6586511
    ],
    [
        85.1974897,
        49.6598979
    ],
    [
        85.1964391,
        49.6606104
    ],
    [
        85.1950634,
        49.6607723
    ],
    [
        85.1930123,
        49.6605132
    ],
    [
        85.1905609,
        49.6603351
    ],
    [
        85.1868339,
        49.6595579
    ],
    [
        85.1855832,
        49.6594607
    ],
    [
        85.1836321,
        49.6599303
    ],
    [
        85.1817061,
        49.6614199
    ],
    [
        85.1799301,
        49.6612256
    ],
    [
        85.177804,
        49.6609018
    ],
    [
        85.1738268,
        49.6611932
    ],
    [
        85.172326,
        49.6614847
    ],
    [
        85.1685239,
        49.6603027
    ],
    [
        85.165072,
        49.6602541
    ],
    [
        85.1629959,
        49.6608694
    ],
    [
        85.1609698,
        49.6624238
    ],
    [
        85.1593689,
        49.664221
    ],
    [
        85.1564173,
        49.6635895
    ],
    [
        85.1525652,
        49.6643019
    ],
    [
        85.1492634,
        49.6660666
    ],
    [
        85.1465619,
        49.6652733
    ],
    [
        85.1446859,
        49.6653867
    ],
    [
        85.1429256,
        49.6661708
    ],
    [
        85.1415592,
        49.6653786
    ],
    [
        85.1403835,
        49.6650871
    ],
    [
        85.1388452,
        49.66499
    ],
    [
        85.1369567,
        49.6652248
    ],
    [
        85.1354559,
        49.6655567
    ],
    [
        85.1341677,
        49.6659776
    ],
    [
        85.1329045,
        49.6662042
    ],
    [
        85.131979,
        49.6662933
    ],
    [
        85.1292024,
        49.66635
    ],
    [
        85.1266135,
        49.6666899
    ],
    [
        85.1257381,
        49.6666818
    ],
    [
        85.1243498,
        49.6665038
    ],
    [
        85.1231241,
        49.6668356
    ],
    [
        85.1222612,
        49.6668033
    ],
    [
        85.1214732,
        49.6668599
    ],
    [
        85.1204602,
        49.6665847
    ],
    [
        85.1185966,
        49.6662771
    ],
    [
        85.1182965,
        49.6637191
    ],
    [
        85.1171959,
        49.6624238
    ],
    [
        85.1163454,
        49.6587968
    ],
    [
        85.117321,
        49.6572909
    ],
    [
        85.1161953,
        49.6545219
    ],
    [
        85.113694,
        49.6556069
    ],
    [
        85.1126434,
        49.6585863
    ],
    [
        85.1123652,
        49.6596623
    ],
    [
        85.1110663,
        49.6609835
    ],
    [
        85.1092849,
        49.661464
    ],
    [
        85.1086169,
        49.6623528
    ],
    [
        85.1075778,
        49.6626171
    ],
    [
        85.1052706,
        49.6619057
    ],
    [
        85.104589,
        49.6619219
    ],
    [
        85.104564,
        49.6622943
    ],
    [
        85.105017,
        49.6630254
    ],
    [
        85.1041264,
        49.66365
    ],
    [
        85.1009719,
        49.6638422
    ],
    [
        85.0944585,
        49.6660343
    ],
    [
        85.0924361,
        49.6672771
    ],
    [
        85.0897084,
        49.6672771
    ],
    [
        85.0821933,
        49.6679496
    ],
    [
        85.0796326,
        49.6694148
    ],
    [
        85.0786676,
        49.669655
    ],
    [
        85.0778141,
        49.6703575
    ],
    [
        85.0767084,
        49.670697
    ],
    [
        85.0749194,
        49.6712462
    ],
    [
        85.0731008,
        49.6719367
    ],
    [
        85.0718484,
        49.6720808
    ],
    [
        85.0682578,
        49.6714623
    ],
    [
        85.0647785,
        49.6707658
    ],
    [
        85.063693,
        49.6707118
    ],
    [
        85.0598148,
        49.6697811
    ],
    [
        85.0578386,
        49.6690905
    ],
    [
        85.056651,
        49.6692046
    ],
    [
        85.0535337,
        49.6698051
    ],
    [
        85.0518729,
        49.6706217
    ],
    [
        85.0474288,
        49.6727593
    ],
    [
        85.0446732,
        49.6745786
    ],
    [
        85.0439124,
        49.6757253
    ],
    [
        85.0432351,
        49.6762416
    ],
    [
        85.0421403,
        49.678391
    ],
    [
        85.0423073,
        49.6788352
    ],
    [
        85.0415929,
        49.6800719
    ],
    [
        85.0410548,
        49.6805702
    ],
    [
        85.0404146,
        49.6822931
    ],
    [
        85.0405909,
        49.6825873
    ],
    [
        85.0405074,
        49.6828574
    ],
    [
        85.0400713,
        49.6830795
    ],
    [
        85.0393662,
        49.6832596
    ],
    [
        85.03829,
        49.6832176
    ],
    [
        85.0371395,
        49.6836558
    ],
    [
        85.0361653,
        49.6845322
    ],
    [
        85.0335025,
        49.6853306
    ],
    [
        85.0310531,
        49.6858828
    ],
    [
        85.0300391,
        49.6859313
    ],
    [
        85.0277207,
        49.6845677
    ],
    [
        85.0245172,
        49.6836404
    ],
    [
        85.0212294,
        49.6822495
    ],
    [
        85.0194168,
        49.6804222
    ],
    [
        85.0178151,
        49.6794676
    ],
    [
        85.0160447,
        49.6773401
    ],
    [
        85.0149487,
        49.6769309
    ],
    [
        85.0121667,
        49.6749124
    ],
    [
        85.0095955,
        49.6740941
    ],
    [
        85.0074879,
        49.6734122
    ],
    [
        85.0050852,
        49.6731939
    ],
    [
        85.0039893,
        49.6724574
    ],
    [
        85.001123,
        49.6719937
    ],
    [
        84.9986782,
        49.6711753
    ],
    [
        84.9968235,
        49.6700023
    ],
    [
        84.9942522,
        49.6694839
    ],
    [
        84.9915124,
        49.6694567
    ],
    [
        84.9883931,
        49.6683927
    ],
    [
        84.9861381,
        49.6690202
    ],
    [
        84.9851686,
        49.6687337
    ],
    [
        84.9836134,
        49.6690365
    ],
    [
        84.9825762,
        49.6692384
    ],
    [
        84.9798364,
        49.6707661
    ],
    [
        84.9771176,
        49.6713526
    ],
    [
        84.975579,
        49.6730985
    ],
    [
        84.9737033,
        49.6739304
    ],
    [
        84.972797,
        49.6763308
    ],
    [
        84.9721226,
        49.6768491
    ],
    [
        84.9708791,
        49.6770673
    ],
    [
        84.9703312,
        49.678322
    ],
    [
        84.9689823,
        49.6790311
    ],
    [
        84.9682025,
        49.6808994
    ],
    [
        84.9669801,
        49.6821131
    ],
    [
        84.9662214,
        49.6838586
    ],
    [
        84.9644721,
        49.6853313
    ],
    [
        84.963334,
        49.6855358
    ],
    [
        84.9622802,
        49.6862721
    ],
    [
        84.9607627,
        49.6884538
    ],
    [
        84.9590977,
        49.6895718
    ],
    [
        84.9565475,
        49.690608
    ],
    [
        84.9525431,
        49.6914806
    ],
    [
        84.9515947,
        49.692285
    ],
    [
        84.9518476,
        49.6933757
    ],
    [
        84.9512364,
        49.6946845
    ],
    [
        84.9484333,
        49.6949844
    ],
    [
        84.9454827,
        49.6962523
    ],
    [
        84.9424478,
        49.6957888
    ],
    [
        84.9388227,
        49.6964159
    ],
    [
        84.9366519,
        49.6960887
    ],
    [
        84.9318888,
        49.6967294
    ],
    [
        84.9294229,
        49.6958297
    ],
    [
        84.9271256,
        49.6962796
    ],
    [
        84.9246387,
        49.6960205
    ],
    [
        84.9232687,
        49.6953525
    ],
    [
        84.9205921,
        49.6952298
    ],
    [
        84.9184002,
        49.6961432
    ],
    [
        84.9162926,
        49.6959796
    ],
    [
        84.9138478,
        49.6969067
    ],
    [
        84.9123725,
        49.6973702
    ],
    [
        84.9106232,
        49.6973429
    ],
    [
        84.9101174,
        49.6970294
    ],
    [
        84.9094851,
        49.6963341
    ],
    [
        84.9078623,
        49.6963205
    ],
    [
        84.9068506,
        49.6960342
    ],
    [
        84.9037314,
        49.6960751
    ],
    [
        84.8991369,
        49.6983108
    ],
    [
        84.8979145,
        49.6990333
    ],
    [
        84.8971979,
        49.6997421
    ],
    [
        84.8963549,
        49.6997013
    ],
    [
        84.8955961,
        49.699265
    ],
    [
        84.8915917,
        49.6977928
    ],
    [
        84.8898635,
        49.6976019
    ],
    [
        84.8881142,
        49.6978609
    ],
    [
        84.88666,
        49.6990333
    ],
    [
        84.8860488,
        49.6992241
    ],
    [
        84.8844681,
        49.6992514
    ],
    [
        84.8826766,
        49.6985425
    ],
    [
        84.8816439,
        49.6985971
    ],
    [
        84.8808641,
        49.699156
    ],
    [
        84.8799578,
        49.6991287
    ],
    [
        84.8790726,
        49.6982699
    ],
    [
        84.8770072,
        49.6982835
    ],
    [
        84.8759956,
        49.69782
    ],
    [
        84.8740987,
        49.6982835
    ],
    [
        84.873066,
        49.698229
    ],
    [
        84.8717382,
        49.6974247
    ],
    [
        84.8707055,
        49.6972884
    ],
    [
        84.8697571,
        49.6975883
    ],
    [
        84.8690195,
        49.6984335
    ],
    [
        84.8685347,
        49.6983244
    ],
    [
        84.8675441,
        49.6982017
    ],
    [
        84.8662585,
        49.6991287
    ],
    [
        84.8653101,
        49.6993468
    ],
    [
        84.8637505,
        49.6987198
    ],
    [
        84.8625702,
        49.6984471
    ],
    [
        84.8615375,
        49.6985016
    ],
    [
        84.8607156,
        49.6982835
    ],
    [
        84.8597882,
        49.6974247
    ],
    [
        84.8585448,
        49.6970566
    ],
    [
        84.8568587,
        49.6972338
    ],
    [
        84.856237,
        49.6968726
    ],
    [
        84.8557522,
        49.6964227
    ],
    [
        84.8546984,
        49.6963068
    ],
    [
        84.8539028,
        49.6965863
    ],
    [
        84.9437896,
        49.6175192
    ],
    [
        84.9437103,
        49.5865415
    ],
    [
        84.9407783,
        49.5892026
    ],
    [
        84.9371761,
        49.5946329
    ],
    [
        84.9352494,
        49.5987596
    ],
    [
        84.9319823,
        49.6026687
    ],
    [
        84.9301393,
        49.602343
    ],
    [
        84.9319823,
        49.5930039
    ],
    [
        84.9337834,
        49.5899629
    ],
    [
        84.9322546,
        49.5878992
    ],
    [
        84.9312074,
        49.5874376
    ],
    [
        84.930265,
        49.586107
    ],
    [
        84.9285299,
        49.585502
    ],
    [
        84.9280864,
        49.589344
    ],
    [
        84.9270785,
        49.5914609
    ],
    [
        84.9231678,
        49.594623
    ],
    [
        84.9184147,
        49.597649
    ],
    [
        84.9121213,
        49.5993005
    ],
    [
        84.9062755,
        49.5996663
    ],
    [
        84.8974721,
        49.5983166
    ],
    [
        84.8880307,
        49.5975377
    ],
    [
        84.8779027,
        49.5956462
    ],
    [
        84.8711475,
        49.5945503
    ],
    [
        84.8674301,
        49.5934553
    ],
    [
        84.8657029,
        49.591871
    ],
    [
        84.8636109,
        49.5911791
    ],
    [
        84.8605649,
        49.5916405
    ],
    [
        84.8555417,
        49.5920094
    ],
    [
        84.850205,
        49.5915666
    ],
    [
        84.8464907,
        49.5900444
    ],
    [
        84.8444841,
        49.5897123
    ],
    [
        84.8336398,
        49.5914282
    ],
    [
        84.827065,
        49.5905426
    ],
    [
        84.8232254,
        49.5906062
    ],
    [
        84.8200204,
        49.5905887
    ],
    [
        84.8135311,
        49.5885222
    ],
    [
        84.8036475,
        49.5877126
    ],
    [
        84.7999623,
        49.5903633
    ],
    [
        84.7998817,
        49.5939697
    ],
    [
        84.7964951,
        49.5947537
    ],
    [
        84.7907703,
        49.5982031
    ],
    [
        84.7823039,
        49.6007638
    ],
    [
        84.7786755,
        49.6025928
    ],
    [
        84.7809332,
        49.6058848
    ],
    [
        84.778353,
        49.6083407
    ],
    [
        84.7681934,
        49.6244311
    ],
    [
        84.7569049,
        49.6241177
    ],
    [
        84.749003,
        49.6324743
    ],
    [
        84.7490836,
        49.6348243
    ],
    [
        84.7502125,
        49.6370698
    ],
    [
        84.7497287,
        49.6430746
    ],
    [
        84.745939,
        49.6423959
    ],
    [
        84.742875,
        49.6423437
    ],
    [
        84.7370695,
        49.649131
    ],
    [
        84.7549697,
        49.6907739
    ],
    [
        84.7415042,
        49.7011008
    ],
    [
        84.7306996,
        49.7088706
    ],
    [
        84.6920769,
        49.7170042
    ],
    [
        84.6824817,
        49.7198714
    ],
    [
        84.6685324,
        49.7125205
    ],
    [
        84.658534,
        49.7053248
    ],
    [
        84.6540187,
        49.6864443
    ],
    [
        84.6615174,
        49.6807578
    ],
    [
        84.6626463,
        49.6711049
    ],
    [
        84.6669198,
        49.6592058
    ],
    [
        84.6727252,
        49.6564915
    ],
    [
        84.6917544,
        49.6301763
    ],
    [
        84.6802239,
        49.6104307
    ],
    [
        84.6829654,
        49.5964262
    ],
    [
        84.666194,
        49.5757257
    ],
    [
        84.6701073,
        49.559423
    ],
    [
        84.6483341,
        49.5308444
    ],
    [
        84.640271,
        49.5277566
    ],
    [
        84.6216449,
        49.5312891
    ],
    [
        84.5986769,
        49.4865829
    ],
    [
        84.6035348,
        49.4857121
    ],
    [
        84.6047579,
        49.4858445
    ],
    [
        84.6065604,
        49.4851893
    ],
    [
        84.6083199,
        49.4844017
    ],
    [
        84.6093391,
        49.4845411
    ],
    [
        84.6099614,
        49.4839208
    ],
    [
        84.6128367,
        49.4832656
    ],
    [
        84.6141456,
        49.4836699
    ],
    [
        84.6164845,
        49.4819482
    ],
    [
        84.6190702,
        49.4817252
    ],
    [
        84.6202825,
        49.4807911
    ],
    [
        84.6232652,
        49.4812024
    ],
    [
        84.6241127,
        49.480826
    ],
    [
        84.6236621,
        49.4801289
    ],
    [
        84.6239089,
        49.4795713
    ],
    [
        84.6250676,
        49.47916
    ],
    [
        84.62628,
        49.4792645
    ],
    [
        84.6273529,
        49.4788255
    ],
    [
        84.627031,
        49.478205
    ],
    [
        84.6293055,
        49.4770478
    ],
    [
        84.6309792,
        49.4764483
    ],
    [
        84.6317839,
        49.4767132
    ],
    [
        84.6325134,
        49.4765598
    ],
    [
        84.6328138,
        49.4758906
    ],
    [
        84.6338331,
        49.4753817
    ],
    [
        84.6341013,
        49.474817
    ],
    [
        84.6334575,
        49.4733878
    ],
    [
        84.6342622,
        49.4725164
    ],
    [
        84.6359359,
        49.4717634
    ],
    [
        84.6374916,
        49.470871
    ],
    [
        84.6383714,
        49.4700623
    ],
    [
        84.6384679,
        49.4685353
    ],
    [
        84.6391975,
        49.4677475
    ],
    [
        84.6400129,
        49.4673779
    ],
    [
        84.6401631,
        49.4662623
    ],
    [
        84.6399592,
        49.4657742
    ],
    [
        84.6390473,
        49.4654326
    ],
    [
        84.63894,
        49.4649026
    ],
    [
        84.6394373,
        49.4646868
    ],
    [
        84.6219777,
        49.4561022
    ],
    [
        84.6007776,
        49.4448588
    ],
    [
        84.6073006,
        49.4379104
    ],
    [
        84.6098327,
        49.4366547
    ],
    [
        84.5952843,
        49.4363476
    ],
    [
        84.5729041,
        49.4478164
    ],
    [
        84.4786109,
        49.46
    ],
    [
        84.4128084,
        49.3948596
    ],
    [
        84.3968438,
        49.396284
    ],
    [
        84.3706655,
        49.3974292
    ],
    [
        84.3509673,
        49.4034057
    ],
    [
        84.2854785,
        49.3691004
    ],
    [
        84.2669819,
        49.379495
    ],
    [
        84.2180156,
        49.4110291
    ],
    [
        84.1830823,
        49.4030427
    ],
    [
        84.1819345,
        49.4012797
    ],
    [
        84.1804432,
        49.400222
    ],
    [
        84.1776108,
        49.3972894
    ],
    [
        84.1756581,
        49.3947897
    ],
    [
        84.1737484,
        49.3932395
    ],
    [
        84.1707443,
        49.3916892
    ],
    [
        84.1688775,
        49.3897339
    ],
    [
        84.1679119,
        49.3893847
    ],
    [
        84.1672896,
        49.3882394
    ],
    [
        84.1653155,
        49.3877645
    ],
    [
        84.1624831,
        49.3864515
    ],
    [
        84.1576766,
        49.383127
    ],
    [
        84.1558741,
        49.3826242
    ],
    [
        84.155917,
        49.3815346
    ],
    [
        84.1549729,
        49.3807523
    ],
    [
        84.1548871,
        49.3798582
    ],
    [
        84.1540287,
        49.3795229
    ],
    [
        84.1539,
        49.3788244
    ],
    [
        84.152398,
        49.3778186
    ],
    [
        84.1505955,
        49.377595
    ],
    [
        84.1486214,
        49.3759744
    ],
    [
        84.1440295,
        49.375248
    ],
    [
        84.1420554,
        49.374242
    ],
    [
        84.1404246,
        49.3725654
    ],
    [
        84.1381501,
        49.3716992
    ],
    [
        84.134631,
        49.3721742
    ],
    [
        84.1303824,
        49.3711403
    ],
    [
        84.1276144,
        49.3722581
    ],
    [
        84.125254,
        49.3715315
    ],
    [
        84.1239022,
        49.3705394
    ],
    [
        84.1225504,
        49.3676191
    ],
    [
        84.1214775,
        49.3663755
    ],
    [
        84.1182588,
        49.3650619
    ],
    [
        84.116628,
        49.3651737
    ],
    [
        84.1140746,
        49.3667388
    ],
    [
        84.1110061,
        49.3663894
    ],
    [
        84.1101478,
        49.3643073
    ],
    [
        84.1089891,
        49.3634689
    ],
    [
        84.10856,
        49.361736
    ],
    [
        84.106779,
        49.361191
    ],
    [
        84.1042684,
        49.3596956
    ],
    [
        84.1011356,
        49.3585775
    ],
    [
        84.0965651,
        49.3574874
    ],
    [
        84.0939258,
        49.3571939
    ],
    [
        84.092574,
        49.3567327
    ],
    [
        84.0919303,
        49.3562994
    ],
    [
        84.0919088,
        49.3554189
    ],
    [
        84.0905784,
        49.354161
    ],
    [
        84.0894197,
        49.3525256
    ],
    [
        84.0877889,
        49.351659
    ],
    [
        84.0874456,
        49.3507504
    ],
    [
        84.0865658,
        49.3502332
    ],
    [
        84.0864586,
        49.3490171
    ],
    [
        84.0857505,
        49.3480386
    ],
    [
        84.0862011,
        49.3470601
    ],
    [
        84.0860294,
        49.346508
    ],
    [
        84.0851336,
        49.3463087
    ],
    [
        84.0837549,
        49.3463471
    ],
    [
        84.082536,
        49.3454206
    ],
    [
        84.0813087,
        49.3444878
    ],
    [
        84.0769957,
        49.3374275
    ],
    [
        84.0705584,
        49.3341836
    ],
    [
        84.0481561,
        49.3291501
    ],
    [
        84.0230064,
        49.3457107
    ],
    [
        83.9978631,
        49.366924
    ],
    [
        83.9609076,
        49.387579
    ],
    [
        83.9156835,
        49.4051508
    ],
    [
        83.892059,
        49.42568
    ],
    [
        83.8826092,
        49.4345696
    ],
    [
        83.8500411,
        49.4415924
    ],
    [
        83.8040077,
        49.4526207
    ],
    [
        83.7536539,
        49.4678217
    ],
    [
        83.6913679,
        49.4799171
    ],
    [
        83.6529921,
        49.4869738
    ],
    [
        83.6104175,
        49.5350899
    ],
    [
        83.5883117,
        49.5539226
    ],
    [
        83.5698059,
        49.5735897
    ],
    [
        83.5157044,
        49.582169
    ],
    [
        83.4826301,
        49.5788867
    ],
    [
        83.4571142,
        49.5820382
    ],
    [
        83.4379615,
        49.5989935
    ],
    [
        83.4226899,
        49.6141936
    ],
    [
        83.4078597,
        49.6196634
    ],
    [
        83.3947277,
        49.6215261
    ],
    [
        83.3849001,
        49.6229439
    ],
    [
        83.3768165,
        49.6249968
    ],
    [
        83.3707809,
        49.6268917
    ],
    [
        83.3625411,
        49.629727
    ],
    [
        83.3581513,
        49.6319976
    ],
    [
        83.3531766,
        49.6353492
    ],
    [
        83.3500098,
        49.6377321
    ],
    [
        83.3481216,
        49.6397886
    ],
    [
        83.3464908,
        49.6434014
    ],
    [
        83.345075,
        49.6492797
    ],
    [
        83.3446679,
        49.6525508
    ],
    [
        83.3455973,
        49.6567
    ],
    [
        83.3459583,
        49.6574228
    ],
    [
        83.3464108,
        49.6581976
    ],
    [
        83.3470353,
        49.6591524
    ],
    [
        83.3481645,
        49.6608222
    ],
    [
        83.3499512,
        49.6637233
    ],
    [
        83.3526549,
        49.6688367
    ],
    [
        83.3531674,
        49.6717955
    ],
    [
        83.3533031,
        49.6725785
    ],
    [
        83.3535327,
        49.6739041
    ],
    [
        83.3515664,
        49.679698
    ],
    [
        83.3471773,
        49.6828332
    ],
    [
        83.3433852,
        49.6835375
    ],
    [
        83.3377672,
        49.6827651
    ],
    [
        83.3310255,
        49.6806068
    ],
    [
        83.3241435,
        49.6766761
    ],
    [
        83.3162432,
        49.674404
    ],
    [
        83.3098878,
        49.6735859
    ],
    [
        83.3010188,
        49.6741335
    ],
    [
        83.2808283,
        49.6736807
    ],
    [
        83.2691007,
        49.6732263
    ],
    [
        83.2594096,
        49.6699541
    ],
    [
        83.2532805,
        49.6685401
    ],
    [
        83.2271763,
        49.6669543
    ],
    [
        83.2129908,
        49.668886
    ],
    [
        83.2068174,
        49.6708637
    ],
    [
        83.2006001,
        49.6728772
    ],
    [
        83.1944418,
        49.6743907
    ],
    [
        83.1884765,
        49.6757238
    ],
    [
        83.183863,
        49.6768207
    ],
    [
        83.1809234,
        49.6774178
    ],
    [
        83.1781338,
        49.6778482
    ],
    [
        83.1757445,
        49.6781308
    ],
    [
        83.1692623,
        49.6788003
    ],
    [
        83.1651735,
        49.6793617
    ],
    [
        83.1581998,
        49.6803058
    ],
    [
        83.1515263,
        49.681236
    ],
    [
        83.1478143,
        49.681722
    ],
    [
        83.1447243,
        49.6820552
    ],
    [
        83.1419563,
        49.6820274
    ],
    [
        83.1388664,
        49.6814859
    ],
    [
        83.1341243,
        49.6800628
    ],
    [
        83.1313884,
        49.6794866
    ],
    [
        83.1288135,
        49.6794033
    ],
    [
        83.1263995,
        49.6796602
    ],
    [
        83.1233311,
        49.6804238
    ],
    [
        83.11993,
        49.6815137
    ],
    [
        83.1173873,
        49.6826938
    ],
    [
        83.1157458,
        49.6836171
    ],
    [
        83.1140935,
        49.6848318
    ],
    [
        83.1127846,
        49.6859772
    ],
    [
        83.1111646,
        49.687643
    ],
    [
        83.1100488,
        49.6886981
    ],
    [
        83.1089544,
        49.6896143
    ],
    [
        83.107624,
        49.6903014
    ],
    [
        83.1060254,
        49.6908844
    ],
    [
        83.103311,
        49.6914535
    ],
    [
        83.0986333,
        49.6919394
    ],
    [
        83.0949211,
        49.6918006
    ],
    [
        83.0920243,
        49.691162
    ],
    [
        83.0893421,
        49.6898988
    ],
    [
        83.0833983,
        49.6869281
    ],
    [
        83.0805015,
        49.6858869
    ],
    [
        83.0768537,
        49.6854566
    ],
    [
        83.0737424,
        49.6859147
    ],
    [
        83.0706739,
        49.6869559
    ],
    [
        83.0665111,
        49.6884551
    ],
    [
        83.0634641,
        49.6889688
    ],
    [
        83.0603528,
        49.68883
    ],
    [
        83.0574989,
        49.6883094
    ],
    [
        83.0539799,
        49.6871502
    ],
    [
        83.0497098,
        49.6856232
    ],
    [
        83.0452679,
        49.6844985
    ],
    [
        83.0399251,
        49.6836795
    ],
    [
        83.0350756,
        49.6829993
    ],
    [
        83.030827,
        49.682791
    ],
    [
        83.0271577,
        49.6830269
    ],
    [
        83.0239176,
        49.6836656
    ],
    [
        83.0225053,
        49.6842911
    ],
    [
        83.0208921,
        49.6856232
    ],
    [
        83.0187464,
        49.6883719
    ],
    [
        83.0181885,
        49.6909122
    ],
    [
        83.0185102,
        49.6931469
    ],
    [
        83.0196475,
        49.6954649
    ],
    [
        83.0227804,
        49.6988932
    ],
    [
        83.0254197,
        49.7017244
    ],
    [
        83.0272436,
        49.7040004
    ],
    [
        83.0283807,
        49.7065676
    ],
    [
        83.0281662,
        49.7087184
    ],
    [
        83.0276513,
        49.7118266
    ],
    [
        83.0255699,
        49.7147681
    ],
    [
        83.0211495,
        49.7176954
    ],
    [
        83.0165577,
        49.719499
    ],
    [
        83.0102491,
        49.7201649
    ],
    [
        83.0048788,
        49.7199449
    ],
    [
        82.9994559,
        49.7186528
    ],
    [
        82.989043,
        49.7144736
    ],
    [
        82.9777719,
        49.7110226
    ],
    [
        82.9660092,
        49.7085249
    ],
    [
        82.9584951,
        49.7085476
    ],
    [
        82.9547732,
        49.710296
    ],
    [
        82.9507001,
        49.714292
    ],
    [
        82.9480667,
        49.7181288
    ],
    [
        82.9497872,
        49.7267548
    ],
    [
        82.9508054,
        49.7337225
    ],
    [
        82.9536144,
        49.7402809
    ],
    [
        82.95846,
        49.7455904
    ],
    [
        82.9634811,
        49.7495155
    ],
    [
        82.9674625,
        49.7529214
    ],
    [
        82.9705524,
        49.7560684
    ],
    [
        82.9727626,
        49.7595617
    ],
    [
        82.9738784,
        49.762015
    ],
    [
        82.9736209,
        49.7647041
    ],
    [
        82.9722261,
        49.7668939
    ],
    [
        82.9678488,
        49.7699152
    ],
    [
        82.9640293,
        49.7715781
    ],
    [
        82.9607892,
        49.7723681
    ],
    [
        82.9572272,
        49.772382
    ],
    [
        82.9533219,
        49.7717722
    ],
    [
        82.949481,
        49.7705388
    ],
    [
        82.9445672,
        49.7691113
    ],
    [
        82.9401898,
        49.7679888
    ],
    [
        82.9363742,
        49.767798
    ],
    [
        82.9339865,
        49.7683876
    ],
    [
        82.9325755,
        49.7696074
    ],
    [
        82.9318095,
        49.771245
    ],
    [
        82.9323011,
        49.7740116
    ],
    [
        82.9336705,
        49.7769366
    ],
    [
        82.9370062,
        49.7809952
    ],
    [
        82.9419922,
        49.7868444
    ],
    [
        82.9465217,
        49.7920808
    ],
    [
        82.9475049,
        49.7977473
    ],
    [
        82.9462408,
        49.8002177
    ],
    [
        82.9430165,
        49.8017815
    ],
    [
        82.9317805,
        49.8054301
    ],
    [
        82.9177003,
        49.8108684
    ],
    [
        82.9069208,
        49.8141991
    ],
    [
        82.9028477,
        49.8161249
    ],
    [
        82.9014783,
        49.818096
    ],
    [
        82.9026722,
        49.8238727
    ],
    [
        82.9018294,
        49.8286068
    ],
    [
        82.9005448,
        49.8319214
    ],
    [
        82.8941192,
        49.8400969
    ],
    [
        82.8934114,
        49.8408387
    ]
]