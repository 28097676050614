import React, {useContext, useEffect, useState} from 'react';
import Map from "../../Map/Map";
import PowerGenerationSideInfo from "./PowerGenerationSideInfo";
import {getPowerPlants, getPowerPlantTopFive} from "../../../services/hcs";
import {IPowerGenerationStatistic, IPowerPlant} from "../../../types/power-generation";
import BarChart from '../../Charts/BarChart';
import { AppDataContext } from '../../../contexts/AppDataContext';
import dayjs from 'dayjs';

const PowerGenerationPage = () => {
    const { dateRange } = useContext(AppDataContext);
    const [powerPlants, setPowerPlants] = useState<IPowerPlant[]>([]);
    const [powerPlantsTopfive, setPowerPlantsTopfive] = useState<IPowerGenerationStatistic[]>([]);

    useEffect(() => {
        loadPowerPlants();
        loadTopFive();
    }, [dateRange]);

    const loadPowerPlants = async (): Promise<void> => {
        const response = await getPowerPlants();

        if (!response.isError) {
            setPowerPlants(response.data);
        } else {
            setPowerPlants([]);
        }
    };

    const loadTopFive = async (): Promise<void> => {
        const response = await getPowerPlantTopFive(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"));

        if(!response.isError) {
            setPowerPlantsTopfive(response.data);
        }
    }

    const barChartData = {
        labels: powerPlantsTopfive.map((powerPlant) => powerPlant.consumer_name),
        datasets: [
            {
                data: powerPlantsTopfive.map((powerPlant) => parseInt(powerPlant.actual_generated)),
                backgroundColor: ["#3DB76E", "#6DC97C", "#71D097", "#99D0AF", "#8ABD9E"],
                borderColor: ["#3DB76E", "#6DC97C", "#71D097", "#99D0AF", "#8ABD9E"],
                borderWidth: 1
            }
        ]
    }

    return (
        <div>
            <Map powerPlants={powerPlants} regionOnly />
            <PowerGenerationSideInfo />
                <div className="power-generation-topfive">
                    <label className="power-generation-topfive-label">Крупные потребители</label>
                    <BarChart 
                        data={barChartData} 
                        responsive={false} 
                        isLegend={false}
                        textRotation={90} 
                        onlyGraph
                        withTicksOnX
                        datalabels
                        datalabelsText="мВтч"
                        datalabelsRotation={270}
                        textColor="white"
                        chartHeight={200}
                        datalabelsColor="white"
                    />
                </div>
        </div>
    );
};

export default PowerGenerationPage;