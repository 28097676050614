import {LatLngExpression} from "leaflet";

export const kourchimDistrict: LatLngExpression[] = [
    [
        83.4233086,
        48.5407054
    ],
    [
        83.4433935,
        48.5776294
    ],
    [
        83.4781446,
        48.6130553
    ],
    [
        83.4749586,
        48.6223335
    ],
    [
        83.4515244,
        48.6814119
    ],
    [
        83.4354935,
        48.7087009
    ],
    [
        83.4348595,
        48.7181507
    ],
    [
        83.4426218,
        48.771562
    ],
    [
        83.4611839,
        48.8251389
    ],
    [
        83.483734,
        48.8641502
    ],
    [
        83.5297392,
        48.9184386
    ],
    [
        83.5477637,
        48.9332138
    ],
    [
        83.5764311,
        48.9469699
    ],
    [
        83.5932539,
        48.9522684
    ],
    [
        83.6116182,
        48.9522684
    ],
    [
        83.6299895,
        48.9522684
    ],
    [
        83.6631201,
        48.9628637
    ],
    [
        83.7480925,
        48.9983528
    ],
    [
        83.7685202,
        49.0109651
    ],
    [
        83.816757,
        49.0276264
    ],
    [
        83.8854216,
        49.0308904
    ],
    [
        83.9226721,
        49.0344919
    ],
    [
        83.9647291,
        49.0506953
    ],
    [
        84.000263,
        49.0704923
    ],
    [
        84.1425703,
        49.1006226
    ],
    [
        84.1892724,
        49.1172729
    ],
    [
        84.1811728,
        49.0892941
    ],
    [
        84.1811675,
        49.0889006
    ],
    [
        84.1810924,
        49.0885141
    ],
    [
        84.1812104,
        49.0882928
    ],
    [
        84.1810548,
        49.0881066
    ],
    [
        84.1811031,
        49.0877061
    ],
    [
        84.18069,
        49.0872002
    ],
    [
        84.1804969,
        49.0870421
    ],
    [
        84.180513,
        49.0868524
    ],
    [
        84.1806686,
        49.08661
    ],
    [
        84.1806632,
        49.086357
    ],
    [
        84.1808027,
        49.0860268
    ],
    [
        84.1808188,
        49.0856403
    ],
    [
        84.1810119,
        49.0854998
    ],
    [
        84.1816664,
        49.0853452
    ],
    [
        84.1823637,
        49.0849693
    ],
    [
        84.1827017,
        49.0848815
    ],
    [
        84.1830504,
        49.0848885
    ],
    [
        84.1835654,
        49.0850606
    ],
    [
        84.184763,
        49.085022
    ],
    [
        84.1848274,
        49.0848287
    ],
    [
        84.1849172,
        49.0847515
    ],
    [
        84.1857621,
        49.0844352
    ],
    [
        84.1871166,
        49.0836623
    ],
    [
        84.1871595,
        49.0826926
    ],
    [
        84.1881895,
        49.0819197
    ],
    [
        84.1895628,
        49.0800223
    ],
    [
        84.1896915,
        49.0779422
    ],
    [
        84.1908288,
        49.0766068
    ],
    [
        84.1893482,
        49.0732193
    ],
    [
        84.1916656,
        49.0661623
    ],
    [
        84.1915798,
        49.0647283
    ],
    [
        84.1898202,
        49.0640816
    ],
    [
        84.1898632,
        49.0617758
    ],
    [
        84.193275,
        49.0571217
    ],
    [
        84.1938543,
        49.0560952
    ],
    [
        84.1944981,
        49.0556733
    ],
    [
        84.195528,
        49.054464
    ],
    [
        84.1984355,
        49.0531632
    ],
    [
        84.1989612,
        49.0525937
    ],
    [
        84.1999376,
        49.0520734
    ],
    [
        84.200592,
        49.0510046
    ],
    [
        84.2000234,
        49.0494647
    ],
    [
        84.1997766,
        49.0477138
    ],
    [
        84.2013216,
        49.0463636
    ],
    [
        84.200871,
        49.0446407
    ],
    [
        84.2011285,
        49.0438882
    ],
    [
        84.2010426,
        49.043213
    ],
    [
        84.2012572,
        49.0423902
    ],
    [
        84.2009997,
        49.0414267
    ],
    [
        84.2012679,
        49.0395137
    ],
    [
        84.2008173,
        49.0382196
    ],
    [
        84.199723,
        49.0370732
    ],
    [
        84.1994119,
        49.0365175
    ],
    [
        84.1989934,
        49.0352726
    ],
    [
        84.1984033,
        49.0340205
    ],
    [
        84.1984677,
        49.0325645
    ],
    [
        84.1988647,
        49.0311998
    ],
    [
        84.1994226,
        49.0302432
    ],
    [
        84.2002272,
        49.0294834
    ],
    [
        84.2009246,
        49.029188
    ],
    [
        84.2020619,
        49.0291317
    ],
    [
        84.2018044,
        49.0274434
    ],
    [
        84.2005491,
        49.0258816
    ],
    [
        84.199326,
        49.0248967
    ],
    [
        84.196515,
        49.0236585
    ],
    [
        84.1921162,
        49.0238414
    ],
    [
        84.1902065,
        49.0235458
    ],
    [
        84.1890049,
        49.0198029
    ],
    [
        84.1952463,
        49.0140922
    ],
    [
        84.1574999,
        48.988333
    ],
    [
        84.1451453,
        48.9705833
    ],
    [
        84.1682338,
        48.9711466
    ],
    [
        84.2135524,
        48.9692309
    ],
    [
        84.2241096,
        48.9663574
    ],
    [
        84.248228,
        48.9713156
    ],
    [
        84.2702006,
        48.9779636
    ],
    [
        84.2935466,
        48.9769497
    ],
    [
        84.3009281,
        48.9711466
    ],
    [
        84.31818,
        48.9664137
    ],
    [
        84.3459034,
        48.9710622
    ],
    [
        84.3565034,
        48.9899897
    ],
    [
        84.3668031,
        48.9982119
    ],
    [
        84.3838405,
        49.0167914
    ],
    [
        84.3972301,
        49.0342105
    ],
    [
        84.4315195,
        49.0414408
    ],
    [
        84.4549083,
        49.0288644
    ],
    [
        84.480443,
        49.0273729
    ],
    [
        84.497137,
        49.0228141
    ],
    [
        84.5053339,
        49.0223357
    ],
    [
        84.5146894,
        49.0202531
    ],
    [
        84.5233583,
        49.0189868
    ],
    [
        84.55091,
        49.0264726
    ],
    [
        84.5604372,
        49.0140331
    ],
    [
        84.5891475,
        49.008713
    ],
    [
        84.6421909,
        48.9824983
    ],
    [
        84.6629619,
        48.9811746
    ],
    [
        84.687767,
        48.967597
    ],
    [
        84.7240761,
        48.9964257
    ],
    [
        84.7636413,
        48.9904683
    ],
    [
        84.8156976,
        48.9792875
    ],
    [
        84.8654794,
        48.9765834
    ],
    [
        84.8953914,
        48.9780481
    ],
    [
        84.9714803,
        48.9833151
    ],
    [
        85.0214767,
        48.9824984
    ],
    [
        85.0822448,
        49.0188459
    ],
    [
        85.0981235,
        49.0481638
    ],
    [
        85.151081,
        49.047545
    ],
    [
        85.1826667,
        49.029821
    ],
    [
        85.2372551,
        49.0208723
    ],
    [
        85.2516746,
        49.0048844
    ],
    [
        85.2683687,
        49.009473
    ],
    [
        85.2877664,
        49.0025758
    ],
    [
        85.2894401,
        48.9960439
    ],
    [
        85.304203,
        48.998775
    ],
    [
        85.3378486,
        49.0091352
    ],
    [
        85.3496933,
        49.0167633
    ],
    [
        85.3661727,
        49.0160877
    ],
    [
        85.3784895,
        49.0145397
    ],
    [
        85.3986597,
        49.0143708
    ],
    [
        85.4727745,
        49.0176357
    ],
    [
        85.4897689,
        49.021801
    ],
    [
        85.5111837,
        49.0259097
    ],
    [
        85.5177927,
        49.0340978
    ],
    [
        85.5279636,
        49.0359829
    ],
    [
        85.5439281,
        49.0355891
    ],
    [
        85.5628109,
        49.0309747
    ],
    [
        85.5683898,
        49.0216603
    ],
    [
        85.5755996,
        49.0149056
    ],
    [
        85.5805778,
        48.9955934
    ],
    [
        85.5769067,
        48.9900562
    ],
    [
        85.5739688,
        48.9856246
    ],
    [
        85.5560302,
        48.9714284
    ],
    [
        85.5491637,
        48.9481535
    ],
    [
        85.5539702,
        48.9347924
    ],
    [
        85.5394649,
        48.9099775
    ],
    [
        85.585041,
        48.9049001
    ],
    [
        85.6270122,
        48.9106544
    ],
    // Markakol
    [
        85.6269693,
        48.9105980
    ],
    [
        85.5150000,
        48.8209900
    ],
    [
        85.3417969,
        48.6565002
    ],
    [
        85.1990604,
        48.5965923
    ],
    [
        84.9547005,
        48.5165475
    ],
    [
        84.9222565,
        48.4902170
    ],
    [
        84.9257755,
        48.4627061
    ],
    [
        84.9238014,
        48.4589495
    ],
    [
        84.9099827,
        48.4518911
    ],
    [
        84.9044037,
        48.4465396
    ],
    [
        84.8915300,
        48.4386100
    ],
    [
        84.8874521,
        48.4370879
    ],
    [
        84.8762298,
        48.4259257
    ],
    [
        84.8689985,
        48.4152168
    ],
    [
        84.8617458,
        48.4070127
    ],
    [
        84.8586774,
        48.4021124
    ],
    [
        84.8515534,
        48.3980522
    ],
    [
        84.8465753,
        48.3942482
    ],
    [
        84.8402023,
        48.3884774
    ],
    [
        84.8291302,
        48.3849007
    ],
    [
        84.8291302,
        48.3849007
    ],
    [84.8213625, 48.3814519],
    [84.8192596, 48.3808106],
    [84.8160303, 48.3803260],
    [84.8104942, 48.3786443],
    [84.8041320, 48.3761643],
    [84.8007524, 48.3753804],
    [84.7937357, 48.3707408],
    [84.7894871, 48.3691443],
    [84.7864079, 48.3682890],
    [84.7716558, 48.3693510],
    [84.7639847, 48.3667066],
    [84.7594357, 48.3666282],
    [84.7520328, 48.3649246],
    [84.7447586, 48.3611252],
    [84.7424197, 48.3595427],
    [84.7407031, 48.3576036],
    [84.7346950, 48.3539533],
    [84.7305107, 48.3535398],
    [84.7232580, 48.3515150],
    [84.7214556, 48.3502173],
    [84.7140098, 48.3475363],
    [84.7055554, 48.3471513],
    [84.7031093, 48.3464667],
    [84.6904063, 48.3459818],
    [84.6814370, 48.3458820],
    [84.6787119, 48.3445129],
    [84.6738839, 48.3392643],
    [84.6726394, 48.3372246],
    [84.6726179, 48.3372103],
    [84.6721673, 48.3337155],
    [84.6711588, 48.3301777],
    [84.6680260, 48.3268537],
    [84.6678329, 48.3247992],
    [84.6686053, 48.3230871],
    [84.6778536, 48.3175509],
    [84.6853423, 48.3137836],
    [84.6955991, 48.3063767],
    [84.7078943, 48.2986833],
    [84.7121215, 48.2941294],
    [84.7293735, 48.2847778],
    [84.7324848, 48.2821362],
    [84.7439861, 48.2763242],
    [84.7595429, 48.2650696],
    [84.7637701, 48.2603843],
    [84.7637486, 48.2596843],
    [84.7708511, 48.2511694],
    [84.8042393, 48.2498549],
    [84.8118138, 48.2490690],
    [84.8184657, 48.2478116],
    [84.8316193, 48.2477973],
    [84.8674321, 48.2443821],
    [84.8897266, 48.2408667],
    [84.9008417, 48.2376226],
    [84.9121284, 48.2365650],
    [84.9183726, 48.2365507],
    [84.9204648, 48.2363721],
    [84.9357641, 48.2332634],
    [84.9363703, 48.2328203],
    [84.9431670, 48.2319413],
    [84.9453986, 48.2328704],
    [84.9508864, 48.2334885],
    [84.9548292, 48.2331169],
    [84.9665505, 48.2293327],
    [84.9686211, 48.2280998],
    [84.9706542, 48.2260879],
    [84.9781698, 48.2242831],
    [84.9833411, 48.2222174],
    [84.9287796, 48.1736414],
    [84.5506096, 47.9211739],
    [84.5203972, 47.8229523],


    // Paste here Markakol


    [
        84.5204412,
        47.8230339
    ],
    [
        84.5165884,
        47.8229723
    ],
    [
        84.5140199,
        47.8218636
    ],
    [
        84.5075986,
        47.8173672
    ],
    [
        84.4990674,
        47.8137943
    ],
    [
        84.4952146,
        47.8137327
    ],
    [
        84.4919122,
        47.8147799
    ],
    [
        84.4900776,
        47.8163815
    ],
    [
        84.4887016,
        47.8184758
    ],
    [
        84.4881512,
        47.8232186
    ],
    [
        84.4873256,
        47.8254974
    ],
    [
        84.4855826,
        47.8265444
    ],
    [
        84.4830141,
        47.826914
    ],
    [
        84.4776532,
        47.8237594
    ],
    [
        84.4714734,
        47.8173046
    ],
    [
        84.4687268,
        47.8080822
    ],
    [
        84.4608303,
        47.8069293
    ],
    [
        84.4553372,
        47.8113102
    ],
    [
        84.4573973,
        47.8184572
    ],
    [
        84.4536207,
        47.8221456
    ],
    [
        84.4457241,
        47.8244509
    ],
    [
        84.4326781,
        47.8220304
    ],
    [
        84.4220349,
        47.8235288
    ],
    [
        84.4107052,
        47.8267559
    ],
    [
        84.4014355,
        47.8352837
    ],
    [
        84.3988608,
        47.837012
    ],
    [
        84.3930243,
        47.8368967
    ],
    [
        84.3847844,
        47.8318265
    ],
    [
        84.378948,
        47.8311351
    ],
    [
        84.3707083,
        47.8259491
    ],
    [
        84.3662451,
        47.825027
    ],
    [
        84.3595503,
        47.8267558
    ],
    [
        84.3569754,
        47.8336703
    ],
    [
        84.3430708,
        47.840123
    ],
    [
        84.1847989,
        47.8484182
    ],
    [
        83.683891,
        48.0833546
    ],
    [
        83.4556353,
        48.2150279
    ],
    [
        83.4299739,
        48.234614
    ],
    [
        83.4603881,
        48.2378227
    ],
    [
        83.4651945,
        48.2381086
    ],
    [
        83.4695719,
        48.2403952
    ],
    [
        83.4696577,
        48.2443965
    ],
    [
        83.4660958,
        48.2513408
    ],
    [
        83.4658812,
        48.2540555
    ],
    [
        83.46807,
        48.2555985
    ],
    [
        83.4724043,
        48.2573414
    ],
    [
        83.4793732,
        48.2586902
    ],
    [
        83.4849356,
        48.2592272
    ],
    [
        83.4886264,
        48.2608557
    ],
    [
        83.4912442,
        48.2643983
    ],
    [
        83.4923601,
        48.2691405
    ],
    [
        83.4911282,
        48.2721622
    ],
    [
        83.4909867,
        48.2736537
    ],
    [
        83.486309,
        48.2768812
    ],
    [
        83.4805153,
        48.2785662
    ],
    [
        83.4772967,
        48.2799657
    ],
    [
        83.4764385,
        48.282736
    ],
    [
        83.4780607,
        48.2852165
    ],
    [
        83.4827241,
        48.2886321
    ],
    [
        83.5181375,
        48.3175514
    ],
    [
        83.5579617,
        48.3379707
    ],
    [
        83.5960985,
        48.3482895
    ],
    [
        83.6196896,
        48.3678829
    ],
    [
        83.6155697,
        48.3909143
    ],
    [
        83.5589215,
        48.4171254
    ],
    [
        83.4857937,
        48.4378568
    ],
    [
        83.4377282,
        48.4706455
    ],
    [
        83.4233086,
        48.5038678
    ],
    [
        83.4233086,
        48.5407054
    ]
]