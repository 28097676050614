import React, {useEffect, useRef} from 'react';
import {divIcon, DivIconOptions} from "leaflet";
import ReactDOMServer from "react-dom/server";
import {Marker, Popup} from "react-leaflet";
import {ICheckpoint} from "../../../types/district";

interface ISpecialPointMarker {
    point: ICheckpoint;
}

const SpecialPointMarker: React.FC<ISpecialPointMarker> = ({ point }) => {
    const markerRef = useRef<any>(null);

    const createMarkers = () => {
        const options: DivIconOptions = {
            className: "marker-wrapper",
            html: ReactDOMServer.renderToString(
                <div className="marker special" />
            )
        }
        return divIcon(options);
    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    return (
        <>
            {point.latitude && point.longitude &&
                <Marker
                    position={[point.latitude, point.longitude]}
                    icon={createMarkers()}
                    ref={markerRef}
                >
                    <Popup>
                        <span style={{ fontWeight: 600, fontSize: "14px" }}>{point.name}</span> <br />
                    </Popup>
                </Marker>
            }
        </>
    );
};

export default SpecialPointMarker;