import { AxiosError } from "axios";
import axiosInstance from "./axiosInstance";
import { TResult } from "./types";

export type CaseInfoEndpointVariants = 
    "floods" |              // - Паводки
    "fires" |               // - Пожары
    "power-generation" |    // - Выработка и потребление электроэнергии
    "debts" |               // - Задолженность за электроэнергию
    "water-supply" |        // - Обеспеченность водоснабжением
    "tenders";              // - Государственные закупки

export type CaseInfo = {
    endpoint: CaseInfoEndpointVariants;
    last_updated_date: string;
    description: string;
};

export type CaseInfoGetParams = {
    endpoint: CaseInfoEndpointVariants;
}

export type CaseInfoUpdate = {
    description: string;
};

export type CaseInfoUpdateParams = {
    endpoint: CaseInfoEndpointVariants;
    body: CaseInfoUpdate
};

export const getCaseInformation = async (params: CaseInfoGetParams): Promise<TResult<CaseInfo>> => {
    try {
        const { data } = await axiosInstance.get<CaseInfo>(`/api/cases/info/get/${params.endpoint}/`);

        return {
            isError: false,
            data,
        };
    } catch (error: any) {
        return {
            isError: true,
            error: (error as AxiosError).response?.data || error.message,
        };
    }
};

export const updateCaseInformation = async (params: CaseInfoUpdateParams): Promise<TResult<CaseInfo>> => {
    try {
        const { data } = await axiosInstance.patch<CaseInfo>(
            `/api/cases/info/update/${params.endpoint}/`, 
            params.body,
        );

        return {
            isError: false,
            data,
        };
    } catch (error: any) {
        return {
            isError: true,
            error: (error as AxiosError).response?.data || error.message,
        };
    }
};