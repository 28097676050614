import {LatLngExpression} from "leaflet";

export const ustKamenogorsk: LatLngExpression[] = [
    [
        82.717546500000083,
        49.909291387000053
    ],
    [
        82.717641441000069,
        49.909232344000031
    ],
    [
        82.718502256000079,
        49.908614476000082
    ],
    [
        82.721099257000049,
        49.908902476000037
    ],
    [
        82.721101550000071,
        49.908902352000041
    ],
    [
        82.724450184000034,
        49.908718122000039
    ],
    [
        82.724885844000084,
        49.90868273600006
    ],
    [
        82.728212477000056,
        49.908412535000082
    ],
    [
        82.734065660000056,
        49.907793791000074
    ],
    [
        82.735381575000076,
        49.907654682000043
    ],
    [
        82.737686891000067,
        49.907292700000028
    ],
    [
        82.745172647000061,
        49.905726428000037
    ],
    [
        82.746968569000046,
        49.905624860000046
    ],
    [
        82.746971562000056,
        49.905624860000046
    ],
    [
        82.749000525000042,
        49.905631012000072
    ],
    [
        82.749003516000073,
        49.905630967000036
    ],
    [
        82.750625551000041,
        49.905606460000058
    ],
    [
        82.753339772000061,
        49.905227957000079
    ],
    [
        82.758837148000055,
        49.904054314000064
    ],
    [
        82.767162694000035,
        49.901057051000066
    ],
    [
        82.768928927000047,
        49.90011833300008
    ],
    [
        82.772947407000061,
        49.897982523000053
    ],
    [
        82.780929809000042,
        49.893112922000057
    ],
    [
        82.78806892700004,
        49.890015084000026
    ],
    [
        82.795135955000035,
        49.887445531000026
    ],
    [
        82.795518599000047,
        49.887316076000047
    ],
    [
        82.808567077000077,
        49.882901394000044
    ],
    [
        82.815213562000054,
        49.880654819000029
    ],
    [
        82.820743093000033,
        49.878785705000041
    ],
    [
        82.825036756000031,
        49.877253547000066
    ],
    [
        82.825073915000075,
        49.877242667000075
    ],
    [
        82.832449766000082,
        49.87507856600007
    ],
    [
        82.833246397000039,
        49.87484481000007
    ],
    [
        82.83844511500007,
        49.874894295000047
    ],
    [
        82.855327456000055,
        49.875047634000055
    ],
    [
        82.866632374000062,
        49.875806830000045
    ],
    [
        82.866895732000046,
        49.87582451600008
    ],
    [
        82.87293151700004,
        49.876229851000062
    ],
    [
        82.882424031000085,
        49.872389425000051
    ],
    [
        82.906237308000073,
        49.874424867000073
    ],
    [
        82.91185713200008,
        49.86607338400006
    ],
    [
        82.913321529000029,
        49.863896941000064
    ],
    [
        82.907213354000078,
        49.849443988000075
    ],
    [
        82.906798605000063,
        49.849225713000067
    ],
    [
        82.891985509000051,
        49.841429180000034
    ],
    [
        82.892038169000045,
        49.841376195000066
    ],
    [
        82.893997651000063,
        49.839404531000071
    ],
    [
        82.896780613000033,
        49.836604129000079
    ],
    [
        82.896815657000047,
        49.836568893000049
    ],
    [
        82.900176959000078,
        49.832697716000041
    ],
    [
        82.901209140000049,
        49.829110987000035
    ],
    [
        82.901296561000038,
        49.828555108000046
    ],
    [
        82.901634840000042,
        49.826404019000051
    ],
    [
        82.902257446000078,
        49.82244468600004
    ],
    [
        82.902382232000036,
        49.821654097000078
    ],
    [
        82.902864759000067,
        49.81859692300003
    ],
    [
        82.905366596000079,
        49.815565441000047
    ],
    [
        82.907709502000046,
        49.814231842000027
    ],
    [
        82.910789117000036,
        49.812881584000081
    ],
    [
        82.918472430000065,
        49.810395733000064
    ],
    [
        82.921880290000047,
        49.809281129000055
    ],
    [
        82.926232845000072,
        49.80785750900003
    ],
    [
        82.933159456000055,
        49.80519812700004
    ],
    [
        82.939780686000063,
        49.802698994000082
    ],
    [
        82.940087173000052,
        49.802583310000045
    ],
    [
        82.945988100000079,
        49.800163827000063
    ],
    [
        82.947870571000067,
        49.797231281000052
    ],
    [
        82.946734474000039,
        49.793281842000056
    ],
    [
        82.945044362000033,
        49.790997112000071
    ],
    [
        82.941742184000077,
        49.787288406000073
    ],
    [
        82.936936965000029,
        49.781891112000039
    ],
    [
        82.932789657000058,
        49.777232311000034
    ],
    [
        82.931808849000049,
        49.775026847000049
    ],
    [
        82.931339988000047,
        49.772691132000034
    ],
    [
        82.933145937000063,
        49.770256429000028
    ],
    [
        82.937342016000059,
        49.768356499000049
    ],
    [
        82.94251156200005,
        49.768312012000081
    ],
    [
        82.942781971000045,
        49.768309676000058
    ],
    [
        82.947189213000058,
        49.769976572000076
    ],
    [
        82.950532558000077,
        49.77154259100007
    ],
    [
        82.951051715000062,
        49.771785698000031
    ],
    [
        82.955834981000066,
        49.772949317000041
    ],
    [
        82.957671520000076,
        49.773040012000081
    ],
    [
        82.961589401000083,
        49.772304103000067
    ],
    [
        82.969525112000042,
        49.768571967000071
    ],
    [
        82.972262876000059,
        49.764203748000057
    ],
    [
        82.971932401000061,
        49.758091345000025
    ],
    [
        82.971900895000033,
        49.757508573000052
    ],
    [
        82.968737368000063,
        49.753934130000061
    ],
    [
        82.968018562000054,
        49.753121920000069
    ],
    [
        82.968003736000071,
        49.753157268000052
    ],
    [
        82.965096982000034,
        49.753482623000082
    ],
    [
        82.953369728000041,
        49.754794448000041
    ],
    [
        82.927125068000066,
        49.754863146000048
    ],
    [
        82.904378328000064,
        49.756325029000038
    ],
    [
        82.888200533000031,
        49.757422767000037
    ],
    [
        82.870428365000066,
        49.75913046200003
    ],
    [
        82.868743374000076,
        49.757725450000066
    ],
    [
        82.86792100200006,
        49.759371161000047
    ],
    [
        82.840585720000036,
        49.761991573000046
    ],
    [
        82.844294563000062,
        49.773391410000045
    ],
    [
        82.83059757500007,
        49.784162285000036
    ],
    [
        82.834778455000048,
        49.796083619000058
    ],
    [
        82.819001831000037,
        49.793714305000037
    ],
    [
        82.802381026000035,
        49.802896609000072
    ],
    [
        82.793586236000067,
        49.805011338000043
    ],
    [
        82.787620077000042,
        49.790901569000027
    ],
    [
        82.786765253000056,
        49.79107212100007
    ],
    [
        82.759299048000059,
        49.797286101000054
    ],
    [
        82.758265071000039,
        49.797551551000026
    ],
    [
        82.756765807000079,
        49.797910824000041
    ],
    [
        82.756385342000044,
        49.797809285000028
    ],
    [
        82.75613376900003,
        49.79776679500003
    ],
    [
        82.755671678000056,
        49.797731816000066
    ],
    [
        82.75534613800005,
        49.797696966000046
    ],
    [
        82.754994917000033,
        49.797706743000049
    ],
    [
        82.754527621000079,
        49.79771603100005
    ],
    [
        82.754234292000035,
        49.797717195000075
    ],
    [
        82.754131722000068,
        49.797717581000029
    ],
    [
        82.753829664000079,
        49.79767135700007
    ],
    [
        82.753634523000073,
        49.797656645000075
    ],
    [
        82.753170150000074,
        49.797652620000065
    ],
    [
        82.752798403000043,
        49.797755493000068
    ],
    [
        82.75257165000005,
        49.797809728000061
    ],
    [
        82.752419239000062,
        49.797797381000066
    ],
    [
        82.752342268000064,
        49.797791128000028
    ],
    [
        82.752180241000076,
        49.79774714000007
    ],
    [
        82.751983464000034,
        49.797591059000069
    ],
    [
        82.751746214000036,
        49.797448809000059
    ],
    [
        82.751545589000045,
        49.797374519000073
    ],
    [
        82.751325852000036,
        49.797340259000066
    ],
    [
        82.751273825000055,
        49.797342036000032
    ],
    [
        82.750967910000043,
        49.797352328000045
    ],
    [
        82.750879756000074,
        49.797349931000042
    ],
    [
        82.750810724000075,
        49.797348143000079
    ],
    [
        82.750569622000057,
        49.797287635000032
    ],
    [
        82.750412967000045,
        49.797203821000039
    ],
    [
        82.75032690300003,
        49.797118804000036
    ],
    [
        82.750313867000045,
        49.797105919000046
    ],
    [
        82.750250258000051,
        49.796952227000077
    ],
    [
        82.750174476000041,
        49.796838463000029
    ],
    [
        82.750074375000054,
        49.796766651000041
    ],
    [
        82.75006589000003,
        49.796760575000064
    ],
    [
        82.749934630000041,
        49.79671836600005
    ],
    [
        82.749695165000048,
        49.796680042000048
    ],
    [
        82.749467716000083,
        49.796643647000053
    ],
    [
        82.748810072000083,
        49.796485670000038
    ],
    [
        82.748428691000072,
        49.796321314000068
    ],
    [
        82.748118944000055,
        49.796155829000043
    ],
    [
        82.747655103000056,
        49.795977249000032
    ],
    [
        82.74724615000008,
        49.795899444000042
    ],
    [
        82.746907510000085,
        49.795880278000027
    ],
    [
        82.746475187000044,
        49.795913327000051
    ],
    [
        82.746271203000049,
        49.795936277000067
    ],
    [
        82.746007747000078,
        49.795918131000064
    ],
    [
        82.74562647700003,
        49.795851008000056
    ],
    [
        82.745434179000085,
        49.795818525000072
    ],
    [
        82.745140848000062,
        49.795825152000077
    ],
    [
        82.744928393000066,
        49.795801875000052
    ],
    [
        82.744703678000064,
        49.795723485000053
    ],
    [
        82.744236211000043,
        49.795538294000039
    ],
    [
        82.744105269000045,
        49.795502728000031
    ],
    [
        82.74394460700006,
        49.795496292000053
    ],
    [
        82.743790193000052,
        49.795474342000034
    ],
    [
        82.74351487000007,
        49.795409974000052
    ],
    [
        82.743411932000072,
        49.795396059000041
    ],
    [
        82.743170384000052,
        49.795417366000038
    ],
    [
        82.74302681100005,
        49.795412919000057
    ],
    [
        82.742854280000074,
        49.795360264000067
    ],
    [
        82.742795164000086,
        49.795329011000035
    ],
    [
        82.74270815400007,
        49.795282935000046
    ],
    [
        82.742593039000042,
        49.795133072000056
    ],
    [
        82.742521928000031,
        49.79504045200008
    ],
    [
        82.742392405000032,
        49.79494961000006
    ],
    [
        82.742044591000081,
        49.794864257000029
    ],
    [
        82.741845648000037,
        49.794772256000044
    ],
    [
        82.741766905000077,
        49.794735857000035
    ],
    [
        82.741660230000036,
        49.794615942000064
    ],
    [
        82.74155504600003,
        49.794347960000039
    ],
    [
        82.741557162000049,
        49.794122557000037
    ],
    [
        82.741535941000052,
        49.794046045000073
    ],
    [
        82.741502270000069,
        49.793924537000066
    ],
    [
        82.741407069000047,
        49.793839786000035
    ],
    [
        82.741060608000055,
        49.793696999000076
    ],
    [
        82.740563208000083,
        49.793532113000026
    ],
    [
        82.74035953300006,
        49.793466706000061
    ],
    [
        82.740068894000046,
        49.793263373000059
    ],
    [
        82.739660761000039,
        49.793112607000069
    ],
    [
        82.739303630000052,
        49.792956668000045
    ],
    [
        82.738977914000031,
        49.792892463000044
    ],
    [
        82.737243973000034,
        49.79380775900006
    ],
    [
        82.729709396000032,
        49.803529284000035
    ],
    [
        82.724794080000038,
        49.806095244000062
    ],
    [
        82.724792783000055,
        49.80609589200003
    ],
    [
        82.711726814000031,
        49.812914425000031
    ],
    [
        82.689892938000071,
        49.819232510000063
    ],
    [
        82.660389313000053,
        49.800633695000045
    ],
    [
        82.658793090000074,
        49.800021790000073
    ],
    [
        82.657372343000077,
        49.799988779000046
    ],
    [
        82.65706810100005,
        49.800059267000051
    ],
    [
        82.65676787600006,
        49.800245281000059
    ],
    [
        82.656722392000063,
        49.800428502000045
    ],
    [
        82.656731972000046,
        49.80070889700005
    ],
    [
        82.656584468000062,
        49.800860040000032
    ],
    [
        82.656279680000068,
        49.80091466500005
    ],
    [
        82.65602307100005,
        49.800901568000029
    ],
    [
        82.655716064000046,
        49.800889236000046
    ],
    [
        82.655407874000048,
        49.800844236000046
    ],
    [
        82.655156353000052,
        49.800980142000071
    ],
    [
        82.655114980000064,
        49.801245350000045
    ],
    [
        82.654864598000074,
        49.801414731000079
    ],
    [
        82.654612502000077,
        49.801533876000065
    ],
    [
        82.654409329000032,
        49.801570273000038
    ],
    [
        82.654177608000055,
        49.801490685000033
    ],
    [
        82.654049588000078,
        49.801492514000074
    ],
    [
        82.65381880700005,
        49.801479088000065
    ],
    [
        82.653673410000067,
        49.801729013000056
    ],
    [
        82.653448849000085,
        49.801898023000035
    ],
    [
        82.653072871000063,
        49.80210187800003
    ],
    [
        82.652848305000077,
        49.802270887000077
    ],
    [
        82.652672706000033,
        49.802356295000038
    ],
    [
        82.652568798000061,
        49.802307517000031
    ],
    [
        82.652411947000076,
        49.802197138000054
    ],
    [
        82.652373183000066,
        49.802178630000071
    ],
    [
        82.652078673000062,
        49.802198974000078
    ],
    [
        82.651803130000076,
        49.802351939000062
    ],
    [
        82.65165483100003,
        49.802519047000033
    ],
    [
        82.651452760000041,
        49.802588062000041
    ],
    [
        82.651135922000037,
        49.802580984000031
    ],
    [
        82.651032429000054,
        49.802619865000054
    ],
    [
        82.650927478000085,
        49.802899375000038
    ],
    [
        82.650782014000072,
        49.803229501000033
    ],
    [
        82.650622393000049,
        49.803317241000059
    ],
    [
        82.65022579500004,
        49.803428186000076
    ],
    [
        82.650162597000076,
        49.803606082000044
    ],
    [
        82.650351643000079,
        49.80395216200003
    ],
    [
        82.650247935000039,
        49.804180674000065
    ],
    [
        82.650025701000061,
        49.804420809000078
    ],
    [
        82.649657761000071,
        49.804623011000047
    ],
    [
        82.649632009000072,
        49.804666447000045
    ],
    [
        82.64943667600005,
        49.804933847000029
    ],
    [
        82.649381948000041,
        49.80495858900008
    ],
    [
        82.649280450000049,
        49.805127725000034
    ],
    [
        82.649037950000036,
        49.80539314400005
    ],
    [
        82.648741311000038,
        49.805441293000058
    ],
    [
        82.648381542000038,
        49.805342836000079
    ],
    [
        82.648351454000078,
        49.805363001000046
    ],
    [
        82.647939971000085,
        49.805285959000059
    ],
    [
        82.647684481000056,
        49.805306322000035
    ],
    [
        82.647508861000063,
        49.805391721000035
    ],
    [
        82.647387572000071,
        49.805591933000073
    ],
    [
        82.647316899000032,
        49.805758739000055
    ],
    [
        82.646963669000058,
        49.805911990000027
    ],
    [
        82.646555564000039,
        49.805934520000051
    ],
    [
        82.646049817000062,
        49.806090791000031
    ],
    [
        82.645825209000066,
        49.806259785000066
    ],
    [
        82.64607002300005,
        49.806685868000045
    ],
    [
        82.645923395000068,
        49.806903162000026
    ],
    [
        82.645674841000073,
        49.807089266000048
    ],
    [
        82.64536761100004,
        49.807110357000056
    ],
    [
        82.645091991000072,
        49.807262498000057
    ],
    [
        82.644878023000047,
        49.807745364000027
    ],
    [
        82.644757856000069,
        49.807979049000039
    ],
    [
        82.644893190000062,
        49.808192335000058
    ],
    [
        82.645105942000043,
        49.80843715900005
    ],
    [
        82.645140279000032,
        49.80868537200007
    ],
    [
        82.644912275000081,
        49.808754789000034
    ],
    [
        82.64476471100005,
        49.80890591800005
    ],
    [
        82.644616438000071,
        49.80907301700006
    ],
    [
        82.644006765000086,
        49.809181303000059
    ],
    [
        82.643414919000065,
        49.809090083000058
    ],
    [
        82.643083665000063,
        49.80912827800006
    ],
    [
        82.642756439000038,
        49.809248463000074
    ],
    [
        82.642258286000072,
        49.809669224000061
    ],
    [
        82.642122479000079,
        49.810167700000079
    ],
    [
        82.641718816000036,
        49.810322502000076
    ],
    [
        82.641286514000058,
        49.810394765000069
    ],
    [
        82.640751432000059,
        49.810451759000046
    ],
    [
        82.639931745000069,
        49.810430721000046
    ],
    [
        82.639931306000051,
        49.810433362000026
    ],
    [
        82.639123057000063,
        49.809948113000075
    ],
    [
        82.638023163000071,
        49.809185840000055
    ],
    [
        82.638023601000043,
        49.809183200000064
    ],
    [
        82.637112343000069,
        49.808716296000057
    ],
    [
        82.635231540000063,
        49.808312466000075
    ],
    [
        82.634199222000063,
        49.808013024000047
    ],
    [
        82.633187577000058,
        49.807596885000066
    ],
    [
        82.632200115000046,
        49.807130989000029
    ],
    [
        82.631336328000032,
        49.806531034000045
    ],
    [
        82.630189624000081,
        49.805868927000063
    ],
    [
        82.629330861000085,
        49.805417110000064
    ],
    [
        82.628501093000068,
        49.80509799400005
    ],
    [
        82.627829705000067,
        49.804908999000077
    ],
    [
        82.626954181000031,
        49.804689332000066
    ],
    [
        82.626700768000035,
        49.804409486000054
    ],
    [
        82.626555128000064,
        49.804248654000048
    ],
    [
        82.626102304000085,
        49.803708161000031
    ],
    [
        82.625869239000053,
        49.80288512900006
    ],
    [
        82.625841039000079,
        49.802785721000078
    ],
    [
        82.626117357000055,
        49.801110388000041
    ],
    [
        82.626792637000051,
        49.799709844000063
    ],
    [
        82.623620731000074,
        49.799008162000064
    ],
    [
        82.623994273000051,
        49.799845065000056
    ],
    [
        82.623955046000049,
        49.799851696000076
    ],
    [
        82.621571882000069,
        49.80023622300007
    ],
    [
        82.620250476000081,
        49.800377596000033
    ],
    [
        82.615457992000074,
        49.80100380500005
    ],
    [
        82.614508406000084,
        49.801169685000048
    ],
    [
        82.614114135000079,
        49.802556885000058
    ],
    [
        82.602482019000036,
        49.807120782000027
    ],
    [
        82.591209200000037,
        49.812246719000029
    ],
    [
        82.58218366400007,
        49.818647196000029
    ],
    [
        82.570452229000068,
        49.826963058000047
    ],
    [
        82.565768173000038,
        49.829152054000076
    ],
    [
        82.555297096000061,
        49.833939010000051
    ],
    [
        82.555848241000035,
        49.842535530000077
    ],
    [
        82.556172220000064,
        49.847588580000036
    ],
    [
        82.560293966000074,
        49.856787800000063
    ],
    [
        82.560322033000034,
        49.864990130000081
    ],
    [
        82.567954607000047,
        49.869567486000051
    ],
    [
        82.568386435000036,
        49.869829965000065
    ],
    [
        82.563005468000085,
        49.874243688000035
    ],
    [
        82.553581611000084,
        49.883548327000028
    ],
    [
        82.521141295000064,
        49.907514113000047
    ],
    [
        82.49784254900004,
        49.885271612000054
    ],
    [
        82.49597138200005,
        49.886014525000064
    ],
    [
        82.490442211000072,
        49.888209538000069
    ],
    [
        82.489528010000072,
        49.888572411000041
    ],
    [
        82.489203596000038,
        49.88869023400008
    ],
    [
        82.488201966000076,
        49.887966796000057
    ],
    [
        82.487988347000055,
        49.88810883900004
    ],
    [
        82.479648308000037,
        49.882462947000079
    ],
    [
        82.466816903000051,
        49.873773520000043
    ],
    [
        82.442564957000059,
        49.884079547000056
    ],
    [
        82.442540070000064,
        49.88406279600008
    ],
    [
        82.435716082000056,
        49.879471057000046
    ],
    [
        82.431304384000043,
        49.881400136000082
    ],
    [
        82.431281217000048,
        49.881385361000071
    ],
    [
        82.428961489000073,
        49.883114333000037
    ],
    [
        82.43204578600006,
        49.88498144600004
    ],
    [
        82.43322543000005,
        49.885480108000081
    ],
    [
        82.436187042000086,
        49.886731839000049
    ],
    [
        82.43623298600005,
        49.886751275000051
    ],
    [
        82.436673777000067,
        49.887236003000055
    ],
    [
        82.436685646000058,
        49.887249027000053
    ],
    [
        82.436888132000036,
        49.887471652000045
    ],
    [
        82.436964499000055,
        49.888210134000076
    ],
    [
        82.436386889000062,
        49.888921485000026
    ],
    [
        82.436065358000064,
        49.889137348000077
    ],
    [
        82.43602454300003,
        49.88959505300005
    ],
    [
        82.436201177000044,
        49.890050330000065
    ],
    [
        82.436201403000041,
        49.89005091100006
    ],
    [
        82.436646382000049,
        49.890326723000044
    ],
    [
        82.437143490000039,
        49.890567585000042
    ],
    [
        82.437586389000046,
        49.890773110000055
    ],
    [
        82.438135869000064,
        49.890926282000066
    ],
    [
        82.438190251000037,
        49.890941431000044
    ],
    [
        82.438307437000049,
        49.891222162000076
    ],
    [
        82.438437228000055,
        49.89192397000005
    ],
    [
        82.438616210000077,
        49.892450108000048
    ],
    [
        82.43842152600007,
        49.893227066000065
    ],
    [
        82.438158906000069,
        49.893547715000068
    ],
    [
        82.438525667000079,
        49.893936565000047
    ],
    [
        82.439203433000046,
        49.894161912000072
    ],
    [
        82.440190590000043,
        49.894361064000066
    ],
    [
        82.44096006400008,
        49.894562109000049
    ],
    [
        82.441559720000043,
        49.894589800000062
    ],
    [
        82.442210914000043,
        49.894476585000064
    ],
    [
        82.443532916000038,
        49.894816579000064
    ],
    [
        82.443539940000051,
        49.894821223000065
    ],
    [
        82.443830588000083,
        49.895483456000079
    ],
    [
        82.44423179100005,
        49.895795614000065
    ],
    [
        82.445091865000052,
        49.896060147000071
    ],
    [
        82.445809053000062,
        49.896333381000034
    ],
    [
        82.44635861200004,
        49.89650238400003
    ],
    [
        82.447506991000068,
        49.896628296000074
    ],
    [
        82.44799596200005,
        49.896551827000053
    ],
    [
        82.448697156000037,
        49.896332486000063
    ],
    [
        82.448759777000078,
        49.896373869000058
    ],
    [
        82.448762329000033,
        49.896373074000053
    ],
    [
        82.449596829000029,
        49.896925340000053
    ],
    [
        82.450542360000043,
        49.897547417000055
    ],
    [
        82.450996752000037,
        49.898175597000034
    ],
    [
        82.451777986000081,
        49.898764524000057
    ],
    [
        82.451959253000041,
        49.899360969000043
    ],
    [
        82.452363155000057,
        49.89967441500005
    ],
    [
        82.453277233000051,
        49.900006766000047
    ],
    [
        82.454151511000077,
        49.900066147000075
    ],
    [
        82.454861181000069,
        49.900127588000032
    ],
    [
        82.454905382000049,
        49.900167043000067
    ],
    [
        82.45492630900003,
        49.90016862300007
    ],
    [
        82.455431260000069,
        49.900619476000031
    ],
    [
        82.455498400000067,
        49.901041484000075
    ],
    [
        82.455456729000048,
        49.901464905000068
    ],
    [
        82.455245314000081,
        49.901678538000056
    ],
    [
        82.454983531000039,
        49.902068715000041
    ],
    [
        82.454816980000032,
        49.902687636000053
    ],
    [
        82.454837488000067,
        49.902852944000074
    ],
    [
        82.454924300000073,
        49.903552706000028
    ],
    [
        82.454930599000079,
        49.903611178000062
    ],
    [
        82.454999943000075,
        49.904254724000054
    ],
    [
        82.454903866000052,
        49.904679233000081
    ],
    [
        82.454854655000076,
        49.905080302000044
    ],
    [
        82.454817303000084,
        49.905419658000028
    ],
    [
        82.454882247000057,
        49.905770979000067
    ],
    [
        82.455051907000041,
        49.905979868000031
    ],
    [
        82.455654888000083,
        49.906112957000062
    ],
    [
        82.456314739000049,
        49.906280527000035
    ],
    [
        82.456820522000044,
        49.906802527000082
    ],
    [
        82.456990319000056,
        49.907640969000056
    ],
    [
        82.457012488000032,
        49.907750517000068
    ],
    [
        82.457081846000051,
        49.908242851000068
    ],
    [
        82.456933204000052,
        49.908738286000073
    ],
    [
        82.456513772000051,
        49.909270711000033
    ],
    [
        82.455818564000083,
        49.909737504000077
    ],
    [
        82.45534346200003,
        49.910236681000072
    ],
    [
        82.455142671000033,
        49.910801912000068
    ],
    [
        82.455482126000049,
        49.91122054300007
    ],
    [
        82.455883308000068,
        49.911884533000034
    ],
    [
        82.456057360000045,
        49.912234341000044
    ],
    [
        82.45645023600008,
        49.912581627000066
    ],
    [
        82.456626025000048,
        49.91300190700008
    ],
    [
        82.456531614000085,
        49.91346092200007
    ],
    [
        82.457060409000064,
        49.913930797000035
    ],
    [
        82.457569439000054,
        49.914322158000061
    ],
    [
        82.458379041000057,
        49.915092032000075
    ],
    [
        82.458410818000061,
        49.916148804000045
    ],
    [
        82.458311655000045,
        49.916466180000043
    ],
    [
        82.458164555000053,
        49.916669477000028
    ],
    [
        82.458152781000081,
        49.917095803000052
    ],
    [
        82.457913148000046,
        49.917534258000046
    ],
    [
        82.457334864000075,
        49.917880680000053
    ],
    [
        82.456830295000032,
        49.918007598000031
    ],
    [
        82.455939959000034,
        49.918070718000081
    ],
    [
        82.454862503000072,
        49.918294425000056
    ],
    [
        82.454195473000084,
        49.918536436000068
    ],
    [
        82.453380926000079,
        49.918552684000076
    ],
    [
        82.448619098000052,
        49.918585011000062
    ],
    [
        82.442657617000066,
        49.918640589000063
    ],
    [
        82.434164180000039,
        49.91871924000003
    ],
    [
        82.390475651000031,
        49.918963274000077
    ],
    [
        82.396870339000031,
        49.923736986000051
    ],
    [
        82.40926427100004,
        49.932776576000037
    ],
    [
        82.426628801000049,
        49.941696186000058
    ],
    [
        82.429931900000042,
        49.942968371000063
    ],
    [
        82.447666776000062,
        49.949808512000061
    ],
    [
        82.454485243000079,
        49.96151393100007
    ],
    [
        82.453000243000076,
        49.967762305000065
    ],
    [
        82.452718625000045,
        49.968947121000042
    ],
    [
        82.452220364000084,
        49.971043147000046
    ],
    [
        82.450433546000056,
        49.972234856000057
    ],
    [
        82.449606324000058,
        49.972731128000078
    ],
    [
        82.44177460700007,
        49.977880896000045
    ],
    [
        82.447115659000076,
        49.988317572000028
    ],
    [
        82.446227088000057,
        49.99137390900006
    ],
    [
        82.444050369000081,
        49.994406721000075
    ],
    [
        82.437573500000042,
        50.00029631700005
    ],
    [
        82.437548965000076,
        50.000315503000081
    ],
    [
        82.442989762000082,
        50.004018679000069
    ],
    [
        82.441051522000066,
        50.006980143000078
    ],
    [
        82.443883346000064,
        50.008742181000059
    ],
    [
        82.453808407000054,
        50.01479936100003
    ],
    [
        82.454101397000045,
        50.014751147000027
    ],
    [
        82.454424971000037,
        50.014743360000068
    ],
    [
        82.455089574000056,
        50.01487944400003
    ],
    [
        82.455573288000039,
        50.01510449500006
    ],
    [
        82.455944583000075,
        50.015279450000037
    ],
    [
        82.456259418000059,
        50.015364917000056
    ],
    [
        82.45662991100005,
        50.015378890000079
    ],
    [
        82.457068555000035,
        50.015160995000031
    ],
    [
        82.458336279000036,
        50.014634589000082
    ],
    [
        82.459142826000061,
        50.014852794000035
    ],
    [
        82.46326110900003,
        50.017677069000058
    ],
    [
        82.463435882000056,
        50.017735883000057
    ],
    [
        82.493869134000079,
        50.020366802000069
    ],
    [
        82.495337993000078,
        50.021383788000037
    ],
    [
        82.498993096000049,
        50.024732596000035
    ],
    [
        82.497750347000078,
        50.02578374400008
    ],
    [
        82.499341963000063,
        50.027063067000029
    ],
    [
        82.492616238000039,
        50.03076739800008
    ],
    [
        82.495706630000029,
        50.032683003000045
    ],
    [
        82.501214336000032,
        50.036097084000062
    ],
    [
        82.509589968000057,
        50.033024838000074
    ],
    [
        82.510368601000039,
        50.032716554000046
    ],
    [
        82.514408525000078,
        50.030168153000034
    ],
    [
        82.516286161000039,
        50.028887585000064
    ],
    [
        82.524249861000044,
        50.024656381000057
    ],
    [
        82.528874947000077,
        50.022092440000051
    ],
    [
        82.532196715000055,
        50.024550359000045
    ],
    [
        82.529038092000064,
        50.026347219000058
    ],
    [
        82.530946670000048,
        50.027466487000027
    ],
    [
        82.530321146000063,
        50.028435882000053
    ],
    [
        82.530557026000054,
        50.029350549000071
    ],
    [
        82.536560680000036,
        50.025790470000061
    ],
    [
        82.545769011000061,
        50.021025034000047
    ],
    [
        82.554048098000067,
        50.015824725000073
    ],
    [
        82.557496700000058,
        50.01598541900006
    ],
    [
        82.560523368000077,
        50.015137391000053
    ],
    [
        82.562133019000044,
        50.013985827000056
    ],
    [
        82.564964346000068,
        50.014208779000057
    ],
    [
        82.566127692000066,
        50.013750235000032
    ],
    [
        82.569394961000057,
        50.015644482000027
    ],
    [
        82.570693076000055,
        50.016004828000064
    ],
    [
        82.572034195000072,
        50.014161115000036
    ],
    [
        82.576820694000048,
        50.012671671000078
    ],
    [
        82.581597164000073,
        50.012935137000056
    ],
    [
        82.586158457000067,
        50.010737669000036
    ],
    [
        82.590843996000046,
        50.009043223000049
    ],
    [
        82.590973606000034,
        50.015161313000078
    ],
    [
        82.596125357000062,
        50.015677062000066
    ],
    [
        82.607212612000069,
        50.020355356000039
    ],
    [
        82.611418233000052,
        50.020509141000048
    ],
    [
        82.612609078000048,
        50.019376145000081
    ],
    [
        82.620873794000033,
        50.019387430000052
    ],
    [
        82.621052005000081,
        50.020181644000047
    ],
    [
        82.622112562000041,
        50.020180752000044
    ],
    [
        82.624233240000081,
        50.019363463000047
    ],
    [
        82.627898138000035,
        50.019393359000048
    ],
    [
        82.630033643000047,
        50.01932597800004
    ],
    [
        82.630637197000055,
        50.015700787000071
    ],
    [
        82.630881963000036,
        50.009506259000034
    ],
    [
        82.64201625100003,
        50.010702490000028
    ],
    [
        82.642830477000075,
        50.012505693000037
    ],
    [
        82.648404504000041,
        50.014341040000033
    ],
    [
        82.647740717000033,
        50.023079618000054
    ],
    [
        82.64641359400008,
        50.028443378000077
    ],
    [
        82.644289172000072,
        50.030781380000064
    ],
    [
        82.650710151000055,
        50.032405317000041
    ],
    [
        82.658010416000081,
        50.034899488000065
    ],
    [
        82.659659992000059,
        50.02763866600003
    ],
    [
        82.662370209000073,
        50.027945049000039
    ],
    [
        82.670592462000059,
        50.028030517000047
    ],
    [
        82.674685107000073,
        50.027908289000038
    ],
    [
        82.676415366000072,
        50.025330581000048
    ],
    [
        82.691714367000031,
        50.02710528700004
    ],
    [
        82.694660949000081,
        50.030798623000067
    ],
    [
        82.694634311000073,
        50.032039055000041
    ],
    [
        82.703013413000065,
        50.036797407000051
    ],
    [
        82.716871251000043,
        50.037926009000046
    ],
    [
        82.724064471000077,
        50.040773555000044
    ],
    [
        82.722723592000079,
        50.040852405000066
    ],
    [
        82.722980593000045,
        50.041005378000079
    ],
    [
        82.722909635000065,
        50.041154205000055
    ],
    [
        82.719187430000034,
        50.041430752000053
    ],
    [
        82.718424257000038,
        50.041759483000078
    ],
    [
        82.716219591000083,
        50.043310040000051
    ],
    [
        82.719679452000037,
        50.044888369000034
    ],
    [
        82.720596665000073,
        50.045242378000069
    ],
    [
        82.722338755000067,
        50.045964282000057
    ],
    [
        82.724682029000064,
        50.045671594000055
    ],
    [
        82.728289265000058,
        50.045033530000069
    ],
    [
        82.729487443000039,
        50.044906393000076
    ],
    [
        82.730106922000061,
        50.047007301000065
    ],
    [
        82.730065463000074,
        50.047705610000037
    ],
    [
        82.729814260000069,
        50.047865236000064
    ],
    [
        82.72976519100007,
        50.048026262000064
    ],
    [
        82.729852032000053,
        50.048312220000071
    ],
    [
        82.730031183000051,
        50.04830114300006
    ],
    [
        82.730022308000059,
        50.048436447000029
    ],
    [
        82.733590202000073,
        50.048088909000057
    ],
    [
        82.735158977000083,
        50.04811679900007
    ],
    [
        82.735650347000046,
        50.045680710000056
    ],
    [
        82.736255647000064,
        50.042683844000067
    ],
    [
        82.737119449000033,
        50.040691268000046
    ],
    [
        82.737141329000053,
        50.040543698000079
    ],
    [
        82.737235094000084,
        50.040502911000033
    ],
    [
        82.737281127000074,
        50.040307323000036
    ],
    [
        82.744327181000074,
        50.039853113000049
    ],
    [
        82.746369638000033,
        50.039693213000078
    ],
    [
        82.746960567000031,
        50.040454728000043
    ],
    [
        82.747720438000044,
        50.042262879000077
    ],
    [
        82.753951062000056,
        50.042425223000066
    ],
    [
        82.753379162000044,
        50.039270152000029
    ],
    [
        82.756251856000063,
        50.038985035000053
    ],
    [
        82.759049080000068,
        50.038709464000078
    ],
    [
        82.762103059000083,
        50.038404016000072
    ],
    [
        82.763452057000052,
        50.038270045000047
    ],
    [
        82.764824279000038,
        50.038133789000028
    ],
    [
        82.76703283900008,
        50.037941487000069
    ],
    [
        82.77066642300008,
        50.037664266000036
    ],
    [
        82.770216259000051,
        50.037138575000029
    ],
    [
        82.773309374000064,
        50.035613201000047
    ],
    [
        82.78521956600008,
        50.029444912000031
    ],
    [
        82.787744710000084,
        50.021832753000069
    ],
    [
        82.790644069000052,
        50.019792421000034
    ],
    [
        82.786760497000046,
        50.015807641000038
    ],
    [
        82.785157062000053,
        50.011610155000028
    ],
    [
        82.785108036000054,
        50.011596345000044
    ],
    [
        82.784161039000082,
        50.011742634000029
    ],
    [
        82.783909577000031,
        50.011781478000046
    ],
    [
        82.78367409100008,
        50.011914036000064
    ],
    [
        82.783335706000059,
        50.012250029000029
    ],
    [
        82.782853773000056,
        50.012780777000046
    ],
    [
        82.782537844000046,
        50.013022644000046
    ],
    [
        82.782097628000031,
        50.013310748000038
    ],
    [
        82.782032695000055,
        50.013344073000042
    ],
    [
        82.781665349000036,
        50.01353259900003
    ],
    [
        82.781036402000041,
        50.013743264000027
    ],
    [
        82.78014431500003,
        50.014112842000031
    ],
    [
        82.779530948000058,
        50.014358370000082
    ],
    [
        82.778210036000075,
        50.014938886000039
    ],
    [
        82.777577551000036,
        50.015160420000029
    ],
    [
        82.776694114000065,
        50.015518150000048
    ],
    [
        82.77662465800006,
        50.015545514000053
    ],
    [
        82.776258612000049,
        50.015689768000072
    ],
    [
        82.776013885000054,
        50.015792332000046
    ],
    [
        82.775760772000069,
        50.015898350000043
    ],
    [
        82.77531874400006,
        50.016033246000063
    ],
    [
        82.774591266000073,
        50.016182538000066
    ],
    [
        82.774008072000072,
        50.016260159000069
    ],
    [
        82.773620041000072,
        50.01637997000006
    ],
    [
        82.773612688000071,
        50.016379229000052
    ],
    [
        82.772469399000045,
        50.016260233000025
    ],
    [
        82.771496106000029,
        50.015838861000077
    ],
    [
        82.770999529000051,
        50.015092810000056
    ],
    [
        82.770780712000033,
        50.014936560000081
    ],
    [
        82.770229051000058,
        50.014672510000025
    ],
    [
        82.769416733000071,
        50.014527478000048
    ],
    [
        82.768558719000055,
        50.014410787000031
    ],
    [
        82.767738535000035,
        50.014123534000078
    ],
    [
        82.767639926000072,
        50.014056211000081
    ],
    [
        82.767363997000075,
        50.013867963000052
    ],
    [
        82.767025998000065,
        50.013604190000081
    ],
    [
        82.766905121000036,
        50.013509873000032
    ],
    [
        82.765993965000064,
        50.012936770000067
    ],
    [
        82.765337318000036,
        50.01271147600005
    ],
    [
        82.764438162000033,
        50.012464739000052
    ],
    [
        82.763665262000075,
        50.012402011000063
    ],
    [
        82.763664841000036,
        50.012401972000077
    ],
    [
        82.762858882000046,
        50.01248796200008
    ],
    [
        82.762361045000034,
        50.012697296000056
    ],
    [
        82.762234330000069,
        50.012744578000081
    ],
    [
        82.761839296000062,
        50.012891976000049
    ],
    [
        82.76133963400008,
        50.013440525000078
    ],
    [
        82.760799045000056,
        50.013938578000079
    ],
    [
        82.76069334400006,
        50.014352206000069
    ],
    [
        82.760638629000084,
        50.01502292400005
    ],
    [
        82.76049774300003,
        50.015540888000032
    ],
    [
        82.760027518000072,
        50.015831028000036
    ],
    [
        82.75912339100006,
        50.016231644000072
    ],
    [
        82.758331783000074,
        50.016475584000034
    ],
    [
        82.757537701000047,
        50.016615752000064
    ],
    [
        82.757534199000077,
        50.016615401000081
    ],
    [
        82.75753069700005,
        50.016615050000041
    ],
    [
        82.756012825000084,
        50.016458818000046
    ],
    [
        82.754358667000076,
        50.016071894000049
    ],
    [
        82.753621629000065,
        50.015899404000038
    ],
    [
        82.753621231000068,
        50.015899949000072
    ],
    [
        82.753510557000084,
        50.016048720000072
    ],
    [
        82.753169239000044,
        50.016132601000038
    ],
    [
        82.753161956000042,
        50.016131858000051
    ],
    [
        82.752721844000064,
        50.01608744400005
    ],
    [
        82.751912685000036,
        50.015564614000027
    ],
    [
        82.751318967000032,
        50.015390212000057
    ],
    [
        82.750625709000076,
        50.015231517000075
    ],
    [
        82.750611997000078,
        50.015230465000059
    ],
    [
        82.749257425000053,
        50.01512742500006
    ],
    [
        82.748680167000032,
        50.015065735000064
    ],
    [
        82.748676805000059,
        50.015065382000046
    ],
    [
        82.748415729000044,
        50.015061318000051
    ],
    [
        82.748249177000048,
        50.01505888500003
    ],
    [
        82.748247636000031,
        50.015058684000053
    ],
    [
        82.748246022000046,
        50.015058439000029
    ],
    [
        82.747556534000068,
        50.01495127700008
    ],
    [
        82.746847368000033,
        50.01488428600004
    ],
    [
        82.746723247000034,
        50.014872580000031
    ],
    [
        82.746109238000031,
        50.014774657000032
    ],
    [
        82.746051418000036,
        50.014765458000056
    ],
    [
        82.745316212000034,
        50.014593966000064
    ],
    [
        82.744599354000059,
        50.014356918000033
    ],
    [
        82.743848229000037,
        50.014063512000064
    ],
    [
        82.743741271000033,
        50.014488741000036
    ],
    [
        82.743396122000036,
        50.014534483000034
    ],
    [
        82.74339352100003,
        50.014533982000046
    ],
    [
        82.742923665000035,
        50.014445788000046
    ],
    [
        82.742765440000085,
        50.014397727000073
    ],
    [
        82.742358699000079,
        50.014274235000073
    ],
    [
        82.741951522000079,
        50.014140498000074
    ],
    [
        82.741793155000039,
        50.014088482000034
    ],
    [
        82.741393194000068,
        50.013904586000081
    ],
    [
        82.740918401000044,
        50.013686279000069
    ],
    [
        82.740346361000036,
        50.01332056900003
    ],
    [
        82.73987971400004,
        50.012884581000037
    ],
    [
        82.739463389000036,
        50.012655531000064
    ],
    [
        82.73878615700005,
        50.012360894000039
    ],
    [
        82.738214664000054,
        50.01200941500008
    ],
    [
        82.73772794100006,
        50.01162901500004
    ],
    [
        82.737091108000072,
        50.011264260000075
    ],
    [
        82.736436458000071,
        50.01098352200006
    ],
    [
        82.735545452000054,
        50.010706274000029
    ],
    [
        82.734683532000076,
        50.010622119000061
    ],
    [
        82.734682974000066,
        50.010622127000033
    ],
    [
        82.734682417000045,
        50.010622180000041
    ],
    [
        82.734233095000036,
        50.010670669000035
    ],
    [
        82.733273071000042,
        50.010928690000071
    ],
    [
        82.732667114000037,
        50.011096851000048
    ],
    [
        82.73112890200008,
        50.01146568200005
    ],
    [
        82.730706080000061,
        50.011523921000048
    ],
    [
        82.730308603000083,
        50.011553684000035
    ],
    [
        82.730306425000038,
        50.011553266000078
    ],
    [
        82.730051499000069,
        50.011506182000062
    ],
    [
        82.730049832000077,
        50.011506342000075
    ],
    [
        82.729190729000038,
        50.011590058000081
    ],
    [
        82.728213858000061,
        50.011525912000081
    ],
    [
        82.727380787000072,
        50.01136516300005
    ],
    [
        82.727194912000073,
        50.011329272000069
    ],
    [
        82.727078161000065,
        50.011306735000062
    ],
    [
        82.726125497000055,
        50.011094575000072
    ],
    [
        82.725756517000036,
        50.011012407000067
    ],
    [
        82.725616922000086,
        50.010961708000082
    ],
    [
        82.725138985000058,
        50.010786302000042
    ],
    [
        82.724673351000035,
        50.010557081000059
    ],
    [
        82.723441237000031,
        50.010182631000077
    ],
    [
        82.723438486000077,
        50.010181774000046
    ],
    [
        82.723435732000041,
        50.010180870000056
    ],
    [
        82.722388610000053,
        50.009865871000045
    ],
    [
        82.721552480000071,
        50.009485561000076
    ],
    [
        82.720897477000051,
        50.009082461000048
    ],
    [
        82.720337537000034,
        50.008756663000042
    ],
    [
        82.719076959000063,
        50.008422870000061
    ],
    [
        82.718417154000065,
        50.008329797000044
    ],
    [
        82.717943422000076,
        50.008262959000035
    ],
    [
        82.717420653000033,
        50.008158946000037
    ],
    [
        82.717391217000056,
        50.008153089000075
    ],
    [
        82.717136551000067,
        50.00786213300006
    ],
    [
        82.716674576000059,
        50.007731637000063
    ],
    [
        82.716303986000071,
        50.007579691000046
    ],
    [
        82.71585919100005,
        50.007056250000062
    ],
    [
        82.715733607000061,
        50.006689114000039
    ],
    [
        82.715551689000051,
        50.006157281000071
    ],
    [
        82.715240170000072,
        50.005945835000034
    ],
    [
        82.715061093000031,
        50.005880647000026
    ],
    [
        82.714467398000068,
        50.00566254000006
    ],
    [
        82.714215486000057,
        50.005410828000038
    ],
    [
        82.714139037000052,
        50.004979889000026
    ],
    [
        82.713333029000069,
        50.004069811000079
    ],
    [
        82.713233473000059,
        50.003957454000044
    ],
    [
        82.713195850000034,
        50.00391354900006
    ],
    [
        82.712795957000083,
        50.00344657100004
    ],
    [
        82.712727913000037,
        50.003367061000063
    ],
    [
        82.712693400000035,
        50.003316457000039
    ],
    [
        82.712456521000036,
        50.002969123000071
    ],
    [
        82.712206346000073,
        50.002585120000049
    ],
    [
        82.711873500000081,
        50.00226127500008
    ],
    [
        82.711828203000039,
        50.002217259000076
    ],
    [
        82.711767420000058,
        50.002139799000076
    ],
    [
        82.711580545000061,
        50.001901866000026
    ],
    [
        82.710772457000076,
        50.001497642000061
    ],
    [
        82.706482760000085,
        50.000858151000045
    ],
    [
        82.705001135000032,
        50.000637235000056
    ],
    [
        82.704998552000063,
        50.000637184000027
    ],
    [
        82.704101284000046,
        50.000613698000052
    ],
    [
        82.702857674000086,
        50.000714877000064
    ],
    [
        82.700992530000065,
        50.000797519000059
    ],
    [
        82.69948452400007,
        50.000695746000076
    ],
    [
        82.698808173000032,
        50.00041426000007
    ],
    [
        82.698283403000062,
        50.000159006000047
    ],
    [
        82.696897943000067,
        49.999846854000054
    ],
    [
        82.695917224000084,
        49.999291127000049
    ],
    [
        82.69580172600007,
        49.999225699000078
    ],
    [
        82.69571430700006,
        49.999175324000078
    ],
    [
        82.694966699000076,
        49.998744571000032
    ],
    [
        82.694815214000073,
        49.998657280000032
    ],
    [
        82.694482776000029,
        49.998443927000039
    ],
    [
        82.69326022000007,
        49.997659292000037
    ],
    [
        82.692382759000054,
        49.997308688000032
    ],
    [
        82.692311919000076,
        49.997291291000067
    ],
    [
        82.692060431000073,
        49.997229642000036
    ],
    [
        82.691775951000068,
        49.997148020000054
    ],
    [
        82.691576505000057,
        49.997090781000054
    ],
    [
        82.691124776000038,
        49.996961114000044
    ],
    [
        82.690959725000084,
        49.996904180000058
    ],
    [
        82.69031215900003,
        49.996680758000082
    ],
    [
        82.689837588000046,
        49.996446491000029
    ],
    [
        82.689544923000085,
        49.996250616000054
    ],
    [
        82.689272508000045,
        49.996001712000066
    ],
    [
        82.689152475000071,
        49.995653436000055
    ],
    [
        82.689111895000053,
        49.99538523700005
    ],
    [
        82.689142224000079,
        49.995176290000074
    ],
    [
        82.688834648000068,
        49.994960580000054
    ],
    [
        82.688535448000039,
        49.994615724000028
    ],
    [
        82.688225290000048,
        49.994401714000048
    ],
    [
        82.68814281300007,
        49.994381390000058
    ],
    [
        82.687689411000065,
        49.994269663000068
    ],
    [
        82.687478794000072,
        49.994227504000037
    ],
    [
        82.68725952300008,
        49.994161195000061
    ],
    [
        82.686907807000068,
        49.994056624000052
    ],
    [
        82.686587003000056,
        49.99394656700008
    ],
    [
        82.68615298900005,
        49.993720103000044
    ],
    [
        82.685690899000065,
        49.993655660000059
    ],
    [
        82.684561876000032,
        49.993497920000038
    ],
    [
        82.684040584000059,
        49.993449393000049
    ],
    [
        82.683820438000055,
        49.993431643000065
    ],
    [
        82.68360748200007,
        49.993424082000047
    ],
    [
        82.68360322400008,
        49.993423919000065
    ],
    [
        82.68385061500004,
        49.993588640000041
    ],
    [
        82.683905181000057,
        49.993625519000034
    ],
    [
        82.683906673000081,
        49.993668204000073
    ],
    [
        82.683812375000059,
        49.993757509000034
    ],
    [
        82.683620293000047,
        49.993800490000069
    ],
    [
        82.683431547000055,
        49.993835196000077
    ],
    [
        82.68332345400006,
        49.993855063000069
    ],
    [
        82.683205035000071,
        49.993914686000039
    ],
    [
        82.683183037000049,
        49.993925750000074
    ],
    [
        82.682806682000034,
        49.994604473000038
    ],
    [
        82.682740110000054,
        49.994705062000037
    ],
    [
        82.682669621000059,
        49.994881098000064
    ],
    [
        82.682472697000037,
        49.995156929000075
    ],
    [
        82.682074812000053,
        49.995629119000057
    ],
    [
        82.681476812000085,
        49.996024687000045
    ],
    [
        82.681048591000035,
        49.996185772000047
    ],
    [
        82.68068160200005,
        49.99627902900005
    ],
    [
        82.680195687000037,
        49.996349733000045
    ],
    [
        82.679785108000033,
        49.996384996000074
    ],
    [
        82.679780194000045,
        49.996384033000027
    ],
    [
        82.679749787000048,
        49.996378046000075
    ],
    [
        82.67934566100007,
        49.996298397000032
    ],
    [
        82.678915481000047,
        49.996181542000045
    ],
    [
        82.678907545000072,
        49.996177755000076
    ],
    [
        82.678392750000057,
        49.99592537500007
    ],
    [
        82.678238265000061,
        49.995740867000052
    ],
    [
        82.678115530000071,
        49.995350765000069
    ],
    [
        82.678190296000082,
        49.995034813000075
    ],
    [
        82.678594764000081,
        49.994610462000026
    ],
    [
        82.678631363000079,
        49.994572080000069
    ],
    [
        82.678683260000071,
        49.994526416000042
    ],
    [
        82.679291743000078,
        49.99399143800008
    ],
    [
        82.679907765000053,
        49.99336702100004
    ],
    [
        82.680124378000073,
        49.992919763000032
    ],
    [
        82.680159765000042,
        49.992846737000036
    ],
    [
        82.680222934000085,
        49.992496650000078
    ],
    [
        82.680040206000058,
        49.991876258000048
    ],
    [
        82.679622436000045,
        49.990996417000076
    ],
    [
        82.678997544000083,
        49.990171454000063
    ],
    [
        82.678472648000081,
        49.989720151000029
    ],
    [
        82.677926805000084,
        49.989373851000039
    ],
    [
        82.67813607000005,
        49.988671623000073
    ],
    [
        82.678326509000044,
        49.987985583000068
    ],
    [
        82.678438947000075,
        49.987584478000031
    ],
    [
        82.678443164000043,
        49.98756940100003
    ],
    [
        82.678445655000075,
        49.987562891000039
    ],
    [
        82.678671788000031,
        49.986974921000069
    ],
    [
        82.678970817000049,
        49.986648211000045
    ],
    [
        82.679063843000051,
        49.986262491000048
    ],
    [
        82.678799898000079,
        49.98573125300004
    ],
    [
        82.678745710000044,
        49.985646847000055
    ],
    [
        82.678179847000081,
        49.985154910000063
    ],
    [
        82.678137831000072,
        49.98503157600004
    ],
    [
        82.678144945000042,
        49.984825485000044
    ],
    [
        82.678055283000049,
        49.983966826000028
    ],
    [
        82.678966925000054,
        49.981629974000043
    ],
    [
        82.676179101000059,
        49.980841074000068
    ],
    [
        82.676832843000057,
        49.978457850000041
    ],
    [
        82.677110430000084,
        49.970870608000041
    ],
    [
        82.673319405000029,
        49.967756309000038
    ],
    [
        82.677712771000074,
        49.967322836000051
    ],
    [
        82.679105653000079,
        49.96746196600003
    ],
    [
        82.678293855000049,
        49.968749572000036
    ],
    [
        82.678185549000034,
        49.969090532000052
    ],
    [
        82.678108866000059,
        49.969584879000081
    ],
    [
        82.677882128000078,
        49.97034607300003
    ],
    [
        82.677680578000036,
        49.971032301000037
    ],
    [
        82.678309084000034,
        49.97105969200004
    ],
    [
        82.679645561000029,
        49.970426302000078
    ],
    [
        82.681196874000079,
        49.970107055000028
    ],
    [
        82.681812075000039,
        49.970039330000077
    ],
    [
        82.687283534000073,
        49.970082297000033
    ],
    [
        82.688279786000066,
        49.968964935000031
    ],
    [
        82.68849050700004,
        49.968730496000035
    ],
    [
        82.688955382000074,
        49.968207174000042
    ],
    [
        82.689275992000034,
        49.967847563000078
    ],
    [
        82.689455981000037,
        49.967492028000038
    ],
    [
        82.68973247200006,
        49.967108990000042
    ],
    [
        82.690792907000059,
        49.965835799000047
    ],
    [
        82.691853423000055,
        49.964562599000033
    ],
    [
        82.69270358600005,
        49.963515028000074
    ],
    [
        82.693553850000058,
        49.962467453000045
    ],
    [
        82.694055080000055,
        49.961760206000065
    ],
    [
        82.687282767000056,
        49.959309282000049
    ],
    [
        82.688008983000032,
        49.958833155000036
    ],
    [
        82.690148501000067,
        49.95955844000008
    ],
    [
        82.690803499000083,
        49.959194597000078
    ],
    [
        82.691618605000031,
        49.959561630000053
    ],
    [
        82.691861158000052,
        49.959353888000066
    ],
    [
        82.691324197000085,
        49.95855087700005
    ],
    [
        82.691544137000051,
        49.958390622000081
    ],
    [
        82.691350487000079,
        49.958105313000033
    ],
    [
        82.693001816000049,
        49.957574491000059
    ],
    [
        82.69099226000003,
        49.954194894000068
    ],
    [
        82.691052422000041,
        49.954155203000028
    ],
    [
        82.690843765000068,
        49.953771364000033
    ],
    [
        82.690774918000045,
        49.95345284800004
    ],
    [
        82.690860189000034,
        49.952954634000037
    ],
    [
        82.691645514000072,
        49.95189644800007
    ],
    [
        82.690520453000033,
        49.951556666000045
    ],
    [
        82.68998556300005,
        49.952001703000064
    ],
    [
        82.689605606000043,
        49.952827185000046
    ],
    [
        82.688238894000051,
        49.952559815000029
    ],
    [
        82.689752239000086,
        49.950848049000058
    ],
    [
        82.691026754000063,
        49.950741956000059
    ],
    [
        82.692090269000062,
        49.949880481000037
    ],
    [
        82.694445332000043,
        49.949129829000071
    ],
    [
        82.695839756000055,
        49.949911629000042
    ],
    [
        82.695393551000052,
        49.95061473800007
    ],
    [
        82.695329970000046,
        49.951421049000032
    ],
    [
        82.695463686000039,
        49.952166013000067
    ],
    [
        82.695914655000081,
        49.952897280000059
    ],
    [
        82.696495479000077,
        49.953423213000065
    ],
    [
        82.696905444000038,
        49.953666077000037
    ],
    [
        82.701866639000059,
        49.954385402000071
    ],
    [
        82.703776288000086,
        49.956505322000055
    ],
    [
        82.703465811000058,
        49.956779942000026
    ],
    [
        82.703550430000064,
        49.95701770900007
    ],
    [
        82.702940978000072,
        49.957428705000041
    ],
    [
        82.702071158000081,
        49.957581535000031
    ],
    [
        82.702742330000035,
        49.95834913300007
    ],
    [
        82.703940591000048,
        49.957834757000057
    ],
    [
        82.704419259000076,
        49.958407699000077
    ],
    [
        82.705369608000069,
        49.957060409000064
    ],
    [
        82.705020353000066,
        49.956447827000034
    ],
    [
        82.704995848000067,
        49.955870066000045
    ],
    [
        82.705886659000043,
        49.955405644000052
    ],
    [
        82.705495616000064,
        49.955026540000063
    ],
    [
        82.705011304000038,
        49.954742777000035
    ],
    [
        82.704903799000078,
        49.954407295000067
    ],
    [
        82.705642864000083,
        49.953910432000043
    ],
    [
        82.701884659000029,
        49.952880162000042
    ],
    [
        82.700892291000059,
        49.952608139000063
    ],
    [
        82.700457325000059,
        49.952154711000048
    ],
    [
        82.699485763000041,
        49.952135462000058
    ],
    [
        82.699395325000069,
        49.952197656000067
    ],
    [
        82.698821103000057,
        49.952274383000031
    ],
    [
        82.698782273000063,
        49.952127755000049
    ],
    [
        82.698598764000053,
        49.951579138000056
    ],
    [
        82.698077646000058,
        49.950507866000066
    ],
    [
        82.696968513000058,
        49.950667052000028
    ],
    [
        82.697026540000081,
        49.948087036000061
    ],
    [
        82.697236500000031,
        49.947560637000038
    ],
    [
        82.695474903000047,
        49.946229784000082
    ],
    [
        82.69501914600005,
        49.945234667000079
    ],
    [
        82.694589435000069,
        49.944890245000067
    ],
    [
        82.690642362000062,
        49.943347424000081
    ],
    [
        82.684870735000061,
        49.941539876000036
    ],
    [
        82.677586082000062,
        49.936752826000031
    ],
    [
        82.691021461000048,
        49.934614474000057
    ],
    [
        82.691592159000038,
        49.913529883000081
    ],
    [
        82.69553524500003,
        49.911879492000025
    ],
    [
        82.708309069000052,
        49.906372506000082
    ],
    [
        82.71587394900007,
        49.90962492500006
    ],
    [
        82.716371323000033,
        49.909848914000065
    ],
    [
        82.71640830900003,
        49.909837756000059
    ],
    [
        82.716545382000049,
        49.909808437000038
    ],
    [
        82.716751100000067,
        49.909775357000058
    ],
    [
        82.716843292000078,
        49.909752456000035
    ],
    [
        82.716956781000079,
        49.909731442000066
    ],
    [
        82.717025198000044,
        49.909723189000033
    ],
    [
        82.717175133000069,
        49.909722811000051
    ],
    [
        82.717193284000075,
        49.909716427000035
    ],
    [
        82.717246350000039,
        49.909695455000076
    ],
    [
        82.717409618000033,
        49.909600154000032
    ],
    [
        82.71746997200006,
        49.90958837900007
    ],
    [
        82.717454197000052,
        49.909553862000053
    ],
    [
        82.717460747000075,
        49.909479677000036
    ],
    [
        82.717437973000074,
        49.909412805000045
    ],
    [
        82.717443285000058,
        49.909370332000037
    ],
    [
        82.71747104700006,
        49.909326852000049
    ],
    [
        82.717524274000084,
        49.909310462000064
    ],
    [
        82.717546500000083,
        49.909291387000053
    ]
]