import React, {useEffect, useRef, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import {ILineChartData} from "../../types/chart";
import annotationPlugin from 'chartjs-plugin-annotation';
import {Cloudy, Fog, Overcast, Rain, Snow, Snowy, Sun} from "../Icons";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

interface ILineChart {
    data: ILineChartData;
    chartWidth?: number;
    chartHeight?: number;
    isLegend: boolean;
    straightLabels?: boolean;
    criticalLevel?:number;
    hasPrediction?: boolean;
    hidePoints?:boolean;
    secondAxis?: boolean;
    weatherData?: {temperature: number, description: string}[]; //Отображать ли над графиком прогноз погоды
}

const LineChart: React.FC<ILineChart> = ({
                                             data,
                                             chartHeight,
                                             chartWidth,
                                             isLegend,
                                             straightLabels,
                                             criticalLevel,
                                             hasPrediction,
                                             hidePoints,
                                             secondAxis,
                                             weatherData
}) => {
    const chartRef = useRef(null);
    const [cellWidth, setCellWidth] = useState(0);
    const [rightScaleWidth, setRightScaleWidth] = useState(0);

    const options: any = {
        responsive: true,
        elements: hidePoints && {
            point: {
                radius: 0
            }
        },
        plugins: {
            legend: {
                display: isLegend,
                position: "bottom",
                align: "start",
                padding: 100,
                labels: {
                    boxWidth: 25,
                    boxHeight: 5,
                }
            },
            datalabels: {
                display: false
            },
            annotation: {
                annotations: {
                    line1: criticalLevel && {
                        type: 'line',
                        yMin: criticalLevel,
                        yMax: criticalLevel,
                        borderColor: 'red',
                        borderWidth: 1,
                        borderDash: [10, 5],
                    },
                    line2: hasPrediction && {
                        type: 'line',
                        scaleID: "x",
                        value: 6,
                        borderColor: 'black',
                        borderWidth: 1,
                    }
                }
              }
        },
        scales: {
            x: {
                ticks: {
                    maxRotation: straightLabels ? 0 : undefined,
                    minRotation: straightLabels ? 0 : undefined,
                }
            },
            y: secondAxis && {
                type: "linear",
                position: "left",
                ticks: {
                    callback: (value: number) => {
                        return `${value} см`
                    }
                }
            },
            waterFlow: secondAxis && {
                type: "linear",
                position: "right",
                grid: {
                    drawOnChartArea: false
                },
                ticks: {
                    callback: (value: number) => {
                        return `${value.toFixed(2)} м3/сек`
                    },
                    font: {
                        size: 10
                    }
                }
            }
        }
    };

    const getWeatherIcon = (weather: string) => {
        if (weather === "clear") {
            return <Sun />;
        } else if (weather === "cloudy") {
            return <Cloudy />;
        } else if (weather === "rain") {
            return <Rain />;
        } else if (weather === "snow") {
            return <Snow />;
        } else if (weather === "fog") {
            return <Fog />;
        } else if (weather === "overcast") {
            return <Overcast />;
        } else if (weather === "snowy") {
            return <Snowy />;
        }
    };

    useEffect(() => {
        if (weatherData?.length) {
            const chart: any = chartRef.current;
            if (chart) {
                const chartWidth = chart.chartArea.width;
                const numberOfLabels = chart.scales.x.ticks.length;
                const widthPerLabel = chartWidth / (numberOfLabels - 1);
                setCellWidth(widthPerLabel);
                const rightScale = chart.scales['waterFlow'];

                if (rightScale) {
                    const scaleWidth = rightScale.width;
                    setRightScaleWidth(scaleWidth);
                }
            }
        }
    }, []);

    return (
        <div className="line-chart-wrapper" style={weatherData?.length ? {paddingTop: "50px"} : {}}>
            <Line
                data={data}
                options={options}
                width={chartWidth}
                height={chartHeight}
                ref={chartRef}
            />

            <div className="weather-items" style={{right: rightScaleWidth}}>
                {weatherData?.length && weatherData.map((weather) =>
                    <div className="weather-item" style={{width: cellWidth}}>
                        {getWeatherIcon(weather.description)}
                        <div>{weather.temperature > 0 ? "+" : null} {weather.temperature}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LineChart;