import { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../../contexts/AppDataContext";
import Map from "../../Map/Map";
import DebtsFilter from "./DebtsFilter";
import DebtsSideInfo from "./DebtsSideInfo"
import { getDebtsDistricts } from "../../../services/hcs";
import dayjs from "dayjs";
import { DebtsDistrict } from "../../../types/debts";
import { useParams } from "react-router-dom";
import { getDistricts } from "../../../services/floods";
import {getDistrictData} from "../../../utils";
import {DebtsContext} from "../../../contexts/DebtsContext";

const DebtsDistrictPage = () => {
    const { dateRange, updateDistrictsData, updateDistricts } = useContext(AppDataContext);
    const { type, kse, krp } = useContext(DebtsContext);
    const [debtsDistricts, setDebtsDistricts] = useState<DebtsDistrict[]>([]);
    const params = useParams();
    const districtId = params.id;

    const loadDebtsDistricts = async () => {
        const response = await getDebtsDistricts(dayjs(dateRange.to).format("YYYY-MM-DD"), krp.selected, kse.selected, type.selected);

        if (!response.isError) {
            setDebtsDistricts(response.data);
            updateDistrictsData(getDistrictData(response.data));
        }
    }

    const loadDistrictsData = async (): Promise<void> => {
        const response = await getDistricts(dayjs(dateRange.to).format("YYYY-MM-DD"));

        if (!response.isError) {
            updateDistricts(response.data);
        } else {
            updateDistricts([]);
        }
    };

    useEffect(() => {
        loadDebtsDistricts();
        loadDistrictsData();
    }, []);

    return (
        <>  
            <Map districtId={Number(districtId)} debts={debtsDistricts} regionOnly/>
            <DebtsSideInfo district={debtsDistricts.filter((district) => district.id === Number(districtId))[0]}/>
            <DebtsFilter />
        </>
    )
}

export default DebtsDistrictPage;
