import React, {useEffect, useRef} from 'react';
import {divIcon, DivIconOptions, LatLngExpression} from "leaflet";
import ReactDOMServer from "react-dom/server";
import {ActiveHelicopter, InactiveHelicopter} from "../../Icons";
import {IFlight} from "../../../types/fires";
import {Marker, Popup} from "react-leaflet";
import {getDistrictCenter, getDistrictNameById} from "../../../utils";

interface IFireStationMarker {
    point: IFlight | "inactive";
}

const HelicopterMarker: React.FC<IFireStationMarker> = ({point}) => {
    const markerRef = useRef<any>(null);
    let coords;

    if (point !== "inactive") {
        coords = getDistrictCenter(point.district);
    } else {
        coords = [49.9499, 82.6165]
    }

    const createFireMarker = () => {
        if (point !== "inactive") {
            const options: DivIconOptions = {
                className: "marker-wrapper",
                html: ReactDOMServer.renderToString(
                    <ActiveHelicopter />
                )}
            return divIcon(options);
        } else {
            const options: DivIconOptions = {
                className: "marker-wrapper",
                html: ReactDOMServer.renderToString(
                    <InactiveHelicopter />
                )}
            return divIcon(options);
        }
    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    const getStatusName = (status: string) => {
        if (status === "stable") {
            return "Стабильно"
        } else if (status === "dangerous") {
            return "Опасно"
        } else {
            return "Нормально"
        }
    };

    return (
        <>
            {coords &&
                <Marker
                    position={coords as LatLngExpression}
                    icon={createFireMarker()}
                    ref={markerRef}
                >
                    <Popup>
                        {point !== "inactive" ? (
                            <>
                                <span style={{ fontWeight: 600, fontSize: "14px" }}>{getDistrictNameById(point.district)}</span> <br />
                                Радиус пожара: <span style={{ fontWeight: 600 }}>{point.raidus ? point.raidus : "-"}</span> <br />
                                Статус пожара: <span style={{ fontWeight: 600 }}>{getStatusName(point.status)}</span> <br />
                                Угроза населенным пунктам: <span style={{ fontWeight: 600 }}>{point.danger_populated_locality ? "Да" : "Нет"}</span> <br />
                                Комментарии: <span style={{ fontWeight: 600 }}>{point.comments}</span> <br />
                            </>
                        ) : (
                            <span style={{ fontWeight: 600, fontSize: "14px" }}>На базе</span>
                        )}
                    </Popup>
                </Marker>
            }
        </>
    );
};

export default HelicopterMarker;