import React from "react";
import Map from "../Map/Map";
import InternetSideInfo from "./InternetSideInfo";

const InternetDistrictPage: React.FC = () => {
    return (
        <>
            <Map />
            <InternetSideInfo />
        </>
    );
};

export default InternetDistrictPage;
