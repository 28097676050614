import {LatLngExpression} from "leaflet";

export const samarDistrict: LatLngExpression[] = [
    [
        82.8049421,
        49.1955732
    ],
    [
        82.7160617,
        49.104895
    ],
    [
        82.8149387,
        48.9229522
    ],
    [
        82.9193088,
        48.9639924
    ],
    [
        83.1143161,
        48.9369368
    ],
    [
        83.0703708,
        48.7742946
    ],
    [
        83.2351657,
        48.5620694
    ],
    [
        83.4233086,
        48.5407054
    ],
    [
        83.4433935,
        48.5776294
    ],
    [
        83.4781446,
        48.6130553
    ],
    [
        83.4749586,
        48.6223335
    ],
    [
        83.4515244,
        48.6814119
    ],
    [
        83.4354935,
        48.7087009
    ],
    [
        83.4348595,
        48.7181507
    ],
    [
        83.4426218,
        48.771562
    ],
    [
        83.4611839,
        48.8251389
    ],
    [
        83.483734,
        48.8641502
    ],
    [
        83.5297392,
        48.9184386
    ],
    [
        83.5477637,
        48.9332138
    ],
    [
        83.5764311,
        48.9469699
    ],
    [
        83.5932539,
        48.9522684
    ],
    [
        83.6116182,
        48.9522684
    ],
    [
        83.6299895,
        48.9522684
    ],
    [
        83.6631201,
        48.9628637
    ],
    [
        83.7480925,
        48.9983528
    ],
    [
        83.7685202,
        49.0109651
    ],
    [
        83.816757,
        49.0276264
    ],
    [
        83.8854216,
        49.0308904
    ],
    [
        83.9226721,
        49.0344919
    ],
    [
        83.9647291,
        49.0506953
    ],
    [
        84.000263,
        49.0704923
    ],
    [
        84.1425703,
        49.1006226
    ],
    [
        84.1892724,
        49.1172729
    ],
    [
        84.20557,
        49.1236577
    ],
    [
        84.2313193,
        49.1403935
    ],
    [
        84.2371557,
        49.1548784
    ],
    [
        84.240589,
        49.1780005
    ],
    [
        84.2414473,
        49.1895575
    ],
    [
        84.2354391,
        49.2004388
    ],
    [
        84.1820524,
        49.2437159
    ],
    [
        84.1415404,
        49.271837
    ],
    [
        84.1202543,
        49.2815804
    ],
    [
        84.0692709,
        49.3138206
    ],
    [
        84.0481561,
        49.3291501
    ],
    [
        84.0230064,
        49.3457107
    ],
    [
        83.9978631,
        49.366924
    ],
    [
        83.9609076,
        49.387579
    ],
    [
        83.9156835,
        49.4051508
    ],
    [
        83.892059,
        49.42568
    ],
    [
        83.8826092,
        49.4345696
    ],
    [
        83.8500411,
        49.4415924
    ],
    [
        83.8040077,
        49.4526207
    ],
    [
        83.7536539,
        49.4678217
    ],
    [
        83.6913679,
        49.4799171
    ],
    [
        83.6529921,
        49.4869738
    ],
    [
        83.6419629,
        49.4812861
    ],
    [
        83.6205052,
        49.4735343
    ],
    [
        83.6101627,
        49.4713592
    ],
    [
        83.6100338,
        49.466618
    ],
    [
        83.6123942,
        49.4636616
    ],
    [
        83.6159132,
        49.4632711
    ],
    [
        83.6171578,
        49.4569671
    ],
    [
        83.6198185,
        49.4519456
    ],
    [
        83.6247538,
        49.4486534
    ],
    [
        83.6162566,
        49.4444681
    ],
    [
        83.6142395,
        49.4394733
    ],
    [
        83.5987042,
        49.4336127
    ],
    [
        83.6078452,
        49.4267466
    ],
    [
        83.6152695,
        49.4294541
    ],
    [
        83.6201619,
        49.4280306
    ],
    [
        83.6187886,
        49.4232853
    ],
    [
        83.6126517,
        49.4162502
    ],
    [
        83.6153553,
        49.4158035
    ],
    [
        83.6199044,
        49.420382
    ],
    [
        83.6259984,
        49.4230061
    ],
    [
        83.6346243,
        49.4204099
    ],
    [
        83.6444521,
        49.4191258
    ],
    [
        83.6435936,
        49.4087394
    ],
    [
        83.6375426,
        49.3939379
    ],
    [
        83.6295603,
        49.3964237
    ],
    [
        83.6090039,
        49.3875411
    ],
    [
        83.5776758,
        49.3882954
    ],
    [
        83.5988759,
        49.3688768
    ],
    [
        83.592782,
        49.3561039
    ],
    [
        83.5447597,
        49.3438029
    ],
    [
        83.522315,
        49.3147438
    ],
    [
        83.4956216,
        49.3041954
    ],
    [
        83.4863518,
        49.2692887
    ],
    [
        83.4682416,
        49.251644
    ],
    [
        83.4446811,
        49.2498792
    ],
    [
        83.4274292,
        49.2646401
    ],
    [
        83.4261845,
        49.2672445
    ],
    [
        83.4236525,
        49.2646401
    ],
    [
        83.3715963,
        49.2846877
    ],
    [
        83.3536577,
        49.2618115
    ],
    [
        83.3163641,
        49.2792286
    ],
    [
        83.3003568,
        49.2820421
    ],
    [
        83.2843493,
        49.2848556
    ],
    [
        83.2619046,
        49.2926093
    ],
    [
        83.2183455,
        49.3092321
    ],
    [
        83.1920814,
        49.3056505
    ],
    [
        83.156805,
        49.2950723
    ],
    [
        83.1817388,
        49.2667124
    ],
    [
        83.0948782,
        49.2387006
    ],
    [
        83.0393457,
        49.2492629
    ],
    [
        83.029561,
        49.2279958
    ],
    [
        83.0608463,
        49.1746618
    ],
    [
        83.0085325,
        49.1692186
    ],
    [
        82.9685782,
        49.1700043
    ],
    [
        82.9677199,
        49.1763452
    ],
    [
        82.9701661,
        49.180946
    ],
    [
        82.9512833,
        49.176261
    ],
    [
        82.9415417,
        49.17281
    ],
    [
        82.9406832,
        49.173287
    ],
    [
        82.9396962,
        49.1740165
    ],
    [
        82.9381191,
        49.1741708
    ],
    [
        82.9375612,
        49.1746758
    ],
    [
        82.9376577,
        49.1754053
    ],
    [
        82.9371856,
        49.1758963
    ],
    [
        82.9360484,
        49.1760856
    ],
    [
        82.9349755,
        49.1761979
    ],
    [
        82.9346751,
        49.1763381
    ],
    [
        82.9342459,
        49.1777058
    ],
    [
        82.9323362,
        49.1781968
    ],
    [
        82.9306625,
        49.1789963
    ],
    [
        82.9285597,
        49.1786035
    ],
    [
        82.9261779,
        49.1794872
    ],
    [
        82.9230021,
        49.1800483
    ],
    [
        82.9193543,
        49.1810442
    ],
    [
        82.9177879,
        49.1820681
    ],
    [
        82.9146122,
        49.1826712
    ],
    [
        82.9134535,
        49.1825309
    ],
    [
        82.9117583,
        49.1830218
    ],
    [
        82.9105996,
        49.1826011
    ],
    [
        82.9096984,
        49.1828255
    ],
    [
        82.9086469,
        49.1827553
    ],
    [
        82.9072307,
        49.1832462
    ],
    [
        82.9055785,
        49.1833585
    ],
    [
        82.9043769,
        49.1837371
    ],
    [
        82.9019736,
        49.1837652
    ],
    [
        82.8994201,
        49.1822224
    ],
    [
        82.8968238,
        49.1818717
    ],
    [
        82.8946004,
        49.1797558
    ],
    [
        82.8929185,
        49.1802306
    ],
    [
        82.8913735,
        49.1812125
    ],
    [
        82.8903234,
        49.1816894
    ],
    [
        82.8903865,
        49.1823346
    ],
    [
        82.8913735,
        49.1833023
    ],
    [
        82.8916789,
        49.1837674
    ],
    [
        82.891631,
        49.1841299
    ],
    [
        82.8910731,
        49.1844524
    ],
    [
        82.8896281,
        49.1844161
    ],
    [
        82.8885626,
        49.1842701
    ],
    [
        82.8872536,
        49.1838213
    ],
    [
        82.8864597,
        49.1839615
    ],
    [
        82.8816196,
        49.1889349
    ],
    [
        82.8813791,
        49.1894191
    ],
    [
        82.8814932,
        49.1900334
    ],
    [
        82.8822262,
        49.191265
    ],
    [
        82.882334,
        49.19242
    ],
    [
        82.8826402,
        49.1929791
    ],
    [
        82.8835579,
        49.1938421
    ],
    [
        82.8850107,
        49.1947687
    ],
    [
        82.8853223,
        49.1951122
    ],
    [
        82.8857014,
        49.1958683
    ],
    [
        82.8869103,
        49.1964707
    ],
    [
        82.8870015,
        49.1970256
    ],
    [
        82.8856229,
        49.1988964
    ],
    [
        82.885289,
        49.1989147
    ],
    [
        82.8831123,
        49.1981673
    ],
    [
        82.8794001,
        49.1978868
    ],
    [
        82.8783487,
        49.1974522
    ],
    [
        82.8766321,
        49.1976064
    ],
    [
        82.8741215,
        49.1955312
    ],
    [
        82.8727268,
        49.195419
    ],
    [
        82.8716968,
        49.1958397
    ],
    [
        82.8719329,
        49.1965408
    ],
    [
        82.8727268,
        49.1974241
    ],
    [
        82.8725766,
        49.1985458
    ],
    [
        82.871611,
        49.1994011
    ],
    [
        82.8674268,
        49.2010276
    ],
    [
        82.866547,
        49.2021912
    ],
    [
        82.8662251,
        49.2036073
    ],
    [
        82.8642725,
        49.2029063
    ],
    [
        82.8618478,
        49.2032007
    ],
    [
        82.8601097,
        49.204813
    ],
    [
        82.8589295,
        49.2054298
    ],
    [
        82.852106,
        49.2054579
    ],
    [
        82.8508185,
        49.2049251
    ],
    [
        82.8495311,
        49.2042382
    ],
    [
        82.8465055,
        49.2043223
    ],
    [
        82.8454541,
        49.2050793
    ],
    [
        82.8458189,
        49.2055981
    ],
    [
        82.8466986,
        49.2060887
    ],
    [
        82.8477501,
        49.2062009
    ],
    [
        82.8476642,
        49.2071542
    ],
    [
        82.8464841,
        49.2081916
    ],
    [
        82.8446816,
        49.2091728
    ],
    [
        82.8423213,
        49.2092289
    ],
    [
        82.8414844,
        49.2098738
    ],
    [
        82.8408407,
        49.2107289
    ],
    [
        82.8404115,
        49.2107569
    ],
    [
        82.8356265,
        49.2082897
    ],
    [
        82.8349828,
        49.2067056
    ],
    [
        82.8363775,
        49.2037335
    ],
    [
        82.837622,
        49.2031446
    ],
    [
        82.8377079,
        49.201322
    ],
    [
        82.8357338,
        49.200663
    ],
    [
        82.8342961,
        49.2007752
    ],
    [
        82.8337812,
        49.200621
    ],
    [
        82.8342103,
        49.1992609
    ],
    [
        82.8341459,
        49.1985038
    ],
    [
        82.8330516,
        49.1976625
    ],
    [
        82.8322791,
        49.1974802
    ],
    [
        82.8314852,
        49.1980271
    ],
    [
        82.8300904,
        49.1989384
    ],
    [
        82.8313993,
        49.1998638
    ],
    [
        82.8309702,
        49.2014762
    ],
    [
        82.8300046,
        49.202037
    ],
    [
        82.8279232,
        49.2015884
    ],
    [
        82.8250478,
        49.200621
    ],
    [
        82.8247045,
        49.2009014
    ],
    [
        82.8246831,
        49.2018828
    ],
    [
        82.8241895,
        49.2031446
    ],
    [
        82.824726,
        49.2041681
    ],
    [
        82.8243827,
        49.2044485
    ],
    [
        82.8222798,
        49.2046447
    ],
    [
        82.8209065,
        49.2042241
    ],
    [
        82.8208421,
        49.2038316
    ],
    [
        82.8215502,
        49.203383
    ],
    [
        82.8229879,
        49.2019389
    ],
    [
        82.8208207,
        49.2018968
    ],
    [
        82.8189539,
        49.2014061
    ],
    [
        82.8182458,
        49.2005929
    ],
    [
        82.8194474,
        49.1995974
    ],
    [
        82.8175377,
        49.1984477
    ],
    [
        82.8157781,
        49.197999
    ],
    [
        82.814555,
        49.1992469
    ],
    [
        82.8132676,
        49.1993871
    ],
    [
        82.812302,
        49.1987562
    ],
    [
        82.8098987,
        49.1981953
    ],
    [
        82.8069376,
        49.1967651
    ],
    [
        82.805972,
        49.1958397
    ],
    [
        82.8049421,
        49.1955732
    ]
]