import { useParams } from "react-router-dom";
import { getFloodsFile } from "../../services/floods";
import Button from "../UI/Button";

const FloodsExportButton = () => {
    const params = useParams();

    const downloadFile = async () => {
        const response = await getFloodsFile(Object.hasOwn(params, "id") ? params.id : "");
        
        if (!response.isError) {
            const fileUrl = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute("download", "forecast.xlsx");
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } else {
            console.error('Произошла ошибка при скачивании файла:', response.error);
        }
    };
    
    return (
        <Button text="ЭКСПОРТ" onClick={downloadFile} />
    )
}

export default FloodsExportButton