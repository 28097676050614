import {LatLngExpression} from "leaflet";

export const markakolLake: LatLngExpression[] = [
    [
        85.91130276000973,
        48.72892566980963
    ],
    [
        85.91160047596281,
        48.72607054067893
    ],
    [
        85.90998990657772,
        48.72441642248367
    ],
    [
        85.90862136983634,
        48.722003440899556
    ],
    [
        85.90772752618528,
        48.71965188351723
    ],
    [
        85.90681161461316,
        48.718822631776874
    ],
    [
        85.90622372200238,
        48.71725316188082
    ],
    [
        85.90485771651481,
        48.71498554708447
    ],
    [
        85.90281966902052,
        48.714129313324264
    ],
    [
        85.8991462815548,
        48.71081510993935
    ],
    [
        85.89516334041599,
        48.709302629610704
    ],
    [
        85.89355392792248,
        48.7081705804722
    ],
    [
        85.89195846766859,
        48.704768645982256
    ],
    [
        85.89115836768457,
        48.70287868452897
    ],
    [
        85.88428519343921,
        48.698497922566304
    ],
    [
        85.87134758013849,
        48.69078872890148
    ],
    [
        85.86933129029205,
        48.68859138583343
    ],
    [
        85.86647480944833,
        48.68745819224543
    ],
    [
        85.86348436204588,
        48.68376007156817
    ],
    [
        85.84873139379062,
        48.68299908473949
    ],
    [
        85.8394750474693,
        48.676436631497836
    ],
    [
        85.83248154610459,
        48.67559511201782
    ],
    [
        85.82985601554265,
        48.674012038335746
    ],
    [
        85.8240274203734,
        48.66925616591914
    ],
    [
        85.81690790885449,
        48.66668832168466
    ],
    [
        85.8140843815853,
        48.665458037859935
    ],
    [
        85.80901276669721,
        48.66522931004843
    ],
    [
        85.80152504726027,
        48.66401205046711
    ],
    [
        85.79782333777206,
        48.66218227023424
    ],
    [
        85.79403855555915,
        48.65944667974338
    ],
    [
        85.78781254661686,
        48.65845613745236
    ],
    [
        85.78365541007469,
        48.65875845015117
    ],
    [
        85.77740638535573,
        48.65687264022995
    ],
    [
        85.77351314504205,
        48.655208938006695
    ],
    [
        85.76938968845917,
        48.65634253208188
    ],
    [
        85.76629524379365,
        48.656342013819994
    ],
    [
        85.75951879217496,
        48.6546746930006
    ],
    [
        85.75600902838465,
        48.65316887936223
    ],
    [
        85.74981199961934,
        48.653846669890214
    ],
    [
        85.74876710173055,
        48.65497939252833
    ],
    [
        85.74349341104397,
        48.655054463740186
    ],
    [
        85.74262000725759,
        48.654468748837616
    ],
    [
        85.738543781401,
        48.65517446869109
    ],
    [
        85.73202957672038,
        48.66017514707494
    ],
    [
        85.72736368985267,
        48.661201422832676
    ],
    [
        85.72154092605899,
        48.661137320791056
    ],
    [
        85.72067327405341,
        48.660624317228894
    ],
    [
        85.71678034857081,
        48.66126559972244
    ],
    [
        85.71417081987659,
        48.66023960442428
    ],
    [
        85.70278094079026,
        48.66173958261848
    ],
    [
        85.6956907644095,
        48.664112149807295
    ],
    [
        85.69191279196883,
        48.66392084103799
    ],
    [
        85.69065589659425,
        48.66340859555805
    ],
    [
        85.6835545798557,
        48.664304715443876
    ],
    [
        85.67959330044982,
        48.66417819265618
    ],
    [
        85.67871835607707,
        48.66475414470273
    ],
    [
        85.67580549789318,
        48.66488256482634
    ],
    [
        85.66783925218897,
        48.6687301727726
    ],
    [
        85.65608976226554,
        48.672193475854755
    ],
    [
        85.64929162033252,
        48.67424566410918
    ],
    [
        85.64227051916282,
        48.67448576920873
    ],
    [
        85.64129996155287,
        48.67390875101614
    ],
    [
        85.63858049932963,
        48.6737804129867
    ],
    [
        85.63785789449702,
        48.67341777893162
    ],
    [
        85.63714185553926,
        48.67373289384315
    ],
    [
        85.636247777851,
        48.673181519435275
    ],
    [
        85.63380189316985,
        48.67326027005845
    ],
    [
        85.63111715034074,
        48.67247237504853
    ],
    [
        85.62270554880314,
        48.67274808091358
    ],
    [
        85.61939894253226,
        48.67398126226624
    ],
    [
        85.61892148026936,
        48.674768858802395
    ],
    [
        85.61438804713197,
        48.675793200269254
    ],
    [
        85.61116649438952,
        48.67748692767742
    ],
    [
        85.60818421696735,
        48.6774869515755
    ],
    [
        85.60508071390706,
        48.678511065316286
    ],
    [
        85.60287353449877,
        48.67847167227623
    ],
    [
        85.60197960531724,
        48.677998990788694
    ],
    [
        85.59499812213602,
        48.6784127482714
    ],
    [
        85.59070379962344,
        48.6799874755182
    ],
    [
        85.58580974697816,
        48.683649715630224
    ],
    [
        85.58247063072088,
        48.684555350611475
    ],
    [
        85.57962534463854,
        48.684712837273196
    ],
    [
        85.57479386884336,
        48.68597317020715
    ],
    [
        85.57139286496658,
        48.687233270856325
    ],
    [
        85.56554772319743,
        48.68813903353407
    ],
    [
        85.56012219717837,
        48.6887598042008
    ],
    [
        85.55081604539618,
        48.69092537216264
    ],
    [
        85.54694413231061,
        48.691948660002936
    ],
    [
        85.54236927192301,
        48.69293351209819
    ],
    [
        85.53992367909177,
        48.69352427080122
    ],
    [
        85.53867058301711,
        48.694193520148474
    ],
    [
        85.53717869405557,
        48.694862796274435
    ],
    [
        85.53647266498763,
        48.69492821310567
    ],
    [
        85.53463787237013,
        48.69571377983206
    ],
    [
        85.5326543192179,
        48.696172021734924
    ],
    [
        85.53116664868821,
        48.69718668305438
    ],
    [
        85.53022445525005,
        48.69800494345529
    ],
    [
        85.52878638435254,
        48.69820132333717
    ],
    [
        85.52774501177032,
        48.697513988665634
    ],
    [
        85.52759624103192,
        48.697121221668226
    ],
    [
        85.52640609826034,
        48.696335678104845
    ],
    [
        85.52561267311637,
        48.69519007176652
    ],
    [
        85.52600935799137,
        48.69430629666141
    ],
    [
        85.52551348934361,
        48.69352071204244
    ],
    [
        85.52600939559932,
        48.69240777878656
    ],
    [
        85.52700136758074,
        48.69070565678595
    ],
    [
        85.5248275702175,
        48.68718946854287
    ],
    [
        85.52453000724745,
        48.68571615778197
    ],
    [
        85.52562088430449,
        48.683162242757874
    ],
    [
        85.52537292569497,
        48.681736803484256
    ],
    [
        85.52552161636015,
        48.68104915950511
    ],
    [
        85.52621594866292,
        48.68049250599833
    ],
    [
        85.52656314650136,
        48.679837620954146
    ],
    [
        85.5272120991209,
        48.679480298151304
    ],
    [
        85.52765854056167,
        48.67833421704407
    ],
    [
        85.52711299491699,
        48.676958867267615
    ],
    [
        85.52592242863693,
        48.67614005331308
    ],
    [
        85.52408743872007,
        48.67591082589067
    ],
    [
        85.52335042732307,
        48.67615172550725
    ],
    [
        85.5225593525081,
        48.67605550156944
    ],
    [
        85.52158091786902,
        48.67587679948301
    ],
    [
        85.52074820753745,
        48.67578057502007
    ],
    [
        85.52033185237167,
        48.67557437912345
    ],
    [
        85.52018612806307,
        48.67529944994848
    ],
    [
        85.51989467944696,
        48.675161984798365
    ],
    [
        85.5196865018641,
        48.67462586712895
    ],
    [
        85.51964488040011,
        48.674268444342374
    ],
    [
        85.51929097850865,
        48.673292413680485
    ],
    [
        85.5180002774946,
        48.672605056797465
    ],
    [
        85.51702184285563,
        48.672343858723195
    ],
    [
        85.51600177269881,
        48.67249507882596
    ],
    [
        85.51521069788379,
        48.67246758429559
    ],
    [
        85.51412817445271,
        48.67264629847489
    ],
    [
        85.51348282394633,
        48.67307246049816
    ],
    [
        85.51267611776132,
        48.67320456111298
    ],
    [
        85.51190586070396,
        48.67303959602859
    ],
    [
        85.51182258967089,
        48.67255844478075
    ],
    [
        85.51153114105477,
        48.67217352047416
    ],
    [
        85.51115642140553,
        48.6721597731231
    ],
    [
        85.51142705226397,
        48.672668422613896
    ],
    [
        85.51153114105477,
        48.67305334313957
    ],
    [
        85.51203076725369,
        48.673383272678194
    ],
    [
        85.51259284672818,
        48.67336952565719
    ],
    [
        85.51311329068483,
        48.673383272678194
    ],
    [
        85.51384191222559,
        48.673163319892495
    ],
    [
        85.51413336084158,
        48.672874630404095
    ],
    [
        85.51479952910688,
        48.672764653021034
    ],
    [
        85.51569469271277,
        48.67269591703473
    ],
    [
        85.51650658528666,
        48.67281964174259
    ],
    [
        85.51711030027644,
        48.67279214738929
    ],
    [
        85.51767237975088,
        48.67286088324434
    ],
    [
        85.51852590784017,
        48.6731220786383
    ],
    [
        85.51902553403909,
        48.67343826072454
    ],
    [
        85.51935861817174,
        48.67448302220305
    ],
    [
        85.5193794359306,
        48.67492291529143
    ],
    [
        85.52039950608622,
        48.6758989143662
    ],
    [
        85.52056604815255,
        48.67607761637419
    ],
    [
        85.52106567435158,
        48.67605012379886
    ],
    [
        85.52162775382595,
        48.67614634774691
    ],
    [
        85.5228768193233,
        48.67646251085375
    ],
    [
        85.52408424930411,
        48.67631130265892
    ],
    [
        85.52459595636037,
        48.67616547059285
    ],
    [
        85.52490822273529,
        48.676275440552445
    ],
    [
        85.52559520875826,
        48.676330425442245
    ],
    [
        85.52659446115621,
        48.67682528674962
    ],
    [
        85.52690672753118,
        48.677278905344394
    ],
    [
        85.5269691808054,
        48.67784248639336
    ],
    [
        85.52717735838831,
        48.67835107851292
    ],
    [
        85.5267818209814,
        48.6789283930747
    ],
    [
        85.52684427425567,
        48.67951944636667
    ],
    [
        85.52617810599037,
        48.679945550251716
    ],
    [
        85.52557233744426,
        48.68066773174098
    ],
    [
        85.52511434676188,
        48.68114880554293
    ],
    [
        85.52482289814583,
        48.68176732225123
    ],
    [
        85.52507271124534,
        48.68206970543321
    ],
    [
        85.52457295557383,
        48.684365007796345
    ],
    [
        85.52399005834178,
        48.68528584888264
    ],
    [
        85.52384432942824,
        48.68642969792526
    ],
    [
        85.5239067827037,
        48.68733675805038
    ],
    [
        85.52503094165132,
        48.68893094534499
    ],
    [
        85.52495003403709,
        48.689124659987954
    ],
    [
        85.52515098184267,
        48.68924783905197
    ],
    [
        85.525438050135,
        48.68945629524265
    ],
    [
        85.52572511842743,
        48.690024807740144
    ],
    [
        85.52591171281705,
        48.69036591215769
    ],
    [
        85.52571076501323,
        48.69115233853705
    ],
    [
        85.52449072476924,
        48.69279147693098
    ],
    [
        85.52446856553894,
        48.69323656415426
    ],
    [
        85.52415279041651,
        48.69416506153368
    ],
    [
        85.52462645309856,
        48.695188283710195
    ],
    [
        85.5249852884653,
        48.695605146042254
    ],
    [
        85.52483843957413,
        48.696291915922444
    ],
    [
        85.52476667250147,
        48.696566660460206
    ],
    [
        85.52466619859871,
        48.69674666468964
    ],
    [
        85.52478102591567,
        48.69704035441836
    ],
    [
        85.52522598176927,
        48.697154040305065
    ],
    [
        85.52565658420713,
        48.697135092675126
    ],
    [
        85.52617330713343,
        48.697286673515094
    ],
    [
        85.52726416664456,
        48.69805404451347
    ],
    [
        85.52774562743213,
        48.698269811236685
    ],
    [
        85.52898002109038,
        48.698610859796986
    ],
    [
        85.52946803718663,
        48.699008746863626
    ],
    [
        85.52939627011398,
        48.69948241784226
    ],
    [
        85.52918096889425,
        48.70001292404666
    ],
    [
        85.52935320986961,
        48.700259228598185
    ],
    [
        85.52896566767612,
        48.70144336826945
    ],
    [
        85.52903742652518,
        48.70194144145597
    ],
    [
        85.52882212530551,
        48.702557176982424
    ],
    [
        85.52873600165884,
        48.70452132272737
    ],
    [
        85.52816799369901,
        48.707671936883685
    ],
    [
        85.52836894150289,
        48.70822130023538
    ],
    [
        85.52823108612313,
        48.70911735981579
    ],
    [
        85.52805884514765,
        48.712545948287556
    ],
    [
        85.52792812068458,
        48.715014936341646
    ],
    [
        85.52814342190442,
        48.71557368987206
    ],
    [
        85.52808600824585,
        48.716492305070034
    ],
    [
        85.52828367141757,
        48.717245632962204
    ],
    [
        85.52868556702697,
        48.718429372970576
    ],
    [
        85.52920522218454,
        48.71996164092681
    ],
    [
        85.5296501780366,
        48.720472992417626
    ],
    [
        85.52970759169506,
        48.721003277361746
    ],
    [
        85.53020539990888,
        48.72218759339074
    ],
    [
        85.53020539990888,
        48.72241485130422
    ],
    [
        85.53069341600514,
        48.72307768102007
    ],
    [
        85.53098048429757,
        48.72399615918707
    ],
    [
        85.53131061283426,
        48.72435597080366
    ],
    [
        85.53177890239323,
        48.72604805351372
    ],
    [
        85.53212338434412,
        48.72685285888994
    ],
    [
        85.53212554300228,
        48.72753959251065
    ],
    [
        85.53239825788052,
        48.7285905399043
    ],
    [
        85.53245567153903,
        48.729622529935284
    ],
    [
        85.53269967958715,
        48.72989709252286
    ],
    [
        85.53272838641715,
        48.73115627415265
    ],
    [
        85.53289679318686,
        48.73678404952145
    ],
    [
        85.53435822423495,
        48.7446389021832
    ],
    [
        85.53666193741822,
        48.74722893862895
    ],
    [
        85.54713096299292,
        48.7519969488682
    ],
    [
        85.54947720296178,
        48.75335070133997
    ],
    [
        85.55691014388907,
        48.75458195588999
    ],
    [
        85.56111109427445,
        48.75655967443282
    ],
    [
        85.56665738558945,
        48.77031097051429
    ],
    [
        85.57064306238686,
        48.773912169060736
    ],
    [
        85.58378493399118,
        48.78198880175921
    ],
    [
        85.5870346208215,
        48.78237832641824
    ],
    [
        85.58909835102355,
        48.784031199171324
    ],
    [
        85.60031671679201,
        48.791325984255536
    ],
    [
        85.60797986012665,
        48.7962492078687
    ],
    [
        85.6132937306242,
        48.79722254991876
    ],
    [
        85.62595710885091,
        48.79906516150899
    ],
    [
        85.63430803826617,
        48.800734020714856
    ],
    [
        85.64533795221041,
        48.80131432226514
    ],
    [
        85.6497491213841,
        48.8025728532881
    ],
    [
        85.65901060445105,
        48.802186381991646
    ],
    [
        85.66753875901469,
        48.801799535898255
    ],
    [
        85.67738710344702,
        48.80208839202729
    ],
    [
        85.68400457720423,
        48.80315432592229
    ],
    [
        85.691061857259,
        48.80964202318745
    ],
    [
        85.69605264927463,
        48.81447828943814
    ],
    [
        85.70162617247479,
        48.816794455354
    ],
    [
        85.70614804276016,
        48.81813116181371
    ],
    [
        85.71187799307557,
        48.82235132032153
    ],
    [
        85.71556778786686,
        48.8237087966441
    ],
    [
        85.72499248759226,
        48.825939383912214
    ],
    [
        85.72927867115061,
        48.82603821962334
    ],
    [
        85.737077585456,
        48.824678704572904
    ],
    [
        85.74523089870883,
        48.82108631405421
    ],
    [
        85.74812311923148,
        48.82283556557678
    ],
    [
        85.75241888802219,
        48.822738282963456
    ],
    [
        85.75877666814125,
        48.824000204078004
    ],
    [
        85.7615839602517,
        48.82380608827842
    ],
    [
        85.7626158820612,
        48.82477702659136
    ],
    [
        85.7683777549077,
        48.82516572431845
    ],
    [
        85.77149554832317,
        48.82554756822134
    ],
    [
        85.7748771924405,
        48.82545080523724
    ],
    [
        85.78061168122821,
        48.82545089311151
    ],
    [
        85.78502254335848,
        48.82545085825771
    ],
    [
        85.78795769342196,
        48.824869568391904
    ],
    [
        85.79635080872572,
        48.82128762231261
    ],
    [
        85.80158990468192,
        48.822548458420044
    ],
    [
        85.80409862627818,
        48.82303145210551
    ],
    [
        85.80824705945923,
        48.821579083347274
    ],
    [
        85.80928451608014,
        48.820707309513125
    ],
    [
        85.81487047611546,
        48.81838392764848
    ],
    [
        85.81473064858551,
        48.81722104559961
    ],
    [
        85.82101878880013,
        48.81229064271963
    ],
    [
        85.82117530626437,
        48.810642933336055
    ],
    [
        85.82439226007585,
        48.80890536505541
    ],
    [
        85.82659804761909,
        48.80832425449847
    ],
    [
        85.82828699149047,
        48.809407779878825
    ],
    [
        85.83149997401767,
        48.8083487382107
    ],
    [
        85.83887090615565,
        48.80408363437883
    ],
    [
        85.84490249018307,
        48.80185478567137
    ],
    [
        85.846642585545,
        48.80224946855395
    ],
    [
        85.85535206533996,
        48.79836383369778
    ],
    [
        85.85951281278767,
        48.79847784435404
    ],
    [
        85.86079184709257,
        48.799560018213384
    ],
    [
        85.86042692660527,
        48.800582168663624
    ],
    [
        85.85923992676908,
        48.80238512195635
    ],
    [
        85.85914935083633,
        48.81038296082235
    ],
    [
        85.85914914240453,
        48.81230538852648
    ],
    [
        85.8601541182914,
        48.81315107968197
    ],
    [
        85.85869300301368,
        48.81585468486347
    ],
    [
        85.85875787647939,
        48.818617047456655
    ],
    [
        85.86012537171109,
        48.82221863724308
    ],
    [
        85.86559753128665,
        48.82708437436068
    ],
    [
        85.86933783433153,
        48.82828585511825
    ],
    [
        85.87098049609085,
        48.828286478110556
    ],
    [
        85.87417503244507,
        48.82876806075703
    ],
    [
        85.87809788315639,
        48.82888793686698
    ],
    [
        85.88145842737117,
        48.82828068242728
    ],
    [
        85.8869250889266,
        48.82527900883778
    ],
    [
        85.89199383261536,
        48.82323431910666
    ],
    [
        85.89484118453589,
        48.81945755717703
    ],
    [
        85.89404776328155,
        48.81909712651807
    ],
    [
        85.8948643247191,
        48.81747620810876
    ],
    [
        85.89594479849478,
        48.81777714957249
    ],
    [
        85.8992495474946,
        48.81619263497521
    ],
    [
        85.90017097650298,
        48.81511415468705
    ],
    [
        85.90252770491372,
        48.81457180844896
    ],
    [
        85.90561651260703,
        48.81319135244556
    ],
    [
        85.90873024732468,
        48.81067070085575
    ],
    [
        85.91143524230705,
        48.80989356852052
    ],
    [
        85.91307467730581,
        48.80881423068257
    ],
    [
        85.91516903038189,
        48.808634307094906
    ],
    [
        85.91714493139085,
        48.80616237680826
    ],
    [
        85.9173322260578,
        48.80483917972231
    ],
    [
        85.91870157258768,
        48.803636998525974
    ],
    [
        85.91997394557706,
        48.80327782159213
    ],
    [
        85.9255417541305,
        48.7976278085215
    ],
    [
        85.9272756612724,
        48.797435941637616
    ],
    [
        85.92919295894438,
        48.79851763660176
    ],
    [
        85.93138287600061,
        48.80014052923485
    ],
    [
        85.93156558462624,
        48.80182345035436
    ],
    [
        85.93293392930121,
        48.802484590810025
    ],
    [
        85.93630926292036,
        48.80212398795962
    ],
    [
        85.93941111565982,
        48.802905286351574
    ],
    [
        85.94153729993917,
        48.80524910144595
    ],
    [
        85.94327067611687,
        48.80518906981166
    ],
    [
        85.94427424816774,
        48.806090582933194
    ],
    [
        85.94573403970185,
        48.80627095289782
    ],
    [
        85.9489275418224,
        48.806511526457456
    ],
    [
        85.95367270113388,
        48.80651179209099
    ],
    [
        85.95595423992631,
        48.80789418368647
    ],
    [
        85.95458537378721,
        48.80873528406681
    ],
    [
        85.95339914088407,
        48.80915567202831
    ],
    [
        85.95339917482892,
        48.810357454195554
    ],
    [
        85.95604545917382,
        48.81125905697013
    ],
    [
        85.95996937789437,
        48.811739891224306
    ],
    [
        85.9612469953966,
        48.81216065551456
    ],
    [
        85.96042563086888,
        48.81288162700386
    ],
    [
        85.96133825960675,
        48.813362539516476
    ],
    [
        85.95933051558035,
        48.81408323265299
    ],
    [
        85.96015232244821,
        48.81576728711798
    ],
    [
        85.96307299568986,
        48.816909596715476
    ],
    [
        85.96562844007514,
        48.817390359195855
    ],
    [
        85.96599251985714,
        48.818530637433014
    ],
    [
        85.96468256795447,
        48.819110643120894
    ],
    [
        85.9638627018885,
        48.82091186221385
    ],
    [
        85.96495892126569,
        48.82157095323694
    ],
    [
        85.97161731362667,
        48.821510732378215
    ],
    [
        85.97507724781701,
        48.822760177417024
    ],
    [
        85.98080895734586,
        48.82293486222824
    ],
    [
        85.98848086889257,
        48.821386755413755
    ],
    [
        85.99048847302777,
        48.82036706534453
    ],
    [
        85.99497367314854,
        48.82193318492028
    ],
    [
        85.9989031039986,
        48.82217516426468
    ],
    [
        86.00127761765071,
        48.82073353839422
    ],
    [
        86.00164287685152,
        48.81983224759077
    ],
    [
        86.00401559698969,
        48.81989232313532
    ],
    [
        86.00693564270347,
        48.82259619238161
    ],
    [
        86.00547388990822,
        48.823436978879585
    ],
    [
        86.00602067786411,
        48.82427790880507
    ],
    [
        86.0086928770425,
        48.82371995501467
    ],
    [
        86.01106821403545,
        48.821318063689716
    ],
    [
        86.01480568119541,
        48.81861327303392
    ],
    [
        86.018092829951,
        48.8142874996997
    ],
    [
        86.02046069859279,
        48.811463563522096
    ],
    [
        86.0220131521769,
        48.808699451601
    ],
    [
        86.02338697071633,
        48.80503253777158
    ],
    [
        86.02530243222378,
        48.80389081983947
    ],
    [
        86.02520992049278,
        48.80226842968793
    ],
    [
        86.02718848192148,
        48.7998294692635
    ],
    [
        86.02721623711255,
        48.797622971804174
    ],
    [
        86.02586130785733,
        48.795280322472905
    ],
    [
        86.02595735248497,
        48.7933583880189
    ],
    [
        86.02380992296014,
        48.79171807113863
    ],
    [
        86.0238902845935,
        48.78982767441215
    ],
    [
        86.02317293661054,
        48.78868601226435
    ],
    [
        86.0227176334813,
        48.78736339296739
    ],
    [
        86.02143969728957,
        48.786762214188656
    ],
    [
        86.01879460239343,
        48.78676214294373
    ],
    [
        86.01751580071465,
        48.78483837296804
    ],
    [
        86.01694096158866,
        48.78256029724702
    ],
    [
        86.01584759986747,
        48.7820797309688
    ],
    [
        86.01445527029705,
        48.78094718365341
    ],
    [
        86.0097288978879,
        48.779923421969926
    ],
    [
        86.00601002944734,
        48.777750327450576
    ],
    [
        86.00510434381101,
        48.7792480886242
    ],
    [
        86.00337193528486,
        48.77900671483437
    ],
    [
        86.0011807951094,
        48.77750520736785
    ],
    [
        85.99643690229243,
        48.77498017533213
    ],
    [
        85.98843327980711,
        48.77073499061578
    ],
    [
        85.98323020025032,
        48.76707265799206
    ],
    [
        85.97712017676918,
        48.758553564798916
    ],
    [
        85.9747609515855,
        48.75860062838339
    ],
    [
        85.97475918992808,
        48.75794281227073
    ],
    [
        85.97285187553257,
        48.757988968786435
    ],
    [
        85.96893062581671,
        48.75617947725274
    ],
    [
        85.9667179895423,
        48.75312662911506
    ],
    [
        85.96461795776486,
        48.751923971086654
    ],
    [
        85.96279475927554,
        48.75144297299863
    ],
    [
        85.95604269642604,
        48.74772027711177
    ],
    [
        85.95321924122862,
        48.74682000852201
    ],
    [
        85.95130429895869,
        48.74681223584602
    ],
    [
        85.95020965106647,
        48.74699237963327
    ],
    [
        85.94619559979691,
        48.74675127907608
    ],
    [
        85.94044620658832,
        48.74470472963816
    ],
    [
        85.93762260455014,
        48.74320332449375
    ],
    [
        85.93497371737192,
        48.73917059956423
    ],
    [
        85.93402856329288,
        48.737285645562366
    ],
    [
        85.93028346869801,
        48.73559943223174
    ],
    [
        85.92159718755744,
        48.73469617336849
    ],
    [
        85.9204069747496,
        48.735058289385734
    ],
    [
        85.9189439161969,
        48.73421380733106
    ],
    [
        85.91702141196936,
        48.73421392779002
    ],
    [
        85.91601749905539,
        48.733791575884084
    ],
    [
        85.91318042461575,
        48.73180082274254
    ],
    [
        85.91244747423713,
        48.73070992769044
    ],
    [
        85.91167466839198,
        48.72994292827937
    ],
    [
        85.91147382154509,
        48.72933730003652
    ],
    [
        85.91127297469984,
        48.72882629553277
    ]
]