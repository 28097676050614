import {LatLngExpression} from "leaflet";

export const katonkaragayDistrict: LatLngExpression[] = [
    [
        85.1440403,
        49.2238775
    ],
    [
        85.1440376,
        49.2534613
    ],
    [
        85.1021549,
        49.2910961
    ],
    [
        85.0712559,
        49.3291444
    ],
    [
        85.0540897,
        49.3703020
    ],
    [
        85.0389862,
        49.4297227
    ],
    [
        85.0143099,
        49.4617649
    ],
    [
        84.9710056,
        49.4993485
    ],
    [
        84.9708400,
        49.495502
    ],
    [
        84.930265,
        49.586107
    ],
    [
        84.9312074,
        49.5874376
    ],
    [
        84.9322546,
        49.5878992
    ],
    [
        84.9337834,
        49.5899629
    ],
    [
        84.9319823,
        49.5930039
    ],
    [
        84.9301393,
        49.602343
    ],
    [
        84.9319823,
        49.6026687
    ],
    [
        84.9352494,
        49.5987596
    ],
    [
        84.9371761,
        49.5946329
    ],
    [
        84.9407783,
        49.5892026
    ],
    [
        84.9437103,
        49.5865415
    ],
    [
        84.9437896,
        49.6175192
    ],
    [
        84.8539028,
        49.6965863
    ],
    [
        84.8546984,
        49.6963068
    ],
    [
        84.8557522,
        49.6964227
    ],
    [
        84.856237,
        49.6968726
    ],
    [
        84.8568587,
        49.6972338
    ],
    [
        84.8585448,
        49.6970566
    ],
    [
        84.8597882,
        49.6974247
    ],
    [
        84.8607156,
        49.6982835
    ],
    [
        84.8615375,
        49.6985016
    ],
    [
        84.8625702,
        49.6984471
    ],
    [
        84.8637505,
        49.6987198
    ],
    [
        84.8653101,
        49.6993468
    ],
    [
        84.8662585,
        49.6991287
    ],
    [
        84.8675441,
        49.6982017
    ],
    [
        84.8685347,
        49.6983244
    ],
    [
        84.8690195,
        49.6984335
    ],
    [
        84.8697571,
        49.6975883
    ],
    [
        84.8707055,
        49.6972884
    ],
    [
        84.8717382,
        49.6974247
    ],
    [
        84.873066,
        49.698229
    ],
    [
        84.8740987,
        49.6982835
    ],
    [
        84.8759956,
        49.69782
    ],
    [
        84.8770072,
        49.6982835
    ],
    [
        84.8790726,
        49.6982699
    ],
    [
        84.8799578,
        49.6991287
    ],
    [
        84.8808641,
        49.699156
    ],
    [
        84.8816439,
        49.6985971
    ],
    [
        84.8826766,
        49.6985425
    ],
    [
        84.8844681,
        49.6992514
    ],
    [
        84.8860488,
        49.6992241
    ],
    [
        84.88666,
        49.6990333
    ],
    [
        84.8881142,
        49.6978609
    ],
    [
        84.8898635,
        49.6976019
    ],
    [
        84.8915917,
        49.6977928
    ],
    [
        84.8955961,
        49.699265
    ],
    [
        84.8963549,
        49.6997013
    ],
    [
        84.8971979,
        49.6997421
    ],
    [
        84.8979145,
        49.6990333
    ],
    [
        84.8991369,
        49.6983108
    ],
    [
        84.9037314,
        49.6960751
    ],
    [
        84.9068506,
        49.6960342
    ],
    [
        84.9078623,
        49.6963205
    ],
    [
        84.9094851,
        49.6963341
    ],
    [
        84.9101174,
        49.6970294
    ],
    [
        84.9106232,
        49.6973429
    ],
    [
        84.9123725,
        49.6973702
    ],
    [
        84.9138478,
        49.6969067
    ],
    [
        84.9162926,
        49.6959796
    ],
    [
        84.9184002,
        49.6961432
    ],
    [
        84.9205921,
        49.6952298
    ],
    [
        84.9232687,
        49.6953525
    ],
    [
        84.9246387,
        49.6960205
    ],
    [
        84.9271256,
        49.6962796
    ],
    [
        84.9294229,
        49.6958297
    ],
    [
        84.9318888,
        49.6967294
    ],
    [
        84.9366519,
        49.6960887
    ],
    [
        84.9388227,
        49.6964159
    ],
    [
        84.9424478,
        49.6957888
    ],
    [
        84.9454827,
        49.6962523
    ],
    [
        84.9484333,
        49.6949844
    ],
    [
        84.9512364,
        49.6946845
    ],
    [
        84.9518476,
        49.6933757
    ],
    [
        84.9515947,
        49.692285
    ],
    [
        84.9525431,
        49.6914806
    ],
    [
        84.9565475,
        49.690608
    ],
    [
        84.9590977,
        49.6895718
    ],
    [
        84.9607627,
        49.6884538
    ],
    [
        84.9622802,
        49.6862721
    ],
    [
        84.963334,
        49.6855358
    ],
    [
        84.9644721,
        49.6853313
    ],
    [
        84.9662214,
        49.6838586
    ],
    [
        84.9669801,
        49.6821131
    ],
    [
        84.9682025,
        49.6808994
    ],
    [
        84.9689823,
        49.6790311
    ],
    [
        84.9703312,
        49.678322
    ],
    [
        84.9708791,
        49.6770673
    ],
    [
        84.9721226,
        49.6768491
    ],
    [
        84.972797,
        49.6763308
    ],
    [
        84.9737033,
        49.6739304
    ],
    [
        84.975579,
        49.6730985
    ],
    [
        84.9771176,
        49.6713526
    ],
    [
        84.9798364,
        49.6707661
    ],
    [
        84.9825762,
        49.6692384
    ],
    [
        84.9836134,
        49.6690365
    ],
    [
        84.9851686,
        49.6687337
    ],
    [
        84.9861381,
        49.6690202
    ],
    [
        84.9883931,
        49.6683927
    ],
    [
        84.9915124,
        49.6694567
    ],
    [
        84.9942522,
        49.6694839
    ],
    [
        84.9968235,
        49.6700023
    ],
    [
        84.9986782,
        49.6711753
    ],
    [
        85.001123,
        49.6719937
    ],
    [
        85.0039893,
        49.6724574
    ],
    [
        85.0050852,
        49.6731939
    ],
    [
        85.0074879,
        49.6734122
    ],
    [
        85.0095955,
        49.6740941
    ],
    [
        85.0121667,
        49.6749124
    ],
    [
        85.0149487,
        49.6769309
    ],
    [
        85.0160447,
        49.6773401
    ],
    [
        85.0178151,
        49.6794676
    ],
    [
        85.0194168,
        49.6804222
    ],
    [
        85.0212294,
        49.6822495
    ],
    [
        85.0245172,
        49.6836404
    ],
    [
        85.0277207,
        49.6845677
    ],
    [
        85.0300391,
        49.6859313
    ],
    [
        85.0310531,
        49.6858828
    ],
    [
        85.0335025,
        49.6853306
    ],
    [
        85.0361653,
        49.6845322
    ],
    [
        85.0371395,
        49.6836558
    ],
    [
        85.03829,
        49.6832176
    ],
    [
        85.0393662,
        49.6832596
    ],
    [
        85.0400713,
        49.6830795
    ],
    [
        85.0405074,
        49.6828574
    ],
    [
        85.0405909,
        49.6825873
    ],
    [
        85.0404146,
        49.6822931
    ],
    [
        85.0410548,
        49.6805702
    ],
    [
        85.0415929,
        49.6800719
    ],
    [
        85.0423073,
        49.6788352
    ],
    [
        85.0421403,
        49.678391
    ],
    [
        85.0432351,
        49.6762416
    ],
    [
        85.0439124,
        49.6757253
    ],
    [
        85.0446732,
        49.6745786
    ],
    [
        85.0474288,
        49.6727593
    ],
    [
        85.0518729,
        49.6706217
    ],
    [
        85.0535337,
        49.6698051
    ],
    [
        85.056651,
        49.6692046
    ],
    [
        85.0578386,
        49.6690905
    ],
    [
        85.0598148,
        49.6697811
    ],
    [
        85.063693,
        49.6707118
    ],
    [
        85.0647785,
        49.6707658
    ],
    [
        85.0682578,
        49.6714623
    ],
    [
        85.0718484,
        49.6720808
    ],
    [
        85.0731008,
        49.6719367
    ],
    [
        85.0749194,
        49.6712462
    ],
    [
        85.0767084,
        49.670697
    ],
    [
        85.0778141,
        49.6703575
    ],
    [
        85.0786676,
        49.669655
    ],
    [
        85.0796326,
        49.6694148
    ],
    [
        85.0821933,
        49.6679496
    ],
    [
        85.0897084,
        49.6672771
    ],
    [
        85.0924361,
        49.6672771
    ],
    [
        85.0944585,
        49.6660343
    ],
    [
        85.1009719,
        49.6638422
    ],
    [
        85.1041264,
        49.66365
    ],
    [
        85.105017,
        49.6630254
    ],
    [
        85.104564,
        49.6622943
    ],
    [
        85.104589,
        49.6619219
    ],
    [
        85.1052706,
        49.6619057
    ],
    [
        85.1075778,
        49.6626171
    ],
    [
        85.1086169,
        49.6623528
    ],
    [
        85.1092849,
        49.661464
    ],
    [
        85.1110663,
        49.6609835
    ],
    [
        85.1123652,
        49.6596623
    ],
    [
        85.1126434,
        49.6585863
    ],
    [
        85.113694,
        49.6556069
    ],
    [
        85.1161953,
        49.6545219
    ],
    [
        85.117321,
        49.6572909
    ],
    [
        85.1163454,
        49.6587968
    ],
    [
        85.1171959,
        49.6624238
    ],
    [
        85.1182965,
        49.6637191
    ],
    [
        85.1185966,
        49.6662771
    ],
    [
        85.1204602,
        49.6665847
    ],
    [
        85.1214732,
        49.6668599
    ],
    [
        85.1222612,
        49.6668033
    ],
    [
        85.1231241,
        49.6668356
    ],
    [
        85.1243498,
        49.6665038
    ],
    [
        85.1257381,
        49.6666818
    ],
    [
        85.1266135,
        49.6666899
    ],
    [
        85.1292024,
        49.66635
    ],
    [
        85.131979,
        49.6662933
    ],
    [
        85.1329045,
        49.6662042
    ],
    [
        85.1341677,
        49.6659776
    ],
    [
        85.1354559,
        49.6655567
    ],
    [
        85.1369567,
        49.6652248
    ],
    [
        85.1388452,
        49.66499
    ],
    [
        85.1403835,
        49.6650871
    ],
    [
        85.1415592,
        49.6653786
    ],
    [
        85.1429256,
        49.6661708
    ],
    [
        85.1446859,
        49.6653867
    ],
    [
        85.1465619,
        49.6652733
    ],
    [
        85.1492634,
        49.6660666
    ],
    [
        85.1525652,
        49.6643019
    ],
    [
        85.1564173,
        49.6635895
    ],
    [
        85.1593689,
        49.664221
    ],
    [
        85.1609698,
        49.6624238
    ],
    [
        85.1629959,
        49.6608694
    ],
    [
        85.165072,
        49.6602541
    ],
    [
        85.1685239,
        49.6603027
    ],
    [
        85.172326,
        49.6614847
    ],
    [
        85.1738268,
        49.6611932
    ],
    [
        85.177804,
        49.6609018
    ],
    [
        85.1799301,
        49.6612256
    ],
    [
        85.1817061,
        49.6614199
    ],
    [
        85.1836321,
        49.6599303
    ],
    [
        85.1855832,
        49.6594607
    ],
    [
        85.1868339,
        49.6595579
    ],
    [
        85.1905609,
        49.6603351
    ],
    [
        85.1930123,
        49.6605132
    ],
    [
        85.1950634,
        49.6607723
    ],
    [
        85.1964391,
        49.6606104
    ],
    [
        85.1974897,
        49.6598979
    ],
    [
        85.1989655,
        49.6586511
    ],
    [
        85.2007165,
        49.657712
    ],
    [
        85.2036298,
        49.6574402
    ],
    [
        85.2045673,
        49.6563004
    ],
    [
        85.2041166,
        49.6536817
    ],
    [
        85.2034571,
        49.6523225
    ],
    [
        85.2053279,
        49.6480751
    ],
    [
        85.2092867,
        49.6443961
    ],
    [
        85.2099452,
        49.6417103
    ],
    [
        85.2126033,
        49.6392642
    ],
    [
        85.2157132,
        49.6378946
    ],
    [
        85.2163452,
        49.6370155
    ],
    [
        85.2145041,
        49.6313551
    ],
    [
        85.2132232,
        49.6304986
    ],
    [
        85.2106798,
        49.6273503
    ],
    [
        85.234525,
        49.6258878
    ],
    [
        85.254512,
        49.6272583
    ],
    [
        85.2646714,
        49.6197876
    ],
    [
        85.2591909,
        49.6126011
    ],
    [
        85.2549831,
        49.6039977
    ],
    [
        85.2586462,
        49.5992168
    ],
    [
        85.2575191,
        49.5885235
    ],
    [
        85.321261,
        49.5908467
    ],
    [
        85.3884399,
        49.6348026
    ],
    [
        85.490205,
        49.5981291
    ],
    [
        85.5238793,
        49.5956797
    ],
    [
        85.5246339,
        49.5967933
    ],
    [
        85.5270067,
        49.5976694
    ],
    [
        85.5325438,
        49.598487
    ],
    [
        85.5394465,
        49.5973066
    ],
    [
        85.5453272,
        49.5969366
    ],
    [
        85.5532876,
        49.5944135
    ],
    [
        85.556311,
        49.5954414
    ],
    [
        85.5667422,
        49.6030825
    ],
    [
        85.5699822,
        49.6031664
    ],
    [
        85.5739539,
        49.6041236
    ],
    [
        85.5781469,
        49.6074917
    ],
    [
        85.5796238,
        49.6103593
    ],
    [
        85.5837033,
        49.6136726
    ],
    [
        85.5899141,
        49.6143806
    ],
    [
        85.599124,
        49.6125584
    ],
    [
        85.6072246,
        49.6092699
    ],
    [
        85.6108444,
        49.6063156
    ],
    [
        85.6216481,
        49.600869
    ],
    [
        85.6234246,
        49.5987154
    ],
    [
        85.6228523,
        49.5942107
    ],
    [
        85.6228429,
        49.5858793
    ],
    [
        85.6272318,
        49.5777867
    ],
    [
        85.6786796,
        49.5508666
    ],
    [
        85.6818777,
        49.5569163
    ],
    [
        85.6841267,
        49.558939
    ],
    [
        85.6939273,
        49.5641207
    ],
    [
        85.7005209,
        49.5653802
    ],
    [
        85.75689,
        49.5771504
    ],
    [
        85.7617812,
        49.5731087
    ],
    [
        85.7659931,
        49.5728146
    ],
    [
        85.7806054,
        49.5749996
    ],
    [
        85.7841045,
        49.5732138
    ],
    [
        85.7900196,
        49.5724777
    ],
    [
        85.8014541,
        49.5670142
    ],
    [
        85.8099119,
        49.5658807
    ],
    [
        85.8056466,
        49.5616311
    ],
    [
        85.8055008,
        49.5573542
    ],
    [
        85.8160024,
        49.5469945
    ],
    [
        85.8203928,
        49.5454895
    ],
    [
        85.826225,
        49.5442537
    ],
    [
        85.8331567,
        49.5446051
    ],
    [
        85.8410632,
        49.5476503
    ],
    [
        85.8837372,
        49.5669068
    ],
    [
        85.8838237,
        49.5651858
    ],
    [
        85.8848887,
        49.564015
    ],
    [
        85.8887493,
        49.5622091
    ],
    [
        85.8901753,
        49.5619632
    ],
    [
        85.9004442,
        49.5621054
    ],
    [
        85.9055244,
        49.5634135
    ],
    [
        85.9172674,
        49.5651685
    ],
    [
        85.9211545,
        49.5643755
    ],
    [
        85.9279268,
        49.5587965
    ],
    [
        85.938214,
        49.5621001
    ],
    [
        85.9442812,
        49.5613255
    ],
    [
        85.9497566,
        49.5560267
    ],
    [
        85.9494498,
        49.5537785
    ],
    [
        85.9468149,
        49.5501594
    ],
    [
        85.941384,
        49.5449459
    ],
    [
        85.9362193,
        49.5424464
    ],
    [
        85.9324999,
        49.5396999
    ],
    [
        85.9320781,
        49.5374414
    ],
    [
        85.9294084,
        49.5358658
    ],
    [
        85.9378281,
        49.5279
    ],
    [
        85.9383568,
        49.5232599
    ],
    [
        85.9395301,
        49.5224045
    ],
    [
        85.9393496,
        49.5202367
    ],
    [
        85.9403424,
        49.5183734
    ],
    [
        85.9431205,
        49.5176152
    ],
    [
        85.9444382,
        49.5132894
    ],
    [
        85.950257,
        49.5104556
    ],
    [
        85.9512498,
        49.508967
    ],
    [
        85.9529865,
        49.5080803
    ],
    [
        85.9550356,
        49.5075578
    ],
    [
        85.9534791,
        49.5050826
    ],
    [
        85.9533888,
        49.5027498
    ],
    [
        85.9601815,
        49.49856
    ],
    [
        85.963158,
        49.4909396
    ],
    [
        85.9689904,
        49.4871161
    ],
    [
        85.9864641,
        49.4889454
    ],
    [
        85.999028,
        49.4928619
    ],
    [
        86.00909,
        49.4976807
    ],
    [
        86.0102721,
        49.4997234
    ],
    [
        86.0245069,
        49.5037268
    ],
    [
        86.0290535,
        49.5065424
    ],
    [
        86.0328369,
        49.5080739
    ],
    [
        86.0324133,
        49.5123243
    ],
    [
        86.0332694,
        49.5167819
    ],
    [
        86.0371993,
        49.5218457
    ],
    [
        86.057964,
        49.5231011
    ],
    [
        86.0772931,
        49.5216759
    ],
    [
        86.0891058,
        49.5243831
    ],
    [
        86.1129224,
        49.5243831
    ],
    [
        86.1411034,
        49.5127929
    ],
    [
        86.1594393,
        49.5077292
    ],
    [
        86.1663446,
        49.5033037
    ],
    [
        86.1706146,
        49.4976439
    ],
    [
        86.1718683,
        49.4832777
    ],
    [
        86.1739094,
        49.4789793
    ],
    [
        86.2020374,
        49.4661127
    ],
    [
        86.2079017,
        49.4699238
    ],
    [
        86.2171584,
        49.4777626
    ],
    [
        86.2250948,
        49.4902102
    ],
    [
        86.2282701,
        49.4951894
    ],
    [
        86.230501,
        49.5022481
    ],
    [
        86.2352072,
        49.5136517
    ],
    [
        86.2387546,
        49.5176809
    ],
    [
        86.2394051,
        49.5254417
    ],
    [
        86.2538292,
        49.5338981
    ],
    [
        86.255045,
        49.5387201
    ],
    [
        86.2603239,
        49.543461
    ],
    [
        86.2670042,
        49.5465394
    ],
    [
        86.2683108,
        49.5504723
    ],
    [
        86.2662446,
        49.5556566
    ],
    [
        86.2682956,
        49.5577558
    ],
    [
        86.268383,
        49.5611255
    ],
    [
        86.266021,
        49.5660636
    ],
    [
        86.2662499,
        49.5686232
    ],
    [
        86.2686617,
        49.5718179
    ],
    [
        86.2690999,
        49.5780999
    ],
    [
        86.2715999,
        49.5826999
    ],
    [
        86.2866118,
        49.5918467
    ],
    [
        86.2992315,
        49.5959555
    ],
    [
        86.3159147,
        49.5992796
    ],
    [
        86.3226746,
        49.5976143
    ],
    [
        86.3313998,
        49.5932033
    ],
    [
        86.3421351,
        49.5941199
    ],
    [
        86.3507333,
        49.5919067
    ],
    [
        86.3618183,
        49.5924001
    ],
    [
        86.3713507,
        49.5936218
    ],
    [
        86.3792064,
        49.5973797
    ],
    [
        86.3867759,
        49.6013724
    ],
    [
        86.3945507,
        49.6033924
    ],
    [
        86.4078318,
        49.6060011
    ],
    [
        86.4194415,
        49.6083071
    ],
    [
        86.4256461,
        49.6242937
    ],
    [
        86.4286117,
        49.6293521
    ],
    [
        86.4477538,
        49.6377689
    ],
    [
        86.4562683,
        49.6383159
    ],
    [
        86.4690996,
        49.6455264
    ],
    [
        86.4884508,
        49.6579097
    ],
    [
        86.4927644,
        49.6620068
    ],
    [
        86.506078,
        49.6746498
    ],
    [
        86.5065202,
        49.6761951
    ],
    [
        86.5083639,
        49.6782157
    ],
    [
        86.5089108,
        49.6842556
    ],
    [
        86.5086057,
        49.6865111
    ],
    [
        86.5087801,
        49.6897344
    ],
    [
        86.5092392,
        49.6909505
    ],
    [
        86.5135531,
        49.6910552
    ],
    [
        86.5182799,
        49.6933279
    ],
    [
        86.523739,
        49.6974508
    ],
    [
        86.526557,
        49.702045
    ],
    [
        86.5341651,
        49.7045333
    ],
    [
        86.5394815,
        49.7054633
    ],
    [
        86.5451167,
        49.7084999
    ],
    [
        86.5486327,
        49.711447
    ],
    [
        86.5542162,
        49.7125971
    ],
    [
        86.5587767,
        49.7130354
    ],
    [
        86.5643092,
        49.7145812
    ],
    [
        86.5654277,
        49.7160182
    ],
    [
        86.567897,
        49.717192
    ],
    [
        86.5701921,
        49.7176148
    ],
    [
        86.5738236,
        49.719324
    ],
    [
        86.5768304,
        49.722085
    ],
    [
        86.580549,
        49.7229677
    ],
    [
        86.5822921,
        49.7241416
    ],
    [
        86.5804799,
        49.7273355
    ],
    [
        86.5784669,
        49.7300765
    ],
    [
        86.5784234,
        49.733919
    ],
    [
        86.5817548,
        49.7354169
    ],
    [
        86.5831743,
        49.7376448
    ],
    [
        86.5852021,
        49.7380381
    ],
    [
        86.5860133,
        49.7396669
    ],
    [
        86.5898661,
        49.7397416
    ],
    [
        86.5930817,
        49.7403221
    ],
    [
        86.5950226,
        49.7426809
    ],
    [
        86.5995707,
        49.7427371
    ],
    [
        86.6024097,
        49.7440475
    ],
    [
        86.60235,
        49.7467666
    ],
    [
        86.602558,
        49.7486899
    ],
    [
        86.6033755,
        49.7533765
    ],
    [
        86.6045981,
        49.7542843
    ],
    [
        86.6055367,
        49.7584477
    ],
    [
        86.6092491,
        49.7637696
    ],
    [
        86.608102,
        49.7655209
    ],
    [
        86.6094785,
        49.7681746
    ],
    [
        86.6105005,
        49.7689291
    ],
    [
        86.6105839,
        49.7720407
    ],
    [
        86.6096985,
        49.7729811
    ],
    [
        86.6081646,
        49.7743844
    ],
    [
        86.6058912,
        49.7750578
    ],
    [
        86.6044104,
        49.7782364
    ],
    [
        86.6029505,
        49.779866
    ],
    [
        86.603889,
        49.78128
    ],
    [
        86.6043412,
        49.7836316
    ],
    [
        86.5999471,
        49.7848218
    ],
    [
        86.5975389,
        49.7872897
    ],
    [
        86.5973192,
        49.7875149
    ],
    [
        86.5967381,
        49.7881289
    ],
    [
        86.5962767,
        49.7893549
    ],
    [
        86.5956321,
        49.790601
    ],
    [
        86.59511,
        49.7911021
    ],
    [
        86.5950778,
        49.7915108
    ],
    [
        86.5953755,
        49.7923575
    ],
    [
        86.5955204,
        49.7929116
    ],
    [
        86.5956759,
        49.7935799
    ],
    [
        86.5960112,
        49.7947158
    ],
    [
        86.5962935,
        49.7950166
    ],
    [
        86.5969393,
        49.7959866
    ],
    [
        86.5965718,
        49.7963779
    ],
    [
        86.596148,
        49.7971813
    ],
    [
        86.5958905,
        49.7976245
    ],
    [
        86.5950322,
        49.7985733
    ],
    [
        86.5937662,
        49.7994251
    ],
    [
        86.5925914,
        49.7999098
    ],
    [
        86.5909338,
        49.8004534
    ],
    [
        86.5902031,
        49.8009663
    ],
    [
        86.5901642,
        49.8015561
    ],
    [
        86.5902453,
        49.8024032
    ],
    [
        86.5900878,
        49.8027253
    ],
    [
        86.5900942,
        49.8028373
    ],
    [
        86.5903114,
        49.804854
    ],
    [
        86.5899075,
        49.8070677
    ],
    [
        86.5947962,
        49.805948
    ],
    [
        86.6011905,
        49.8031228
    ],
    [
        86.6034436,
        49.8030259
    ],
    [
        86.6053748,
        49.80376
    ],
    [
        86.6100417,
        49.8046254
    ],
    [
        86.6110289,
        49.8030536
    ],
    [
        86.6128207,
        49.8017328
    ],
    [
        86.6131532,
        49.8014955
    ],
    [
        86.6152775,
        49.8007062
    ],
    [
        86.6159426,
        49.8011147
    ],
    [
        86.6181537,
        49.8029182
    ],
    [
        86.6181922,
        49.8029496
    ],
    [
        86.6182595,
        49.8030045
    ],
    [
        86.6199037,
        49.8043455
    ],
    [
        86.6222473,
        49.8052692
    ],
    [
        86.6253695,
        49.8048817
    ],
    [
        86.6264603,
        49.8055856
    ],
    [
        86.6282424,
        49.8054817
    ],
    [
        86.6288176,
        49.805082
    ],
    [
        86.6305283,
        49.8057767
    ],
    [
        86.6315505,
        49.8069033
    ],
    [
        86.636417,
        49.8079682
    ],
    [
        86.6400492,
        49.8079711
    ],
    [
        86.6433775,
        49.8062744
    ],
    [
        86.6456149,
        49.8065271
    ],
    [
        86.6473352,
        49.8075174
    ],
    [
        86.6488593,
        49.8084223
    ],
    [
        86.6488087,
        49.8100346
    ],
    [
        86.6488893,
        49.8106402
    ],
    [
        86.6499556,
        49.8110929
    ],
    [
        86.6538786,
        49.8125599
    ],
    [
        86.6554448,
        49.8126862
    ],
    [
        86.6605986,
        49.811796
    ],
    [
        86.664143,
        49.8086795
    ],
    [
        86.6658873,
        49.8081767
    ],
    [
        86.6678452,
        49.8084957
    ],
    [
        86.6713558,
        49.8083737
    ],
    [
        86.6762148,
        49.8075773
    ],
    [
        86.6787708,
        49.8087769
    ],
    [
        86.6818072,
        49.8095521
    ],
    [
        86.685065,
        49.8109245
    ],
    [
        86.6883746,
        49.8108988
    ],
    [
        86.693833,
        49.8097757
    ],
    [
        86.6974139,
        49.8080905
    ],
    [
        86.6997493,
        49.8064303
    ],
    [
        86.7014711,
        49.8060702
    ],
    [
        86.7044203,
        49.8040536
    ],
    [
        86.7053768,
        49.8039507
    ],
    [
        86.710829,
        49.8012137
    ],
    [
        86.7133,
        49.7954821
    ],
    [
        86.7153406,
        49.7942163
    ],
    [
        86.7194855,
        49.7938972
    ],
    [
        86.7217022,
        49.7936422
    ],
    [
        86.7250173,
        49.7932901
    ],
    [
        86.7285086,
        49.7928578
    ],
    [
        86.731697,
        49.7904084
    ],
    [
        86.73255,
        49.7893167
    ],
    [
        86.7324661,
        49.788049
    ],
    [
        86.7358617,
        49.7871536
    ],
    [
        86.73835,
        49.7869333
    ],
    [
        86.7400545,
        49.7870815
    ],
    [
        86.7413936,
        49.7874109
    ],
    [
        86.7431791,
        49.7873594
    ],
    [
        86.746511,
        49.7865978
    ],
    [
        86.7509666,
        49.7906549
    ],
    [
        86.7551037,
        49.791785
    ],
    [
        86.7562037,
        49.7913219
    ],
    [
        86.7576225,
        49.791219
    ],
    [
        86.7591689,
        49.7906015
    ],
    [
        86.7612254,
        49.7902721
    ],
    [
        86.7625167,
        49.7906015
    ],
    [
        86.7638718,
        49.7903853
    ],
    [
        86.7664225,
        49.7914557
    ],
    [
        86.7722732,
        49.7921349
    ],
    [
        86.7745848,
        49.7935757
    ],
    [
        86.7776456,
        49.7936478
    ],
    [
        86.779425,
        49.7944909
    ],
    [
        86.7810989,
        49.7943469
    ],
    [
        86.7828427,
        49.7937918
    ],
    [
        86.7834645,
        49.7931126
    ],
    [
        86.7835282,
        49.7918056
    ],
    [
        86.7822369,
        49.790622
    ],
    [
        86.7827311,
        49.78914
    ],
    [
        86.7780252,
        49.7824716
    ],
    [
        86.7760349,
        49.7775466
    ],
    [
        86.776295,
        49.7742145
    ],
    [
        86.7755252,
        49.7699359
    ],
    [
        86.7745383,
        49.7696586
    ],
    [
        86.7747529,
        49.7679955
    ],
    [
        86.7737873,
        49.7678569
    ],
    [
        86.7719152,
        49.7646228
    ],
    [
        86.771384,
        49.7638237
    ],
    [
        86.7715342,
        49.762618
    ],
    [
        86.7697318,
        49.7619387
    ],
    [
        86.7684443,
        49.7602892
    ],
    [
        86.7667277,
        49.75961
    ],
    [
        86.7656763,
        49.7584041
    ],
    [
        86.7644746,
        49.7581408
    ],
    [
        86.7647536,
        49.7546057
    ],
    [
        86.7643888,
        49.7533996
    ],
    [
        86.7653115,
        49.7518054
    ],
    [
        86.7645845,
        49.7502326
    ],
    [
        86.7628224,
        49.7481591
    ],
    [
        86.7587453,
        49.7464121
    ],
    [
        86.7576753,
        49.7448768
    ],
    [
        86.7549904,
        49.743015
    ],
    [
        86.7529975,
        49.7423948
    ],
    [
        86.755315,
        49.7390529
    ],
    [
        86.7580186,
        49.7371252
    ],
    [
        86.7563878,
        49.7339632
    ],
    [
        86.7568385,
        49.7322157
    ],
    [
        86.7583405,
        49.7310091
    ],
    [
        86.7614948,
        49.7303711
    ],
    [
        86.76216,
        49.7285402
    ],
    [
        86.7636951,
        49.7255558
    ],
    [
        86.7663363,
        49.7249357
    ],
    [
        86.7666292,
        49.7236608
    ],
    [
        86.7675519,
        49.722121
    ],
    [
        86.7686603,
        49.7179831
    ],
    [
        86.7710481,
        49.7132568
    ],
    [
        86.7706793,
        49.7110774
    ],
    [
        86.7709261,
        49.7105154
    ],
    [
        86.7736727,
        49.7072337
    ],
    [
        86.7751465,
        49.7056389
    ],
    [
        86.7766446,
        49.7042883
    ],
    [
        86.7784569,
        49.7031465
    ],
    [
        86.7789236,
        49.6960165
    ],
    [
        86.7730187,
        49.6932107
    ],
    [
        86.7708035,
        49.6932107
    ],
    [
        86.7706623,
        49.6919495
    ],
    [
        86.7677226,
        49.6891316
    ],
    [
        86.7614999,
        49.6879655
    ],
    [
        86.7590108,
        49.6883126
    ],
    [
        86.7579594,
        49.6877851
    ],
    [
        86.7560711,
        49.6884097
    ],
    [
        86.7554548,
        49.6896125
    ],
    [
        86.7525392,
        49.6899991
    ],
    [
        86.7517673,
        49.6894191
    ],
    [
        86.7496507,
        49.6898218
    ],
    [
        86.7490531,
        49.6907723
    ],
    [
        86.7445212,
        49.6893708
    ],
    [
        86.7384205,
        49.6884204
    ],
    [
        86.736279,
        49.6869544
    ],
    [
        86.7348597,
        49.6856494
    ],
    [
        86.734785,
        49.6842962
    ],
    [
        86.734013,
        49.6838452
    ],
    [
        86.7317222,
        49.6819762
    ],
    [
        86.7309876,
        49.6807114
    ],
    [
        86.7311245,
        49.680228
    ],
    [
        86.7307759,
        49.6795271
    ],
    [
        86.7298546,
        49.6791888
    ],
    [
        86.7294064,
        49.678198
    ],
    [
        86.7268988,
        49.6774543
    ],
    [
        86.7241897,
        49.6766511
    ],
    [
        86.721351,
        49.6771587
    ],
    [
        86.719832,
        49.6780851
    ],
    [
        86.7163957,
        49.6829591
    ],
    [
        86.7136815,
        49.6841674
    ],
    [
        86.7094733,
        49.6872606
    ],
    [
        86.707033,
        49.6863826
    ],
    [
        86.7049414,
        49.6866725
    ],
    [
        86.7019906,
        49.6856818
    ],
    [
        86.6997247,
        49.6849729
    ],
    [
        86.7005802,
        49.6829875
    ],
    [
        86.7006673,
        49.6806515
    ],
    [
        86.6972047,
        49.6784072
    ],
    [
        86.6842375,
        49.6751482
    ],
    [
        86.6846981,
        49.6706844
    ],
    [
        86.6819148,
        49.6686591
    ],
    [
        86.6797667,
        49.6688983
    ],
    [
        86.6781468,
        49.6692972
    ],
    [
        86.6733575,
        49.6686021
    ],
    [
        86.6657335,
        49.6655024
    ],
    [
        86.6663321,
        49.6628355
    ],
    [
        86.6632251,
        49.6568084
    ],
    [
        86.658543,
        49.6548812
    ],
    [
        86.6532607,
        49.6524873
    ],
    [
        86.6528734,
        49.6497515
    ],
    [
        86.6568879,
        49.6454648
    ],
    [
        86.6591592,
        49.6437888
    ],
    [
        86.6356928,
        49.6319262
    ],
    [
        86.6217032,
        49.6309918
    ],
    [
        86.6191874,
        49.6290188
    ],
    [
        86.6155494,
        49.6285236
    ],
    [
        86.6113599,
        49.6239653
    ],
    [
        86.6100693,
        49.6195024
    ],
    [
        86.6085769,
        49.6170992
    ],
    [
        86.6007582,
        49.6109145
    ],
    [
        86.5975765,
        49.609524
    ],
    [
        86.602492,
        49.6055533
    ],
    [
        86.6102374,
        49.5845317
    ],
    [
        86.6182488,
        49.5703775
    ],
    [
        86.6295593,
        49.5656815
    ],
    [
        86.6438954,
        49.5628083
    ],
    [
        86.6659047,
        49.5602717
    ],
    [
        86.6755483,
        49.5614612
    ],
    [
        86.6809745,
        49.5637969
    ],
    [
        86.6823965,
        49.5690592
    ],
    [
        86.6966175,
        49.5702568
    ],
    [
        86.7104304,
        49.5680851
    ],
    [
        86.721383,
        49.5682209
    ],
    [
        86.7261268,
        49.5706866
    ],
    [
        86.7338985,
        49.571457
    ],
    [
        86.7395126,
        49.571169
    ],
    [
        86.7424077,
        49.5701963
    ],
    [
        86.7442215,
        49.5688843
    ],
    [
        86.7477445,
        49.5689295
    ],
    [
        86.752279,
        49.5676401
    ],
    [
        86.7588018,
        49.5687712
    ],
    [
        86.7593599,
        49.5645181
    ],
    [
        86.7596263,
        49.5636907
    ],
    [
        86.7612831,
        49.5625086
    ],
    [
        86.7645707,
        49.5618355
    ],
    [
        86.7771891,
        49.5634761
    ],
    [
        86.7774332,
        49.5618479
    ],
    [
        86.7747125,
        49.557549
    ],
    [
        86.7902327,
        49.5527722
    ],
    [
        86.798372,
        49.5541162
    ],
    [
        86.8008377,
        49.5505844
    ],
    [
        86.8044304,
        49.5491587
    ],
    [
        86.8069767,
        49.5490455
    ],
    [
        86.8092606,
        49.5493117
    ],
    [
        86.8106199,
        49.548072
    ],
    [
        86.8129918,
        49.5477212
    ],
    [
        86.8180495,
        49.5481399
    ],
    [
        86.823843,
        49.5472812
    ],
    [
        86.8271741,
        49.5454254
    ],
    [
        86.8273607,
        49.5387381
    ],
    [
        86.8251905,
        49.5367025
    ],
    [
        86.8148466,
        49.5323219
    ],
    [
        86.8186044,
        49.5273046
    ],
    [
        86.8248181,
        49.5230345
    ],
    [
        86.8343827,
        49.5183495
    ],
    [
        86.8375767,
        49.5160622
    ],
    [
        86.8449322,
        49.5146408
    ],
    [
        86.8463471,
        49.5125298
    ],
    [
        86.8510494,
        49.5106244
    ],
    [
        86.8533432,
        49.5058838
    ],
    [
        86.8536391,
        49.501383
    ],
    [
        86.8554365,
        49.4993355
    ],
    [
        86.8503834,
        49.4954079
    ],
    [
        86.8473003,
        49.4948793
    ],
    [
        86.8458698,
        49.4932132
    ],
    [
        86.8407142,
        49.4887038
    ],
    [
        86.8361266,
        49.486243
    ],
    [
        86.8332882,
        49.4852914
    ],
    [
        86.832131,
        49.4832626
    ],
    [
        86.8297196,
        49.4817399
    ],
    [
        86.8342578,
        49.4771334
    ],
    [
        86.8317791,
        49.4748977
    ],
    [
        86.8309158,
        49.4727581
    ],
    [
        86.8309528,
        49.4697529
    ],
    [
        86.8336739,
        49.4667548
    ],
    [
        86.8360458,
        49.4662675
    ],
    [
        86.8369527,
        49.4635698
    ],
    [
        86.8386619,
        49.4627196
    ],
    [
        86.8383305,
        49.4612233
    ],
    [
        86.8362551,
        49.4588768
    ],
    [
        86.8363074,
        49.4565094
    ],
    [
        86.8339355,
        49.4537432
    ],
    [
        86.8333774,
        49.4522466
    ],
    [
        86.8441207,
        49.4527455
    ],
    [
        86.8470507,
        49.4517024
    ],
    [
        86.8482543,
        49.4474089
    ],
    [
        86.8510814,
        49.4445937
    ],
    [
        86.8533921,
        49.444122
    ],
    [
        86.8575631,
        49.4443211
    ],
    [
        86.8612361,
        49.4420266
    ],
    [
        86.8637788,
        49.4411824
    ],
    [
        86.8652646,
        49.4395687
    ],
    [
        86.8683051,
        49.4348227
    ],
    [
        86.8802244,
        49.4341011
    ],
    [
        86.8911001,
        49.4302447
    ],
    [
        86.8937124,
        49.4309061
    ],
    [
        86.8973017,
        49.4297124
    ],
    [
        86.9024319,
        49.4292472
    ],
    [
        86.9065016,
        49.427643
    ],
    [
        86.9118785,
        49.4211936
    ],
    [
        86.9117305,
        49.4186105
    ],
    [
        86.9144436,
        49.4166369
    ],
    [
        86.9209214,
        49.4168689
    ],
    [
        86.9237331,
        49.418682
    ],
    [
        86.9269642,
        49.4192757
    ],
    [
        86.9289127,
        49.4202383
    ],
    [
        86.9301213,
        49.4200618
    ],
    [
        86.9328735,
        49.4176872
    ],
    [
        86.9373378,
        49.4169331
    ],
    [
        86.9437999,
        49.4108996
    ],
    [
        86.9473282,
        49.4096239
    ],
    [
        86.9477722,
        49.4064175
    ],
    [
        86.9450344,
        49.4056311
    ],
    [
        86.9443259,
        49.4035023
    ],
    [
        86.9385182,
        49.4033548
    ],
    [
        86.9362659,
        49.4001947
    ],
    [
        86.9364385,
        49.3980117
    ],
    [
        86.9450732,
        49.3914794
    ],
    [
        86.9452127,
        49.3857804
    ],
    [
        86.9492001,
        49.3799416
    ],
    [
        86.9396667,
        49.3727187
    ],
    [
        86.9367763,
        49.3689182
    ],
    [
        86.931043,
        49.3686505
    ],
    [
        86.9247153,
        49.3698176
    ],
    [
        86.9231005,
        49.3623292
    ],
    [
        86.9250042,
        49.3607609
    ],
    [
        86.927097,
        49.359784
    ],
    [
        86.9249693,
        49.3582505
    ],
    [
        86.925353,
        49.3556719
    ],
    [
        86.9269575,
        49.3526727
    ],
    [
        86.9259459,
        49.3509572
    ],
    [
        86.9264692,
        49.3488781
    ],
    [
        86.9292528,
        49.3473531
    ],
    [
        86.9317991,
        49.3449898
    ],
    [
        86.9356535,
        49.3437967
    ],
    [
        86.9403677,
        49.3397189
    ],
    [
        86.9430438,
        49.3385216
    ],
    [
        86.9484331,
        49.3369867
    ],
    [
        86.9494672,
        49.3373735
    ],
    [
        86.9500596,
        49.3372778
    ],
    [
        86.9502665,
        49.3369665
    ],
    [
        86.9505749,
        49.33674
    ],
    [
        86.9508979,
        49.336416
    ],
    [
        86.9511829,
        49.336296
    ],
    [
        86.9517269,
        49.336215
    ],
    [
        86.9523899,
        49.336193
    ],
    [
        86.9531319,
        49.335991
    ],
    [
        86.9537799,
        49.335561
    ],
    [
        86.9541259,
        49.335238
    ],
    [
        86.9544029,
        49.334562
    ],
    [
        86.9547199,
        49.334125
    ],
    [
        86.9548829,
        49.333391
    ],
    [
        86.9551539,
        49.333037
    ],
    [
        86.9578669,
        49.331163
    ],
    [
        86.9594609,
        49.329814
    ],
    [
        86.9602819,
        49.328497
    ],
    [
        86.9606999,
        49.327963
    ],
    [
        86.9613779,
        49.3273
    ],
    [
        86.9627079,
        49.325412
    ],
    [
        86.9627979,
        49.325037
    ],
    [
        86.9621789,
        49.323963
    ],
    [
        86.9587679,
        49.318599
    ],
    [
        86.9586989,
        49.318211
    ],
    [
        86.9588249,
        49.317878
    ],
    [
        86.9589669,
        49.317622
    ],
    [
        86.9593839,
        49.317463
    ],
    [
        86.9600889,
        49.317401
    ],
    [
        86.9606069,
        49.317289
    ],
    [
        86.9609689,
        49.317104
    ],
    [
        86.9619049,
        49.315929
    ],
    [
        86.9626299,
        49.314567
    ],
    [
        86.9633819,
        49.312776
    ],
    [
        86.9636979,
        49.312187
    ],
    [
        86.9640799,
        49.311671
    ],
    [
        86.9652599,
        49.310428
    ],
    [
        86.9662399,
        49.309585
    ],
    [
        86.9671669,
        49.30911
    ],
    [
        86.9677879,
        49.308615
    ],
    [
        86.9680419,
        49.308301
    ],
    [
        86.9684889,
        49.308058
    ],
    [
        86.9691109,
        49.307626
    ],
    [
        86.9699909,
        49.307308
    ],
    [
        86.9703529,
        49.306617
    ],
    [
        86.9709869,
        49.305851
    ],
    [
        86.9731949,
        49.304755
    ],
    [
        86.9741829,
        49.30436
    ],
    [
        86.9750579,
        49.304158
    ],
    [
        86.9786939,
        49.303593
    ],
    [
        86.9799689,
        49.303284
    ],
    [
        86.9821429,
        49.30089
    ],
    [
        86.9995569,
        49.297546
    ],
    [
        87.0060455,
        49.2988636
    ],
    [
        87.0185208,
        49.2690463
    ],
    [
        87.0185332,
        49.2690166
    ],
    [
        87.0185585,
        49.2689726
    ],
    [
        87.0252909,
        49.2572823
    ],
    [
        87.0307157,
        49.2528804
    ],
    [
        87.0369401,
        49.2508474
    ],
    [
        87.0438033,
        49.250477
    ],
    [
        87.0471749,
        49.250485
    ],
    [
        87.0482239,
        49.250544
    ],
    [
        87.0495059,
        49.250725
    ],
    [
        87.0506149,
        49.250977
    ],
    [
        87.0519279,
        49.251371
    ],
    [
        87.0546879,
        49.25236
    ],
    [
        87.0597579,
        49.254474
    ],
    [
        87.0603539,
        49.254606
    ],
    [
        87.0634999,
        49.254785
    ],
    [
        87.0645029,
        49.254773
    ],
    [
        87.0659149,
        49.254594
    ],
    [
        87.0677149,
        49.254299
    ],
    [
        87.0707769,
        49.253535
    ],
    [
        87.0720879,
        49.253374
    ],
    [
        87.0727749,
        49.253326
    ],
    [
        87.0740979,
        49.253478
    ],
    [
        87.0760939,
        49.253577
    ],
    [
        87.0815019,
        49.253103
    ],
    [
        87.0829699,
        49.252878
    ],
    [
        87.0857299,
        49.252151
    ],
    [
        87.0862849,
        49.252063
    ],
    [
        87.0870289,
        49.252066
    ],
    [
        87.0892989,
        49.252188
    ],
    [
        87.0922589,
        49.25207
    ],
    [
        87.0947089,
        49.252063
    ],
    [
        87.0951709,
        49.252154
    ],
    [
        87.0966549,
        49.25298
    ],
    [
        87.0986669,
        49.253872
    ],
    [
        87.1008039,
        49.2545
    ],
    [
        87.1018789,
        49.254763
    ],
    [
        87.1032409,
        49.254977
    ],
    [
        87.1040649,
        49.254989
    ],
    [
        87.1058529,
        49.254815
    ],
    [
        87.1079259,
        49.25447
    ],
    [
        87.1109609,
        49.253817
    ],
    [
        87.1124719,
        49.253561
    ],
    [
        87.1130199,
        49.253491
    ],
    [
        87.1146219,
        49.253394
    ],
    [
        87.1153409,
        49.253266
    ],
    [
        87.1160789,
        49.253316
    ],
    [
        87.1166449,
        49.253414
    ],
    [
        87.1200999,
        49.25421
    ],
    [
        87.1216119,
        49.254628
    ],
    [
        87.1247249,
        49.255641
    ],
    [
        87.1255089,
        49.255803
    ],
    [
        87.1269429,
        49.255978
    ],
    [
        87.1282739,
        49.256051
    ],
    [
        87.1287199,
        49.255963
    ],
    [
        87.1289909,
        49.25582
    ],
    [
        87.1306459,
        49.254168
    ],
    [
        87.1335159,
        49.251905
    ],
    [
        87.1351689,
        49.250178
    ],
    [
        87.1358709,
        49.249245
    ],
    [
        87.1367319,
        49.24832
    ],
    [
        87.1376689,
        49.247554
    ],
    [
        87.1387949,
        49.246842
    ],
    [
        87.1395809,
        49.246442
    ],
    [
        87.1403249,
        49.246145
    ],
    [
        87.1410729,
        49.24597
    ],
    [
        87.1417209,
        49.245908
    ],
    [
        87.1423079,
        49.245921
    ],
    [
        87.1443279,
        49.246851
    ],
    [
        87.1456969,
        49.247401
    ],
    [
        87.1485369,
        49.248135
    ],
    [
        87.1531199,
        49.249238
    ],
    [
        87.1545839,
        49.249468
    ],
    [
        87.1562479,
        49.249604
    ],
    [
        87.1577359,
        49.24961
    ],
    [
        87.1601369,
        49.24972
    ],
    [
        87.1618009,
        49.249864
    ],
    [
        87.1635799,
        49.249961
    ],
    [
        87.1652019,
        49.250144
    ],
    [
        87.1657789,
        49.25017
    ],
    [
        87.1664529,
        49.250154
    ],
    [
        87.1669639,
        49.250091
    ],
    [
        87.1673069,
        49.249947
    ],
    [
        87.1675569,
        49.24975
    ],
    [
        87.1677299,
        49.249475
    ],
    [
        87.1677589,
        49.248829
    ],
    [
        87.1678679,
        49.248573
    ],
    [
        87.1683649,
        49.248159
    ],
    [
        87.1697519,
        49.24751
    ],
    [
        87.1707209,
        49.247187
    ],
    [
        87.1714419,
        49.247017
    ],
    [
        87.1724809,
        49.246821
    ],
    [
        87.1734029,
        49.246701
    ],
    [
        87.1741819,
        49.246656
    ],
    [
        87.1756429,
        49.246746
    ],
    [
        87.1785859,
        49.247207
    ],
    [
        87.1800529,
        49.247541
    ],
    [
        87.1807009,
        49.247831
    ],
    [
        87.1812219,
        49.24813
    ],
    [
        87.1816131,
        49.2485524
    ],
    [
        87.1827439,
        49.248369
    ],
    [
        87.1843889,
        49.248367
    ],
    [
        87.1860109,
        49.248452
    ],
    [
        87.1878209,
        49.248784
    ],
    [
        87.1895099,
        49.249245
    ],
    [
        87.1903859,
        49.249658
    ],
    [
        87.1915669,
        49.250125
    ],
    [
        87.1955349,
        49.251316
    ],
    [
        87.1967469,
        49.251764
    ],
    [
        87.1983249,
        49.252499
    ],
    [
        87.1985259,
        49.252469
    ],
    [
        87.1991279,
        49.252258
    ],
    [
        87.2011179,
        49.251453
    ],
    [
        87.2019799,
        49.251249
    ],
    [
        87.2027149,
        49.251176
    ],
    [
        87.2034369,
        49.25116
    ],
    [
        87.2040019,
        49.25124
    ],
    [
        87.2055419,
        49.251619
    ],
    [
        87.2064129,
        49.25177
    ],
    [
        87.2071209,
        49.251792
    ],
    [
        87.2077989,
        49.251729
    ],
    [
        87.2093079,
        49.251407
    ],
    [
        87.2099979,
        49.251241
    ],
    [
        87.2108719,
        49.250924
    ],
    [
        87.2119429,
        49.250381
    ],
    [
        87.2134119,
        49.249552
    ],
    [
        87.2173829,
        49.248243
    ],
    [
        87.2183139,
        49.247887
    ],
    [
        87.2189279,
        49.24761
    ],
    [
        87.2197389,
        49.24705
    ],
    [
        87.2209999,
        49.246329
    ],
    [
        87.2212849,
        49.246228
    ],
    [
        87.2217259,
        49.24618
    ],
    [
        87.2255329,
        49.246189
    ],
    [
        87.2277209,
        49.246026
    ],
    [
        87.2285779,
        49.245909
    ],
    [
        87.2290839,
        49.245784
    ],
    [
        87.2297859,
        49.245525
    ],
    [
        87.2320229,
        49.24483
    ],
    [
        87.2334809,
        49.244311
    ],
    [
        87.2347539,
        49.243643
    ],
    [
        87.2363699,
        49.242382
    ],
    [
        87.2374699,
        49.241202
    ],
    [
        87.2399489,
        49.239165
    ],
    [
        87.2412049,
        49.238526
    ],
    [
        87.2425949,
        49.238045
    ],
    [
        87.2432979,
        49.237869
    ],
    [
        87.2439889,
        49.237779
    ],
    [
        87.2448549,
        49.237733
    ],
    [
        87.2461559,
        49.237796
    ],
    [
        87.2474629,
        49.238103
    ],
    [
        87.2507869,
        49.239217
    ],
    [
        87.2521369,
        49.239859
    ],
    [
        87.2533139,
        49.240264
    ],
    [
        87.2539619,
        49.240328
    ],
    [
        87.2545499,
        49.240321
    ],
    [
        87.2552649,
        49.240168
    ],
    [
        87.2577919,
        49.239216
    ],
    [
        87.2600959,
        49.23818
    ],
    [
        87.2626299,
        49.236916
    ],
    [
        87.2632029,
        49.236422
    ],
    [
        87.2637469,
        49.236056
    ],
    [
        87.2643529,
        49.235732
    ],
    [
        87.2653929,
        49.234791
    ],
    [
        87.2658119,
        49.234518
    ],
    [
        87.2666039,
        49.234102
    ],
    [
        87.2676649,
        49.233316
    ],
    [
        87.2688709,
        49.232705
    ],
    [
        87.2693249,
        49.23257
    ],
    [
        87.2707369,
        49.232531
    ],
    [
        87.2721679,
        49.232549
    ],
    [
        87.2737549,
        49.232769
    ],
    [
        87.2753109,
        49.233201
    ],
    [
        87.2766769,
        49.233733
    ],
    [
        87.2789069,
        49.234207
    ],
    [
        87.2814739,
        49.234223
    ],
    [
        87.2846919,
        49.233865
    ],
    [
        87.2853769,
        49.233874
    ],
    [
        87.2862779,
        49.233822
    ],
    [
        87.2867439,
        49.233741
    ],
    [
        87.2874599,
        49.233527
    ],
    [
        87.2880959,
        49.233193
    ],
    [
        87.2893249,
        49.233343
    ],
    [
        87.2907729,
        49.233694
    ],
    [
        87.2922509,
        49.233924
    ],
    [
        87.2931229,
        49.234003
    ],
    [
        87.2956669,
        49.234111
    ],
    [
        87.2972769,
        49.234445
    ],
    [
        87.2987549,
        49.23459
    ],
    [
        87.3002959,
        49.234952
    ],
    [
        87.3019639,
        49.235192
    ],
    [
        87.3036569,
        49.235279
    ],
    [
        87.3041819,
        49.235247
    ],
    [
        87.3056459,
        49.234892
    ],
    [
        87.3067029,
        49.234709
    ],
    [
        87.3082419,
        49.234373
    ],
    [
        87.3096709,
        49.233885
    ],
    [
        87.3101989,
        49.233592
    ],
    [
        87.3119519,
        49.232255
    ],
    [
        87.3123859,
        49.232012
    ],
    [
        87.3134269,
        49.231707
    ],
    [
        87.3148879,
        49.231361
    ],
    [
        87.3156336,
        49.2310833
    ],
    [
        87.2915928,
        49.201769
    ],
    [
        87.2926946,
        49.1901042
    ],
    [
        87.2997388,
        49.1845691
    ],
    [
        87.3007609,
        49.184046
    ],
    [
        87.3015169,
        49.1826
    ],
    [
        87.3019319,
        49.181337
    ],
    [
        87.3020689,
        49.180276
    ],
    [
        87.3025619,
        49.179154
    ],
    [
        87.3027529,
        49.178554
    ],
    [
        87.3031559,
        49.176312
    ],
    [
        87.3036029,
        49.174606
    ],
    [
        87.3038139,
        49.172727
    ],
    [
        87.3038119,
        49.172181
    ],
    [
        87.3035749,
        49.171171
    ],
    [
        87.3033559,
        49.170707
    ],
    [
        87.3027309,
        49.169557
    ],
    [
        87.3021309,
        49.168823
    ],
    [
        87.3016559,
        49.168457
    ],
    [
        87.2994279,
        49.167062
    ],
    [
        87.2959329,
        49.165432
    ],
    [
        87.2945249,
        49.164462
    ],
    [
        87.2939759,
        49.164021
    ],
    [
        87.2931269,
        49.16316
    ],
    [
        87.2928399,
        49.162728
    ],
    [
        87.2923499,
        49.161439
    ],
    [
        87.2918949,
        49.159174
    ],
    [
        87.2916789,
        49.158518
    ],
    [
        87.2910399,
        49.157286
    ],
    [
        87.2906779,
        49.156729
    ],
    [
        87.2899649,
        49.155999
    ],
    [
        87.2907589,
        49.155277
    ],
    [
        87.2942579,
        49.153244
    ],
    [
        87.2954049,
        49.152498
    ],
    [
        87.2959609,
        49.152029
    ],
    [
        87.2965149,
        49.151663
    ],
    [
        87.2977059,
        49.150624
    ],
    [
        87.2986679,
        49.149913
    ],
    [
        87.2991729,
        49.149459
    ],
    [
        87.2994129,
        49.149129
    ],
    [
        87.2994589,
        49.148658
    ],
    [
        87.2993299,
        49.148135
    ],
    [
        87.2990289,
        49.147356
    ],
    [
        87.2976539,
        49.145043
    ],
    [
        87.2965529,
        49.143993
    ],
    [
        87.2960109,
        49.143583
    ],
    [
        87.2951809,
        49.142763
    ],
    [
        87.2948759,
        49.142264
    ],
    [
        87.2943269,
        49.141013
    ],
    [
        87.2940569,
        49.139913
    ],
    [
        87.2941349,
        49.139432
    ],
    [
        87.2944949,
        49.138937
    ],
    [
        87.2950429,
        49.138503
    ],
    [
        87.2964739,
        49.137571
    ],
    [
        87.2968919,
        49.135694
    ],
    [
        87.2972309,
        49.133679
    ],
    [
        87.2970419,
        49.132452
    ],
    [
        87.2967659,
        49.131834
    ],
    [
        87.2962789,
        49.131085
    ],
    [
        87.2959689,
        49.130281
    ],
    [
        87.2960617,
        49.130044
    ],
    [
        87.2971969,
        49.1271417
    ],
    [
        87.2937029,
        49.1238876
    ],
    [
        87.2903199,
        49.1210925
    ],
    [
        87.2883359,
        49.1189934
    ],
    [
        87.2877449,
        49.1179584
    ],
    [
        87.2875789,
        49.1172944
    ],
    [
        87.2875279,
        49.1167973
    ],
    [
        87.2876049,
        49.1156513
    ],
    [
        87.2878779,
        49.1144423
    ],
    [
        87.2878499,
        49.1140742
    ],
    [
        87.2835929,
        49.116077
    ],
    [
        87.2814349,
        49.117057
    ],
    [
        87.2787289,
        49.117763
    ],
    [
        87.2774999,
        49.117732
    ],
    [
        87.2769449,
        49.117631
    ],
    [
        87.2767689,
        49.117416
    ],
    [
        87.2763349,
        49.117131
    ],
    [
        87.2761169,
        49.11673
    ],
    [
        87.2756509,
        49.116285
    ],
    [
        87.2752139,
        49.115553
    ],
    [
        87.2741149,
        49.114608
    ],
    [
        87.2726889,
        49.114139
    ],
    [
        87.2723639,
        49.113887
    ],
    [
        87.2720439,
        49.113807
    ],
    [
        87.2717619,
        49.113825
    ],
    [
        87.2713119,
        49.114046
    ],
    [
        87.2699819,
        49.114184
    ],
    [
        87.2694369,
        49.114344
    ],
    [
        87.2682029,
        49.114341
    ],
    [
        87.2658749,
        49.114488
    ],
    [
        87.2646989,
        49.114422
    ],
    [
        87.2628019,
        49.114573
    ],
    [
        87.2615899,
        49.114885
    ],
    [
        87.2608229,
        49.114909
    ],
    [
        87.2599929,
        49.114839
    ],
    [
        87.2590349,
        49.114627
    ],
    [
        87.2586869,
        49.114152
    ],
    [
        87.2575399,
        49.113619
    ],
    [
        87.2552649,
        49.112443
    ],
    [
        87.2540719,
        49.112014
    ],
    [
        87.2535239,
        49.11171
    ],
    [
        87.2521889,
        49.112248
    ],
    [
        87.2506779,
        49.112456
    ],
    [
        87.2500019,
        49.112456
    ],
    [
        87.2490759,
        49.112696
    ],
    [
        87.2482199,
        49.112705
    ],
    [
        87.2474639,
        49.112811
    ],
    [
        87.2445459,
        49.112951
    ],
    [
        87.2430249,
        49.112964
    ],
    [
        87.2418529,
        49.113066
    ],
    [
        87.2410259,
        49.113041
    ],
    [
        87.2399639,
        49.112766
    ],
    [
        87.2395809,
        49.112811
    ],
    [
        87.2391379,
        49.112736
    ],
    [
        87.2387849,
        49.112809
    ],
    [
        87.2379459,
        49.112872
    ],
    [
        87.2376519,
        49.112789
    ],
    [
        87.2370499,
        49.112954
    ],
    [
        87.2365099,
        49.113225
    ],
    [
        87.2352329,
        49.11375
    ],
    [
        87.2326849,
        49.114261
    ],
    [
        87.2319549,
        49.114475
    ],
    [
        87.2314479,
        49.11451
    ],
    [
        87.2308789,
        49.114471
    ],
    [
        87.2306649,
        49.114375
    ],
    [
        87.2303029,
        49.1144
    ],
    [
        87.2297549,
        49.114684
    ],
    [
        87.2289729,
        49.114916
    ],
    [
        87.2279949,
        49.1153
    ],
    [
        87.2278269,
        49.116316
    ],
    [
        87.2277239,
        49.117985
    ],
    [
        87.2273719,
        49.119458
    ],
    [
        87.2269499,
        49.119592
    ],
    [
        87.2262929,
        49.119665
    ],
    [
        87.2256419,
        49.11969
    ],
    [
        87.2249279,
        49.119603
    ],
    [
        87.2243369,
        49.119588
    ],
    [
        87.2236139,
        49.119676
    ],
    [
        87.2221119,
        49.120027
    ],
    [
        87.2219439,
        49.120126
    ],
    [
        87.2205509,
        49.120434
    ],
    [
        87.2197039,
        49.120758
    ],
    [
        87.2187059,
        49.121073
    ],
    [
        87.2183999,
        49.121089
    ],
    [
        87.2175279,
        49.121473
    ],
    [
        87.2160959,
        49.121835
    ],
    [
        87.2155619,
        49.122031
    ],
    [
        87.2150739,
        49.12215
    ],
    [
        87.2141169,
        49.122464
    ],
    [
        87.2135459,
        49.122777
    ],
    [
        87.2135669,
        49.123266
    ],
    [
        87.2136939,
        49.123499
    ],
    [
        87.2140259,
        49.123828
    ],
    [
        87.2141269,
        49.12409
    ],
    [
        87.2141979,
        49.124902
    ],
    [
        87.2144879,
        49.126253
    ],
    [
        87.2153819,
        49.127904
    ],
    [
        87.2158339,
        49.12835
    ],
    [
        87.2156419,
        49.129316
    ],
    [
        87.2153709,
        49.129509
    ],
    [
        87.2149859,
        49.130341
    ],
    [
        87.2147199,
        49.130468
    ],
    [
        87.2144909,
        49.130815
    ],
    [
        87.2137229,
        49.131399
    ],
    [
        87.2136059,
        49.131615
    ],
    [
        87.2136219,
        49.131923
    ],
    [
        87.2132469,
        49.132841
    ],
    [
        87.2130369,
        49.133005
    ],
    [
        87.2125599,
        49.133314
    ],
    [
        87.2121359,
        49.133428
    ],
    [
        87.2117969,
        49.13358
    ],
    [
        87.2115909,
        49.133788
    ],
    [
        87.2112129,
        49.134016
    ],
    [
        87.2104019,
        49.13431
    ],
    [
        87.2086109,
        49.135219
    ],
    [
        87.2082669,
        49.135255
    ],
    [
        87.2073949,
        49.135678
    ],
    [
        87.2072669,
        49.135882
    ],
    [
        87.2072419,
        49.136193
    ],
    [
        87.2073259,
        49.136771
    ],
    [
        87.2070699,
        49.137122
    ],
    [
        87.2071599,
        49.137221
    ],
    [
        87.2069639,
        49.137428
    ],
    [
        87.2067389,
        49.137475
    ],
    [
        87.2059039,
        49.138345
    ],
    [
        87.2051549,
        49.138771
    ],
    [
        87.2042569,
        49.139128
    ],
    [
        87.2038259,
        49.139479
    ],
    [
        87.2035319,
        49.139609
    ],
    [
        87.2037249,
        49.13992
    ],
    [
        87.2038079,
        49.140323
    ],
    [
        87.2039229,
        49.140365
    ],
    [
        87.2041049,
        49.140584
    ],
    [
        87.2041159,
        49.140752
    ],
    [
        87.2040229,
        49.141015
    ],
    [
        87.2037229,
        49.141247
    ],
    [
        87.2029639,
        49.141567
    ],
    [
        87.2018949,
        49.14186
    ],
    [
        87.2013119,
        49.142323
    ],
    [
        87.2000039,
        49.142945
    ],
    [
        87.1991659,
        49.143187
    ],
    [
        87.1979609,
        49.14315
    ],
    [
        87.1975159,
        49.142879
    ],
    [
        87.1965629,
        49.142732
    ],
    [
        87.1958679,
        49.142576
    ],
    [
        87.1953079,
        49.142353
    ],
    [
        87.1948159,
        49.142318
    ],
    [
        87.1932959,
        49.141927
    ],
    [
        87.1924589,
        49.141885
    ],
    [
        87.1915469,
        49.141921
    ],
    [
        87.1907929,
        49.142154
    ],
    [
        87.1898979,
        49.14228
    ],
    [
        87.1891389,
        49.142265
    ],
    [
        87.1879449,
        49.141385
    ],
    [
        87.1872369,
        49.140709
    ],
    [
        87.1862559,
        49.1401
    ],
    [
        87.1849529,
        49.139481
    ],
    [
        87.1845339,
        49.139018
    ],
    [
        87.1829099,
        49.138897
    ],
    [
        87.1815789,
        49.139073
    ],
    [
        87.1790189,
        49.138963
    ],
    [
        87.1760799,
        49.139448
    ],
    [
        87.1753559,
        49.139511
    ],
    [
        87.1746569,
        49.139631
    ],
    [
        87.1741069,
        49.13982
    ],
    [
        87.1734059,
        49.13976
    ],
    [
        87.1731359,
        49.139545
    ],
    [
        87.1720929,
        49.139563
    ],
    [
        87.1716259,
        49.139483
    ],
    [
        87.1711689,
        49.13931
    ],
    [
        87.1710089,
        49.139113
    ],
    [
        87.1707199,
        49.139009
    ],
    [
        87.1700129,
        49.138909
    ],
    [
        87.1692189,
        49.138947
    ],
    [
        87.1686709,
        49.138877
    ],
    [
        87.1677859,
        49.139134
    ],
    [
        87.1668149,
        49.139519
    ],
    [
        87.1665429,
        49.139698
    ],
    [
        87.1658569,
        49.139923
    ],
    [
        87.1653469,
        49.140207
    ],
    [
        87.1647769,
        49.140271
    ],
    [
        87.1641339,
        49.140846
    ],
    [
        87.1627549,
        49.141419
    ],
    [
        87.1617039,
        49.141673
    ],
    [
        87.1610769,
        49.141902
    ],
    [
        87.1593249,
        49.142371
    ],
    [
        87.1587269,
        49.142829
    ],
    [
        87.1576079,
        49.143235
    ],
    [
        87.1565689,
        49.143724
    ],
    [
        87.1555819,
        49.144107
    ],
    [
        87.1553669,
        49.144276
    ],
    [
        87.1541939,
        49.144753
    ],
    [
        87.1532629,
        49.14521
    ],
    [
        87.1526839,
        49.145611
    ],
    [
        87.1523209,
        49.146101
    ],
    [
        87.1523999,
        49.14642
    ],
    [
        87.1521679,
        49.14657
    ],
    [
        87.1518429,
        49.147656
    ],
    [
        87.1514609,
        49.148384
    ],
    [
        87.1510099,
        49.149024
    ],
    [
        87.1511349,
        49.149361
    ],
    [
        87.1509099,
        49.149427
    ],
    [
        87.1507919,
        49.149588
    ],
    [
        87.1509249,
        49.149758
    ],
    [
        87.1514329,
        49.150148
    ],
    [
        87.1515139,
        49.150749
    ],
    [
        87.1500279,
        49.150753
    ],
    [
        87.1477959,
        49.151317
    ],
    [
        87.1449129,
        49.151639
    ],
    [
        87.1434939,
        49.151966
    ],
    [
        87.1425089,
        49.152272
    ],
    [
        87.1418299,
        49.152374
    ],
    [
        87.1410999,
        49.152304
    ],
    [
        87.1404309,
        49.152049
    ],
    [
        87.1399209,
        49.151676
    ],
    [
        87.1395809,
        49.150952
    ],
    [
        87.1384889,
        49.15077
    ],
    [
        87.1359519,
        49.150801
    ],
    [
        87.1343839,
        49.150883
    ],
    [
        87.1328569,
        49.151064
    ],
    [
        87.1319359,
        49.151032
    ],
    [
        87.1303139,
        49.15118
    ],
    [
        87.1287369,
        49.151537
    ],
    [
        87.1274329,
        49.152018
    ],
    [
        87.1264849,
        49.152182
    ],
    [
        87.1252519,
        49.152641
    ],
    [
        87.1240179,
        49.152659
    ],
    [
        87.1202839,
        49.152438
    ],
    [
        87.1180569,
        49.152586
    ],
    [
        87.1162409,
        49.152496
    ],
    [
        87.1131749,
        49.152261
    ],
    [
        87.1095879,
        49.151617
    ],
    [
        87.1082709,
        49.15178
    ],
    [
        87.1059729,
        49.151452
    ],
    [
        87.1041369,
        49.151124
    ],
    [
        87.1034899,
        49.150112
    ],
    [
        87.1028069,
        49.149544
    ],
    [
        87.1013479,
        49.148674
    ],
    [
        87.1003199,
        49.147541
    ],
    [
        87.1000719,
        49.146811
    ],
    [
        87.1001559,
        49.145459
    ],
    [
        87.0999379,
        49.144685
    ],
    [
        87.1000949,
        49.143336
    ],
    [
        87.1005419,
        49.141347
    ],
    [
        87.1010279,
        49.140729
    ],
    [
        87.1009539,
        49.140353
    ],
    [
        87.1005749,
        49.140051
    ],
    [
        87.0978939,
        49.139226
    ],
    [
        87.0967899,
        49.13895
    ],
    [
        87.0957099,
        49.138964
    ],
    [
        87.0947849,
        49.138485
    ],
    [
        87.0938149,
        49.138726
    ],
    [
        87.0926959,
        49.138345
    ],
    [
        87.0897289,
        49.138035
    ],
    [
        87.0882839,
        49.137171
    ],
    [
        87.0870259,
        49.136305
    ],
    [
        87.0868139,
        49.135894
    ],
    [
        87.0855529,
        49.134937
    ],
    [
        87.0844589,
        49.133795
    ],
    [
        87.0838509,
        49.132738
    ],
    [
        87.0826209,
        49.131518
    ],
    [
        87.0824739,
        49.131059
    ],
    [
        87.0816109,
        49.131013
    ],
    [
        87.0810059,
        49.130878
    ],
    [
        87.0793829,
        49.131017
    ],
    [
        87.0784469,
        49.131276
    ],
    [
        87.0769079,
        49.131349
    ],
    [
        87.0757629,
        49.131734
    ],
    [
        87.0747889,
        49.132325
    ],
    [
        87.0739279,
        49.132218
    ],
    [
        87.0717143,
        49.1347499
    ],
    [
        87.0671652,
        49.1353957
    ],
    [
        87.0650195,
        49.1342164
    ],
    [
        87.0629442,
        49.1323571
    ],
    [
        87.06223,
        49.1317173
    ],
    [
        87.0598349,
        49.12992
    ],
    [
        87.0594849,
        49.129906
    ],
    [
        87.0567829,
        49.129459
    ],
    [
        87.0563369,
        49.129263
    ],
    [
        87.0554719,
        49.129421
    ],
    [
        87.0544439,
        49.129733
    ],
    [
        87.0536419,
        49.129838
    ],
    [
        87.0529049,
        49.12999
    ],
    [
        87.0525119,
        49.130168
    ],
    [
        87.0518549,
        49.130269
    ],
    [
        87.0512169,
        49.130247
    ],
    [
        87.0507509,
        49.130305
    ],
    [
        87.0505969,
        49.130146
    ],
    [
        87.0502569,
        49.130063
    ],
    [
        87.0498239,
        49.130054
    ],
    [
        87.0486119,
        49.13052
    ],
    [
        87.0483899,
        49.130645
    ],
    [
        87.0479379,
        49.130637
    ],
    [
        87.0473029,
        49.130815
    ],
    [
        87.0467019,
        49.130869
    ],
    [
        87.0464399,
        49.130933
    ],
    [
        87.0451249,
        49.130957
    ],
    [
        87.0448849,
        49.131143
    ],
    [
        87.0446039,
        49.131195
    ],
    [
        87.0443199,
        49.131124
    ],
    [
        87.0437649,
        49.13122
    ],
    [
        87.0432779,
        49.131348
    ],
    [
        87.0427009,
        49.131611
    ],
    [
        87.0424079,
        49.132019
    ],
    [
        87.0413399,
        49.132176
    ],
    [
        87.0407079,
        49.132502
    ],
    [
        87.0404829,
        49.132528
    ],
    [
        87.0402989,
        49.132702
    ],
    [
        87.0400539,
        49.132716
    ],
    [
        87.0398509,
        49.132878
    ],
    [
        87.0396429,
        49.132843
    ],
    [
        87.0380609,
        49.133619
    ],
    [
        87.0376139,
        49.133783
    ],
    [
        87.0372059,
        49.134057
    ],
    [
        87.0359689,
        49.134253
    ],
    [
        87.0357839,
        49.134426
    ],
    [
        87.0355049,
        49.134527
    ],
    [
        87.0353389,
        49.134701
    ],
    [
        87.0351149,
        49.134764
    ],
    [
        87.0346889,
        49.1351
    ],
    [
        87.0338849,
        49.135292
    ],
    [
        87.0330779,
        49.135372
    ],
    [
        87.0329309,
        49.135534
    ],
    [
        87.0323169,
        49.135643
    ],
    [
        87.0320189,
        49.135844
    ],
    [
        87.0315289,
        49.135787
    ],
    [
        87.0306969,
        49.136053
    ],
    [
        87.0299699,
        49.1358
    ],
    [
        87.0297169,
        49.13594
    ],
    [
        87.0295369,
        49.136261
    ],
    [
        87.0292609,
        49.136431
    ],
    [
        87.0288219,
        49.136572
    ],
    [
        87.0285429,
        49.136589
    ],
    [
        87.0278319,
        49.137113
    ],
    [
        87.0276009,
        49.137207
    ],
    [
        87.0271579,
        49.13718
    ],
    [
        87.0267589,
        49.137001
    ],
    [
        87.0264339,
        49.137064
    ],
    [
        87.0262269,
        49.137158
    ],
    [
        87.0259239,
        49.13713
    ],
    [
        87.0252109,
        49.137547
    ],
    [
        87.0247009,
        49.137673
    ],
    [
        87.0241709,
        49.137921
    ],
    [
        87.0236599,
        49.138017
    ],
    [
        87.0232939,
        49.138294
    ],
    [
        87.0226659,
        49.138315
    ],
    [
        87.0220649,
        49.138564
    ],
    [
        87.0209769,
        49.138817
    ],
    [
        87.0207959,
        49.139077
    ],
    [
        87.0203879,
        49.139146
    ],
    [
        87.0199679,
        49.139074
    ],
    [
        87.0191569,
        49.139278
    ],
    [
        87.0187219,
        49.139648
    ],
    [
        87.0183059,
        49.139773
    ],
    [
        87.017523,
        49.1398836
    ],
    [
        87.0140579,
        49.140373
    ],
    [
        87.0129819,
        49.140324
    ],
    [
        87.0124709,
        49.140404
    ],
    [
        87.0101909,
        49.140469
    ],
    [
        87.0097309,
        49.140747
    ],
    [
        87.0089439,
        49.140967
    ],
    [
        87.0083869,
        49.141009
    ],
    [
        87.0078249,
        49.140846
    ],
    [
        87.0069869,
        49.140853
    ],
    [
        87.0065239,
        49.140948
    ],
    [
        87.0060389,
        49.14115
    ],
    [
        87.0057639,
        49.141381
    ],
    [
        87.0056459,
        49.141631
    ],
    [
        87.0048629,
        49.142033
    ],
    [
        87.0042599,
        49.142176
    ],
    [
        87.0039809,
        49.142163
    ],
    [
        87.0037729,
        49.142256
    ],
    [
        87.0035399,
        49.142243
    ],
    [
        87.0034429,
        49.142045
    ],
    [
        87.0016289,
        49.142152
    ],
    [
        87.0009629,
        49.142462
    ],
    [
        87.0000579,
        49.142606
    ],
    [
        86.9993149,
        49.142674
    ],
    [
        86.9965209,
        49.142682
    ],
    [
        86.9960549,
        49.14264
    ],
    [
        86.9944849,
        49.142075
    ],
    [
        86.9918959,
        49.141837
    ],
    [
        86.9911249,
        49.141707
    ],
    [
        86.9895309,
        49.141111
    ],
    [
        86.9893819,
        49.14064
    ],
    [
        86.9886289,
        49.14022
    ],
    [
        86.9883489,
        49.140176
    ],
    [
        86.9872219,
        49.140171
    ],
    [
        86.9866659,
        49.140282
    ],
    [
        86.9860379,
        49.140287
    ],
    [
        86.9853579,
        49.140049
    ],
    [
        86.9849789,
        49.139748
    ],
    [
        86.9842539,
        49.139556
    ],
    [
        86.9838289,
        49.139224
    ],
    [
        86.9838249,
        49.138996
    ],
    [
        86.9833779,
        49.138786
    ],
    [
        86.9830239,
        49.138546
    ],
    [
        86.9822329,
        49.138537
    ],
    [
        86.9816489,
        49.138466
    ],
    [
        86.9812039,
        49.138271
    ],
    [
        86.9808939,
        49.137893
    ],
    [
        86.9805669,
        49.13782
    ],
    [
        86.9797689,
        49.137491
    ],
    [
        86.9786229,
        49.137227
    ],
    [
        86.9782009,
        49.137063
    ],
    [
        86.9771969,
        49.136888
    ],
    [
        86.9761659,
        49.136531
    ],
    [
        86.9755979,
        49.136033
    ],
    [
        86.9754529,
        49.135745
    ],
    [
        86.9751239,
        49.135596
    ],
    [
        86.9743469,
        49.135099
    ],
    [
        86.9736329,
        49.135049
    ],
    [
        86.9725359,
        49.134612
    ],
    [
        86.9720269,
        49.134639
    ],
    [
        86.9712039,
        49.134356
    ],
    [
        86.9711339,
        49.134201
    ],
    [
        86.9700079,
        49.134032
    ],
    [
        86.9697659,
        49.133811
    ],
    [
        86.9688059,
        49.133441
    ],
    [
        86.9675799,
        49.133384
    ],
    [
        86.9656879,
        49.133902
    ],
    [
        86.9648099,
        49.134037
    ],
    [
        86.9646099,
        49.134042
    ],
    [
        86.9640939,
        49.133943
    ],
    [
        86.9638379,
        49.134001
    ],
    [
        86.9637579,
        49.134064
    ],
    [
        86.9635059,
        49.134094
    ],
    [
        86.9633769,
        49.134058
    ],
    [
        86.9633149,
        49.133968
    ],
    [
        86.9628789,
        49.13385
    ],
    [
        86.9627409,
        49.13382
    ],
    [
        86.9622709,
        49.133902
    ],
    [
        86.9618649,
        49.13379
    ],
    [
        86.9617479,
        49.133691
    ],
    [
        86.9613849,
        49.133619
    ],
    [
        86.9611629,
        49.133444
    ],
    [
        86.9609319,
        49.13281
    ],
    [
        86.9608539,
        49.132711
    ],
    [
        86.9607049,
        49.132659
    ],
    [
        86.9601289,
        49.133106
    ],
    [
        86.9599309,
        49.133177
    ],
    [
        86.9592699,
        49.133256
    ],
    [
        86.9589749,
        49.133375
    ],
    [
        86.9588359,
        49.133385
    ],
    [
        86.9583099,
        49.13325
    ],
    [
        86.9580239,
        49.133244
    ],
    [
        86.9575919,
        49.133156
    ],
    [
        86.9569299,
        49.133145
    ],
    [
        86.9566649,
        49.133033
    ],
    [
        86.9564019,
        49.133048
    ],
    [
        86.9562319,
        49.133005
    ],
    [
        86.9558079,
        49.132717
    ],
    [
        86.9552329,
        49.132634
    ],
    [
        86.9549019,
        49.132632
    ],
    [
        86.9547459,
        49.132549
    ],
    [
        86.9547099,
        49.132474
    ],
    [
        86.9547319,
        49.132192
    ],
    [
        86.9546829,
        49.132117
    ],
    [
        86.9544999,
        49.132039
    ],
    [
        86.9543339,
        49.131785
    ],
    [
        86.9540189,
        49.131518
    ],
    [
        86.9538009,
        49.131418
    ],
    [
        86.9535669,
        49.131155
    ],
    [
        86.9533109,
        49.131197
    ],
    [
        86.9529109,
        49.131068
    ],
    [
        86.9524639,
        49.131023
    ],
    [
        86.9520939,
        49.131066
    ],
    [
        86.9519259,
        49.131111
    ],
    [
        86.9511789,
        49.131563
    ],
    [
        86.9502859,
        49.131844
    ],
    [
        86.9500839,
        49.131868
    ],
    [
        86.9495779,
        49.131719
    ],
    [
        86.9491809,
        49.131427
    ],
    [
        86.9487749,
        49.13135
    ],
    [
        86.9480269,
        49.131405
    ],
    [
        86.9473309,
        49.131397
    ],
    [
        86.9470289,
        49.131461
    ],
    [
        86.9467089,
        49.131327
    ],
    [
        86.9465809,
        49.131332
    ],
    [
        86.9461029,
        49.131385
    ],
    [
        86.9457139,
        49.131489
    ],
    [
        86.9454909,
        49.131487
    ],
    [
        86.9450489,
        49.131162
    ],
    [
        86.9447569,
        49.131046
    ],
    [
        86.9445509,
        49.130884
    ],
    [
        86.9444139,
        49.130752
    ],
    [
        86.9443979,
        49.130616
    ],
    [
        86.9442069,
        49.130485
    ],
    [
        86.9439779,
        49.130164
    ],
    [
        86.9436919,
        49.130021
    ],
    [
        86.9434229,
        49.130085
    ],
    [
        86.9428769,
        49.130107
    ],
    [
        86.9429469,
        49.13023
    ],
    [
        86.9425789,
        49.130436
    ],
    [
        86.9423239,
        49.130848
    ],
    [
        86.9419859,
        49.131169
    ],
    [
        86.9414599,
        49.131383
    ],
    [
        86.9407139,
        49.131565
    ],
    [
        86.9396539,
        49.131498
    ],
    [
        86.9392599,
        49.131378
    ],
    [
        86.9381539,
        49.131404
    ],
    [
        86.9380309,
        49.131352
    ],
    [
        86.9379819,
        49.131251
    ],
    [
        86.9374799,
        49.130977
    ],
    [
        86.9372899,
        49.13089
    ],
    [
        86.9368899,
        49.130809
    ],
    [
        86.9363389,
        49.130946
    ],
    [
        86.9356889,
        49.131207
    ],
    [
        86.9355609,
        49.131208
    ],
    [
        86.9353309,
        49.130817
    ],
    [
        86.9351749,
        49.130774
    ],
    [
        86.9348929,
        49.130838
    ],
    [
        86.9347749,
        49.131082
    ],
    [
        86.9344809,
        49.131186
    ],
    [
        86.9340589,
        49.13115
    ],
    [
        86.9339249,
        49.131069
    ],
    [
        86.9335619,
        49.131048
    ],
    [
        86.9334239,
        49.131155
    ],
    [
        86.9331939,
        49.131549
    ],
    [
        86.9331279,
        49.131559
    ],
    [
        86.9326719,
        49.131263
    ],
    [
        86.9325609,
        49.131273
    ],
    [
        86.9322689,
        49.131435
    ],
    [
        86.9315659,
        49.131421
    ],
    [
        86.9311609,
        49.131468
    ],
    [
        86.9307529,
        49.131413
    ],
    [
        86.9305289,
        49.131299
    ],
    [
        86.9302509,
        49.131277
    ],
    [
        86.9300969,
        49.131365
    ],
    [
        86.9300269,
        49.131545
    ],
    [
        86.9298459,
        49.131754
    ],
    [
        86.9293939,
        49.131706
    ],
    [
        86.9292969,
        49.131654
    ],
    [
        86.9292519,
        49.131596
    ],
    [
        86.9293669,
        49.131455
    ],
    [
        86.9291939,
        49.131272
    ],
    [
        86.9289879,
        49.131288
    ],
    [
        86.9288169,
        49.131241
    ],
    [
        86.9283879,
        49.131264
    ],
    [
        86.9282489,
        49.131294
    ],
    [
        86.9281269,
        49.131542
    ],
    [
        86.9275969,
        49.131652
    ],
    [
        86.9275809,
        49.13201
    ],
    [
        86.9274939,
        49.13205
    ],
    [
        86.9272279,
        49.132076
    ],
    [
        86.9269869,
        49.131855
    ],
    [
        86.9267099,
        49.131993
    ],
    [
        86.9261909,
        49.132133
    ],
    [
        86.9260369,
        49.132245
    ],
    [
        86.9260109,
        49.132705
    ],
    [
        86.9257579,
        49.132917
    ],
    [
        86.9256279,
        49.132834
    ],
    [
        86.9252549,
        49.132761
    ],
    [
        86.9251379,
        49.132645
    ],
    [
        86.9252249,
        49.132501
    ],
    [
        86.9251329,
        49.132401
    ],
    [
        86.9247849,
        49.132303
    ],
    [
        86.9240809,
        49.132368
    ],
    [
        86.9238609,
        49.132235
    ],
    [
        86.9236969,
        49.132035
    ],
    [
        86.9233459,
        49.132123
    ],
    [
        86.9231079,
        49.132294
    ],
    [
        86.9227579,
        49.132431
    ],
    [
        86.9227049,
        49.132565
    ],
    [
        86.9228369,
        49.132685
    ],
    [
        86.9228589,
        49.132867
    ],
    [
        86.9224909,
        49.133015
    ],
    [
        86.9221579,
        49.133066
    ],
    [
        86.9218779,
        49.133008
    ],
    [
        86.9215779,
        49.132792
    ],
    [
        86.9213549,
        49.132769
    ],
    [
        86.9211899,
        49.13288
    ],
    [
        86.9208739,
        49.132858
    ],
    [
        86.9206709,
        49.13292
    ],
    [
        86.9203809,
        49.133286
    ],
    [
        86.9202529,
        49.13336
    ],
    [
        86.9199949,
        49.133325
    ],
    [
        86.9199449,
        49.133086
    ],
    [
        86.9196509,
        49.133268
    ],
    [
        86.9194729,
        49.133569
    ],
    [
        86.9192239,
        49.13375
    ],
    [
        86.9187189,
        49.133679
    ],
    [
        86.9183299,
        49.133712
    ],
    [
        86.9181509,
        49.133924
    ],
    [
        86.9178779,
        49.134016
    ],
    [
        86.9172439,
        49.134096
    ],
    [
        86.9167889,
        49.134071
    ],
    [
        86.9163919,
        49.134026
    ],
    [
        86.9162429,
        49.133912
    ],
    [
        86.9160809,
        49.133894
    ],
    [
        86.9158919,
        49.133981
    ],
    [
        86.9157179,
        49.134273
    ],
    [
        86.9154119,
        49.134381
    ],
    [
        86.9152639,
        49.134315
    ],
    [
        86.9150699,
        49.134125
    ],
    [
        86.9145669,
        49.133927
    ],
    [
        86.9142439,
        49.133901
    ],
    [
        86.9141139,
        49.133988
    ],
    [
        86.9139729,
        49.134306
    ],
    [
        86.9136659,
        49.134376
    ],
    [
        86.9134889,
        49.134348
    ],
    [
        86.9133669,
        49.134071
    ],
    [
        86.9132169,
        49.133899
    ],
    [
        86.9129379,
        49.133882
    ],
    [
        86.9125159,
        49.134087
    ],
    [
        86.9119159,
        49.134178
    ],
    [
        86.9115989,
        49.134488
    ],
    [
        86.9113799,
        49.134556
    ],
    [
        86.9109039,
        49.134195
    ],
    [
        86.9107139,
        49.134235
    ],
    [
        86.9105119,
        49.134419
    ],
    [
        86.9097209,
        49.13455
    ],
    [
        86.9095009,
        49.134532
    ],
    [
        86.9092629,
        49.134352
    ],
    [
        86.9090269,
        49.134296
    ],
    [
        86.9088279,
        49.134313
    ],
    [
        86.9081269,
        49.13451
    ],
    [
        86.9079239,
        49.134637
    ],
    [
        86.9075119,
        49.135427
    ],
    [
        86.9068569,
        49.135701
    ],
    [
        86.9065459,
        49.135588
    ],
    [
        86.9065879,
        49.135482
    ],
    [
        86.9070399,
        49.135296
    ],
    [
        86.9070389,
        49.13521
    ],
    [
        86.9069049,
        49.135134
    ],
    [
        86.9067159,
        49.135231
    ],
    [
        86.9058659,
        49.135276
    ],
    [
        86.9055399,
        49.135115
    ],
    [
        86.9052319,
        49.135118
    ],
    [
        86.9049839,
        49.135187
    ],
    [
        86.9046749,
        49.135122
    ],
    [
        86.9042469,
        49.135001
    ],
    [
        86.9039779,
        49.134753
    ],
    [
        86.9039009,
        49.134552
    ],
    [
        86.9036639,
        49.13439
    ],
    [
        86.9030039,
        49.134443
    ],
    [
        86.9027119,
        49.134522
    ],
    [
        86.9023889,
        49.134525
    ],
    [
        86.9020639,
        49.134393
    ],
    [
        86.9016379,
        49.134348
    ],
    [
        86.9010509,
        49.134404
    ],
    [
        86.9007439,
        49.1347
    ],
    [
        86.9005299,
        49.134701
    ],
    [
        86.9001189,
        49.13459
    ],
    [
        86.8998829,
        49.134426
    ],
    [
        86.8995889,
        49.134352
    ],
    [
        86.8992359,
        49.134176
    ],
    [
        86.8986329,
        49.134232
    ],
    [
        86.8977259,
        49.134697
    ],
    [
        86.8973969,
        49.134776
    ],
    [
        86.8966969,
        49.134858
    ],
    [
        86.8963069,
        49.134822
    ],
    [
        86.8954269,
        49.134587
    ],
    [
        86.8948779,
        49.134387
    ],
    [
        86.8940319,
        49.133884
    ],
    [
        86.8935409,
        49.133652
    ],
    [
        86.8932309,
        49.133757
    ],
    [
        86.8929389,
        49.133797
    ],
    [
        86.8925489,
        49.133724
    ],
    [
        86.8919979,
        49.133384
    ],
    [
        86.8918189,
        49.133169
    ],
    [
        86.8916429,
        49.133132
    ],
    [
        86.8913659,
        49.132904
    ],
    [
        86.8905259,
        49.132745
    ],
    [
        86.8902739,
        49.132785
    ],
    [
        86.8900229,
        49.132902
    ],
    [
        86.8898279,
        49.132878
    ],
    [
        86.8895719,
        49.132739
    ],
    [
        86.8893779,
        49.132741
    ],
    [
        86.8885559,
        49.13253
    ],
    [
        86.8878359,
        49.132548
    ],
    [
        86.8873699,
        49.132616
    ],
    [
        86.8871549,
        49.132579
    ],
    [
        86.8868189,
        49.132301
    ],
    [
        86.8866049,
        49.132303
    ],
    [
        86.8864859,
        49.132202
    ],
    [
        86.8862519,
        49.132165
    ],
    [
        86.8857029,
        49.131915
    ],
    [
        86.8852139,
        49.131791
    ],
    [
        86.8848579,
        49.1315
    ],
    [
        86.8845999,
        49.131197
    ],
    [
        86.8841379,
        49.130385
    ],
    [
        86.8841359,
        49.130232
    ],
    [
        86.8839589,
        49.130144
    ],
    [
        86.8836839,
        49.130019
    ],
    [
        86.8829599,
        49.12982
    ],
    [
        86.8823959,
        49.129824
    ],
    [
        86.8821529,
        49.129758
    ],
    [
        86.8816819,
        49.129558
    ],
    [
        86.8814649,
        49.129394
    ],
    [
        86.8800549,
        49.128895
    ],
    [
        86.8797639,
        49.12896
    ],
    [
        86.8793929,
        49.128887
    ],
    [
        86.8792729,
        49.128709
    ],
    [
        86.8789989,
        49.128647
    ],
    [
        86.8787039,
        49.128484
    ],
    [
        86.8780129,
        49.127941
    ],
    [
        86.8778289,
        49.127433
    ],
    [
        86.8775929,
        49.127256
    ],
    [
        86.8776479,
        49.127078
    ],
    [
        86.8772149,
        49.126762
    ],
    [
        86.8772309,
        49.126571
    ],
    [
        86.8769729,
        49.12628
    ],
    [
        86.8762889,
        49.12617
    ],
    [
        86.8754019,
        49.125502
    ],
    [
        86.8752999,
        49.12526
    ],
    [
        86.8753649,
        49.124508
    ],
    [
        86.8752459,
        49.124369
    ],
    [
        86.8751989,
        49.12386
    ],
    [
        86.8747659,
        49.123621
    ],
    [
        86.8747019,
        49.123277
    ],
    [
        86.8752399,
        49.122866
    ],
    [
        86.8753529,
        49.122674
    ],
    [
        86.8755269,
        49.122596
    ],
    [
        86.8756779,
        49.122417
    ],
    [
        86.8754569,
        49.121799
    ],
    [
        86.8747739,
        49.12077
    ],
    [
        86.8745289,
        49.120522
    ],
    [
        86.8742999,
        49.120424
    ],
    [
        86.8736839,
        49.120393
    ],
    [
        86.8725989,
        49.120515
    ],
    [
        86.8719249,
        49.120508
    ],
    [
        86.8713429,
        49.12002
    ],
    [
        86.8709119,
        49.119839
    ],
    [
        86.8697309,
        49.119931
    ],
    [
        86.8689099,
        49.119786
    ],
    [
        86.8681029,
        49.119447
    ],
    [
        86.8675349,
        49.11902
    ],
    [
        86.8672469,
        49.118877
    ],
    [
        86.8666379,
        49.118698
    ],
    [
        86.8663289,
        49.118648
    ],
    [
        86.8660099,
        49.118652
    ],
    [
        86.8654619,
        49.118519
    ],
    [
        86.8646389,
        49.118282
    ],
    [
        86.8641099,
        49.118002
    ],
    [
        86.8636919,
        49.117875
    ],
    [
        86.8625359,
        49.117704
    ],
    [
        86.8623069,
        49.11756
    ],
    [
        86.8619639,
        49.117162
    ],
    [
        86.8614459,
        49.116835
    ],
    [
        86.8608229,
        49.116154
    ],
    [
        86.8606849,
        49.1159
    ],
    [
        86.8606829,
        49.115396
    ],
    [
        86.8605839,
        49.115243
    ],
    [
        86.8598719,
        49.114764
    ],
    [
        86.8591629,
        49.114401
    ],
    [
        86.8588339,
        49.114064
    ],
    [
        86.8586259,
        49.113639
    ],
    [
        86.8586619,
        49.113251
    ],
    [
        86.8582929,
        49.112745
    ],
    [
        86.8578569,
        49.112425
    ],
    [
        86.8572089,
        49.112085
    ],
    [
        86.8555429,
        49.111338
    ],
    [
        86.8550669,
        49.111228
    ],
    [
        86.8540219,
        49.111078
    ],
    [
        86.8537209,
        49.110904
    ],
    [
        86.8531749,
        49.110745
    ],
    [
        86.8528269,
        49.110571
    ],
    [
        86.8527489,
        49.110348
    ],
    [
        86.8519979,
        49.109706
    ],
    [
        86.8516459,
        49.108986
    ],
    [
        86.8507599,
        49.108184
    ],
    [
        86.8505229,
        49.107784
    ],
    [
        86.8503389,
        49.107128
    ],
    [
        86.8500709,
        49.106837
    ],
    [
        86.8496969,
        49.106586
    ],
    [
        86.8490989,
        49.106353
    ],
    [
        86.8489619,
        49.106131
    ],
    [
        86.8489529,
        49.105403
    ],
    [
        86.8488669,
        49.10528
    ],
    [
        86.8485279,
        49.105128
    ],
    [
        86.8481189,
        49.104625
    ],
    [
        86.8478499,
        49.104396
    ],
    [
        86.8465329,
        49.103878
    ],
    [
        86.8460619,
        49.103514
    ],
    [
        86.8460909,
        49.103126
    ],
    [
        86.8462259,
        49.102789
    ],
    [
        86.8461269,
        49.102557
    ],
    [
        86.8462529,
        49.102366
    ],
    [
        86.8462869,
        49.102185
    ],
    [
        86.8459739,
        49.101784
    ],
    [
        86.8455849,
        49.101531
    ],
    [
        86.8454089,
        49.101343
    ],
    [
        86.8446509,
        49.101051
    ],
    [
        86.8443609,
        49.101038
    ],
    [
        86.8438719,
        49.100909
    ],
    [
        86.8435349,
        49.100629
    ],
    [
        86.8434619,
        49.10038
    ],
    [
        86.8435319,
        49.100035
    ],
    [
        86.8434759,
        49.099484
    ],
    [
        86.8443399,
        49.098423
    ],
    [
        86.8443739,
        49.098225
    ],
    [
        86.8442969,
        49.097813
    ],
    [
        86.8444169,
        49.097406
    ],
    [
        86.8443199,
        49.097235
    ],
    [
        86.8437439,
        49.096846
    ],
    [
        86.8426929,
        49.096357
    ],
    [
        86.8425549,
        49.096135
    ],
    [
        86.8422819,
        49.095311
    ],
    [
        86.8423989,
        49.094811
    ],
    [
        86.8423949,
        49.093734
    ],
    [
        86.8421339,
        49.093359
    ],
    [
        86.8420859,
        49.093067
    ],
    [
        86.8426149,
        49.092208
    ],
    [
        86.8425729,
        49.091816
    ],
    [
        86.8427319,
        49.091441
    ],
    [
        86.8429439,
        49.090391
    ],
    [
        86.8430359,
        49.090191
    ],
    [
        86.8432109,
        49.089951
    ],
    [
        86.8440539,
        49.089354
    ],
    [
        86.8440849,
        49.089163
    ],
    [
        86.8440089,
        49.089029
    ],
    [
        86.8435919,
        49.088859
    ],
    [
        86.8429929,
        49.088272
    ],
    [
        86.8423179,
        49.08797
    ],
    [
        86.8421559,
        49.087806
    ],
    [
        86.8421109,
        49.08757
    ],
    [
        86.8422299,
        49.087069
    ],
    [
        86.8427309,
        49.085928
    ],
    [
        86.8432189,
        49.085192
    ],
    [
        86.8432469,
        49.084898
    ],
    [
        86.8431549,
        49.084637
    ],
    [
        86.8429329,
        49.084325
    ],
    [
        86.8430339,
        49.084046
    ],
    [
        86.8430039,
        49.083856
    ],
    [
        86.8427299,
        49.083558
    ],
    [
        86.8428079,
        49.083334
    ],
    [
        86.8432109,
        49.08302
    ],
    [
        86.8437859,
        49.08237
    ],
    [
        86.8438359,
        49.081989
    ],
    [
        86.8441179,
        49.081232
    ],
    [
        86.8441089,
        49.080922
    ],
    [
        86.8436509,
        49.080571
    ],
    [
        86.8438239,
        49.080081
    ],
    [
        86.8441139,
        49.079633
    ],
    [
        86.8443749,
        49.079424
    ],
    [
        86.8445499,
        49.079175
    ],
    [
        86.8445289,
        49.07889
    ],
    [
        86.8442509,
        49.078481
    ],
    [
        86.8442769,
        49.078107
    ],
    [
        86.8444289,
        49.077923
    ],
    [
        86.8456569,
        49.077242
    ],
    [
        86.8458929,
        49.077001
    ],
    [
        86.8460269,
        49.076602
    ],
    [
        86.8459459,
        49.076318
    ],
    [
        86.8460839,
        49.076038
    ],
    [
        86.8460709,
        49.075727
    ],
    [
        86.8458529,
        49.075317
    ],
    [
        86.8457799,
        49.074882
    ],
    [
        86.8456119,
        49.074519
    ],
    [
        86.8454379,
        49.07433
    ],
    [
        86.8447849,
        49.073942
    ],
    [
        86.8445109,
        49.073691
    ],
    [
        86.8442459,
        49.073313
    ],
    [
        86.8440629,
        49.072824
    ],
    [
        86.8440299,
        49.072546
    ],
    [
        86.8441079,
        49.072291
    ],
    [
        86.8442259,
        49.072171
    ],
    [
        86.8448129,
        49.071965
    ],
    [
        86.8450149,
        49.071804
    ],
    [
        86.8452129,
        49.071529
    ],
    [
        86.8452199,
        49.071346
    ],
    [
        86.8451299,
        49.071141
    ],
    [
        86.8451909,
        49.070736
    ],
    [
        86.8455069,
        49.070343
    ],
    [
        86.8456969,
        49.070198
    ],
    [
        86.8460339,
        49.069717
    ],
    [
        86.8465029,
        49.069609
    ],
    [
        86.8467769,
        49.069415
    ],
    [
        86.8469519,
        49.069183
    ],
    [
        86.8469299,
        49.068834
    ],
    [
        86.8467319,
        49.068459
    ],
    [
        86.8469019,
        49.068045
    ],
    [
        86.8472469,
        49.067818
    ],
    [
        86.8473689,
        49.067444
    ],
    [
        86.8473379,
        49.067206
    ],
    [
        86.8469289,
        49.066434
    ],
    [
        86.8471589,
        49.066002
    ],
    [
        86.8473939,
        49.065761
    ],
    [
        86.8475329,
        49.065514
    ],
    [
        86.8475019,
        49.0653
    ],
    [
        86.8465449,
        49.063808
    ],
    [
        86.8459509,
        49.063363
    ],
    [
        86.8456239,
        49.063367
    ],
    [
        86.8453399,
        49.063188
    ],
    [
        86.8449549,
        49.062488
    ],
    [
        86.8442989,
        49.061988
    ],
    [
        86.8439859,
        49.06165
    ],
    [
        86.8437639,
        49.061518
    ],
    [
        86.8431559,
        49.061399
    ],
    [
        86.8430189,
        49.061257
    ],
    [
        86.8429529,
        49.061068
    ],
    [
        86.8427549,
        49.060951
    ],
    [
        86.8424389,
        49.060876
    ],
    [
        86.8421119,
        49.06088
    ],
    [
        86.8418069,
        49.060812
    ],
    [
        86.8412859,
        49.060794
    ],
    [
        86.8407039,
        49.060872
    ],
    [
        86.8402909,
        49.060845
    ],
    [
        86.8395329,
        49.0606
    ],
    [
        86.8393469,
        49.060436
    ],
    [
        86.8390749,
        49.05982
    ],
    [
        86.8389139,
        49.058944
    ],
    [
        86.8386049,
        49.058313
    ],
    [
        86.8377329,
        49.057427
    ],
    [
        86.8367499,
        49.056058
    ],
    [
        86.8361359,
        49.055748
    ],
    [
        86.8356609,
        49.05481
    ],
    [
        86.8348799,
        49.054019
    ],
    [
        86.8347559,
        49.053473
    ],
    [
        86.8347929,
        49.053052
    ],
    [
        86.8346629,
        49.052736
    ],
    [
        86.8344609,
        49.052468
    ],
    [
        86.8332749,
        49.052046
    ],
    [
        86.8325129,
        49.052087
    ],
    [
        86.8322489,
        49.05217
    ],
    [
        86.8318469,
        49.052087
    ],
    [
        86.8317209,
        49.051891
    ],
    [
        86.8317149,
        49.051684
    ],
    [
        86.8315689,
        49.05138
    ],
    [
        86.8313289,
        49.051033
    ],
    [
        86.8311319,
        49.050933
    ],
    [
        86.8306939,
        49.050414
    ],
    [
        86.8305509,
        49.050051
    ],
    [
        86.8298869,
        49.049273
    ],
    [
        86.8296289,
        49.049134
    ],
    [
        86.8292889,
        49.049114
    ],
    [
        86.8278529,
        49.049266
    ],
    [
        86.8271879,
        49.049282
    ],
    [
        86.8250979,
        49.049076
    ],
    [
        86.8242959,
        49.048959
    ],
    [
        86.8235999,
        49.048753
    ],
    [
        86.8233899,
        49.048597
    ],
    [
        86.8233389,
        49.048486
    ],
    [
        86.8235469,
        49.048142
    ],
    [
        86.8235549,
        49.047984
    ],
    [
        86.8234399,
        49.047763
    ],
    [
        86.8228319,
        49.047262
    ],
    [
        86.8225989,
        49.047146
    ],
    [
        86.8223439,
        49.047117
    ],
    [
        86.8221579,
        49.047397
    ],
    [
        86.8217789,
        49.047703
    ],
    [
        86.8215479,
        49.047682
    ],
    [
        86.8211789,
        49.047465
    ],
    [
        86.8209479,
        49.047428
    ],
    [
        86.8201479,
        49.047118
    ],
    [
        86.8200399,
        49.047037
    ],
    [
        86.8199389,
        49.046817
    ],
    [
        86.8197469,
        49.046736
    ],
    [
        86.8193659,
        49.046692
    ],
    [
        86.8190009,
        49.046461
    ],
    [
        86.8188689,
        49.046276
    ],
    [
        86.8190449,
        49.046157
    ],
    [
        86.8190319,
        49.046074
    ],
    [
        86.8186469,
        49.045885
    ],
    [
        86.8178509,
        49.045069
    ],
    [
        86.8174099,
        49.044756
    ],
    [
        86.8172239,
        49.044686
    ],
    [
        86.8155959,
        49.044933
    ],
    [
        86.8148559,
        49.04488
    ],
    [
        86.8143549,
        49.044706
    ],
    [
        86.8139149,
        49.044251
    ],
    [
        86.8126549,
        49.043789
    ],
    [
        86.8120649,
        49.043751
    ],
    [
        86.8111249,
        49.043592
    ],
    [
        86.8100329,
        49.04356
    ],
    [
        86.8095629,
        49.043486
    ],
    [
        86.8088939,
        49.043085
    ],
    [
        86.8081339,
        49.042057
    ],
    [
        86.8076369,
        49.041643
    ],
    [
        86.8069689,
        49.041276
    ],
    [
        86.8055259,
        49.0411
    ],
    [
        86.8048259,
        49.041437
    ],
    [
        86.8039949,
        49.041459
    ],
    [
        86.8035589,
        49.04135
    ],
    [
        86.8032769,
        49.041194
    ],
    [
        86.8031129,
        49.04089
    ],
    [
        86.8028129,
        49.040678
    ],
    [
        86.8027509,
        49.040315
    ],
    [
        86.8014219,
        49.039229
    ],
    [
        86.8010879,
        49.03904
    ],
    [
        86.8007729,
        49.038931
    ],
    [
        86.8001469,
        49.038836
    ],
    [
        86.7992999,
        49.038641
    ],
    [
        86.7987269,
        49.03858
    ],
    [
        86.7979819,
        49.038588
    ],
    [
        86.7968789,
        49.038783
    ],
    [
        86.7966059,
        49.038911
    ],
    [
        86.7958279,
        49.039144
    ],
    [
        86.7952749,
        49.039196
    ],
    [
        86.7949429,
        49.039097
    ],
    [
        86.7942229,
        49.03872
    ],
    [
        86.7939739,
        49.038473
    ],
    [
        86.7936239,
        49.038352
    ],
    [
        86.7933519,
        49.037913
    ],
    [
        86.7927389,
        49.036421
    ],
    [
        86.7922979,
        49.03612
    ],
    [
        86.7920329,
        49.035731
    ],
    [
        86.7913189,
        49.034967
    ],
    [
        86.7911369,
        49.03464
    ],
    [
        86.7907879,
        49.03453
    ],
    [
        86.7906109,
        49.034408
    ],
    [
        86.7899139,
        49.034245
    ],
    [
        86.7892609,
        49.033799
    ],
    [
        86.7892169,
        49.033436
    ],
    [
        86.7886209,
        49.033171
    ],
    [
        86.7883919,
        49.032401
    ],
    [
        86.7871919,
        49.03189
    ],
    [
        86.7866629,
        49.031533
    ],
    [
        86.7864129,
        49.031264
    ],
    [
        86.7862659,
        49.030959
    ],
    [
        86.786203,
        49.030551
    ],
    [
        86.7857479,
        49.030374
    ],
    [
        86.7850409,
        49.030485
    ],
    [
        86.7844539,
        49.030526
    ],
    [
        86.7840889,
        49.030485
    ],
    [
        86.7837749,
        49.030386
    ],
    [
        86.7832789,
        49.029995
    ],
    [
        86.782742,
        49.029354
    ],
    [
        86.782448,
        49.02871
    ],
    [
        86.7808859,
        49.027309
    ],
    [
        86.7806589,
        49.027232
    ],
    [
        86.7804339,
        49.027235
    ],
    [
        86.7799539,
        49.027444
    ],
    [
        86.7796099,
        49.027528
    ],
    [
        86.7786759,
        49.027539
    ],
    [
        86.7768329,
        49.027169
    ],
    [
        86.7761149,
        49.026871
    ],
    [
        86.7755689,
        49.026525
    ],
    [
        86.7753089,
        49.026506
    ],
    [
        86.7751019,
        49.026542
    ],
    [
        86.7748649,
        49.026738
    ],
    [
        86.7743699,
        49.026993
    ],
    [
        86.7731039,
        49.027564
    ],
    [
        86.772605,
        49.027684
    ],
    [
        86.772259,
        49.02771
    ],
    [
        86.7720499,
        49.027679
    ],
    [
        86.7717519,
        49.027535
    ],
    [
        86.7714149,
        49.027243
    ],
    [
        86.7712049,
        49.027166
    ],
    [
        86.7705949,
        49.027003
    ],
    [
        86.7698429,
        49.026728
    ],
    [
        86.7689429,
        49.02651
    ],
    [
        86.7674049,
        49.026005
    ],
    [
        86.76688,
        49.025773
    ],
    [
        86.7662689,
        49.02561
    ],
    [
        86.7649129,
        49.024762
    ],
    [
        86.7647479,
        49.024412
    ],
    [
        86.7643099,
        49.024201
    ],
    [
        86.7641309,
        49.02401
    ],
    [
        86.7640339,
        49.023614
    ],
    [
        86.764504,
        49.022405
    ],
    [
        86.764578,
        49.021939
    ],
    [
        86.764297,
        49.021783
    ],
    [
        86.764171,
        49.021626
    ],
    [
        86.763157,
        49.021251
    ],
    [
        86.762962,
        49.021061
    ],
    [
        86.762884,
        49.020755
    ],
    [
        86.762691,
        49.020655
    ],
    [
        86.7619849,
        49.020799
    ],
    [
        86.76169,
        49.02078
    ],
    [
        86.761375,
        49.020647
    ],
    [
        86.761075,
        49.020435
    ],
    [
        86.7606289,
        49.01994
    ],
    [
        86.7604579,
        49.019648
    ],
    [
        86.7602099,
        49.019435
    ],
    [
        86.7597359,
        49.019191
    ],
    [
        86.7587739,
        49.018804
    ],
    [
        86.7584429,
        49.018751
    ],
    [
        86.7580809,
        49.018789
    ],
    [
        86.7575429,
        49.018739
    ],
    [
        86.7573669,
        49.018639
    ],
    [
        86.7572009,
        49.018266
    ],
    [
        86.756721,
        49.017794
    ],
    [
        86.756817,
        49.017498
    ],
    [
        86.756681,
        49.016943
    ],
    [
        86.756213,
        49.016915
    ],
    [
        86.7555,
        49.016775
    ],
    [
        86.754498,
        49.016855
    ],
    [
        86.753281,
        49.016375
    ],
    [
        86.752329,
        49.016352
    ],
    [
        86.751388,
        49.016147
    ],
    [
        86.750742,
        49.015904
    ],
    [
        86.750459,
        49.01568
    ],
    [
        86.750313,
        49.015432
    ],
    [
        86.750119,
        49.015276
    ],
    [
        86.749645,
        49.015031
    ],
    [
        86.749164,
        49.014537
    ],
    [
        86.749156,
        49.014231
    ],
    [
        86.749456,
        49.013762
    ],
    [
        86.74955,
        49.013397
    ],
    [
        86.749194,
        49.013038
    ],
    [
        86.748966,
        49.01203
    ],
    [
        86.749114,
        49.011616
    ],
    [
        86.748974,
        49.011225
    ],
    [
        86.749071,
        49.010951
    ],
    [
        86.749673,
        49.010108
    ],
    [
        86.749866,
        49.00956
    ],
    [
        86.749756,
        49.009323
    ],
    [
        86.749232,
        49.009192
    ],
    [
        86.748869,
        49.009213
    ],
    [
        86.748099,
        49.009597
    ],
    [
        86.747626,
        49.009432
    ],
    [
        86.747197,
        49.008942
    ],
    [
        86.747181,
        49.008345
    ],
    [
        86.746303,
        49.007588
    ],
    [
        86.745776,
        49.007338
    ],
    [
        86.744913,
        49.007143
    ],
    [
        86.743914,
        49.006762
    ],
    [
        86.742947,
        49.006568
    ],
    [
        86.741976,
        49.006238
    ],
    [
        86.741288,
        49.005785
    ],
    [
        86.739973,
        49.004399
    ],
    [
        86.739476,
        49.003296
    ],
    [
        86.738292,
        49.001791
    ],
    [
        86.737518,
        49.001015
    ],
    [
        86.737352,
        49.000641
    ],
    [
        86.7375,
        49.000349
    ],
    [
        86.737467,
        49.00006
    ],
    [
        86.736329,
        48.999322
    ],
    [
        86.735655,
        48.999398
    ],
    [
        86.734947,
        48.99915
    ],
    [
        86.734915,
        48.998945
    ],
    [
        86.735113,
        48.998568
    ],
    [
        86.735055,
        48.998313
    ],
    [
        86.734864,
        48.997991
    ],
    [
        86.735218,
        48.997611
    ],
    [
        86.735142,
        48.997436
    ],
    [
        86.734593,
        48.997005
    ],
    [
        86.733095,
        48.996501
    ],
    [
        86.732211,
        48.995957
    ],
    [
        86.731571,
        48.994973
    ],
    [
        86.731207,
        48.994759
    ],
    [
        86.730927,
        48.994812
    ],
    [
        86.73051,
        48.994531
    ],
    [
        86.730024,
        48.994537
    ],
    [
        86.729303,
        48.994394
    ],
    [
        86.728784,
        48.994148
    ],
    [
        86.728907,
        48.993945
    ],
    [
        86.728586,
        48.993394
    ],
    [
        86.728601,
        48.993007
    ],
    [
        86.728516,
        48.992672
    ],
    [
        86.728605,
        48.992184
    ],
    [
        86.729094,
        48.991272
    ],
    [
        86.729768,
        48.990845
    ],
    [
        86.72968,
        48.990575
    ],
    [
        86.730013,
        48.989901
    ],
    [
        86.729908,
        48.989546
    ],
    [
        86.72965,
        48.989423
    ],
    [
        86.729335,
        48.989594
    ],
    [
        86.728791,
        48.989538
    ],
    [
        86.72872,
        48.989266
    ],
    [
        86.728225,
        48.988643
    ],
    [
        86.727874,
        48.988647
    ],
    [
        86.727695,
        48.989131
    ],
    [
        86.726959,
        48.989076
    ],
    [
        86.726698,
        48.98887
    ],
    [
        86.726388,
        48.987993
    ],
    [
        86.726695,
        48.987529
    ],
    [
        86.727447,
        48.986976
    ],
    [
        86.728333,
        48.986673
    ],
    [
        86.728641,
        48.986229
    ],
    [
        86.7298,
        48.985378
    ],
    [
        86.73004,
        48.98481
    ],
    [
        86.730346,
        48.984283
    ],
    [
        86.730333,
        48.98378
    ],
    [
        86.73013,
        48.983364
    ],
    [
        86.729613,
        48.983097
    ],
    [
        86.727623,
        48.982679
    ],
    [
        86.727042,
        48.982434
    ],
    [
        86.726879,
        48.98222
    ],
    [
        86.726917,
        48.981664
    ],
    [
        86.727658,
        48.980612
    ],
    [
        86.727596,
        48.980265
    ],
    [
        86.727403,
        48.979989
    ],
    [
        86.726679,
        48.979649
    ],
    [
        86.726434,
        48.979356
    ],
    [
        86.726428,
        48.979148
    ],
    [
        86.726607,
        48.97892
    ],
    [
        86.727384,
        48.978233
    ],
    [
        86.729259,
        48.976943
    ],
    [
        86.72949,
        48.976697
    ],
    [
        86.729591,
        48.976504
    ],
    [
        86.72956,
        48.976331
    ],
    [
        86.730069,
        48.975508
    ],
    [
        86.730319,
        48.974949
    ],
    [
        86.73046,
        48.974252
    ],
    [
        86.730393,
        48.973696
    ],
    [
        86.730544,
        48.973416
    ],
    [
        86.731114,
        48.972923
    ],
    [
        86.731242,
        48.972748
    ],
    [
        86.731231,
        48.972348
    ],
    [
        86.731521,
        48.97156
    ],
    [
        86.731458,
        48.971178
    ],
    [
        86.731263,
        48.970833
    ],
    [
        86.73078,
        48.97056
    ],
    [
        86.730372,
        48.970147
    ],
    [
        86.73015,
        48.969767
    ],
    [
        86.729501,
        48.969253
    ],
    [
        86.72901,
        48.968702
    ],
    [
        86.728836,
        48.96813
    ],
    [
        86.728787,
        48.967261
    ],
    [
        86.728543,
        48.967056
    ],
    [
        86.728455,
        48.966726
    ],
    [
        86.728737,
        48.96634
    ],
    [
        86.72918,
        48.966092
    ],
    [
        86.729888,
        48.965806
    ],
    [
        86.730165,
        48.965247
    ],
    [
        86.729843,
        48.965094
    ],
    [
        86.729337,
        48.964978
    ],
    [
        86.728799,
        48.964653
    ],
    [
        86.728596,
        48.964012
    ],
    [
        86.728527,
        48.963387
    ],
    [
        86.728355,
        48.962867
    ],
    [
        86.727779,
        48.961946
    ],
    [
        86.727282,
        48.961473
    ],
    [
        86.727202,
        48.961065
    ],
    [
        86.727421,
        48.960152
    ],
    [
        86.727689,
        48.959785
    ],
    [
        86.72778,
        48.959283
    ],
    [
        86.72763,
        48.958875
    ],
    [
        86.72675,
        48.958339
    ],
    [
        86.726356,
        48.957843
    ],
    [
        86.726239,
        48.957343
    ],
    [
        86.726474,
        48.957045
    ],
    [
        86.72692,
        48.956858
    ],
    [
        86.727574,
        48.956714
    ],
    [
        86.72795,
        48.956505
    ],
    [
        86.72791,
        48.956278
    ],
    [
        86.727518,
        48.955896
    ],
    [
        86.726637,
        48.955336
    ],
    [
        86.726419,
        48.954929
    ],
    [
        86.726299,
        48.954316
    ],
    [
        86.72645,
        48.953889
    ],
    [
        86.726746,
        48.953572
    ],
    [
        86.727816,
        48.95299
    ],
    [
        86.727894,
        48.952676
    ],
    [
        86.727621,
        48.952166
    ],
    [
        86.727043,
        48.951659
    ],
    [
        86.726706,
        48.951153
    ],
    [
        86.726771,
        48.950935
    ],
    [
        86.727072,
        48.950636
    ],
    [
        86.727424,
        48.950539
    ],
    [
        86.728535,
        48.950464
    ],
    [
        86.729559,
        48.950733
    ],
    [
        86.72982,
        48.95073
    ],
    [
        86.730123,
        48.950571
    ],
    [
        86.730281,
        48.950258
    ],
    [
        86.730788,
        48.94974
    ],
    [
        86.731211,
        48.949626
    ],
    [
        86.731514,
        48.949452
    ],
    [
        86.731579,
        48.949202
    ],
    [
        86.731523,
        48.948876
    ],
    [
        86.731352,
        48.948661
    ],
    [
        86.730899,
        48.948541
    ],
    [
        86.73054,
        48.94839
    ],
    [
        86.730118,
        48.947633
    ],
    [
        86.729988,
        48.947184
    ],
    [
        86.730025,
        48.94681
    ],
    [
        86.7303,
        48.946434
    ],
    [
        86.730623,
        48.946135
    ],
    [
        86.731625,
        48.945518
    ],
    [
        86.731738,
        48.945314
    ],
    [
        86.731609,
        48.944896
    ],
    [
        86.73098,
        48.944374
    ],
    [
        86.730662,
        48.943974
    ],
    [
        86.730435,
        48.943432
    ],
    [
        86.730376,
        48.943013
    ],
    [
        86.730528,
        48.942636
    ],
    [
        86.731077,
        48.94193
    ],
    [
        86.731188,
        48.941634
    ],
    [
        86.731036,
        48.941247
    ],
    [
        86.730353,
        48.940477
    ],
    [
        86.730179,
        48.940168
    ],
    [
        86.73022,
        48.939919
    ],
    [
        86.730358,
        48.939762
    ],
    [
        86.730708,
        48.939556
    ],
    [
        86.730821,
        48.939353
    ],
    [
        86.731397,
        48.938771
    ],
    [
        86.731482,
        48.938382
    ],
    [
        86.7313,
        48.937746
    ],
    [
        86.731195,
        48.937343
    ],
    [
        86.731416,
        48.936766
    ],
    [
        86.732163,
        48.936384
    ],
    [
        86.732512,
        48.936147
    ],
    [
        86.732787,
        48.935771
    ],
    [
        86.733096,
        48.934959
    ],
    [
        86.733417,
        48.93452
    ],
    [
        86.733753,
        48.933575
    ],
    [
        86.73379,
        48.93317
    ],
    [
        86.733606,
        48.932457
    ],
    [
        86.733667,
        48.931198
    ],
    [
        86.733793,
        48.93059
    ],
    [
        86.734018,
        48.930121
    ],
    [
        86.734871,
        48.929257
    ],
    [
        86.735462,
        48.92785
    ],
    [
        86.735591,
        48.927366
    ],
    [
        86.735197,
        48.92678
    ],
    [
        86.735164,
        48.926423
    ],
    [
        86.734956,
        48.925695
    ],
    [
        86.735172,
        48.924915
    ],
    [
        86.73518,
        48.924309
    ],
    [
        86.735406,
        48.923887
    ],
    [
        86.735443,
        48.923498
    ],
    [
        86.735714,
        48.923013
    ],
    [
        86.736083,
        48.922635
    ],
    [
        86.737271,
        48.921938
    ],
    [
        86.7378,
        48.921357
    ],
    [
        86.737926,
        48.920734
    ],
    [
        86.738217,
        48.92014
    ],
    [
        86.738611,
        48.919809
    ],
    [
        86.739615,
        48.919316
    ],
    [
        86.740089,
        48.919357
    ],
    [
        86.740603,
        48.919102
    ],
    [
        86.740908,
        48.918549
    ],
    [
        86.740825,
        48.918083
    ],
    [
        86.740508,
        48.917729
    ],
    [
        86.740268,
        48.917592
    ],
    [
        86.740074,
        48.917392
    ],
    [
        86.740038,
        48.916957
    ],
    [
        86.740097,
        48.916475
    ],
    [
        86.740419,
        48.916145
    ],
    [
        86.740509,
        48.915957
    ],
    [
        86.7405259,
        48.9152826
    ],
    [
        86.7400374,
        48.9154163
    ],
    [
        86.7397332,
        48.9155812
    ],
    [
        86.7389945,
        48.9154639
    ],
    [
        86.7384634,
        48.915448
    ],
    [
        86.7381207,
        48.9155495
    ],
    [
        86.7378841,
        48.9158795
    ],
    [
        86.7376379,
        48.9162792
    ],
    [
        86.7372999,
        48.9163776
    ],
    [
        86.7369572,
        48.916238
    ],
    [
        86.736682,
        48.9161777
    ],
    [
        86.7362909,
        48.9162729
    ],
    [
        86.7359096,
        48.9165616
    ],
    [
        86.7357647,
        48.91679
    ],
    [
        86.7351806,
        48.9169296
    ],
    [
        86.7344323,
        48.9170914
    ],
    [
        86.7333508,
        48.917323
    ],
    [
        86.7327812,
        48.9172786
    ],
    [
        86.7323177,
        48.9172373
    ],
    [
        86.7315935,
        48.9173484
    ],
    [
        86.730884,
        48.9173357
    ],
    [
        86.7303384,
        48.917415
    ],
    [
        86.7299184,
        48.9176054
    ],
    [
        86.7297446,
        48.9179639
    ],
    [
        86.7293825,
        48.9182906
    ],
    [
        86.7275432,
        48.9191884
    ],
    [
        86.7272679,
        48.9194866
    ],
    [
        86.7271906,
        48.9199403
    ],
    [
        86.7269687,
        48.9205938
    ],
    [
        86.7263362,
        48.9210982
    ],
    [
        86.7257955,
        48.9218849
    ],
    [
        86.7254238,
        48.922291
    ],
    [
        86.7247574,
        48.9225447
    ],
    [
        86.7240671,
        48.9226145
    ],
    [
        86.7235803,
        48.9225669
    ],
    [
        86.7232729,
        48.9226484
    ],
    [
        86.7231326,
        48.9229249
    ],
    [
        86.7231326,
        48.9235451
    ],
    [
        86.7229654,
        48.9237826
    ],
    [
        86.7226202,
        48.923857
    ],
    [
        86.7224044,
        48.9240732
    ],
    [
        86.7220916,
        48.9244134
    ],
    [
        86.7216762,
        48.9245658
    ],
    [
        86.7211314,
        48.9246473
    ],
    [
        86.7205003,
        48.924977
    ],
    [
        86.7202414,
        48.9253597
    ],
    [
        86.7197991,
        48.9255972
    ],
    [
        86.7196157,
        48.9258984
    ],
    [
        86.7195186,
        48.9272806
    ],
    [
        86.7196912,
        48.9275924
    ],
    [
        86.7197398,
        48.9280461
    ],
    [
        86.7196535,
        48.9284713
    ],
    [
        86.7192004,
        48.9288505
    ],
    [
        86.7186556,
        48.9291482
    ],
    [
        86.7183373,
        48.9294317
    ],
    [
        86.7183104,
        48.9297967
    ],
    [
        86.7183481,
        48.930144
    ],
    [
        86.7180676,
        48.9304984
    ],
    [
        86.7177763,
        48.9307252
    ],
    [
        86.7176091,
        48.9310406
    ],
    [
        86.7176793,
        48.9314375
    ],
    [
        86.7180353,
        48.9321214
    ],
    [
        86.7187041,
        48.9334006
    ],
    [
        86.7187958,
        48.9338932
    ],
    [
        86.7187796,
        48.9348251
    ],
    [
        86.7184668,
        48.9351121
    ],
    [
        86.7178519,
        48.935594
    ],
    [
        86.7175444,
        48.9359802
    ],
    [
        86.7174581,
        48.9363558
    ],
    [
        86.717566,
        48.9366606
    ],
    [
        86.7174203,
        48.9369086
    ],
    [
        86.7170643,
        48.9370503
    ],
    [
        86.7166652,
        48.9370468
    ],
    [
        86.7164117,
        48.9370113
    ],
    [
        86.7161689,
        48.9370609
    ],
    [
        86.7157967,
        48.9373125
    ],
    [
        86.715527,
        48.9374188
    ],
    [
        86.7153167,
        48.9374046
    ],
    [
        86.7149661,
        48.9372735
    ],
    [
        86.7142594,
        48.9369865
    ],
    [
        86.7136068,
        48.9369795
    ],
    [
        86.7126628,
        48.9368271
    ],
    [
        86.711789,
        48.9369972
    ],
    [
        86.7114815,
        48.9371672
    ],
    [
        86.7113736,
        48.9373834
    ],
    [
        86.7114114,
        48.937557
    ],
    [
        86.7109152,
        48.9379007
    ],
    [
        86.7105538,
        48.9379432
    ],
    [
        86.7100737,
        48.9378972
    ],
    [
        86.7093487,
        48.9376401
    ],
    [
        86.7081852,
        48.9375613
    ],
    [
        86.707126,
        48.9377121
    ],
    [
        86.7066408,
        48.9379726
    ],
    [
        86.706526,
        48.938329
    ],
    [
        86.706406,
        48.9389048
    ],
    [
        86.7063225,
        48.9395011
    ],
    [
        86.7063434,
        48.93995
    ],
    [
        86.7062599,
        48.9401591
    ],
    [
        86.7062912,
        48.9403681
    ],
    [
        86.7061295,
        48.9406526
    ],
    [
        86.7062129,
        48.9409062
    ],
    [
        86.7061086,
        48.9411666
    ],
    [
        86.7054981,
        48.9414202
    ],
    [
        86.7049451,
        48.9416738
    ],
    [
        86.7042303,
        48.9418486
    ],
    [
        86.7034581,
        48.9419514
    ],
    [
        86.7028842,
        48.9422187
    ],
    [
        86.7021798,
        48.9426848
    ],
    [
        86.7015589,
        48.9432091
    ],
    [
        86.701272,
        48.943706
    ],
    [
        86.7010476,
        48.9439767
    ],
    [
        86.7004006,
        48.9440658
    ],
    [
        86.6997537,
        48.9438876
    ],
    [
        86.698756,
        48.9435577
    ],
    [
        86.6976526,
        48.9434462
    ],
    [
        86.6964642,
        48.9435925
    ],
    [
        86.6959656,
        48.9441779
    ],
    [
        86.6955836,
        48.9447911
    ],
    [
        86.6944271,
        48.9450908
    ],
    [
        86.6935677,
        48.9451744
    ],
    [
        86.6927083,
        48.9450281
    ],
    [
        86.6919232,
        48.9448817
    ],
    [
        86.6908728,
        48.9449514
    ],
    [
        86.6899498,
        48.9449723
    ],
    [
        86.6894405,
        48.9449654
    ],
    [
        86.6883583,
        48.9452511
    ],
    [
        86.6872124,
        48.9453486
    ],
    [
        86.6863742,
        48.9454392
    ],
    [
        86.6853768,
        48.9456692
    ],
    [
        86.6848039,
        48.9457179
    ],
    [
        86.6842416,
        48.9455786
    ],
    [
        86.6837004,
        48.9451535
    ],
    [
        86.6832654,
        48.9447702
    ],
    [
        86.6826607,
        48.9445403
    ],
    [
        86.6813768,
        48.9444288
    ],
    [
        86.6799657,
        48.944387
    ],
    [
        86.6792761,
        48.9444845
    ],
    [
        86.6787349,
        48.9446657
    ],
    [
        86.6781196,
        48.944596
    ],
    [
        86.6775785,
        48.9444845
    ],
    [
        86.6764962,
        48.9449723
    ],
    [
        86.6755307,
        48.9453138
    ],
    [
        86.6740771,
        48.9457667
    ],
    [
        86.6736952,
        48.9460176
    ],
    [
        86.673483,
        48.9462684
    ],
    [
        86.6732177,
        48.9464008
    ],
    [
        86.6729206,
        48.9468398
    ],
    [
        86.6727403,
        48.9469165
    ],
    [
        86.671987,
        48.9469583
    ],
    [
        86.6710427,
        48.947237
    ],
    [
        86.6703954,
        48.9474182
    ],
    [
        86.6701269,
        48.9477018
    ],
    [
        86.6695785,
        48.9478711
    ],
    [
        86.6689631,
        48.9479269
    ],
    [
        86.6686005,
        48.947976
    ],
    [
        86.6682544,
        48.9480827
    ],
    [
        86.6679681,
        48.9482875
    ],
    [
        86.6677587,
        48.9485822
    ],
    [
        86.6675066,
        48.948961
    ],
    [
        86.6671519,
        48.9491884
    ],
    [
        86.6665964,
        48.9495055
    ],
    [
        86.6658657,
        48.9500162
    ],
    [
        86.6654854,
        48.9501621
    ],
    [
        86.6653316,
        48.950381
    ],
    [
        86.6649086,
        48.9504989
    ],
    [
        86.6644171,
        48.9504792
    ],
    [
        86.664165,
        48.9505073
    ],
    [
        86.6639172,
        48.9506813
    ],
    [
        86.6637719,
        48.9508468
    ],
    [
        86.6627377,
        48.9511022
    ],
    [
        86.6621694,
        48.9510994
    ],
    [
        86.66161,
        48.9511468
    ],
    [
        86.6611483,
        48.9513535
    ],
    [
        86.6608335,
        48.9517118
    ],
    [
        86.6601935,
        48.9518428
    ],
    [
        86.6584831,
        48.9526007
    ],
    [
        86.6579479,
        48.9525663
    ],
    [
        86.6574233,
        48.9524009
    ],
    [
        86.6570665,
        48.9528074
    ],
    [
        86.656416,
        48.9529659
    ],
    [
        86.6557024,
        48.952897
    ],
    [
        86.6552198,
        48.9529935
    ],
    [
        86.6548525,
        48.9532347
    ],
    [
        86.6543383,
        48.9532622
    ],
    [
        86.6531526,
        48.9532002
    ],
    [
        86.6523237,
        48.9531037
    ],
    [
        86.6515892,
        48.9531037
    ],
    [
        86.6508442,
        48.9533242
    ],
    [
        86.6491653,
        48.9529453
    ],
    [
        86.6486406,
        48.9529659
    ],
    [
        86.6478746,
        48.9531313
    ],
    [
        86.6463217,
        48.9530831
    ],
    [
        86.6457131,
        48.953276
    ],
    [
        86.645052,
        48.953593
    ],
    [
        86.6440132,
        48.953841
    ],
    [
        86.6433102,
        48.9544129
    ],
    [
        86.6426806,
        48.9546816
    ],
    [
        86.642051,
        48.9548884
    ],
    [
        86.6415683,
        48.9555429
    ],
    [
        86.6411276,
        48.955791
    ],
    [
        86.640498,
        48.9559012
    ],
    [
        86.6399419,
        48.9560321
    ],
    [
        86.6396534,
        48.9563228
    ],
    [
        86.6356837,
        48.9562629
    ],
    [
        86.6321968,
        48.9551569
    ],
    [
        86.6297078,
        48.9539733
    ],
    [
        86.6258668,
        48.9537056
    ],
    [
        86.6235279,
        48.9529447
    ],
    [
        86.6171979,
        48.9513101
    ],
    [
        86.6154598,
        48.9514369
    ],
    [
        86.613872,
        48.9512819
    ],
    [
        86.6033256,
        48.9453562
    ],
    [
        86.591953,
        48.9325405
    ],
    [
        86.590703,
        48.9323397
    ],
    [
        86.5887128,
        48.9324383
    ],
    [
        86.5880315,
        48.9322551
    ],
    [
        86.5864329,
        48.932093
    ],
    [
        86.5859662,
        48.9317793
    ],
    [
        86.5850918,
        48.9317194
    ],
    [
        86.5834235,
        48.9318322
    ],
    [
        86.5799259,
        48.9316842
    ],
    [
        86.5791695,
        48.9315256
    ],
    [
        86.5779412,
        48.9311591
    ],
    [
        86.5773403,
        48.9315009
    ],
    [
        86.5770023,
        48.9314481
    ],
    [
        86.5766268,
        48.9317864
    ],
    [
        86.5760796,
        48.9319908
    ],
    [
        86.5750872,
        48.9318533
    ],
    [
        86.5742235,
        48.9314903
    ],
    [
        86.57292,
        48.931663
    ],
    [
        86.5711068,
        48.9323855
    ],
    [
        86.5697013,
        48.9333652
    ],
    [
        86.5690576,
        48.933707
    ],
    [
        86.5681457,
        48.9345669
    ],
    [
        86.5668475,
        48.9347431
    ],
    [
        86.5656029,
        48.9347079
    ],
    [
        86.564691,
        48.9344964
    ],
    [
        86.5638648,
        48.933707
    ],
    [
        86.5638112,
        48.9331432
    ],
    [
        86.563028,
        48.9326709
    ],
    [
        86.5625881,
        48.9321
    ],
    [
        86.5619444,
        48.9317124
    ],
    [
        86.5617727,
        48.9312755
    ],
    [
        86.5591871,
        48.9295414
    ],
    [
        86.5578567,
        48.9283502
    ],
    [
        86.5551208,
        48.9266937
    ],
    [
        86.5535008,
        48.9254248
    ],
    [
        86.5530716,
        48.9246846
    ],
    [
        86.5314102,
        48.9270532
    ],
    [
        86.5306376,
        48.9319309
    ],
    [
        86.5282774,
        48.941699
    ],
    [
        86.5189217,
        48.9389365
    ],
    [
        86.5170978,
        48.9374988
    ],
    [
        86.5158747,
        48.9358496
    ],
    [
        86.5146946,
        48.9352999
    ],
    [
        86.513965,
        48.9342004
    ],
    [
        86.5115403,
        48.932819
    ],
    [
        86.5072487,
        48.9291538
    ],
    [
        86.505575,
        48.926165
    ],
    [
        86.5038584,
        48.9243886
    ],
    [
        86.5026139,
        48.9238528
    ],
    [
        86.5004681,
        48.9238246
    ],
    [
        86.4983223,
        48.9225839
    ],
    [
        86.4970778,
        48.9221609
    ],
    [
        86.495962,
        48.9222173
    ],
    [
        86.4945029,
        48.9217943
    ],
    [
        86.4935587,
        48.9218225
    ],
    [
        86.4927863,
        48.9211457
    ],
    [
        86.4890528,
        48.9170143
    ],
    [
        86.4688611,
        48.9313529
    ],
    [
        86.447618,
        48.9347079
    ],
    [
        86.4278441,
        48.9334351
    ],
    [
        86.4275652,
        48.9335055
    ],
    [
        86.4272326,
        48.9336606
    ],
    [
        86.4262783,
        48.9346586
    ],
    [
        86.4246367,
        48.9360682
    ],
    [
        86.4242183,
        48.9363149
    ],
    [
        86.4237999,
        48.9364629
    ],
    [
        86.4225768,
        48.9368082
    ],
    [
        86.4220184,
        48.9370084
    ],
    [
        86.4199155,
        48.9382699
    ],
    [
        86.418274,
        48.9391437
    ],
    [
        86.417663,
        48.9395426
    ],
    [
        86.4171588,
        48.9397047
    ],
    [
        86.4166116,
        48.9398386
    ],
    [
        86.4163965,
        48.9399189
    ],
    [
        86.4162785,
        48.9400106
    ],
    [
        86.4163648,
        48.9403812
    ],
    [
        86.4163219,
        48.9406843
    ],
    [
        86.4161926,
        48.9410394
    ],
    [
        86.4155172,
        48.9423544
    ],
    [
        86.4152914,
        48.9426884
    ],
    [
        86.415131,
        48.9428336
    ],
    [
        86.414895,
        48.9429463
    ],
    [
        86.414101,
        48.9432071
    ],
    [
        86.4137357,
        48.9434072
    ],
    [
        86.413446,
        48.9436891
    ],
    [
        86.4131456,
        48.9440485
    ],
    [
        86.4125019,
        48.945486
    ],
    [
        86.4123088,
        48.9457396
    ],
    [
        86.4120089,
        48.9459905
    ],
    [
        86.4108818,
        48.9465993
    ],
    [
        86.4100235,
        48.9472193
    ],
    [
        86.4096057,
        48.947597
    ],
    [
        86.4090799,
        48.9478366
    ],
    [
        86.4086824,
        48.948086
    ],
    [
        86.4083928,
        48.9483115
    ],
    [
        86.4077168,
        48.9486356
    ],
    [
        86.407535,
        48.9487667
    ],
    [
        86.4073633,
        48.9489569
    ],
    [
        86.4072775,
        48.9492317
    ],
    [
        86.407299,
        48.9499363
    ],
    [
        86.4071697,
        48.9503478
    ],
    [
        86.40688,
        48.9507353
    ],
    [
        86.4065689,
        48.9510101
    ],
    [
        86.4062153,
        48.9511411
    ],
    [
        86.4059686,
        48.9511975
    ],
    [
        86.4054965,
        48.9511975
    ],
    [
        86.4051639,
        48.9512257
    ],
    [
        86.4048415,
        48.9512989
    ],
    [
        86.4046377,
        48.9514117
    ],
    [
        86.4039939,
        48.9519049
    ],
    [
        86.4033829,
        48.9523107
    ],
    [
        86.4025026,
        48.9529687
    ],
    [
        86.4020627,
        48.9533633
    ],
    [
        86.4018267,
        48.9536451
    ],
    [
        86.4016014,
        48.9539903
    ],
    [
        86.4014732,
        48.9540368
    ],
    [
        86.4013123,
        48.9540438
    ],
    [
        86.4011084,
        48.9540368
    ],
    [
        86.4007538,
        48.9539551
    ],
    [
        86.4003461,
        48.9539692
    ],
    [
        86.4000033,
        48.9540086
    ],
    [
        86.3993483,
        48.9542016
    ],
    [
        86.3984042,
        48.9545539
    ],
    [
        86.3965165,
        48.9553613
    ],
    [
        86.3953787,
        48.9558713
    ],
    [
        86.3940698,
        48.9565617
    ],
    [
        86.3931578,
        48.9572803
    ],
    [
        86.3928038,
        48.9576678
    ],
    [
        86.3924717,
        48.9581933
    ],
    [
        86.3923,
        48.9586583
    ],
    [
        86.3921922,
        48.9587738
    ],
    [
        86.3919347,
        48.9588442
    ],
    [
        86.3913661,
        48.9589358
    ],
    [
        86.3911306,
        48.9590105
    ],
    [
        86.3909804,
        48.9591021
    ],
    [
        86.3901537,
        48.9598939
    ],
    [
        86.3895636,
        48.9603588
    ],
    [
        86.3889741,
        48.9606659
    ],
    [
        86.388759,
        48.9608308
    ],
    [
        86.388663,
        48.960997
    ],
    [
        86.3886732,
        48.9611618
    ],
    [
        86.388781,
        48.9613563
    ],
    [
        86.3889736,
        48.9615704
    ],
    [
        86.3889956,
        48.9616662
    ],
    [
        86.3888775,
        48.9617437
    ],
    [
        86.3883518,
        48.9618494
    ],
    [
        86.3882231,
        48.9619268
    ],
    [
        86.3881153,
        48.9620424
    ],
    [
        86.3878899,
        48.9624298
    ],
    [
        86.3872253,
        48.9632159
    ],
    [
        86.3871395,
        48.9633709
    ],
    [
        86.3871073,
        48.9635329
    ],
    [
        86.3872999,
        48.9639372
    ],
    [
        86.387375,
        48.9641978
    ],
    [
        86.3873106,
        48.9644866
    ],
    [
        86.387214,
        48.9647402
    ],
    [
        86.3869136,
        48.9650572
    ],
    [
        86.3867312,
        48.9651487
    ],
    [
        86.3852721,
        48.9655079
    ],
    [
        86.3850366,
        48.9656037
    ],
    [
        86.3841676,
        48.9661743
    ],
    [
        86.3838564,
        48.9664771
    ],
    [
        86.3834273,
        48.9671181
    ],
    [
        86.3831585,
        48.9676773
    ],
    [
        86.3830083,
        48.9678323
    ],
    [
        86.3827616,
        48.9679943
    ],
    [
        86.3823329,
        48.9681887
    ],
    [
        86.3821071,
        48.9683605
    ],
    [
        86.3820218,
        48.9684986
    ],
    [
        86.3820218,
        48.9686676
    ],
    [
        86.3822686,
        48.969224
    ],
    [
        86.3822573,
        48.9693254
    ],
    [
        86.3821286,
        48.9694381
    ],
    [
        86.3814639,
        48.9697311
    ],
    [
        86.3812493,
        48.9698579
    ],
    [
        86.3811099,
        48.9699988
    ],
    [
        86.3800364,
        48.9716214
    ],
    [
        86.3797258,
        48.9718229
    ],
    [
        86.3793176,
        48.9720229
    ],
    [
        86.3789748,
        48.9719214
    ],
    [
        86.3789855,
        48.971615
    ],
    [
        86.3794415,
        48.9709917
    ],
    [
        86.3790285,
        48.9706466
    ],
    [
        86.3765072,
        48.9699635
    ],
    [
        86.3746833,
        48.9699353
    ],
    [
        86.3734816,
        48.9696958
    ],
    [
        86.3707565,
        48.9681462
    ],
    [
        86.3686537,
        48.9666671
    ],
    [
        86.3667761,
        48.9646456
    ],
    [
        86.3649844,
        48.9635961
    ],
    [
        86.3626241,
        48.9627085
    ],
    [
        86.361122,
        48.9612293
    ],
    [
        86.359738,
        48.9602924
    ],
    [
        86.3584398,
        48.9600529
    ],
    [
        86.3558434,
        48.9604403
    ],
    [
        86.3547276,
        48.9595457
    ],
    [
        86.3536118,
        48.9587426
    ],
    [
        86.3526892,
        48.9585383
    ],
    [
        86.3522278,
        48.9582424
    ],
    [
        86.351627,
        48.9581579
    ],
    [
        86.3505541,
        48.9584608
    ],
    [
        86.3491486,
        48.9586792
    ],
    [
        86.3482796,
        48.9591582
    ],
    [
        86.3442348,
        48.9578338
    ],
    [
        86.3419174,
        48.9572562
    ],
    [
        86.3408552,
        48.9567701
    ],
    [
        86.3396965,
        48.9564566
    ],
    [
        86.3390716,
        48.9559898
    ],
    [
        86.3349115,
        48.9546426
    ],
    [
        86.3337206,
        48.9545933
    ],
    [
        86.3329266,
        48.9541212
    ],
    [
        86.3304483,
        48.9534096
    ],
    [
        86.3293754,
        48.9522823
    ],
    [
        86.3278948,
        48.9521273
    ],
    [
        86.3253735,
        48.9504575
    ],
    [
        86.3227664,
        48.9493372
    ],
    [
        86.3204813,
        48.9487666
    ],
    [
        86.3184964,
        48.9490976
    ],
    [
        86.3172947,
        48.949006
    ],
    [
        86.3168066,
        48.9494007
    ],
    [
        86.3161199,
        48.9496543
    ],
    [
        86.3158142,
        48.9499961
    ],
    [
        86.3156157,
        48.9509542
    ],
    [
        86.3143121,
        48.9523387
    ],
    [
        86.3129603,
        48.9528038
    ],
    [
        86.3100742,
        48.9543536
    ],
    [
        86.3098704,
        48.9549737
    ],
    [
        86.3093017,
        48.9554246
    ],
    [
        86.3089799,
        48.9559317
    ],
    [
        86.3082825,
        48.9563123
    ],
    [
        86.3079338,
        48.9568406
    ],
    [
        86.3081216,
        48.9577106
    ],
    [
        86.3045918,
        48.960391
    ],
    [
        86.2987338,
        48.962307
    ],
    [
        86.294614,
        48.9624197
    ],
    [
        86.2922858,
        48.9629762
    ],
    [
        86.2887775,
        48.9652021
    ],
    [
        86.2845074,
        48.9661248
    ],
    [
        86.2826942,
        48.9675405
    ],
    [
        86.2794434,
        48.9683505
    ],
    [
        86.2783598,
        48.969738
    ],
    [
        86.2769436,
        48.9707662
    ],
    [
        86.2739931,
        48.9715903
    ],
    [
        86.2715738,
        48.9730586
    ],
    [
        86.2708386,
        48.973802
    ],
    [
        86.2704416,
        48.9737386
    ],
    [
        86.2701626,
        48.9737739
    ],
    [
        86.2698515,
        48.9739006
    ],
    [
        86.2695404,
        48.973971
    ],
    [
        86.2691649,
        48.9742105
    ],
    [
        86.2688752,
        48.9741682
    ],
    [
        86.2685211,
        48.9741682
    ],
    [
        86.2682207,
        48.9742598
    ],
    [
        86.267459,
        48.9743795
    ],
    [
        86.2665583,
        48.9747668
    ],
    [
        86.2663223,
        48.9748443
    ],
    [
        86.266135,
        48.9748697
    ],
    [
        86.2659634,
        48.9748344
    ],
    [
        86.2657429,
        48.9747246
    ],
    [
        86.2655712,
        48.9746894
    ],
    [
        86.2654103,
        48.9747246
    ],
    [
        86.2647344,
        48.9751471
    ],
    [
        86.2644876,
        48.9752457
    ],
    [
        86.2641228,
        48.9752457
    ],
    [
        86.2638761,
        48.975288
    ],
    [
        86.2633933,
        48.9752316
    ],
    [
        86.263168,
        48.9752387
    ],
    [
        86.2629963,
        48.9753161
    ],
    [
        86.2626852,
        48.9753513
    ],
    [
        86.2616659,
        48.9752739
    ],
    [
        86.2614085,
        48.9751823
    ],
    [
        86.2608779,
        48.9748978
    ],
    [
        86.2605882,
        48.9748274
    ],
    [
        86.2599601,
        48.9740626
    ],
    [
        86.2597669,
        48.9739429
    ],
    [
        86.2595368,
        48.9738556
    ],
    [
        86.2593271,
        48.9737105
    ],
    [
        86.2592841,
        48.9736119
    ],
    [
        86.2593807,
        48.9735133
    ],
    [
        86.2593807,
        48.9734147
    ],
    [
        86.2589408,
        48.9731823
    ],
    [
        86.2587155,
        48.9731048
    ],
    [
        86.2585009,
        48.9731048
    ],
    [
        86.2583615,
        48.9731541
    ],
    [
        86.2582386,
        48.9731443
    ],
    [
        86.2579216,
        48.9729428
    ],
    [
        86.2575783,
        48.9729006
    ],
    [
        86.2572349,
        48.9729006
    ],
    [
        86.2567629,
        48.9729781
    ],
    [
        86.2565161,
        48.9730696
    ],
    [
        86.2561835,
        48.9732386
    ],
    [
        86.2558938,
        48.9734569
    ],
    [
        86.2554647,
        48.9736048
    ],
    [
        86.25466,
        48.9737879
    ],
    [
        86.2543703,
        48.9737316
    ],
    [
        86.2533355,
        48.9732992
    ],
    [
        86.253121,
        48.9732499
    ],
    [
        86.2528635,
        48.9731302
    ],
    [
        86.2525845,
        48.9729471
    ],
    [
        86.2523914,
        48.9728555
    ],
    [
        86.252128,
        48.972809
    ],
    [
        86.2519563,
        48.9728302
    ],
    [
        86.2518276,
        48.9729076
    ],
    [
        86.2515165,
        48.9732457
    ],
    [
        86.2511517,
        48.9735274
    ],
    [
        86.2507225,
        48.9740696
    ],
    [
        86.2504758,
        48.9742246
    ],
    [
        86.2498857,
        48.9744499
    ],
    [
        86.2495209,
        48.9745415
    ],
    [
        86.24936,
        48.9745415
    ],
    [
        86.2490381,
        48.9746401
    ],
    [
        86.2488128,
        48.9746119
    ],
    [
        86.2485982,
        48.9746119
    ],
    [
        86.2481262,
        48.9747316
    ],
    [
        86.2469996,
        48.9747668
    ],
    [
        86.2463517,
        48.9749461
    ],
    [
        86.2454758,
        48.9747808
    ],
    [
        86.245154,
        48.9746646
    ],
    [
        86.2450574,
        48.974573
    ],
    [
        86.244934,
        48.974235
    ],
    [
        86.2448503,
        48.9741477
    ],
    [
        86.244684,
        48.9740737
    ],
    [
        86.244108,
        48.9739639
    ],
    [
        86.2438874,
        48.9737905
    ],
    [
        86.2438237,
        48.973492
    ],
    [
        86.2436735,
        48.9733864
    ],
    [
        86.2432658,
        48.9732385
    ],
    [
        86.2430405,
        48.9730835
    ],
    [
        86.2426757,
        48.9726469
    ],
    [
        86.2421607,
        48.9722877
    ],
    [
        86.2418388,
        48.9720976
    ],
    [
        86.2416994,
        48.9719356
    ],
    [
        86.2416672,
        48.9715412
    ],
    [
        86.2416994,
        48.9712736
    ],
    [
        86.2416672,
        48.9710764
    ],
    [
        86.2415384,
        48.9709707
    ],
    [
        86.2412917,
        48.9709144
    ],
    [
        86.2407552,
        48.9708792
    ],
    [
        86.240251,
        48.9707806
    ],
    [
        86.2398862,
        48.9706679
    ],
    [
        86.2396823,
        48.9704848
    ],
    [
        86.2395429,
        48.9701819
    ],
    [
        86.2389099,
        48.9696114
    ],
    [
        86.236807,
        48.9685268
    ],
    [
        86.2366997,
        48.9683719
    ],
    [
        86.2366568,
        48.9681253
    ],
    [
        86.2366675,
        48.9678788
    ],
    [
        86.2364101,
        48.9675055
    ],
    [
        86.2361847,
        48.9674421
    ],
    [
        86.2354552,
        48.9674281
    ],
    [
        86.2351977,
        48.9673434
    ],
    [
        86.2345647,
        48.9669842
    ],
    [
        86.2341999,
        48.9668294
    ],
    [
        86.2334596,
        48.9666674
    ],
    [
        86.2329446,
        48.966442
    ],
    [
        86.2327408,
        48.9663786
    ],
    [
        86.2319147,
        48.9663363
    ],
    [
        86.2317323,
        48.966301
    ],
    [
        86.2315606,
        48.9662377
    ],
    [
        86.2309062,
        48.9659067
    ],
    [
        86.2304663,
        48.9657657
    ],
    [
        86.2302195,
        48.965646
    ],
    [
        86.2300371,
        48.9655051
    ],
    [
        86.2299191,
        48.9653642
    ],
    [
        86.2298547,
        48.9651671
    ],
    [
        86.2298547,
        48.9649346
    ],
    [
        86.2297796,
        48.964667
    ],
    [
        86.2296616,
        48.9644486
    ],
    [
        86.2295007,
        48.9643571
    ],
    [
        86.229093,
        48.9642583
    ],
    [
        86.228578,
        48.9642372
    ],
    [
        86.2281381,
        48.9641879
    ],
    [
        86.227312,
        48.9639555
    ],
    [
        86.2269472,
        48.9638921
    ],
    [
        86.226561,
        48.963871
    ],
    [
        86.2254023,
        48.9635752
    ],
    [
        86.2240504,
        48.963406
    ],
    [
        86.2237286,
        48.9633146
    ],
    [
        86.2235247,
        48.9632299
    ],
    [
        86.2232243,
        48.9629552
    ],
    [
        86.2220656,
        48.9621522
    ],
    [
        86.2211536,
        48.9618776
    ],
    [
        86.2210034,
        48.9617507
    ],
    [
        86.2209283,
        48.9616451
    ],
    [
        86.2211107,
        48.9611097
    ],
    [
        86.2211107,
        48.9607363
    ],
    [
        86.220982,
        48.9602926
    ],
    [
        86.2202846,
        48.9591655
    ],
    [
        86.2197374,
        48.9585878
    ],
    [
        86.2195658,
        48.9583554
    ],
    [
        86.2194263,
        48.9579678
    ],
    [
        86.2194263,
        48.957672
    ],
    [
        86.2196194,
        48.9573832
    ],
    [
        86.2196409,
        48.9572071
    ],
    [
        86.2196302,
        48.9569464
    ],
    [
        86.2194692,
        48.956566
    ],
    [
        86.2194799,
        48.9562842
    ],
    [
        86.219598,
        48.955953
    ],
    [
        86.219598,
        48.9558192
    ],
    [
        86.2195014,
        48.955629
    ],
    [
        86.2194907,
        48.9555021
    ],
    [
        86.2195229,
        48.9553613
    ],
    [
        86.2196302,
        48.9551923
    ],
    [
        86.2201559,
        48.9546356
    ],
    [
        86.2201773,
        48.9544948
    ],
    [
        86.2201344,
        48.9543961
    ],
    [
        86.2199413,
        48.9541917
    ],
    [
        86.2193834,
        48.9538465
    ],
    [
        86.2182461,
        48.9533745
    ],
    [
        86.2180208,
        48.9531702
    ],
    [
        86.2174737,
        48.9525291
    ],
    [
        86.2171518,
        48.9522896
    ],
    [
        86.2166046,
        48.9519724
    ],
    [
        86.2158,
        48.951592
    ],
    [
        86.2142014,
        48.9509438
    ],
    [
        86.2138473,
        48.9507113
    ],
    [
        86.2133109,
        48.9502251
    ],
    [
        86.212871,
        48.9500209
    ],
    [
        86.2123989,
        48.9499151
    ],
    [
        86.2113689,
        48.9498165
    ],
    [
        86.2109398,
        48.9497249
    ],
    [
        86.2106179,
        48.9496121
    ],
    [
        86.2102102,
        48.949429
    ],
    [
        86.2096738,
        48.9490132
    ],
    [
        86.2088262,
        48.9486539
    ],
    [
        86.2083971,
        48.9483932
    ],
    [
        86.2076675,
        48.9478226
    ],
    [
        86.2072062,
        48.947604
    ],
    [
        86.2068521,
        48.9474702
    ],
    [
        86.2064551,
        48.9473997
    ],
    [
        86.2062298,
        48.9473223
    ],
    [
        86.2060904,
        48.9472307
    ],
    [
        86.2060367,
        48.9469347
    ],
    [
        86.2058864,
        48.9466244
    ],
    [
        86.2055163,
        48.9462264
    ],
    [
        86.2050925,
        48.9458634
    ],
    [
        86.2050228,
        48.9457542
    ],
    [
        86.2050067,
        48.9456345
    ],
    [
        86.2050603,
        48.9454829
    ],
    [
        86.2053822,
        48.9449967
    ],
    [
        86.2054519,
        48.944736
    ],
    [
        86.2054519,
        48.9444682
    ],
    [
        86.2053607,
        48.9437072
    ],
    [
        86.205409,
        48.9433866
    ],
    [
        86.2054999,
        48.9431277
    ],
    [
        86.2054948,
        48.9430342
    ],
    [
        86.2054197,
        48.9429215
    ],
    [
        86.2052373,
        48.9427559
    ],
    [
        86.2043039,
        48.9417095
    ],
    [
        86.2042556,
        48.9415755
    ],
    [
        86.2042876,
        48.9408056
    ],
    [
        86.2042074,
        48.9406454
    ],
    [
        86.2041001,
        48.940529
    ],
    [
        86.2039552,
        48.9404515
    ],
    [
        86.2036816,
        48.9403529
    ],
    [
        86.2027268,
        48.9401309
    ],
    [
        86.2018202,
        48.9398595
    ],
    [
        86.2010638,
        48.9395459
    ],
    [
        86.2002967,
        48.9391619
    ],
    [
        86.2000392,
        48.9389892
    ],
    [
        86.1999051,
        48.9388342
    ],
    [
        86.199712,
        48.9384853
    ],
    [
        86.1995561,
        48.9383427
    ],
    [
        86.1992882,
        48.9381682
    ],
    [
        86.198961,
        48.9380062
    ],
    [
        86.1985425,
        48.9378546
    ],
    [
        86.1983387,
        48.9376713
    ],
    [
        86.1981241,
        48.9376009
    ],
    [
        86.1979632,
        48.9375868
    ],
    [
        86.1978291,
        48.9375445
    ],
    [
        86.1977218,
        48.937481
    ],
    [
        86.1976359,
        48.9372063
    ],
    [
        86.1976252,
        48.9370159
    ],
    [
        86.1977754,
        48.936519
    ],
    [
        86.1976893,
        48.9361967
    ],
    [
        86.1976252,
        48.9357579
    ],
    [
        86.1974318,
        48.9356153
    ],
    [
        86.1968474,
        48.9354303
    ],
    [
        86.1966167,
        48.935314
    ],
    [
        86.1964719,
        48.9351659
    ],
    [
        86.1962841,
        48.9347218
    ],
    [
        86.1962305,
        48.9343624
    ],
    [
        86.1962197,
        48.9341192
    ],
    [
        86.1962895,
        48.933869
    ],
    [
        86.1962895,
        48.9336999
    ],
    [
        86.1961768,
        48.9335554
    ],
    [
        86.1957799,
        48.9333862
    ],
    [
        86.195694,
        48.9333017
    ],
    [
        86.1956079,
        48.933152
    ],
    [
        86.195517,
        48.9328893
    ],
    [
        86.1950286,
        48.9325598
    ],
    [
        86.1946745,
        48.9321193
    ],
    [
        86.1943956,
        48.9319008
    ],
    [
        86.193371,
        48.9313792
    ],
    [
        86.193092,
        48.9311466
    ],
    [
        86.1923786,
        48.9304242
    ],
    [
        86.1921372,
        48.9302516
    ],
    [
        86.1919869,
        48.930019
    ],
    [
        86.1918636,
        48.9297263
    ],
    [
        86.1917885,
        48.9296453
    ],
    [
        86.1915953,
        48.9295573
    ],
    [
        86.1908014,
        48.9293281
    ],
    [
        86.1906566,
        48.9292577
    ],
    [
        86.19056,
        48.9291589
    ],
    [
        86.1904903,
        48.9289439
    ],
    [
        86.1904205,
        48.9288664
    ],
    [
        86.1898948,
        48.9286902
    ],
    [
        86.1897017,
        48.9285703
    ],
    [
        86.189117,
        48.9281263
    ],
    [
        86.1887093,
        48.9277739
    ],
    [
        86.1885269,
        48.9276611
    ],
    [
        86.1883445,
        48.9275941
    ],
    [
        86.1874433,
        48.9273968
    ],
    [
        86.1871375,
        48.9272592
    ],
    [
        86.1869769,
        48.9271341
    ],
    [
        86.1869286,
        48.927046
    ],
    [
        86.1868854,
        48.9266813
    ],
    [
        86.1868317,
        48.9265439
    ],
    [
        86.1866601,
        48.9263746
    ],
    [
        86.1864133,
        48.9262442
    ],
    [
        86.1862363,
        48.9262125
    ],
    [
        86.1860003,
        48.9262054
    ],
    [
        86.1852653,
        48.9263393
    ],
    [
        86.1849491,
        48.9263376
    ],
    [
        86.1847557,
        48.9263006
    ],
    [
        86.1843751,
        48.9260486
    ],
    [
        86.1843483,
        48.92589
    ],
    [
        86.1844234,
        48.9256961
    ],
    [
        86.1844234,
        48.9254106
    ],
    [
        86.1842568,
        48.9251975
    ],
    [
        86.1840476,
        48.9250036
    ],
    [
        86.1836667,
        48.9247639
    ],
    [
        86.182975,
        48.9244554
    ],
    [
        86.1825458,
        48.9243462
    ],
    [
        86.1823471,
        48.9242492
    ],
    [
        86.1822454,
        48.9241664
    ],
    [
        86.1819504,
        48.9237822
    ],
    [
        86.1812474,
        48.9233469
    ],
    [
        86.1810757,
        48.9231424
    ],
    [
        86.180958,
        48.9229363
    ],
    [
        86.1809041,
        48.9221731
    ],
    [
        86.1807058,
        48.9215333
    ],
    [
        86.1807112,
        48.9214806
    ],
    [
        86.1809043,
        48.9212479
    ],
    [
        86.1810806,
        48.9209723
    ],
    [
        86.1816393,
        48.9207261
    ],
    [
        86.181768,
        48.9205217
    ],
    [
        86.181768,
        48.9203525
    ],
    [
        86.1816875,
        48.9200176
    ],
    [
        86.1814837,
        48.9196122
    ],
    [
        86.1813013,
        48.919436
    ],
    [
        86.181194,
        48.919221
    ],
    [
        86.1812477,
        48.9186641
    ],
    [
        86.1812047,
        48.9184595
    ],
    [
        86.1811189,
        48.9183502
    ],
    [
        86.1807809,
        48.9180506
    ],
    [
        86.180207,
        48.9176734
    ],
    [
        86.1800299,
        48.9175148
    ],
    [
        86.1799602,
        48.9173808
    ],
    [
        86.1799065,
        48.9165489
    ],
    [
        86.1799173,
        48.9159037
    ],
    [
        86.1804162,
        48.914483
    ],
    [
        86.1806573,
        48.9140018
    ],
    [
        86.1806841,
        48.9138715
    ],
    [
        86.1806629,
        48.9136476
    ],
    [
        86.1807968,
        48.9134025
    ],
    [
        86.1808397,
        48.9132579
    ],
    [
        86.1808346,
        48.912893
    ],
    [
        86.1810277,
        48.9122161
    ],
    [
        86.1810009,
        48.9117472
    ],
    [
        86.1810331,
        48.9116416
    ],
    [
        86.1810867,
        48.9115498
    ],
    [
        86.181371,
        48.9113241
    ],
    [
        86.1815212,
        48.9111655
    ],
    [
        86.1816017,
        48.9110245
    ],
    [
        86.1816393,
        48.9108693
    ],
    [
        86.18165,
        48.9106156
    ],
    [
        86.1817841,
        48.9103546
    ],
    [
        86.1819289,
        48.9102172
    ],
    [
        86.1819879,
        48.910069
    ],
    [
        86.1820416,
        48.9098539
    ],
    [
        86.1820738,
        48.9091805
    ],
    [
        86.1822669,
        48.9088915
    ],
    [
        86.1824761,
        48.9086517
    ],
    [
        86.1826639,
        48.9082603
    ],
    [
        86.1827336,
        48.9071038
    ],
    [
        86.1830179,
        48.9065397
    ],
    [
        86.183034,
        48.9062822
    ],
    [
        86.1829374,
        48.9061518
    ],
    [
        86.1826692,
        48.9059296
    ],
    [
        86.1825673,
        48.9058027
    ],
    [
        86.1824064,
        48.9055419
    ],
    [
        86.1820845,
        48.9051646
    ],
    [
        86.1819345,
        48.9048402
    ],
    [
        86.181591,
        48.9049953
    ],
    [
        86.181119,
        48.9049989
    ],
    [
        86.1803515,
        48.9049213
    ],
    [
        86.1796112,
        48.9047591
    ],
    [
        86.1784633,
        48.904618
    ],
    [
        86.1780877,
        48.904618
    ],
    [
        86.1777551,
        48.9046956
    ],
    [
        86.1774011,
        48.9048155
    ],
    [
        86.1761136,
        48.9054008
    ],
    [
        86.1752553,
        48.9056759
    ],
    [
        86.1746652,
        48.9057816
    ],
    [
        86.1741771,
        48.9058081
    ],
    [
        86.173823,
        48.9057587
    ],
    [
        86.1735441,
        48.905667
    ],
    [
        86.1712267,
        48.9046233
    ],
    [
        86.1702879,
        48.9042584
    ],
    [
        86.1700358,
        48.9042072
    ],
    [
        86.1697353,
        48.904179
    ],
    [
        86.1693867,
        48.9041879
    ],
    [
        86.1690111,
        48.9042302
    ],
    [
        86.1685391,
        48.9043501
    ],
    [
        86.166447,
        48.9050553
    ],
    [
        86.1662002,
        48.9051893
    ],
    [
        86.1658354,
        48.9055489
    ],
    [
        86.1656423,
        48.9056688
    ],
    [
        86.1653204,
        48.9058451
    ],
    [
        86.1646123,
        48.9060849
    ],
    [
        86.1638398,
        48.9062118
    ],
    [
        86.1619945,
        48.9063105
    ],
    [
        86.1609752,
        48.9064516
    ],
    [
        86.1600311,
        48.906642
    ],
    [
        86.1595269,
        48.9067055
    ],
    [
        86.1590977,
        48.9068112
    ],
    [
        86.1586364,
        48.9069734
    ],
    [
        86.1580892,
        48.9072908
    ],
    [
        86.157703,
        48.90746
    ],
    [
        86.1569412,
        48.9076645
    ],
    [
        86.1559649,
        48.907869
    ],
    [
        86.1551602,
        48.9080665
    ],
    [
        86.1541088,
        48.908412
    ],
    [
        86.1532612,
        48.9087223
    ],
    [
        86.1519845,
        48.9092582
    ],
    [
        86.1506756,
        48.9096531
    ],
    [
        86.1496885,
        48.9098294
    ],
    [
        86.1492755,
        48.9098629
    ],
    [
        86.1489643,
        48.9098629
    ],
    [
        86.1479397,
        48.9097447
    ],
    [
        86.1470814,
        48.9097518
    ],
    [
        86.1465342,
        48.9098294
    ],
    [
        86.1461587,
        48.9099281
    ],
    [
        86.1458154,
        48.910048
    ],
    [
        86.1455686,
        48.9102101
    ],
    [
        86.1448284,
        48.9108236
    ],
    [
        86.1444958,
        48.9109999
    ],
    [
        86.1435409,
        48.9113454
    ],
    [
        86.1432834,
        48.9114794
    ],
    [
        86.1429723,
        48.9117473
    ],
    [
        86.1425002,
        48.9123255
    ],
    [
        86.1419852,
        48.91308
    ],
    [
        86.1413415,
        48.9142011
    ],
    [
        86.140966,
        48.9145818
    ],
    [
        86.1405475,
        48.9148427
    ],
    [
        86.1401613,
        48.9149767
    ],
    [
        86.1398931,
        48.9149978
    ],
    [
        86.1396249,
        48.9149908
    ],
    [
        86.1388738,
        48.9149132
    ],
    [
        86.1380692,
        48.9149908
    ],
    [
        86.1336811,
        48.9158016
    ],
    [
        86.1331554,
        48.9159426
    ],
    [
        86.1324902,
        48.9161964
    ],
    [
        86.1319645,
        48.9164362
    ],
    [
        86.1314817,
        48.9166054
    ],
    [
        86.129885,
        48.9169712
    ],
    [
        86.1288874,
        48.9175435
    ],
    [
        86.1287783,
        48.9181848
    ],
    [
        86.128267,
        48.9184842
    ],
    [
        86.1278314,
        48.9194209
    ],
    [
        86.1279547,
        48.9200904
    ],
    [
        86.1275201,
        48.920599
    ],
    [
        86.1258306,
        48.9212589
    ],
    [
        86.1233513,
        48.9216073
    ],
    [
        86.122188,
        48.9215884
    ],
    [
        86.1203803,
        48.9218451
    ],
    [
        86.1192096,
        48.9218036
    ],
    [
        86.1182319,
        48.9220267
    ],
    [
        86.1171863,
        48.9226869
    ],
    [
        86.1169962,
        48.9233026
    ],
    [
        86.1163349,
        48.9236518
    ],
    [
        86.1155975,
        48.9236684
    ],
    [
        86.1143754,
        48.9230884
    ],
    [
        86.1136693,
        48.9229635
    ],
    [
        86.1126544,
        48.9225864
    ],
    [
        86.1121756,
        48.9225531
    ],
    [
        86.1103811,
        48.9238532
    ],
    [
        86.108225,
        48.9259331
    ],
    [
        86.1077052,
        48.9274082
    ],
    [
        86.1070168,
        48.9278415
    ],
    [
        86.1056439,
        48.928067
    ],
    [
        86.1045983,
        48.9287629
    ],
    [
        86.104191,
        48.9293338
    ],
    [
        86.1029812,
        48.9308242
    ],
    [
        86.1023322,
        48.9313282
    ],
    [
        86.1022169,
        48.9317537
    ],
    [
        86.1029145,
        48.9330209
    ],
    [
        86.1031334,
        48.9334186
    ],
    [
        86.1036071,
        48.93383
    ],
    [
        86.1036244,
        48.9344876
    ],
    [
        86.1031362,
        48.935765
    ],
    [
        86.1027553,
        48.9371235
    ],
    [
        86.1025354,
        48.9376186
    ],
    [
        86.1016127,
        48.9389224
    ],
    [
        86.1015376,
        48.9391338
    ],
    [
        86.1015376,
        48.93931
    ],
    [
        86.1016771,
        48.9396271
    ],
    [
        86.102117,
        48.9400711
    ],
    [
        86.1026105,
        48.9404587
    ],
    [
        86.1026641,
        48.9406701
    ],
    [
        86.1026373,
        48.9407952
    ],
    [
        86.1024227,
        48.9410489
    ],
    [
        86.1019131,
        48.9414593
    ],
    [
        86.1016181,
        48.9417888
    ],
    [
        86.1015269,
        48.9420583
    ],
    [
        86.1015322,
        48.942268
    ],
    [
        86.1017307,
        48.9430942
    ],
    [
        86.1019131,
        48.9433761
    ],
    [
        86.1021009,
        48.9435012
    ],
    [
        86.1023959,
        48.9436298
    ],
    [
        86.102648,
        48.9438042
    ],
    [
        86.1028251,
        48.9440596
    ],
    [
        86.1028358,
        48.9442922
    ],
    [
        86.1026963,
        48.9445247
    ],
    [
        86.1024495,
        48.9447502
    ],
    [
        86.1012426,
        48.9456081
    ],
    [
        86.1010602,
        48.9459041
    ],
    [
        86.1009475,
        48.9462229
    ],
    [
        86.1007758,
        48.9470896
    ],
    [
        86.1005988,
        48.9477009
    ],
    [
        86.1003413,
        48.94818
    ],
    [
        86.0999873,
        48.9485534
    ],
    [
        86.0993382,
        48.9491048
    ],
    [
        86.09907,
        48.94945
    ],
    [
        86.0989305,
        48.9496825
    ],
    [
        86.0988339,
        48.9499785
    ],
    [
        86.0988017,
        48.9502251
    ],
    [
        86.0988447,
        48.9503871
    ],
    [
        86.0992738,
        48.9508944
    ],
    [
        86.0993811,
        48.9510776
    ],
    [
        86.0994026,
        48.9512819
    ],
    [
        86.0993382,
        48.9514651
    ],
    [
        86.0991773,
        48.9516483
    ],
    [
        86.0982224,
        48.9523247
    ],
    [
        86.0978147,
        48.9527756
    ],
    [
        86.0975036,
        48.9532617
    ],
    [
        86.0973319,
        48.953621
    ],
    [
        86.0971388,
        48.9538817
    ],
    [
        86.0969135,
        48.9541071
    ],
    [
        86.0959801,
        48.9548046
    ],
    [
        86.0957172,
        48.9549156
    ],
    [
        86.0955294,
        48.9549667
    ],
    [
        86.0945531,
        48.9549455
    ],
    [
        86.0941293,
        48.9550001
    ],
    [
        86.0936412,
        48.9551076
    ],
    [
        86.0932925,
        48.9552749
    ],
    [
        86.0930672,
        48.9555003
    ],
    [
        86.0921445,
        48.9565007
    ],
    [
        86.0917851,
        48.9570097
    ],
    [
        86.0914739,
        48.9576085
    ],
    [
        86.0912057,
        48.9582355
    ],
    [
        86.091077,
        48.9587145
    ],
    [
        86.0910066,
        48.9596074
    ],
    [
        86.0901153,
        48.9612186
    ],
    [
        86.0888102,
        48.9635804
    ],
    [
        86.0877429,
        48.964683
    ],
    [
        86.0864396,
        48.9664753
    ],
    [
        86.0850858,
        48.9677511
    ],
    [
        86.0850501,
        48.9683246
    ],
    [
        86.0848736,
        48.9689577
    ],
    [
        86.0847176,
        48.969208
    ],
    [
        86.0833083,
        48.9698352
    ],
    [
        86.0827221,
        48.9700426
    ],
    [
        86.0823388,
        48.9703752
    ],
    [
        86.0821777,
        48.9708176
    ],
    [
        86.0818317,
        48.971224
    ],
    [
        86.0816198,
        48.9716325
    ],
    [
        86.0815106,
        48.9717295
    ],
    [
        86.0812738,
        48.9718139
    ],
    [
        86.0797761,
        48.9720029
    ],
    [
        86.0792315,
        48.9719749
    ],
    [
        86.0787626,
        48.9721508
    ],
    [
        86.0783435,
        48.972222
    ],
    [
        86.077972,
        48.9722047
    ],
    [
        86.0765052,
        48.9718396
    ],
    [
        86.0757564,
        48.9717223
    ],
    [
        86.0749536,
        48.9717523
    ],
    [
        86.0740686,
        48.9720131
    ],
    [
        86.073313,
        48.9723966
    ],
    [
        86.0726307,
        48.9727646
    ],
    [
        86.0720068,
        48.9729264
    ],
    [
        86.0712235,
        48.9729671
    ],
    [
        86.0704572,
        48.9729416
    ],
    [
        86.0693652,
        48.973078
    ],
    [
        86.0676709,
        48.9736198
    ],
    [
        86.0668615,
        48.9742333
    ],
    [
        86.0659079,
        48.9746668
    ],
    [
        86.0647746,
        48.975922
    ],
    [
        86.0640334,
        48.9765541
    ],
    [
        86.0633739,
        48.976805
    ],
    [
        86.0627386,
        48.9767391
    ],
    [
        86.0625719,
        48.9767217
    ],
    [
        86.061256,
        48.9763514
    ],
    [
        86.0603054,
        48.9763637
    ],
    [
        86.059011,
        48.9767915
    ],
    [
        86.0576139,
        48.9776985
    ],
    [
        86.0560718,
        48.9789262
    ],
    [
        86.0551239,
        48.9796966
    ],
    [
        86.0547949,
        48.9799874
    ],
    [
        86.054279,
        48.980738
    ],
    [
        86.0538725,
        48.9811542
    ],
    [
        86.053097,
        48.9814724
    ],
    [
        86.0523131,
        48.9815346
    ],
    [
        86.0518355,
        48.9816169
    ],
    [
        86.0513341,
        48.9817659
    ],
    [
        86.0509446,
        48.9819637
    ],
    [
        86.0507176,
        48.9822839
    ],
    [
        86.0497928,
        48.9826618
    ],
    [
        86.0493931,
        48.9827129
    ],
    [
        86.0490205,
        48.9827285
    ],
    [
        86.0474077,
        48.9826695
    ],
    [
        86.0469861,
        48.9827449
    ],
    [
        86.0462819,
        48.9830609
    ],
    [
        86.0460999,
        48.9830923
    ],
    [
        86.0458076,
        48.9830295
    ],
    [
        86.0456639,
        48.9830955
    ],
    [
        86.0455873,
        48.9832747
    ],
    [
        86.0457262,
        48.9838563
    ],
    [
        86.0458555,
        48.9841802
    ],
    [
        86.0457789,
        48.9846706
    ],
    [
        86.0454723,
        48.9851233
    ],
    [
        86.0451896,
        48.9857207
    ],
    [
        86.0450603,
        48.9860571
    ],
    [
        86.0450459,
        48.9864532
    ],
    [
        86.0456591,
        48.9874529
    ],
    [
        86.0455969,
        48.9876352
    ],
    [
        86.0453621,
        48.9878333
    ],
    [
        86.0447345,
        48.9880313
    ],
    [
        86.0442842,
        48.9882671
    ],
    [
        86.0441932,
        48.9884746
    ],
    [
        86.044198,
        48.9886789
    ],
    [
        86.0443369,
        48.9888864
    ],
    [
        86.0448112,
        48.9892134
    ],
    [
        86.0450124,
        48.9895183
    ],
    [
        86.045022,
        48.9901627
    ],
    [
        86.0447489,
        48.9907286
    ],
    [
        86.0442459,
        48.9913132
    ],
    [
        86.0438195,
        48.9921997
    ],
    [
        86.0436806,
        48.9926146
    ],
    [
        86.0434986,
        48.9929007
    ],
    [
        86.042756,
        48.9932747
    ],
    [
        86.042574,
        48.9935136
    ],
    [
        86.0425835,
        48.9937525
    ],
    [
        86.0426794,
        48.9941737
    ],
    [
        86.042871,
        48.9945132
    ],
    [
        86.0429812,
        48.994837
    ],
    [
        86.0430051,
        48.9950664
    ],
    [
        86.0429093,
        48.9953996
    ],
    [
        86.0426219,
        48.9956856
    ],
    [
        86.0420853,
        48.9959088
    ],
    [
        86.0415488,
        48.9962263
    ],
    [
        86.0406769,
        48.9965752
    ],
    [
        86.0398768,
        48.9967543
    ],
    [
        86.0390169,
        48.9967855
    ],
    [
        86.0380579,
        48.9966774
    ],
    [
        86.0373086,
        48.9967261
    ],
    [
        86.0369122,
        48.9971471
    ],
    [
        86.0369114,
        48.9973955
    ],
    [
        86.0368635,
        48.9980619
    ],
    [
        86.0367294,
        48.9983887
    ],
    [
        86.0364707,
        48.9986685
    ],
    [
        86.035776,
        48.9991839
    ],
    [
        86.0356515,
        48.9994196
    ],
    [
        86.0355796,
        48.9999131
    ],
    [
        86.0354503,
        49.00013
    ],
    [
        86.0351102,
        49.0004411
    ],
    [
        86.0346263,
        49.0006988
    ],
    [
        86.0340897,
        49.0009062
    ],
    [
        86.0338502,
        49.0011262
    ],
    [
        86.0336107,
        49.0017454
    ],
    [
        86.0331699,
        49.0020157
    ],
    [
        86.0326334,
        49.0024745
    ],
    [
        86.0320825,
        49.0036939
    ],
    [
        86.032207,
        49.0038228
    ],
    [
        86.0325424,
        49.0039013
    ],
    [
        86.0326909,
        49.0039987
    ],
    [
        86.0327867,
        49.004181
    ],
    [
        86.0327053,
        49.0044576
    ],
    [
        86.032322,
        49.0048389
    ],
    [
        86.0318477,
        49.0052223
    ],
    [
        86.0316753,
        49.0057785
    ],
    [
        86.0317615,
        49.0062939
    ],
    [
        86.0316753,
        49.0066647
    ],
    [
        86.0315411,
        49.0069664
    ],
    [
        86.0312681,
        49.007133
    ],
    [
        86.0309088,
        49.0072178
    ],
    [
        86.030401,
        49.0072147
    ],
    [
        86.0297782,
        49.0071801
    ],
    [
        86.0290213,
        49.0073435
    ],
    [
        86.0284847,
        49.0075226
    ],
    [
        86.0280967,
        49.0077615
    ],
    [
        86.027838,
        49.0080977
    ],
    [
        86.0277278,
        49.0087922
    ],
    [
        86.0277757,
        49.0090561
    ],
    [
        86.0280536,
        49.0094301
    ],
    [
        86.0281474,
        49.0099847
    ],
    [
        86.0280899,
        49.0105063
    ],
    [
        86.027745,
        49.0108771
    ],
    [
        86.0274384,
        49.01165
    ],
    [
        86.027036,
        49.0123979
    ],
    [
        86.0264132,
        49.0129886
    ],
    [
        86.0260587,
        49.0136359
    ],
    [
        86.0263461,
        49.0138872
    ],
    [
        86.0264419,
        49.014478
    ],
    [
        86.0264228,
        49.0147796
    ],
    [
        86.0267294,
        49.0154583
    ],
    [
        86.0266623,
        49.0160678
    ],
    [
        86.0261162,
        49.0167025
    ],
    [
        86.0255221,
        49.0171612
    ],
    [
        86.0255221,
        49.0178524
    ],
    [
        86.0254646,
        49.0185121
    ],
    [
        86.0252347,
        49.018996
    ],
    [
        86.0253401,
        49.019373
    ],
    [
        86.025982,
        49.0197814
    ],
    [
        86.0262695,
        49.0203029
    ],
    [
        86.0262599,
        49.0206611
    ],
    [
        86.0259724,
        49.0212014
    ],
    [
        86.0255221,
        49.0218549
    ],
    [
        86.0251676,
        49.0224643
    ],
    [
        86.0247173,
        49.0227973
    ],
    [
        86.0245257,
        49.0234508
    ],
    [
        86.0247173,
        49.0239597
    ],
    [
        86.024976,
        49.0243429
    ],
    [
        86.0251772,
        49.0252853
    ],
    [
        86.0255509,
        49.0265733
    ],
    [
        86.0256179,
        49.0274905
    ],
    [
        86.0259916,
        49.0280497
    ],
    [
        86.0268348,
        49.0286967
    ],
    [
        86.0269497,
        49.0291491
    ],
    [
        86.0267485,
        49.0302924
    ],
    [
        86.0269306,
        49.0307259
    ],
    [
        86.0273905,
        49.0315111
    ],
    [
        86.0277546,
        49.0324534
    ],
    [
        86.0279934,
        49.0330248
    ],
    [
        86.028057,
        49.0333759
    ],
    [
        86.027887,
        49.0337236
    ],
    [
        86.027491,
        49.0338545
    ],
    [
        86.027247,
        49.0340121
    ],
    [
        86.027191,
        49.0340625
    ],
    [
        86.0267302,
        49.0344767
    ],
    [
        86.0265463,
        49.0346463
    ],
    [
        86.0263355,
        49.0349855
    ],
    [
        86.0261371,
        49.0352246
    ],
    [
        86.0258372,
        49.0352933
    ],
    [
        86.0254252,
        49.0353686
    ],
    [
        86.0252624,
        49.0357518
    ],
    [
        86.0249941,
        49.0361224
    ],
    [
        86.024448,
        49.0365432
    ],
    [
        86.0239785,
        49.0367819
    ],
    [
        86.0234228,
        49.0369201
    ],
    [
        86.023212,
        49.0371337
    ],
    [
        86.0228766,
        49.0371965
    ],
    [
        86.0221772,
        49.037096
    ],
    [
        86.0216406,
        49.0370834
    ],
    [
        86.0211137,
        49.037052
    ],
    [
        86.0207017,
        49.0371462
    ],
    [
        86.0205292,
        49.0373724
    ],
    [
        86.0202322,
        49.0375168
    ],
    [
        86.0198863,
        49.0375189
    ],
    [
        86.0193986,
        49.037655
    ],
    [
        86.018956,
        49.0378234
    ],
    [
        86.0185263,
        49.037914
    ],
    [
        86.0182393,
        49.0381826
    ],
    [
        86.0177602,
        49.0382643
    ],
    [
        86.0174249,
        49.0382203
    ],
    [
        86.0168308,
        49.0383585
    ],
    [
        86.016417,
        49.038302
    ],
    [
        86.0153937,
        49.0384778
    ],
    [
        86.014905,
        49.0386411
    ],
    [
        86.0142822,
        49.0387919
    ],
    [
        86.013899,
        49.0389678
    ],
    [
        86.013669,
        49.0392001
    ],
    [
        86.012941,
        49.0394702
    ],
    [
        86.0127685,
        49.0397026
    ],
    [
        86.0126823,
        49.0400041
    ],
    [
        86.0122703,
        49.0401548
    ],
    [
        86.0120882,
        49.0403809
    ],
    [
        86.0120786,
        49.0406322
    ],
    [
        86.0119253,
        49.0408834
    ],
    [
        86.0109097,
        49.0411409
    ],
    [
        86.0106319,
        49.041411
    ],
    [
        86.0105169,
        49.0417376
    ],
    [
        86.0103444,
        49.0420265
    ],
    [
        86.0099229,
        49.0421081
    ],
    [
        86.0095396,
        49.0422965
    ],
    [
        86.0090222,
        49.0426797
    ],
    [
        86.0087635,
        49.0428304
    ],
    [
        86.0084761,
        49.0430251
    ],
    [
        86.0083132,
        49.0433579
    ],
    [
        86.0081695,
        49.0435966
    ],
    [
        86.0078246,
        49.0437285
    ],
    [
        86.0073838,
        49.0438478
    ],
    [
        86.0072209,
        49.0440299
    ],
    [
        86.0071155,
        49.0442937
    ],
    [
        86.0069527,
        49.0446015
    ],
    [
        86.006694,
        49.0448903
    ],
    [
        86.0066461,
        49.0451227
    ],
    [
        86.0063778,
        49.045242
    ],
    [
        86.0061095,
        49.0451918
    ],
    [
        86.0058221,
        49.0450976
    ],
    [
        86.0052759,
        49.045173
    ],
    [
        86.0047969,
        49.0453174
    ],
    [
        86.0040974,
        49.0453614
    ],
    [
        86.0037525,
        49.0453614
    ],
    [
        86.0031872,
        49.0455121
    ],
    [
        86.002986,
        49.0456816
    ],
    [
        86.002392,
        49.0460208
    ],
    [
        86.0019704,
        49.0462845
    ],
    [
        86.001338,
        49.0464981
    ],
    [
        86.0009931,
        49.0467681
    ],
    [
        86.0008781,
        49.0470256
    ],
    [
        86.000629,
        49.047214
    ],
    [
        86.0001883,
        49.0472893
    ],
    [
        85.9991822,
        49.0475154
    ],
    [
        85.9986936,
        49.0478106
    ],
    [
        85.9981091,
        49.047955
    ],
    [
        85.9976301,
        49.0479173
    ],
    [
        85.9971031,
        49.047955
    ],
    [
        85.9967582,
        49.0482564
    ],
    [
        85.996557,
        49.0483946
    ],
    [
        85.9957617,
        49.0487588
    ],
    [
        85.9953402,
        49.0487839
    ],
    [
        85.9950431,
        49.0489786
    ],
    [
        85.9945928,
        49.0496819
    ],
    [
        85.9942287,
        49.0500964
    ],
    [
        85.9936634,
        49.050335
    ],
    [
        85.9925712,
        49.0503413
    ],
    [
        85.9910416,
        49.0500764
    ],
    [
        85.9887457,
        49.050428
    ],
    [
        85.9858918,
        49.0512577
    ],
    [
        85.9828663,
        49.0514265
    ],
    [
        85.9802699,
        49.0512015
    ],
    [
        85.9775018,
        49.0507515
    ],
    [
        85.9731459,
        49.0497952
    ],
    [
        85.9708714,
        49.049092
    ],
    [
        85.9690904,
        49.0479247
    ],
    [
        85.9673095,
        49.0471652
    ],
    [
        85.9661937,
        49.0463214
    ],
    [
        85.96349,
        49.0462229
    ],
    [
        85.9611296,
        49.0453931
    ],
    [
        85.9582329,
        49.0452384
    ],
    [
        85.9570098,
        49.0444789
    ],
    [
        85.954628,
        49.0441273
    ],
    [
        85.951581,
        49.0433256
    ],
    [
        85.9506368,
        49.0426364
    ],
    [
        85.9473967,
        49.0420738
    ],
    [
        85.9443283,
        49.040836
    ],
    [
        85.9418821,
        49.0399357
    ],
    [
        85.939157,
        49.0396966
    ],
    [
        85.9371614,
        49.0386557
    ],
    [
        85.934608,
        49.0366019
    ],
    [
        85.9332347,
        49.0363769
    ],
    [
        85.9329557,
        49.0360111
    ],
    [
        85.9316039,
        49.0357298
    ],
    [
        85.9308958,
        49.0353359
    ],
    [
        85.9305739,
        49.034112
    ],
    [
        85.9298658,
        49.0332538
    ],
    [
        85.9270978,
        49.033085
    ],
    [
        85.9248447,
        49.0325364
    ],
    [
        85.9243083,
        49.0320299
    ],
    [
        85.9247374,
        49.0308059
    ],
    [
        85.9236431,
        49.0300884
    ],
    [
        85.9240293,
        49.0285408
    ],
    [
        85.922656,
        49.0278373
    ],
    [
        85.9220552,
        49.0274293
    ],
    [
        85.921905,
        49.0270353
    ],
    [
        85.9210414,
        49.0270617
    ],
    [
        85.9200382,
        49.0267434
    ],
    [
        85.9192228,
        49.0256846
    ],
    [
        85.9176564,
        49.0246293
    ],
    [
        85.9162617,
        49.0243761
    ],
    [
        85.9156823,
        49.0241509
    ],
    [
        85.9147596,
        49.0239117
    ],
    [
        85.9144163,
        49.0236163
    ],
    [
        85.9144136,
        49.0233507
    ],
    [
        85.9148857,
        49.023027
    ],
    [
        85.9149313,
        49.0227087
    ],
    [
        85.9141454,
        49.021984
    ],
    [
        85.9138772,
        49.0216533
    ],
    [
        85.9131288,
        49.0210272
    ],
    [
        85.911262,
        49.0203095
    ],
    [
        85.9101677,
        49.0201829
    ],
    [
        85.9094167,
        49.0193245
    ],
    [
        85.9080863,
        49.0188179
    ],
    [
        85.9078717,
        49.018072
    ],
    [
        85.9071207,
        49.0171151
    ],
    [
        85.906949,
        49.0162707
    ],
    [
        85.9052753,
        49.0154545
    ],
    [
        85.90343,
        49.0153841
    ],
    [
        85.9024644,
        49.0148353
    ],
    [
        85.9017885,
        49.0136952
    ],
    [
        85.901016,
        49.0128015
    ],
    [
        85.8912849,
        49.008319
    ],
    [
        85.8741187,
        49.0124286
    ],
    [
        85.8615016,
        49.0181143
    ],
    [
        85.8488845,
        49.0173824
    ],
    [
        85.8397864,
        49.0159752
    ],
    [
        85.8219337,
        49.0078122
    ],
    [
        85.8017634,
        49.006855
    ],
    [
        85.7881164,
        49.0003799
    ],
    [
        85.7768725,
        48.979879
    ],
    [
        85.7569598,
        48.9687238
    ],
    [
        85.7449435,
        48.9634834
    ],
    [
        85.7231425,
        48.9641033
    ],
    [
        85.702629,
        48.9616802
    ],
    [
        85.6893252,
        48.9497318
    ],
    [
        85.6660651,
        48.9350743
    ],
    [
        85.6407451,
        48.9176488
    ],
    [
        85.6270122,
        48.9106544
    ],
    [
        85.585041,
        48.9049001
    ],
    [
        85.5394649,
        48.9099775
    ],
    [
        85.5539702,
        48.9347924
    ],
    [
        85.5491637,
        48.9481535
    ],
    [
        85.5560302,
        48.9714284
    ],
    [
        85.5739688,
        48.9856246
    ],
    [
        85.5769067,
        48.9900562
    ],
    [
        85.5805778,
        48.9955934
    ],
    [
        85.5755996,
        49.0149056
    ],
    [
        85.5683898,
        49.0216603
    ],
    [
        85.5628109,
        49.0309747
    ],
    [
        85.5439281,
        49.0355891
    ],
    [
        85.5279636,
        49.0359829
    ],
    [
        85.5177927,
        49.0340978
    ],
    [
        85.5111837,
        49.0259097
    ],
    [
        85.4897689,
        49.021801
    ],
    [
        85.4727745,
        49.0176357
    ],
    [
        85.3986597,
        49.0143708
    ],
    [
        85.3784895,
        49.0145397
    ],
    [
        85.3661727,
        49.0160877
    ],
    [
        85.3496933,
        49.0167633
    ],
    [
        85.3378486,
        49.0091352
    ],
    [
        85.304203,
        48.998775
    ],
    [
        85.2894401,
        48.9960439
    ],
    [
        85.2877664,
        49.0025758
    ],
    [
        85.2683687,
        49.009473
    ],
    [
        85.2516746,
        49.0048844
    ],
    [
        85.2372551,
        49.0208723
    ],
    [
        85.1826667,
        49.029821
    ],
    [
        85.151081,
        49.047545
    ],
    [
        85.0981235,
        49.0481638
    ]
]