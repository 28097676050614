import React, {useContext, useEffect, useState} from 'react';
import LineChart from "../../../Charts/LineChart";
import {IPowerPlantAverage} from "../../../../types/power-generation";
import {getPowerPlantAverage} from "../../../../services/hcs";
import dayjs from "dayjs";
import {AppDataContext} from "../../../../contexts/AppDataContext";
import {ILineChartData} from "../../../../types/chart";
import { addSpaces } from '../../../../utils';

interface IPowerGenerationModal {
    id?: number;
}

const PowerGenerationModal: React.FC<IPowerGenerationModal> = ({id}) => {
    const {dateRange} = useContext(AppDataContext);
    const tableHeader = ["Дата", "Наименование эл/станции", "Суточная выроботка э/э мВт.ч", "Расход воды куб. м/сек", "Уровень ВБ, м"];
    const [loading, setLoading] = useState<boolean>(false);
    const [powerPlantAverage, setPowerPlantAverage] = useState<IPowerPlantAverage[]>([]);
    const [averageDailyOutput, setAverageDailyOutput] = useState<ILineChartData>();
    const [averageSettlement, setAverageSettlement] = useState<ILineChartData>();

    useEffect(() => {
        loadPowerPlantAverage();
    }, []);

    const loadPowerPlantAverage = async (): Promise<void> => {
        setLoading(true);
        const response = await getPowerPlantAverage(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"), id && id);

        if (!response.isError) {
            setPowerPlantAverage(response.data);
            setAverageDailyOutput(getPowerPlantChartData(response.data, true));
            setAverageSettlement(getPowerPlantChartData(response.data));
        } else {
            setPowerPlantAverage([]);
        }
        setLoading(false);
    };

    const getPowerPlantChartData = (data: IPowerPlantAverage[], dailyOutput?: boolean) => {
        let days: string[] = [];
        let ridder: any[] = [];
        let bges: any[] = [];
        let ukGes: any[] = [];
        let ukTc: any[] = [];
        let sTc: any[] = [];
        let lenGes: any[] = [];

        data.forEach((plant) => {
            plant.daily_averages.map((item) => {
                const date = dayjs(item.date).format("DD.MM");
                if (!days.includes(date)) days.push(date);
                if (plant.power_plant === "Риддер ТЭЦ")
                    dailyOutput ? ridder.push(item.average_daily_output) : ridder.push(
                        item.average_settlement ? item.average_settlement : "");
                if (plant.power_plant === "БГЭС")
                    dailyOutput ? bges.push(item.average_daily_output) : bges.push(
                        item.average_daily_output ? item.average_settlement : "");
                if (plant.power_plant === "УК ГЭС")
                    dailyOutput ? ukGes.push(item.average_daily_output) : ukGes.push(
                        item.average_daily_output ? item.average_settlement : "");
                if (plant.power_plant === "УК ТЭЦ")
                    dailyOutput ? ukTc.push(item.average_daily_output) : ukTc.push(
                        item.average_daily_output ? item.average_settlement : "");
                if (plant.power_plant === "СТЭЦ")
                    dailyOutput ? sTc.push(item.average_daily_output) : sTc.push(
                        item.average_daily_output ? item.average_settlement : "");
                if (plant.power_plant === "ЛенГЭС")
                    dailyOutput ? lenGes.push(item.average_daily_output) : lenGes.push(
                        item.average_daily_output ? item.average_settlement : "");
            });
        });

        return {
            labels: days,
            datasets: [
                {
                    label: 'Риддер ТЭЦ',
                    data: ridder,
                    borderColor: '#08293E',
                    backgroundColor: '#08293E',

                },
                {
                    label: 'БГЭС',
                    data: bges,
                    borderColor: '#1F5673',
                    backgroundColor: '#1F5673'
                },
                {
                    label: 'УК ГЭС',
                    data: ukGes,
                    borderColor: '#BCD0CF',
                    backgroundColor: '#BCD0CF'
                },
                {
                    label: 'УК ТЭЦ',
                    data: ukTc,
                    borderColor: '#E0C78D',
                    backgroundColor: '#E0C78D'
                },
                {
                    label: 'СТЭЦ',
                    data: sTc,
                    borderColor: '#E9DB46',
                    backgroundColor: '#E9DB46'
                },
                {
                    label: 'ЛенГЭС',
                    data: lenGes,
                    borderColor: '#F2730E',
                    backgroundColor: '#F2730E'
                }
            ]
        }
    };

    return (
        <div>
            {loading ? (
                <div className="loader">
                    Загрузка...
                </div>
            ) : (
                <>
                    {averageDailyOutput &&
                        <>
                            <div className="modal-title" style={{margin: "20px 0"}}>Суточная выроботка</div>
                            <LineChart
                                data={averageDailyOutput}
                                chartWidth={1000}
                                chartHeight={200}
                                isLegend={true}
                            />
                        </>
                    }

                    {averageSettlement &&
                        <>
                            <div className="modal-title" style={{margin: "20px 0"}}>Расход воды на выроботку</div>
                            <LineChart
                                data={averageSettlement}
                                chartWidth={1000}
                                chartHeight={200}
                                isLegend={true}
                            />
                        </>
                    }

                    <div className="table-border power-generation-statistic-table" style={{marginTop: "20px"}}>
                        <table className="table">
                            <thead className="table-header">
                                {tableHeader.map((item, index) =>
                                    <td key={index} className='cell'>
                                        {item}
                                    </td>
                                )}
                            </thead>

                            <tbody>
                                {powerPlantAverage.map((powerPlant) =>
                                    powerPlant.daily_averages.map((item, index) =>
                                        <tr key={index} className="row">
                                            <td className="cell">{dayjs(item.date).format("DD.MM.YYYY")}</td>
                                            <td className="cell">{powerPlant.power_plant}</td>
                                            <td className="cell">{addSpaces(item.average_daily_output.toString())}</td>
                                            <td className="cell">{item.average_settlement ? addSpaces(item.average_settlement.toString()) : "-"}</td>
                                            <td className="cell">{item.average_level ? addSpaces(item.average_level.toString()) : "-"}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default PowerGenerationModal;