import React from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Arrow} from "../../Icons";
import {getDistrictNameById} from "../../../utils";
import WaterStatisticCard from "./WaterStatisticCard";
import WaterSliderCard from "./WaterSliderCard";

const WaterSideInfo = () => {
    const params = useParams();
    const districtId = params.id;
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="side-info" style={{overflowY: "scroll"}}>
            {districtId &&
                <div className="side-info-district" id="side-info-district-name">
                    <div
                        className="side-info-card back-btn floods-back-btn"
                        onClick={() => location.pathname.includes("water-supply") ? navigate("/water-supply") : navigate("/water-disposal")}
                    >
                        <Arrow />
                    </div>
                    <div className="side-info-card side-info-district-name">
                        {getDistrictNameById(Number(districtId))}
                    </div>
                </div>
            }

            <WaterStatisticCard />
            <WaterSliderCard />
        </div>
    );
};

export default WaterSideInfo;