import React, {useContext, useEffect, useRef, useState} from 'react';
import {divIcon, DivIconOptions, LatLngExpression} from "leaflet";
import ReactDOMServer from "react-dom/server";
import {Marker} from "react-leaflet";
import {getSumString} from "../../../utils";
import { DebtsDistrict } from '../../../types/debts';
import Modal from '../../UI/Modal';
import DebtsGeneralModal from '../../HCSPages/DebtsPage/modals/DebtsGeneralModal';
import { DebtsContext } from '../../../contexts/DebtsContext';
import {DebtsMapIcon} from "../../Icons";

const DebtsMarker: React.FC<{point: DebtsDistrict}> = ({point}) => {
    const markerRef = useRef<any>(null);
    const coord = [parseFloat(point.latitude), parseFloat(point.longitude)];
    const [isModal, setIsModal] = useState<boolean>(false);
    const [chosenLocality, setChosenLocality] = useState<DebtsDistrict | null>(null);
    const { text } = useContext(DebtsContext);
    const createDebtsMarker = () => {
        const options: DivIconOptions = {
            className: "marker-wrapper",
            html: ReactDOMServer.renderToString(
                <div className="info-marker">
                    <DebtsMapIcon />
                    <div className="info-marker-sum">{getSumString(parseInt(point.total_outstanding_amount)) + " ₸"}</div>
                </div>
            )}
        return divIcon(options);
    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    return (
        <>
            <Marker
                position={coord as LatLngExpression}
                icon={createDebtsMarker()}
                ref={markerRef}
                eventHandlers={{
                    click: () => {setIsModal(true); setChosenLocality(point)},
                }}
            />

            {chosenLocality && 
                <Modal
                    isOpen={isModal}
                    onClose={() => {
                        setIsModal(false);
                        setChosenLocality(null)
                    }}
                    title={chosenLocality.name}
                >
                    <DebtsGeneralModal text={text} id={chosenLocality.id}/>
                </Modal>
            }
        </>
    );
};

export default DebtsMarker;