import { useEffect, useRef, useState } from "react";
import { Cancel, SearchIcon } from "../Icons";

interface ISeacrh {
    value: string;
    onCancel: () => void;
    onChange: (text: string) => void;
    placeholder?: string;
    withCheckboxes?: boolean;
    checkboxes?: string[];
    selected?: string[] | number[];
    setSelected?: (options: any) => void;
    tooltipId?: string;
    options?: {id: number, label: string}[];
}

const Search: React.FC<ISeacrh> = ({value, placeholder, onChange, onCancel, withCheckboxes, checkboxes, selected, setSelected,tooltipId, options}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [filteredOptions, setFilteredOptions] = useState<string[]>(checkboxes!);

    useEffect(() => {
        if (checkboxes) {
            setFilteredOptions(checkboxes!.filter((option) => option.toLowerCase().includes(value.toLowerCase())));
        }
    }, [value]);

    return (
        <>
            <div className="input-wrapper">
                <SearchIcon />

                <div className="input-container">
                    <input
                        ref={inputRef}
                        className="input"
                        value={value}
                        type="text"
                        placeholder={placeholder}
                        onChange={(e) => onChange(e.target.value)}
                    />
                    
                    {(value.length > 0) && 
                        <span className="cancel" onClick={onCancel}><Cancel /></span>
                    }
                </div>
            </div>

            {withCheckboxes && (
                <div className="checkbox-wrapper">
                    {options ?
                        options.map((option, i) => (
                            <div key={i} className={(selected as string[]).includes(option.label) ? "checkbox-container" : "checkbox-unselected"}>
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    id={`checkbox-${i}`}
                                    value={option.label}
                                    checked={(selected as string[]).includes(option.label)}
                                    onChange={(e) =>
                                        setSelected!((prevState: any) => ({
                                            ...prevState,
                                            selected: prevState.selected.includes(e.target.value)
                                                ? prevState.selected.filter((v: any) => v !== e.target.value)
                                                : [...prevState.selected, e.target.value],
                                            selectedIds: prevState.selectedIds.includes(option.id)
                                                ? prevState.selectedIds.filter((v: any) => v !== option.id)
                                                : [...prevState.selectedIds, option.id],
                                        }))
                                    }
                                />
                                <label
                                    htmlFor={`checkbox-${i}`}
                                    className="checkbox-label"
                                    data-tooltip-id={tooltipId}
                                    data-tooltip-content={option.label}
                                >
                                    {option.label}
                                </label>
                            </div>
                        ))
                    :
                        <>
                            {filteredOptions.map((option, i) => (
                                <div key={i} className={(selected as string[]).includes(option) ? "checkbox-container" : "checkbox-unselected"}>
                                    <input
                                        className="checkbox"
                                        type="checkbox"
                                        id={`checkbox-${i}`}
                                        value={option}
                                        checked={(selected as string[]).includes(option)}
                                        onChange={(e) =>
                                            setSelected!((prevState: any) => ({
                                                ...prevState,
                                                selected: prevState.selected!.includes(e.target.value)
                                                    ? prevState.selected!.filter((v: any) => v !== e.target.value)
                                                    : [...prevState.selected!, e.target.value],
                                            }))
                                        }
                                    />
                                    <label
                                        htmlFor={`checkbox-${i}`}
                                        className="checkbox-label"
                                        data-tooltip-id={tooltipId}
                                        data-tooltip-content={option}
                                    >
                                        {option}
                                    </label>
                                </div>
                            ))}
                        </>
                    }
                </div>
            )}
        </>
    )
}

export default Search;