import React, { useEffect, useState } from "react";
import BarChart from "../Charts/BarChart";
import CardCarousel from "../CardCarousel/CardCarousel";

const PopulationCommunicationsStatuses: React.FC = () => {
    const [info, setInfo] = useState({
        "4G": {
            purals: 0,
            people: 0,
        },
        "3G": {
            purals: 0,
            people: 0,
        },
        "2G": {
            purals: 0,
            people: 0,
        },
        "N/A": {
            purals: 0,
            people: 0,
        },
    });

    const names = ["4G", "3G", "2G", "N/A"];

    const getChartDataByPural = () => {
        return {
            labels: names,
            datasets: [
                {
                    data: [
                        info["4G"].purals,
                        info["3G"].purals,
                        info["2G"].purals,
                        info["N/A"].purals,
                    ],
                    backgroundColor: "#9b59b6",
                },
            ],
        };
    };

    const getChartDataByPeople = () => {
        return {
            labels: names,
            datasets: [
                {
                    data: [
                        info["4G"].people,
                        info["3G"].people,
                        info["2G"].people,
                        info["N/A"].people,
                    ],
                    backgroundColor: "#4b7bec",
                },
            ],
        };
    };

    const fetchInfo = async () => {
        await new Promise((res) => setTimeout(res, 500));

        setInfo({
            "4G": {
                purals: 200,
                people: 100_000,
            },
            "3G": {
                purals: 100,
                people: 50_000,
            },
            "2G": {
                purals: 50,
                people: 25_000,
            },
            "N/A": {
                purals: 25,
                people: 10_000,
            },
        });
    };

    useEffect(() => {
        fetchInfo();
    }, []);

    return (
        <CardCarousel
            titles={{
                0: "Обеспеченность НП по видам связи",
                1: "Обеспеченность населения по видам связи",
            }}
        >
            <BarChart
                data={getChartDataByPural()}
                isLegend={false}
                responsive
                chartHeight={250}
                chartWidth={280}
                onlyGraph
                withTicksOnX={true}
            />

            <BarChart
                data={getChartDataByPeople()}
                isLegend={false}
                responsive
                chartHeight={250}
                chartWidth={280}
                onlyGraph
                withTicksOnX={true}
            />
        </CardCarousel>
    );
};

export default PopulationCommunicationsStatuses;
