import React from 'react';
import dayjs from "dayjs";
import {IPowerGenerationStatistic} from "../../../../types/power-generation";
import { addSpaces } from '../../../../utils';

interface IPowerGenerationStatisticModal {
    statistic: IPowerGenerationStatistic[];
}

const PowerGenerationStatisticModal: React.FC<IPowerGenerationStatisticModal> = ({statistic}) => {
    const tableHeader = ["Дата", "Потребитель", "План, мВтч", "Факт, мВтч", "Отклонение, мВтч"];

    return (
        <div className="table-border power-generation-statistic-table">
            <table className="table">
                <thead className="table-header">
                    {tableHeader.map((item, index) =>
                        <td key={index} className='cell'>
                            {item}
                        </td>
                    )}
                </thead>

                <tbody>
                    {statistic.map((item, index) =>
                        <tr key={index} className="row">
                            <td className="cell">{dayjs(item.formed_datetime).format("DD.MM.YYYY")}</td>
                            <td className="cell">{item.consumer_name}</td>
                            <td className="cell">{addSpaces(item.planned)}</td>
                            <td className="cell">{addSpaces(item.actual_generated)}</td>
                            <td className="cell">{addSpaces(item.deviation)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default PowerGenerationStatisticModal;