import React, {useEffect, useState} from 'react';
import {ChevronOutline} from "../Icons";
import BarChart from "../Charts/BarChart";
import {chartColors} from "../../utils/options";
import { IMonth, ITenderMonthly } from '../../types/tenders';
import { getTendersMonthlyChart } from '../../services/tenders';
import dayjs from 'dayjs';
import { getSumString } from '../../utils';

dayjs.locale('ru');

const AdditionalAgreementsCard = () => {
    const [page, setPage] = useState<number>(0);
    const [chartData, setChartData] = useState<ITenderMonthly>();
    const titles = ["Дополнительные соглашения", "Дополнительные соглашения"];

    useEffect(() => {
        loadMonthlyChart();
    }, []);

    const loadMonthlyChart = async () => {
        const response = await getTendersMonthlyChart();

        if (!response.isError) {
            setChartData(response.data);
        }
    }

    const sum: number = chartData ? chartData.data.reduce((accumulator: number, item: { total_sum: number }) => {
        return accumulator + item.total_sum;
    }, 0) : 0;

    const count: number = chartData ? chartData.data.reduce((accumulator: number, item: { count: number }) => {
        return accumulator + item.count;
    }, 0) : 0;

    const getChartData = () => {
        let names: string[] = [];
        let statusesData: number[] = [];

        chartData?.data.forEach((item: IMonth) => {
            if (page === 0) {
                if (item.total_sum !== 0) {
                    names.push(dayjs(item.month).format("MMMM"));
                    statusesData.push(Math.round(item.total_sum));
                }
            } else {
                if (item.total_sum !== 0) {
                    names.push(dayjs(item.month).format("MMMM"));
                    statusesData.push(item.count);
                }
            }
        });

        return {
            labels: names,
            datasets: [
                {
                    data: statusesData,
                    backgroundColor: chartColors,
                    borderColor: chartColors,
                    borderWidth: 1
                }
            ]
        }
    };

    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-slider">
                <span className="side-info-big-card-slider-arrow prev" onClick={() => setPage(page === 0 ? 1 : 0)}><ChevronOutline /></span>
                <span className="side-info-big-card-title">{titles[page]}</span>
                <span className="side-info-big-card-slider-arrow" onClick={() => setPage(page === 0 ? 1 : 0)}><ChevronOutline /></span>
            </div>

            {page === 0 &&
                <>
                    <p className="side-info-big-card-subtitle">Сумма {getSumString(sum)}</p>
                    <div>
                        <BarChart
                            data={getChartData()}
                            isLegend={false}
                            responsive
                            horizontal
                            chartHeight={300}
                            datalabels
                            textRotation={180}
                        />
                    </div>
                </>
            }

            {page === 1 &&
                <>
                    <p className="side-info-big-card-subtitle">Количество {getSumString(count)}</p>
                    <div>
                        <BarChart
                            data={getChartData()}
                            isLegend={false}
                            responsive
                            horizontal
                            chartHeight={300}
                            datalabels
                            textRotation={180}//чтобы скрыть тики на оси X
                        />
                    </div>
                </>
            }
        </div>
    );
};

export default AdditionalAgreementsCard;