import React, {useContext, useEffect, useState} from 'react';
import {ChevronOutline} from "../Icons";
import BarChart from "../Charts/BarChart";
import {chartColors} from "../../utils/options";
import { getTendersStatusChart } from '../../services/tenders';
import { AppDataContext } from '../../contexts/AppDataContext';
import dayjs from 'dayjs';
import { IStatusChart } from '../../types/tenders';

const TenderStatusesCard = () => {
    const [page, setPage] = useState<number>(0);
    const { date } = useContext(AppDataContext);
    const [chartData, setChartData] = useState<IStatusChart>();
    const titles = ["Статусы объявленний по сумме", "Статусы объявлений по кол-ву"];

    const loadStatusChart = async () => {
        const response = await getTendersStatusChart(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            setChartData(response.data);
        }
    }

    useEffect(() => {
        loadStatusChart();
    }, [date]);

    const getChartData = () => {
        let names: string[] = [];
        let statusesData: number[] = [];

        chartData?.status.forEach((item) => {
            if (page === 1) {
                if (item.count !== 0) {
                    names.push(item.status);
                    statusesData.push(item.count);
                }
            } else {
                if (item.total_sum !== 0) {
                    names.push(item.status);
                    statusesData.push(Math.round(item.total_sum));
                }
            }
        });

        return {
            labels: names,
            datasets: [
                {
                    data: statusesData,
                    backgroundColor: chartColors,
                    borderColor: chartColors,
                    borderWidth: 1
                }
            ]
        }
    };

    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-slider">
                <span className="side-info-big-card-slider-arrow prev" onClick={() => setPage(page === 0 ? 1 : 0)}><ChevronOutline /></span>
                <span className="side-info-big-card-title" style={{fontSize: "14px"}}>{titles[page]}</span>
                <span className="side-info-big-card-slider-arrow" onClick={() => setPage(page === 0 ? 1 : 0)}><ChevronOutline /></span>
            </div>

            {page === 0 &&
                <div>
                    <BarChart
                        data={getChartData()}
                        isLegend={false}
                        responsive
                        horizontal
                        chartHeight={200}
                        datalabels
                        textRotation={0}
                    />
                </div>
            }

            {page === 1 &&
                <div>
                    <BarChart
                        data={getChartData()}
                        isLegend={false}
                        responsive
                        horizontal
                        chartHeight={290}
                        datalabels
                        textRotation={0}
                    />
                </div>
            }
        </div>
    );
};

export default TenderStatusesCard;