import React from 'react';
import Map from "../../../Map/Map";
import WaterSideInfo from "../WaterSideInfo";

const WaterSupplyRegionPage = () => {
    return (
        <div>
            <Map />
            <WaterSideInfo />
        </div>
    );
};

export default WaterSupplyRegionPage;