import { useContext, useEffect, useState } from "react";
import NewFilter from "../../UI/NewFilter";
import { Cancel, ChevronOutline } from "../../Icons";
import Search from "../../UI/Search";
import { getFilter } from "../../../services/hcs";
import { DebtFilter } from "../../../types/debts";
import { DebtsContext } from "../../../contexts/DebtsContext";
import { Tooltip } from "react-tooltip";

const DebtsFilter = () => {
    const { type, setType, kse, setKse, krp, setKrp } = useContext(DebtsContext);
    const [filterData, setFilterData] = useState<DebtFilter>();
    const [amount, setAmount] = useState<number>(0);

    useEffect(() => {
        let count = 0;

        if (type.selected && type.selected.length !== 0) count += 1;
        if (kse.selected && kse.selected.length !== 0) count += 1;
        if (krp.selected && krp.selected.length !== 0) count += 1;

        setAmount(count);
    }, [type, kse, krp]);

    const closeAll = () => {
        setType({ ...type, open: false });
        setKse({ ...kse, open: false });
        setKrp({ ...krp, open: false });
    };

    const loadFilter = async () => {
        const response = await getFilter();

        if (!response.isError) {
            setFilterData(response.data);
        }
    }

    useEffect(() => {
        loadFilter();
    }, []);

    return (
        <NewFilter amount={amount}>
            <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>

                {/* Размерность предприятия */}

                <div className="new-filter-option" style={{width: "330px"}}>
                    
                    {krp.selected.length > 0 &&
                        <div className="debts-filter" style={{fontSize: "10px"}}>
                            <div>
                                <span>Выбрано: </span>
                                <span className="debts-filter-current">
                                {krp.selected.length < 4 ? krp.selected.join(", ") : `${krp.selected.slice(0, 3).join(", ")} и еще(${krp.selected.length - 3})`}
                                </span>
                            </div>
                            <span 
                                style={{width: "fit-content", height: "fit-content"}}
                                onClick={() => setKrp({...krp, selected: []})}
                            >
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="debts-filter" onClick={() => {closeAll(); setKrp({...krp, open: !krp.open})}}>
                        <span>Размерность предприятия</span> 
                        <span className={krp.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {krp.open &&
                <>
                    <Search
                        value={krp.value as string} 
                        placeholder="Поиск по размерности предприятия"
                        onChange={(e) => setKrp({...krp, value: e})}
                        onCancel={() => setKrp({...krp, value: ""})}
                        withCheckboxes
                        checkboxes={filterData?.krp_names}
                        selected={krp.selected}
                        setSelected={setKrp}
                        tooltipId="krp"
                    />
                    <Tooltip id="krp" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                    </>
                }

                {/* Код КСЭ */}

                <div className="new-filter-option" style={{width: "330px"}}>
                    
                    {kse.selected?.length as number > 0 &&
                        <div className="debts-filter" style={{fontSize: "10px"}}>
                            <div>
                                <span>Выбрано: </span>
                                <span className="debts-filter-current">
                                {kse.selected.length < 4 ? kse.selected.join(", ") : `${kse.selected.slice(0, 3).join(", ")} и еще(${kse.selected.length - 3})`}    
                                </span>
                            </div>
                            <span 
                                style={{width: "fit-content", height: "fit-content"}}
                                onClick={() => setKse({...kse, selected: []})}
                            >
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="debts-filter" onClick={() => {closeAll(); setKse({...kse, open: !kse.open})}}>
                        <span>КСЭ</span> 
                        <span className={kse.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>
                
                {kse.open &&
                    <>
                    <Search
                        value={kse.value} 
                        placeholder="Поиск по КСЭ"
                        onChange={(e) => setType({...type, value: e})}
                        onCancel={() => setType({...type, value: ""})}
                        withCheckboxes
                        checkboxes={filterData?.kse_names}
                        selected={kse.selected}
                        setSelected={setKse}
                        tooltipId="kse"
                    />
                    <Tooltip id="kse" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                    </>
                }

                {/* Вид деятельности */}

                <div className="new-filter-option" style={{width: "330px"}}>
                    
                    {type.selected.length > 0 &&
                        <div className="debts-filter" style={{fontSize: "10px"}}>
                            <div>
                                <span>Выбрано: </span>
                                <span className="debts-filter-current">{type.selected.length < 4 ? type.selected.join(", ") : `${type.selected.slice(0, 3).join(", ")} и еще(${type.selected.length - 3})`}</span>
                            </div>
                            <span 
                                style={{width: "fit-content", height: "fit-content"}}
                                onClick={() => setType({...type, selected: []})}
                            >
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="debts-filter" onClick={() => {closeAll(); setType({...type, open: !type.open})}}>
                        <span>Вид деятельности</span> 
                        <span className={type.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {type.open &&
                    <>
                    <Search
                        value={type.value as string} 
                        placeholder="Наименование основного вида деятельности"
                        onChange={(e) => setType({...type, value: e})}
                        onCancel={() => setType({...type, value: ""})}
                        withCheckboxes
                        checkboxes={filterData?.main_activity_names}
                        selected={type.selected}
                        setSelected={setType}
                        tooltipId="type"
                    />
                    <Tooltip id="type" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                    </>
                }
            </div>
        </NewFilter>
    )
}

export default DebtsFilter;