import React, {ReactNode} from 'react';
import {Close} from "../Icons";
import ReactDOM from 'react-dom';
import Button from "./Button";

interface IModal {
    title: string;
    subtitle?: ReactNode;
    children: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    headerIcon?: ReactNode;
    buttonText?: string;
    buttonAction?: () => void;
    bigModal?: boolean;
}

const Modal: React.FC<IModal> = ({ title, onClose, isOpen, headerIcon, buttonText, buttonAction, bigModal,  children, subtitle }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="modal-backdrop">
            <div className={bigModal ? "modal card big-modal" : "modal card"}>
                <div className="modal-header">
                    <div className="modal-header__top">
                        <div className="modal-header-title">
                            {headerIcon && headerIcon}
                            {title}
                        </div>

                        {
                            subtitle && 
                            <div className="modal-header-subtitle">
                                { subtitle }
                            </div>
                        }
                    </div>
                    
                    <div className="modal-header-actions">
                        {(buttonText && buttonAction) && <Button text={buttonText} onClick={buttonAction} />}
                        <div className="modal-close" onClick={onClose}><Close /></div>
                    </div>
                </div>
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')!
    );
};

export default Modal;