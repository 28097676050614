import 'react-markdown-editor-lite/lib/index.css';
import { CaseInfo, CaseInfoEndpointVariants, getCaseInformation, updateCaseInformation } from "../../services/cases-info";
import { useMatch } from "react-router-dom";
import dayjs from "dayjs";
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import Modal from "../UI/Modal";
import React, { useEffect, useMemo, useRef, useState } from 'react';

const mdParser = new MarkdownIt({
    html: true,
    breaks: true,
    linkify: true
});

const mdUsedPlugins = [
    "header",
    "font-bold",
    "font-italic",
    "font-strikethrough",
    "list-unordered",
    "list-ordered",
    "block-quote",
    "block-wrap",
    "block-code-inline",
    "block-code-block",
    "table",
    "image",
    "link",
    "clear",
    "logger",
];

type PassportModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const caseNamesByEndpoint: Record<CaseInfoEndpointVariants, string> = {
    "floods": "Ситуация по паводками",
    "fires": "Ситуация по пожарам",
    "debts": "Задолженность за электроэнергию",
    "tenders": "Анализ осуществления государственных закупок",
    "power-generation": "Выработка и потребление электроэнергии",
    "water-supply": "Обеспеченность водоснабжением",
};

const PassportModal: React.FC<PassportModalProps> = ({ isOpen, onClose }) => {
    const [passportData, setPassportData] = useState<CaseInfo>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const mdEditorRef = useRef<MdEditor>(null);

    const isFloodsCase = [useMatch("/"), useMatch("/district/:id")].map(Boolean).includes(true);
    const isFiresCase = [useMatch("/fires"), useMatch("/fires/district/:id")].map(Boolean).includes(true);
    const isDebtsCase = [useMatch("/debts"), useMatch("/debts/district/:id")].map(Boolean).includes(true);
    const isTendersCase = [useMatch("/tenders"), useMatch("/tenders/district/:id")].map(Boolean).includes(true);
    const isPowerGenerationCase = Boolean(useMatch("/power-generation"));
    const isWaterSupplyCase = Boolean(useMatch("/water-supply"));

    const endpoint = useMemo<CaseInfoEndpointVariants | null>(
        () => {
            return (
                (isFloodsCase && "floods") ||
                (isFiresCase && "fires") ||
                (isDebtsCase && "debts") ||
                (isTendersCase && "tenders") ||
                (isPowerGenerationCase && "power-generation") ||
                (isWaterSupplyCase && "water-supply") ||
                null
            );
        },
        [],
    );

    const modalTitle = useMemo(
        () => `Паспорт | ${endpoint && caseNamesByEndpoint[endpoint] || ''}`, 
        [endpoint],
    );

    const subTitle = useMemo(
        () => {
            if (loading || !passportData){
                return;
            }

            return (
                <>
                    Дата обновления данных по данному кейсу: <strong style={{ fontWeight: '600' }}>{dayjs(passportData.last_updated_date).format("DD.MM.YYYY")}</strong>
                </>
            );
        },
        [loading, passportData],
    )

    const fetchPassportData = async () => {
        if (!endpoint){
            return;
        }

        const response = await getCaseInformation({ endpoint });

        setLoading(false);

        if (!response.isError) {
            setPassportData(response.data);
            return;
        }

        setError(true);
    }

    const handleClickButton = async () => {
        if (
            !passportData || 
            !endpoint
        ) {
            return;
        }

        if (!isEditing) {
            setIsEditing(true);
            return;
        }

        if (!mdEditorRef.current) {
            return;
        }

        const newValue = mdEditorRef.current.getMdValue();

        if (newValue == passportData.description){
            setIsEditing(false);
            return;
        }

        const response = await updateCaseInformation({
            endpoint,
            body: {
                description: newValue,
            },
        });

        if(response.isError){
            alert(`[UpdateCaseInformation#${endpoint}]: ${response.error}`);
            return;
        }

        setPassportData({
            ...passportData,
            description: newValue,
        });

        setIsEditing(false);
    }

    useEffect(
        () => {
            fetchPassportData();
        },
        [],
    )

    return (
        <Modal
            title={ modalTitle }
            subtitle={ subTitle }
            isOpen={ isOpen }
            onClose={ onClose }
            
            buttonText={ !isEditing ? "Редактировать" : "Сохранить" }
            buttonAction={ handleClickButton }
        >
            <div className="passport-modal__inner html-wrap">
                {
                    loading &&
                    <p style={{ textAlign: "center" }}>
                        Загрузка...
                    </p>   
                }

                {
                    (!loading && !error && passportData) &&

                    <>
                        { !isEditing && <div className="custom-html-style" dangerouslySetInnerHTML={{__html: mdParser.render(passportData.description) }} /> }  
                        
                        { isEditing && <MdEditor ref={mdEditorRef} style={{ minHeight: "400px" }} defaultValue={passportData.description} renderHTML={text => mdParser.render(text)} plugins={mdUsedPlugins} /> }
                    </>
                }
            </div>
        </Modal>
    );
};

export default PassportModal;