import React, { memo } from 'react';
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MapController from "./MapController";
import { ICheckpoint, IDistrict } from "../../types/district";
import PointMarker from "./markers/PointMarker";
import { vko } from "../../coordinates/vko_coordinates";
import {getDistrictCoordinates, isMobileDevice, swapCoordinates} from "../../utils";
import { IFire, IFiresStation, IFlight, ISatellite } from "../../types/fires";
import FirePointMarker from "./markers/FirePointMarker";
import FireStationMarker from "./markers/FireStationMarker";
import HelicopterMarker from "./markers/HelicopterMarker";
import SatelliteMarker from "./markers/SatelliteMarker";
import { zaysanLake } from "../../coordinates/riversAndLakes/zaysan_lake";
import { bukhtarmaLake } from "../../coordinates/riversAndLakes/bukhtarma_lake";
import { markakolLake } from "../../coordinates/riversAndLakes/markakol_lake";
import { irtyshRiver } from "../../coordinates/riversAndLakes/irtysh_river";
import {IPowerPlant} from "../../types/power-generation";
import PowerPlantMarker from "./markers/PowerPlantMarker";
import {ITenderDistrictInfo} from "../../types/tenders";
import TenderInfoMarker from "./markers/TenderInfoMarker";
import { DebtsDistrict } from '../../types/debts';
import DebtsMarker from './markers/DebtsMarker';
import SpecialPointMarker from './markers/SpecialPointMarker';

interface IMap {
    districtId?: number;
    districts?: IDistrict[];
    blueCheckpoints?: ICheckpoint[];
    regionOnly?: boolean;
    district?: IDistrict;
    fires?: IFire[];
    fireStations?: IFiresStation[];
    helicopter?: boolean;
    flights?: IFlight[];
    satelliteFilter?: boolean;
    satellites?: ISatellite[];
    powerPlants?: IPowerPlant[];
    tenderInfo?: ITenderDistrictInfo[];
    debts?: DebtsDistrict[]
}

const Map: React.FC<IMap> = ({ districtId, districts, district, fires, fireStations, helicopter, flights, satelliteFilter, satellites, regionOnly, powerPlants, tenderInfo, debts, blueCheckpoints }) => {
    const outerBounds = [[90, -180], [90, 180], [-90, 180], [-90, -180]];
    const regionCoordinates = swapCoordinates(vko);
    const districtCoordinates = districtId ? swapCoordinates(getDistrictCoordinates(districtId)) : null;
    const lakesAndRivers = [bukhtarmaLake, markakolLake, irtyshRiver, zaysanLake];
    const isMobile = isMobileDevice();

    return (
        <MapContainer
            center={[49.10, 83.5]}
            zoom={isMobile ? 7 : 7.5}
            style={{ width: "100%", height: "100vh" }}
            zoomSnap={0}
            dragging={false}
            doubleClickZoom={false}
            boxZoom={false}
            keyboard={false}
            touchZoom={false}
            scrollWheelZoom={false}
            zoomControl={false}
        >
            <MapController districtId={districtId} regionOnly={regionOnly} />
            <TileLayer
                attribution=''
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxNativeZoom={19}
                maxZoom={21}
                className="leaflet-custom-tilelayer"
            />

            {(districtId && !regionOnly) ?
                <Polygon
                    positions={[outerBounds, districtCoordinates]}
                    color="black"
                    fillColor="black"
                    fillOpacity={0.8}
                    weight={0}
                />
            :
                <>
                    <Polygon
                        positions={regionCoordinates}
                        color="white"
                        fillColor="#3880CE"
                        fillOpacity={1}
                        weight={3}
                    />

                    {lakesAndRivers.map((item, index) =>
                        <Polygon
                            key={index}
                            positions={swapCoordinates(item)}
                            fillColor="#186ECA"
                            fillOpacity={1}
                            weight={0}
                        />
                    )}

                    <Polygon
                        positions={[outerBounds, regionCoordinates]}
                        color="black"
                        fillColor="black"
                        fillOpacity={0.8}
                        weight={0}
                    />

                    {
                    /**
                     * Issues#12
                     * Убери эффект покраски красным цветом
                     */

                    /* {debts?.map((debt: DebtsDistrict) => 
                        debt.debt_situation_crit &&
                        <Polygon
                            positions={swapCoordinates(getDistrictCoordinates(debt.id))}
                            color="white"
                            fillColor="#B74747CC"
                            fillOpacity={1}
                            weight={3}
                        />
                    )} */
                    }
                </>
            }

            {districts?.map((district) =>
                district.hydrometeorological_reports?.map((point) => (
                    <PointMarker key={point.id} point={point} districtName={district.name} />
                ))
            )}

            {blueCheckpoints?.map((checkpoint) => 
                <SpecialPointMarker point={checkpoint} key={checkpoint.id}/>
            )}

            {district?.hydrometeorological_reports?.map((point) => (
                point.latitude && point.longitude && <PointMarker key={point.id} point={point} />
            ))}

            {fires?.map((fire) => (
                <FirePointMarker point={fire} />
            ))}

            {fireStations?.map((fire) => (
                <FireStationMarker point={fire} />
            ))}

            {helicopter && (
                flights?.length ?
                    flights?.map((item) => (
                        <HelicopterMarker point={item} />
                    ))
                : <HelicopterMarker point="inactive" />
            )}

            {satelliteFilter && satellites?.map((item) => (
                <SatelliteMarker point={item} key={item.id} />
            ))}

            {powerPlants?.map((item) => (
                <PowerPlantMarker point={item} key={item.id} />
            ))}

            {tenderInfo?.map((item) =>
                districtId ?
                    (item.latitude && item.longitude && item.total) && (
                        <TenderInfoMarker point={item} key={item.id} />
                    )
                : ( item.total &&
                    <TenderInfoMarker point={item} key={item.id} />
                )
            )}

            {debts?.map((item: DebtsDistrict) => (
                (debts && districtId) ?
                <DebtsMarker point={debts.filter((district) => district.id === Number(districtId))[0]} key={item.id}/>
                : <DebtsMarker point={item} key={item.id}/>
            ))}
        </MapContainer>
    );
};

export default memo(Map);
