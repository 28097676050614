export const announcementStatus = [
    { id: 10, label: 'Опубликовано'},
    { id: 11, label: 'Опубликовано (дополнение заявок)'},
    { id: 12, label: 'Опубликовано (ожидание проведения аукциона)'},
    { id: 13, label: 'Опубликовано (прием заявок)'},
    { id: 14, label: 'Опубликовано (прием ценовых предложений)'},
    { id: 30, label: 'Рассмотрение заявок'},
    { id: 31, label: 'Рассмотрение дополнений заявок'},
    { id: 32, label: 'Формирование протокола допуска'},
    { id: 33, label: 'Формирование протокола преддопуска'},
    { id: 34, label: 'Формирование протокола промежуточных итогов'},
    { id: 20, label: 'Завершено'},
    { id: 21, label: 'Формирование протокола итогов'},
    { id: 22, label: 'Заполнение условных скидок'},
    { id: 50, label: 'Отменено'},
    { id: 51, label: 'Отказ от закупки'},
    { id: 52, label: 'Приостановлено'},
    { id: 53, label: 'Изменена документация'},
    { id: 60, label: 'На обжаловании'},
    { id: 61, label: 'Пересмотр итогов'},
    { id: 62, label: 'Принятие решение об исполнении уведомления'},
    { id: 63, label: 'Ожидание проведения контроля качества'},
    { id: 64, label: 'Отправлено на контроль качества'},
    { id: 65, label: 'Пройдено контроль качества'},
    { id: 80, label: 'Не указан'},
];

export const budgetTypes = [
    { id: 1, label: 'Бюджет сельского округа, села, поселка, города районного значения'},
    { id: 2, label: 'Вне бюджета'},
    { id: 3, label: 'Областной бюджет, бюджет города республиканского значения, столицы'},
    { id: 4, label: 'Районный бюджет, города областного значения'},
    { id: 5, label: 'Республиканский бюджет'},
    { id: 6, label: 'Не указано'},
];

export const tradeMethodFact = [
    { id: 10, label: 'Открытый конкурс'},
    { id: 11, label: 'Конкурс с предварительным квалификационным отбором'},
    { id: 12, label: 'Конкурс с применением двухэтапных процедур'},
    { id: 13, label: 'Первый этап конкурса с использованием рамочного соглашения'},
    { id: 14, label: 'Второй этап конкурса с использованием рамочного соглашения'},
    { id: 15, label: 'Конкурс с использованием рейтингово-балльной системы'},
    { id: 16, label: 'Конкурс с применением специального порядка'},
    { id: 17, label: 'Конкурс по приобретению услуг по организации питания воспитанников и обучающихся'},
    { id: 18, label: 'Конкурс по приобретению товаров, связанных с обеспечением питания воспитанников и обучающихсяа'},
    { id: 19, label: 'Из одного источника путем прямого заключения договора по питанию обучающихся'},
    { id: 20, label: 'Аукцион (до 2022)'},
    { id: 21, label: 'Аукцион (с 2022)'},
    { id: 22, label: 'Аукцион (не ГЗ) с 2022 г'},
    { id: 30, label: 'Запрос ценовых предложений'},
    { id: 31, label: 'Запрос ценовых предложений (не ГЗ new)'},
    { id: 40, label: 'Из одного источника по несостоявшимся закупкам'},
    { id: 41, label: 'Из одного источника по несостоявшимся закупкам не ГЗ'},
    { id: 50, label: 'Тендер'},
    { id: 51, label: 'Тендер с применением особого порядка'},
    { id: 52, label: 'Государственные закупки с применением особого порядка'},
    { id: 60, label: 'Закупка жилища'},
    { id: 61, label: 'Закупка по государственному социальному заказу'},
    { id: 70, label: 'Электронный магазин'},
    { id: 80, label: 'Не указан'},
];

export const subjectTypes = [
    { id: 0, label: 'Товар', checked: false},
    { id: 1, label: 'Услуга', checked: false},
    { id: 2, label: 'Работа', checked: false},
];

export const tradeTypes = [
    { id: 1, label: 'Первая закупка', checked: false},
    { id: 2, label: 'Повторная закупка', checked: false},
    { id: 3, label: 'ИОИ от конкурса', checked: false},
    { id: 4, label: 'ИОИ от ЗЦП', checked: false},
    { id: 5, label: 'ИОИ от гос. соц. зак.', checked: false},
    { id: 6, label: 'ИОИ от КПКО', checked: false},
    { id: 7, label: 'ИОИ от Конкурс по приобретению товаров, связанных с обеспечением питания воспитанников и обучающихся', checked: false},
    { id: 8, label: 'ИОИ от аукциона', checked: false},
    { id: 9, label: 'ИОИ ПНЗ от КРБС', checked: false},
];
export const chartColors = ["#34A8E5", "#B26376", "#E9DB46", "#F2730E", "#A6BB52", "#008080", "#3CB44B", "#0082C8", "#F58231", "#46F0F0", "#F032E6", "#D2F53C", "#FF6347", "#FFE119", "#800080", "#AA6E28", "#FFD700", "#32CD32", "#808000", "#FFD8B1", "#808080", "#A9A9A9","#E6194B", "#911EB4", "#1F5673","#800000", "#000080", "#000000", "#BC8F8F", "#C71585", "#BDB76B"];

export const featureTypes = [
    { id: 1, label: 'Работа с ТЭО и ПСД', checked: false },
    { id: 2, label: 'Работа на разработку ТЭО/ПСД', checked: false },
    { id: 3, label: 'Работа по текущему ремонту и работа, не связанная со строительством', checked: false },
    { id: 4, label: 'Работа по среднему ремонту существующих автомобильных дорог общего пользования (работа без ПСД)', checked: false },
    { id: 5, label: 'Работа', checked: false },
    { id: 6, label: 'Работа с ТЭО и ПСД/Работа по среднему ремонту существующих автомобильных дорог', checked: false },
    { id: 9, label: 'Строительно-монтажные работы', checked: false },
    { id: 16, label: 'По комплексной вневедомственной/градостроительной экспертизе проектов строительства объектов', checked: false },
    { id: 17, label: 'Работа на разработку ПСД 1', checked: false },
    { id: 18, label: 'Работа на разработку ПСД 2', checked: false },
    { id: 20, label: 'Работы на разработку градостроительных проектов', checked: false },
    { id: 0, label: 'Не указано', checked: false }
];