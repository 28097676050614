import React, {useEffect, useRef} from 'react';
import {ISatellite} from "../../../types/fires";
import {divIcon, DivIconOptions} from "leaflet";
import ReactDOMServer from "react-dom/server";
import {
    TemperatureLevel1,
    TemperatureLevel2,
    TemperatureLevel3,
    TemperatureLevel4,
    TemperatureLevel5
} from "../../Icons";
import {Marker, Popup} from "react-leaflet";

interface ISatelliteMarker {
    point: ISatellite;
}

const SatelliteMarker: React.FC<ISatelliteMarker> = ({point}) => {
    const markerRef = useRef<any>(null);

    const createFireMarker = () => {
        if (point.confidence <= 20) {
            const options: DivIconOptions = {
                className: "marker-wrapper",
                html: ReactDOMServer.renderToString(
                    <TemperatureLevel1 />
                )}
            return divIcon(options);
        } else if (point.confidence > 20 && point.confidence <= 40) {
            const options: DivIconOptions = {
                className: "marker-wrapper",
                html: ReactDOMServer.renderToString(
                    <TemperatureLevel2 />
                )}
            return divIcon(options);
        } else if (point.confidence > 40 && point.confidence <= 60) {
            const options: DivIconOptions = {
                className: "marker-wrapper",
                html: ReactDOMServer.renderToString(
                    <TemperatureLevel3 />
                )}
            return divIcon(options);
        }  else if (point.confidence > 60 && point.confidence <= 80) {
            const options: DivIconOptions = {
                className: "marker-wrapper",
                html: ReactDOMServer.renderToString(
                    <TemperatureLevel4 />
                )}
            return divIcon(options);
        }  else {
            const options: DivIconOptions = {
                className: "marker-wrapper",
                html: ReactDOMServer.renderToString(
                    <TemperatureLevel5 />
                )}
            return divIcon(options);
        }

    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    return (
        <>
            {point.latitude && point.longitude &&
                <Marker
                    position={[point.latitude, point.longitude]}
                    icon={createFireMarker()}
                    ref={markerRef}
                >
                    <Popup>
                        Область: <span style={{ fontWeight: 600, fontSize: "14px" }}>{point.region}</span> <br />
                        Ближайший город: <span style={{ fontWeight: 600 }}>{point.locality}</span> <br />
                        Достоверность обнаружения пожара: <span style={{ fontWeight: 600 }}>{point.confidence + "%"}</span> <br />
                        {point.technogenic && <><span style={{ fontWeight: 600 }}>Техногенная горячая точка</span> <br /></>}
                        Расстояние до ближайшего города: <span style={{ fontWeight: 600 }}>{point.localitydistance + "км"}</span> <br />
                        Ближайшая пожарная часть: <span style={{ fontWeight: 600 }}>{point.firedep}</span> <br />
                    </Popup>
                </Marker>
            }
        </>
    );
};

export default SatelliteMarker;