import {LatLngExpression} from "leaflet";

export const ulkenNarinDistrict: LatLngExpression[] = [
    [
        84.9285299,
        49.585502
    ],
    [
        84.9280864,
        49.589344
    ],
    [
        84.9270785,
        49.5914609
    ],
    [
        84.9231678,
        49.594623
    ],
    [
        84.9184147,
        49.597649
    ],
    [
        84.9121213,
        49.5993005
    ],
    [
        84.9062755,
        49.5996663
    ],
    [
        84.8974721,
        49.5983166
    ],
    [
        84.8880307,
        49.5975377
    ],
    [
        84.8779027,
        49.5956462
    ],
    [
        84.8711475,
        49.5945503
    ],
    [
        84.8674301,
        49.5934553
    ],
    [
        84.8657029,
        49.591871
    ],
    [
        84.8636109,
        49.5911791
    ],
    [
        84.8605649,
        49.5916405
    ],
    [
        84.8555417,
        49.5920094
    ],
    [
        84.850205,
        49.5915666
    ],
    [
        84.8464907,
        49.5900444
    ],
    [
        84.8444841,
        49.5897123
    ],
    [
        84.8336398,
        49.5914282
    ],
    [
        84.827065,
        49.5905426
    ],
    [
        84.8232254,
        49.5906062
    ],
    [
        84.8200204,
        49.5905887
    ],
    [
        84.8135311,
        49.5885222
    ],
    [
        84.8036475,
        49.5877126
    ],
    [
        84.7999623,
        49.5903633
    ],
    [
        84.7998817,
        49.5939697
    ],
    [
        84.7964951,
        49.5947537
    ],
    [
        84.7907703,
        49.5982031
    ],
    [
        84.7823039,
        49.6007638
    ],
    [
        84.7786755,
        49.6025928
    ],
    [
        84.7809332,
        49.6058848
    ],
    [
        84.778353,
        49.6083407
    ],
    [
        84.7681934,
        49.6244311
    ],
    [
        84.7569049,
        49.6241177
    ],
    [
        84.749003,
        49.6324743
    ],
    [
        84.7490836,
        49.6348243
    ],
    [
        84.7502125,
        49.6370698
    ],
    [
        84.7497287,
        49.6430746
    ],
    [
        84.745939,
        49.6423959
    ],
    [
        84.742875,
        49.6423437
    ],
    [
        84.7370695,
        49.649131
    ],
    [
        84.7549697,
        49.6907739
    ],
    [
        84.7415042,
        49.7011008
    ],
    [
        84.7306996,
        49.7088706
    ],
    [
        84.6920769,
        49.7170042
    ],
    [
        84.6824817,
        49.7198714
    ],
    [
        84.6685324,
        49.7125205
    ],
    [
        84.658534,
        49.7053248
    ],
    [
        84.6540187,
        49.6864443
    ],
    [
        84.6615174,
        49.6807578
    ],
    [
        84.6626463,
        49.6711049
    ],
    [
        84.6669198,
        49.6592058
    ],
    [
        84.6727252,
        49.6564915
    ],
    [
        84.6917544,
        49.6301763
    ],
    [
        84.6802239,
        49.6104307
    ],
    [
        84.6829654,
        49.5964262
    ],
    [
        84.666194,
        49.5757257
    ],
    [
        84.6701073,
        49.559423
    ],
    [
        84.6483341,
        49.5308444
    ],
    [
        84.640271,
        49.5277566
    ],
    [
        84.6216449,
        49.5312891
    ],
    [
        84.5986769,
        49.4865829
    ],
    [
        84.6035348,
        49.4857121
    ],
    [
        84.6047579,
        49.4858445
    ],
    [
        84.6065604,
        49.4851893
    ],
    [
        84.6083199,
        49.4844017
    ],
    [
        84.6093391,
        49.4845411
    ],
    [
        84.6099614,
        49.4839208
    ],
    [
        84.6128367,
        49.4832656
    ],
    [
        84.6141456,
        49.4836699
    ],
    [
        84.6164845,
        49.4819482
    ],
    [
        84.6190702,
        49.4817252
    ],
    [
        84.6202825,
        49.4807911
    ],
    [
        84.6232652,
        49.4812024
    ],
    [
        84.6241127,
        49.480826
    ],
    [
        84.6236621,
        49.4801289
    ],
    [
        84.6239089,
        49.4795713
    ],
    [
        84.6250676,
        49.47916
    ],
    [
        84.62628,
        49.4792645
    ],
    [
        84.6273529,
        49.4788255
    ],
    [
        84.627031,
        49.478205
    ],
    [
        84.6293055,
        49.4770478
    ],
    [
        84.6309792,
        49.4764483
    ],
    [
        84.6317839,
        49.4767132
    ],
    [
        84.6325134,
        49.4765598
    ],
    [
        84.6328138,
        49.4758906
    ],
    [
        84.6338331,
        49.4753817
    ],
    [
        84.6341013,
        49.474817
    ],
    [
        84.6334575,
        49.4733878
    ],
    [
        84.6342622,
        49.4725164
    ],
    [
        84.6359359,
        49.4717634
    ],
    [
        84.6374916,
        49.470871
    ],
    [
        84.6383714,
        49.4700623
    ],
    [
        84.6384679,
        49.4685353
    ],
    [
        84.6391975,
        49.4677475
    ],
    [
        84.6400129,
        49.4673779
    ],
    [
        84.6401631,
        49.4662623
    ],
    [
        84.6399592,
        49.4657742
    ],
    [
        84.6390473,
        49.4654326
    ],
    [
        84.63894,
        49.4649026
    ],
    [
        84.6394373,
        49.4646868
    ],
    [
        84.6219777,
        49.4561022
    ],
    [
        84.6007776,
        49.4448588
    ],
    [
        84.6073006,
        49.4379104
    ],
    [
        84.6098327,
        49.4366547
    ],
    [
        84.5952843,
        49.4363476
    ],
    [
        84.5729041,
        49.4478164
    ],
    [
        84.4786109,
        49.46
    ],
    [
        84.4128084,
        49.3948596
    ],
    [
        84.3968438,
        49.396284
    ],
    [
        84.3706655,
        49.3974292
    ],
    [
        84.3509673,
        49.4034057
    ],
    [
        84.2854785,
        49.3691004
    ],
    [
        84.2669819,
        49.379495
    ],
    [
        84.2180156,
        49.4110291
    ],
    [
        84.1830823,
        49.4030427
    ],
    [
        84.1819345,
        49.4012797
    ],
    [
        84.1804432,
        49.400222
    ],
    [
        84.1776108,
        49.3972894
    ],
    [
        84.1756581,
        49.3947897
    ],
    [
        84.1737484,
        49.3932395
    ],
    [
        84.1707443,
        49.3916892
    ],
    [
        84.1688775,
        49.3897339
    ],
    [
        84.1679119,
        49.3893847
    ],
    [
        84.1672896,
        49.3882394
    ],
    [
        84.1653155,
        49.3877645
    ],
    [
        84.1624831,
        49.3864515
    ],
    [
        84.1576766,
        49.383127
    ],
    [
        84.1558741,
        49.3826242
    ],
    [
        84.155917,
        49.3815346
    ],
    [
        84.1549729,
        49.3807523
    ],
    [
        84.1548871,
        49.3798582
    ],
    [
        84.1540287,
        49.3795229
    ],
    [
        84.1539,
        49.3788244
    ],
    [
        84.152398,
        49.3778186
    ],
    [
        84.1505955,
        49.377595
    ],
    [
        84.1486214,
        49.3759744
    ],
    [
        84.1440295,
        49.375248
    ],
    [
        84.1420554,
        49.374242
    ],
    [
        84.1404246,
        49.3725654
    ],
    [
        84.1381501,
        49.3716992
    ],
    [
        84.134631,
        49.3721742
    ],
    [
        84.1303824,
        49.3711403
    ],
    [
        84.1276144,
        49.3722581
    ],
    [
        84.125254,
        49.3715315
    ],
    [
        84.1239022,
        49.3705394
    ],
    [
        84.1225504,
        49.3676191
    ],
    [
        84.1214775,
        49.3663755
    ],
    [
        84.1182588,
        49.3650619
    ],
    [
        84.116628,
        49.3651737
    ],
    [
        84.1140746,
        49.3667388
    ],
    [
        84.1110061,
        49.3663894
    ],
    [
        84.1101478,
        49.3643073
    ],
    [
        84.1089891,
        49.3634689
    ],
    [
        84.10856,
        49.361736
    ],
    [
        84.106779,
        49.361191
    ],
    [
        84.1042684,
        49.3596956
    ],
    [
        84.1011356,
        49.3585775
    ],
    [
        84.0965651,
        49.3574874
    ],
    [
        84.0939258,
        49.3571939
    ],
    [
        84.092574,
        49.3567327
    ],
    [
        84.0919303,
        49.3562994
    ],
    [
        84.0919088,
        49.3554189
    ],
    [
        84.0905784,
        49.354161
    ],
    [
        84.0894197,
        49.3525256
    ],
    [
        84.0877889,
        49.351659
    ],
    [
        84.0874456,
        49.3507504
    ],
    [
        84.0865658,
        49.3502332
    ],
    [
        84.0864586,
        49.3490171
    ],
    [
        84.0857505,
        49.3480386
    ],
    [
        84.0862011,
        49.3470601
    ],
    [
        84.0860294,
        49.346508
    ],
    [
        84.0851336,
        49.3463087
    ],
    [
        84.0837549,
        49.3463471
    ],
    [
        84.082536,
        49.3454206
    ],
    [
        84.0813087,
        49.3444878
    ],
    [
        84.0769957,
        49.3374275
    ],
    [
        84.0705584,
        49.3341836
    ],
    [
        84.0481561,
        49.3291501
    ],
    // Samar Severo-Zapad

    [
        84.0481567,
        49.3291356
    ],
    [
        84.0693569,
        49.3136906
    ],
    [
        84.1201901,
        49.2815943
    ],
    [
        84.1416264,
        49.2718089
    ],
    [
        84.1819700,
        49.2436900
    ],
    [
        84.2354393,
        49.2004948
    ],

    [
        84.2414474,
        49.1895574
    ],

    [
        84.2405500,
        49.1778900
    ],

    [
        84.2371139,
        49.1548640
    ],
    [
        84.2312765,
        49.1403373
    ],
    [
        84.2056561,
        49.1234890
    ],
    [
        84.1890907,
        49.1168043
    ],

    // Kurchumskiy
    [
        84.1892724,
        49.1172729
    ],
    [
        84.1811728,
        49.0892941
    ],
    [
        84.1811675,
        49.0889006
    ],
    [
        84.1810924,
        49.0885141
    ],
    [
        84.1812104,
        49.0882928
    ],
    [
        84.1810548,
        49.0881066
    ],
    [
        84.1811031,
        49.0877061
    ],
    [
        84.18069,
        49.0872002
    ],
    [
        84.1804969,
        49.0870421
    ],
    [
        84.180513,
        49.0868524
    ],
    [
        84.1806686,
        49.08661
    ],
    [
        84.1806632,
        49.086357
    ],
    [
        84.1808027,
        49.0860268
    ],
    [
        84.1808188,
        49.0856403
    ],
    [
        84.1810119,
        49.0854998
    ],
    [
        84.1816664,
        49.0853452
    ],
    [
        84.1823637,
        49.0849693
    ],
    [
        84.1827017,
        49.0848815
    ],
    [
        84.1830504,
        49.0848885
    ],
    [
        84.1835654,
        49.0850606
    ],
    [
        84.184763,
        49.085022
    ],
    [
        84.1848274,
        49.0848287
    ],
    [
        84.1849172,
        49.0847515
    ],
    [
        84.1857621,
        49.0844352
    ],
    [
        84.1871166,
        49.0836623
    ],
    [
        84.1871595,
        49.0826926
    ],
    [
        84.1881895,
        49.0819197
    ],
    [
        84.1895628,
        49.0800223
    ],
    [
        84.1896915,
        49.0779422
    ],
    [
        84.1908288,
        49.0766068
    ],
    [
        84.1893482,
        49.0732193
    ],
    [
        84.1916656,
        49.0661623
    ],
    [
        84.1915798,
        49.0647283
    ],
    [
        84.1898202,
        49.0640816
    ],
    [
        84.1898632,
        49.0617758
    ],
    [
        84.193275,
        49.0571217
    ],
    [
        84.1938543,
        49.0560952
    ],
    [
        84.1944981,
        49.0556733
    ],
    [
        84.195528,
        49.054464
    ],
    [
        84.1984355,
        49.0531632
    ],
    [
        84.1989612,
        49.0525937
    ],
    [
        84.1999376,
        49.0520734
    ],
    [
        84.200592,
        49.0510046
    ],
    [
        84.2000234,
        49.0494647
    ],
    [
        84.1997766,
        49.0477138
    ],
    [
        84.2013216,
        49.0463636
    ],
    [
        84.200871,
        49.0446407
    ],
    [
        84.2011285,
        49.0438882
    ],
    [
        84.2010426,
        49.043213
    ],
    [
        84.2012572,
        49.0423902
    ],
    [
        84.2009997,
        49.0414267
    ],
    [
        84.2012679,
        49.0395137
    ],
    [
        84.2008173,
        49.0382196
    ],
    [
        84.199723,
        49.0370732
    ],
    [
        84.1994119,
        49.0365175
    ],
    [
        84.1989934,
        49.0352726
    ],
    [
        84.1984033,
        49.0340205
    ],
    [
        84.1984677,
        49.0325645
    ],
    [
        84.1988647,
        49.0311998
    ],
    [
        84.1994226,
        49.0302432
    ],
    [
        84.2002272,
        49.0294834
    ],
    [
        84.2009246,
        49.029188
    ],
    [
        84.2020619,
        49.0291317
    ],
    [
        84.2018044,
        49.0274434
    ],
    [
        84.2005491,
        49.0258816
    ],
    [
        84.199326,
        49.0248967
    ],
    [
        84.196515,
        49.0236585
    ],
    [
        84.1921162,
        49.0238414
    ],
    [
        84.1902065,
        49.0235458
    ],
    [
        84.1890049,
        49.0198029
    ],
    [
        84.1952463,
        49.0140922
    ],
    [
        84.1574999,
        48.988333
    ],
    [
        84.1451453,
        48.9705833
    ],
    [
        84.1682338,
        48.9711466
    ],
    [
        84.2135524,
        48.9692309
    ],
    [
        84.2241096,
        48.9663574
    ],
    [
        84.248228,
        48.9713156
    ],
    [
        84.2702006,
        48.9779636
    ],
    [
        84.2935466,
        48.9769497
    ],
    [
        84.3009281,
        48.9711466
    ],
    [
        84.31818,
        48.9664137
    ],
    [
        84.3459034,
        48.9710622
    ],
    [
        84.3565034,
        48.9899897
    ],
    [
        84.3668031,
        48.9982119
    ],
    [
        84.3838405,
        49.0167914
    ],
    [
        84.3972301,
        49.0342105
    ],
    [
        84.4315195,
        49.0414408
    ],
    [
        84.4549083,
        49.0288644
    ],
    [
        84.480443,
        49.0273729
    ],
    [
        84.497137,
        49.0228141
    ],
    [
        84.5053339,
        49.0223357
    ],
    [
        84.5146894,
        49.0202531
    ],
    [
        84.5233583,
        49.0189868
    ],
    [
        84.55091,
        49.0264726
    ],
    [
        84.5604372,
        49.0140331
    ],
    [
        84.5891475,
        49.008713
    ],
    [
        84.6421909,
        48.9824983
    ],
    [
        84.6629619,
        48.9811746
    ],
    [
        84.687767,
        48.967597
    ],
    [
        84.7240761,
        48.9964257
    ],
    [
        84.7636413,
        48.9904683
    ],
    [
        84.8156976,
        48.9792875
    ],
    [
        84.8654794,
        48.9765834
    ],
    [
        84.8953914,
        48.9780481
    ],
    [
        84.9714803,
        48.9833151
    ],
    // Kurchumskiy verx
    [
        85.0214767,
        48.9824984
    ],
    [
        85.0822449,
        49.0187334
    ],
    [
        85.0981235,
        49.0481638
    ],
    [
        85.1440403,
        49.2238775
    ],
    [
        85.1440376,
        49.2534613
    ],
    [
        85.1021549,
        49.2910961
    ],
    [
        85.0712559,
        49.3291444
    ],
    [
        85.0540897,
        49.3703020
    ],
    [
        85.0389862,
        49.4297227
    ],
    [
        85.0143099,
        49.4617649
    ],
    [
        84.9710056,
        49.4993485
    ],
    [
        84.9708400,
        49.495502
    ],
    [
        84.930265,
        49.586107
    ],
]