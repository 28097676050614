import {LatLngExpression} from "leaflet";

export const tarbagatayDistrict: LatLngExpression[] = [
    [
        83.197921,
        47.2173806
    ],
    [
        83.1991693,
        47.2169099
    ],
    [
        83.2004593,
        47.2163289
    ],
    [
        83.2026569,
        47.2140467
    ],
    [
        83.203562,
        47.2133931
    ],
    [
        83.2043374,
        47.2131435
    ],
    [
        83.2055123,
        47.2129204
    ],
    [
        83.206453,
        47.2125245
    ],
    [
        83.2071566,
        47.2120006
    ],
    [
        83.2078326,
        47.2111433
    ],
    [
        83.208348,
        47.2101926
    ],
    [
        83.2085674,
        47.2093908
    ],
    [
        83.2085782,
        47.2086063
    ],
    [
        83.2084929,
        47.2074661
    ],
    [
        83.2083379,
        47.206842
    ],
    [
        83.2074008,
        47.2055333
    ],
    [
        83.2072065,
        47.2050554
    ],
    [
        83.2071133,
        47.2046008
    ],
    [
        83.2076184,
        47.2035414
    ],
    [
        83.2078612,
        47.2028329
    ],
    [
        83.2079627,
        47.2021575
    ],
    [
        83.2072899,
        47.200899
    ],
    [
        83.2069774,
        47.1998257
    ],
    [
        83.2068776,
        47.1989022
    ],
    [
        83.207143,
        47.1983366
    ],
    [
        83.2085736,
        47.1970577
    ],
    [
        83.2090509,
        47.195967
    ],
    [
        83.2112701,
        47.1950305
    ],
    [
        83.2120136,
        47.1946385
    ],
    [
        83.2130431,
        47.1936939
    ],
    [
        83.2137031,
        47.1928057
    ],
    [
        83.2146203,
        47.1911049
    ],
    [
        83.2149209,
        47.1906983
    ],
    [
        83.215399,
        47.1906031
    ],
    [
        83.2161665,
        47.1905556
    ],
    [
        83.2171638,
        47.1902137
    ],
    [
        83.2181441,
        47.1896856
    ],
    [
        83.2189373,
        47.1890805
    ],
    [
        83.2201455,
        47.1882942
    ],
    [
        83.2208861,
        47.1879509
    ],
    [
        83.2217704,
        47.187734
    ],
    [
        83.2225542,
        47.1883298
    ],
    [
        83.2234826,
        47.1886969
    ],
    [
        83.2242751,
        47.1887218
    ],
    [
        83.2260918,
        47.1886614
    ],
    [
        83.2270799,
        47.1887393
    ],
    [
        83.2282936,
        47.1889724
    ],
    [
        83.23199,
        47.1892944
    ],
    [
        83.2344139,
        47.189257
    ],
    [
        83.2359517,
        47.189178
    ],
    [
        83.2364815,
        47.1890704
    ],
    [
        83.2368569,
        47.1885821
    ],
    [
        83.2375113,
        47.1880753
    ],
    [
        83.2379904,
        47.1876044
    ],
    [
        83.2381094,
        47.1871876
    ],
    [
        83.2378805,
        47.186847
    ],
    [
        83.2376238,
        47.1866789
    ],
    [
        83.2375159,
        47.1862713
    ],
    [
        83.2372078,
        47.1861001
    ],
    [
        83.2366726,
        47.1859232
    ],
    [
        83.2362555,
        47.1854354
    ],
    [
        83.2362973,
        47.1850891
    ],
    [
        83.2361036,
        47.184017
    ],
    [
        83.2357859,
        47.1835615
    ],
    [
        83.2353497,
        47.1823604
    ],
    [
        83.2354481,
        47.1817231
    ],
    [
        83.2359785,
        47.1811823
    ],
    [
        83.2371545,
        47.1805544
    ],
    [
        83.2393403,
        47.1792098
    ],
    [
        83.2405908,
        47.1788367
    ],
    [
        83.2422274,
        47.1784043
    ],
    [
        83.2441187,
        47.1782909
    ],
    [
        83.2458888,
        47.1776864
    ],
    [
        83.2496753,
        47.176906
    ],
    [
        83.2516246,
        47.176571
    ],
    [
        83.2535005,
        47.1763271
    ],
    [
        83.2554529,
        47.1762194
    ],
    [
        83.2574603,
        47.1763123
    ],
    [
        83.2609735,
        47.1767867
    ],
    [
        83.262777,
        47.1763499
    ],
    [
        83.2650529,
        47.1759626
    ],
    [
        83.2667042,
        47.1757837
    ],
    [
        83.2684187,
        47.1757282
    ],
    [
        83.2716201,
        47.1754286
    ],
    [
        83.2731859,
        47.1754503
    ],
    [
        83.2761391,
        47.1756128
    ],
    [
        83.2776285,
        47.1756223
    ],
    [
        83.2781993,
        47.1755651
    ],
    [
        83.2797767,
        47.1751904
    ],
    [
        83.2800874,
        47.1751998
    ],
    [
        83.2807434,
        47.1753496
    ],
    [
        83.2829926,
        47.1761675
    ],
    [
        83.2838209,
        47.1765695
    ],
    [
        83.2843873,
        47.1769095
    ],
    [
        83.2851865,
        47.1783468
    ],
    [
        83.2864969,
        47.1802661
    ],
    [
        83.2873865,
        47.1813043
    ],
    [
        83.2884538,
        47.1819923
    ],
    [
        83.2892247,
        47.1820068
    ],
    [
        83.2898798,
        47.1819586
    ],
    [
        83.2903452,
        47.181872
    ],
    [
        83.2909782,
        47.182045
    ],
    [
        83.291634,
        47.1820762
    ],
    [
        83.2930714,
        47.1819985
    ],
    [
        83.2937201,
        47.1817268
    ],
    [
        83.2945328,
        47.1816526
    ],
    [
        83.2952005,
        47.1815046
    ],
    [
        83.2955259,
        47.1813816
    ],
    [
        83.2955497,
        47.18117
    ],
    [
        83.2969851,
        47.1806033
    ],
    [
        83.2981777,
        47.1802875
    ],
    [
        83.2990806,
        47.1801441
    ],
    [
        83.2996309,
        47.1798076
    ],
    [
        83.2999284,
        47.1794258
    ],
    [
        83.3009766,
        47.1786449
    ],
    [
        83.3013541,
        47.1785641
    ],
    [
        83.3015466,
        47.1783998
    ],
    [
        83.3019177,
        47.1782434
    ],
    [
        83.3040996,
        47.1777923
    ],
    [
        83.3055111,
        47.177831
    ],
    [
        83.306361,
        47.1777188
    ],
    [
        83.3070706,
        47.1775543
    ],
    [
        83.3076861,
        47.1772308
    ],
    [
        83.3083717,
        47.1766958
    ],
    [
        83.308903,
        47.1761237
    ],
    [
        83.3098071,
        47.1757921
    ],
    [
        83.3114306,
        47.1750112
    ],
    [
        83.3128318,
        47.1747855
    ],
    [
        83.3138795,
        47.1742975
    ],
    [
        83.3145828,
        47.1740458
    ],
    [
        83.314834,
        47.1738422
    ],
    [
        83.314969,
        47.1735664
    ],
    [
        83.3152217,
        47.1733609
    ],
    [
        83.3161746,
        47.1727742
    ],
    [
        83.3164278,
        47.1724707
    ],
    [
        83.3178366,
        47.1717175
    ],
    [
        83.3181207,
        47.1714321
    ],
    [
        83.3185649,
        47.1713511
    ],
    [
        83.3199266,
        47.1712376
    ],
    [
        83.3256113,
        47.1714676
    ],
    [
        83.3266528,
        47.1714415
    ],
    [
        83.3310656,
        47.1707278
    ],
    [
        83.3326113,
        47.1708475
    ],
    [
        83.3329945,
        47.170918
    ],
    [
        83.3337469,
        47.1709427
    ],
    [
        83.3340919,
        47.1709123
    ],
    [
        83.3343451,
        47.1707772
    ],
    [
        83.3348322,
        47.1701173
    ],
    [
        83.3350891,
        47.1700044
    ],
    [
        83.3355093,
        47.1699743
    ],
    [
        83.3360137,
        47.1699977
    ],
    [
        83.3366034,
        47.1701885
    ],
    [
        83.3374968,
        47.1712296
    ],
    [
        83.3384742,
        47.1716293
    ],
    [
        83.3388272,
        47.1720461
    ],
    [
        83.3390536,
        47.1722153
    ],
    [
        83.339367,
        47.1723277
    ],
    [
        83.3409345,
        47.1725565
    ],
    [
        83.3423238,
        47.1726118
    ],
    [
        83.3427057,
        47.1727307
    ],
    [
        83.3438975,
        47.1726679
    ],
    [
        83.3449239,
        47.1727466
    ],
    [
        83.3453197,
        47.1726902
    ],
    [
        83.3459083,
        47.172781
    ],
    [
        83.3463592,
        47.172667
    ],
    [
        83.3470138,
        47.1727506
    ],
    [
        83.3477984,
        47.1729237
    ],
    [
        83.3489375,
        47.1732871
    ],
    [
        83.3491743,
        47.173505
    ],
    [
        83.3495307,
        47.1737201
    ],
    [
        83.3498884,
        47.1737982
    ],
    [
        83.3507149,
        47.1738406
    ],
    [
        83.3523147,
        47.1737916
    ],
    [
        83.3533515,
        47.1740903
    ],
    [
        83.3537513,
        47.1743167
    ],
    [
        83.3539786,
        47.1745596
    ],
    [
        83.3538845,
        47.1751703
    ],
    [
        83.3539182,
        47.1754874
    ],
    [
        83.3540285,
        47.1760864
    ],
    [
        83.354255,
        47.1763087
    ],
    [
        83.3542657,
        47.1767057
    ],
    [
        83.3562084,
        47.1768314
    ],
    [
        83.3577902,
        47.1772597
    ],
    [
        83.358576,
        47.1773755
    ],
    [
        83.3593378,
        47.1774155
    ],
    [
        83.3596152,
        47.1775256
    ],
    [
        83.3598784,
        47.1777048
    ],
    [
        83.3602894,
        47.1777783
    ],
    [
        83.361146,
        47.1780366
    ],
    [
        83.3617893,
        47.1779937
    ],
    [
        83.3624067,
        47.1778122
    ],
    [
        83.3632216,
        47.177702
    ],
    [
        83.3648906,
        47.1777807
    ],
    [
        83.3652956,
        47.1760834
    ],
    [
        83.3652108,
        47.1756683
    ],
    [
        83.3642703,
        47.174437
    ],
    [
        83.3626194,
        47.1725461
    ],
    [
        83.3619772,
        47.1716733
    ],
    [
        83.3617583,
        47.1710714
    ],
    [
        83.3621324,
        47.1701377
    ],
    [
        83.3621691,
        47.1695253
    ],
    [
        83.3622929,
        47.1693753
    ],
    [
        83.362676,
        47.1692218
    ],
    [
        83.3642461,
        47.1677913
    ],
    [
        83.3643653,
        47.1670508
    ],
    [
        83.3642628,
        47.1653444
    ],
    [
        83.3645324,
        47.1638665
    ],
    [
        83.3653237,
        47.1629946
    ],
    [
        83.3670782,
        47.1619175
    ],
    [
        83.3683504,
        47.1610007
    ],
    [
        83.3696478,
        47.1602114
    ],
    [
        83.3729763,
        47.1584182
    ],
    [
        83.3744776,
        47.1581223
    ],
    [
        83.3747278,
        47.1579079
    ],
    [
        83.3748864,
        47.1576109
    ],
    [
        83.3752784,
        47.1575868
    ],
    [
        83.3759955,
        47.1571205
    ],
    [
        83.3764592,
        47.1566287
    ],
    [
        83.376965,
        47.1563501
    ],
    [
        83.3790373,
        47.1556167
    ],
    [
        83.3794615,
        47.1553906
    ],
    [
        83.3806565,
        47.1541963
    ],
    [
        83.3820393,
        47.1515434
    ],
    [
        83.3842659,
        47.1511994
    ],
    [
        83.3855535,
        47.1507934
    ],
    [
        83.38628,
        47.1502218
    ],
    [
        83.387302,
        47.1491274
    ],
    [
        83.3878177,
        47.148388
    ],
    [
        83.3879674,
        47.1476245
    ],
    [
        83.3879299,
        47.1466602
    ],
    [
        83.3874315,
        47.1448957
    ],
    [
        83.3873422,
        47.144295
    ],
    [
        83.3875685,
        47.1430428
    ],
    [
        83.3880651,
        47.141844
    ],
    [
        83.3888252,
        47.1409534
    ],
    [
        83.3900841,
        47.1401321
    ],
    [
        83.391878,
        47.1394139
    ],
    [
        83.3928163,
        47.1384197
    ],
    [
        83.3949057,
        47.1365039
    ],
    [
        83.3965238,
        47.1358283
    ],
    [
        83.3982179,
        47.1353344
    ],
    [
        83.399482,
        47.1348535
    ],
    [
        83.401316,
        47.133389
    ],
    [
        83.4023263,
        47.1324915
    ],
    [
        83.4039483,
        47.131533
    ],
    [
        83.4072869,
        47.1284465
    ],
    [
        83.4080982,
        47.1273636
    ],
    [
        83.4083805,
        47.1267223
    ],
    [
        83.4084167,
        47.1253987
    ],
    [
        83.4087416,
        47.1242322
    ],
    [
        83.4091186,
        47.1234535
    ],
    [
        83.409813,
        47.1226778
    ],
    [
        83.4105168,
        47.1222781
    ],
    [
        83.4118505,
        47.1218656
    ],
    [
        83.4131043,
        47.1211668
    ],
    [
        83.4137281,
        47.1205132
    ],
    [
        83.4137477,
        47.1199216
    ],
    [
        83.4127467,
        47.117929
    ],
    [
        83.4127236,
        47.1171198
    ],
    [
        83.4130773,
        47.1163075
    ],
    [
        83.4134661,
        47.1159925
    ],
    [
        83.4159213,
        47.1157795
    ],
    [
        83.4170858,
        47.1155085
    ],
    [
        83.4189721,
        47.1156948
    ],
    [
        83.4208087,
        47.1155042
    ],
    [
        83.4233537,
        47.1150401
    ],
    [
        83.4248233,
        47.1149999
    ],
    [
        83.4275639,
        47.1145633
    ],
    [
        83.4287509,
        47.1145463
    ],
    [
        83.4292668,
        47.1148225
    ],
    [
        83.4295829,
        47.1155227
    ],
    [
        83.4281856,
        47.1183486
    ],
    [
        83.4278958,
        47.1196214
    ],
    [
        83.4279677,
        47.1201103
    ],
    [
        83.4286586,
        47.1216105
    ],
    [
        83.4298918,
        47.1232471
    ],
    [
        83.4325936,
        47.1240469
    ],
    [
        83.4373702,
        47.1248438
    ],
    [
        83.4407552,
        47.1253496
    ],
    [
        83.4433588,
        47.1265145
    ],
    [
        83.445705,
        47.1291164
    ],
    [
        83.4468499,
        47.1299821
    ],
    [
        83.4485124,
        47.1308146
    ],
    [
        83.449236,
        47.131404
    ],
    [
        83.4515326,
        47.1316933
    ],
    [
        83.4521189,
        47.1315694
    ],
    [
        83.4530074,
        47.1310452
    ],
    [
        83.4536147,
        47.1309837
    ],
    [
        83.4551187,
        47.1314113
    ],
    [
        83.4567899,
        47.1320599
    ],
    [
        83.4575394,
        47.1320721
    ],
    [
        83.4580554,
        47.1317834
    ],
    [
        83.4596665,
        47.130637
    ],
    [
        83.4600398,
        47.1295775
    ],
    [
        83.4614448,
        47.1283355
    ],
    [
        83.4621493,
        47.1280036
    ],
    [
        83.4627612,
        47.1278496
    ],
    [
        83.4637564,
        47.127939
    ],
    [
        83.4645896,
        47.1281422
    ],
    [
        83.4650683,
        47.128096
    ],
    [
        83.4658459,
        47.1277181
    ],
    [
        83.4663977,
        47.1276452
    ],
    [
        83.4692625,
        47.12815
    ],
    [
        83.4703752,
        47.1281123
    ],
    [
        83.4707805,
        47.1280202
    ],
    [
        83.4709722,
        47.1278081
    ],
    [
        83.4709527,
        47.1276157
    ],
    [
        83.4706184,
        47.1274048
    ],
    [
        83.4678058,
        47.1266133
    ],
    [
        83.4667027,
        47.1262158
    ],
    [
        83.4657866,
        47.1256597
    ],
    [
        83.4650377,
        47.1248899
    ],
    [
        83.4644327,
        47.1234983
    ],
    [
        83.4640572,
        47.1228613
    ],
    [
        83.463106,
        47.1218375
    ],
    [
        83.4629652,
        47.1215788
    ],
    [
        83.463061,
        47.1212527
    ],
    [
        83.4641822,
        47.1202383
    ],
    [
        83.4643622,
        47.1199945
    ],
    [
        83.4645365,
        47.1193598
    ],
    [
        83.4649029,
        47.1190278
    ],
    [
        83.4656729,
        47.1188234
    ],
    [
        83.4665515,
        47.1187367
    ],
    [
        83.4671884,
        47.11841
    ],
    [
        83.4676177,
        47.1182931
    ],
    [
        83.4685769,
        47.1181349
    ],
    [
        83.4711842,
        47.1181267
    ],
    [
        83.4729306,
        47.1180498
    ],
    [
        83.474863,
        47.1178299
    ],
    [
        83.4760145,
        47.1178908
    ],
    [
        83.4785233,
        47.1178473
    ],
    [
        83.4803429,
        47.1177416
    ],
    [
        83.4811573,
        47.1175318
    ],
    [
        83.4816675,
        47.1170496
    ],
    [
        83.4820633,
        47.1157559
    ],
    [
        83.4826033,
        47.1147054
    ],
    [
        83.4832349,
        47.1140476
    ],
    [
        83.4842204,
        47.1134315
    ],
    [
        83.4842406,
        47.1131113
    ],
    [
        83.4827524,
        47.1108371
    ],
    [
        83.4816235,
        47.109785
    ],
    [
        83.4823303,
        47.1093471
    ],
    [
        83.4832017,
        47.1091283
    ],
    [
        83.4852059,
        47.1090626
    ],
    [
        83.486302,
        47.1091635
    ],
    [
        83.4890497,
        47.109634
    ],
    [
        83.4909774,
        47.1098911
    ],
    [
        83.4926229,
        47.1099958
    ],
    [
        83.4938648,
        47.1099607
    ],
    [
        83.4946396,
        47.1097264
    ],
    [
        83.4951793,
        47.1094073
    ],
    [
        83.4966321,
        47.1081906
    ],
    [
        83.4976197,
        47.1076453
    ],
    [
        83.4986421,
        47.1073653
    ],
    [
        83.4994793,
        47.1073796
    ],
    [
        83.5001321,
        47.1075335
    ],
    [
        83.501123,
        47.107894
    ],
    [
        83.5023685,
        47.1085991
    ],
    [
        83.5038332,
        47.1091584
    ],
    [
        83.5053934,
        47.1096057
    ],
    [
        83.5059296,
        47.1095069
    ],
    [
        83.5067048,
        47.1088176
    ],
    [
        83.5074307,
        47.1084946
    ],
    [
        83.5089197,
        47.1074873
    ],
    [
        83.509177,
        47.1069914
    ],
    [
        83.5094135,
        47.1058423
    ],
    [
        83.5097028,
        47.1051407
    ],
    [
        83.509783,
        47.1044832
    ],
    [
        83.5097613,
        47.1038317
    ],
    [
        83.5099548,
        47.1034055
    ],
    [
        83.5103846,
        47.1028262
    ],
    [
        83.5106577,
        47.102308
    ],
    [
        83.5115641,
        47.0999977
    ],
    [
        83.5119098,
        47.0995674
    ],
    [
        83.5138909,
        47.0982536
    ],
    [
        83.5148329,
        47.0974718
    ],
    [
        83.5152029,
        47.0967109
    ],
    [
        83.5155737,
        47.0952451
    ],
    [
        83.5160122,
        47.0945999
    ],
    [
        83.5165783,
        47.0941147
    ],
    [
        83.5168725,
        47.0939995
    ],
    [
        83.5172897,
        47.0939198
    ],
    [
        83.5175557,
        47.0939309
    ],
    [
        83.5181044,
        47.094123
    ],
    [
        83.5189587,
        47.0947939
    ],
    [
        83.519294,
        47.094999
    ],
    [
        83.5196133,
        47.0950681
    ],
    [
        83.5199017,
        47.0950587
    ],
    [
        83.5200005,
        47.0949275
    ],
    [
        83.5200139,
        47.0947468
    ],
    [
        83.5198279,
        47.0939453
    ],
    [
        83.5199146,
        47.0932497
    ],
    [
        83.520128,
        47.0930244
    ],
    [
        83.5204915,
        47.0929116
    ],
    [
        83.5242327,
        47.0924458
    ],
    [
        83.5247062,
        47.0923302
    ],
    [
        83.5250273,
        47.0921917
    ],
    [
        83.5257788,
        47.0917204
    ],
    [
        83.5268646,
        47.0907856
    ],
    [
        83.5270624,
        47.0904409
    ],
    [
        83.5271245,
        47.0900607
    ],
    [
        83.5271027,
        47.0897006
    ],
    [
        83.5265582,
        47.0882259
    ],
    [
        83.5261485,
        47.0873173
    ],
    [
        83.5248231,
        47.0858635
    ],
    [
        83.5246995,
        47.0855959
    ],
    [
        83.5247835,
        47.0853578
    ],
    [
        83.5249814,
        47.0852174
    ],
    [
        83.527544,
        47.084694
    ],
    [
        83.5281841,
        47.0844987
    ],
    [
        83.5305297,
        47.0843475
    ],
    [
        83.5313338,
        47.0842035
    ],
    [
        83.5326487,
        47.0838294
    ],
    [
        83.5341248,
        47.0832976
    ],
    [
        83.5359197,
        47.0829887
    ],
    [
        83.5367429,
        47.0830082
    ],
    [
        83.5393714,
        47.0833662
    ],
    [
        83.5429125,
        47.0835765
    ],
    [
        83.5445865,
        47.0835453
    ],
    [
        83.5456612,
        47.0834679
    ],
    [
        83.5460959,
        47.0833384
    ],
    [
        83.546587,
        47.0831011
    ],
    [
        83.5475089,
        47.0824787
    ],
    [
        83.5483094,
        47.0814775
    ],
    [
        83.5488761,
        47.0802805
    ],
    [
        83.5492088,
        47.0801955
    ],
    [
        83.5501267,
        47.0801436
    ],
    [
        83.5529382,
        47.080162
    ],
    [
        83.5563853,
        47.0799158
    ],
    [
        83.5605175,
        47.0792271
    ],
    [
        83.5615678,
        47.0789991
    ],
    [
        83.5624424,
        47.0789115
    ],
    [
        83.5634156,
        47.0789161
    ],
    [
        83.5644832,
        47.0787779
    ],
    [
        83.5650154,
        47.0786391
    ],
    [
        83.5655686,
        47.0783995
    ],
    [
        83.5660067,
        47.0781228
    ],
    [
        83.565577,
        47.0770684
    ],
    [
        83.5653335,
        47.0759527
    ],
    [
        83.5652818,
        47.0750534
    ],
    [
        83.5653688,
        47.0742131
    ],
    [
        83.5648104,
        47.0713409
    ],
    [
        83.5642629,
        47.0698934
    ],
    [
        83.5628138,
        47.0677354
    ],
    [
        83.5623769,
        47.0669935
    ],
    [
        83.5619705,
        47.0656166
    ],
    [
        83.5617005,
        47.0650964
    ],
    [
        83.5609922,
        47.0642854
    ],
    [
        83.5601559,
        47.0635051
    ],
    [
        83.559911,
        47.0629982
    ],
    [
        83.5599554,
        47.062546
    ],
    [
        83.5598851,
        47.0618251
    ],
    [
        83.5596966,
        47.0611355
    ],
    [
        83.5597158,
        47.060693
    ],
    [
        83.5598109,
        47.0603638
    ],
    [
        83.560154,
        47.0599509
    ],
    [
        83.5608881,
        47.0596004
    ],
    [
        83.561451,
        47.0591784
    ],
    [
        83.562175,
        47.0583487
    ],
    [
        83.5626968,
        47.0579247
    ],
    [
        83.5641053,
        47.0570618
    ],
    [
        83.5647625,
        47.0565509
    ],
    [
        83.5651078,
        47.0563578
    ],
    [
        83.5660434,
        47.056267
    ],
    [
        83.5669234,
        47.0562447
    ],
    [
        83.5682285,
        47.0560249
    ],
    [
        83.5689291,
        47.05605
    ],
    [
        83.5693793,
        47.0562788
    ],
    [
        83.570826,
        47.0581084
    ],
    [
        83.5720199,
        47.0593847
    ],
    [
        83.5726852,
        47.0599219
    ],
    [
        83.5734987,
        47.0602946
    ],
    [
        83.5741499,
        47.0603801
    ],
    [
        83.5779714,
        47.06054
    ],
    [
        83.5787928,
        47.0605058
    ],
    [
        83.579695,
        47.0602889
    ],
    [
        83.5807911,
        47.0597636
    ],
    [
        83.5822274,
        47.0594277
    ],
    [
        83.5826751,
        47.0594659
    ],
    [
        83.5837105,
        47.0598147
    ],
    [
        83.5842266,
        47.0598916
    ],
    [
        83.5850221,
        47.0598134
    ],
    [
        83.5866457,
        47.0593977
    ],
    [
        83.587491,
        47.0593274
    ],
    [
        83.5883091,
        47.0591605
    ],
    [
        83.5902152,
        47.0589234
    ],
    [
        83.5914236,
        47.058558
    ],
    [
        83.5930953,
        47.0579004
    ],
    [
        83.5956407,
        47.0565789
    ],
    [
        83.5968343,
        47.0561796
    ],
    [
        83.598195,
        47.055799
    ],
    [
        83.5995147,
        47.0555421
    ],
    [
        83.6020212,
        47.0551661
    ],
    [
        83.6030121,
        47.0549334
    ],
    [
        83.6038532,
        47.0546344
    ],
    [
        83.6043279,
        47.0542407
    ],
    [
        83.6053912,
        47.0529088
    ],
    [
        83.6066252,
        47.052055
    ],
    [
        83.6067959,
        47.0517985
    ],
    [
        83.6068657,
        47.0513445
    ],
    [
        83.6067314,
        47.0495551
    ],
    [
        83.6067983,
        47.0485347
    ],
    [
        83.6066801,
        47.0472553
    ],
    [
        83.6067229,
        47.0468465
    ],
    [
        83.6068753,
        47.0465962
    ],
    [
        83.6070942,
        47.0463934
    ],
    [
        83.607391,
        47.0462812
    ],
    [
        83.6076546,
        47.0462861
    ],
    [
        83.6089382,
        47.0467015
    ],
    [
        83.6103882,
        47.0477989
    ],
    [
        83.6122141,
        47.0483222
    ],
    [
        83.6134346,
        47.048611
    ],
    [
        83.614314,
        47.0487077
    ],
    [
        83.6164754,
        47.0487547
    ],
    [
        83.6172743,
        47.0488638
    ],
    [
        83.6177951,
        47.048857
    ],
    [
        83.6191092,
        47.0487655
    ],
    [
        83.6199657,
        47.0486427
    ],
    [
        83.6219307,
        47.048455
    ],
    [
        83.6236525,
        47.0481453
    ],
    [
        83.624202,
        47.0477972
    ],
    [
        83.6244897,
        47.047482
    ],
    [
        83.6247101,
        47.0467897
    ],
    [
        83.6249469,
        47.0462925
    ],
    [
        83.6253025,
        47.0459669
    ],
    [
        83.6258072,
        47.0458231
    ],
    [
        83.6267819,
        47.0457355
    ],
    [
        83.6349768,
        47.0446613
    ],
    [
        83.6363884,
        47.0443925
    ],
    [
        83.6386535,
        47.0436189
    ],
    [
        83.6401408,
        47.04318
    ],
    [
        83.6406279,
        47.04323
    ],
    [
        83.6427436,
        47.0439751
    ],
    [
        83.6433412,
        47.0440115
    ],
    [
        83.6438913,
        47.0439557
    ],
    [
        83.645902,
        47.0434287
    ],
    [
        83.6480854,
        47.0430233
    ],
    [
        83.652932,
        47.0421871
    ],
    [
        83.654939,
        47.0418864
    ],
    [
        83.6555082,
        47.0417122
    ],
    [
        83.6575933,
        47.0404757
    ],
    [
        83.6586494,
        47.0400617
    ],
    [
        83.6599438,
        47.0398215
    ],
    [
        83.6631293,
        47.0394742
    ],
    [
        83.6656255,
        47.0389819
    ],
    [
        83.6660503,
        47.0387044
    ],
    [
        83.6664697,
        47.0380822
    ],
    [
        83.6668492,
        47.0379179
    ],
    [
        83.6671895,
        47.0378939
    ],
    [
        83.668771,
        47.0382505
    ],
    [
        83.669295,
        47.0383114
    ],
    [
        83.6700968,
        47.0383092
    ],
    [
        83.670723,
        47.0383654
    ],
    [
        83.6714052,
        47.0385259
    ],
    [
        83.6718158,
        47.0385807
    ],
    [
        83.6722439,
        47.0385732
    ],
    [
        83.6726312,
        47.0384427
    ],
    [
        83.6733985,
        47.0378146
    ],
    [
        83.6741835,
        47.0372991
    ],
    [
        83.6751101,
        47.0368867
    ],
    [
        83.6774242,
        47.0361441
    ],
    [
        83.6795935,
        47.0353558
    ],
    [
        83.6802047,
        47.035182
    ],
    [
        83.6827369,
        47.0353163
    ],
    [
        83.6840345,
        47.03528
    ],
    [
        83.6850054,
        47.0353203
    ],
    [
        83.6863652,
        47.0354542
    ],
    [
        83.687921,
        47.0353072
    ],
    [
        83.6885266,
        47.0352908
    ],
    [
        83.6934731,
        47.035843
    ],
    [
        83.6944998,
        47.0357134
    ],
    [
        83.6956364,
        47.0357719
    ],
    [
        83.695776,
        47.0356253
    ],
    [
        83.6957878,
        47.0349609
    ],
    [
        83.6960258,
        47.0346711
    ],
    [
        83.6968089,
        47.034115
    ],
    [
        83.6981085,
        47.0333026
    ],
    [
        83.6987175,
        47.0325784
    ],
    [
        83.6988825,
        47.0317309
    ],
    [
        83.6988902,
        47.0311342
    ],
    [
        83.6990641,
        47.0309299
    ],
    [
        83.6998042,
        47.0304495
    ],
    [
        83.7006138,
        47.0297229
    ],
    [
        83.7007208,
        47.0294002
    ],
    [
        83.7005749,
        47.0290104
    ],
    [
        83.7000651,
        47.0280396
    ],
    [
        83.7001266,
        47.0277578
    ],
    [
        83.7008808,
        47.0262205
    ],
    [
        83.7010168,
        47.0257454
    ],
    [
        83.7010063,
        47.0252378
    ],
    [
        83.7008375,
        47.0242435
    ],
    [
        83.7008617,
        47.0231334
    ],
    [
        83.7010282,
        47.0226524
    ],
    [
        83.7014966,
        47.0218898
    ],
    [
        83.7019831,
        47.0212955
    ],
    [
        83.702295,
        47.0207155
    ],
    [
        83.7021133,
        47.0204998
    ],
    [
        83.6980318,
        47.0181991
    ],
    [
        83.6979889,
        47.0177895
    ],
    [
        83.698697,
        47.0175993
    ],
    [
        83.6997055,
        47.0177017
    ],
    [
        83.701735,
        47.0175909
    ],
    [
        83.7028871,
        47.0175864
    ],
    [
        83.705704,
        47.017476
    ],
    [
        83.7070593,
        47.0175119
    ],
    [
        83.7073975,
        47.0176522
    ],
    [
        83.707526,
        47.0178498
    ],
    [
        83.7084301,
        47.0201101
    ],
    [
        83.7087576,
        47.0206325
    ],
    [
        83.7106108,
        47.0220158
    ],
    [
        83.7114892,
        47.0228059
    ],
    [
        83.712,
        47.0238876
    ],
    [
        83.7132745,
        47.0242114
    ],
    [
        83.7148968,
        47.0244041
    ],
    [
        83.7157158,
        47.0246416
    ],
    [
        83.7168518,
        47.0252311
    ],
    [
        83.7178218,
        47.0256147
    ],
    [
        83.7187456,
        47.0258113
    ],
    [
        83.7218313,
        47.0261062
    ],
    [
        83.725405,
        47.0265121
    ],
    [
        83.7266144,
        47.0265905
    ],
    [
        83.7277296,
        47.0265437
    ],
    [
        83.7282593,
        47.0266568
    ],
    [
        83.7300401,
        47.0273478
    ],
    [
        83.7308302,
        47.0274217
    ],
    [
        83.7324469,
        47.0274798
    ],
    [
        83.7327497,
        47.0275791
    ],
    [
        83.7337549,
        47.0286434
    ],
    [
        83.7357659,
        47.0302674
    ],
    [
        83.737175,
        47.0315349
    ],
    [
        83.7387185,
        47.0325743
    ],
    [
        83.7393796,
        47.0328721
    ],
    [
        83.7402354,
        47.0330737
    ],
    [
        83.7415975,
        47.0330728
    ],
    [
        83.7433037,
        47.0330133
    ],
    [
        83.7462352,
        47.0327564
    ],
    [
        83.7472712,
        47.0327774
    ],
    [
        83.747827,
        47.0326582
    ],
    [
        83.7482063,
        47.0322459
    ],
    [
        83.7491927,
        47.0294604
    ],
    [
        83.7495061,
        47.0292375
    ],
    [
        83.7506507,
        47.028781
    ],
    [
        83.7514951,
        47.0285105
    ],
    [
        83.7523873,
        47.0278515
    ],
    [
        83.7531789,
        47.0273831
    ],
    [
        83.7537067,
        47.0269999
    ],
    [
        83.753978,
        47.0264679
    ],
    [
        83.7540347,
        47.0257612
    ],
    [
        83.7541292,
        47.025429
    ],
    [
        83.7563649,
        47.0246667
    ],
    [
        83.7579633,
        47.024186
    ],
    [
        83.7584629,
        47.0241826
    ],
    [
        83.7588177,
        47.024299
    ],
    [
        83.7597332,
        47.0248626
    ],
    [
        83.760811,
        47.0253458
    ],
    [
        83.7614141,
        47.0255396
    ],
    [
        83.762097,
        47.025892
    ],
    [
        83.7624458,
        47.0263567
    ],
    [
        83.7625948,
        47.026816
    ],
    [
        83.7641992,
        47.0268305
    ],
    [
        83.7655549,
        47.0267769
    ],
    [
        83.7683409,
        47.0268847
    ],
    [
        83.7695769,
        47.0268353
    ],
    [
        83.7705566,
        47.0265336
    ],
    [
        83.772085,
        47.0258905
    ],
    [
        83.77302,
        47.0258256
    ],
    [
        83.7739414,
        47.0255797
    ],
    [
        83.7750851,
        47.0250449
    ],
    [
        83.7766682,
        47.024631
    ],
    [
        83.7781839,
        47.0243729
    ],
    [
        83.7801235,
        47.0244548
    ],
    [
        83.7824337,
        47.0243799
    ],
    [
        83.783512,
        47.024266
    ],
    [
        83.7845704,
        47.0239789
    ],
    [
        83.7850695,
        47.0236266
    ],
    [
        83.7856096,
        47.0231472
    ],
    [
        83.7859921,
        47.0227139
    ],
    [
        83.7860665,
        47.0222747
    ],
    [
        83.7862782,
        47.0217948
    ],
    [
        83.7867193,
        47.0215683
    ],
    [
        83.7881471,
        47.02063
    ],
    [
        83.7887734,
        47.0200533
    ],
    [
        83.7897439,
        47.019484
    ],
    [
        83.7905204,
        47.0189319
    ],
    [
        83.7913882,
        47.0182003
    ],
    [
        83.7917031,
        47.018024
    ],
    [
        83.7922673,
        47.017878
    ],
    [
        83.7936998,
        47.0169185
    ],
    [
        83.7947393,
        47.0174014
    ],
    [
        83.7959452,
        47.0182285
    ],
    [
        83.7966898,
        47.0183542
    ],
    [
        83.7975973,
        47.0183522
    ],
    [
        83.7983851,
        47.0182621
    ],
    [
        83.7997201,
        47.0176569
    ],
    [
        83.8008599,
        47.0170267
    ],
    [
        83.8021492,
        47.0167882
    ],
    [
        83.8036294,
        47.016658
    ],
    [
        83.8051381,
        47.0163207
    ],
    [
        83.8063647,
        47.0159161
    ],
    [
        83.8069064,
        47.0158483
    ],
    [
        83.8080447,
        47.016189
    ],
    [
        83.8089759,
        47.016279
    ],
    [
        83.8103339,
        47.0163219
    ],
    [
        83.8112355,
        47.0165052
    ],
    [
        83.8122753,
        47.0164403
    ],
    [
        83.8134185,
        47.0159498
    ],
    [
        83.8137162,
        47.0150076
    ],
    [
        83.8148569,
        47.0139912
    ],
    [
        83.8173789,
        47.0129769
    ],
    [
        83.8190433,
        47.0124061
    ],
    [
        83.8204592,
        47.0123133
    ],
    [
        83.821235,
        47.0122111
    ],
    [
        83.8220046,
        47.0117423
    ],
    [
        83.8249814,
        47.0116059
    ],
    [
        83.8265407,
        47.0112479
    ],
    [
        83.8277606,
        47.0107341
    ],
    [
        83.8291174,
        47.0103767
    ],
    [
        83.8299917,
        47.009626
    ],
    [
        83.8320882,
        47.0085674
    ],
    [
        83.8336095,
        47.0073666
    ],
    [
        83.8343083,
        47.0066192
    ],
    [
        83.8349891,
        47.0052249
    ],
    [
        83.8357785,
        47.0045655
    ],
    [
        83.8361605,
        47.0041796
    ],
    [
        83.8370137,
        47.0037101
    ],
    [
        83.8372802,
        47.0033519
    ],
    [
        83.8372799,
        47.0029636
    ],
    [
        83.8370772,
        47.0024065
    ],
    [
        83.8367106,
        47.0020266
    ],
    [
        83.8363859,
        47.0018618
    ],
    [
        83.8357313,
        47.0013348
    ],
    [
        83.8353524,
        47.0006803
    ],
    [
        83.8352905,
        47.0000724
    ],
    [
        83.8353388,
        46.9993818
    ],
    [
        83.835098,
        46.999089
    ],
    [
        83.8353033,
        46.9982681
    ],
    [
        83.8348985,
        46.9980098
    ],
    [
        83.8346387,
        46.9976044
    ],
    [
        83.8345127,
        46.9972505
    ],
    [
        83.8344701,
        46.9968583
    ],
    [
        83.8345217,
        46.9962011
    ],
    [
        83.8346698,
        46.995709
    ],
    [
        83.834783,
        46.9950009
    ],
    [
        83.8349936,
        46.9947106
    ],
    [
        83.8353888,
        46.9945833
    ],
    [
        83.8356901,
        46.9946104
    ],
    [
        83.836306,
        46.994816
    ],
    [
        83.836683,
        46.9952096
    ],
    [
        83.8375592,
        46.9957339
    ],
    [
        83.838396,
        46.9964484
    ],
    [
        83.8392052,
        46.9967488
    ],
    [
        83.8400054,
        46.9967782
    ],
    [
        83.840539,
        46.9967256
    ],
    [
        83.8420874,
        46.9965339
    ],
    [
        83.8429607,
        46.9962926
    ],
    [
        83.8455811,
        46.9961062
    ],
    [
        83.8459119,
        46.9958432
    ],
    [
        83.8462945,
        46.9957223
    ],
    [
        83.8463844,
        46.9954117
    ],
    [
        83.8469676,
        46.9954925
    ],
    [
        83.8474743,
        46.99543
    ],
    [
        83.8484096,
        46.9955752
    ],
    [
        83.849387,
        46.9953684
    ],
    [
        83.8510397,
        46.995125
    ],
    [
        83.8521589,
        46.9953371
    ],
    [
        83.8542179,
        46.9963868
    ],
    [
        83.8559917,
        46.9970003
    ],
    [
        83.8568715,
        46.9972238
    ],
    [
        83.8592352,
        46.9979424
    ],
    [
        83.8613071,
        46.9965769
    ],
    [
        83.8623749,
        46.9961554
    ],
    [
        83.8630087,
        46.9961975
    ],
    [
        83.8641697,
        46.9964016
    ],
    [
        83.8652254,
        46.9963362
    ],
    [
        83.8677404,
        46.9958249
    ],
    [
        83.8685479,
        46.995555
    ],
    [
        83.869727,
        46.995355
    ],
    [
        83.8708301,
        46.9950365
    ],
    [
        83.8714351,
        46.9942859
    ],
    [
        83.8718826,
        46.9932478
    ],
    [
        83.8716759,
        46.993037
    ],
    [
        83.8709847,
        46.9926472
    ],
    [
        83.8704224,
        46.9917798
    ],
    [
        83.8702041,
        46.9911857
    ],
    [
        83.8700536,
        46.9899469
    ],
    [
        83.870295,
        46.9889542
    ],
    [
        83.8708372,
        46.9882424
    ],
    [
        83.8709702,
        46.9878368
    ],
    [
        83.8708286,
        46.9869775
    ],
    [
        83.8719738,
        46.9868178
    ],
    [
        83.8732787,
        46.9873016
    ],
    [
        83.8737315,
        46.9873207
    ],
    [
        83.8743896,
        46.9871846
    ],
    [
        83.87485,
        46.9869029
    ],
    [
        83.8755032,
        46.9861943
    ],
    [
        83.8766454,
        46.9862418
    ],
    [
        83.8783033,
        46.9865787
    ],
    [
        83.8793663,
        46.9865643
    ],
    [
        83.8827227,
        46.9860007
    ],
    [
        83.8857635,
        46.9853531
    ],
    [
        83.8862757,
        46.9861473
    ],
    [
        83.8865863,
        46.9863079
    ],
    [
        83.8869696,
        46.9863271
    ],
    [
        83.8873109,
        46.9865825
    ],
    [
        83.8877097,
        46.9867806
    ],
    [
        83.8888429,
        46.9877247
    ],
    [
        83.8895555,
        46.9879466
    ],
    [
        83.8903885,
        46.9883495
    ],
    [
        83.8911752,
        46.9884619
    ],
    [
        83.8919521,
        46.9883669
    ],
    [
        83.8932147,
        46.9877265
    ],
    [
        83.8941871,
        46.987052
    ],
    [
        83.8950347,
        46.9866495
    ],
    [
        83.8963865,
        46.9862542
    ],
    [
        83.8969015,
        46.9851564
    ],
    [
        83.8981375,
        46.983991
    ],
    [
        83.8996695,
        46.984
    ],
    [
        83.9011403,
        46.9834148
    ],
    [
        83.9025204,
        46.9827497
    ],
    [
        83.9040731,
        46.9816352
    ],
    [
        83.9047917,
        46.9808269
    ],
    [
        83.9049596,
        46.9797841
    ],
    [
        83.9048408,
        46.9775879
    ],
    [
        83.903489,
        46.9762556
    ],
    [
        83.9020728,
        46.9750697
    ],
    [
        83.9031886,
        46.9748062
    ],
    [
        83.9044117,
        46.9745134
    ],
    [
        83.9069008,
        46.9742791
    ],
    [
        83.9111854,
        46.9742778
    ],
    [
        83.9138101,
        46.974894
    ],
    [
        83.9156126,
        46.9744548
    ],
    [
        83.916428,
        46.9728003
    ],
    [
        83.9172649,
        46.9728881
    ],
    [
        83.9193122,
        46.975557
    ],
    [
        83.9208697,
        46.9768266
    ],
    [
        83.9221644,
        46.979689
    ],
    [
        83.9228692,
        46.9807705
    ],
    [
        83.9262246,
        46.9847255
    ],
    [
        83.9270392,
        46.9849912
    ],
    [
        83.9293227,
        46.9855825
    ],
    [
        83.9306773,
        46.9856634
    ],
    [
        83.9313506,
        46.9853567
    ],
    [
        83.9316681,
        46.9848609
    ],
    [
        83.9321538,
        46.9844879
    ],
    [
        83.9328562,
        46.984347
    ],
    [
        83.9336838,
        46.984476
    ],
    [
        83.9346331,
        46.9849145
    ],
    [
        83.9357985,
        46.9851954
    ],
    [
        83.9369412,
        46.9858449
    ],
    [
        83.9385723,
        46.9868397
    ],
    [
        83.9406322,
        46.988406
    ],
    [
        83.9444438,
        46.9901767
    ],
    [
        83.9457036,
        46.9907005
    ],
    [
        83.9472097,
        46.9909876
    ],
    [
        83.9497697,
        46.9912358
    ],
    [
        83.951514,
        46.991203
    ],
    [
        83.9525413,
        46.9910912
    ],
    [
        83.9538469,
        46.990767
    ],
    [
        83.9552102,
        46.9902117
    ],
    [
        83.956551,
        46.989376
    ],
    [
        83.9575172,
        46.9884457
    ],
    [
        83.9579093,
        46.9871008
    ],
    [
        83.9587342,
        46.9847263
    ],
    [
        83.9588661,
        46.9839803
    ],
    [
        83.9592951,
        46.9834217
    ],
    [
        83.9607246,
        46.9824428
    ],
    [
        83.9631842,
        46.9835901
    ],
    [
        83.9650864,
        46.9839837
    ],
    [
        83.9674574,
        46.984007
    ],
    [
        83.9684233,
        46.9846192
    ],
    [
        83.9714357,
        46.9858655
    ],
    [
        83.9722562,
        46.9867317
    ],
    [
        83.9725315,
        46.9873166
    ],
    [
        83.973955,
        46.9881699
    ],
    [
        83.9760269,
        46.9897879
    ],
    [
        83.9776465,
        46.9908358
    ],
    [
        83.9788582,
        46.9913575
    ],
    [
        83.9822273,
        46.9924287
    ],
    [
        83.9832246,
        46.9931644
    ],
    [
        83.9839859,
        46.994303
    ],
    [
        83.9847167,
        46.9952583
    ],
    [
        83.9856482,
        46.9956887
    ],
    [
        83.9865235,
        46.9956643
    ],
    [
        83.9873137,
        46.9953264
    ],
    [
        83.9879516,
        46.994398
    ],
    [
        83.9887504,
        46.9942651
    ],
    [
        83.9898928,
        46.9941742
    ],
    [
        83.9911821,
        46.9938898
    ],
    [
        83.9930692,
        46.9930167
    ],
    [
        83.9954652,
        46.9920577
    ],
    [
        83.9980644,
        46.9914233
    ],
    [
        84.0033124,
        46.990485
    ],
    [
        84.0052754,
        46.9897516
    ],
    [
        84.0075462,
        46.9892662
    ],
    [
        84.008932,
        46.9880254
    ],
    [
        84.0098118,
        46.9866787
    ],
    [
        84.0101765,
        46.9853906
    ],
    [
        84.0099474,
        46.9839193
    ],
    [
        84.0102775,
        46.9831715
    ],
    [
        84.0111304,
        46.982884
    ],
    [
        84.0120219,
        46.9828874
    ],
    [
        84.01376,
        46.9835901
    ],
    [
        84.0148329,
        46.9843659
    ],
    [
        84.0159487,
        46.9847611
    ],
    [
        84.0180515,
        46.9843659
    ],
    [
        84.0217347,
        46.9822963
    ],
    [
        84.0231155,
        46.981321
    ],
    [
        84.0242957,
        46.9805891
    ],
    [
        84.0251792,
        46.9795272
    ],
    [
        84.025572,
        46.9788731
    ],
    [
        84.0289529,
        46.9780092
    ],
    [
        84.0326082,
        46.9778911
    ],
    [
        84.0338227,
        46.977147
    ],
    [
        84.0343057,
        46.9765192
    ],
    [
        84.0353413,
        46.9759823
    ],
    [
        84.0369986,
        46.9754943
    ],
    [
        84.0395309,
        46.9749438
    ],
    [
        84.0406601,
        46.9744094
    ],
    [
        84.0413101,
        46.9742998
    ],
    [
        84.0420734,
        46.9735837
    ],
    [
        84.0431205,
        46.9730736
    ],
    [
        84.0437422,
        46.9719691
    ],
    [
        84.0447025,
        46.9709132
    ],
    [
        84.0459786,
        46.9702548
    ],
    [
        84.0476541,
        46.9702495
    ],
    [
        84.0507779,
        46.969089
    ],
    [
        84.0529534,
        46.9687298
    ],
    [
        84.0550553,
        46.9687005
    ],
    [
        84.0564715,
        46.9687005
    ],
    [
        84.057877,
        46.96985
    ],
    [
        84.058628,
        46.9709481
    ],
    [
        84.0597491,
        46.9727564
    ],
    [
        84.0603446,
        46.9726503
    ],
    [
        84.0618681,
        46.9723465
    ],
    [
        84.0643464,
        46.9719218
    ],
    [
        84.0662991,
        46.971995
    ],
    [
        84.068005,
        46.9717827
    ],
    [
        84.0705822,
        46.971418
    ],
    [
        84.0734244,
        46.9709732
    ],
    [
        84.0755049,
        46.9702648
    ],
    [
        84.0768348,
        46.9699598
    ],
    [
        84.0779828,
        46.969213
    ],
    [
        84.0787982,
        46.9688177
    ],
    [
        84.0794097,
        46.9686566
    ],
    [
        84.0801929,
        46.9686127
    ],
    [
        84.0810083,
        46.9687591
    ],
    [
        84.0820168,
        46.9689641
    ],
    [
        84.0830056,
        46.9691591
    ],
    [
        84.0832536,
        46.9691959
    ],
    [
        84.0834925,
        46.9691316
    ],
    [
        84.0836596,
        46.9689098
    ],
    [
        84.083813,
        46.9688278
    ],
    [
        84.0841455,
        46.9688688
    ],
    [
        84.0844186,
        46.9688525
    ],
    [
        84.0854034,
        46.9689282
    ],
    [
        84.0856075,
        46.9689274
    ],
    [
        84.0864745,
        46.9688753
    ],
    [
        84.0867535,
        46.9687897
    ],
    [
        84.0870918,
        46.9688041
    ],
    [
        84.087433,
        46.9687816
    ],
    [
        84.0877298,
        46.9688301
    ],
    [
        84.0880255,
        46.9688275
    ],
    [
        84.0880776,
        46.9688307
    ],
    [
        84.0881197,
        46.9688388
    ],
    [
        84.0882885,
        46.9689394
    ],
    [
        84.0885144,
        46.9690476
    ],
    [
        84.0886609,
        46.9690624
    ],
    [
        84.0887324,
        46.9690534
    ],
    [
        84.0889394,
        46.9689882
    ],
    [
        84.0892613,
        46.9689657
    ],
    [
        84.0895666,
        46.968985
    ],
    [
        84.0896605,
        46.9689519
    ],
    [
        84.089766,
        46.9688293
    ],
    [
        84.0898422,
        46.9687827
    ],
    [
        84.0899139,
        46.9687645
    ],
    [
        84.0900493,
        46.9687695
    ],
    [
        84.0903584,
        46.9688293
    ],
    [
        84.0907588,
        46.968593
    ],
    [
        84.0909253,
        46.9685544
    ],
    [
        84.0912109,
        46.9684432
    ],
    [
        84.091362,
        46.9684254
    ],
    [
        84.0916342,
        46.9684285
    ],
    [
        84.0925522,
        46.9685652
    ],
    [
        84.0929095,
        46.9686032
    ],
    [
        84.0932081,
        46.9686099
    ],
    [
        84.0933434,
        46.9685917
    ],
    [
        84.0937386,
        46.968599
    ],
    [
        84.0940033,
        46.9685738
    ],
    [
        84.0942481,
        46.9686541
    ],
    [
        84.0944639,
        46.968677
    ],
    [
        84.0952867,
        46.9688628
    ],
    [
        84.0958326,
        46.9688219
    ],
    [
        84.0962441,
        46.9687098
    ],
    [
        84.0964134,
        46.9686997
    ],
    [
        84.0972365,
        46.9687483
    ],
    [
        84.0982542,
        46.9690433
    ],
    [
        84.0990747,
        46.9691549
    ],
    [
        84.100207,
        46.9692531
    ],
    [
        84.101106,
        46.9693697
    ],
    [
        84.1013151,
        46.9693053
    ],
    [
        84.1014564,
        46.9693035
    ],
    [
        84.1016937,
        46.9693002
    ],
    [
        84.1018457,
        46.9692807
    ],
    [
        84.1021109,
        46.9691922
    ],
    [
        84.102428,
        46.9691524
    ],
    [
        84.1025344,
        46.9690753
    ],
    [
        84.1027276,
        46.9688299
    ],
    [
        84.102901,
        46.968707
    ],
    [
        84.1034493,
        46.9686253
    ],
    [
        84.1037456,
        46.9686238
    ],
    [
        84.1043003,
        46.9686613
    ],
    [
        84.1046008,
        46.9687091
    ],
    [
        84.105536,
        46.9692189
    ],
    [
        84.1056062,
        46.9692389
    ],
    [
        84.1057457,
        46.9692455
    ],
    [
        84.1074743,
        46.9691621
    ],
    [
        84.1081565,
        46.9688738
    ],
    [
        84.1089305,
        46.9684014
    ],
    [
        84.10937,
        46.968434
    ],
    [
        84.1102101,
        46.9682672
    ],
    [
        84.1107516,
        46.967745
    ],
    [
        84.1115786,
        46.9674606
    ],
    [
        84.1117427,
        46.9673572
    ],
    [
        84.1137596,
        46.9670501
    ],
    [
        84.1152485,
        46.9669417
    ],
    [
        84.1167797,
        46.9670588
    ],
    [
        84.1184784,
        46.9677127
    ],
    [
        84.1186928,
        46.9679153
    ],
    [
        84.119093,
        46.9681606
    ],
    [
        84.1192039,
        46.96828
    ],
    [
        84.1192573,
        46.9684956
    ],
    [
        84.1194122,
        46.9686775
    ],
    [
        84.1198319,
        46.969025
    ],
    [
        84.1199868,
        46.9690866
    ],
    [
        84.120251,
        46.9690992
    ],
    [
        84.1205038,
        46.9690724
    ],
    [
        84.1209613,
        46.9689124
    ],
    [
        84.1215389,
        46.9686276
    ],
    [
        84.1217662,
        46.9685803
    ],
    [
        84.122151,
        46.9685423
    ],
    [
        84.1223122,
        46.9684373
    ],
    [
        84.1224426,
        46.9682548
    ],
    [
        84.1226547,
        46.9681344
    ],
    [
        84.123148,
        46.9677594
    ],
    [
        84.1246187,
        46.9670148
    ],
    [
        84.1251146,
        46.9670554
    ],
    [
        84.1254227,
        46.9671867
    ],
    [
        84.1264104,
        46.9673945
    ],
    [
        84.1267634,
        46.9676047
    ],
    [
        84.1273799,
        46.9679719
    ],
    [
        84.1274881,
        46.9681474
    ],
    [
        84.1278662,
        46.9685282
    ],
    [
        84.1279271,
        46.9686628
    ],
    [
        84.1280689,
        46.9688849
    ],
    [
        84.1282017,
        46.9689988
    ],
    [
        84.1283815,
        46.9690955
    ],
    [
        84.1285789,
        46.969149
    ],
    [
        84.1286826,
        46.9692281
    ],
    [
        84.1289243,
        46.9695207
    ],
    [
        84.1290033,
        46.9696591
    ],
    [
        84.1291902,
        46.969976
    ],
    [
        84.1297541,
        46.9708046
    ],
    [
        84.1299594,
        46.9709903
    ],
    [
        84.1302657,
        46.9711557
    ],
    [
        84.1304267,
        46.9713496
    ],
    [
        84.1305859,
        46.9714881
    ],
    [
        84.1308938,
        46.9716231
    ],
    [
        84.1312055,
        46.9717052
    ],
    [
        84.1313558,
        46.9716937
    ],
    [
        84.1315952,
        46.9716608
    ],
    [
        84.1322561,
        46.9716604
    ],
    [
        84.132754,
        46.9717244
    ],
    [
        84.1330696,
        46.9718005
    ],
    [
        84.1334745,
        46.9720006
    ],
    [
        84.1339854,
        46.9722325
    ],
    [
        84.1347118,
        46.9723505
    ],
    [
        84.1350738,
        46.9722736
    ],
    [
        84.135666,
        46.9724372
    ],
    [
        84.1363672,
        46.9727179
    ],
    [
        84.1367774,
        46.9729538
    ],
    [
        84.137405,
        46.9730286
    ],
    [
        84.1381902,
        46.9733447
    ],
    [
        84.1386529,
        46.97369
    ],
    [
        84.1389292,
        46.9737236
    ],
    [
        84.1398114,
        46.9736329
    ],
    [
        84.1413587,
        46.9734335
    ],
    [
        84.1420628,
        46.9736205
    ],
    [
        84.142595,
        46.9740893
    ],
    [
        84.1427841,
        46.974416
    ],
    [
        84.1429482,
        46.9745141
    ],
    [
        84.1434114,
        46.9746972
    ],
    [
        84.1436115,
        46.9748183
    ],
    [
        84.1438209,
        46.9750785
    ],
    [
        84.1440394,
        46.9751994
    ],
    [
        84.1445031,
        46.9758564
    ],
    [
        84.1457063,
        46.9762159
    ],
    [
        84.1464326,
        46.9763126
    ],
    [
        84.1466409,
        46.9763898
    ],
    [
        84.146855,
        46.9766248
    ],
    [
        84.147019,
        46.9768905
    ],
    [
        84.1471753,
        46.9774474
    ],
    [
        84.1476618,
        46.9777898
    ],
    [
        84.1480438,
        46.9780185
    ],
    [
        84.1483253,
        46.9783463
    ],
    [
        84.1486804,
        46.978683
    ],
    [
        84.1488848,
        46.9787304
    ],
    [
        84.1495912,
        46.978679
    ],
    [
        84.1498533,
        46.9785929
    ],
    [
        84.1501306,
        46.978428
    ],
    [
        84.1506295,
        46.9783711
    ],
    [
        84.1508551,
        46.9783209
    ],
    [
        84.1511845,
        46.9783045
    ],
    [
        84.1514144,
        46.9784008
    ],
    [
        84.1515449,
        46.9785232
    ],
    [
        84.1516738,
        46.9789311
    ],
    [
        84.1516953,
        46.9793227
    ],
    [
        84.1518768,
        46.9799472
    ],
    [
        84.1521581,
        46.9803763
    ],
    [
        84.1523949,
        46.9804526
    ],
    [
        84.1526736,
        46.9804628
    ],
    [
        84.1531954,
        46.9805682
    ],
    [
        84.1537757,
        46.9807431
    ],
    [
        84.1545216,
        46.9809775
    ],
    [
        84.1553127,
        46.9811185
    ],
    [
        84.1555109,
        46.98093
    ],
    [
        84.1559581,
        46.9808389
    ],
    [
        84.1569095,
        46.9807684
    ],
    [
        84.1571938,
        46.980966
    ],
    [
        84.1576766,
        46.9809587
    ],
    [
        84.1579663,
        46.9808052
    ],
    [
        84.1583456,
        46.980858
    ],
    [
        84.1582345,
        46.9810758
    ],
    [
        84.1582853,
        46.9813884
    ],
    [
        84.1581631,
        46.9819201
    ],
    [
        84.1582109,
        46.982407
    ],
    [
        84.1581481,
        46.9827129
    ],
    [
        84.1585991,
        46.9830567
    ],
    [
        84.1586149,
        46.9831248
    ],
    [
        84.1585936,
        46.9833005
    ],
    [
        84.1584085,
        46.9836094
    ],
    [
        84.158286,
        46.9841836
    ],
    [
        84.1584413,
        46.9846101
    ],
    [
        84.1587485,
        46.9849006
    ],
    [
        84.1590979,
        46.9854948
    ],
    [
        84.1589498,
        46.9860744
    ],
    [
        84.1592756,
        46.9863777
    ],
    [
        84.1597993,
        46.9867974
    ],
    [
        84.1597943,
        46.9869585
    ],
    [
        84.159518,
        46.9873855
    ],
    [
        84.1593494,
        46.9874908
    ],
    [
        84.159471,
        46.9878761
    ],
    [
        84.1592672,
        46.9882778
    ],
    [
        84.159607,
        46.9888169
    ],
    [
        84.1598503,
        46.9889804
    ],
    [
        84.1599736,
        46.9890159
    ],
    [
        84.1604315,
        46.9888953
    ],
    [
        84.1610305,
        46.9890415
    ],
    [
        84.1620011,
        46.9896609
    ],
    [
        84.1633468,
        46.989671
    ],
    [
        84.1635548,
        46.989709
    ],
    [
        84.1638511,
        46.9898945
    ],
    [
        84.1640232,
        46.9901224
    ],
    [
        84.1642228,
        46.9902705
    ],
    [
        84.1646776,
        46.9905481
    ],
    [
        84.1646626,
        46.9907725
    ],
    [
        84.1648132,
        46.9909495
    ],
    [
        84.165351,
        46.9916618
    ],
    [
        84.1653226,
        46.9918776
    ],
    [
        84.1653091,
        46.9921292
    ],
    [
        84.1651419,
        46.9925227
    ],
    [
        84.1652305,
        46.9930591
    ],
    [
        84.1653942,
        46.9934269
    ],
    [
        84.1654391,
        46.9938869
    ],
    [
        84.1657154,
        46.9942883
    ],
    [
        84.1660196,
        46.9942571
    ],
    [
        84.1664166,
        46.9939056
    ],
    [
        84.1668527,
        46.9938192
    ],
    [
        84.1673263,
        46.9938543
    ],
    [
        84.167352,
        46.9941926
    ],
    [
        84.1676631,
        46.9944724
    ],
    [
        84.1680999,
        46.9945926
    ],
    [
        84.1684787,
        46.9947503
    ],
    [
        84.1690432,
        46.9949039
    ],
    [
        84.1694937,
        46.9948084
    ],
    [
        84.1696832,
        46.9950621
    ],
    [
        84.1701475,
        46.9949035
    ],
    [
        84.1707544,
        46.9945636
    ],
    [
        84.1720041,
        46.9941605
    ],
    [
        84.1726924,
        46.9941857
    ],
    [
        84.1733617,
        46.9939474
    ],
    [
        84.1737119,
        46.9938799
    ],
    [
        84.1742845,
        46.9937846
    ],
    [
        84.1747586,
        46.9936252
    ],
    [
        84.1751209,
        46.9934022
    ],
    [
        84.1753513,
        46.9932087
    ],
    [
        84.1754065,
        46.9929795
    ],
    [
        84.1755782,
        46.9927496
    ],
    [
        84.1759853,
        46.9926167
    ],
    [
        84.1767698,
        46.9928252
    ],
    [
        84.177402,
        46.9931882
    ],
    [
        84.1772793,
        46.9934652
    ],
    [
        84.177375,
        46.9942456
    ],
    [
        84.1774186,
        46.9947418
    ],
    [
        84.1773809,
        46.9951495
    ],
    [
        84.1773277,
        46.9955053
    ],
    [
        84.1776677,
        46.995885
    ],
    [
        84.1777564,
        46.996405
    ],
    [
        84.1774421,
        46.9968987
    ],
    [
        84.1774362,
        46.997093
    ],
    [
        84.1778054,
        46.9974572
    ],
    [
        84.1780135,
        46.9975329
    ],
    [
        84.1789459,
        46.9973222
    ],
    [
        84.1794493,
        46.9973563
    ],
    [
        84.179997,
        46.9975554
    ],
    [
        84.1800578,
        46.9975907
    ],
    [
        84.180087,
        46.9976306
    ],
    [
        84.1801103,
        46.9976573
    ],
    [
        84.180137,
        46.9976817
    ],
    [
        84.1801636,
        46.9976874
    ],
    [
        84.1804581,
        46.9978683
    ],
    [
        84.1806733,
        46.9979346
    ],
    [
        84.1808692,
        46.9979174
    ],
    [
        84.1811871,
        46.9978174
    ],
    [
        84.1817628,
        46.9977694
    ],
    [
        84.181904,
        46.9977117
    ],
    [
        84.182188,
        46.9976617
    ],
    [
        84.1825117,
        46.9976066
    ],
    [
        84.1829212,
        46.9976256
    ],
    [
        84.1832353,
        46.9976424
    ],
    [
        84.1836878,
        46.9976568
    ],
    [
        84.1838897,
        46.9976185
    ],
    [
        84.1842538,
        46.9974222
    ],
    [
        84.1848741,
        46.9973695
    ],
    [
        84.1855191,
        46.9973743
    ],
    [
        84.1858002,
        46.9974459
    ],
    [
        84.1859876,
        46.9975024
    ],
    [
        84.1863497,
        46.9974557
    ],
    [
        84.1864808,
        46.9974624
    ],
    [
        84.1866646,
        46.9973833
    ],
    [
        84.1869505,
        46.9973698
    ],
    [
        84.1869672,
        46.9976955
    ],
    [
        84.1871131,
        46.9981639
    ],
    [
        84.1871868,
        46.9982742
    ],
    [
        84.1872948,
        46.9984672
    ],
    [
        84.1873642,
        46.9986096
    ],
    [
        84.1874139,
        46.9987359
    ],
    [
        84.1874751,
        46.9988991
    ],
    [
        84.1876335,
        46.9993181
    ],
    [
        84.1878458,
        46.999574
    ],
    [
        84.1880297,
        46.9997764
    ],
    [
        84.1882279,
        46.9998642
    ],
    [
        84.1884767,
        46.9999143
    ],
    [
        84.1889941,
        46.9998462
    ],
    [
        84.1892501,
        46.9996471
    ],
    [
        84.189643,
        46.9994942
    ],
    [
        84.1903741,
        46.9992709
    ],
    [
        84.1908162,
        46.9991055
    ],
    [
        84.1912853,
        46.9988646
    ],
    [
        84.19159,
        46.9988025
    ],
    [
        84.1919771,
        46.9987382
    ],
    [
        84.1923965,
        46.9986485
    ],
    [
        84.1925921,
        46.9986576
    ],
    [
        84.1928678,
        46.9988244
    ],
    [
        84.1932152,
        46.999024
    ],
    [
        84.1935294,
        46.9990106
    ],
    [
        84.1940791,
        46.9989672
    ],
    [
        84.1944007,
        46.9989922
    ],
    [
        84.19472,
        46.999014
    ],
    [
        84.1949114,
        46.9990078
    ],
    [
        84.1952265,
        46.9989678
    ],
    [
        84.1956378,
        46.9989668
    ],
    [
        84.1957392,
        46.9989947
    ],
    [
        84.1958142,
        46.9990106
    ],
    [
        84.1958796,
        46.9990208
    ],
    [
        84.195926,
        46.9990448
    ],
    [
        84.1961563,
        46.9992024
    ],
    [
        84.1968484,
        46.9995294
    ],
    [
        84.1969407,
        46.9996341
    ],
    [
        84.1970484,
        46.9998806
    ],
    [
        84.1972539,
        47.0000359
    ],
    [
        84.1972955,
        47.0002276
    ],
    [
        84.1972609,
        47.0003573
    ],
    [
        84.1973069,
        47.0005713
    ],
    [
        84.1974418,
        47.0007969
    ],
    [
        84.1978649,
        47.0012244
    ],
    [
        84.1981861,
        47.0013018
    ],
    [
        84.1990163,
        47.001301
    ],
    [
        84.1996146,
        47.0014045
    ],
    [
        84.1999142,
        47.0015391
    ],
    [
        84.2007444,
        47.0020532
    ],
    [
        84.2011445,
        47.0022784
    ],
    [
        84.2015635,
        47.0020427
    ],
    [
        84.2021459,
        47.0017772
    ],
    [
        84.202662,
        47.0017421
    ],
    [
        84.2028999,
        47.0016968
    ],
    [
        84.2033749,
        47.0013453
    ],
    [
        84.2039893,
        47.0010469
    ],
    [
        84.2040978,
        47.0009985
    ],
    [
        84.2041986,
        47.0009516
    ],
    [
        84.2042787,
        47.0009034
    ],
    [
        84.2044545,
        47.0007906
    ],
    [
        84.2048034,
        47.0007612
    ],
    [
        84.2050503,
        47.000794
    ],
    [
        84.2053424,
        47.0008134
    ],
    [
        84.2058029,
        47.0005941
    ],
    [
        84.2060455,
        47.0003958
    ],
    [
        84.2062854,
        47.0002087
    ],
    [
        84.2065662,
        47.0001065
    ],
    [
        84.2066832,
        47.0001978
    ],
    [
        84.2068589,
        47.0003293
    ],
    [
        84.2070365,
        47.000642
    ],
    [
        84.2074975,
        47.0007415
    ],
    [
        84.2077198,
        47.0009268
    ],
    [
        84.2079397,
        47.0009586
    ],
    [
        84.2085789,
        47.0012303
    ],
    [
        84.208959,
        47.0013853
    ],
    [
        84.2099488,
        47.0014136
    ],
    [
        84.2103487,
        47.0011507
    ],
    [
        84.2105507,
        47.0008364
    ],
    [
        84.2108815,
        47.0005103
    ],
    [
        84.2113462,
        47.0004204
    ],
    [
        84.2118537,
        47.0004999
    ],
    [
        84.2127215,
        47.0007738
    ],
    [
        84.2136852,
        47.0008531
    ],
    [
        84.2142274,
        47.0008197
    ],
    [
        84.2146364,
        47.0006939
    ],
    [
        84.2150529,
        47.0004104
    ],
    [
        84.2156207,
        47.0003132
    ],
    [
        84.2158784,
        47.0000528
    ],
    [
        84.2162747,
        46.9999086
    ],
    [
        84.2164525,
        46.9997667
    ],
    [
        84.2166324,
        46.9995228
    ],
    [
        84.2170969,
        46.9993354
    ],
    [
        84.2179345,
        46.9990537
    ],
    [
        84.2182429,
        46.9989892
    ],
    [
        84.2187253,
        46.9987841
    ],
    [
        84.2191104,
        46.9986704
    ],
    [
        84.2194397,
        46.9985324
    ],
    [
        84.2200508,
        46.9984742
    ],
    [
        84.2206615,
        46.9985537
    ],
    [
        84.2213519,
        46.9982862
    ],
    [
        84.2215586,
        46.9981318
    ],
    [
        84.2219284,
        46.9980285
    ],
    [
        84.2223588,
        46.9978902
    ],
    [
        84.2226439,
        46.9976835
    ],
    [
        84.2228548,
        46.997623
    ],
    [
        84.2229641,
        46.9976064
    ],
    [
        84.2232719,
        46.997716
    ],
    [
        84.2236589,
        46.9978686
    ],
    [
        84.2239627,
        46.9979004
    ],
    [
        84.2242114,
        46.9978126
    ],
    [
        84.2246268,
        46.9975071
    ],
    [
        84.2247428,
        46.9973269
    ],
    [
        84.224808,
        46.9972344
    ],
    [
        84.2249981,
        46.9971921
    ],
    [
        84.2252679,
        46.9971937
    ],
    [
        84.2258084,
        46.9972057
    ],
    [
        84.2262044,
        46.9972031
    ],
    [
        84.2264559,
        46.9976855
    ],
    [
        84.2269267,
        46.9982818
    ],
    [
        84.2271763,
        46.9987674
    ],
    [
        84.2273619,
        46.9994669
    ],
    [
        84.227598,
        46.9996314
    ],
    [
        84.2278007,
        46.9996536
    ],
    [
        84.2282721,
        46.9996453
    ],
    [
        84.2284269,
        46.9997953
    ],
    [
        84.2286324,
        46.9999083
    ],
    [
        84.2289386,
        47.0001624
    ],
    [
        84.2292622,
        47.0004413
    ],
    [
        84.2295918,
        47.000648
    ],
    [
        84.2297922,
        47.0007425
    ],
    [
        84.2299215,
        47.0007434
    ],
    [
        84.2300334,
        47.0006747
    ],
    [
        84.2302868,
        47.0006228
    ],
    [
        84.2305943,
        47.0006072
    ],
    [
        84.230796,
        47.0005874
    ],
    [
        84.2310498,
        47.0006104
    ],
    [
        84.2313047,
        47.0006882
    ],
    [
        84.231851,
        47.0008187
    ],
    [
        84.2320286,
        47.0008705
    ],
    [
        84.2325636,
        47.0011836
    ],
    [
        84.2336797,
        47.0014314
    ],
    [
        84.2361258,
        47.0005828
    ],
    [
        84.238336,
        47.0003779
    ],
    [
        84.2425846,
        46.9992364
    ],
    [
        84.2473911,
        46.9987974
    ],
    [
        84.2490648,
        46.9997925
    ],
    [
        84.2500627,
        47.000129
    ],
    [
        84.2520153,
        46.9997339
    ],
    [
        84.252026,
        46.9989291
    ],
    [
        84.2524123,
        46.9989875
    ],
    [
        84.2530346,
        46.9997778
    ],
    [
        84.2562531,
        47.0010218
    ],
    [
        84.2585492,
        47.0029972
    ],
    [
        84.2605448,
        47.0029241
    ],
    [
        84.2636775,
        47.0017242
    ],
    [
        84.2664884,
        47.0020022
    ],
    [
        84.2671752,
        47.0009631
    ],
    [
        84.2682695,
        47.0007071
    ],
    [
        84.2689991,
        47.000729
    ],
    [
        84.2703937,
        47.0011535
    ],
    [
        84.2714453,
        47.0008388
    ],
    [
        84.2722392,
        47.0007071
    ],
    [
        84.2728185,
        47.0012558
    ],
    [
        84.274578,
        47.0016657
    ],
    [
        84.2778181,
        47.001073
    ],
    [
        84.2807998,
        47.0002441
    ],
    [
        84.2836547,
        46.9988266
    ],
    [
        84.2845559,
        46.9999827
    ],
    [
        84.2845344,
        47.0010217
    ],
    [
        84.2863582,
        47.002251
    ],
    [
        84.2889118,
        47.0017534
    ],
    [
        84.2897916,
        47.0003485
    ],
    [
        84.2909502,
        46.9990169
    ],
    [
        84.2940402,
        46.9979192
    ],
    [
        84.2942548,
        46.9967924
    ],
    [
        84.2952417,
        46.9969681
    ],
    [
        84.2968726,
        46.9964411
    ],
    [
        84.297924,
        46.9956947
    ],
    [
        84.2991042,
        46.9954313
    ],
    [
        84.3002629,
        46.9955191
    ],
    [
        84.3011427,
        46.9959435
    ],
    [
        84.302237,
        46.9961045
    ],
    [
        84.3035029,
        46.9963534
    ],
    [
        84.3041467,
        46.9976119
    ],
    [
        84.3056487,
        46.998212
    ],
    [
        84.3074083,
        46.9981827
    ],
    [
        84.3085027,
        46.9973924
    ],
    [
        84.3100691,
        46.9969533
    ],
    [
        84.3111848,
        46.9970412
    ],
    [
        84.3125796,
        46.9987827
    ],
    [
        84.313674,
        46.9998802
    ],
    [
        84.3147253,
        46.9992803
    ],
    [
        84.3155621,
        46.9981974
    ],
    [
        84.3170428,
        46.997729
    ],
    [
        84.3191671,
        46.9966021
    ],
    [
        84.3198967,
        46.9968363
    ],
    [
        84.3200683,
        46.9976997
    ],
    [
        84.3219995,
        46.9982119
    ],
    [
        84.3222356,
        46.9979046
    ],
    [
        84.3222999,
        46.9970704
    ],
    [
        84.3229222,
        46.9969241
    ],
    [
        84.3246602,
        46.9997194
    ],
    [
        84.3261031,
        47.0004501
    ],
    [
        84.3282865,
        47.0017974
    ],
    [
        84.3294883,
        47.0028948
    ],
    [
        84.330883,
        47.0028655
    ],
    [
        84.3327926,
        47.0020169
    ],
    [
        84.3372129,
        47.0006998
    ],
    [
        84.339702,
        46.9994706
    ],
    [
        84.3412685,
        46.9992363
    ],
    [
        84.3451952,
        46.9996901
    ],
    [
        84.3468046,
        46.9994998
    ],
    [
        84.3485211,
        46.9978315
    ],
    [
        84.3500447,
        46.99789
    ],
    [
        84.3526196,
        46.9998071
    ],
    [
        84.3541859,
        47.0004218
    ],
    [
        84.3551516,
        47.0004949
    ],
    [
        84.3572116,
        46.999529
    ],
    [
        84.3581341,
        46.9993389
    ],
    [
        84.3602586,
        47.0000266
    ],
    [
        84.3619323,
        46.9994559
    ],
    [
        84.3635629,
        46.9991633
    ],
    [
        84.3642497,
        46.9983436
    ],
    [
        84.3651724,
        46.9979631
    ],
    [
        84.3666743,
        46.997934
    ],
    [
        84.3694209,
        46.9984901
    ],
    [
        84.3709874,
        46.9980948
    ],
    [
        84.3720817,
        46.9973631
    ],
    [
        84.3733477,
        46.9973777
    ],
    [
        84.3791628,
        46.9959874
    ],
    [
        84.3826604,
        46.9964997
    ],
    [
        84.3847418,
        46.9974363
    ],
    [
        84.385321,
        46.9982998
    ],
    [
        84.3864155,
        46.9991193
    ],
    [
        84.3863295,
        46.9996608
    ],
    [
        84.3875655,
        47.0001488
    ],
    [
        84.3910289,
        47.0006412
    ],
    [
        84.3918443,
        47.0027777
    ],
    [
        84.3942904,
        47.002368
    ],
    [
        84.3954491,
        47.0013875
    ],
    [
        84.3967581,
        47.0013583
    ],
    [
        84.3980025,
        47.001095
    ],
    [
        84.3993329,
        47.0029388
    ],
    [
        84.3999337,
        47.0040655
    ],
    [
        84.4009423,
        47.0056166
    ],
    [
        84.373455,
        47.0204081
    ],
    [
        84.3700647,
        47.0315831
    ],
    [
        84.3650007,
        47.0381934
    ],
    [
        84.3632841,
        47.0525226
    ],
    [
        84.3618249,
        47.0762013
    ],
    [
        84.3627691,
        47.0989638
    ],
    [
        84.3667173,
        47.117234
    ],
    [
        84.3707809,
        47.1180407
    ],
    [
        84.3773603,
        47.1208406
    ],
    [
        84.3726396,
        47.1324033
    ],
    [
        84.3601512,
        47.1390886
    ],
    [
        84.3610096,
        47.1450143
    ],
    [
        84.3633699,
        47.1479622
    ],
    [
        84.3598508,
        47.1564842
    ],
    [
        84.3530702,
        47.156776
    ],
    [
        84.348092,
        47.1687102
    ],
    [
        84.339509,
        47.1719486
    ],
    [
        84.3376207,
        47.1701398
    ],
    [
        84.3310117,
        47.1723862
    ],
    [
        84.3311834,
        47.1738448
    ],
    [
        84.3230295,
        47.1811667
    ],
    [
        84.3248319,
        47.1857169
    ],
    [
        84.3181371,
        47.189275
    ],
    [
        84.3020439,
        47.2041759
    ],
    [
        84.2988681,
        47.2091905
    ],
    [
        84.29286,
        47.2101234
    ],
    [
        84.2803716,
        47.2181399
    ],
    [
        84.2804145,
        47.2199471
    ],
    [
        84.2783117,
        47.2210255
    ],
    [
        84.2753934,
        47.2202968
    ],
    [
        84.2738056,
        47.2204426
    ],
    [
        84.2722177,
        47.2217542
    ],
    [
        84.2726469,
        47.2223954
    ],
    [
        84.2714881,
        47.2234155
    ],
    [
        84.2699003,
        47.2225703
    ],
    [
        84.2673683,
        47.2231823
    ],
    [
        84.2683982,
        47.2239693
    ],
    [
        84.2663812,
        47.2250185
    ],
    [
        84.2665098,
        47.227729
    ],
    [
        84.3185234,
        47.3185195
    ],
    [
        84.2995548,
        47.336118
    ],
    [
        84.2880106,
        47.3281776
    ],
    [
        84.2650938,
        47.3790279
    ],
    [
        84.2941474,
        47.4035488
    ],
    [
        84.2722606,
        47.4217868
    ],
    [
        84.2800712,
        47.4334871
    ],
    [
        84.2805003,
        47.4844679
    ],
    [
        84.277668,
        47.5242433
    ],
    [
        84.2752646,
        47.5292276
    ],
    [
        84.2623471,
        47.5664209
    ],
    [
        84.2565965,
        47.5876128
    ],
    [
        84.288482,
        47.6501255
    ],
    [
        84.3020867,
        47.6585083
    ],
    [
        84.3148326,
        47.6639134
    ],
    [
        84.3429851,
        47.6972563
    ],
    [
        84.3331145,
        47.7059785
    ],
    [
        84.3159483,
        47.7276624
    ],
    [
        84.2358684,
        47.7573871
    ],
    [
        84.1847989,
        47.8484182
    ],
    [
        83.683891,
        48.0833546
    ],
    [
        83.4556353,
        48.2150279
    ],
    [
        83.4299739,
        48.234614
    ],
    [
        83.3724948,
        48.011977
    ],
    [
        83.1170627,
        47.9163439
    ],
    [
        83.0264255,
        47.5932006
    ],
    [
        83.3175632,
        47.3946326
    ],
    [
        83.197921,
        47.2173806
    ]
]