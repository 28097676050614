import React, { ReactNode, useMemo, useState } from "react";
import { ChevronOutline } from "../Icons";

type CardCarouselProps = {
  children?: ReactNode;
  staticContent?: ReactNode;
  title?: ReactNode;
  titles?: Partial<Record<number, ReactNode>>;
};

const CardCarousel: React.FC<CardCarouselProps> = ({
  children,
  staticContent,
  title,
  titles,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const currentTitle = useMemo(() => {
    return titles?.[currentSlide] || title;
  }, [title, titles, currentSlide]);

  const currentComponent = useMemo(
    () =>
      React.Children.map(children, (child, index) =>
        currentSlide === index ? child : null
      ),
    [children, currentSlide]
  );

  const handleClickPrev = () => {
    if (currentSlide === 0) {
      return setCurrentSlide(
        React.Children.count(children) ? React.Children.count(children) - 1 : 0
      );
    }

    setCurrentSlide(currentSlide - 1);
  };

  const handleClickNext = () => {
    if (currentSlide + 1 === React.Children.count(children)) {
      return setCurrentSlide(0);
    }

    setCurrentSlide(currentSlide + 1);
  };

  return (
    <div className="card-carousel">
      <div className="card-carousel__top">
        <button
          type="button"
          className="card-carousel__top-btn --prev-btn"
          onClick={handleClickPrev}
        >
          <ChevronOutline />
        </button>

        <div className="card-carousel__top-title">{currentTitle}</div>

        <button
          type="button"
          className="card-carousel__top-btn --next-btn"
          onClick={handleClickNext}
        >
          <ChevronOutline />
        </button>
      </div>

      {staticContent && (
        <div className="card-carousel__static-content">{staticContent}</div>
      )}

      <div className="card-carousel__content">{currentComponent}</div>
    </div>
  );
};

export default CardCarousel;
