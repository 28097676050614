import React, {useContext, useEffect, useState} from 'react';
import Map from "../Map/Map";
import {useParams} from "react-router-dom";
import {AppDataContext} from "../../contexts/AppDataContext";
import SideInfo from "../SideInfo/SideInfo";
import Filter from "../UI/Filter";
import {firesFilterOptions} from "./FiresRegionPage";
import {IFilterOption} from "../../types";
import {getFires, getFireStations, getFlights} from "../../services/fires";
import {IFire, IFiresStation, IFlight} from "../../types/fires";
import dayjs from "dayjs";
import {HelicopterBlack} from "../Icons";

const FiresDistrictPage = () => {
    const params = useParams();
    const districtId = params.id;
    const {date} = useContext(AppDataContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilterOption | null>(firesFilterOptions[3]);
    const [helicopterFilter, setHelicopterFilter] = useState<boolean>(false);
    const [fires, setFires] = useState<IFire[]>([]);
    const [allFires, setAllFires] = useState<IFire[]>([]);
    const [fireStations, setFireStations] = useState<IFiresStation[]>([]);
    const [flights, setFlights] = useState<IFlight[]>([]);

    useEffect(() => {
        loadAllFires();
        if (filter) handleFilterChange();
        if (helicopterFilter) fetchFlights();
    }, [date]);

    useEffect(() => {
        if (filter) handleFilterChange();
    }, [filter]);

    useEffect(() => {
        if (helicopterFilter) fetchFlights();
    }, [helicopterFilter]);

    const handleFilterChange = () => {
        if (filter?.value === "active") {
            fetchFires("active");
        } else if (filter?.value === "inactive") {
            fetchFires("inactive");
        } else if (filter?.value === "all") {
            fetchFires();
        } else if (filter?.value === "stations") {
            fetchFireStations();
        }
    };

    const fetchFires = async (status?: string): Promise<void> => {
        const response = await getFires(dayjs(date).format("YYYY-MM-DD"), status, districtId);

        if (!response.isError) {
            setFires(response.data);
            setFireStations([]);
            setFlights([]);
        } else {
            setFires([]);
        }
    };

    const loadAllFires = async (): Promise<void> => {
        setLoading(true);
        const response = await getFires(dayjs(date).format("YYYY-MM-DD"), undefined, districtId);

        if (!response.isError) {
            setAllFires(response.data);
        } else {
            setAllFires([]);
        }
        setLoading(false);
    };

    const fetchFireStations = async (): Promise<void> => {
        const response = await getFireStations(districtId);

        if (!response.isError) {
            setFireStations(response.data);
            setFires([]);
            setFlights([]);
        } else {
            setFires([]);
        }
    };

    const fetchFlights = async (): Promise<void> => {
        const response = await getFlights(dayjs(date).format("YYYY-MM-DD"), districtId);

        if (!response.isError) {
            setFlights(response.data);
            setFireStations([]);
            setFires([]);
        } else {
            setFlights([]);
        }
    };

    const handleChangeFlightsFilter = () => {
        setHelicopterFilter(true);
        setFilter(null);
        setFireStations([]);
        setFires([]);
    };

    return (
        <div>
            {loading ? (
                <div className="page-loader">
                    Загрузка...
                </div>
            ) : (
                <>
                    <Map districtId={Number(districtId)} fires={fires} fireStations={fireStations} helicopter={helicopterFilter} flights={flights} />
                    <SideInfo allFires={allFires} />
                    <Filter
                        options={firesFilterOptions}
                        onSelect={(option) => {
                            setFilter(option);
                            setHelicopterFilter(false);
                        }}
                        selectedOpt={filter}
                        isFire
                    />
                    <div className="filter-wrapper helicopter">
                        <div
                            className={`filter ${helicopterFilter ? "fires" : ""}`}
                            onClick={() => handleChangeFlightsFilter()}
                        >
                            <HelicopterBlack />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default FiresDistrictPage;