import React, {useEffect, useRef, useState} from 'react';
import {divIcon, DivIconOptions} from "leaflet";
import ReactDOMServer from "react-dom/server";
import {Marker, Popup} from "react-leaflet";
import {IPowerPlant} from "../../../types/power-generation";
import {addSpaces, isMobileDevice} from "../../../utils";
import Modal from "../../UI/Modal";
import PowerGenerationModal from "../../HCSPages/PowerGenerationPage/modals/PowerGenerationModal";

interface IPowerPlantMarker {
    point: IPowerPlant;
}

const PowerPlantMarker: React.FC<IPowerPlantMarker> = ({point}) => {
    const markerRef = useRef<any>(null);
    const isMobile = isMobileDevice();
    const [isChartModal, setIsChartModal] = useState<boolean>(false);
    const [chosenLocality, setChosenLocality] = useState<IPowerPlant | null>(null);

    const getColor = () => {
        if (point.name.includes("ТЭЦ")) {
            return "#1F5673"
        } else {
            return "#E9DB46"
        }
    };

    const createMarker = () => {
        const options: DivIconOptions = {
            className: "marker-wrapper",
            html: ReactDOMServer.renderToString(
                <>
                    <div className={`power-plant-marker ${point.last_day_total_actual_generated !== 0 ? "pulse" : ""}`} style={{background: getColor()}}/>
                    <div className="power-plant-marker-label">{point.name}</div>
                    <span className="power-plant-marker-label" style={{marginTop: "0px"}}>
                        {point.last_day_total_actual_generated !== 0
                            ? addSpaces(point.last_day_total_actual_generated.toString() + " мВтч")
                            : null
                        }
                    </span>
                </>
            )}
        return divIcon(options);
    };

    useEffect(() => {
        const marker = markerRef.current;
        if (marker) {
            marker.on('mouseover', function () {
                marker.openPopup();
            });
            marker.on('mouseout', function () {
                marker.closePopup();
            });
        }
    }, []);

    const showModal = () => {
        if (!isMobile) {
            setIsChartModal(true);
            setChosenLocality(point);
        }
    };

    return (
        <>
            {point.latitude && point.longitude &&
                <Marker
                    position={[point.latitude, point.longitude]}
                    icon={createMarker()}
                    ref={markerRef}
                    eventHandlers={{
                        click: showModal,
                    }}
                >
                    <Popup>
                        <span style={{ fontWeight: 600, fontSize: "14px" }}>{point.name}</span>

                        {isMobile &&
                            <button
                                onClick={() => {
                                    setIsChartModal(true);
                                    setChosenLocality(point);
                                }}
                                className="popup-btn">
                                Посмотреть
                            </button>
                        }
                    </Popup>
                </Marker>
            }

            {chosenLocality &&
                <Modal
                    isOpen={isChartModal}
                    onClose={() => {
                        setIsChartModal(false);
                        setChosenLocality(null);
                    }}
                    title={chosenLocality.name}
                >
                    <PowerGenerationModal id={chosenLocality.id} />
                </Modal>
            }
        </>
    );
};

export default PowerPlantMarker;