import React, {useContext, useEffect, useState} from 'react';
import Map from "../Map/Map";
import TendersSideInfo from "./TendersSideInfo";
import TendersFilter from "./TendersFilter";
import {getTendersDistrictInfo} from "../../services/tenders";
import {AppDataContext} from "../../contexts/AppDataContext";
import dayjs from "dayjs";
import {ITenderCheckbox, ITenderDistrictInfo} from "../../types/tenders";
import {TendersContext} from "../../contexts/TendersContext";
import {getDistrictData} from "../../utils";
import Button from "../UI/Button";

const TendersRegionPage = () => {
    const {date, updateDistrictsData} = useContext(AppDataContext);
    const {
        customer,
        supplier,
        subject,
        status,
        budgetType,
        type,
        tradeMethod,
        organizator,
        feature,
        setFeature
    } = useContext(TendersContext);
    const [tenderInfo, setTenderInfo] = useState<ITenderDistrictInfo[]>([]);

    useEffect(() => {
        loadTenderInfo();
    }, [date, customer, supplier, subject, status, budgetType, type, tradeMethod, organizator, feature]);

    const loadTenderInfo = async (): Promise<void> => {
        const response = await getTendersDistrictInfo(
            dayjs(date).format("YYYY-MM-DD"), status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids, organizator.selectedIds, feature.ids
        );

        if (!response.isError) {
            setTenderInfo(response.data);
            updateDistrictsData(getDistrictData(response.data));
        } else {
            setTenderInfo([]);
        }
    };

    return (
        <>
            <Map tenderInfo={tenderInfo} />
            <TendersSideInfo />
            <TendersFilter />
            <div className="tenders-btn">
                <Button
                    text="Строительно-монтажные работы"
                    onClick={() => {
                        const newFeatureState: ITenderCheckbox = {
                            ...feature,
                            value: feature.value.map((item, index) => index === 6
                                ? { ...item, checked: !item.checked }
                                : item
                            ),
                            ids: feature.ids.includes(9)
                                ? feature.ids.filter((item: number) => item !== 9)
                                : [...feature.ids, 9]
                        }; 
                        setFeature(newFeatureState);                        
                    }}
                    filterBtn
                    active={feature.ids.includes(9)}
                />
            </div>
        </>
    );
};

export default TendersRegionPage;