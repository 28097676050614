import React from 'react';

const WaterStatisticCard = () => {
    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-title" style={{marginBottom: "12px"}}>Статистика водоснабжения</div>
            
            <div className="side-info-big-card-items" style={{gap: "8px"}}>
                <div className="side-info-big-card-item">
                    <div className="side-info-water-statistic-dots-wrapper">
                        <div className="side-info-water-statistic-dots">
                            <div className="dot" />
                            <div className="dot" style={{background: "#E0C225", left: "4px"}} />
                            <div className="dot" style={{background: "#B74747", left: "initial", right: 0}} />
                        </div>
                        Износ сетей
                    </div>
                    <div>89%</div>
                </div>
                <div className="side-info-big-card-item">
                    <div>КМ сетей</div>
                    <div>37</div>
                </div>
                <div className="side-info-big-card-item">
                    <div>Обслуживающие организации</div>
                    <div>2</div>
                </div>
                <div className="side-info-big-card-item">
                    <div>Количество абонентов</div>
                    <div>345</div>
                </div>
                <div className="side-info-big-card-item">
                    <div>Уровень тарифов</div>
                    <div>9</div>
                </div>
                <ul>
                    <li>
                        <div className="data-list-item">
                            <div>усредненный, тенге/м3</div>
                            <div>9</div>
                        </div>
                    </li>
                    <li>
                        <div className="data-list-item">
                            <div>физическим лицам/населению, тенге/м3</div>
                            <div>9</div>
                        </div>
                    </li>
                    <li>
                        <div className="data-list-item">
                            <div>юридическим лицам (в том числе бюджетные организации), тенге/м3</div>
                            <div>9</div>
                        </div>
                    </li>
                    <li>
                        <div className="data-list-item">
                            <div>бюджетным организациям, тенге/м3</div>
                            <div>9</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default WaterStatisticCard;