import React, {useContext, useEffect, useState} from 'react';
import Button from "../../UI/Button";
import LineChart from "../../Charts/LineChart";
import BarChart from "../../Charts/BarChart";
import Modal from "../../UI/Modal";
import PowerGenerationStatisticModal from "./modals/PowerGenerationStatisticModal";
import PowerGenerationModal from "./modals/PowerGenerationModal";
import PowerGenerationLateralInflowModal from "./modals/PowerGenerationLateralInflowModal";
import {
    IPowerGenerationLateralInflow,
    IPowerGenerationStatisticData,
    IPowerPlantSummaryPlants,
} from "../../../types/power-generation";
import {getLateralInflow, getPowerGenerationStatistic, getPowerPlantSummary} from "../../../services/hcs";
import dayjs from "dayjs";
import {AppDataContext} from "../../../contexts/AppDataContext";
import {ILineChartData} from "../../../types/chart";
import { addSpaces } from '../../../utils';

export const colorfy = (name: string):string => {
    if (name === 'Риддер ТЭЦ') {
        return '#08293E';
    } 
    if (name === 'БГЭС') {
        return '#1F5673';
    } 
    if (name === 'УК ГЭС') {
        return '#BCD0CF';
    } 
    if (name === 'УК ТЭЦ') {
        return '#E0C78D';
    } 
    if (name === 'СТЭЦ') {
        return '#E9DB46';
    } 
    if (name === 'ЛенГЭС') {
        return '#F2730E';
    }
    return "";
};

const PowerGenerationSideInfo = () => {
    const {dateRange} = useContext(AppDataContext);
    const [isStatisticModal, setIsStatisticModal] = useState<boolean>(false);
    const [powerGenerationModal, setPowerGenerationModal] = useState<boolean>(false);
    const [lateralInflowModal, setLateralInflowModal] = useState<boolean>(false);
    const [statistic, setStatistic] = useState<IPowerGenerationStatisticData | null>();
    const [lateralInflow, setLateralInflow] = useState<IPowerGenerationLateralInflow[]>([]);
    const [lateralInflowChartData, setLateralInflowChartData] = useState<ILineChartData>();
    const [lateralInflowChartDataShort, setLateralInflowChartDataShort] = useState<ILineChartData>();
    const [powerGenerationChartData, setPowerGenerationChartData] = useState<any>();
    const [powerPlantTotalGenerated, setPowerPlantTotalGenerated] = useState<number>();

    useEffect(() => {
        loadStatistic();
        loadPowerPlantSummary();
        loadLateralInflow();
    }, [dateRange]);

    const loadStatistic = async (): Promise<void> => {
        const response = await getPowerGenerationStatistic(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"));

        if (!response.isError) {
            setStatistic(response.data);
        } else {
            setStatistic(null);
        }
    };

    const loadLateralInflow = async (): Promise<void> => {
        const response = await getLateralInflow(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"));

        if (!response.isError) {
            setLateralInflow(response.data);
            setLateralInflowChartData(getLateralInflowChartData(response.data));
            setLateralInflowChartDataShort(getLateralInflowChartData(response.data, true));
        } else {
            setLateralInflow([]);
        }
    };

    const loadPowerPlantSummary = async (): Promise<void> => {
        const response = await getPowerPlantSummary(dayjs(dateRange.from).format("YYYY-MM-DD"), dayjs(dateRange.to).format("YYYY-MM-DD"));

        if (!response.isError) {
            setPowerGenerationChartData(getPowerPlantChartsData(response.data.power_plants));
            setPowerPlantTotalGenerated(response.data.total_actual_generated);
        }
    };

    const getLateralInflowChartData = (data: IPowerGenerationLateralInflow[], short?: boolean) => {
        let days: any = [];
        let ulbaData: number[] = [];
        let ubaData: number[] = [];

        data.forEach((river) => {
            river.daily_averages.forEach((item) => {
                const date = dayjs(item.date).format("DD.MM");
                if (!days.includes(date)) days.push(date);
                if (river.river === "р. Ульба") ulbaData.push(item.average_flow);
                if (river.river === "р. Уба") ubaData.push(item.average_flow);
            });
        });


        return {
            labels: short ? getLastFourElements(days) : days,
            datasets: [
                {
                    label: 'Река Уба',
                    data: short ? getLastFourElements(ubaData) : ubaData,
                    borderColor: '#FFB933',
                    backgroundColor: '#FFB933',

                },
                {
                    label: 'Река Ульба',
                    data: short ? getLastFourElements(ulbaData) : ulbaData,
                    borderColor: '#FF3D63',
                    backgroundColor: '#FF3D63'
                }
            ]
        }
    };

    const getPowerPlantChartsData = (data: IPowerPlantSummaryPlants[]) => {
        let names: any[] = [];
        let chartData: any[] = [];
        let colors: string[] = [];
        let date = data[0].daily_totals[0].date;

        data.forEach((plant) => {
            if (plant.daily_totals[0].total_actual_generated) {
                chartData.push(plant.daily_totals[0].total_actual_generated);
                names.push(plant.power_plant);
                colors.push(colorfy(plant.power_plant));
            }
        });
        
        return {
            labels: names,
            datasets: [
                {
                    data: chartData,
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1,
                    date: date
                }
            ]
        }
    };

    const getLastFourElements = (arr: any) => {
        if (arr.length < 7) {
            return arr;
        }
        return arr.slice(-7);
    };

    return (
        <>
            <div className="side-info">
                <div className="side-info-items">
                    <div className="power-generation-card">
                        <p className="power-generation-card-title">Общая статистика</p>
                        {statistic ?
                            <>
                                <div className="power-generation-card-items">
                                    <div className="power-generation-card-item">
                                        <p>Фактическое потребление</p>
                                        <p>{addSpaces(Math.round(statistic.actual_generated_total).toString())} мВтч</p>
                                    </div>
                                    <div className="power-generation-card-item">
                                        <p>Плановое потребление</p>
                                        <p>{addSpaces(Math.round(statistic.planned_total).toString())} мВтч</p>
                                    </div>
                                </div>
                                <Button text="Подробнее" onClick={() => setIsStatisticModal(true)} />
                            </>
                        :
                            <div style={{textAlign: "center"}}>Нет данных за выбранный период</div>
                        }
                    </div>

                    <div className="power-generation-card">
                        <p className="power-generation-card-title">
                            Статистика по выработке электроэнергии {powerGenerationChartData?.datasets[0].date
                            ? `за ${dayjs(powerGenerationChartData.datasets[0].date).format("DD.MM")}` : ""}
                        </p>
                        {powerPlantTotalGenerated &&
                            <p style={{fontSize: "12px"}}>
                                Общая выработка: {addSpaces(powerPlantTotalGenerated.toString())} мВтч
                            </p>
                        }
                        {powerGenerationChartData ?
                            <BarChart
                                data={powerGenerationChartData}
                                responsive={false}
                                isLegend={false}
                            />
                        :
                            <div style={{textAlign: "center"}}>Нет данных за выбранный период</div>
                        }

                        <Button text="Подробнее" onClick={() => setPowerGenerationModal(true)} />
                    </div>

                    <div className="power-generation-card">
                        <p className="power-generation-card-title">Боковая приточность</p>
                        {lateralInflow && lateralInflowChartDataShort ?
                            <>
                                <LineChart
                                    data={lateralInflowChartDataShort}
                                    isLegend={true}
                                />
                                <Button text="Подробнее" onClick={() => setLateralInflowModal(true)} />
                            </>
                        :
                            <div style={{textAlign: "center"}}>Нет данных за выбранный период</div>
                        }
                    </div>
                </div>

                <div className="side-info-big-card" style={{marginTop: "auto", width: "fit-content"}}>
                    <p>Типы электростанций</p>
                    <div className="side-info-legend side-info-legend-horizontal">
                        <div className="side-info-legend-item">
                            <div className="marker" style={{background: "#1F5673"}} />
                            <p> - ТЭЦ</p>
                        </div>
                        <div className="side-info-legend-item">
                            <div className="marker"  style={{background: "#E9DB46"}} />
                            <p> - ГЭС</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <Modal
                title="Общая статистика"
                isOpen={isStatisticModal}
                onClose={() => setIsStatisticModal(false)}
                buttonText="ЭКСПОРТ"
                buttonAction={() => console.log("qwe")}
            >
                {statistic?.results && <PowerGenerationStatisticModal statistic={statistic.results} />}
            </Modal>

            <Modal
                title=""
                isOpen={powerGenerationModal}
                onClose={() => setPowerGenerationModal(false)}
                buttonText="ЭКСПОРТ"
                buttonAction={() => console.log("qwe")}
            >
                <PowerGenerationModal />
            </Modal>

            <Modal
                title="Боковая приточность"
                isOpen={lateralInflowModal}
                onClose={() => setLateralInflowModal(false)}
                buttonText="ЭКСПОРТ"
                buttonAction={() => console.log("qwe")}
            >
                {lateralInflowChartData && <PowerGenerationLateralInflowModal lateralInflow={lateralInflow} lineChartData={lateralInflowChartData} />}
            </Modal>
        </>
    );
};

export default PowerGenerationSideInfo;