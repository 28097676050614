import React from 'react';
import LineChart from "../../../Charts/LineChart";
import {IPowerGenerationLateralInflow} from "../../../../types/power-generation";
import {ILineChartData} from "../../../../types/chart";
import dayjs from "dayjs";
import { addSpaces } from '../../../../utils';

interface IPowerGenerationLateralInflowModal {
    lateralInflow: IPowerGenerationLateralInflow[];
    lineChartData: ILineChartData;
}

const PowerGenerationLateralInflowModal: React.FC<IPowerGenerationLateralInflowModal> = ({lateralInflow, lineChartData}) => {
    const tableHeader = ["Дата", "Наименование реки", "Поток воды куб. м/сек"];

    return (
        <div>
            <LineChart
                data={lineChartData}
                chartWidth={1000}
                chartHeight={200}
                isLegend={true}
            />

            <div className="table-border power-generation-statistic-table" style={{marginTop: "20px"}}>
                <table className="table">
                    <thead className="table-header">
                        {tableHeader.map((item, index) =>
                            <td key={index} className='cell'>
                                {item}
                            </td>
                        )}
                    </thead>

                    <tbody>
                        {lateralInflow.map((river) =>
                            river.daily_averages.map((item, index) =>
                                <tr key={index} className="row">
                                    <td className="cell">{dayjs(item.date).format("DD.MM.YYYY")}</td>
                                    <td className="cell">{river.river}</td>
                                    <td className="cell">{addSpaces(item.average_flow.toString())}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PowerGenerationLateralInflowModal;