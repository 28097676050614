import {LatLngExpression} from "leaflet";

export const markakolDistrict: LatLngExpression[] = [
    [84.5203972, 47.8229523],
    [84.5506096, 47.9211739],
    [84.9287796, 48.1736414],
    [84.9833411, 48.2222174],
    [84.9781698, 48.2242831],
    [84.9706542, 48.2260879],
    [84.9686211, 48.2280998],
    [84.9665505, 48.2293327],
    [84.9548292, 48.2331169],
    [84.9508864, 48.2334885],
    [84.9453986, 48.2328704],
    [84.9431670, 48.2319413],
    [84.9363703, 48.2328203],
    [84.9357641, 48.2332634],
    [84.9204648, 48.2363721],
    [84.9183726, 48.2365507],
    [84.9121284, 48.2365650],
    [84.9008417, 48.2376226],
    [84.8897266, 48.2408667],
    [84.8674321, 48.2443821],
    [84.8316193, 48.2477973],
    [84.8184657, 48.2478116],
    [84.8118138, 48.2490690],
    [84.8042393, 48.2498549],
    [84.7708511, 48.2511694],
    [84.7637486, 48.2596843],
    [84.7637701, 48.2603843],
    [84.7595429, 48.2650696],
    [84.7439861, 48.2763242],
    [84.7324848, 48.2821362],
    [84.7293735, 48.2847778],
    [84.7121215, 48.2941294],
    [84.7078943, 48.2986833],
    [84.6955991, 48.3063767],
    [84.6853423, 48.3137836],
    [84.6778536, 48.3175509],
    [84.6686053, 48.3230871],
    [84.6678329, 48.3247992],
    [84.6680260, 48.3268537],
    [84.6711588, 48.3301777],
    [84.6721673, 48.3337155],
    [84.6726179, 48.3372103],
    [84.6726394, 48.3372246],
    [84.6738839, 48.3392643],
    [84.6787119, 48.3445129],
    [84.6814370, 48.3458820],
    [84.6904063, 48.3459818],
    [84.7031093, 48.3464667],
    [84.7055554, 48.3471513],
    [84.7140098, 48.3475363],
    [84.7214556, 48.3502173],
    [84.7232580, 48.3515150],
    [84.7305107, 48.3535398],
    [84.7346950, 48.3539533],
    [84.7407031, 48.3576036],
    [84.7424197, 48.3595427],
    [84.7447586, 48.3611252],
    [84.7520328, 48.3649246],
    [84.7594357, 48.3666282],
    [84.7639847, 48.3667066],
    [84.7716558, 48.3693510],
    [84.7864079, 48.3682890],
    [84.7894871, 48.3691443],
    [84.7937357, 48.3707408],
    [84.8007524, 48.3753804],
    [84.8041320, 48.3761643],
    [84.8104942, 48.3786443],
    [84.8160303, 48.3803260],
    [84.8192596, 48.3808106],
    [84.8213625, 48.3814519],
    [84.8291302, 48.3849007],
    [84.8291302, 48.3849007],
    [84.8402023, 48.3884774],
    [84.8465753, 48.3942482],
    [84.8515534, 48.3980522],
    [84.8586774, 48.4021124],
    [84.8617458, 48.4070127],
    [84.8689985, 48.4152168],
    [84.8762298, 48.4259257],
    [84.8874521, 48.4370879],
    [84.8915300, 48.4386100],
    [84.9044037, 48.4465396],
    [84.9099827, 48.4518911],
    [84.9238014, 48.4589495],
    [84.9257755, 48.4627061],
    [84.9222565, 48.4902170],
    [84.9547005, 48.5165475],
    [85.1990604, 48.5965923],
    [85.3417969, 48.6565002],
    [85.5150000, 48.8209900],
    [85.6269693, 48.9105980],

    // Kurchum end
    [
        85.6270122,
        48.9106544
    ],
    [
        85.6407451,
        48.9176488
    ],
    [
        85.6660651,
        48.9350743
    ],
    [
        85.6893252,
        48.9497318
    ],
    [
        85.702629,
        48.9616802
    ],
    [
        85.7231425,
        48.9641033
    ],
    [
        85.7449435,
        48.9634834
    ],
    [
        85.7569598,
        48.9687238
    ],
    [
        85.7768725,
        48.979879
    ],
    [
        85.7881164,
        49.0003799
    ],
    [
        85.8017634,
        49.006855
    ],
    [
        85.8219337,
        49.0078122
    ],
    [
        85.8397864,
        49.0159752
    ],
    [
        85.8488845,
        49.0173824
    ],
    [
        85.8615016,
        49.0181143
    ],
    [
        85.8741187,
        49.0124286
    ],
    [
        85.8912849,
        49.008319
    ],
    [
        85.901016,
        49.0128015
    ],
    [
        85.9017885,
        49.0136952
    ],
    [
        85.9024644,
        49.0148353
    ],
    [
        85.90343,
        49.0153841
    ],
    [
        85.9052753,
        49.0154545
    ],
    [
        85.906949,
        49.0162707
    ],
    [
        85.9071207,
        49.0171151
    ],
    [
        85.9078717,
        49.018072
    ],
    [
        85.9080863,
        49.0188179
    ],
    [
        85.9094167,
        49.0193245
    ],
    [
        85.9101677,
        49.0201829
    ],
    [
        85.911262,
        49.0203095
    ],
    [
        85.9131288,
        49.0210272
    ],
    [
        85.9138772,
        49.0216533
    ],
    [
        85.9141454,
        49.021984
    ],
    [
        85.9149313,
        49.0227087
    ],
    [
        85.9148857,
        49.023027
    ],
    [
        85.9144136,
        49.0233507
    ],
    [
        85.9144163,
        49.0236163
    ],
    [
        85.9147596,
        49.0239117
    ],
    [
        85.9156823,
        49.0241509
    ],
    [
        85.9162617,
        49.0243761
    ],
    [
        85.9176564,
        49.0246293
    ],
    [
        85.9192228,
        49.0256846
    ],
    [
        85.9200382,
        49.0267434
    ],
    [
        85.9210414,
        49.0270617
    ],
    [
        85.921905,
        49.0270353
    ],
    [
        85.9220552,
        49.0274293
    ],
    [
        85.922656,
        49.0278373
    ],
    [
        85.9240293,
        49.0285408
    ],
    [
        85.9236431,
        49.0300884
    ],
    [
        85.9247374,
        49.0308059
    ],
    [
        85.9243083,
        49.0320299
    ],
    [
        85.9248447,
        49.0325364
    ],
    [
        85.9270978,
        49.033085
    ],
    [
        85.9298658,
        49.0332538
    ],
    [
        85.9305739,
        49.034112
    ],
    [
        85.9308958,
        49.0353359
    ],
    [
        85.9316039,
        49.0357298
    ],
    [
        85.9329557,
        49.0360111
    ],
    [
        85.9332347,
        49.0363769
    ],
    [
        85.934608,
        49.0366019
    ],
    [
        85.9371614,
        49.0386557
    ],
    [
        85.939157,
        49.0396966
    ],
    [
        85.9418821,
        49.0399357
    ],
    [
        85.9443283,
        49.040836
    ],
    [
        85.9473967,
        49.0420738
    ],
    [
        85.9506368,
        49.0426364
    ],
    [
        85.951581,
        49.0433256
    ],
    [
        85.954628,
        49.0441273
    ],
    [
        85.9570098,
        49.0444789
    ],
    [
        85.9582329,
        49.0452384
    ],
    [
        85.9611296,
        49.0453931
    ],
    [
        85.96349,
        49.0462229
    ],
    [
        85.9661937,
        49.0463214
    ],
    [
        85.9673095,
        49.0471652
    ],
    [
        85.9690904,
        49.0479247
    ],
    [
        85.9708714,
        49.049092
    ],
    [
        85.9731459,
        49.0497952
    ],
    [
        85.9775018,
        49.0507515
    ],
    [
        85.9802699,
        49.0512015
    ],
    [
        85.9828663,
        49.0514265
    ],
    [
        85.9858918,
        49.0512577
    ],
    [
        85.9887457,
        49.050428
    ],
    [
        85.9910416,
        49.0500764
    ],
    [
        85.9925712,
        49.0503413
    ],
    [
        85.9936634,
        49.050335
    ],
    [
        85.9942287,
        49.0500964
    ],
    [
        85.9945928,
        49.0496819
    ],
    [
        85.9950431,
        49.0489786
    ],
    [
        85.9953402,
        49.0487839
    ],
    [
        85.9957617,
        49.0487588
    ],
    [
        85.996557,
        49.0483946
    ],
    [
        85.9967582,
        49.0482564
    ],
    [
        85.9971031,
        49.047955
    ],
    [
        85.9976301,
        49.0479173
    ],
    [
        85.9981091,
        49.047955
    ],
    [
        85.9986936,
        49.0478106
    ],
    [
        85.9991822,
        49.0475154
    ],
    [
        86.0001883,
        49.0472893
    ],
    [
        86.000629,
        49.047214
    ],
    [
        86.0008781,
        49.0470256
    ],
    [
        86.0009931,
        49.0467681
    ],
    [
        86.001338,
        49.0464981
    ],
    [
        86.0019704,
        49.0462845
    ],
    [
        86.002392,
        49.0460208
    ],
    [
        86.002986,
        49.0456816
    ],
    [
        86.0031872,
        49.0455121
    ],
    [
        86.0037525,
        49.0453614
    ],
    [
        86.0040974,
        49.0453614
    ],
    [
        86.0047969,
        49.0453174
    ],
    [
        86.0052759,
        49.045173
    ],
    [
        86.0058221,
        49.0450976
    ],
    [
        86.0061095,
        49.0451918
    ],
    [
        86.0063778,
        49.045242
    ],
    [
        86.0066461,
        49.0451227
    ],
    [
        86.006694,
        49.0448903
    ],
    [
        86.0069527,
        49.0446015
    ],
    [
        86.0071155,
        49.0442937
    ],
    [
        86.0072209,
        49.0440299
    ],
    [
        86.0073838,
        49.0438478
    ],
    [
        86.0078246,
        49.0437285
    ],
    [
        86.0081695,
        49.0435966
    ],
    [
        86.0083132,
        49.0433579
    ],
    [
        86.0084761,
        49.0430251
    ],
    [
        86.0087635,
        49.0428304
    ],
    [
        86.0090222,
        49.0426797
    ],
    [
        86.0095396,
        49.0422965
    ],
    [
        86.0099229,
        49.0421081
    ],
    [
        86.0103444,
        49.0420265
    ],
    [
        86.0105169,
        49.0417376
    ],
    [
        86.0106319,
        49.041411
    ],
    [
        86.0109097,
        49.0411409
    ],
    [
        86.0119253,
        49.0408834
    ],
    [
        86.0120786,
        49.0406322
    ],
    [
        86.0120882,
        49.0403809
    ],
    [
        86.0122703,
        49.0401548
    ],
    [
        86.0126823,
        49.0400041
    ],
    [
        86.0127685,
        49.0397026
    ],
    [
        86.012941,
        49.0394702
    ],
    [
        86.013669,
        49.0392001
    ],
    [
        86.013899,
        49.0389678
    ],
    [
        86.0142822,
        49.0387919
    ],
    [
        86.014905,
        49.0386411
    ],
    [
        86.0153937,
        49.0384778
    ],
    [
        86.016417,
        49.038302
    ],
    [
        86.0168308,
        49.0383585
    ],
    [
        86.0174249,
        49.0382203
    ],
    [
        86.0177602,
        49.0382643
    ],
    [
        86.0182393,
        49.0381826
    ],
    [
        86.0185263,
        49.037914
    ],
    [
        86.018956,
        49.0378234
    ],
    [
        86.0193986,
        49.037655
    ],
    [
        86.0198863,
        49.0375189
    ],
    [
        86.0202322,
        49.0375168
    ],
    [
        86.0205292,
        49.0373724
    ],
    [
        86.0207017,
        49.0371462
    ],
    [
        86.0211137,
        49.037052
    ],
    [
        86.0216406,
        49.0370834
    ],
    [
        86.0221772,
        49.037096
    ],
    [
        86.0228766,
        49.0371965
    ],
    [
        86.023212,
        49.0371337
    ],
    [
        86.0234228,
        49.0369201
    ],
    [
        86.0239785,
        49.0367819
    ],
    [
        86.024448,
        49.0365432
    ],
    [
        86.0249941,
        49.0361224
    ],
    [
        86.0252624,
        49.0357518
    ],
    [
        86.0254252,
        49.0353686
    ],
    [
        86.0258372,
        49.0352933
    ],
    [
        86.0261371,
        49.0352246
    ],
    [
        86.0263355,
        49.0349855
    ],
    [
        86.0265463,
        49.0346463
    ],
    [
        86.0267302,
        49.0344767
    ],
    [
        86.027191,
        49.0340625
    ],
    [
        86.027247,
        49.0340121
    ],
    [
        86.027491,
        49.0338545
    ],
    [
        86.027887,
        49.0337236
    ],
    [
        86.028057,
        49.0333759
    ],
    [
        86.0279934,
        49.0330248
    ],
    [
        86.0277546,
        49.0324534
    ],
    [
        86.0273905,
        49.0315111
    ],
    [
        86.0269306,
        49.0307259
    ],
    [
        86.0267485,
        49.0302924
    ],
    [
        86.0269497,
        49.0291491
    ],
    [
        86.0268348,
        49.0286967
    ],
    [
        86.0259916,
        49.0280497
    ],
    [
        86.0256179,
        49.0274905
    ],
    [
        86.0255509,
        49.0265733
    ],
    [
        86.0251772,
        49.0252853
    ],
    [
        86.024976,
        49.0243429
    ],
    [
        86.0247173,
        49.0239597
    ],
    [
        86.0245257,
        49.0234508
    ],
    [
        86.0247173,
        49.0227973
    ],
    [
        86.0251676,
        49.0224643
    ],
    [
        86.0255221,
        49.0218549
    ],
    [
        86.0259724,
        49.0212014
    ],
    [
        86.0262599,
        49.0206611
    ],
    [
        86.0262695,
        49.0203029
    ],
    [
        86.025982,
        49.0197814
    ],
    [
        86.0253401,
        49.019373
    ],
    [
        86.0252347,
        49.018996
    ],
    [
        86.0254646,
        49.0185121
    ],
    [
        86.0255221,
        49.0178524
    ],
    [
        86.0255221,
        49.0171612
    ],
    [
        86.0261162,
        49.0167025
    ],
    [
        86.0266623,
        49.0160678
    ],
    [
        86.0267294,
        49.0154583
    ],
    [
        86.0264228,
        49.0147796
    ],
    [
        86.0264419,
        49.014478
    ],
    [
        86.0263461,
        49.0138872
    ],
    [
        86.0260587,
        49.0136359
    ],
    [
        86.0264132,
        49.0129886
    ],
    [
        86.027036,
        49.0123979
    ],
    [
        86.0274384,
        49.01165
    ],
    [
        86.027745,
        49.0108771
    ],
    [
        86.0280899,
        49.0105063
    ],
    [
        86.0281474,
        49.0099847
    ],
    [
        86.0280536,
        49.0094301
    ],
    [
        86.0277757,
        49.0090561
    ],
    [
        86.0277278,
        49.0087922
    ],
    [
        86.027838,
        49.0080977
    ],
    [
        86.0280967,
        49.0077615
    ],
    [
        86.0284847,
        49.0075226
    ],
    [
        86.0290213,
        49.0073435
    ],
    [
        86.0297782,
        49.0071801
    ],
    [
        86.030401,
        49.0072147
    ],
    [
        86.0309088,
        49.0072178
    ],
    [
        86.0312681,
        49.007133
    ],
    [
        86.0315411,
        49.0069664
    ],
    [
        86.0316753,
        49.0066647
    ],
    [
        86.0317615,
        49.0062939
    ],
    [
        86.0316753,
        49.0057785
    ],
    [
        86.0318477,
        49.0052223
    ],
    [
        86.032322,
        49.0048389
    ],
    [
        86.0327053,
        49.0044576
    ],
    [
        86.0327867,
        49.004181
    ],
    [
        86.0326909,
        49.0039987
    ],
    [
        86.0325424,
        49.0039013
    ],
    [
        86.032207,
        49.0038228
    ],
    [
        86.0320825,
        49.0036939
    ],
    [
        86.0326334,
        49.0024745
    ],
    [
        86.0331699,
        49.0020157
    ],
    [
        86.0336107,
        49.0017454
    ],
    [
        86.0338502,
        49.0011262
    ],
    [
        86.0340897,
        49.0009062
    ],
    [
        86.0346263,
        49.0006988
    ],
    [
        86.0351102,
        49.0004411
    ],
    [
        86.0354503,
        49.00013
    ],
    [
        86.0355796,
        48.9999131
    ],
    [
        86.0356515,
        48.9994196
    ],
    [
        86.035776,
        48.9991839
    ],
    [
        86.0364707,
        48.9986685
    ],
    [
        86.0367294,
        48.9983887
    ],
    [
        86.0368635,
        48.9980619
    ],
    [
        86.0369114,
        48.9973955
    ],
    [
        86.0369122,
        48.9971471
    ],
    [
        86.0373086,
        48.9967261
    ],
    [
        86.0380579,
        48.9966774
    ],
    [
        86.0390169,
        48.9967855
    ],
    [
        86.0398768,
        48.9967543
    ],
    [
        86.0406769,
        48.9965752
    ],
    [
        86.0415488,
        48.9962263
    ],
    [
        86.0420853,
        48.9959088
    ],
    [
        86.0426219,
        48.9956856
    ],
    [
        86.0429093,
        48.9953996
    ],
    [
        86.0430051,
        48.9950664
    ],
    [
        86.0429812,
        48.994837
    ],
    [
        86.042871,
        48.9945132
    ],
    [
        86.0426794,
        48.9941737
    ],
    [
        86.0425835,
        48.9937525
    ],
    [
        86.042574,
        48.9935136
    ],
    [
        86.042756,
        48.9932747
    ],
    [
        86.0434986,
        48.9929007
    ],
    [
        86.0436806,
        48.9926146
    ],
    [
        86.0438195,
        48.9921997
    ],
    [
        86.0442459,
        48.9913132
    ],
    [
        86.0447489,
        48.9907286
    ],
    [
        86.045022,
        48.9901627
    ],
    [
        86.0450124,
        48.9895183
    ],
    [
        86.0448112,
        48.9892134
    ],
    [
        86.0443369,
        48.9888864
    ],
    [
        86.044198,
        48.9886789
    ],
    [
        86.0441932,
        48.9884746
    ],
    [
        86.0442842,
        48.9882671
    ],
    [
        86.0447345,
        48.9880313
    ],
    [
        86.0453621,
        48.9878333
    ],
    [
        86.0455969,
        48.9876352
    ],
    [
        86.0456591,
        48.9874529
    ],
    [
        86.0450459,
        48.9864532
    ],
    [
        86.0450603,
        48.9860571
    ],
    [
        86.0451896,
        48.9857207
    ],
    [
        86.0454723,
        48.9851233
    ],
    [
        86.0457789,
        48.9846706
    ],
    [
        86.0458555,
        48.9841802
    ],
    [
        86.0457262,
        48.9838563
    ],
    [
        86.0455873,
        48.9832747
    ],
    [
        86.0456639,
        48.9830955
    ],
    [
        86.0458076,
        48.9830295
    ],
    [
        86.0460999,
        48.9830923
    ],
    [
        86.0462819,
        48.9830609
    ],
    [
        86.0469861,
        48.9827449
    ],
    [
        86.0474077,
        48.9826695
    ],
    [
        86.0490205,
        48.9827285
    ],
    [
        86.0493931,
        48.9827129
    ],
    [
        86.0497928,
        48.9826618
    ],
    [
        86.0507176,
        48.9822839
    ],
    [
        86.0509446,
        48.9819637
    ],
    [
        86.0513341,
        48.9817659
    ],
    [
        86.0518355,
        48.9816169
    ],
    [
        86.0523131,
        48.9815346
    ],
    [
        86.053097,
        48.9814724
    ],
    [
        86.0538725,
        48.9811542
    ],
    [
        86.054279,
        48.980738
    ],
    [
        86.0547949,
        48.9799874
    ],
    [
        86.0551239,
        48.9796966
    ],
    [
        86.0560718,
        48.9789262
    ],
    [
        86.0576139,
        48.9776985
    ],
    [
        86.059011,
        48.9767915
    ],
    [
        86.0603054,
        48.9763637
    ],
    [
        86.061256,
        48.9763514
    ],
    [
        86.0625719,
        48.9767217
    ],
    [
        86.0627386,
        48.9767391
    ],
    [
        86.0633739,
        48.976805
    ],
    [
        86.0640334,
        48.9765541
    ],
    [
        86.0647746,
        48.975922
    ],
    [
        86.0659079,
        48.9746668
    ],
    [
        86.0668615,
        48.9742333
    ],
    [
        86.0676709,
        48.9736198
    ],
    [
        86.0693652,
        48.973078
    ],
    [
        86.0704572,
        48.9729416
    ],
    [
        86.0712235,
        48.9729671
    ],
    [
        86.0720068,
        48.9729264
    ],
    [
        86.0726307,
        48.9727646
    ],
    [
        86.073313,
        48.9723966
    ],
    [
        86.0740686,
        48.9720131
    ],
    [
        86.0749536,
        48.9717523
    ],
    [
        86.0757564,
        48.9717223
    ],
    [
        86.0765052,
        48.9718396
    ],
    [
        86.077972,
        48.9722047
    ],
    [
        86.0783435,
        48.972222
    ],
    [
        86.0787626,
        48.9721508
    ],
    [
        86.0792315,
        48.9719749
    ],
    [
        86.0797761,
        48.9720029
    ],
    [
        86.0812738,
        48.9718139
    ],
    [
        86.0815106,
        48.9717295
    ],
    [
        86.0816198,
        48.9716325
    ],
    [
        86.0818317,
        48.971224
    ],
    [
        86.0821777,
        48.9708176
    ],
    [
        86.0823388,
        48.9703752
    ],
    [
        86.0827221,
        48.9700426
    ],
    [
        86.0833083,
        48.9698352
    ],
    [
        86.0847176,
        48.969208
    ],
    [
        86.0848736,
        48.9689577
    ],
    [
        86.0850501,
        48.9683246
    ],
    [
        86.0850858,
        48.9677511
    ],
    [
        86.0864396,
        48.9664753
    ],
    [
        86.0877429,
        48.964683
    ],
    [
        86.0888102,
        48.9635804
    ],
    [
        86.0901153,
        48.9612186
    ],
    [
        86.0910066,
        48.9596074
    ],
    [
        86.091077,
        48.9587145
    ],
    [
        86.0912057,
        48.9582355
    ],
    [
        86.0914739,
        48.9576085
    ],
    [
        86.0917851,
        48.9570097
    ],
    [
        86.0921445,
        48.9565007
    ],
    [
        86.0930672,
        48.9555003
    ],
    [
        86.0932925,
        48.9552749
    ],
    [
        86.0936412,
        48.9551076
    ],
    [
        86.0941293,
        48.9550001
    ],
    [
        86.0945531,
        48.9549455
    ],
    [
        86.0955294,
        48.9549667
    ],
    [
        86.0957172,
        48.9549156
    ],
    [
        86.0959801,
        48.9548046
    ],
    [
        86.0969135,
        48.9541071
    ],
    [
        86.0971388,
        48.9538817
    ],
    [
        86.0973319,
        48.953621
    ],
    [
        86.0975036,
        48.9532617
    ],
    [
        86.0978147,
        48.9527756
    ],
    [
        86.0982224,
        48.9523247
    ],
    [
        86.0991773,
        48.9516483
    ],
    [
        86.0993382,
        48.9514651
    ],
    [
        86.0994026,
        48.9512819
    ],
    [
        86.0993811,
        48.9510776
    ],
    [
        86.0992738,
        48.9508944
    ],
    [
        86.0988447,
        48.9503871
    ],
    [
        86.0988017,
        48.9502251
    ],
    [
        86.0988339,
        48.9499785
    ],
    [
        86.0989305,
        48.9496825
    ],
    [
        86.09907,
        48.94945
    ],
    [
        86.0993382,
        48.9491048
    ],
    [
        86.0999873,
        48.9485534
    ],
    [
        86.1003413,
        48.94818
    ],
    [
        86.1005988,
        48.9477009
    ],
    [
        86.1007758,
        48.9470896
    ],
    [
        86.1009475,
        48.9462229
    ],
    [
        86.1010602,
        48.9459041
    ],
    [
        86.1012426,
        48.9456081
    ],
    [
        86.1024495,
        48.9447502
    ],
    [
        86.1026963,
        48.9445247
    ],
    [
        86.1028358,
        48.9442922
    ],
    [
        86.1028251,
        48.9440596
    ],
    [
        86.102648,
        48.9438042
    ],
    [
        86.1023959,
        48.9436298
    ],
    [
        86.1021009,
        48.9435012
    ],
    [
        86.1019131,
        48.9433761
    ],
    [
        86.1017307,
        48.9430942
    ],
    [
        86.1015322,
        48.942268
    ],
    [
        86.1015269,
        48.9420583
    ],
    [
        86.1016181,
        48.9417888
    ],
    [
        86.1019131,
        48.9414593
    ],
    [
        86.1024227,
        48.9410489
    ],
    [
        86.1026373,
        48.9407952
    ],
    [
        86.1026641,
        48.9406701
    ],
    [
        86.1026105,
        48.9404587
    ],
    [
        86.102117,
        48.9400711
    ],
    [
        86.1016771,
        48.9396271
    ],
    [
        86.1015376,
        48.93931
    ],
    [
        86.1015376,
        48.9391338
    ],
    [
        86.1016127,
        48.9389224
    ],
    [
        86.1025354,
        48.9376186
    ],
    [
        86.1027553,
        48.9371235
    ],
    [
        86.1031362,
        48.935765
    ],
    [
        86.1036244,
        48.9344876
    ],
    [
        86.1036071,
        48.93383
    ],
    [
        86.1031334,
        48.9334186
    ],
    [
        86.1029145,
        48.9330209
    ],
    [
        86.1022169,
        48.9317537
    ],
    [
        86.1023322,
        48.9313282
    ],
    [
        86.1029812,
        48.9308242
    ],
    [
        86.104191,
        48.9293338
    ],
    [
        86.1045983,
        48.9287629
    ],
    [
        86.1056439,
        48.928067
    ],
    [
        86.1070168,
        48.9278415
    ],
    [
        86.1077052,
        48.9274082
    ],
    [
        86.108225,
        48.9259331
    ],
    [
        86.1103811,
        48.9238532
    ],
    [
        86.1121756,
        48.9225531
    ],
    [
        86.1126544,
        48.9225864
    ],
    [
        86.1136693,
        48.9229635
    ],
    [
        86.1143754,
        48.9230884
    ],
    [
        86.1155975,
        48.9236684
    ],
    [
        86.1163349,
        48.9236518
    ],
    [
        86.1169962,
        48.9233026
    ],
    [
        86.1171863,
        48.9226869
    ],
    [
        86.1182319,
        48.9220267
    ],
    [
        86.1192096,
        48.9218036
    ],
    [
        86.1203803,
        48.9218451
    ],
    [
        86.122188,
        48.9215884
    ],
    [
        86.1233513,
        48.9216073
    ],
    [
        86.1258306,
        48.9212589
    ],
    [
        86.1275201,
        48.920599
    ],
    [
        86.1279547,
        48.9200904
    ],
    [
        86.1278314,
        48.9194209
    ],
    [
        86.128267,
        48.9184842
    ],
    [
        86.1287783,
        48.9181848
    ],
    [
        86.1288874,
        48.9175435
    ],
    [
        86.129885,
        48.9169712
    ],
    [
        86.1314817,
        48.9166054
    ],
    [
        86.1319645,
        48.9164362
    ],
    [
        86.1324902,
        48.9161964
    ],
    [
        86.1331554,
        48.9159426
    ],
    [
        86.1336811,
        48.9158016
    ],
    [
        86.1380692,
        48.9149908
    ],
    [
        86.1388738,
        48.9149132
    ],
    [
        86.1396249,
        48.9149908
    ],
    [
        86.1398931,
        48.9149978
    ],
    [
        86.1401613,
        48.9149767
    ],
    [
        86.1405475,
        48.9148427
    ],
    [
        86.140966,
        48.9145818
    ],
    [
        86.1413415,
        48.9142011
    ],
    [
        86.1419852,
        48.91308
    ],
    [
        86.1425002,
        48.9123255
    ],
    [
        86.1429723,
        48.9117473
    ],
    [
        86.1432834,
        48.9114794
    ],
    [
        86.1435409,
        48.9113454
    ],
    [
        86.1444958,
        48.9109999
    ],
    [
        86.1448284,
        48.9108236
    ],
    [
        86.1455686,
        48.9102101
    ],
    [
        86.1458154,
        48.910048
    ],
    [
        86.1461587,
        48.9099281
    ],
    [
        86.1465342,
        48.9098294
    ],
    [
        86.1470814,
        48.9097518
    ],
    [
        86.1479397,
        48.9097447
    ],
    [
        86.1489643,
        48.9098629
    ],
    [
        86.1492755,
        48.9098629
    ],
    [
        86.1496885,
        48.9098294
    ],
    [
        86.1506756,
        48.9096531
    ],
    [
        86.1519845,
        48.9092582
    ],
    [
        86.1532612,
        48.9087223
    ],
    [
        86.1541088,
        48.908412
    ],
    [
        86.1551602,
        48.9080665
    ],
    [
        86.1559649,
        48.907869
    ],
    [
        86.1569412,
        48.9076645
    ],
    [
        86.157703,
        48.90746
    ],
    [
        86.1580892,
        48.9072908
    ],
    [
        86.1586364,
        48.9069734
    ],
    [
        86.1590977,
        48.9068112
    ],
    [
        86.1595269,
        48.9067055
    ],
    [
        86.1600311,
        48.906642
    ],
    [
        86.1609752,
        48.9064516
    ],
    [
        86.1619945,
        48.9063105
    ],
    [
        86.1638398,
        48.9062118
    ],
    [
        86.1646123,
        48.9060849
    ],
    [
        86.1653204,
        48.9058451
    ],
    [
        86.1656423,
        48.9056688
    ],
    [
        86.1658354,
        48.9055489
    ],
    [
        86.1662002,
        48.9051893
    ],
    [
        86.166447,
        48.9050553
    ],
    [
        86.1685391,
        48.9043501
    ],
    [
        86.1690111,
        48.9042302
    ],
    [
        86.1693867,
        48.9041879
    ],
    [
        86.1697353,
        48.904179
    ],
    [
        86.1700358,
        48.9042072
    ],
    [
        86.1702879,
        48.9042584
    ],
    [
        86.1712267,
        48.9046233
    ],
    [
        86.1735441,
        48.905667
    ],
    [
        86.173823,
        48.9057587
    ],
    [
        86.1741771,
        48.9058081
    ],
    [
        86.1746652,
        48.9057816
    ],
    [
        86.1752553,
        48.9056759
    ],
    [
        86.1761136,
        48.9054008
    ],
    [
        86.1774011,
        48.9048155
    ],
    [
        86.1777551,
        48.9046956
    ],
    [
        86.1780877,
        48.904618
    ],
    [
        86.1784633,
        48.904618
    ],
    [
        86.1796112,
        48.9047591
    ],
    [
        86.1803515,
        48.9049213
    ],
    [
        86.181119,
        48.9049989
    ],
    [
        86.181591,
        48.9049953
    ],
    [
        86.1819345,
        48.9048402
    ],
    [
        86.1820845,
        48.9051646
    ],
    [
        86.1824064,
        48.9055419
    ],
    [
        86.1825673,
        48.9058027
    ],
    [
        86.1826692,
        48.9059296
    ],
    [
        86.1829374,
        48.9061518
    ],
    [
        86.183034,
        48.9062822
    ],
    [
        86.1830179,
        48.9065397
    ],
    [
        86.1827336,
        48.9071038
    ],
    [
        86.1826639,
        48.9082603
    ],
    [
        86.1824761,
        48.9086517
    ],
    [
        86.1822669,
        48.9088915
    ],
    [
        86.1820738,
        48.9091805
    ],
    [
        86.1820416,
        48.9098539
    ],
    [
        86.1819879,
        48.910069
    ],
    [
        86.1819289,
        48.9102172
    ],
    [
        86.1817841,
        48.9103546
    ],
    [
        86.18165,
        48.9106156
    ],
    [
        86.1816393,
        48.9108693
    ],
    [
        86.1816017,
        48.9110245
    ],
    [
        86.1815212,
        48.9111655
    ],
    [
        86.181371,
        48.9113241
    ],
    [
        86.1810867,
        48.9115498
    ],
    [
        86.1810331,
        48.9116416
    ],
    [
        86.1810009,
        48.9117472
    ],
    [
        86.1810277,
        48.9122161
    ],
    [
        86.1808346,
        48.912893
    ],
    [
        86.1808397,
        48.9132579
    ],
    [
        86.1807968,
        48.9134025
    ],
    [
        86.1806629,
        48.9136476
    ],
    [
        86.1806841,
        48.9138715
    ],
    [
        86.1806573,
        48.9140018
    ],
    [
        86.1804162,
        48.914483
    ],
    [
        86.1799173,
        48.9159037
    ],
    [
        86.1799065,
        48.9165489
    ],
    [
        86.1799602,
        48.9173808
    ],
    [
        86.1800299,
        48.9175148
    ],
    [
        86.180207,
        48.9176734
    ],
    [
        86.1807809,
        48.9180506
    ],
    [
        86.1811189,
        48.9183502
    ],
    [
        86.1812047,
        48.9184595
    ],
    [
        86.1812477,
        48.9186641
    ],
    [
        86.181194,
        48.919221
    ],
    [
        86.1813013,
        48.919436
    ],
    [
        86.1814837,
        48.9196122
    ],
    [
        86.1816875,
        48.9200176
    ],
    [
        86.181768,
        48.9203525
    ],
    [
        86.181768,
        48.9205217
    ],
    [
        86.1816393,
        48.9207261
    ],
    [
        86.1810806,
        48.9209723
    ],
    [
        86.1809043,
        48.9212479
    ],
    [
        86.1807112,
        48.9214806
    ],
    [
        86.1807058,
        48.9215333
    ],
    [
        86.1809041,
        48.9221731
    ],
    [
        86.180958,
        48.9229363
    ],
    [
        86.1810757,
        48.9231424
    ],
    [
        86.1812474,
        48.9233469
    ],
    [
        86.1819504,
        48.9237822
    ],
    [
        86.1822454,
        48.9241664
    ],
    [
        86.1823471,
        48.9242492
    ],
    [
        86.1825458,
        48.9243462
    ],
    [
        86.182975,
        48.9244554
    ],
    [
        86.1836667,
        48.9247639
    ],
    [
        86.1840476,
        48.9250036
    ],
    [
        86.1842568,
        48.9251975
    ],
    [
        86.1844234,
        48.9254106
    ],
    [
        86.1844234,
        48.9256961
    ],
    [
        86.1843483,
        48.92589
    ],
    [
        86.1843751,
        48.9260486
    ],
    [
        86.1847557,
        48.9263006
    ],
    [
        86.1849491,
        48.9263376
    ],
    [
        86.1852653,
        48.9263393
    ],
    [
        86.1860003,
        48.9262054
    ],
    [
        86.1862363,
        48.9262125
    ],
    [
        86.1864133,
        48.9262442
    ],
    [
        86.1866601,
        48.9263746
    ],
    [
        86.1868317,
        48.9265439
    ],
    [
        86.1868854,
        48.9266813
    ],
    [
        86.1869286,
        48.927046
    ],
    [
        86.1869769,
        48.9271341
    ],
    [
        86.1871375,
        48.9272592
    ],
    [
        86.1874433,
        48.9273968
    ],
    [
        86.1883445,
        48.9275941
    ],
    [
        86.1885269,
        48.9276611
    ],
    [
        86.1887093,
        48.9277739
    ],
    [
        86.189117,
        48.9281263
    ],
    [
        86.1897017,
        48.9285703
    ],
    [
        86.1898948,
        48.9286902
    ],
    [
        86.1904205,
        48.9288664
    ],
    [
        86.1904903,
        48.9289439
    ],
    [
        86.19056,
        48.9291589
    ],
    [
        86.1906566,
        48.9292577
    ],
    [
        86.1908014,
        48.9293281
    ],
    [
        86.1915953,
        48.9295573
    ],
    [
        86.1917885,
        48.9296453
    ],
    [
        86.1918636,
        48.9297263
    ],
    [
        86.1919869,
        48.930019
    ],
    [
        86.1921372,
        48.9302516
    ],
    [
        86.1923786,
        48.9304242
    ],
    [
        86.193092,
        48.9311466
    ],
    [
        86.193371,
        48.9313792
    ],
    [
        86.1943956,
        48.9319008
    ],
    [
        86.1946745,
        48.9321193
    ],
    [
        86.1950286,
        48.9325598
    ],
    [
        86.195517,
        48.9328893
    ],
    [
        86.1956079,
        48.933152
    ],
    [
        86.195694,
        48.9333017
    ],
    [
        86.1957799,
        48.9333862
    ],
    [
        86.1961768,
        48.9335554
    ],
    [
        86.1962895,
        48.9336999
    ],
    [
        86.1962895,
        48.933869
    ],
    [
        86.1962197,
        48.9341192
    ],
    [
        86.1962305,
        48.9343624
    ],
    [
        86.1962841,
        48.9347218
    ],
    [
        86.1964719,
        48.9351659
    ],
    [
        86.1966167,
        48.935314
    ],
    [
        86.1968474,
        48.9354303
    ],
    [
        86.1974318,
        48.9356153
    ],
    [
        86.1976252,
        48.9357579
    ],
    [
        86.1976893,
        48.9361967
    ],
    [
        86.1977754,
        48.936519
    ],
    [
        86.1976252,
        48.9370159
    ],
    [
        86.1976359,
        48.9372063
    ],
    [
        86.1977218,
        48.937481
    ],
    [
        86.1978291,
        48.9375445
    ],
    [
        86.1979632,
        48.9375868
    ],
    [
        86.1981241,
        48.9376009
    ],
    [
        86.1983387,
        48.9376713
    ],
    [
        86.1985425,
        48.9378546
    ],
    [
        86.198961,
        48.9380062
    ],
    [
        86.1992882,
        48.9381682
    ],
    [
        86.1995561,
        48.9383427
    ],
    [
        86.199712,
        48.9384853
    ],
    [
        86.1999051,
        48.9388342
    ],
    [
        86.2000392,
        48.9389892
    ],
    [
        86.2002967,
        48.9391619
    ],
    [
        86.2010638,
        48.9395459
    ],
    [
        86.2018202,
        48.9398595
    ],
    [
        86.2027268,
        48.9401309
    ],
    [
        86.2036816,
        48.9403529
    ],
    [
        86.2039552,
        48.9404515
    ],
    [
        86.2041001,
        48.940529
    ],
    [
        86.2042074,
        48.9406454
    ],
    [
        86.2042876,
        48.9408056
    ],
    [
        86.2042556,
        48.9415755
    ],
    [
        86.2043039,
        48.9417095
    ],
    [
        86.2052373,
        48.9427559
    ],
    [
        86.2054197,
        48.9429215
    ],
    [
        86.2054948,
        48.9430342
    ],
    [
        86.2054999,
        48.9431277
    ],
    [
        86.205409,
        48.9433866
    ],
    [
        86.2053607,
        48.9437072
    ],
    [
        86.2054519,
        48.9444682
    ],
    [
        86.2054519,
        48.944736
    ],
    [
        86.2053822,
        48.9449967
    ],
    [
        86.2050603,
        48.9454829
    ],
    [
        86.2050067,
        48.9456345
    ],
    [
        86.2050228,
        48.9457542
    ],
    [
        86.2050925,
        48.9458634
    ],
    [
        86.2055163,
        48.9462264
    ],
    [
        86.2058864,
        48.9466244
    ],
    [
        86.2060367,
        48.9469347
    ],
    [
        86.2060904,
        48.9472307
    ],
    [
        86.2062298,
        48.9473223
    ],
    [
        86.2064551,
        48.9473997
    ],
    [
        86.2068521,
        48.9474702
    ],
    [
        86.2072062,
        48.947604
    ],
    [
        86.2076675,
        48.9478226
    ],
    [
        86.2083971,
        48.9483932
    ],
    [
        86.2088262,
        48.9486539
    ],
    [
        86.2096738,
        48.9490132
    ],
    [
        86.2102102,
        48.949429
    ],
    [
        86.2106179,
        48.9496121
    ],
    [
        86.2109398,
        48.9497249
    ],
    [
        86.2113689,
        48.9498165
    ],
    [
        86.2123989,
        48.9499151
    ],
    [
        86.212871,
        48.9500209
    ],
    [
        86.2133109,
        48.9502251
    ],
    [
        86.2138473,
        48.9507113
    ],
    [
        86.2142014,
        48.9509438
    ],
    [
        86.2158,
        48.951592
    ],
    [
        86.2166046,
        48.9519724
    ],
    [
        86.2171518,
        48.9522896
    ],
    [
        86.2174737,
        48.9525291
    ],
    [
        86.2180208,
        48.9531702
    ],
    [
        86.2182461,
        48.9533745
    ],
    [
        86.2193834,
        48.9538465
    ],
    [
        86.2199413,
        48.9541917
    ],
    [
        86.2201344,
        48.9543961
    ],
    [
        86.2201773,
        48.9544948
    ],
    [
        86.2201559,
        48.9546356
    ],
    [
        86.2196302,
        48.9551923
    ],
    [
        86.2195229,
        48.9553613
    ],
    [
        86.2194907,
        48.9555021
    ],
    [
        86.2195014,
        48.955629
    ],
    [
        86.219598,
        48.9558192
    ],
    [
        86.219598,
        48.955953
    ],
    [
        86.2194799,
        48.9562842
    ],
    [
        86.2194692,
        48.956566
    ],
    [
        86.2196302,
        48.9569464
    ],
    [
        86.2196409,
        48.9572071
    ],
    [
        86.2196194,
        48.9573832
    ],
    [
        86.2194263,
        48.957672
    ],
    [
        86.2194263,
        48.9579678
    ],
    [
        86.2195658,
        48.9583554
    ],
    [
        86.2197374,
        48.9585878
    ],
    [
        86.2202846,
        48.9591655
    ],
    [
        86.220982,
        48.9602926
    ],
    [
        86.2211107,
        48.9607363
    ],
    [
        86.2211107,
        48.9611097
    ],
    [
        86.2209283,
        48.9616451
    ],
    [
        86.2210034,
        48.9617507
    ],
    [
        86.2211536,
        48.9618776
    ],
    [
        86.2220656,
        48.9621522
    ],
    [
        86.2232243,
        48.9629552
    ],
    [
        86.2235247,
        48.9632299
    ],
    [
        86.2237286,
        48.9633146
    ],
    [
        86.2240504,
        48.963406
    ],
    [
        86.2254023,
        48.9635752
    ],
    [
        86.226561,
        48.963871
    ],
    [
        86.2269472,
        48.9638921
    ],
    [
        86.227312,
        48.9639555
    ],
    [
        86.2281381,
        48.9641879
    ],
    [
        86.228578,
        48.9642372
    ],
    [
        86.229093,
        48.9642583
    ],
    [
        86.2295007,
        48.9643571
    ],
    [
        86.2296616,
        48.9644486
    ],
    [
        86.2297796,
        48.964667
    ],
    [
        86.2298547,
        48.9649346
    ],
    [
        86.2298547,
        48.9651671
    ],
    [
        86.2299191,
        48.9653642
    ],
    [
        86.2300371,
        48.9655051
    ],
    [
        86.2302195,
        48.965646
    ],
    [
        86.2304663,
        48.9657657
    ],
    [
        86.2309062,
        48.9659067
    ],
    [
        86.2315606,
        48.9662377
    ],
    [
        86.2317323,
        48.966301
    ],
    [
        86.2319147,
        48.9663363
    ],
    [
        86.2327408,
        48.9663786
    ],
    [
        86.2329446,
        48.966442
    ],
    [
        86.2334596,
        48.9666674
    ],
    [
        86.2341999,
        48.9668294
    ],
    [
        86.2345647,
        48.9669842
    ],
    [
        86.2351977,
        48.9673434
    ],
    [
        86.2354552,
        48.9674281
    ],
    [
        86.2361847,
        48.9674421
    ],
    [
        86.2364101,
        48.9675055
    ],
    [
        86.2366675,
        48.9678788
    ],
    [
        86.2366568,
        48.9681253
    ],
    [
        86.2366997,
        48.9683719
    ],
    [
        86.236807,
        48.9685268
    ],
    [
        86.2389099,
        48.9696114
    ],
    [
        86.2395429,
        48.9701819
    ],
    [
        86.2396823,
        48.9704848
    ],
    [
        86.2398862,
        48.9706679
    ],
    [
        86.240251,
        48.9707806
    ],
    [
        86.2407552,
        48.9708792
    ],
    [
        86.2412917,
        48.9709144
    ],
    [
        86.2415384,
        48.9709707
    ],
    [
        86.2416672,
        48.9710764
    ],
    [
        86.2416994,
        48.9712736
    ],
    [
        86.2416672,
        48.9715412
    ],
    [
        86.2416994,
        48.9719356
    ],
    [
        86.2418388,
        48.9720976
    ],
    [
        86.2421607,
        48.9722877
    ],
    [
        86.2426757,
        48.9726469
    ],
    [
        86.2430405,
        48.9730835
    ],
    [
        86.2432658,
        48.9732385
    ],
    [
        86.2436735,
        48.9733864
    ],
    [
        86.2438237,
        48.973492
    ],
    [
        86.2438874,
        48.9737905
    ],
    [
        86.244108,
        48.9739639
    ],
    [
        86.244684,
        48.9740737
    ],
    [
        86.2448503,
        48.9741477
    ],
    [
        86.244934,
        48.974235
    ],
    [
        86.2450574,
        48.974573
    ],
    [
        86.245154,
        48.9746646
    ],
    [
        86.2454758,
        48.9747808
    ],
    [
        86.2463517,
        48.9749461
    ],
    [
        86.2469996,
        48.9747668
    ],
    [
        86.2481262,
        48.9747316
    ],
    [
        86.2485982,
        48.9746119
    ],
    [
        86.2488128,
        48.9746119
    ],
    [
        86.2490381,
        48.9746401
    ],
    [
        86.24936,
        48.9745415
    ],
    [
        86.2495209,
        48.9745415
    ],
    [
        86.2498857,
        48.9744499
    ],
    [
        86.2504758,
        48.9742246
    ],
    [
        86.2507225,
        48.9740696
    ],
    [
        86.2511517,
        48.9735274
    ],
    [
        86.2515165,
        48.9732457
    ],
    [
        86.2518276,
        48.9729076
    ],
    [
        86.2519563,
        48.9728302
    ],
    [
        86.252128,
        48.972809
    ],
    [
        86.2523914,
        48.9728555
    ],
    [
        86.2525845,
        48.9729471
    ],
    [
        86.2528635,
        48.9731302
    ],
    [
        86.253121,
        48.9732499
    ],
    [
        86.2533355,
        48.9732992
    ],
    [
        86.2543703,
        48.9737316
    ],
    [
        86.25466,
        48.9737879
    ],
    [
        86.2554647,
        48.9736048
    ],
    [
        86.2558938,
        48.9734569
    ],
    [
        86.2561835,
        48.9732386
    ],
    [
        86.2565161,
        48.9730696
    ],
    [
        86.2567629,
        48.9729781
    ],
    [
        86.2572349,
        48.9729006
    ],
    [
        86.2575783,
        48.9729006
    ],
    [
        86.2579216,
        48.9729428
    ],
    [
        86.2582386,
        48.9731443
    ],
    [
        86.2583615,
        48.9731541
    ],
    [
        86.2585009,
        48.9731048
    ],
    [
        86.2587155,
        48.9731048
    ],
    [
        86.2589408,
        48.9731823
    ],
    [
        86.2593807,
        48.9734147
    ],
    [
        86.2593807,
        48.9735133
    ],
    [
        86.2592841,
        48.9736119
    ],
    [
        86.2593271,
        48.9737105
    ],
    [
        86.2595368,
        48.9738556
    ],
    [
        86.2597669,
        48.9739429
    ],
    [
        86.2599601,
        48.9740626
    ],
    [
        86.2605882,
        48.9748274
    ],
    [
        86.2608779,
        48.9748978
    ],
    [
        86.2614085,
        48.9751823
    ],
    [
        86.2616659,
        48.9752739
    ],
    [
        86.2626852,
        48.9753513
    ],
    [
        86.2629963,
        48.9753161
    ],
    [
        86.263168,
        48.9752387
    ],
    [
        86.2633933,
        48.9752316
    ],
    [
        86.2638761,
        48.975288
    ],
    [
        86.2641228,
        48.9752457
    ],
    [
        86.2644876,
        48.9752457
    ],
    [
        86.2647344,
        48.9751471
    ],
    [
        86.2654103,
        48.9747246
    ],
    [
        86.2655712,
        48.9746894
    ],
    [
        86.2657429,
        48.9747246
    ],
    [
        86.2659634,
        48.9748344
    ],
    [
        86.266135,
        48.9748697
    ],
    [
        86.2663223,
        48.9748443
    ],
    [
        86.2665583,
        48.9747668
    ],
    [
        86.267459,
        48.9743795
    ],
    [
        86.2682207,
        48.9742598
    ],
    [
        86.2685211,
        48.9741682
    ],
    [
        86.2688752,
        48.9741682
    ],
    [
        86.2691649,
        48.9742105
    ],
    [
        86.2695404,
        48.973971
    ],
    [
        86.2698515,
        48.9739006
    ],
    [
        86.2701626,
        48.9737739
    ],
    [
        86.2704416,
        48.9737386
    ],
    [
        86.2708386,
        48.973802
    ],
    [
        86.2715738,
        48.9730586
    ],
    [
        86.2739931,
        48.9715903
    ],
    [
        86.2769436,
        48.9707662
    ],
    [
        86.2783598,
        48.969738
    ],
    [
        86.2794434,
        48.9683505
    ],
    [
        86.2826942,
        48.9675405
    ],
    [
        86.2845074,
        48.9661248
    ],
    [
        86.2887775,
        48.9652021
    ],
    [
        86.2922858,
        48.9629762
    ],
    [
        86.294614,
        48.9624197
    ],
    [
        86.2987338,
        48.962307
    ],
    [
        86.3045918,
        48.960391
    ],
    [
        86.3081216,
        48.9577106
    ],
    [
        86.3079338,
        48.9568406
    ],
    [
        86.3082825,
        48.9563123
    ],
    [
        86.3089799,
        48.9559317
    ],
    [
        86.3093017,
        48.9554246
    ],
    [
        86.3098704,
        48.9549737
    ],
    [
        86.3100742,
        48.9543536
    ],
    [
        86.3129603,
        48.9528038
    ],
    [
        86.3143121,
        48.9523387
    ],
    [
        86.3156157,
        48.9509542
    ],
    [
        86.3158142,
        48.9499961
    ],
    [
        86.3161199,
        48.9496543
    ],
    [
        86.3168066,
        48.9494007
    ],
    [
        86.3172947,
        48.949006
    ],
    [
        86.3184964,
        48.9490976
    ],
    [
        86.3204813,
        48.9487666
    ],
    [
        86.3227664,
        48.9493372
    ],
    [
        86.3253735,
        48.9504575
    ],
    [
        86.3278948,
        48.9521273
    ],
    [
        86.3293754,
        48.9522823
    ],
    [
        86.3304483,
        48.9534096
    ],
    [
        86.3329266,
        48.9541212
    ],
    [
        86.3337206,
        48.9545933
    ],
    [
        86.3349115,
        48.9546426
    ],
    [
        86.3390716,
        48.9559898
    ],
    [
        86.3396965,
        48.9564566
    ],
    [
        86.3408552,
        48.9567701
    ],
    [
        86.3419174,
        48.9572562
    ],
    [
        86.3442348,
        48.9578338
    ],
    [
        86.3482796,
        48.9591582
    ],
    [
        86.3491486,
        48.9586792
    ],
    [
        86.3505541,
        48.9584608
    ],
    [
        86.351627,
        48.9581579
    ],
    [
        86.3522278,
        48.9582424
    ],
    [
        86.3526892,
        48.9585383
    ],
    [
        86.3536118,
        48.9587426
    ],
    [
        86.3547276,
        48.9595457
    ],
    [
        86.3558434,
        48.9604403
    ],
    [
        86.3584398,
        48.9600529
    ],
    [
        86.359738,
        48.9602924
    ],
    [
        86.361122,
        48.9612293
    ],
    [
        86.3626241,
        48.9627085
    ],
    [
        86.3649844,
        48.9635961
    ],
    [
        86.3667761,
        48.9646456
    ],
    [
        86.3686537,
        48.9666671
    ],
    [
        86.3707565,
        48.9681462
    ],
    [
        86.3734816,
        48.9696958
    ],
    [
        86.3746833,
        48.9699353
    ],
    [
        86.3765072,
        48.9699635
    ],
    [
        86.3790285,
        48.9706466
    ],
    [
        86.3794415,
        48.9709917
    ],
    [
        86.3789855,
        48.971615
    ],
    [
        86.3789748,
        48.9719214
    ],
    [
        86.3793176,
        48.9720229
    ],
    [
        86.3797258,
        48.9718229
    ],
    [
        86.3800364,
        48.9716214
    ],
    [
        86.3811099,
        48.9699988
    ],
    [
        86.3812493,
        48.9698579
    ],
    [
        86.3814639,
        48.9697311
    ],
    [
        86.3821286,
        48.9694381
    ],
    [
        86.3822573,
        48.9693254
    ],
    [
        86.3822686,
        48.969224
    ],
    [
        86.3820218,
        48.9686676
    ],
    [
        86.3820218,
        48.9684986
    ],
    [
        86.3821071,
        48.9683605
    ],
    [
        86.3823329,
        48.9681887
    ],
    [
        86.3827616,
        48.9679943
    ],
    [
        86.3830083,
        48.9678323
    ],
    [
        86.3831585,
        48.9676773
    ],
    [
        86.3834273,
        48.9671181
    ],
    [
        86.3838564,
        48.9664771
    ],
    [
        86.3841676,
        48.9661743
    ],
    [
        86.3850366,
        48.9656037
    ],
    [
        86.3852721,
        48.9655079
    ],
    [
        86.3867312,
        48.9651487
    ],
    [
        86.3869136,
        48.9650572
    ],
    [
        86.387214,
        48.9647402
    ],
    [
        86.3873106,
        48.9644866
    ],
    [
        86.387375,
        48.9641978
    ],
    [
        86.3872999,
        48.9639372
    ],
    [
        86.3871073,
        48.9635329
    ],
    [
        86.3871395,
        48.9633709
    ],
    [
        86.3872253,
        48.9632159
    ],
    [
        86.3878899,
        48.9624298
    ],
    [
        86.3881153,
        48.9620424
    ],
    [
        86.3882231,
        48.9619268
    ],
    [
        86.3883518,
        48.9618494
    ],
    [
        86.3888775,
        48.9617437
    ],
    [
        86.3889956,
        48.9616662
    ],
    [
        86.3889736,
        48.9615704
    ],
    [
        86.388781,
        48.9613563
    ],
    [
        86.3886732,
        48.9611618
    ],
    [
        86.388663,
        48.960997
    ],
    [
        86.388759,
        48.9608308
    ],
    [
        86.3889741,
        48.9606659
    ],
    [
        86.3895636,
        48.9603588
    ],
    [
        86.3901537,
        48.9598939
    ],
    [
        86.3909804,
        48.9591021
    ],
    [
        86.3911306,
        48.9590105
    ],
    [
        86.3913661,
        48.9589358
    ],
    [
        86.3919347,
        48.9588442
    ],
    [
        86.3921922,
        48.9587738
    ],
    [
        86.3923,
        48.9586583
    ],
    [
        86.3924717,
        48.9581933
    ],
    [
        86.3928038,
        48.9576678
    ],
    [
        86.3931578,
        48.9572803
    ],
    [
        86.3940698,
        48.9565617
    ],
    [
        86.3953787,
        48.9558713
    ],
    [
        86.3965165,
        48.9553613
    ],
    [
        86.3984042,
        48.9545539
    ],
    [
        86.3993483,
        48.9542016
    ],
    [
        86.4000033,
        48.9540086
    ],
    [
        86.4003461,
        48.9539692
    ],
    [
        86.4007538,
        48.9539551
    ],
    [
        86.4011084,
        48.9540368
    ],
    [
        86.4013123,
        48.9540438
    ],
    [
        86.4014732,
        48.9540368
    ],
    [
        86.4016014,
        48.9539903
    ],
    [
        86.4018267,
        48.9536451
    ],
    [
        86.4020627,
        48.9533633
    ],
    [
        86.4025026,
        48.9529687
    ],
    [
        86.4033829,
        48.9523107
    ],
    [
        86.4039939,
        48.9519049
    ],
    [
        86.4046377,
        48.9514117
    ],
    [
        86.4048415,
        48.9512989
    ],
    [
        86.4051639,
        48.9512257
    ],
    [
        86.4054965,
        48.9511975
    ],
    [
        86.4059686,
        48.9511975
    ],
    [
        86.4062153,
        48.9511411
    ],
    [
        86.4065689,
        48.9510101
    ],
    [
        86.40688,
        48.9507353
    ],
    [
        86.4071697,
        48.9503478
    ],
    [
        86.407299,
        48.9499363
    ],
    [
        86.4072775,
        48.9492317
    ],
    [
        86.4073633,
        48.9489569
    ],
    [
        86.407535,
        48.9487667
    ],
    [
        86.4077168,
        48.9486356
    ],
    [
        86.4083928,
        48.9483115
    ],
    [
        86.4086824,
        48.948086
    ],
    [
        86.4090799,
        48.9478366
    ],
    [
        86.4096057,
        48.947597
    ],
    [
        86.4100235,
        48.9472193
    ],
    [
        86.4108818,
        48.9465993
    ],
    [
        86.4120089,
        48.9459905
    ],
    [
        86.4123088,
        48.9457396
    ],
    [
        86.4125019,
        48.945486
    ],
    [
        86.4131456,
        48.9440485
    ],
    [
        86.413446,
        48.9436891
    ],
    [
        86.4137357,
        48.9434072
    ],
    [
        86.414101,
        48.9432071
    ],
    [
        86.414895,
        48.9429463
    ],
    [
        86.415131,
        48.9428336
    ],
    [
        86.4152914,
        48.9426884
    ],
    [
        86.4155172,
        48.9423544
    ],
    [
        86.4161926,
        48.9410394
    ],
    [
        86.4163219,
        48.9406843
    ],
    [
        86.4163648,
        48.9403812
    ],
    [
        86.4162785,
        48.9400106
    ],
    [
        86.4163965,
        48.9399189
    ],
    [
        86.4166116,
        48.9398386
    ],
    [
        86.4171588,
        48.9397047
    ],
    [
        86.417663,
        48.9395426
    ],
    [
        86.418274,
        48.9391437
    ],
    [
        86.4199155,
        48.9382699
    ],
    [
        86.4220184,
        48.9370084
    ],
    [
        86.4225768,
        48.9368082
    ],
    [
        86.4237999,
        48.9364629
    ],
    [
        86.4242183,
        48.9363149
    ],
    [
        86.4246367,
        48.9360682
    ],
    [
        86.4262783,
        48.9346586
    ],
    [
        86.4272326,
        48.9336606
    ],
    [
        86.4275652,
        48.9335055
    ],
    [
        86.4278441,
        48.9334351
    ],
    [
        86.447618,
        48.9347079
    ],
    [
        86.4688611,
        48.9313529
    ],
    [
        86.4890528,
        48.9170143
    ],
    [
        86.4927863,
        48.9211457
    ],
    [
        86.4935587,
        48.9218225
    ],
    [
        86.4945029,
        48.9217943
    ],
    [
        86.495962,
        48.9222173
    ],
    [
        86.4970778,
        48.9221609
    ],
    [
        86.4983223,
        48.9225839
    ],
    [
        86.5004681,
        48.9238246
    ],
    [
        86.5026139,
        48.9238528
    ],
    [
        86.5038584,
        48.9243886
    ],
    [
        86.505575,
        48.926165
    ],
    [
        86.5072487,
        48.9291538
    ],
    [
        86.5115403,
        48.932819
    ],
    [
        86.513965,
        48.9342004
    ],
    [
        86.5146946,
        48.9352999
    ],
    [
        86.5158747,
        48.9358496
    ],
    [
        86.5170978,
        48.9374988
    ],
    [
        86.5189217,
        48.9389365
    ],
    [
        86.5282774,
        48.941699
    ],
    [
        86.5306376,
        48.9319309
    ],
    [
        86.5314102,
        48.9270532
    ],
    [
        86.5530716,
        48.9246846
    ],
    [
        86.5535008,
        48.9254248
    ],
    [
        86.5551208,
        48.9266937
    ],
    [
        86.5578567,
        48.9283502
    ],
    [
        86.5591871,
        48.9295414
    ],
    [
        86.5617727,
        48.9312755
    ],
    [
        86.5619444,
        48.9317124
    ],
    [
        86.5625881,
        48.9321
    ],
    [
        86.563028,
        48.9326709
    ],
    [
        86.5638112,
        48.9331432
    ],
    [
        86.5638648,
        48.933707
    ],
    [
        86.564691,
        48.9344964
    ],
    [
        86.5656029,
        48.9347079
    ],
    [
        86.5668475,
        48.9347431
    ],
    [
        86.5681457,
        48.9345669
    ],
    [
        86.5690576,
        48.933707
    ],
    [
        86.5697013,
        48.9333652
    ],
    [
        86.5711068,
        48.9323855
    ],
    [
        86.57292,
        48.931663
    ],
    [
        86.5742235,
        48.9314903
    ],
    [
        86.5750872,
        48.9318533
    ],
    [
        86.5760796,
        48.9319908
    ],
    [
        86.5766268,
        48.9317864
    ],
    [
        86.5770023,
        48.9314481
    ],
    [
        86.5773403,
        48.9315009
    ],
    [
        86.5779412,
        48.9311591
    ],
    [
        86.5791695,
        48.9315256
    ],
    [
        86.5799259,
        48.9316842
    ],
    [
        86.5834235,
        48.9318322
    ],
    [
        86.5850918,
        48.9317194
    ],
    [
        86.5859662,
        48.9317793
    ],
    [
        86.5864329,
        48.932093
    ],
    [
        86.5880315,
        48.9322551
    ],
    [
        86.5887128,
        48.9324383
    ],
    [
        86.590703,
        48.9323397
    ],
    [
        86.591953,
        48.9325405
    ],
    [
        86.6033256,
        48.9453562
    ],
    [
        86.613872,
        48.9512819
    ],
    [
        86.6154598,
        48.9514369
    ],
    [
        86.6171979,
        48.9513101
    ],
    [
        86.6235279,
        48.9529447
    ],
    [
        86.6258668,
        48.9537056
    ],
    [
        86.6297078,
        48.9539733
    ],
    [
        86.6321968,
        48.9551569
    ],
    [
        86.6356837,
        48.9562629
    ],
    [
        86.6396534,
        48.9563228
    ],
    [
        86.6399419,
        48.9560321
    ],
    [
        86.640498,
        48.9559012
    ],
    [
        86.6411276,
        48.955791
    ],
    [
        86.6415683,
        48.9555429
    ],
    [
        86.642051,
        48.9548884
    ],
    [
        86.6426806,
        48.9546816
    ],
    [
        86.6433102,
        48.9544129
    ],
    [
        86.6440132,
        48.953841
    ],
    [
        86.645052,
        48.953593
    ],
    [
        86.6457131,
        48.953276
    ],
    [
        86.6463217,
        48.9530831
    ],
    [
        86.6478746,
        48.9531313
    ],
    [
        86.6486406,
        48.9529659
    ],
    [
        86.6491653,
        48.9529453
    ],
    [
        86.6508442,
        48.9533242
    ],
    [
        86.6515892,
        48.9531037
    ],
    [
        86.6523237,
        48.9531037
    ],
    [
        86.6531526,
        48.9532002
    ],
    [
        86.6543383,
        48.9532622
    ],
    [
        86.6548525,
        48.9532347
    ],
    [
        86.6552198,
        48.9529935
    ],
    [
        86.6557024,
        48.952897
    ],
    [
        86.656416,
        48.9529659
    ],
    [
        86.6570665,
        48.9528074
    ],
    [
        86.6574233,
        48.9524009
    ],
    [
        86.6579479,
        48.9525663
    ],
    [
        86.6584831,
        48.9526007
    ],
    [
        86.6601935,
        48.9518428
    ],
    [
        86.6608335,
        48.9517118
    ],
    [
        86.6611483,
        48.9513535
    ],
    [
        86.66161,
        48.9511468
    ],
    [
        86.6621694,
        48.9510994
    ],
    [
        86.6627377,
        48.9511022
    ],
    [
        86.6637719,
        48.9508468
    ],
    [
        86.6639172,
        48.9506813
    ],
    [
        86.664165,
        48.9505073
    ],
    [
        86.6644171,
        48.9504792
    ],
    [
        86.6649086,
        48.9504989
    ],
    [
        86.6653316,
        48.950381
    ],
    [
        86.6654854,
        48.9501621
    ],
    [
        86.6658657,
        48.9500162
    ],
    [
        86.6665964,
        48.9495055
    ],
    [
        86.6671519,
        48.9491884
    ],
    [
        86.6675066,
        48.948961
    ],
    [
        86.6677587,
        48.9485822
    ],
    [
        86.6679681,
        48.9482875
    ],
    [
        86.6682544,
        48.9480827
    ],
    [
        86.6686005,
        48.947976
    ],
    [
        86.6689631,
        48.9479269
    ],
    [
        86.6695785,
        48.9478711
    ],
    [
        86.6701269,
        48.9477018
    ],
    [
        86.6703954,
        48.9474182
    ],
    [
        86.6710427,
        48.947237
    ],
    [
        86.671987,
        48.9469583
    ],
    [
        86.6727403,
        48.9469165
    ],
    [
        86.6729206,
        48.9468398
    ],
    [
        86.6732177,
        48.9464008
    ],
    [
        86.673483,
        48.9462684
    ],
    [
        86.6736952,
        48.9460176
    ],
    [
        86.6740771,
        48.9457667
    ],
    [
        86.6755307,
        48.9453138
    ],
    [
        86.6764962,
        48.9449723
    ],
    [
        86.6775785,
        48.9444845
    ],
    [
        86.6781196,
        48.944596
    ],
    [
        86.6787349,
        48.9446657
    ],
    [
        86.6792761,
        48.9444845
    ],
    [
        86.6799657,
        48.944387
    ],
    [
        86.6813768,
        48.9444288
    ],
    [
        86.6826607,
        48.9445403
    ],
    [
        86.6832654,
        48.9447702
    ],
    [
        86.6837004,
        48.9451535
    ],
    [
        86.6842416,
        48.9455786
    ],
    [
        86.6848039,
        48.9457179
    ],
    [
        86.6853768,
        48.9456692
    ],
    [
        86.6863742,
        48.9454392
    ],
    [
        86.6872124,
        48.9453486
    ],
    [
        86.6883583,
        48.9452511
    ],
    [
        86.6894405,
        48.9449654
    ],
    [
        86.6899498,
        48.9449723
    ],
    [
        86.6908728,
        48.9449514
    ],
    [
        86.6919232,
        48.9448817
    ],
    [
        86.6927083,
        48.9450281
    ],
    [
        86.6935677,
        48.9451744
    ],
    [
        86.6944271,
        48.9450908
    ],
    [
        86.6955836,
        48.9447911
    ],
    [
        86.6959656,
        48.9441779
    ],
    [
        86.6964642,
        48.9435925
    ],
    [
        86.6976526,
        48.9434462
    ],
    [
        86.698756,
        48.9435577
    ],
    [
        86.6997537,
        48.9438876
    ],
    [
        86.7004006,
        48.9440658
    ],
    [
        86.7010476,
        48.9439767
    ],
    [
        86.701272,
        48.943706
    ],
    [
        86.7015589,
        48.9432091
    ],
    [
        86.7021798,
        48.9426848
    ],
    [
        86.7028842,
        48.9422187
    ],
    [
        86.7034581,
        48.9419514
    ],
    [
        86.7042303,
        48.9418486
    ],
    [
        86.7049451,
        48.9416738
    ],
    [
        86.7054981,
        48.9414202
    ],
    [
        86.7061086,
        48.9411666
    ],
    [
        86.7062129,
        48.9409062
    ],
    [
        86.7061295,
        48.9406526
    ],
    [
        86.7062912,
        48.9403681
    ],
    [
        86.7062599,
        48.9401591
    ],
    [
        86.7063434,
        48.93995
    ],
    [
        86.7063225,
        48.9395011
    ],
    [
        86.706406,
        48.9389048
    ],
    [
        86.706526,
        48.938329
    ],
    [
        86.7066408,
        48.9379726
    ],
    [
        86.707126,
        48.9377121
    ],
    [
        86.7081852,
        48.9375613
    ],
    [
        86.7093487,
        48.9376401
    ],
    [
        86.7100737,
        48.9378972
    ],
    [
        86.7105538,
        48.9379432
    ],
    [
        86.7109152,
        48.9379007
    ],
    [
        86.7114114,
        48.937557
    ],
    [
        86.7113736,
        48.9373834
    ],
    [
        86.7114815,
        48.9371672
    ],
    [
        86.711789,
        48.9369972
    ],
    [
        86.7126628,
        48.9368271
    ],
    [
        86.7136068,
        48.9369795
    ],
    [
        86.7142594,
        48.9369865
    ],
    [
        86.7149661,
        48.9372735
    ],
    [
        86.7153167,
        48.9374046
    ],
    [
        86.715527,
        48.9374188
    ],
    [
        86.7157967,
        48.9373125
    ],
    [
        86.7161689,
        48.9370609
    ],
    [
        86.7164117,
        48.9370113
    ],
    [
        86.7166652,
        48.9370468
    ],
    [
        86.7170643,
        48.9370503
    ],
    [
        86.7174203,
        48.9369086
    ],
    [
        86.717566,
        48.9366606
    ],
    [
        86.7174581,
        48.9363558
    ],
    [
        86.7175444,
        48.9359802
    ],
    [
        86.7178519,
        48.935594
    ],
    [
        86.7184668,
        48.9351121
    ],
    [
        86.7187796,
        48.9348251
    ],
    [
        86.7187958,
        48.9338932
    ],
    [
        86.7187041,
        48.9334006
    ],
    [
        86.7180353,
        48.9321214
    ],
    [
        86.7176793,
        48.9314375
    ],
    [
        86.7176091,
        48.9310406
    ],
    [
        86.7177763,
        48.9307252
    ],
    [
        86.7180676,
        48.9304984
    ],
    [
        86.7183481,
        48.930144
    ],
    [
        86.7183104,
        48.9297967
    ],
    [
        86.7183373,
        48.9294317
    ],
    [
        86.7186556,
        48.9291482
    ],
    [
        86.7192004,
        48.9288505
    ],
    [
        86.7196535,
        48.9284713
    ],
    [
        86.7197398,
        48.9280461
    ],
    [
        86.7196912,
        48.9275924
    ],
    [
        86.7195186,
        48.9272806
    ],
    [
        86.7196157,
        48.9258984
    ],
    [
        86.7197991,
        48.9255972
    ],
    [
        86.7202414,
        48.9253597
    ],
    [
        86.7205003,
        48.924977
    ],
    [
        86.7211314,
        48.9246473
    ],
    [
        86.7216762,
        48.9245658
    ],
    [
        86.7220916,
        48.9244134
    ],
    [
        86.7224044,
        48.9240732
    ],
    [
        86.7226202,
        48.923857
    ],
    [
        86.7229654,
        48.9237826
    ],
    [
        86.7231326,
        48.9235451
    ],
    [
        86.7231326,
        48.9229249
    ],
    [
        86.7232729,
        48.9226484
    ],
    [
        86.7235803,
        48.9225669
    ],
    [
        86.7240671,
        48.9226145
    ],
    [
        86.7247574,
        48.9225447
    ],
    [
        86.7254238,
        48.922291
    ],
    [
        86.7257955,
        48.9218849
    ],
    [
        86.7263362,
        48.9210982
    ],
    [
        86.7269687,
        48.9205938
    ],
    [
        86.7271906,
        48.9199403
    ],
    [
        86.7272679,
        48.9194866
    ],
    [
        86.7275432,
        48.9191884
    ],
    [
        86.7293825,
        48.9182906
    ],
    [
        86.7297446,
        48.9179639
    ],
    [
        86.7299184,
        48.9176054
    ],
    [
        86.7303384,
        48.917415
    ],
    [
        86.730884,
        48.9173357
    ],
    [
        86.7315935,
        48.9173484
    ],
    [
        86.7323177,
        48.9172373
    ],
    [
        86.7327812,
        48.9172786
    ],
    [
        86.7333508,
        48.917323
    ],
    [
        86.7344323,
        48.9170914
    ],
    [
        86.7351806,
        48.9169296
    ],
    [
        86.7357647,
        48.91679
    ],
    [
        86.7359096,
        48.9165616
    ],
    [
        86.7362909,
        48.9162729
    ],
    [
        86.736682,
        48.9161777
    ],
    [
        86.7369572,
        48.916238
    ],
    [
        86.7372999,
        48.9163776
    ],
    [
        86.7376379,
        48.9162792
    ],
    [
        86.7378841,
        48.9158795
    ],
    [
        86.7381207,
        48.9155495
    ],
    [
        86.7384634,
        48.915448
    ],
    [
        86.7389945,
        48.9154639
    ],
    [
        86.7397332,
        48.9155812
    ],
    [
        86.7400374,
        48.9154163
    ],
    [
        86.7405259,
        48.9152826
    ],
    [
        86.740151,
        48.91492
    ],
    [
        86.740145,
        48.914687
    ],
    [
        86.74028,
        48.914436
    ],
    [
        86.740745,
        48.914136
    ],
    [
        86.741174,
        48.913338
    ],
    [
        86.741458,
        48.912464
    ],
    [
        86.741709,
        48.912088
    ],
    [
        86.742009,
        48.911836
    ],
    [
        86.742455,
        48.911707
    ],
    [
        86.743185,
        48.91159
    ],
    [
        86.744213,
        48.911096
    ],
    [
        86.744653,
        48.910734
    ],
    [
        86.744859,
        48.910365
    ],
    [
        86.745714,
        48.909408
    ],
    [
        86.746019,
        48.908826
    ],
    [
        86.746373,
        48.907809
    ],
    [
        86.74724,
        48.907299
    ],
    [
        86.747432,
        48.907021
    ],
    [
        86.747444,
        48.906705
    ],
    [
        86.747556,
        48.906401
    ],
    [
        86.747769,
        48.906123
    ],
    [
        86.748081,
        48.905817
    ],
    [
        86.748789,
        48.905348
    ],
    [
        86.749061,
        48.90503
    ],
    [
        86.750223,
        48.904332
    ],
    [
        86.750601,
        48.904236
    ],
    [
        86.750912,
        48.903917
    ],
    [
        86.751337,
        48.903333
    ],
    [
        86.751699,
        48.902645
    ],
    [
        86.751706,
        48.901496
    ],
    [
        86.751801,
        48.901311
    ],
    [
        86.752135,
        48.90107
    ],
    [
        86.752614,
        48.900289
    ],
    [
        86.753004,
        48.89914
    ],
    [
        86.752895,
        48.898813
    ],
    [
        86.752191,
        48.8979
    ],
    [
        86.751086,
        48.896952
    ],
    [
        86.751,
        48.89673
    ],
    [
        86.751112,
        48.896413
    ],
    [
        86.751382,
        48.896068
    ],
    [
        86.751677,
        48.895867
    ],
    [
        86.752054,
        48.895758
    ],
    [
        86.752591,
        48.895491
    ],
    [
        86.755236,
        48.894908
    ],
    [
        86.755474,
        48.8948
    ],
    [
        86.756471,
        48.893921
    ],
    [
        86.756905,
        48.893718
    ],
    [
        86.757462,
        48.89358
    ],
    [
        86.757941,
        48.893535
    ],
    [
        86.758445,
        48.893688
    ],
    [
        86.759139,
        48.893443
    ],
    [
        86.7628,
        48.89273
    ],
    [
        86.763415,
        48.892499
    ],
    [
        86.76373,
        48.892312
    ],
    [
        86.764178,
        48.891859
    ],
    [
        86.764474,
        48.891711
    ],
    [
        86.764752,
        48.891655
    ],
    [
        86.765453,
        48.891702
    ],
    [
        86.766724,
        48.892082
    ],
    [
        86.767085,
        48.892118
    ],
    [
        86.768403,
        48.892036
    ],
    [
        86.7687,
        48.891915
    ],
    [
        86.768855,
        48.891729
    ],
    [
        86.768882,
        48.891242
    ],
    [
        86.768973,
        48.890912
    ],
    [
        86.76954,
        48.890406
    ],
    [
        86.769973,
        48.890164
    ],
    [
        86.770373,
        48.890146
    ],
    [
        86.771421,
        48.889713
    ],
    [
        86.77196,
        48.889667
    ],
    [
        86.773342,
        48.889717
    ],
    [
        86.773805,
        48.889843
    ],
    [
        86.774205,
        48.889812
    ],
    [
        86.77476,
        48.889648
    ],
    [
        86.775156,
        48.889472
    ],
    [
        86.775251,
        48.889313
    ],
    [
        86.775303,
        48.888984
    ],
    [
        86.775235,
        48.888722
    ],
    [
        86.775486,
        48.888364
    ],
    [
        86.776191,
        48.887627
    ],
    [
        86.777114,
        48.886972
    ],
    [
        86.778183,
        48.886578
    ],
    [
        86.778477,
        48.886351
    ],
    [
        86.779038,
        48.885634
    ],
    [
        86.779641,
        48.885009
    ],
    [
        86.780314,
        48.883239
    ],
    [
        86.780584,
        48.882867
    ],
    [
        86.780858,
        48.882654
    ],
    [
        86.781285,
        48.882175
    ],
    [
        86.781611,
        48.881645
    ],
    [
        86.781798,
        48.881183
    ],
    [
        86.781821,
        48.880285
    ],
    [
        86.782087,
        48.879589
    ],
    [
        86.782171,
        48.878732
    ],
    [
        86.782326,
        48.878531
    ],
    [
        86.782959,
        48.878207
    ],
    [
        86.78308,
        48.878041
    ],
    [
        86.783164,
        48.877208
    ],
    [
        86.783534,
        48.876382
    ],
    [
        86.783576,
        48.875267
    ],
    [
        86.783712,
        48.875031
    ],
    [
        86.783921,
        48.874853
    ],
    [
        86.785098,
        48.874203
    ],
    [
        86.785469,
        48.87407
    ],
    [
        86.786216,
        48.874003
    ],
    [
        86.786446,
        48.873941
    ],
    [
        86.786656,
        48.873786
    ],
    [
        86.78672,
        48.873492
    ],
    [
        86.78684,
        48.873327
    ],
    [
        86.787172,
        48.8731
    ],
    [
        86.787631,
        48.872907
    ],
    [
        86.788216,
        48.872771
    ],
    [
        86.789239,
        48.872372
    ],
    [
        86.789697,
        48.872156
    ],
    [
        86.789905,
        48.87193
    ],
    [
        86.790694,
        48.87144
    ],
    [
        86.791901,
        48.87125
    ],
    [
        86.793686,
        48.871106
    ],
    [
        86.794946,
        48.870869
    ],
    [
        86.796238,
        48.870513
    ],
    [
        86.796891,
        48.870283
    ],
    [
        86.797982,
        48.869742
    ],
    [
        86.798717,
        48.869229
    ],
    [
        86.799135,
        48.868872
    ],
    [
        86.799532,
        48.868398
    ],
    [
        86.799913,
        48.867641
    ],
    [
        86.800434,
        48.867131
    ],
    [
        86.80195,
        48.866526
    ],
    [
        86.802659,
        48.86633
    ],
    [
        86.803116,
        48.866114
    ],
    [
        86.803377,
        48.865888
    ],
    [
        86.803529,
        48.865581
    ],
    [
        86.803608,
        48.865193
    ],
    [
        86.803565,
        48.864924
    ],
    [
        86.803719,
        48.864711
    ],
    [
        86.804508,
        48.864197
    ],
    [
        86.804829,
        48.864193
    ],
    [
        86.805786,
        48.863982
    ],
    [
        86.806474,
        48.86374
    ],
    [
        86.806755,
        48.86356
    ],
    [
        86.806926,
        48.8633
    ],
    [
        86.806968,
        48.862889
    ],
    [
        86.806842,
        48.862062
    ],
    [
        86.80693,
        48.861916
    ],
    [
        86.808171,
        48.861217
    ],
    [
        86.809433,
        48.860313
    ],
    [
        86.809954,
        48.85987
    ],
    [
        86.810249,
        48.859739
    ],
    [
        86.810911,
        48.859705
    ],
    [
        86.811221,
        48.859642
    ],
    [
        86.811746,
        48.859362
    ],
    [
        86.813031,
        48.858842
    ],
    [
        86.813377,
        48.85865
    ],
    [
        86.814195,
        48.857691
    ],
    [
        86.814879,
        48.857281
    ],
    [
        86.815342,
        48.857087
    ],
    [
        86.815635,
        48.856887
    ],
    [
        86.815918,
        48.856789
    ],
    [
        86.816276,
        48.856554
    ],
    [
        86.816503,
        48.856312
    ],
    [
        86.816588,
        48.856106
    ],
    [
        86.816581,
        48.855849
    ],
    [
        86.816497,
        48.855628
    ],
    [
        86.816296,
        48.855391
    ],
    [
        86.816031,
        48.855232
    ],
    [
        86.815585,
        48.855083
    ],
    [
        86.815492,
        48.854999
    ],
    [
        86.815489,
        48.854896
    ],
    [
        86.81561,
        48.854578
    ],
    [
        86.816979,
        48.852501
    ],
    [
        86.817285,
        48.851832
    ],
    [
        86.817406,
        48.851386
    ],
    [
        86.817258,
        48.850429
    ],
    [
        86.817324,
        48.850183
    ],
    [
        86.817473,
        48.849919
    ],
    [
        86.818195,
        48.849007
    ],
    [
        86.818793,
        48.847533
    ],
    [
        86.818846,
        48.846811
    ],
    [
        86.818802,
        48.84655
    ],
    [
        86.81869,
        48.846416
    ],
    [
        86.817799,
        48.845991
    ],
    [
        86.81766,
        48.845739
    ],
    [
        86.817718,
        48.845255
    ],
    [
        86.818111,
        48.844632
    ],
    [
        86.818518,
        48.843789
    ],
    [
        86.818445,
        48.84333
    ],
    [
        86.818638,
        48.84202
    ],
    [
        86.818704,
        48.841813
    ],
    [
        86.819095,
        48.841436
    ],
    [
        86.819145,
        48.84107
    ],
    [
        86.818992,
        48.840787
    ],
    [
        86.818757,
        48.840552
    ],
    [
        86.818144,
        48.840163
    ],
    [
        86.817806,
        48.839676
    ],
    [
        86.81718,
        48.839255
    ],
    [
        86.816975,
        48.838837
    ],
    [
        86.817275,
        48.837486
    ],
    [
        86.817255,
        48.837177
    ],
    [
        86.817334,
        48.836692
    ],
    [
        86.817284,
        48.836178
    ],
    [
        86.817408,
        48.835907
    ],
    [
        86.817549,
        48.835754
    ],
    [
        86.818774,
        48.835185
    ],
    [
        86.818965,
        48.834715
    ],
    [
        86.819222,
        48.833126
    ],
    [
        86.819158,
        48.832992
    ],
    [
        86.818746,
        48.832761
    ],
    [
        86.818432,
        48.832297
    ],
    [
        86.817893,
        48.831987
    ],
    [
        86.81755,
        48.831333
    ],
    [
        86.817172,
        48.830727
    ],
    [
        86.816999,
        48.830547
    ],
    [
        86.815511,
        48.829877
    ],
    [
        86.815197,
        48.829841
    ],
    [
        86.814009,
        48.829562
    ],
    [
        86.813603,
        48.829265
    ],
    [
        86.812482,
        48.828764
    ],
    [
        86.811754,
        48.828582
    ],
    [
        86.810745,
        48.828206
    ],
    [
        86.81026,
        48.8281
    ],
    [
        86.809846,
        48.827923
    ],
    [
        86.809527,
        48.827737
    ],
    [
        86.808959,
        48.827259
    ],
    [
        86.808762,
        48.826691
    ],
    [
        86.808638,
        48.82655
    ],
    [
        86.808432,
        48.826505
    ],
    [
        86.808192,
        48.826523
    ],
    [
        86.80769,
        48.826656
    ],
    [
        86.806522,
        48.82667
    ],
    [
        86.806194,
        48.826571
    ],
    [
        86.80551,
        48.82623
    ],
    [
        86.805337,
        48.826066
    ],
    [
        86.805131,
        48.825989
    ],
    [
        86.804756,
        48.825938
    ],
    [
        86.804622,
        48.825868
    ],
    [
        86.804312,
        48.825539
    ],
    [
        86.804306,
        48.825341
    ],
    [
        86.804431,
        48.825054
    ],
    [
        86.804431,
        48.824741
    ],
    [
        86.804184,
        48.824104
    ],
    [
        86.803965,
        48.823862
    ],
    [
        86.802962,
        48.823442
    ],
    [
        86.801321,
        48.822922
    ],
    [
        86.800184,
        48.82276
    ],
    [
        86.799827,
        48.82266
    ],
    [
        86.798984,
        48.822227
    ],
    [
        86.79878,
        48.821868
    ],
    [
        86.798436,
        48.821593
    ],
    [
        86.797771,
        48.821216
    ],
    [
        86.797128,
        48.820979
    ],
    [
        86.795559,
        48.820544
    ],
    [
        86.7952,
        48.820373
    ],
    [
        86.794893,
        48.820121
    ],
    [
        86.794527,
        48.819694
    ],
    [
        86.794171,
        48.818953
    ],
    [
        86.793988,
        48.818745
    ],
    [
        86.793309,
        48.8182
    ],
    [
        86.791425,
        48.817232
    ],
    [
        86.789761,
        48.816518
    ],
    [
        86.789314,
        48.816372
    ],
    [
        86.788884,
        48.816132
    ],
    [
        86.78807,
        48.815501
    ],
    [
        86.787263,
        48.815091
    ],
    [
        86.786172,
        48.814649
    ],
    [
        86.784828,
        48.814013
    ],
    [
        86.783676,
        48.813587
    ],
    [
        86.780773,
        48.812188
    ],
    [
        86.779149,
        48.811671
    ],
    [
        86.776638,
        48.811036
    ],
    [
        86.776011,
        48.810729
    ],
    [
        86.77522,
        48.810238
    ],
    [
        86.774026,
        48.809914
    ],
    [
        86.772728,
        48.809643
    ],
    [
        86.770935,
        48.809407
    ],
    [
        86.76919,
        48.808973
    ],
    [
        86.768482,
        48.808946
    ],
    [
        86.768108,
        48.808846
    ],
    [
        86.767606,
        48.808607
    ],
    [
        86.767244,
        48.808285
    ],
    [
        86.76671,
        48.807511
    ],
    [
        86.766476,
        48.807374
    ],
    [
        86.766086,
        48.80732
    ],
    [
        86.765854,
        48.807241
    ],
    [
        86.765632,
        48.806883
    ],
    [
        86.765381,
        48.806734
    ],
    [
        86.764988,
        48.806611
    ],
    [
        86.763835,
        48.806027
    ],
    [
        86.762519,
        48.805099
    ],
    [
        86.761641,
        48.80469
    ],
    [
        86.761101,
        48.8043
    ],
    [
        86.760847,
        48.804081
    ],
    [
        86.760772,
        48.803919
    ],
    [
        86.760796,
        48.803488
    ],
    [
        86.761191,
        48.803064
    ],
    [
        86.761201,
        48.802761
    ],
    [
        86.76107,
        48.802471
    ],
    [
        86.760888,
        48.802287
    ],
    [
        86.760583,
        48.802127
    ],
    [
        86.760381,
        48.801839
    ],
    [
        86.760285,
        48.801572
    ],
    [
        86.760589,
        48.801032
    ],
    [
        86.760627,
        48.800795
    ],
    [
        86.760439,
        48.800377
    ],
    [
        86.760375,
        48.799959
    ],
    [
        86.760173,
        48.799658
    ],
    [
        86.759799,
        48.799593
    ],
    [
        86.759241,
        48.799215
    ],
    [
        86.759131,
        48.799053
    ],
    [
        86.759168,
        48.79847
    ],
    [
        86.758992,
        48.797797
    ],
    [
        86.758962,
        48.797354
    ],
    [
        86.758812,
        48.797041
    ],
    [
        86.75934,
        48.796267
    ],
    [
        86.759386,
        48.79601
    ],
    [
        86.759378,
        48.795684
    ],
    [
        86.759263,
        48.795399
    ],
    [
        86.759199,
        48.79498
    ],
    [
        86.759242,
        48.794595
    ],
    [
        86.759198,
        48.794281
    ],
    [
        86.758992,
        48.79383
    ],
    [
        86.758682,
        48.793484
    ],
    [
        86.757585,
        48.792797
    ],
    [
        86.757348,
        48.792532
    ],
    [
        86.757236,
        48.792301
    ],
    [
        86.757245,
        48.791963
    ],
    [
        86.757618,
        48.791341
    ],
    [
        86.75771,
        48.790851
    ],
    [
        86.75736,
        48.789216
    ],
    [
        86.757066,
        48.788812
    ],
    [
        86.756776,
        48.788524
    ],
    [
        86.756453,
        48.788365
    ],
    [
        86.754903,
        48.787893
    ],
    [
        86.754512,
        48.787828
    ],
    [
        86.75384,
        48.787835
    ],
    [
        86.753307,
        48.787702
    ],
    [
        86.753382,
        48.787235
    ],
    [
        86.753969,
        48.786017
    ],
    [
        86.754008,
        48.785469
    ],
    [
        86.753751,
        48.785122
    ],
    [
        86.753262,
        48.784662
    ],
    [
        86.752327,
        48.783947
    ],
    [
        86.751454,
        48.78299
    ],
    [
        86.751076,
        48.782086
    ],
    [
        86.751115,
        48.781549
    ],
    [
        86.751318,
        48.781209
    ],
    [
        86.751885,
        48.780585
    ],
    [
        86.75195,
        48.780363
    ],
    [
        86.75194,
        48.779956
    ],
    [
        86.751862,
        48.7797
    ],
    [
        86.751348,
        48.778972
    ],
    [
        86.751209,
        48.778088
    ],
    [
        86.751221,
        48.777878
    ],
    [
        86.751323,
        48.777725
    ],
    [
        86.752574,
        48.776884
    ],
    [
        86.752798,
        48.776684
    ],
    [
        86.753183,
        48.775864
    ],
    [
        86.754135,
        48.77442
    ],
    [
        86.756691,
        48.772412
    ],
    [
        86.757409,
        48.771666
    ],
    [
        86.7577,
        48.771134
    ],
    [
        86.758629,
        48.76807
    ],
    [
        86.760522,
        48.766069
    ],
    [
        86.760829,
        48.765638
    ],
    [
        86.761084,
        48.765166
    ],
    [
        86.761165,
        48.764896
    ],
    [
        86.761234,
        48.764124
    ],
    [
        86.761321,
        48.763803
    ],
    [
        86.761467,
        48.76355
    ],
    [
        86.764317,
        48.761169
    ],
    [
        86.766215,
        48.759864
    ],
    [
        86.767602,
        48.758341
    ],
    [
        86.767891,
        48.757775
    ],
    [
        86.768229,
        48.756339
    ],
    [
        86.768546,
        48.755686
    ],
    [
        86.768636,
        48.754527
    ],
    [
        86.769014,
        48.753867
    ],
    [
        86.769465,
        48.753253
    ],
    [
        86.769964,
        48.7521
    ],
    [
        86.770921,
        48.751815
    ],
    [
        86.771512,
        48.75154
    ],
    [
        86.772455,
        48.7512
    ],
    [
        86.773463,
        48.75012
    ],
    [
        86.773493,
        48.749935
    ],
    [
        86.773428,
        48.749683
    ],
    [
        86.773306,
        48.7495
    ],
    [
        86.773092,
        48.749325
    ],
    [
        86.772716,
        48.749215
    ],
    [
        86.772146,
        48.748384
    ],
    [
        86.772022,
        48.748086
    ],
    [
        86.771996,
        48.747548
    ],
    [
        86.772191,
        48.747247
    ],
    [
        86.772602,
        48.746927
    ],
    [
        86.772867,
        48.746378
    ],
    [
        86.773305,
        48.745774
    ],
    [
        86.773556,
        48.745556
    ],
    [
        86.774257,
        48.745202
    ],
    [
        86.774768,
        48.743747
    ],
    [
        86.775031,
        48.742715
    ],
    [
        86.775438,
        48.739991
    ],
    [
        86.776313,
        48.738298
    ],
    [
        86.776623,
        48.737372
    ],
    [
        86.777728,
        48.736038
    ],
    [
        86.777837,
        48.735753
    ],
    [
        86.777842,
        48.735492
    ],
    [
        86.77764,
        48.734941
    ],
    [
        86.776564,
        48.73386
    ],
    [
        86.776406,
        48.733632
    ],
    [
        86.776305,
        48.733318
    ],
    [
        86.776325,
        48.732757
    ],
    [
        86.777047,
        48.731911
    ],
    [
        86.777136,
        48.731733
    ],
    [
        86.777174,
        48.731433
    ],
    [
        86.777115,
        48.730958
    ],
    [
        86.776822,
        48.730441
    ],
    [
        86.776818,
        48.729865
    ],
    [
        86.77674,
        48.729559
    ],
    [
        86.776483,
        48.72907
    ],
    [
        86.776368,
        48.728703
    ],
    [
        86.776139,
        48.728421
    ],
    [
        86.775926,
        48.728301
    ],
    [
        86.775738,
        48.728234
    ],
    [
        86.775528,
        48.728221
    ],
    [
        86.774176,
        48.727745
    ],
    [
        86.77395,
        48.72754
    ],
    [
        86.773839,
        48.727342
    ],
    [
        86.773819,
        48.727004
    ],
    [
        86.773668,
        48.726591
    ],
    [
        86.773322,
        48.726045
    ],
    [
        86.772441,
        48.72488
    ],
    [
        86.772327,
        48.724543
    ],
    [
        86.772353,
        48.724228
    ],
    [
        86.772483,
        48.723827
    ],
    [
        86.773125,
        48.723036
    ],
    [
        86.773032,
        48.722176
    ],
    [
        86.773197,
        48.721172
    ],
    [
        86.773135,
        48.720619
    ],
    [
        86.773001,
        48.720398
    ],
    [
        86.772799,
        48.720224
    ],
    [
        86.772149,
        48.719886
    ],
    [
        86.771927,
        48.719842
    ],
    [
        86.771683,
        48.71986
    ],
    [
        86.771143,
        48.720128
    ],
    [
        86.770516,
        48.720212
    ],
    [
        86.769977,
        48.72008
    ],
    [
        86.769729,
        48.719929
    ],
    [
        86.769467,
        48.719709
    ],
    [
        86.769094,
        48.718408
    ],
    [
        86.768732,
        48.717604
    ],
    [
        86.768574,
        48.71736
    ],
    [
        86.768324,
        48.717133
    ],
    [
        86.767981,
        48.716968
    ],
    [
        86.766818,
        48.716566
    ],
    [
        86.766334,
        48.716326
    ],
    [
        86.765884,
        48.716024
    ],
    [
        86.765272,
        48.715778
    ],
    [
        86.762925,
        48.714222
    ],
    [
        86.762708,
        48.714003
    ],
    [
        86.762183,
        48.713064
    ],
    [
        86.761715,
        48.71254
    ],
    [
        86.761179,
        48.7121
    ],
    [
        86.759991,
        48.711622
    ],
    [
        86.756375,
        48.709459
    ],
    [
        86.756139,
        48.709362
    ],
    [
        86.754102,
        48.707611
    ],
    [
        86.7512922,
        48.7065729
    ],
    [
        86.74891,
        48.704825
    ],
    [
        86.748731,
        48.704643
    ],
    [
        86.748596,
        48.704391
    ],
    [
        86.748518,
        48.704077
    ],
    [
        86.7477908,
        48.7027335
    ],
    [
        86.747622,
        48.702495
    ],
    [
        86.7459358,
        48.7019796
    ],
    [
        86.7454362,
        48.7014644
    ],
    [
        86.744854,
        48.70036
    ],
    [
        86.744327,
        48.699677
    ],
    [
        86.743956,
        48.699274
    ],
    [
        86.743591,
        48.698687
    ],
    [
        86.743233,
        48.698368
    ],
    [
        86.742645,
        48.698129
    ],
    [
        86.742376,
        48.698071
    ],
    [
        86.740728,
        48.698193
    ],
    [
        86.739902,
        48.698171
    ],
    [
        86.739815,
        48.697973
    ],
    [
        86.739855,
        48.697726
    ],
    [
        86.739731,
        48.697421
    ],
    [
        86.739416,
        48.696956
    ],
    [
        86.739116,
        48.696621
    ],
    [
        86.738139,
        48.695741
    ],
    [
        86.737533,
        48.694844
    ],
    [
        86.737294,
        48.694616
    ],
    [
        86.736975,
        48.694413
    ],
    [
        86.735999,
        48.694047
    ],
    [
        86.735763,
        48.693912
    ],
    [
        86.735607,
        48.693752
    ],
    [
        86.735374,
        48.693278
    ],
    [
        86.735368,
        48.693056
    ],
    [
        86.735257,
        48.692803
    ],
    [
        86.735329,
        48.692283
    ],
    [
        86.7352,
        48.69137
    ],
    [
        86.73469,
        48.690531
    ],
    [
        86.733732,
        48.689958
    ],
    [
        86.732956,
        48.689628
    ],
    [
        86.732558,
        48.689355
    ],
    [
        86.731698,
        48.688482
    ],
    [
        86.731085,
        48.687743
    ],
    [
        86.73073,
        48.687509
    ],
    [
        86.730046,
        48.687164
    ],
    [
        86.729672,
        48.687106
    ],
    [
        86.729437,
        48.687009
    ],
    [
        86.729117,
        48.686782
    ],
    [
        86.728648,
        48.686642
    ],
    [
        86.727798,
        48.686175
    ],
    [
        86.727488,
        48.685894
    ],
    [
        86.727134,
        48.685706
    ],
    [
        86.7267,
        48.68555
    ],
    [
        86.726336,
        48.685292
    ],
    [
        86.726133,
        48.685095
    ],
    [
        86.72598,
        48.684559
    ],
    [
        86.72603,
        48.683805
    ],
    [
        86.726171,
        48.683412
    ],
    [
        86.726156,
        48.683266
    ],
    [
        86.726037,
        48.68316
    ],
    [
        86.725754,
        48.683017
    ],
    [
        86.724591,
        48.682608
    ],
    [
        86.723952,
        48.682169
    ],
    [
        86.723,
        48.681788
    ],
    [
        86.722173,
        48.681321
    ],
    [
        86.721353,
        48.680731
    ],
    [
        86.720905,
        48.68049
    ],
    [
        86.718516,
        48.679449
    ],
    [
        86.71786,
        48.67928
    ],
    [
        86.717018,
        48.678198
    ],
    [
        86.716283,
        48.677676
    ],
    [
        86.715558,
        48.677322
    ],
    [
        86.713902,
        48.676734
    ],
    [
        86.713259,
        48.676579
    ],
    [
        86.711922,
        48.676156
    ],
    [
        86.711375,
        48.67617
    ],
    [
        86.71039,
        48.675874
    ],
    [
        86.710271,
        48.675745
    ],
    [
        86.710222,
        48.675223
    ],
    [
        86.710146,
        48.675065
    ],
    [
        86.709851,
        48.67463
    ],
    [
        86.70919,
        48.67406
    ],
    [
        86.707462,
        48.673302
    ],
    [
        86.706798,
        48.673074
    ],
    [
        86.706142,
        48.67293
    ],
    [
        86.705768,
        48.672783
    ],
    [
        86.705409,
        48.67253
    ],
    [
        86.70463,
        48.671712
    ],
    [
        86.704123,
        48.671342
    ],
    [
        86.703827,
        48.671021
    ],
    [
        86.702769,
        48.670215
    ],
    [
        86.702657,
        48.669798
    ],
    [
        86.702322,
        48.669639
    ],
    [
        86.701744,
        48.669579
    ],
    [
        86.701047,
        48.66933
    ],
    [
        86.700475,
        48.668937
    ],
    [
        86.700464,
        48.668259
    ],
    [
        86.700307,
        48.667981
    ],
    [
        86.700008,
        48.666957
    ],
    [
        86.699754,
        48.666644
    ],
    [
        86.699449,
        48.665918
    ],
    [
        86.699423,
        48.6652
    ],
    [
        86.699286,
        48.664666
    ],
    [
        86.69935,
        48.664385
    ],
    [
        86.699601,
        48.663847
    ],
    [
        86.699942,
        48.663401
    ],
    [
        86.699986,
        48.663051
    ],
    [
        86.699857,
        48.662819
    ],
    [
        86.699569,
        48.662613
    ],
    [
        86.698736,
        48.662436
    ],
    [
        86.698503,
        48.662275
    ],
    [
        86.698032,
        48.661791
    ],
    [
        86.697699,
        48.661178
    ],
    [
        86.697747,
        48.660968
    ],
    [
        86.697935,
        48.660709
    ],
    [
        86.698177,
        48.660544
    ],
    [
        86.698787,
        48.660269
    ],
    [
        86.69915,
        48.659962
    ],
    [
        86.699267,
        48.657981
    ],
    [
        86.699221,
        48.657221
    ],
    [
        86.698954,
        48.655781
    ],
    [
        86.698765,
        48.655191
    ],
    [
        86.698372,
        48.65447
    ],
    [
        86.698039,
        48.654005
    ],
    [
        86.697801,
        48.653061
    ],
    [
        86.69759,
        48.652656
    ],
    [
        86.696915,
        48.652734
    ],
    [
        86.695936,
        48.652555
    ],
    [
        86.69473,
        48.652158
    ],
    [
        86.694207,
        48.651608
    ],
    [
        86.6942,
        48.651344
    ],
    [
        86.694873,
        48.650796
    ],
    [
        86.69418,
        48.649662
    ],
    [
        86.693887,
        48.64946
    ],
    [
        86.693353,
        48.649378
    ],
    [
        86.69269,
        48.649415
    ],
    [
        86.692179,
        48.649376
    ],
    [
        86.691754,
        48.649205
    ],
    [
        86.691234,
        48.648772
    ],
    [
        86.690831,
        48.648615
    ],
    [
        86.690058,
        48.648726
    ],
    [
        86.689344,
        48.648514
    ],
    [
        86.689007,
        48.648328
    ],
    [
        86.689047,
        48.648152
    ],
    [
        86.689289,
        48.648091
    ],
    [
        86.689328,
        48.647871
    ],
    [
        86.688947,
        48.647637
    ],
    [
        86.688752,
        48.647347
    ],
    [
        86.688708,
        48.647179
    ],
    [
        86.68877,
        48.647011
    ],
    [
        86.689362,
        48.646553
    ],
    [
        86.68962,
        48.646188
    ],
    [
        86.689885,
        48.645548
    ],
    [
        86.69004,
        48.645325
    ],
    [
        86.690513,
        48.644957
    ],
    [
        86.690877,
        48.644546
    ],
    [
        86.691774,
        48.643195
    ],
    [
        86.691688,
        48.642957
    ],
    [
        86.691509,
        48.642764
    ],
    [
        86.689899,
        48.642065
    ],
    [
        86.688836,
        48.641308
    ],
    [
        86.688603,
        48.641089
    ],
    [
        86.688395,
        48.640826
    ],
    [
        86.688387,
        48.640516
    ],
    [
        86.68853,
        48.640329
    ],
    [
        86.688927,
        48.640157
    ],
    [
        86.688992,
        48.640085
    ],
    [
        86.68895,
        48.640015
    ],
    [
        86.687535,
        48.639624
    ],
    [
        86.686617,
        48.639524
    ],
    [
        86.685534,
        48.639041
    ],
    [
        86.684472,
        48.638831
    ],
    [
        86.683564,
        48.638912
    ],
    [
        86.683245,
        48.639039
    ],
    [
        86.682925,
        48.638882
    ],
    [
        86.682212,
        48.638969
    ],
    [
        86.682003,
        48.638932
    ],
    [
        86.681584,
        48.63847
    ],
    [
        86.680985,
        48.637952
    ],
    [
        86.681243,
        48.637523
    ],
    [
        86.681258,
        48.637332
    ],
    [
        86.681087,
        48.637249
    ],
    [
        86.680732,
        48.637338
    ],
    [
        86.680413,
        48.637295
    ],
    [
        86.678831,
        48.636841
    ],
    [
        86.678571,
        48.636675
    ],
    [
        86.678217,
        48.636307
    ],
    [
        86.678353,
        48.635824
    ],
    [
        86.678596,
        48.635679
    ],
    [
        86.678803,
        48.635286
    ],
    [
        86.6781129,
        48.634547
    ],
    [
        86.6776629,
        48.63418
    ],
    [
        86.6770089,
        48.633719
    ],
    [
        86.6767249,
        48.633598
    ],
    [
        86.6750489,
        48.633483
    ],
    [
        86.6743669,
        48.633508
    ],
    [
        86.6731269,
        48.633707
    ],
    [
        86.6727829,
        48.633853
    ],
    [
        86.6723569,
        48.633919
    ],
    [
        86.6708879,
        48.633971
    ],
    [
        86.6699489,
        48.633858
    ],
    [
        86.6693439,
        48.633367
    ],
    [
        86.6692829,
        48.633042
    ],
    [
        86.6693829,
        48.632776
    ],
    [
        86.6699529,
        48.632082
    ],
    [
        86.6699219,
        48.631906
    ],
    [
        86.6698109,
        48.631731
    ],
    [
        86.6695399,
        48.631566
    ],
    [
        86.6693479,
        48.631374
    ],
    [
        86.6691589,
        48.631279
    ],
    [
        86.6686619,
        48.631144
    ],
    [
        86.667338,
        48.631017
    ],
    [
        86.66674,
        48.63112
    ],
    [
        86.6660189,
        48.63111
    ],
    [
        86.6657369,
        48.631034
    ],
    [
        86.664941,
        48.630663
    ],
    [
        86.6644559,
        48.630492
    ],
    [
        86.6639069,
        48.630436
    ],
    [
        86.6637259,
        48.63051
    ],
    [
        86.6633419,
        48.63047
    ],
    [
        86.6623079,
        48.630463
    ],
    [
        86.6617309,
        48.630263
    ],
    [
        86.6606179,
        48.630103
    ],
    [
        86.6596389,
        48.630022
    ],
    [
        86.6584499,
        48.630232
    ],
    [
        86.6575159,
        48.629699
    ],
    [
        86.6571079,
        48.629584
    ],
    [
        86.6561159,
        48.629479
    ],
    [
        86.6555849,
        48.629462
    ],
    [
        86.6541759,
        48.629522
    ],
    [
        86.6528719,
        48.629715
    ],
    [
        86.6519939,
        48.629381
    ],
    [
        86.6509319,
        48.629243
    ],
    [
        86.6492839,
        48.629149
    ],
    [
        86.6484209,
        48.629329
    ],
    [
        86.6475659,
        48.629375
    ],
    [
        86.6463749,
        48.629624
    ],
    [
        86.6455549,
        48.629692
    ],
    [
        86.6449109,
        48.629647
    ],
    [
        86.6447509,
        48.629544
    ],
    [
        86.6447029,
        48.629426
    ],
    [
        86.6438299,
        48.629196
    ],
    [
        86.6433229,
        48.629209
    ],
    [
        86.6422049,
        48.629094
    ],
    [
        86.6416169,
        48.629004
    ],
    [
        86.6412509,
        48.628902
    ],
    [
        86.6403329,
        48.628823
    ],
    [
        86.6400889,
        48.628528
    ],
    [
        86.6394989,
        48.62834
    ],
    [
        86.6390609,
        48.627935
    ],
    [
        86.6388449,
        48.627524
    ],
    [
        86.6377389,
        48.626985
    ],
    [
        86.6371499,
        48.626857
    ],
    [
        86.6365309,
        48.626871
    ],
    [
        86.6356269,
        48.626783
    ],
    [
        86.6350179,
        48.62646
    ],
    [
        86.6348139,
        48.626061
    ],
    [
        86.6345019,
        48.625734
    ],
    [
        86.6341289,
        48.625537
    ],
    [
        86.6334949,
        48.62444
    ],
    [
        86.6333549,
        48.62404
    ],
    [
        86.6331889,
        48.62306
    ],
    [
        86.6330929,
        48.621785
    ],
    [
        86.6332809,
        48.621496
    ],
    [
        86.6335779,
        48.621207
    ],
    [
        86.6336589,
        48.620976
    ],
    [
        86.6336109,
        48.620762
    ],
    [
        86.6329969,
        48.620467
    ],
    [
        86.6327409,
        48.620222
    ],
    [
        86.6327329,
        48.619876
    ],
    [
        86.6331129,
        48.619098
    ],
    [
        86.6337509,
        48.618663
    ],
    [
        86.6333489,
        48.61753
    ],
    [
        86.6336129,
        48.617066
    ],
    [
        86.6333009,
        48.616559
    ],
    [
        86.6332849,
        48.615867
    ],
    [
        86.6338109,
        48.615511
    ],
    [
        86.6340189,
        48.615165
    ],
    [
        86.6341669,
        48.61461
    ],
    [
        86.6343309,
        48.613304
    ],
    [
        86.6343379,
        48.612701
    ],
    [
        86.6338749,
        48.611459
    ],
    [
        86.6332619,
        48.610304
    ],
    [
        86.6330149,
        48.609976
    ],
    [
        86.6314609,
        48.608444
    ],
    [
        86.6294269,
        48.606914
    ],
    [
        86.6293769,
        48.606642
    ],
    [
        86.6294429,
        48.605781
    ],
    [
        86.6294119,
        48.605366
    ],
    [
        86.6291419,
        48.604953
    ],
    [
        86.6280759,
        48.603903
    ],
    [
        86.6277039,
        48.603749
    ],
    [
        86.6262199,
        48.603391
    ],
    [
        86.6256949,
        48.603167
    ],
    [
        86.6250079,
        48.602572
    ],
    [
        86.623875,
        48.601836
    ],
    [
        86.6232529,
        48.601197
    ],
    [
        86.6231509,
        48.600764
    ],
    [
        86.6227109,
        48.600436
    ],
    [
        86.6223359,
        48.600195
    ],
    [
        86.6218799,
        48.600037
    ],
    [
        86.6214269,
        48.599967
    ],
    [
        86.6212059,
        48.599653
    ],
    [
        86.6205749,
        48.599297
    ],
    [
        86.6205429,
        48.599017
    ],
    [
        86.6194089,
        48.598545
    ],
    [
        86.6190629,
        48.598276
    ],
    [
        86.6182199,
        48.597816
    ],
    [
        86.6181139,
        48.597896
    ],
    [
        86.6178909,
        48.597947
    ],
    [
        86.6170949,
        48.597989
    ],
    [
        86.6166449,
        48.597927
    ],
    [
        86.6161919,
        48.597751
    ],
    [
        86.6147919,
        48.597534
    ],
    [
        86.6142929,
        48.597523
    ],
    [
        86.6138009,
        48.597792
    ],
    [
        86.6135279,
        48.597795
    ],
    [
        86.6133059,
        48.597912
    ],
    [
        86.6130299,
        48.597816
    ],
    [
        86.6119179,
        48.597136
    ],
    [
        86.6116029,
        48.596463
    ],
    [
        86.6114879,
        48.595822
    ],
    [
        86.6112299,
        48.595491
    ],
    [
        86.6109249,
        48.594767
    ],
    [
        86.6105299,
        48.594113
    ],
    [
        86.6104619,
        48.593869
    ],
    [
        86.610579,
        48.592032
    ],
    [
        86.6105139,
        48.591435
    ],
    [
        86.610416,
        48.591212
    ],
    [
        86.6102369,
        48.59101
    ],
    [
        86.60982,
        48.590729
    ],
    [
        86.6096869,
        48.590252
    ],
    [
        86.6097209,
        48.589485
    ],
    [
        86.6101329,
        48.588508
    ],
    [
        86.6101739,
        48.588161
    ],
    [
        86.6100929,
        48.587866
    ],
    [
        86.6091869,
        48.587463
    ],
    [
        86.6090579,
        48.587283
    ],
    [
        86.6090509,
        48.58697
    ],
    [
        86.6089479,
        48.586856
    ],
    [
        86.608598,
        48.586794
    ],
    [
        86.608221,
        48.586666
    ],
    [
        86.608108,
        48.58609
    ],
    [
        86.607903,
        48.585845
    ],
    [
        86.607475,
        48.585635
    ],
    [
        86.60727,
        48.585374
    ],
    [
        86.60694,
        48.585114
    ],
    [
        86.605542,
        48.585012
    ],
    [
        86.604592,
        48.584857
    ],
    [
        86.604195,
        48.584894
    ],
    [
        86.603097,
        48.584711
    ],
    [
        86.60241,
        48.584759
    ],
    [
        86.602039,
        48.584729
    ],
    [
        86.601377,
        48.584518
    ],
    [
        86.601383,
        48.583085
    ],
    [
        86.60128,
        48.582938
    ],
    [
        86.60116,
        48.582049
    ],
    [
        86.600804,
        48.581773
    ],
    [
        86.600326,
        48.581563
    ],
    [
        86.598672,
        48.581102
    ],
    [
        86.598574,
        48.580847
    ],
    [
        86.598601,
        48.579874
    ],
    [
        86.598366,
        48.579382
    ],
    [
        86.598381,
        48.578987
    ],
    [
        86.598642,
        48.578407
    ],
    [
        86.598118,
        48.577292
    ],
    [
        86.598084,
        48.576914
    ],
    [
        86.597718,
        48.57616
    ],
    [
        86.596919,
        48.576036
    ],
    [
        86.596565,
        48.575842
    ],
    [
        86.596132,
        48.575401
    ],
    [
        86.59578,
        48.57524
    ],
    [
        86.594734,
        48.575267
    ],
    [
        86.594259,
        48.57519
    ],
    [
        86.593918,
        48.574974
    ],
    [
        86.594185,
        48.574675
    ],
    [
        86.594326,
        48.574344
    ],
    [
        86.594292,
        48.573932
    ],
    [
        86.594081,
        48.573424
    ],
    [
        86.594128,
        48.573292
    ],
    [
        86.594275,
        48.573191
    ],
    [
        86.595019,
        48.573085
    ],
    [
        86.596154,
        48.572628
    ],
    [
        86.596774,
        48.572523
    ],
    [
        86.597019,
        48.572323
    ],
    [
        86.596962,
        48.572011
    ],
    [
        86.596478,
        48.571554
    ],
    [
        86.596032,
        48.57057
    ],
    [
        86.5966733,
        48.569927
    ],
    [
        86.5972117,
        48.5693025
    ],
    [
        86.5968981,
        48.5689109
    ],
    [
        86.596474,
        48.5686179
    ],
    [
        86.5957646,
        48.5680942
    ],
    [
        86.5950301,
        48.5675926
    ],
    [
        86.5944752,
        48.566999
    ],
    [
        86.5944076,
        48.5660739
    ],
    [
        86.5946908,
        48.5654381
    ],
    [
        86.594896,
        48.56521
    ],
    [
        86.594941,
        48.564749
    ],
    [
        86.5953632,
        48.5642403
    ],
    [
        86.5956572,
        48.5638109
    ],
    [
        86.5958026,
        48.5631721
    ],
    [
        86.5956416,
        48.5626714
    ],
    [
        86.595421,
        48.561498
    ],
    [
        86.5952096,
        48.5609196
    ],
    [
        86.5952317,
        48.5604558
    ],
    [
        86.59519,
        48.559851
    ],
    [
        86.595515,
        48.559461
    ],
    [
        86.5958271,
        48.5592575
    ],
    [
        86.596498,
        48.55882
    ],
    [
        86.597088,
        48.558597
    ],
    [
        86.597351,
        48.558417
    ],
    [
        86.597429,
        48.558267
    ],
    [
        86.597085,
        48.557564
    ],
    [
        86.5965687,
        48.5554884
    ],
    [
        86.596226,
        48.554754
    ],
    [
        86.5959789,
        48.554551
    ],
    [
        86.5952664,
        48.5536961
    ],
    [
        86.5948003,
        48.5533644
    ],
    [
        86.5943618,
        48.5525492
    ],
    [
        86.5941608,
        48.5518126
    ],
    [
        86.593645,
        48.551263
    ],
    [
        86.5933239,
        48.5510916
    ],
    [
        86.5924351,
        48.5506442
    ],
    [
        86.5920619,
        48.5505698
    ],
    [
        86.5915328,
        48.5500688
    ],
    [
        86.5926114,
        48.5478637
    ],
    [
        86.5921931,
        48.5463577
    ],
    [
        86.5922606,
        48.5452011
    ],
    [
        86.5921991,
        48.5449434
    ],
    [
        86.5916613,
        48.5442313
    ],
    [
        86.5905673,
        48.5442314
    ],
    [
        86.5898628,
        48.544075
    ],
    [
        86.5894033,
        48.543459
    ],
    [
        86.5893325,
        48.5428159
    ],
    [
        86.589096,
        48.542257
    ],
    [
        86.588783,
        48.541948
    ],
    [
        86.588473,
        48.541748
    ],
    [
        86.58822,
        48.541659
    ],
    [
        86.588113,
        48.541456
    ],
    [
        86.5873723,
        48.541046
    ],
    [
        86.5873599,
        48.5406266
    ],
    [
        86.5873692,
        48.5406046
    ],
    [
        86.5868647,
        48.5395766
    ],
    [
        86.5854302,
        48.5380353
    ],
    [
        86.5841717,
        48.5374522
    ],
    [
        86.5833792,
        48.5375841
    ],
    [
        86.5808486,
        48.5391137
    ],
    [
        86.5359073,
        48.5234932
    ],
    [
        86.4069771,
        48.478654
    ],
    [
        86.4061885,
        48.4786504
    ],
    [
        86.4059739,
        48.478718
    ],
    [
        86.4057111,
        48.4788247
    ],
    [
        86.4057701,
        48.4788816
    ],
    [
        86.4055179,
        48.4790807
    ],
    [
        86.4052229,
        48.479006
    ],
    [
        86.4052014,
        48.4788816
    ],
    [
        86.4049547,
        48.478942
    ],
    [
        86.4048313,
        48.4789029
    ],
    [
        86.4046006,
        48.4789242
    ],
    [
        86.4045738,
        48.4789669
    ],
    [
        86.4046811,
        48.4790096
    ],
    [
        86.4045913,
        48.4790283
    ],
    [
        86.4044451,
        48.4790398
    ],
    [
        86.4044075,
        48.478878
    ],
    [
        86.4042037,
        48.478846
    ],
    [
        86.4039837,
        48.4790238
    ],
    [
        86.4039059,
        48.4790967
    ],
    [
        86.4037329,
        48.479126
    ],
    [
        86.4037262,
        48.4790674
    ],
    [
        86.4035908,
        48.4790487
    ],
    [
        86.4035868,
        48.4791376
    ],
    [
        86.4035036,
        48.4792123
    ],
    [
        86.4034017,
        48.4791963
    ],
    [
        86.4033507,
        48.4792158
    ],
    [
        86.4034392,
        48.479271
    ],
    [
        86.4031442,
        48.4793065
    ],
    [
        86.4031388,
        48.4792532
    ],
    [
        86.4030476,
        48.4792674
    ],
    [
        86.4029243,
        48.4794648
    ],
    [
        86.4025836,
        48.4794985
    ],
    [
        86.4025997,
        48.4795946
    ],
    [
        86.4025353,
        48.4797155
    ],
    [
        86.4024334,
        48.4797066
    ],
    [
        86.4024173,
        48.479655
    ],
    [
        86.402302,
        48.4796639
    ],
    [
        86.4022618,
        48.4797955
    ],
    [
        86.4021706,
        48.4798524
    ],
    [
        86.401838,
        48.4799466
    ],
    [
        86.401618,
        48.4801084
    ],
    [
        86.401551,
        48.4801671
    ],
    [
        86.4014061,
        48.4801439
    ],
    [
        86.4012586,
        48.480192
    ],
    [
        86.401213,
        48.480256
    ],
    [
        86.4010199,
        48.4802897
    ],
    [
        86.400977,
        48.4803324
    ],
    [
        86.4010467,
        48.4804302
    ],
    [
        86.4009475,
        48.4805067
    ],
    [
        86.4007865,
        48.4805173
    ],
    [
        86.4005773,
        48.4805884
    ],
    [
        86.4000945,
        48.4804889
    ],
    [
        86.3997619,
        48.4804355
    ],
    [
        86.399601,
        48.4804747
    ],
    [
        86.399483,
        48.480496
    ],
    [
        86.3990324,
        48.4803538
    ],
    [
        86.3988124,
        48.4803751
    ],
    [
        86.398732,
        48.4803217
    ],
    [
        86.3981258,
        48.4806525
    ],
    [
        86.3978254,
        48.4807911
    ],
    [
        86.3976537,
        48.4806311
    ],
    [
        86.3975357,
        48.4806489
    ],
    [
        86.397423,
        48.4809618
    ],
    [
        86.3972353,
        48.4809654
    ],
    [
        86.3970958,
        48.4810472
    ],
    [
        86.3968598,
        48.4808338
    ],
    [
        86.3966237,
        48.4809049
    ],
    [
        86.3967149,
        48.48104
    ],
    [
        86.3963555,
        48.481072
    ],
    [
        86.396157,
        48.4811609
    ],
    [
        86.3959478,
        48.4810827
    ],
    [
        86.3957493,
        48.4811361
    ],
    [
        86.3959156,
        48.4812854
    ],
    [
        86.395685,
        48.4813885
    ],
    [
        86.3955562,
        48.4815272
    ],
    [
        86.3953685,
        48.4816765
    ],
    [
        86.3953256,
        48.4817868
    ],
    [
        86.3954436,
        48.4818437
    ],
    [
        86.3953685,
        48.4819539
    ],
    [
        86.3952022,
        48.4819397
    ],
    [
        86.39517,
        48.481993
    ],
    [
        86.3953631,
        48.482089
    ],
    [
        86.3952183,
        48.4821495
    ],
    [
        86.3951861,
        48.4822419
    ],
    [
        86.3953953,
        48.4822988
    ],
    [
        86.3953202,
        48.4826153
    ],
    [
        86.395288,
        48.4827717
    ],
    [
        86.3951807,
        48.482786
    ],
    [
        86.3951914,
        48.4830029
    ],
    [
        86.3953148,
        48.4830384
    ],
    [
        86.3953524,
        48.4831664
    ],
    [
        86.3952022,
        48.4832944
    ],
    [
        86.3952183,
        48.4834118
    ],
    [
        86.3951914,
        48.4836216
    ],
    [
        86.3954436,
        48.4836856
    ],
    [
        86.3954758,
        48.4837816
    ],
    [
        86.3953363,
        48.4839665
    ],
    [
        86.3954328,
        48.484098
    ],
    [
        86.3955777,
        48.4842118
    ],
    [
        86.3954758,
        48.4844003
    ],
    [
        86.3952826,
        48.4843718
    ],
    [
        86.3951432,
        48.4844714
    ],
    [
        86.3951432,
        48.4847345
    ],
    [
        86.3950037,
        48.4848518
    ],
    [
        86.3950144,
        48.4851398
    ],
    [
        86.3947355,
        48.485339
    ],
    [
        86.3944887,
        48.4855238
    ],
    [
        86.3938933,
        48.485755
    ],
    [
        86.3937591,
        48.4858723
    ],
    [
        86.393786,
        48.4860678
    ],
    [
        86.393389,
        48.4862598
    ],
    [
        86.3929598,
        48.4864447
    ],
    [
        86.3929759,
        48.4867078
    ],
    [
        86.3928204,
        48.4867612
    ],
    [
        86.392638,
        48.4866723
    ],
    [
        86.3925307,
        48.4866936
    ],
    [
        86.3925736,
        48.4868643
    ],
    [
        86.392359,
        48.4869674
    ],
    [
        86.3920908,
        48.4869425
    ],
    [
        86.3911467,
        48.4873976
    ],
    [
        86.3911252,
        48.4875967
    ],
    [
        86.3909804,
        48.4876429
    ],
    [
        86.3908677,
        48.4875683
    ],
    [
        86.3906531,
        48.4875896
    ],
    [
        86.3904922,
        48.4877638
    ],
    [
        86.390106,
        48.4882616
    ],
    [
        86.3900684,
        48.4883682
    ],
    [
        86.390342,
        48.4883789
    ],
    [
        86.3905459,
        48.4887878
    ],
    [
        86.3904654,
        48.4892997
    ],
    [
        86.3908516,
        48.4894633
    ],
    [
        86.3908892,
        48.4895877
    ],
    [
        86.390798,
        48.4896233
    ],
    [
        86.3904922,
        48.4895557
    ],
    [
        86.3903957,
        48.4896268
    ],
    [
        86.3904654,
        48.4898472
    ],
    [
        86.3901918,
        48.4898295
    ],
    [
        86.3900362,
        48.4898828
    ],
    [
        86.3901757,
        48.4900143
    ],
    [
        86.3901328,
        48.4901175
    ],
    [
        86.3893603,
        48.4899752
    ],
    [
        86.3891779,
        48.4900748
    ],
    [
        86.3891672,
        48.4904019
    ],
    [
        86.3887917,
        48.4907929
    ],
    [
        86.3882338,
        48.4907858
    ],
    [
        86.388148,
        48.4906436
    ],
    [
        86.3879763,
        48.4906401
    ],
    [
        86.3878905,
        48.4907609
    ],
    [
        86.387515,
        48.4908249
    ],
    [
        86.3867908,
        48.4908285
    ],
    [
        86.3865816,
        48.4909565
    ],
    [
        86.3859164,
        48.4910418
    ],
    [
        86.3856857,
        48.4914187
    ],
    [
        86.3851707,
        48.4917244
    ],
    [
        86.3848113,
        48.491696
    ],
    [
        86.3843768,
        48.4919128
    ],
    [
        86.3840335,
        48.4917493
    ],
    [
        86.3840388,
        48.491632
    ],
    [
        86.3836097,
        48.4915324
    ],
    [
        86.3833361,
        48.4915964
    ],
    [
        86.3830249,
        48.4920835
    ],
    [
        86.3824778,
        48.492311
    ],
    [
        86.3820701,
        48.4922719
    ],
    [
        86.3814639,
        48.492375
    ],
    [
        86.381244,
        48.4929083
    ],
    [
        86.3810884,
        48.4929616
    ],
    [
        86.3808899,
        48.4928443
    ],
    [
        86.3800209,
        48.4928478
    ],
    [
        86.3796293,
        48.4923377
    ],
    [
        86.3788595,
        48.4926238
    ],
    [
        86.3789882,
        48.4929065
    ],
    [
        86.3785457,
        48.4931536
    ],
    [
        86.3783713,
        48.4936121
    ],
    [
        86.3783069,
        48.4938326
    ],
    [
        86.3781675,
        48.4938859
    ],
    [
        86.3778161,
        48.4937544
    ],
    [
        86.3773387,
        48.4939605
    ],
    [
        86.3774406,
        48.4942272
    ],
    [
        86.3777356,
        48.4941987
    ],
    [
        86.3782721,
        48.4942663
    ],
    [
        86.3781755,
        48.4946929
    ],
    [
        86.3776069,
        48.4948102
    ],
    [
        86.3777625,
        48.4949453
    ],
    [
        86.3783204,
        48.4952332
    ],
    [
        86.3793235,
        48.4953967
    ],
    [
        86.3791411,
        48.4955496
    ],
    [
        86.3791089,
        48.4957629
    ],
    [
        86.3793181,
        48.495866
    ],
    [
        86.3792538,
        48.4960402
    ],
    [
        86.3788782,
        48.4959975
    ],
    [
        86.3786959,
        48.4961468
    ],
    [
        86.3787978,
        48.4968791
    ],
    [
        86.3782667,
        48.4983863
    ],
    [
        86.3783043,
        48.4988804
    ],
    [
        86.3780146,
        48.498916
    ],
    [
        86.3779878,
        48.4992394
    ],
    [
        86.3778054,
        48.4992963
    ],
    [
        86.3774084,
        48.4996731
    ],
    [
        86.377446,
        48.4997584
    ],
    [
        86.3773226,
        48.4998793
    ],
    [
        86.3767593,
        48.5000392
    ],
    [
        86.3768237,
        48.5003414
    ],
    [
        86.3767378,
        48.500416
    ],
    [
        86.3764053,
        48.5003947
    ],
    [
        86.3750427,
        48.5009776
    ],
    [
        86.3749944,
        48.5013722
    ],
    [
        86.3745009,
        48.5013331
    ],
    [
        86.3740449,
        48.5012335
    ],
    [
        86.3737606,
        48.5012371
    ],
    [
        86.373546,
        48.5013722
    ],
    [
        86.3729988,
        48.5013651
    ],
    [
        86.3723444,
        48.5013295
    ],
    [
        86.3718616,
        48.50123
    ],
    [
        86.3701128,
        48.5014219
    ],
    [
        86.3691794,
        48.5010736
    ],
    [
        86.3681172,
        48.5009883
    ],
    [
        86.3681816,
        48.5001778
    ],
    [
        86.3683533,
        48.4998153
    ],
    [
        86.367967,
        48.4984503
    ],
    [
        86.3682138,
        48.4974479
    ],
    [
        86.3677095,
        48.497032
    ],
    [
        86.3677203,
        48.4967618
    ],
    [
        86.3678329,
        48.4965805
    ],
    [
        86.3688146,
        48.4962428
    ],
    [
        86.3690399,
        48.4955212
    ],
    [
        86.3686483,
        48.4949986
    ],
    [
        86.3682621,
        48.4947142
    ],
    [
        86.3682674,
        48.4944938
    ],
    [
        86.3685035,
        48.4942734
    ],
    [
        86.3687449,
        48.4941561
    ],
    [
        86.368675,
        48.4932887
    ],
    [
        86.3685464,
        48.4929047
    ],
    [
        86.3681011,
        48.4927909
    ],
    [
        86.3674681,
        48.4928052
    ],
    [
        86.3673984,
        48.4929882
    ],
    [
        86.3666554,
        48.4930398
    ],
    [
        86.3665884,
        48.4930256
    ],
    [
        86.3666554,
        48.4928798
    ],
    [
        86.3667869,
        48.4928674
    ],
    [
        86.3668727,
        48.4927696
    ],
    [
        86.3667627,
        48.4925865
    ],
    [
        86.3660975,
        48.4925599
    ],
    [
        86.3659473,
        48.4926523
    ],
    [
        86.3653331,
        48.4932353
    ],
    [
        86.3651158,
        48.4932495
    ],
    [
        86.3645445,
        48.4930469
    ],
    [
        86.3634716,
        48.4922257
    ],
    [
        86.3628976,
        48.4920906
    ],
    [
        86.3624363,
        48.4920906
    ],
    [
        86.3617389,
        48.4924461
    ],
    [
        86.3615511,
        48.4924355
    ],
    [
        86.3610094,
        48.4923039
    ],
    [
        86.3608431,
        48.4921439
    ],
    [
        86.360827,
        48.491959
    ],
    [
        86.3612293,
        48.4916142
    ],
    [
        86.361181,
        48.4912409
    ],
    [
        86.3608645,
        48.491184
    ],
    [
        86.3601833,
        48.4911876
    ],
    [
        86.3598828,
        48.4913369
    ],
    [
        86.3594966,
        48.4912587
    ],
    [
        86.3596978,
        48.4908943
    ],
    [
        86.3594671,
        48.4907307
    ],
    [
        86.3589521,
        48.4906578
    ],
    [
        86.3575922,
        48.4909565
    ],
    [
        86.3564657,
        48.4910703
    ],
    [
        86.3562082,
        48.4912302
    ],
    [
        86.3564443,
        48.4913013
    ],
    [
        86.3566266,
        48.4915111
    ],
    [
        86.3561653,
        48.4919697
    ],
    [
        86.3564496,
        48.4923288
    ],
    [
        86.3565515,
        48.4927838
    ],
    [
        86.3560097,
        48.4928905
    ],
    [
        86.3555055,
        48.4927838
    ],
    [
        86.3550656,
        48.4928905
    ],
    [
        86.3547813,
        48.4927803
    ],
    [
        86.3546364,
        48.4925243
    ],
    [
        86.3539766,
        48.4923963
    ],
    [
        86.3537138,
        48.4920159
    ],
    [
        86.3539122,
        48.491216
    ],
    [
        86.3535475,
        48.4908463
    ],
    [
        86.3531237,
        48.4907858
    ],
    [
        86.3523512,
        48.4908569
    ],
    [
        86.3509082,
        48.4907361
    ],
    [
        86.3502269,
        48.4908249
    ],
    [
        86.3495349,
        48.4914187
    ],
    [
        86.3490038,
        48.4915964
    ],
    [
        86.3482957,
        48.4923928
    ],
    [
        86.3474535,
        48.4930078
    ],
    [
        86.3468688,
        48.4930362
    ],
    [
        86.3465898,
        48.4929154
    ],
    [
        86.3458549,
        48.4929651
    ],
    [
        86.3444816,
        48.4929118
    ],
    [
        86.3440149,
        48.4925705
    ],
    [
        86.3417404,
        48.4912907
    ],
    [
        86.3408606,
        48.4912231
    ],
    [
        86.3404958,
        48.4910809
    ],
    [
        86.3403564,
        48.4907538
    ],
    [
        86.3399165,
        48.4905974
    ],
    [
        86.3389455,
        48.4905939
    ],
    [
        86.3383447,
        48.4908712
    ],
    [
        86.3374864,
        48.4915466
    ],
    [
        86.3370036,
        48.4913689
    ],
    [
        86.3349651,
        48.4909067
    ],
    [
        86.3324278,
        48.4907823
    ],
    [
        86.3316124,
        48.4904588
    ],
    [
        86.3312315,
        48.490505
    ],
    [
        86.3298957,
        48.4913404
    ],
    [
        86.3297509,
        48.4917351
    ],
    [
        86.3299977,
        48.4922008
    ],
    [
        86.3305341,
        48.4928905
    ],
    [
        86.3305234,
        48.4933526
    ],
    [
        86.3292788,
        48.4940281
    ],
    [
        86.3284205,
        48.4941987
    ],
    [
        86.3268756,
        48.4938575
    ],
    [
        86.3251697,
        48.4936441
    ],
    [
        86.3238822,
        48.4928052
    ],
    [
        86.3233136,
        48.4918951
    ],
    [
        86.3232063,
        48.4903308
    ],
    [
        86.3222944,
        48.4894206
    ],
    [
        86.3222729,
        48.4887664
    ],
    [
        86.3214146,
        48.4881407
    ],
    [
        86.3197194,
        48.487842
    ],
    [
        86.3182281,
        48.4876358
    ],
    [
        86.3171016,
        48.4877638
    ],
    [
        86.3150953,
        48.4884891
    ],
    [
        86.3135504,
        48.4891291
    ],
    [
        86.3123702,
        48.4892215
    ],
    [
        86.3106965,
        48.4896837
    ],
    [
        86.3101708,
        48.4895699
    ],
    [
        86.3086902,
        48.4896908
    ],
    [
        86.3078426,
        48.4895131
    ],
    [
        86.3051604,
        48.4893637
    ],
    [
        86.302446,
        48.4901103
    ],
    [
        86.3006972,
        48.4905441
    ],
    [
        86.3000106,
        48.4901814
    ],
    [
        86.2994849,
        48.490025
    ],
    [
        86.2994849,
        48.4895984
    ],
    [
        86.3002681,
        48.4889726
    ],
    [
        86.3002573,
        48.4881976
    ],
    [
        86.3008796,
        48.4873443
    ],
    [
        86.3006221,
        48.4860998
    ],
    [
        86.2998389,
        48.4849549
    ],
    [
        86.2986909,
        48.4840447
    ],
    [
        86.2963413,
        48.4823237
    ],
    [
        86.2961375,
        48.4813636
    ],
    [
        86.2971352,
        48.4791945
    ],
    [
        86.2970601,
        48.4778503
    ],
    [
        86.296867,
        48.4770182
    ],
    [
        86.2962877,
        48.4766625
    ],
    [
        86.2927793,
        48.4763283
    ],
    [
        86.2916635,
        48.4750693
    ],
    [
        86.2903546,
        48.4745288
    ],
    [
        86.2869214,
        48.4737322
    ],
    [
        86.2859129,
        48.4733196
    ],
    [
        86.2852906,
        48.4728217
    ],
    [
        86.2826728,
        48.4722953
    ],
    [
        86.2824475,
        48.4714987
    ],
    [
        86.2824367,
        48.4707162
    ],
    [
        86.2818788,
        48.4700333
    ],
    [
        86.2815355,
        48.4680558
    ],
    [
        86.2813424,
        48.4656087
    ],
    [
        86.2810634,
        48.464947
    ],
    [
        86.2813746,
        48.4639795
    ],
    [
        86.2805592,
        48.4636309
    ],
    [
        86.2796043,
        48.4619662
    ],
    [
        86.27645,
        48.4604863
    ],
    [
        86.2724588,
        48.4586935
    ],
    [
        86.2700986,
        48.4557335
    ],
    [
        86.2687467,
        48.4527592
    ],
    [
        86.2677168,
        48.4516064
    ],
    [
        86.2656783,
        48.4514214
    ],
    [
        86.2627171,
        48.4531719
    ],
    [
        86.259477,
        48.454097
    ],
    [
        86.25746,
        48.4541539
    ],
    [
        86.2510227,
        48.4525742
    ],
    [
        86.2497137,
        48.451578
    ],
    [
        86.2481903,
        48.4485465
    ],
    [
        86.2473749,
        48.4478918
    ],
    [
        86.2476538,
        48.4471374
    ],
    [
        86.2467955,
        48.4464827
    ],
    [
        86.2461518,
        48.4463546
    ],
    [
        86.2449716,
        48.4446466
    ],
    [
        86.2437485,
        48.4437783
    ],
    [
        86.2414097,
        48.4430524
    ],
    [
        86.240208,
        48.4410453
    ],
    [
        86.2375902,
        48.4392802
    ],
    [
        86.2364958,
        48.4377427
    ],
    [
        86.2367748,
        48.4361768
    ],
    [
        86.2357663,
        48.4350379
    ],
    [
        86.2357019,
        48.4338705
    ],
    [
        86.2345861,
        48.4329735
    ],
    [
        86.2322687,
        48.432219
    ],
    [
        86.2305306,
        48.4322617
    ],
    [
        86.2285994,
        48.4329308
    ],
    [
        86.2267755,
        48.432162
    ],
    [
        86.2259172,
        48.4321193
    ],
    [
        86.223986,
        48.4319485
    ],
    [
        86.222999,
        48.4321905
    ],
    [
        86.1458585,
        48.435579
    ],
    [
        86.0532742,
        48.4388602
    ],
    [
        85.962524,
        48.4369457
    ],
    [
        85.9307668,
        48.436604
    ],
    [
        85.914781,
        48.4362195
    ],
    [
        85.9117341,
        48.4362052
    ],
    [
        85.9109938,
        48.4358493
    ],
    [
        85.9097707,
        48.4355575
    ],
    [
        85.9090519,
        48.4355077
    ],
    [
        85.9082258,
        48.4354009
    ],
    [
        85.906552,
        48.4351019
    ],
    [
        85.9061229,
        48.4343474
    ],
    [
        85.9057903,
        48.4340271
    ],
    [
        85.9050286,
        48.4339915
    ],
    [
        85.9042776,
        48.4336355
    ],
    [
        85.9034836,
        48.4336
    ],
    [
        85.9028613,
        48.4331586
    ],
    [
        85.9019387,
        48.4328454
    ],
    [
        85.9015739,
        48.4324468
    ],
    [
        85.9008443,
        48.4321905
    ],
    [
        85.9002435,
        48.4317278
    ],
    [
        85.8992886,
        48.4315356
    ],
    [
        85.8978509,
        48.4307667
    ],
    [
        85.89607,
        48.43066
    ],
    [
        85.8947503,
        48.4304464
    ],
    [
        85.8927441,
        48.4305318
    ],
    [
        85.8909523,
        48.430304
    ],
    [
        85.8904266,
        48.430482
    ],
    [
        85.8892894,
        48.43108
    ],
    [
        85.8882594,
        48.4307667
    ],
    [
        85.8882594,
        48.4299837
    ],
    [
        85.8874225,
        48.4293714
    ],
    [
        85.8854055,
        48.4291152
    ],
    [
        85.8845687,
        48.4290724
    ],
    [
        85.8823585,
        48.4296135
    ],
    [
        85.8802128,
        48.4288019
    ],
    [
        85.8789897,
        48.4292006
    ],
    [
        85.8772516,
        48.4288873
    ],
    [
        85.8759211,
        48.429286
    ],
    [
        85.8747625,
        48.4288873
    ],
    [
        85.8739686,
        48.429286
    ],
    [
        85.8728742,
        48.4289158
    ],
    [
        85.8708143,
        48.4298413
    ],
    [
        85.8696985,
        48.4302542
    ],
    [
        85.8681965,
        48.4314786
    ],
    [
        85.8668875,
        48.4317491
    ],
    [
        85.8652782,
        48.4310088
    ],
    [
        85.8649564,
        48.4299694
    ],
    [
        85.8647203,
        48.4296847
    ],
    [
        85.8634328,
        48.4302115
    ],
    [
        85.8612013,
        48.4302399
    ],
    [
        85.8591199,
        48.430781
    ],
    [
        85.8575535,
        48.4300691
    ],
    [
        85.85603,
        48.4300121
    ],
    [
        85.8550215,
        48.429827
    ],
    [
        85.8532833,
        48.4296704
    ],
    [
        85.8506226,
        48.428389
    ],
    [
        85.8490562,
        48.4279903
    ],
    [
        85.8465242,
        48.4281897
    ],
    [
        85.8452581,
        48.4277127
    ],
    [
        85.8433592,
        48.4275418
    ],
    [
        85.8415997,
        48.4273923
    ],
    [
        85.8406877,
        48.4273994
    ],
    [
        85.8399153,
        48.427435
    ],
    [
        85.8393037,
        48.4269722
    ],
    [
        85.838542,
        48.4267231
    ],
    [
        85.8382952,
        48.427314
    ],
    [
        85.8375334,
        48.4275347
    ],
    [
        85.8361709,
        48.4272285
    ],
    [
        85.835849,
        48.4265095
    ],
    [
        85.8353019,
        48.4263244
    ],
    [
        85.8340573,
        48.4265451
    ],
    [
        85.8330488,
        48.4260538
    ],
    [
        85.8314395,
        48.4255626
    ],
    [
        85.8307743,
        48.424338
    ],
    [
        85.8297872,
        48.4237399
    ],
    [
        85.8299696,
        48.4223373
    ],
    [
        85.8295298,
        48.4219101
    ],
    [
        85.8285642,
        48.4224512
    ],
    [
        85.8207319,
        48.4188912
    ],
    [
        85.8057974,
        48.4157011
    ],
    [
        85.7995224,
        48.4160858
    ],
    [
        85.7979011,
        48.4161853
    ],
    [
        85.7924938,
        48.417111
    ],
    [
        85.7922792,
        48.4180366
    ],
    [
        85.7910132,
        48.4181079
    ],
    [
        85.790112,
        48.4178942
    ],
    [
        85.7895326,
        48.4168119
    ],
    [
        85.788846,
        48.4175097
    ],
    [
        85.7874298,
        48.4174528
    ],
    [
        85.7865929,
        48.4167264
    ],
    [
        85.7853054,
        48.4141344
    ],
    [
        85.783267,
        48.4141771
    ],
    [
        85.7811427,
        48.4132229
    ],
    [
        85.7803917,
        48.4133653
    ],
    [
        85.7789111,
        48.4134223
    ],
    [
        85.7777309,
        48.4130092
    ],
    [
        85.7782592,
        48.4119911
    ],
    [
        85.7768512,
        48.4113286
    ],
    [
        85.7756924,
        48.410887
    ],
    [
        85.7751989,
        48.409776
    ],
    [
        85.7745552,
        48.4088217
    ],
    [
        85.7720875,
        48.4079671
    ],
    [
        85.7701027,
        48.407732
    ],
    [
        85.7669163,
        48.406158
    ],
    [
        85.7655215,
        48.4047336
    ],
    [
        85.7636547,
        48.404235
    ],
    [
        85.7626247,
        48.403943
    ],
    [
        85.7616483,
        48.4037578
    ],
    [
        85.760951,
        48.4043703
    ],
    [
        85.7599318,
        48.4044558
    ],
    [
        85.7592773,
        48.4041495
    ],
    [
        85.7585156,
        48.4040356
    ],
    [
        85.7582581,
        48.4036153
    ],
    [
        85.7566595,
        48.4033874
    ],
    [
        85.7559085,
        48.4029529
    ],
    [
        85.7565522,
        48.4021836
    ],
    [
        85.7562625,
        48.4015711
    ],
    [
        85.7564664,
        48.4012007
    ],
    [
        85.7556188,
        48.4004314
    ],
    [
        85.7553077,
        48.3996122
    ],
    [
        85.7561552,
        48.3994057
    ],
    [
        85.7559836,
        48.3988928
    ],
    [
        85.7553939,
        48.3981667
    ],
    [
        85.7548571,
        48.3981306
    ],
    [
        85.754621,
        48.3978172
    ],
    [
        85.7549643,
        48.3973043
    ],
    [
        85.7544923,
        48.3967771
    ],
    [
        85.7538378,
        48.3971618
    ],
    [
        85.7529688,
        48.3970478
    ],
    [
        85.7527971,
        48.3965848
    ],
    [
        85.7531619,
        48.3955233
    ],
    [
        85.7526469,
        48.395388
    ],
    [
        85.7522821,
        48.3950033
    ],
    [
        85.7524967,
        48.3945403
    ],
    [
        85.7530439,
        48.3938706
    ],
    [
        85.753001,
        48.3932864
    ],
    [
        85.7524323,
        48.3932864
    ],
    [
        85.75122,
        48.39303
    ],
    [
        85.750587,
        48.3925313
    ],
    [
        85.7507586,
        48.3919898
    ],
    [
        85.7514024,
        48.3917049
    ],
    [
        85.7515182,
        48.3912621
    ],
    [
        85.7525182,
        48.3908784
    ],
    [
        85.7527757,
        48.3901446
    ],
    [
        85.7526898,
        48.3896388
    ],
    [
        85.7514882,
        48.3896958
    ],
    [
        85.750705,
        48.3893538
    ],
    [
        85.7501686,
        48.3894393
    ],
    [
        85.7493853,
        48.3903868
    ],
    [
        85.7484627,
        48.3908143
    ],
    [
        85.7464456,
        48.3902159
    ],
    [
        85.7458663,
        48.3895675
    ],
    [
        85.7461882,
        48.3893324
    ],
    [
        85.746274,
        48.3886556
    ],
    [
        85.7465207,
        48.3880215
    ],
    [
        85.7462311,
        48.3873802
    ],
    [
        85.7454693,
        48.3871237
    ],
    [
        85.7449757,
        48.3862616
    ],
    [
        85.7440853,
        48.3862687
    ],
    [
        85.7431948,
        48.3866535
    ],
    [
        85.7425296,
        48.3865608
    ],
    [
        85.7421541,
        48.3863043
    ],
    [
        85.7420361,
        48.385827
    ],
    [
        85.7418537,
        48.3855918
    ],
    [
        85.7421863,
        48.3853994
    ],
    [
        85.742315,
        48.3850218
    ],
    [
        85.7426476,
        48.3838604
    ],
    [
        85.7414782,
        48.3833759
    ],
    [
        85.7414782,
        48.3830196
    ],
    [
        85.7419503,
        48.3827559
    ],
    [
        85.7416713,
        48.3814234
    ],
    [
        85.7408988,
        48.3812025
    ],
    [
        85.7397936,
        48.381224
    ],
    [
        85.7392788,
        48.3814021
    ],
    [
        85.7388389,
        48.381117
    ],
    [
        85.7367039,
        48.3793213
    ],
    [
        85.7354271,
        48.3794638
    ],
    [
        85.7352233,
        48.3786942
    ],
    [
        85.7330668,
        48.3783236
    ],
    [
        85.7325303,
        48.3774471
    ],
    [
        85.7312643,
        48.3772191
    ],
    [
        85.7315433,
        48.3768627
    ],
    [
        85.7310283,
        48.3761929
    ],
    [
        85.7307923,
        48.3763425
    ],
    [
        85.7307601,
        48.3766561
    ],
    [
        85.7303846,
        48.3767772
    ],
    [
        85.7291937,
        48.3760361
    ],
    [
        85.7282494,
        48.3759577
    ],
    [
        85.7268762,
        48.3757154
    ],
    [
        85.7280135,
        48.3750098
    ],
    [
        85.7287431,
        48.3736059
    ],
    [
        85.7276487,
        48.3725155
    ],
    [
        85.7265544,
        48.3728504
    ],
    [
        85.7253528,
        48.3728718
    ],
    [
        85.725224,
        48.3722019
    ],
    [
        85.7256316,
        48.3718313
    ],
    [
        85.7255781,
        48.3710972
    ],
    [
        85.7252133,
        48.3694864
    ],
    [
        85.724988,
        48.3688592
    ],
    [
        85.7253849,
        48.3680395
    ],
    [
        85.7255459,
        48.3666068
    ],
    [
        85.725224,
        48.3663146
    ],
    [
        85.7245481,
        48.3663787
    ],
    [
        85.7235181,
        48.3654521
    ],
    [
        85.7227778,
        48.3653095
    ],
    [
        85.722928,
        48.3659154
    ],
    [
        85.7224345,
        48.3659226
    ],
    [
        85.7218873,
        48.365502
    ],
    [
        85.7219408,
        48.3650886
    ],
    [
        85.7211041,
        48.3649175
    ],
    [
        85.720557,
        48.3650458
    ],
    [
        85.7199132,
        48.3648961
    ],
    [
        85.7189047,
        48.3649175
    ],
    [
        85.7183361,
        48.365167
    ],
    [
        85.7177246,
        48.3651955
    ],
    [
        85.7170915,
        48.3649674
    ],
    [
        85.7170808,
        48.3644399
    ],
    [
        85.7179391,
        48.3637984
    ],
    [
        85.7179499,
        48.3636202
    ],
    [
        85.7175636,
        48.363499
    ],
    [
        85.717113,
        48.3634634
    ],
    [
        85.7166516,
        48.3622515
    ],
    [
        85.7146346,
        48.3610112
    ],
    [
        85.7136798,
        48.3608401
    ],
    [
        85.7134008,
        48.3597922
    ],
    [
        85.7135403,
        48.359393
    ],
    [
        85.7141089,
        48.3592147
    ],
    [
        85.7150316,
        48.3592789
    ],
    [
        85.7158685,
        48.3586658
    ],
    [
        85.7153106,
        48.3579244
    ],
    [
        85.7155251,
        48.3575679
    ],
    [
        85.7152998,
        48.3572685
    ],
    [
        85.7143557,
        48.3569691
    ],
    [
        85.7134759,
        48.3565128
    ],
    [
        85.7134223,
        48.3558427
    ],
    [
        85.7126927,
        48.3555432
    ],
    [
        85.7125318,
        48.3550798
    ],
    [
        85.7118451,
        48.3547091
    ],
    [
        85.7110727,
        48.3541815
    ],
    [
        85.71015,
        48.3535541
    ],
    [
        85.7104826,
        48.353055
    ],
    [
        85.7101606,
        48.3526558
    ],
    [
        85.7104075,
        48.3518144
    ],
    [
        85.7118451,
        48.3519428
    ],
    [
        85.7115555,
        48.3515078
    ],
    [
        85.7126391,
        48.3511371
    ],
    [
        85.7128429,
        48.3505025
    ],
    [
        85.7138944,
        48.3499392
    ],
    [
        85.7142161,
        48.349376
    ],
    [
        85.7139373,
        48.3491478
    ],
    [
        85.7126498,
        48.3492547
    ],
    [
        85.71177,
        48.3488626
    ],
    [
        85.7115126,
        48.3483421
    ],
    [
        85.7121348,
        48.3476718
    ],
    [
        85.7119632,
        48.3474436
    ],
    [
        85.7108688,
        48.3474151
    ],
    [
        85.7103646,
        48.34785
    ],
    [
        85.7094204,
        48.3476005
    ],
    [
        85.7092649,
        48.347686
    ],
    [
        85.709474,
        48.347982
    ],
    [
        85.7093561,
        48.3483278
    ],
    [
        85.7086211,
        48.3482993
    ],
    [
        85.7077038,
        48.3474935
    ],
    [
        85.7074892,
        48.3471441
    ],
    [
        85.7086104,
        48.3466735
    ],
    [
        85.7094902,
        48.3463598
    ],
    [
        85.7096082,
        48.3461209
    ],
    [
        85.7092595,
        48.3459105
    ],
    [
        85.7086962,
        48.3461066
    ],
    [
        85.7081544,
        48.3457643
    ],
    [
        85.7082778,
        48.3453579
    ],
    [
        85.7073283,
        48.3452331
    ],
    [
        85.7071673,
        48.3456253
    ],
    [
        85.7067274,
        48.3457537
    ],
    [
        85.7057619,
        48.3456039
    ],
    [
        85.7054508,
        48.3451119
    ],
    [
        85.7060623,
        48.3445699
    ],
    [
        85.7059979,
        48.3440779
    ],
    [
        85.7051825,
        48.3441563
    ],
    [
        85.7040238,
        48.3444059
    ],
    [
        85.703482,
        48.3437927
    ],
    [
        85.7034874,
        48.3434932
    ],
    [
        85.7030368,
        48.3430867
    ],
    [
        85.7027686,
        48.34283
    ],
    [
        85.7025969,
        48.3423379
    ],
    [
        85.703069,
        48.3418102
    ],
    [
        85.7028115,
        48.3413681
    ],
    [
        85.703069,
        48.3410258
    ],
    [
        85.7032621,
        48.3402484
    ],
    [
        85.7030368,
        48.3399346
    ],
    [
        85.7024573,
        48.3402484
    ],
    [
        85.7020605,
        48.339792
    ],
    [
        85.7018566,
        48.3393784
    ],
    [
        85.7020068,
        48.339143
    ],
    [
        85.7025003,
        48.3393213
    ],
    [
        85.7028544,
        48.3391929
    ],
    [
        85.7029617,
        48.3389219
    ],
    [
        85.7026613,
        48.3387223
    ],
    [
        85.7025754,
        48.3382872
    ],
    [
        85.702157,
        48.3383942
    ],
    [
        85.701642,
        48.3384869
    ],
    [
        85.7013845,
        48.338166
    ],
    [
        85.7016635,
        48.3379021
    ],
    [
        85.7016528,
        48.3376026
    ],
    [
        85.7014382,
        48.337517
    ],
    [
        85.7008695,
        48.337788
    ],
    [
        85.7005584,
        48.3375027
    ],
    [
        85.701009,
        48.3368965
    ],
    [
        85.6995714,
        48.3364614
    ],
    [
        85.6984019,
        48.3365684
    ],
    [
        85.6981015,
        48.3362047
    ],
    [
        85.6972861,
        48.336062
    ],
    [
        85.6968461,
        48.3363331
    ],
    [
        85.6950652,
        48.3352133
    ],
    [
        85.6948714,
        48.3349445
    ],
    [
        85.694443,
        48.3343503
    ],
    [
        85.6945075,
        48.3336445
    ],
    [
        85.6945395,
        48.3332947
    ],
    [
        85.6949917,
        48.3332301
    ],
    [
        85.6959879,
        48.3330879
    ],
    [
        85.6961167,
        48.3325458
    ],
    [
        85.6954192,
        48.331911
    ],
    [
        85.6944966,
        48.3317755
    ],
    [
        85.694561,
        48.3313832
    ],
    [
        85.6935418,
        48.3308553
    ],
    [
        85.6922436,
        48.3305914
    ],
    [
        85.6912994,
        48.3295215
    ],
    [
        85.6909668,
        48.3289794
    ],
    [
        85.6913209,
        48.3283089
    ],
    [
        85.6923187,
        48.3280592
    ],
    [
        85.6924151,
        48.3274814
    ],
    [
        85.6921792,
        48.3267609
    ],
    [
        85.6917286,
        48.3264827
    ],
    [
        85.6909025,
        48.3264185
    ],
    [
        85.6912243,
        48.325377
    ],
    [
        85.6906557,
        48.3250703
    ],
    [
        85.6902373,
        48.3241001
    ],
    [
        85.6903124,
        48.323401
    ],
    [
        85.6909132,
        48.3230871
    ],
    [
        85.6908595,
        48.3226805
    ],
    [
        85.6900549,
        48.3224807
    ],
    [
        85.6899047,
        48.3216032
    ],
    [
        85.6893736,
        48.321432
    ],
    [
        85.6893897,
        48.3211823
    ],
    [
        85.6898724,
        48.321168
    ],
    [
        85.690366,
        48.3209469
    ],
    [
        85.6907093,
        48.3209255
    ],
    [
        85.6907523,
        48.3206972
    ],
    [
        85.6914282,
        48.3204546
    ],
    [
        85.6933272,
        48.3185283
    ],
    [
        85.6929301,
        48.3176508
    ],
    [
        85.6923509,
        48.3174225
    ],
    [
        85.6915784,
        48.3176436
    ],
    [
        85.6910205,
        48.3174581
    ],
    [
        85.6908274,
        48.3168802
    ],
    [
        85.6905913,
        48.3168303
    ],
    [
        85.6905591,
        48.3166376
    ],
    [
        85.6907308,
        48.3161525
    ],
    [
        85.6905806,
        48.3156744
    ],
    [
        85.6899261,
        48.3154318
    ],
    [
        85.6899368,
        48.3139406
    ],
    [
        85.6906557,
        48.3139905
    ],
    [
        85.6908381,
        48.3138835
    ],
    [
        85.6906664,
        48.313591
    ],
    [
        85.6909025,
        48.3135053
    ],
    [
        85.6916428,
        48.3135838
    ],
    [
        85.69337,
        48.3131129
    ],
    [
        85.6932843,
        48.3126705
    ],
    [
        85.6933164,
        48.3120426
    ],
    [
        85.6927693,
        48.3118999
    ],
    [
        85.6925225,
        48.3111721
    ],
    [
        85.6925654,
        48.3102872
    ],
    [
        85.6922649,
        48.3099519
    ],
    [
        85.6903553,
        48.3095879
    ],
    [
        85.6894969,
        48.3090599
    ],
    [
        85.6891537,
        48.3087673
    ],
    [
        85.6894112,
        48.3084177
    ],
    [
        85.6899798,
        48.3084034
    ],
    [
        85.6899798,
        48.3076041
    ],
    [
        85.6903552,
        48.3072473
    ],
    [
        85.6910419,
        48.3068834
    ],
    [
        85.6912243,
        48.3068977
    ],
    [
        85.6914711,
        48.3074043
    ],
    [
        85.6925332,
        48.3075328
    ],
    [
        85.6929517,
        48.3072902
    ],
    [
        85.6935738,
        48.3072545
    ],
    [
        85.6943464,
        48.3068406
    ],
    [
        85.6944001,
        48.3064624
    ],
    [
        85.694164,
        48.3061769
    ],
    [
        85.6946468,
        48.3057987
    ],
    [
        85.6944643,
        48.3054062
    ],
    [
        85.6932413,
        48.3052991
    ],
    [
        85.6929088,
        48.3046854
    ],
    [
        85.6923454,
        48.3044856
    ],
    [
        85.6924474,
        48.3039575
    ],
    [
        85.692265,
        48.3034865
    ],
    [
        85.6924312,
        48.3033116
    ],
    [
        85.693059,
        48.3031617
    ],
    [
        85.6935686,
        48.303069
    ],
    [
        85.6936598,
        48.3025658
    ],
    [
        85.6938958,
        48.3022875
    ],
    [
        85.6939763,
        48.3015274
    ],
    [
        85.6944537,
        48.3012883
    ],
    [
        85.6944537,
        48.3007245
    ],
    [
        85.6939334,
        48.300332
    ],
    [
        85.6935363,
        48.3002713
    ],
    [
        85.6925815,
        48.2995968
    ],
    [
        85.6920987,
        48.2996182
    ],
    [
        85.6919378,
        48.2998466
    ],
    [
        85.6924635,
        48.3001321
    ],
    [
        85.6924528,
        48.3004569
    ],
    [
        85.6921953,
        48.300828
    ],
    [
        85.6919002,
        48.3008101
    ],
    [
        85.6908273,
        48.3003391
    ],
    [
        85.6900978,
        48.3005389
    ],
    [
        85.6891535,
        48.3003106
    ],
    [
        85.6878875,
        48.2989973
    ],
    [
        85.6882739,
        48.297941
    ],
    [
        85.6902909,
        48.297113
    ],
    [
        85.6903123,
        48.2961994
    ],
    [
        85.686364,
        48.2948147
    ],
    [
        85.687394,
        48.2926162
    ],
    [
        85.68233,
        48.2919309
    ],
    [
        85.68336,
        48.2896466
    ],
    [
        85.6810425,
        48.285706
    ],
    [
        85.6805275,
        48.2823076
    ],
    [
        85.6824587,
        48.2787091
    ],
    [
        85.6798838,
        48.2781379
    ],
    [
        85.6783818,
        48.2765956
    ],
    [
        85.6812142,
        48.2749676
    ],
    [
        85.6776951,
        48.2720827
    ],
    [
        85.6747769,
        48.272454
    ],
    [
        85.673189,
        48.2675693
    ],
    [
        85.6754607,
        48.2670212
    ],
    [
        85.6755494,
        48.2641126
    ],
    [
        85.6728972,
        48.262188
    ],
    [
        85.6691121,
        48.2606557
    ],
    [
        85.6656788,
        48.2575414
    ],
    [
        85.6628893,
        48.2548841
    ],
    [
        85.6645201,
        48.251598
    ],
    [
        85.6621598,
        48.2509408
    ],
    [
        85.6621169,
        48.2476544
    ],
    [
        85.6594132,
        48.2418242
    ],
    [
        85.656967,
        48.2415384
    ],
    [
        85.6507014,
        48.2464827
    ],
    [
        85.6488989,
        48.2453682
    ],
    [
        85.6510447,
        48.2420529
    ],
    [
        85.6447524,
        48.2366104
    ],
    [
        85.6371401,
        48.2338781
    ],
    [
        85.6326769,
        48.2334779
    ],
    [
        85.6326769,
        48.2280749
    ],
    [
        85.6352948,
        48.2278747
    ],
    [
        85.6342648,
        48.2258449
    ],
    [
        85.6313036,
        48.2237006
    ],
    [
        85.6267089,
        48.2159958
    ],
    [
        85.6266688,
        48.211891
    ],
    [
        85.6227635,
        48.2100893
    ],
    [
        85.6237076,
        48.20374
    ],
    [
        85.623536,
        48.2027389
    ],
    [
        85.6216477,
        48.2026817
    ],
    [
        85.6186007,
        48.2025387
    ],
    [
        85.6165408,
        48.2039402
    ],
    [
        85.6139444,
        48.2048269
    ],
    [
        85.6107687,
        48.2051558
    ],
    [
        85.6072711,
        48.2044551
    ],
    [
        85.6067346,
        48.2036256
    ],
    [
        85.6070565,
        48.2027532
    ],
    [
        85.6063699,
        48.2022097
    ],
    [
        85.6034731,
        48.2020095
    ],
    [
        85.6033658,
        48.2011371
    ],
    [
        85.6026791,
        48.1998213
    ],
    [
        85.60225,
        48.1992921
    ],
    [
        85.6014346,
        48.1994495
    ],
    [
        85.6011127,
        48.1991348
    ],
    [
        85.6012415,
        48.1983053
    ],
    [
        85.5985163,
        48.1980764
    ],
    [
        85.5936455,
        48.1961884
    ],
    [
        85.5927013,
        48.1961884
    ],
    [
        85.5899118,
        48.1967748
    ],
    [
        85.5885814,
        48.1965603
    ],
    [
        85.5889462,
        48.1957164
    ],
    [
        85.5924009,
        48.1945578
    ],
    [
        85.5921434,
        48.1937854
    ],
    [
        85.5904053,
        48.1899661
    ],
    [
        85.5887746,
        48.1901521
    ],
    [
        85.5881094,
        48.1915825
    ],
    [
        85.586779,
        48.1918257
    ],
    [
        85.5834316,
        48.1903809
    ],
    [
        85.5834101,
        48.1896228
    ],
    [
        85.5849551,
        48.187105
    ],
    [
        85.5834531,
        48.1854312
    ],
    [
        85.5847405,
        48.18463
    ],
    [
        85.5871867,
        48.184673
    ],
    [
        85.586264,
        48.1827845
    ],
    [
        85.586543,
        48.1818974
    ],
    [
        85.5851911,
        48.1815111
    ],
    [
        85.5846761,
        48.1795224
    ],
    [
        85.5828093,
        48.1783491
    ],
    [
        85.5850195,
        48.1772903
    ],
    [
        85.5846547,
        48.1767895
    ],
    [
        85.5830239,
        48.1765891
    ],
    [
        85.5828737,
        48.1756448
    ],
    [
        85.5822729,
        48.175888
    ],
    [
        85.5804919,
        48.1750867
    ],
    [
        85.5802559,
        48.1742425
    ],
    [
        85.5832814,
        48.1729832
    ],
    [
        85.5835174,
        48.1717239
    ],
    [
        85.5820584,
        48.1703931
    ],
    [
        85.581758,
        48.1690908
    ],
    [
        85.5812859,
        48.1675166
    ],
    [
        85.5795908,
        48.167259
    ],
    [
        85.579419,
        48.1665863
    ],
    [
        85.5776596,
        48.1664862
    ],
    [
        85.576415,
        48.1659137
    ],
    [
        85.5772733,
        48.1655273
    ],
    [
        85.5773163,
        48.1639959
    ],
    [
        85.5752563,
        48.1615484
    ],
    [
        85.5751061,
        48.159745
    ],
    [
        85.5741833,
        48.159201
    ],
    [
        85.574001,
        48.1583494
    ],
    [
        85.5742717,
        48.1574732
    ],
    [
        85.5745271,
        48.1570739
    ],
    [
        85.5744755,
        48.1567782
    ],
    [
        85.5742412,
        48.1566041
    ],
    [
        85.573798,
        48.1566037
    ],
    [
        85.5734328,
        48.1566904
    ],
    [
        85.5730672,
        48.1570031
    ],
    [
        85.5725451,
        48.1573679
    ],
    [
        85.5718931,
        48.1574891
    ],
    [
        85.5713713,
        48.15768
    ],
    [
        85.5709282,
        48.1576274
    ],
    [
        85.5706941,
        48.1573489
    ],
    [
        85.5708509,
        48.1571752
    ],
    [
        85.571242,
        48.1571581
    ],
    [
        85.5715028,
        48.1571062
    ],
    [
        85.5714509,
        48.1569844
    ],
    [
        85.5711381,
        48.1569319
    ],
    [
        85.5710341,
        48.1567927
    ],
    [
        85.5705653,
        48.1565488
    ],
    [
        85.5702266,
        48.1564616
    ],
    [
        85.5697832,
        48.1565655
    ],
    [
        85.5696003,
        48.1567567
    ],
    [
        85.5696521,
        48.1569133
    ],
    [
        85.5698602,
        48.1571743
    ],
    [
        85.5697556,
        48.1573307
    ],
    [
        85.5694427,
        48.1573826
    ],
    [
        85.5690777,
        48.1573649
    ],
    [
        85.568556,
        48.1574862
    ],
    [
        85.568217,
        48.1575207
    ],
    [
        85.5679825,
        48.1574857
    ],
    [
        85.5679047,
        48.157277
    ],
    [
        85.5677226,
        48.1570681
    ],
    [
        85.567697,
        48.1568246
    ],
    [
        85.5678801,
        48.1565291
    ],
    [
        85.5676981,
        48.1562507
    ],
    [
        85.5673073,
        48.156146
    ],
    [
        85.5671514,
        48.1559023
    ],
    [
        85.5672301,
        48.1556242
    ],
    [
        85.5672827,
        48.1553807
    ],
    [
        85.5669449,
        48.1548413
    ],
    [
        85.566503,
        48.1542148
    ],
    [
        85.5661134,
        48.1534839
    ],
    [
        85.5656447,
        48.1531878
    ],
    [
        85.5650974,
        48.1531004
    ],
    [
        85.5644979,
        48.1530477
    ],
    [
        85.5637418,
        48.1530817
    ],
    [
        85.5630275,
        48.1529978
    ],
    [
        85.5625326,
        48.152806
    ],
    [
        85.5621685,
        48.1524057
    ],
    [
        85.5620652,
        48.1519012
    ],
    [
        85.5623267,
        48.1515188
    ],
    [
        85.5626659,
        48.1513626
    ],
    [
        85.5631876,
        48.1512066
    ],
    [
        85.563683,
        48.1511722
    ],
    [
        85.56397,
        48.1510682
    ],
    [
        85.5641792,
        48.1507379
    ],
    [
        85.564389,
        48.150112
    ],
    [
        85.5643638,
        48.1496946
    ],
    [
        85.5641299,
        48.1493291
    ],
    [
        85.5638695,
        48.1491898
    ],
    [
        85.5634262,
        48.1492937
    ],
    [
        85.5628262,
        48.1495018
    ],
    [
        85.5621996,
        48.1499708
    ],
    [
        85.5612344,
        48.1503178
    ],
    [
        85.5604784,
        48.1503171
    ],
    [
        85.5600096,
        48.1501079
    ],
    [
        85.5593333,
        48.1494464
    ],
    [
        85.559282,
        48.1490463
    ],
    [
        85.5595701,
        48.1484205
    ],
    [
        85.5600408,
        48.1477079
    ],
    [
        85.5604847,
        48.1473431
    ],
    [
        85.5613199,
        48.1468569
    ],
    [
        85.5618937,
        48.1467009
    ],
    [
        85.5624933,
        48.1466493
    ],
    [
        85.5630667,
        48.1466672
    ],
    [
        85.5633016,
        48.1465283
    ],
    [
        85.5634324,
        48.1463023
    ],
    [
        85.5633285,
        48.1461109
    ],
    [
        85.5629898,
        48.1460236
    ],
    [
        85.5626254,
        48.1457798
    ],
    [
        85.5624697,
        48.145484
    ],
    [
        85.5622092,
        48.1454142
    ],
    [
        85.5620786,
        48.145501
    ],
    [
        85.5618964,
        48.1453965
    ],
    [
        85.5617408,
        48.1450137
    ],
    [
        85.5612204,
        48.1445958
    ],
    [
        85.5608557,
        48.1445085
    ],
    [
        85.5602296,
        48.1447341
    ],
    [
        85.5602032,
        48.144908
    ],
    [
        85.5603853,
        48.1450647
    ],
    [
        85.5603067,
        48.1452907
    ],
    [
        85.5601831,
        48.1453684
    ],
    [
        85.5595058,
        48.1451591
    ],
    [
        85.5588021,
        48.1451584
    ],
    [
        85.5582026,
        48.1451752
    ],
    [
        85.5575778,
        48.1448268
    ],
    [
        85.557318,
        48.1444439
    ],
    [
        85.5572666,
        48.1441134
    ],
    [
        85.5570588,
        48.1437828
    ],
    [
        85.5564858,
        48.1435909
    ],
    [
        85.556304,
        48.1433125
    ],
    [
        85.5562786,
        48.142982
    ],
    [
        85.5562537,
        48.1424429
    ],
    [
        85.5557074,
        48.1419727
    ],
    [
        85.5547711,
        48.1411022
    ],
    [
        85.5539652,
        48.140197
    ],
    [
        85.5536021,
        48.1393966
    ],
    [
        85.5533959,
        48.1383529
    ],
    [
        85.5535462,
        48.1377177
    ],
    [
        85.5540691,
        48.1370051
    ],
    [
        85.5548786,
        48.1363276
    ],
    [
        85.5556092,
        48.1359109
    ],
    [
        85.5568348,
        48.1355643
    ],
    [
        85.5573301,
        48.1355126
    ],
    [
        85.5579553,
        48.1356002
    ],
    [
        85.5584243,
        48.1356354
    ],
    [
        85.5587632,
        48.1356009
    ],
    [
        85.5591025,
        48.1353578
    ],
    [
        85.5592598,
        48.1349232
    ],
    [
        85.5595733,
        48.1345408
    ],
    [
        85.5601993,
        48.1342979
    ],
    [
        85.5609815,
        48.1340726
    ],
    [
        85.5618161,
        48.1337429
    ],
    [
        85.562911,
        48.1335179
    ],
    [
        85.5635634,
        48.1331011
    ],
    [
        85.5640069,
        48.1328232
    ],
    [
        85.5639554,
        48.1325623
    ],
    [
        85.5637475,
        48.1322664
    ],
    [
        85.5632266,
        48.1321442
    ],
    [
        85.5625489,
        48.1322479
    ],
    [
        85.5616108,
        48.1322123
    ],
    [
        85.5609074,
        48.132142
    ],
    [
        85.5605953,
        48.1318809
    ],
    [
        85.559619,
        48.1319783
    ],
    [
        85.5591761,
        48.1319257
    ],
    [
        85.5588114,
        48.1318732
    ],
    [
        85.5585513,
        48.1316816
    ],
    [
        85.5585779,
        48.1314381
    ],
    [
        85.5585785,
        48.1311425
    ],
    [
        85.5588656,
        48.1309167
    ],
    [
        85.558736,
        48.1306209
    ],
    [
        85.5585021,
        48.130325
    ],
    [
        85.5586328,
        48.1301164
    ],
    [
        85.5586332,
        48.1299599
    ],
    [
        85.5587915,
        48.1290209
    ],
    [
        85.558688,
        48.1286904
    ],
    [
        85.5588968,
        48.1285514
    ],
    [
        85.5593409,
        48.1279779
    ],
    [
        85.5592372,
        48.1277691
    ],
    [
        85.5591856,
        48.1275256
    ],
    [
        85.5589772,
        48.127508
    ],
    [
        85.5583518,
        48.1275422
    ],
    [
        85.5581167,
        48.1277854
    ],
    [
        85.5576998,
        48.1278024
    ],
    [
        85.557361,
        48.1278195
    ],
    [
        85.5569964,
        48.1277496
    ],
    [
        85.5569186,
        48.1275582
    ],
    [
        85.5571016,
        48.1272801
    ],
    [
        85.5575191,
        48.1270196
    ],
    [
        85.5577283,
        48.1266894
    ],
    [
        85.5581979,
        48.1263768
    ],
    [
        85.5585113,
        48.1260814
    ],
    [
        85.5585379,
        48.1258206
    ],
    [
        85.5583558,
        48.1256813
    ],
    [
        85.5580692,
        48.125681
    ],
    [
        85.5577826,
        48.1256633
    ],
    [
        85.5573134,
        48.1257672
    ],
    [
        85.5567661,
        48.1258537
    ],
    [
        85.5563233,
        48.1257837
    ],
    [
        85.5558542,
        48.1258006
    ],
    [
        85.5556974,
        48.1260265
    ],
    [
        85.5555669,
        48.1261134
    ],
    [
        85.5552282,
        48.1261304
    ],
    [
        85.5545243,
        48.1263036
    ],
    [
        85.5545498,
        48.1265645
    ],
    [
        85.5547312,
        48.1270169
    ],
    [
        85.5547305,
        48.1272952
    ],
    [
        85.5547816,
        48.1277648
    ],
    [
        85.5549371,
        48.1281476
    ],
    [
        85.5548577,
        48.1286866
    ],
    [
        85.5545443,
        48.1290167
    ],
    [
        85.5540746,
        48.1293293
    ],
    [
        85.5536053,
        48.1294332
    ],
    [
        85.5531884,
        48.1294154
    ],
    [
        85.5526679,
        48.1291366
    ],
    [
        85.5522519,
        48.1287362
    ],
    [
        85.5522264,
        48.1284927
    ],
    [
        85.5524874,
        48.128319
    ],
    [
        85.5526962,
        48.1281627
    ],
    [
        85.5525924,
        48.1279887
    ],
    [
        85.5521493,
        48.1280578
    ],
    [
        85.5517843,
        48.1281444
    ],
    [
        85.551523,
        48.1284572
    ],
    [
        85.5517829,
        48.1287531
    ],
    [
        85.5517563,
        48.1289965
    ],
    [
        85.5513388,
        48.1292222
    ],
    [
        85.5509219,
        48.1292392
    ],
    [
        85.5505052,
        48.1291344
    ],
    [
        85.5504537,
        48.1288735
    ],
    [
        85.5505847,
        48.1285605
    ],
    [
        85.5499858,
        48.128386
    ],
    [
        85.5494915,
        48.1280551
    ],
    [
        85.5492841,
        48.1276026
    ],
    [
        85.5491558,
        48.1267503
    ],
    [
        85.5492079,
        48.1263204
    ],
    [
        85.5495228,
        48.1254163
    ],
    [
        85.5499679,
        48.124495
    ],
    [
        85.550336,
        48.1230344
    ],
    [
        85.550547,
        48.1219564
    ],
    [
        85.5505487,
        48.1211911
    ],
    [
        85.550368,
        48.1204953
    ],
    [
        85.5495357,
        48.1199031
    ],
    [
        85.5495372,
        48.1192422
    ],
    [
        85.5498501,
        48.1191382
    ],
    [
        85.5503455,
        48.1189474
    ],
    [
        85.5506593,
        48.1184434
    ],
    [
        85.5508423,
        48.1181653
    ],
    [
        85.5511821,
        48.1176613
    ],
    [
        85.5515025,
        48.1169892
    ],
    [
        85.5514776,
        48.1164674
    ],
    [
        85.551427,
        48.1158412
    ],
    [
        85.5515324,
        48.1153022
    ],
    [
        85.5514032,
        48.1148325
    ],
    [
        85.5507785,
        48.1146579
    ],
    [
        85.5500751,
        48.1146572
    ],
    [
        85.5493714,
        48.1148304
    ],
    [
        85.54872,
        48.1148993
    ],
    [
        85.5480164,
        48.1149855
    ],
    [
        85.5473909,
        48.1151066
    ],
    [
        85.5470005,
        48.114967
    ],
    [
        85.5468188,
        48.114706
    ],
    [
        85.5468974,
        48.1145147
    ],
    [
        85.5472103,
        48.1143933
    ],
    [
        85.5475753,
        48.114272
    ],
    [
        85.5479409,
        48.1138723
    ],
    [
        85.5486199,
        48.1131426
    ],
    [
        85.5490899,
        48.1126735
    ],
    [
        85.5496904,
        48.1120654
    ],
    [
        85.5499522,
        48.1115266
    ],
    [
        85.5499531,
        48.1111092
    ],
    [
        85.5497193,
        48.110848
    ],
    [
        85.5497199,
        48.1106046
    ],
    [
        85.5499547,
        48.1104135
    ],
    [
        85.5503747,
        48.1102513
    ],
    [
        85.5508702,
        48.1099909
    ],
    [
        85.5508969,
        48.10973
    ],
    [
        85.5506633,
        48.109382
    ],
    [
        85.5502989,
        48.1092772
    ],
    [
        85.5496224,
        48.1089287
    ],
    [
        85.5489986,
        48.1083889
    ],
    [
        85.5490521,
        48.1077802
    ],
    [
        85.5493391,
        48.1075718
    ],
    [
        85.5496517,
        48.1075722
    ],
    [
        85.5498859,
        48.1076594
    ],
    [
        85.5499374,
        48.1078855
    ],
    [
        85.5503016,
        48.1080946
    ],
    [
        85.550614,
        48.1081645
    ],
    [
        85.5513698,
        48.1079565
    ],
    [
        85.5518909,
        48.1078875
    ],
    [
        85.5523596,
        48.1079402
    ],
    [
        85.5528024,
        48.1079232
    ],
    [
        85.5532195,
        48.1077845
    ],
    [
        85.5534281,
        48.1076456
    ],
    [
        85.5537412,
        48.1074198
    ],
    [
        85.5545759,
        48.1068641
    ],
    [
        85.5545767,
        48.1065337
    ],
    [
        85.5544733,
        48.1061857
    ],
    [
        85.5542135,
        48.1058724
    ],
    [
        85.5536665,
        48.1058719
    ],
    [
        85.5530669,
        48.1061495
    ],
    [
        85.5525456,
        48.1063229
    ],
    [
        85.5513735,
        48.1063391
    ],
    [
        85.5509304,
        48.1064778
    ],
    [
        85.5502003,
        48.1068423
    ],
    [
        85.5496271,
        48.1069286
    ],
    [
        85.5490805,
        48.1067889
    ],
    [
        85.5485862,
        48.1065275
    ],
    [
        85.5486649,
        48.1063015
    ],
    [
        85.5486914,
        48.1060929
    ],
    [
        85.5489004,
        48.1058322
    ],
    [
        85.5490833,
        48.1055889
    ],
    [
        85.5496308,
        48.105346
    ],
    [
        85.5497619,
        48.1049635
    ],
    [
        85.5497367,
        48.1045983
    ],
    [
        85.5494771,
        48.104285
    ],
    [
        85.5490865,
        48.1042324
    ],
    [
        85.5487217,
        48.1043537
    ],
    [
        85.5485128,
        48.1045274
    ],
    [
        85.548278,
        48.1047185
    ],
    [
        85.5479389,
        48.1049268
    ],
    [
        85.5478604,
        48.1050833
    ],
    [
        85.5479118,
        48.1053616
    ],
    [
        85.5478074,
        48.1054658
    ],
    [
        85.5475729,
        48.1055004
    ],
    [
        85.5471302,
        48.1054999
    ],
    [
        85.5468432,
        48.1057083
    ],
    [
        85.5464,
        48.1058817
    ],
    [
        85.5459834,
        48.1058291
    ],
    [
        85.5452288,
        48.1055153
    ],
    [
        85.5449432,
        48.1051845
    ],
    [
        85.5449698,
        48.1049237
    ],
    [
        85.5452046,
        48.10475
    ],
    [
        85.5456476,
        48.1046809
    ],
    [
        85.5457002,
        48.1044723
    ],
    [
        85.5454663,
        48.1042807
    ],
    [
        85.545545,
        48.1040025
    ],
    [
        85.5459625,
        48.1036725
    ],
    [
        85.5466928,
        48.1032559
    ],
    [
        85.5472663,
        48.1030304
    ],
    [
        85.5479438,
        48.1028398
    ],
    [
        85.5486216,
        48.1025797
    ],
    [
        85.5489607,
        48.1023365
    ],
    [
        85.549144,
        48.1019019
    ],
    [
        85.5491186,
        48.101641
    ],
    [
        85.5488585,
        48.1014842
    ],
    [
        85.5483377,
        48.1014663
    ],
    [
        85.5480721,
        48.1014114
    ],
    [
        85.5476299,
        48.1011848
    ],
    [
        85.5470061,
        48.1006798
    ],
    [
        85.5464863,
        48.100227
    ],
    [
        85.5461225,
        48.0999136
    ],
    [
        85.5451338,
        48.0995125
    ],
    [
        85.5435477,
        48.0985194
    ],
    [
        85.5432619,
        48.0982235
    ],
    [
        85.5432367,
        48.0979104
    ],
    [
        85.5434458,
        48.0975976
    ],
    [
        85.5438371,
        48.0973023
    ],
    [
        85.5441761,
        48.0971114
    ],
    [
        85.5445666,
        48.097164
    ],
    [
        85.5448798,
        48.096886
    ],
    [
        85.5452975,
        48.0964865
    ],
    [
        85.5454546,
        48.0960866
    ],
    [
        85.5457418,
        48.0958087
    ],
    [
        85.5460283,
        48.0957742
    ],
    [
        85.5463141,
        48.096018
    ],
    [
        85.5468609,
        48.096036
    ],
    [
        85.5481369,
        48.0960547
    ],
    [
        85.5489702,
        48.0960208
    ],
    [
        85.5496477,
        48.0958302
    ],
    [
        85.5500912,
        48.0955002
    ],
    [
        85.5500917,
        48.0952567
    ],
    [
        85.5498057,
        48.0950825
    ],
    [
        85.5495723,
        48.0946475
    ],
    [
        85.5493907,
        48.094369
    ],
    [
        85.5494182,
        48.0937951
    ],
    [
        85.5492636,
        48.093219
    ],
    [
        85.5487693,
        48.0930098
    ],
    [
        85.5483789,
        48.0929051
    ],
    [
        85.54778,
        48.092887
    ],
    [
        85.5471551,
        48.0929038
    ],
    [
        85.5466344,
        48.0928684
    ],
    [
        85.5462963,
        48.0926942
    ],
    [
        85.5463491,
        48.0924333
    ],
    [
        85.5461409,
        48.0923461
    ],
    [
        85.5457766,
        48.0922588
    ],
    [
        85.5456989,
        48.0920674
    ],
    [
        85.5458564,
        48.0915632
    ],
    [
        85.5457267,
        48.0913717
    ],
    [
        85.5454408,
        48.0911454
    ],
    [
        85.5453632,
        48.0909366
    ],
    [
        85.5452603,
        48.0904321
    ],
    [
        85.5452096,
        48.0898581
    ],
    [
        85.5450808,
        48.089284
    ],
    [
        85.5451335,
        48.0890058
    ],
    [
        85.5455253,
        48.0885193
    ],
    [
        85.5459946,
        48.0882241
    ],
    [
        85.5462553,
        48.0881027
    ],
    [
        85.5462297,
        48.0878939
    ],
    [
        85.5458398,
        48.08765
    ],
    [
        85.5454501,
        48.0873018
    ],
    [
        85.5453732,
        48.0868147
    ],
    [
        85.545323,
        48.086032
    ],
    [
        85.5454612,
        48.0853548
    ],
    [
        85.5451513,
        48.0843283
    ],
    [
        85.5447366,
        48.0835452
    ],
    [
        85.5447377,
        48.083093
    ],
    [
        85.5449213,
        48.082554
    ],
    [
        85.5452609,
        48.0820674
    ],
    [
        85.5458087,
        48.0815984
    ],
    [
        85.5463302,
        48.0812338
    ],
    [
        85.5469562,
        48.0807301
    ],
    [
        85.5476336,
        48.0805047
    ],
    [
        85.5481803,
        48.0804705
    ],
    [
        85.5483374,
        48.0800881
    ],
    [
        85.5483383,
        48.0797402
    ],
    [
        85.5482872,
        48.0793228
    ],
    [
        85.5478716,
        48.0789397
    ],
    [
        85.5475075,
        48.0788002
    ],
    [
        85.5469869,
        48.0787997
    ],
    [
        85.5467004,
        48.0788515
    ],
    [
        85.5464139,
        48.0789034
    ],
    [
        85.5456334,
        48.0787112
    ],
    [
        85.5446965,
        48.0786407
    ],
    [
        85.5433432,
        48.0785174
    ],
    [
        85.5425887,
        48.0783427
    ],
    [
        85.5421208,
        48.0780639
    ],
    [
        85.5411656,
        48.0778033
    ],
    [
        85.5403592,
        48.0775763
    ],
    [
        85.5398915,
        48.0772279
    ],
    [
        85.5396318,
        48.0769667
    ],
    [
        85.5397368,
        48.076619
    ],
    [
        85.540024,
        48.0763063
    ],
    [
        85.5404932,
        48.0760459
    ],
    [
        85.5409885,
        48.0757508
    ],
    [
        85.5413799,
        48.0753339
    ],
    [
        85.5416151,
        48.0749689
    ],
    [
        85.5416162,
        48.0745341
    ],
    [
        85.5415128,
        48.0742557
    ],
    [
        85.5411487,
        48.0741336
    ],
    [
        85.5408629,
        48.0739071
    ],
    [
        85.5403944,
        48.0739066
    ],
    [
        85.5400037,
        48.0740105
    ],
    [
        85.5397433,
        48.0740798
    ],
    [
        85.5382605,
        48.0737303
    ],
    [
        85.5377406,
        48.0734688
    ],
    [
        85.5374296,
        48.0729641
    ],
    [
        85.5364939,
        48.0724239
    ],
    [
        85.5355582,
        48.0719358
    ],
    [
        85.5350903,
        48.0717092
    ],
    [
        85.5347529,
        48.0713436
    ],
    [
        85.53452,
        48.0708216
    ],
    [
        85.5345731,
        48.0704216
    ],
    [
        85.5349387,
        48.0699351
    ],
    [
        85.535408,
        48.0696226
    ],
    [
        85.5357726,
        48.0695534
    ],
    [
        85.5363189,
        48.0696584
    ],
    [
        85.5369956,
        48.0696592
    ],
    [
        85.5377767,
        48.069521
    ],
    [
        85.5384278,
        48.0693478
    ],
    [
        85.5388189,
        48.0691047
    ],
    [
        85.5392365,
        48.0686356
    ],
    [
        85.5395755,
        48.0683751
    ],
    [
        85.5397063,
        48.0681144
    ],
    [
        85.5396289,
        48.067836
    ],
    [
        85.5394409,
        48.0674617
    ],
    [
        85.5389735,
        48.0670611
    ],
    [
        85.5386355,
        48.0669042
    ],
    [
        85.5382191,
        48.0669037
    ],
    [
        85.5378812,
        48.0667468
    ],
    [
        85.5378037,
        48.066538
    ],
    [
        85.5378047,
        48.0661554
    ],
    [
        85.5373625,
        48.0660505
    ],
    [
        85.5371806,
        48.0659286
    ],
    [
        85.5372595,
        48.0656156
    ],
    [
        85.5375207,
        48.0652681
    ],
    [
        85.5378857,
        48.0649902
    ],
    [
        85.5383545,
        48.0648516
    ],
    [
        85.5387708,
        48.0648869
    ],
    [
        85.5394731,
        48.0650442
    ],
    [
        85.5400714,
        48.0651318
    ],
    [
        85.5404358,
        48.0651149
    ],
    [
        85.5407744,
        48.0650109
    ],
    [
        85.5408269,
        48.0648196
    ],
    [
        85.5411398,
        48.0645591
    ],
    [
        85.5411926,
        48.0642635
    ],
    [
        85.5410112,
        48.063985
    ],
    [
        85.540908,
        48.0636023
    ],
    [
        85.5410645,
        48.0634633
    ],
    [
        85.541329,
        48.0634391
    ],
    [
        85.5416346,
        48.0634697
    ],
    [
        85.541895,
        48.0634322
    ],
    [
        85.5421218,
        48.0632584
    ],
    [
        85.5423374,
        48.0630091
    ],
    [
        85.5424966,
        48.0627142
    ],
    [
        85.5425311,
        48.0625025
    ],
    [
        85.5424523,
        48.062336
    ],
    [
        85.542243,
        48.0621785
    ],
    [
        85.5419829,
        48.062095
    ],
    [
        85.5416773,
        48.0621022
    ],
    [
        85.5412927,
        48.0620413
    ],
    [
        85.5410663,
        48.062041
    ],
    [
        85.5408738,
        48.0620938
    ],
    [
        85.5404888,
        48.0621463
    ],
    [
        85.5402624,
        48.0621612
    ],
    [
        85.5401155,
        48.0620702
    ],
    [
        85.5401955,
        48.0617677
    ],
    [
        85.5401733,
        48.0615862
    ],
    [
        85.5402302,
        48.0614652
    ],
    [
        85.5404004,
        48.0613066
    ],
    [
        85.5406609,
        48.0612009
    ],
    [
        85.5407631,
        48.0610952
    ],
    [
        85.5407407,
        48.0609817
    ],
    [
        85.5405484,
        48.0609436
    ],
    [
        85.5403119,
        48.0604744
    ],
    [
        85.5402105,
        48.0603003
    ],
    [
        85.5398372,
        48.0602394
    ],
    [
        85.5397354,
        48.0601863
    ],
    [
        85.5397243,
        48.0601031
    ],
    [
        85.5396113,
        48.0600273
    ],
    [
        85.5395211,
        48.0599138
    ],
    [
        85.539329,
        48.0597698
    ],
    [
        85.5390695,
        48.0594594
    ],
    [
        85.5390359,
        48.0593383
    ],
    [
        85.5389342,
        48.0592474
    ],
    [
        85.5387871,
        48.0592473
    ],
    [
        85.5386741,
        48.0591942
    ],
    [
        85.5386742,
        48.0591412
    ],
    [
        85.5388101,
        48.059096
    ],
    [
        85.5389127,
        48.0589228
    ],
    [
        85.5389132,
        48.058711
    ],
    [
        85.5389486,
        48.0581362
    ],
    [
        85.5390173,
        48.0578337
    ],
    [
        85.5390517,
        48.0576371
    ],
    [
        85.5389729,
        48.0574706
    ],
    [
        85.5390186,
        48.0572966
    ],
    [
        85.5391776,
        48.0571002
    ],
    [
        85.5393476,
        48.0569793
    ],
    [
        85.5394496,
        48.0569416
    ],
    [
        85.53954,
        48.0570098
    ],
    [
        85.5397324,
        48.0569722
    ],
    [
        85.5398233,
        48.0568361
    ],
    [
        85.539948,
        48.0567606
    ],
    [
        85.5401065,
        48.056723
    ],
    [
        85.5402423,
        48.0567534
    ],
    [
        85.5404461,
        48.0567007
    ],
    [
        85.5404915,
        48.0566327
    ],
    [
        85.5404125,
        48.0565569
    ],
    [
        85.5403336,
        48.0564434
    ],
    [
        85.5402546,
        48.0563298
    ],
    [
        85.5398928,
        48.0562008
    ],
    [
        85.5397347,
        48.0560645
    ],
    [
        85.5395652,
        48.0559887
    ],
    [
        85.5395655,
        48.0558525
    ],
    [
        85.5397128,
        48.0557846
    ],
    [
        85.5398828,
        48.0556789
    ],
    [
        85.5401094,
        48.0555959
    ],
    [
        85.5404268,
        48.0553845
    ],
    [
        85.5407438,
        48.0553168
    ],
    [
        85.5408798,
        48.0552337
    ],
    [
        85.5408461,
        48.0551429
    ],
    [
        85.5409821,
        48.0550447
    ],
    [
        85.5411853,
        48.0549202
    ],
    [
        85.5414233,
        48.0547843
    ],
    [
        85.5415707,
        48.0547012
    ],
    [
        85.5418317,
        48.0543763
    ],
    [
        85.5418892,
        48.0540208
    ],
    [
        85.5419019,
        48.0534611
    ],
    [
        85.5418232,
        48.0532492
    ],
    [
        85.5415746,
        48.0531279
    ],
    [
        85.5415069,
        48.0530295
    ],
    [
        85.5412357,
        48.0528854
    ],
    [
        85.5410549,
        48.0527869
    ],
    [
        85.5408512,
        48.0527715
    ],
    [
        85.5407495,
        48.0527034
    ],
    [
        85.5406594,
        48.052552
    ],
    [
        85.5406486,
        48.0523553
    ],
    [
        85.5407849,
        48.0521512
    ],
    [
        85.5407837,
        48.0519556
    ],
    [
        85.5407165,
        48.0516605
    ],
    [
        85.5399369,
        48.0511831
    ],
    [
        85.5394958,
        48.0511145
    ],
    [
        85.5393373,
        48.0511294
    ],
    [
        85.5389753,
        48.0511215
    ],
    [
        85.5387376,
        48.0511439
    ],
    [
        85.5383187,
        48.0512266
    ],
    [
        85.5378322,
        48.0511731
    ],
    [
        85.53764,
        48.0511124
    ],
    [
        85.5375386,
        48.050961
    ],
    [
        85.5375278,
        48.0507567
    ],
    [
        85.5374941,
        48.0506432
    ],
    [
        85.5374944,
        48.0505373
    ],
    [
        85.5375737,
        48.0504845
    ],
    [
        85.5377662,
        48.0504317
    ],
    [
        85.5379248,
        48.0503865
    ],
    [
        85.5381171,
        48.0504019
    ],
    [
        85.5385925,
        48.0503344
    ],
    [
        85.5386605,
        48.050289
    ],
    [
        85.5386043,
        48.0501528
    ],
    [
        85.5385716,
        48.049646
    ],
    [
        85.5383688,
        48.0493205
    ],
    [
        85.5380863,
        48.0491689
    ],
    [
        85.5378601,
        48.0491308
    ],
    [
        85.5375885,
        48.0491456
    ],
    [
        85.5369432,
        48.0492508
    ],
    [
        85.5365582,
        48.0493714
    ],
    [
        85.5360596,
        48.0496582
    ],
    [
        85.5357652,
        48.049726
    ],
    [
        85.535256,
        48.0497405
    ],
    [
        85.534962,
        48.0496494
    ],
    [
        85.5347701,
        48.0494525
    ],
    [
        85.5345985,
        48.0492442
    ],
    [
        85.5345763,
        48.0490627
    ],
    [
        85.5346899,
        48.0489267
    ],
    [
        85.5348939,
        48.0487907
    ],
    [
        85.5351204,
        48.0487229
    ],
    [
        85.5355733,
        48.0486175
    ],
    [
        85.5357432,
        48.0485345
    ],
    [
        85.5358793,
        48.0484137
    ],
    [
        85.5359249,
        48.0483078
    ],
    [
        85.5358573,
        48.0481716
    ],
    [
        85.5358349,
        48.0480959
    ],
    [
        85.535903,
        48.0480203
    ],
    [
        85.5359936,
        48.0479751
    ],
    [
        85.5364142,
        48.0472418
    ],
    [
        85.5365963,
        48.0468336
    ],
    [
        85.5366421,
        48.0466143
    ],
    [
        85.5365863,
        48.0462965
    ],
    [
        85.5366093,
        48.0461528
    ],
    [
        85.536621,
        48.0460242
    ],
    [
        85.5364518,
        48.0458274
    ],
    [
        85.5361649,
        48.0456521
    ],
    [
        85.5355998,
        48.0454245
    ],
    [
        85.5350008,
        48.0451818
    ],
    [
        85.5344241,
        48.0450298
    ],
    [
        85.5340851,
        48.0448706
    ],
    [
        85.5338821,
        48.0446056
    ],
    [
        85.5336562,
        48.0444616
    ],
    [
        85.53343,
        48.0444386
    ],
    [
        85.5330791,
        48.0445138
    ],
    [
        85.5326492,
        48.044468
    ],
    [
        85.5324229,
        48.0444904
    ],
    [
        85.5317214,
        48.0445047
    ],
    [
        85.5315171,
        48.0447238
    ],
    [
        85.5311093,
        48.0448973
    ],
    [
        85.530883,
        48.0449121
    ],
    [
        85.5304534,
        48.044783
    ],
    [
        85.530137,
        48.0446238
    ],
    [
        85.5300921,
        48.0445103
    ],
    [
        85.5300699,
        48.0443363
    ],
    [
        85.5298553,
        48.044215
    ],
    [
        85.529697,
        48.0441543
    ],
    [
        85.5295954,
        48.0440785
    ],
    [
        85.5294263,
        48.0438514
    ],
    [
        85.5292706,
        48.0435527
    ],
    [
        85.5292261,
        48.043303
    ],
    [
        85.5293169,
        48.0431669
    ],
    [
        85.5294416,
        48.0431066
    ],
    [
        85.5299396,
        48.0430467
    ],
    [
        85.5301659,
        48.0430091
    ],
    [
        85.530268,
        48.0429336
    ],
    [
        85.530246,
        48.0427066
    ],
    [
        85.530337,
        48.0425025
    ],
    [
        85.5303722,
        48.0420487
    ],
    [
        85.5304864,
        48.0416479
    ],
    [
        85.530487,
        48.0414437
    ],
    [
        85.5303969,
        48.0412999
    ],
    [
        85.5301597,
        48.0411483
    ],
    [
        85.5299338,
        48.0410119
    ],
    [
        85.5296743,
        48.0407468
    ],
    [
        85.5295396,
        48.0403306
    ],
    [
        85.5295338,
        48.0401593
    ],
    [
        85.5296022,
        48.0399854
    ],
    [
        85.5298178,
        48.0397511
    ],
    [
        85.5303049,
        48.0395021
    ],
    [
        85.5305882,
        48.0393209
    ],
    [
        85.5309389,
        48.0393289
    ],
    [
        85.5311993,
        48.0392611
    ],
    [
        85.5313014,
        48.039178
    ],
    [
        85.531313,
        48.0390646
    ],
    [
        85.531449,
        48.038974
    ],
    [
        85.531438,
        48.0388454
    ],
    [
        85.5311787,
        48.0385198
    ],
    [
        85.5309982,
        48.0383305
    ],
    [
        85.530637,
        48.0380577
    ],
    [
        85.5304337,
        48.0378986
    ],
    [
        85.5302877,
        48.0375127
    ],
    [
        85.5301075,
        48.0372477
    ],
    [
        85.5298481,
        48.03696
    ],
    [
        85.5295871,
        48.0366349
    ],
    [
        85.5293617,
        48.0363018
    ],
    [
        85.529114,
        48.0358855
    ],
    [
        85.5289684,
        48.0353331
    ],
    [
        85.5289238,
        48.0351061
    ],
    [
        85.5289809,
        48.0349246
    ],
    [
        85.5291513,
        48.0346601
    ],
    [
        85.5296164,
        48.0341614
    ],
    [
        85.5297979,
        48.0339952
    ],
    [
        85.5300356,
        48.033935
    ],
    [
        85.53059,
        48.0338903
    ],
    [
        85.5309746,
        48.033921
    ],
    [
        85.5313592,
        48.0339139
    ],
    [
        85.5319477,
        48.0338163
    ],
    [
        85.5321969,
        48.0336653
    ],
    [
        85.5321972,
        48.033567
    ],
    [
        85.5320618,
        48.0334306
    ],
    [
        85.5320282,
        48.0333247
    ],
    [
        85.5318706,
        48.0330219
    ],
    [
        85.5318146,
        48.0328025
    ],
    [
        85.5318496,
        48.0324016
    ],
    [
        85.5319199,
        48.0321661
    ],
    [
        85.5319884,
        48.0319317
    ],
    [
        85.5322496,
        48.0315159
    ],
    [
        85.5324766,
        48.0312212
    ],
    [
        85.5326133,
        48.0308885
    ],
    [
        85.532682,
        48.0305558
    ],
    [
        85.5325921,
        48.0303363
    ],
    [
        85.5322538,
        48.0299501
    ],
    [
        85.5319266,
        48.0296321
    ],
    [
        85.531577,
        48.0292686
    ],
    [
        85.5311366,
        48.0289881
    ],
    [
        85.5306849,
        48.0287229
    ],
    [
        85.5303572,
        48.0286014
    ],
    [
        85.5292592,
        48.0280004
    ],
    [
        85.5287849,
        48.0277351
    ],
    [
        85.5284007,
        48.0275909
    ],
    [
        85.527305,
        48.02706
    ],
    [
        85.5270342,
        48.0268555
    ],
    [
        85.5268428,
        48.0265148
    ],
    [
        85.526685,
        48.0263255
    ],
    [
        85.5265731,
        48.0258791
    ],
    [
        85.5266425,
        48.0253497
    ],
    [
        85.5265095,
        48.0251322
    ],
    [
        85.5264543,
        48.0246631
    ],
    [
        85.5268063,
        48.0238854
    ],
    [
        85.5163229,
        48.0220462
    ],
    [
        85.5101096,
        48.0216437
    ],
    [
        85.5051323,
        48.0207626
    ],
    [
        85.502675,
        48.0197245
    ],
    [
        85.5008066,
        48.0179985
    ],
    [
        85.4989382,
        48.0159897
    ],
    [
        85.4963054,
        48.0136024
    ],
    [
        85.4940304,
        48.0119874
    ],
    [
        85.4905809,
        48.0106982
    ],
    [
        85.487294,
        48.0098223
    ],
    [
        85.4747628,
        48.0084442
    ],
    [
        85.4709862,
        48.0086739
    ],
    [
        85.4666947,
        48.010052
    ],
    [
        85.4627465,
        48.0124636
    ],
    [
        85.457826,
        48.0139348
    ],
    [
        85.4485529,
        48.0164344
    ],
    [
        85.4438637,
        48.01832
    ],
    [
        85.4357956,
        48.0223387
    ],
    [
        85.433564,
        48.0232572
    ],
    [
        85.4313324,
        48.023372
    ],
    [
        85.4286158,
        48.0230454
    ],
    [
        85.4237143,
        48.0211942
    ],
    [
        85.4212839,
        48.0200212
    ],
    [
        85.4196179,
        48.0181303
    ],
    [
        85.4176237,
        48.0151946
    ],
    [
        85.4164973,
        48.0105779
    ],
    [
        85.4152487,
        48.0084359
    ],
    [
        85.4127848,
        48.0070402
    ],
    [
        85.4097966,
        48.0060268
    ],
    [
        85.407717,
        48.0057377
    ],
    [
        85.4058977,
        48.0061232
    ],
    [
        85.4041278,
        48.0067961
    ],
    [
        85.403171,
        48.0068379
    ],
    [
        85.4019405,
        48.0065517
    ],
    [
        85.3999022,
        48.0056746
    ],
    [
        85.3974727,
        48.004196
    ],
    [
        85.3924413,
        48.0016403
    ],
    [
        85.391272,
        48.0005894
    ],
    [
        85.3878114,
        47.9991028
    ],
    [
        85.381894,
        47.9982221
    ],
    [
        85.3778654,
        47.9965238
    ],
    [
        85.3758858,
        47.9948908
    ],
    [
        85.3733833,
        47.9929608
    ],
    [
        85.3726924,
        47.9914988
    ],
    [
        85.3726386,
        47.9901315
    ],
    [
        85.3733327,
        47.9881538
    ],
    [
        85.3745053,
        47.9866855
    ],
    [
        85.3774633,
        47.9844155
    ],
    [
        85.3786324,
        47.9817318
    ],
    [
        85.378804,
        47.9802996
    ],
    [
        85.3780089,
        47.9781103
    ],
    [
        85.3769139,
        47.9772169
    ],
    [
        85.3755425,
        47.9767373
    ],
    [
        85.3726242,
        47.9762777
    ],
    [
        85.36891,
        47.9766019
    ],
    [
        85.36583,
        47.9772417
    ],
    [
        85.3630798,
        47.9781894
    ],
    [
        85.3607199,
        47.9799971
    ],
    [
        85.3515099,
        47.9899509
    ],
    [
        85.3482483,
        47.9930528
    ],
    [
        85.3457837,
        47.9955075
    ],
    [
        85.34539,
        47.9967082
    ],
    [
        85.3454566,
        47.9987323
    ],
    [
        85.3475617,
        48.0041949
    ],
    [
        85.3470467,
        48.0060325
    ],
    [
        85.3457258,
        48.0075569
    ],
    [
        85.3422113,
        48.0086982
    ],
    [
        85.3352364,
        48.0093812
    ],
    [
        85.3284928,
        48.0105803
    ],
    [
        85.3208186,
        48.0106153
    ],
    [
        85.3170385,
        48.0100243
    ],
    [
        85.3140209,
        48.0089676
    ],
    [
        85.3121091,
        48.0080658
    ],
    [
        85.3097095,
        48.0081167
    ],
    [
        85.3074852,
        48.0084988
    ],
    [
        85.3051326,
        48.0100861
    ],
    [
        85.3035478,
        48.0107775
    ],
    [
        85.3021546,
        48.0108368
    ],
    [
        85.300185,
        48.0103592
    ],
    [
        85.2990778,
        48.0094127
    ],
    [
        85.299075,
        48.0077385
    ],
    [
        85.2994604,
        48.0037657
    ],
    [
        85.2987213,
        48.0026038
    ],
    [
        85.2973833,
        48.0014432
    ],
    [
        85.2959924,
        48.0003931
    ],
    [
        85.2941298,
        47.9994953
    ],
    [
        85.2917717,
        47.9987964
    ],
    [
        85.2894194,
        47.9988226
    ],
    [
        85.2851204,
        47.9992261
    ],
    [
        85.280415,
        47.9991289
    ],
    [
        85.2744339,
        47.9982221
    ],
    [
        85.2722294,
        47.9972342
    ],
    [
        85.2688124,
        47.9962414
    ],
    [
        85.2645761,
        47.9942758
    ],
    [
        85.2608439,
        47.9925388
    ],
    [
        85.2575353,
        47.9910539
    ],
    [
        85.2546097,
        47.9905218
    ],
    [
        85.2520744,
        47.9908604
    ],
    [
        85.2505163,
        47.9915158
    ],
    [
        85.2459854,
        47.9957324
    ],
    [
        85.2441494,
        47.9983501
    ],
    [
        85.2423333,
        48.0021274
    ],
    [
        85.2402733,
        48.0039651
    ],
    [
        85.2373551,
        48.0049988
    ],
    [
        85.2323372,
        48.0058172
    ],
    [
        85.227563,
        48.0055196
    ],
    [
        85.2239298,
        48.0044993
    ],
    [
        85.2195811,
        48.0023474
    ],
    [
        85.2119593,
        47.9972072
    ],
    [
        85.2084185,
        47.9957213
    ],
    [
        85.1992462,
        47.9954652
    ],
    [
        85.1959955,
        47.9953398
    ],
    [
        85.1945333,
        47.9957337
    ],
    [
        85.1924547,
        47.9967253
    ],
    [
        85.1906843,
        47.9971872
    ],
    [
        85.1863332,
        47.9974482
    ],
    [
        85.1813579,
        47.997279
    ],
    [
        85.1736867,
        47.9966672
    ],
    [
        85.1697587,
        47.9972119
    ],
    [
        85.1681755,
        47.997418
    ],
    [
        85.1630438,
        47.997291
    ],
    [
        85.1608971,
        47.996875
    ],
    [
        85.1589418,
        47.9954828
    ],
    [
        85.156489,
        47.9925005
    ],
    [
        85.1559876,
        47.9904105
    ],
    [
        85.1558159,
        47.9874234
    ],
    [
        85.1570176,
        47.9837128
    ],
    [
        85.1591986,
        47.98109
    ],
    [
        85.1625342,
        47.9780099
    ],
    [
        85.163369,
        47.9768522
    ],
    [
        85.1644109,
        47.9741176
    ],
    [
        85.162374,
        47.9706619
    ],
    [
        85.1603618,
        47.969351
    ],
    [
        85.1566908,
        47.9683558
    ],
    [
        85.1519135,
        47.968156
    ],
    [
        85.1411891,
        47.9711774
    ],
    [
        85.1319998,
        47.9706619
    ],
    [
        85.1300778,
        47.9690301
    ],
    [
        85.1281784,
        47.9654744
    ],
    [
        85.1247452,
        47.9618608
    ],
    [
        85.1226853,
        47.9589223
    ],
    [
        85.1195954,
        47.9580027
    ],
    [
        85.1116989,
        47.9578877
    ],
    [
        85.1055711,
        47.9602831
    ],
    [
        85.1015558,
        47.9614757
    ],
    [
        85.1000282,
        47.9615161
    ],
    [
        85.0981377,
        47.9615662
    ],
    [
        85.0898979,
        47.9624858
    ],
    [
        85.0845864,
        47.9620735
    ],
    [
        85.0785135,
        47.9584753
    ],
    [
        85.0746141,
        47.9540417
    ],
    [
        85.0723208,
        47.9523678
    ],
    [
        85.070114,
        47.9518234
    ],
    [
        85.0577544,
        47.9542089
    ],
    [
        85.0516175,
        47.9568818
    ],
    [
        85.0495147,
        47.960819
    ],
    [
        85.0495146,
        47.9644399
    ],
    [
        85.0502442,
        47.9682329
    ],
    [
        85.0490713,
        47.9712493
    ],
    [
        85.0460672,
        47.972887
    ],
    [
        85.0409603,
        47.9733754
    ],
    [
        85.0343513,
        47.9720538
    ],
    [
        85.0266588,
        47.9680815
    ],
    [
        85.0219917,
        47.9627583
    ],
    [
        85.0206326,
        47.9584337
    ],
    [
        85.0131653,
        47.9494951
    ],
    [
        85.0044393,
        47.945614
    ],
    [
        84.9991178,
        47.9382832
    ],
    [
        84.9977016,
        47.9299449
    ],
    [
        84.9961708,
        47.9253445
    ],
    [
        84.9923943,
        47.9225837
    ],
    [
        84.989476,
        47.9215483
    ],
    [
        84.9804209,
        47.9202829
    ],
    [
        84.9774168,
        47.9209731
    ],
    [
        84.9686621,
        47.9273574
    ],
    [
        84.964528,
        47.9308076
    ],
    [
        84.9566745,
        47.9323316
    ],
    [
        84.9535417,
        47.9323891
    ],
    [
        84.9512672,
        47.9320153
    ],
    [
        84.9412392,
        47.92753
    ],
    [
        84.9345444,
        47.9231588
    ],
    [
        84.9299095,
        47.9141853
    ],
    [
        84.9280213,
        47.9121143
    ],
    [
        84.9225281,
        47.9108486
    ],
    [
        84.9194382,
        47.9111938
    ],
    [
        84.9075936,
        47.9159111
    ],
    [
        84.903817,
        47.9159111
    ],
    [
        84.9008988,
        47.9148757
    ],
    [
        84.8990105,
        47.9124594
    ],
    [
        84.8983239,
        47.9094678
    ],
    [
        84.8984955,
        47.9076267
    ],
    [
        84.9006282,
        47.9052473
    ],
    [
        84.9034153,
        47.9009266
    ],
    [
        84.9038218,
        47.8960214
    ],
    [
        84.9023121,
        47.8937244
    ],
    [
        84.8988281,
        47.892167
    ],
    [
        84.8917441,
        47.8921281
    ],
    [
        84.8812342,
        47.894464
    ],
    [
        84.8779825,
        47.8942304
    ],
    [
        84.8736223,
        47.8935319
    ],
    [
        84.8694275,
        47.8910708
    ],
    [
        84.8668206,
        47.8873614
    ],
    [
        84.8679145,
        47.8804787
    ],
    [
        84.8684968,
        47.8788678
    ],
    [
        84.8713353,
        47.8762804
    ],
    [
        84.8725726,
        47.8748647
    ],
    [
        84.8731549,
        47.8725702
    ],
    [
        84.8725726,
        47.8706661
    ],
    [
        84.870098,
        47.8688596
    ],
    [
        84.8664589,
        47.8679319
    ],
    [
        84.8635476,
        47.8678343
    ],
    [
        84.8602724,
        47.8682737
    ],
    [
        84.85838,
        47.8692502
    ],
    [
        84.8569244,
        47.8710079
    ],
    [
        84.8559054,
        47.8740348
    ],
    [
        84.8554687,
        47.8761828
    ],
    [
        84.8538675,
        47.877452
    ],
    [
        84.8516113,
        47.8778915
    ],
    [
        84.8497917,
        47.8776473
    ],
    [
        84.8476082,
        47.876134
    ],
    [
        84.8416401,
        47.8745231
    ],
    [
        84.8323967,
        47.8692991
    ],
    [
        84.827247,
        47.8682805
    ],
    [
        84.8212335,
        47.8684084
    ],
    [
        84.8162414,
        47.8673782
    ],
    [
        84.8092677,
        47.8675732
    ],
    [
        84.8011827,
        47.8646649
    ],
    [
        84.7972184,
        47.8619846
    ],
    [
        84.790981,
        47.8598709
    ],
    [
        84.7863329,
        47.8594369
    ],
    [
        84.7839629,
        47.859221
    ],
    [
        84.7793499,
        47.8568942
    ],
    [
        84.7781824,
        47.8555331
    ],
    [
        84.7772105,
        47.8515814
    ],
    [
        84.7744512,
        47.8497628
    ],
    [
        84.7718737,
        47.8497709
    ],
    [
        84.7696744,
        47.8505094
    ],
    [
        84.7676389,
        47.8524432
    ],
    [
        84.7670416,
        47.8534451
    ],
    [
        84.7673803,
        47.8554621
    ],
    [
        84.7673742,
        47.8584379
    ],
    [
        84.7655486,
        47.8615751
    ],
    [
        84.7633744,
        47.8642308
    ],
    [
        84.7584097,
        47.8662944
    ],
    [
        84.7561945,
        47.8670071
    ],
    [
        84.7524727,
        47.8660473
    ],
    [
        84.7484575,
        47.8629451
    ],
    [
        84.7454969,
        47.8599134
    ],
    [
        84.7410184,
        47.8575039
    ],
    [
        84.7362509,
        47.8566868
    ],
    [
        84.7313566,
        47.8539494
    ],
    [
        84.7261056,
        47.8510179
    ],
    [
        84.7233312,
        47.8483773
    ],
    [
        84.7190797,
        47.8478233
    ],
    [
        84.7146841,
        47.8480059
    ],
    [
        84.7101344,
        47.8504059
    ],
    [
        84.7074118,
        47.8560222
    ],
    [
        84.7047317,
        47.8576528
    ],
    [
        84.7005974,
        47.8594374
    ],
    [
        84.6967748,
        47.8590758
    ],
    [
        84.6932168,
        47.8568726
    ],
    [
        84.6918914,
        47.8535119
    ],
    [
        84.6859715,
        47.8496613
    ],
    [
        84.6809788,
        47.8492466
    ],
    [
        84.6749702,
        47.852176
    ],
    [
        84.6697434,
        47.8541221
    ],
    [
        84.6660595,
        47.854505
    ],
    [
        84.6590493,
        47.8511252
    ],
    [
        84.6552619,
        47.8497046
    ],
    [
        84.6524749,
        47.8473667
    ],
    [
        84.6502342,
        47.8440184
    ],
    [
        84.6497345,
        47.8425245
    ],
    [
        84.6511676,
        47.8400181
    ],
    [
        84.6518603,
        47.8348084
    ],
    [
        84.6484914,
        47.829887
    ],
    [
        84.6480835,
        47.8272297
    ],
    [
        84.6487852,
        47.8259965
    ],
    [
        84.649583,
        47.8214648
    ],
    [
        84.6479019,
        47.8171099
    ],
    [
        84.6469433,
        47.8151496
    ],
    [
        84.6459045,
        47.8120186
    ],
    [
        84.64587,
        47.808929
    ],
    [
        84.6434621,
        47.807432
    ],
    [
        84.6403134,
        47.8069391
    ],
    [
        84.6377678,
        47.8081034
    ],
    [
        84.6360087,
        47.8102044
    ],
    [
        84.6330893,
        47.8114534
    ],
    [
        84.6283398,
        47.8115119
    ],
    [
        84.6228152,
        47.808373
    ],
    [
        84.6152014,
        47.8042451
    ],
    [
        84.6076793,
        47.8019653
    ],
    [
        84.5996366,
        47.8004619
    ],
    [
        84.5935915,
        47.8012013
    ],
    [
        84.5900665,
        47.8009178
    ],
    [
        84.5826362,
        47.7986379
    ],
    [
        84.5799759,
        47.7982682
    ],
    [
        84.5771322,
        47.798453
    ],
    [
        84.5674085,
        47.8022118
    ],
    [
        84.5564923,
        47.8059086
    ],
    [
        84.5480528,
        47.8060318
    ],
    [
        84.5401638,
        47.8038138
    ],
    [
        84.5365862,
        47.8043683
    ],
    [
        84.5344763,
        47.8051076
    ],
    [
        84.5273212,
        47.8062167
    ],
    [
        84.5226428,
        47.8091123
    ],
    [
        84.5209916,
        47.811823
    ],
    [
        84.5216337,
        47.8145336
    ],
    [
        84.5234684,
        47.8191535
    ],
    [
        84.5232849,
        47.8214941
    ],
    [
        84.5222758,
        47.8225412
    ],
    [
        84.5204412,
        47.8230339
    ]
]