import React, {useContext, useEffect, useState} from 'react';
import Map from "../../Map/Map";
import {useParams} from "react-router-dom";
import {AppDataContext} from "../../../contexts/AppDataContext";
import {getDistrictById, getDistricts} from "../../../services/floods";
import dayjs from "dayjs";
import SideInfo from "../../SideInfo/SideInfo";
import Filter from "../../UI/Filter";
import {floodsFilterOptions} from "../RegionPage/RegionPage";
import {IFilterOption} from "../../../types";
import { ICheckpoint } from '../../../types/district';
import PageLoading from '../../UI/PageLoading';

const DistrictPage = () => {
    const params = useParams();
    const districtId = params.id;
    const {district, date, updateDistrict, updateDistricts, updateDistrictsData} = useContext(AppDataContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilterOption>(floodsFilterOptions[0]);
    const blueCheckpoints: ICheckpoint[] = [];

    useEffect(() => {
        loadDistricts();
    }, [district]);

    useEffect(() => {
        loadDistrictData();
    }, [date, districtId]);

    const loadDistrictData = async (): Promise<void> => {
        setLoading(true);
        const response = await getDistrictById(Number(districtId), dayjs(date).format("YYYY-MM-DD"));
        if (!response.isError) {
            updateDistrict(response.data);
        } else {
            updateDistrict(null);
        }
        setLoading(false);
    };

    const loadDistricts = async (): Promise<void> => {
        const response = await getDistricts(dayjs(date).format("YYYY-MM-DD"));

        if (!response.isError) {
            updateDistrictsData(response.data);
            updateDistricts(response.data);
        }
    };

    if (district) {
        for (let j = 0; j < (district.checkpoints.length); j++) {
            if (!district.hydrometeorological_reports.map((report) => report.checkpoint).includes(district.checkpoints[j].id)) {
                blueCheckpoints.push(district.checkpoints[j]);
            }
        }
    }

    return (
        <div>
            {/*{loading && <PageLoading />}*/}
            <Map districtId={Number(districtId)} district={district ? district : undefined} blueCheckpoints={blueCheckpoints} regionOnly/>
            <SideInfo />
            <Filter
                options={floodsFilterOptions}
                onSelect={(option) => setFilter(option)}
                selectedOpt={filter}
            />
        </div>
    );
};

export default DistrictPage;